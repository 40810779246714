// TODO: in the process of development
import { nanoid } from '@reduxjs/toolkit';
import cn from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export type SelectProps = {
  items: { title: string; value: string }[];
  currentValue: string | number;
  choise: (val: unknown) => void;
};

export const Select: React.FC<SelectProps> = ({
  items,
  currentValue,
  choise,
}) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const handleClickSelect = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const currentElement = e.target as HTMLElement;
    if (currentElement.tagName === 'INPUT') {
      setIsVisible(!isVisible);
    }
  };
  const handleChangeOption = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const currentElement = e.target as HTMLElement;
    setIsVisible(false);
    if (currentElement.textContent) {
      const value = items.find((i) => i.title === currentElement.textContent)
        ?.value as string;

      if (value) choise(value);
    }
  };

  return (
    <div
      className={cn('select border', { open: isVisible })}
      onClick={(e) => handleClickSelect(e)}
    >
      <input type="text" placeholder="0" readOnly value={currentValue} />
      <label>{t('object.the-stage-of-the-object')}</label>
      <ul onClick={(e) => handleChangeOption(e)}>
        {items.map((e) => {
          return <li key={nanoid()}>{e.title}</li>;
        })}
      </ul>
    </div>
  );
};
