import { CSSProperties } from 'react';

type Props = {
  firstPosition: string;
  secondPosition: string;
  disable?: boolean;
  styles?: CSSProperties;
  handleClickToggle?: () => void;
};

const Toggle = ({
  firstPosition,
  secondPosition,
  styles,
  disable,
  handleClickToggle,
}: Props) => {
  return (
    <div className="switch">
      <input
        type="checkbox"
        onClick={handleClickToggle}
        disabled={disable}
        style={styles}
      />
      <div>
        <p>{firstPosition}</p>
        <label />
        <p>{secondPosition}</p>
      </div>
    </div>
  );
};

export default Toggle;
