/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchStatus } from '@store/constants';
import { refreshTokenError, signOutSuccess } from '@store/user/actions';
import {
  FetchFoundCategoryRequestAction,
  FetchFoundCategorySuccessAction,
  FetchVocabularySuccessAction,
} from '@store/vocabulary/actions';

export type CountryId = string;
export type Country = {
  id: CountryId;
  name: string;
  code: string;
};

export type CityId = string;
export type City = {
  id: CityId;
  name: string;
  countryId: CountryId;
};

export type CurrencyId = string;
export type CurrencyCode = string;
export type Currency = {
  id: CurrencyId;
  name: string;
  code: CurrencyCode;
  symbol: string;
};

export type LanguageId = string;
export type Language = {
  id: LanguageId;
  name: string;
  code: string;
  suggestedCurrencyId: CurrencyId;
};

export type UnitId = string;
export type Unit = {
  id: UnitId;
  name: string;
  aliases: string[];
};

export type CategoryId = string;

export type Category = {
  id: CategoryId;
  name: string;
  children: Category[];
};

export type FoundCategory = {
  id: CategoryId;
  name: string;
  parentId: string;
  path: string;
}[];

export type VocabularyState = {
  status: FetchStatus;
  countries: Country[];
  cities: City[];
  currencies: Currency[];
  languages: Language[];
  units: Unit[];
  category: Category[];
  foundCategories: FoundCategory;
  foundCategoryStatus: FetchStatus;
};

const initialState: VocabularyState = {
  status: FetchStatus.NotFetched,
  countries: [],
  cities: [],
  currencies: [],
  languages: [],
  units: [],
  category: [],
  foundCategories: [],
  foundCategoryStatus: FetchStatus.NotFetched,
};

export const vocabularySlice = createSlice({
  name: 'vocabulary',
  initialState,
  reducers: {
    fetchVocabularyRequest: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetchVocabularySuccess: (
      state,
      action: PayloadAction<FetchVocabularySuccessAction>
    ) => {
      const { countries, cities, currencies, languages, units, categories } =
        action.payload;

      state.status = FetchStatus.Fetched;
      state.countries = countries;
      state.cities = cities;
      state.currencies = currencies;
      state.languages = languages;
      state.units = units;
      state.category = categories;
    },

    fetchFoundCategoryRequest: (
      state,
      action: PayloadAction<FetchFoundCategoryRequestAction>
    ) => {
      state.foundCategoryStatus = FetchStatus.Fetching;
    },
    fetchFoundCategorySuccess: (
      state,
      action: PayloadAction<FetchFoundCategorySuccessAction>
    ) => {
      state.foundCategories = action.payload;
      state.foundCategoryStatus = FetchStatus.Fetched;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signOutSuccess, () => initialState);
    builder.addCase(refreshTokenError, () => initialState);
  },
});

export default vocabularySlice.reducer;
