import React, { ChangeEvent, useEffect, useState } from 'react';
import { EMAIL_PATTERN } from '@constants/patterns';
import { Input } from '@components/EditCompany/Input';
import { Select } from '@components/EditCompany/Select';
import { useAppSelector } from '@hooks/redux';
import {
  getCitiesByCountryId,
  getCountries,
} from '@store/vocabulary/selectors';
import { CountryId } from '@store/vocabulary/slice';
import {
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { phoneFormat } from '@utils/phoneFormat';
import { useLocalizedVocabulary } from '@hooks/useLocalizedVocabulary';
import { inputErrors } from '@components/EditCompany/utils';
import { SettingsFormData } from '../Company/type';

export type ContactInfoProps = {
  register: UseFormRegister<SettingsFormData>;
  setValue: UseFormSetValue<SettingsFormData>;
  getValues: UseFormGetValues<SettingsFormData>;
  errors: FieldValues;
  onBlur: (name: string) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  watch: UseFormWatch<SettingsFormData>;
};

export const ContactInfo: React.FC<ContactInfoProps> = ({
  register,
  setValue,
  getValues,
  errors,
  onBlur,
  onKeyDown,
  watch,
}) => {
  const { t } = useTranslation('');

  const [currentCountryId, setCurrentCountryId] = useState<CountryId>('');

  const countriesCollection = useAppSelector(getCountries);
  const citiesCollection = useAppSelector((state) =>
    getCitiesByCountryId(state, currentCountryId)
  );

  const cities = useLocalizedVocabulary(citiesCollection, 'cities');
  const countries = useLocalizedVocabulary(countriesCollection, 'countries');

  const handleChangePhone = (event: ChangeEvent<HTMLInputElement>) => {
    setValue('phone', phoneFormat(event.target.value));
  };

  const handleUrlBlur = (name: keyof SettingsFormData) => {
    const value = getValues(name)?.replace(/^https?:\/\//, '');
    setValue(name, value as string);
    onBlur(name);
  };

  const countryId = watch('countryId');

  useEffect(() => {
    setCurrentCountryId(countryId || '');
  }, [countryId]);

  return (
    <div className="form__cot">
      <div className="form__cap">
        <h5>{t('profile.contact-info')}</h5>
      </div>
      <div className="form__group">
        <Input
          title={t('profile.company-phone')}
          isSmall
          {...register('phone', {
            onChange: handleChangePhone,
          })}
          error={inputErrors('phone', errors, t)}
          onKeyDown={onKeyDown}
          maxLength={17}
        />
        <Input
          title={t('profile.company-email')}
          isSmall
          {...register('email', {
            pattern: EMAIL_PATTERN,
            minLength: 3,
            onBlur: () => onBlur('email'),
          })}
          error={inputErrors('email', errors, t)}
          onKeyDown={onKeyDown}
          maxLength={320}
        />
        <Input
          title={t('profile.company-site')}
          isSmall
          {...register('website', {
            onBlur: () => handleUrlBlur('website'),
            minLength: 3,
          })}
          error={inputErrors('website', errors, t)}
          onKeyDown={onKeyDown}
          maxLength={300}
        />
        <Input
          title={t('profile.company-address')}
          isSmall
          {...register('address', {
            onBlur: () => onBlur('address'),
          })}
          error={inputErrors('address', errors, t)}
          onKeyDown={onKeyDown}
          maxLength={300}
        />

        <Select
          title={t('profile.country')}
          listItems={countries}
          isSmall
          onSelect={(id) => {
            setValue('countryId', id);
          }}
          selectedId={watch('countryId') || ''}
        />
        <Select
          title={t('profile.city')}
          listItems={cities ?? []}
          onSelect={(id) => {
            setValue('cityId', id);
          }}
          selectedId={watch('cityId') || ''}
          isSmall
        />
      </div>
    </div>
  );
};
