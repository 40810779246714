import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { Saga } from '@sagas/types';
import { handleError } from '@sagas/errors';
import {
  FetchDeleteOrerRequestAction,
  FetchOrder,
  FetchOrderList,
  FetchOrderStatus,
  FetchOrders,
  FetchOrerBasicDataRequestAction,
  FetchOrerDetailsRequestAction,
  FetchUpdateCurrentOrderRequestAction,
  FetchUpdateCurrentOrderValidityInvoiceRequestAction,
  FetchUpdateCurrentOrderValidityInvoiceRequestActionSuccess,
  FetchUpdateOrderInvoiceValidity,
  FetchUpdateStatusORderRequestAction,
  fetchDeleteOrderItemsRequest,
  fetchOrerBasicDataRequestSeccess,
  fetchOrerDetailsRequestActionSuccess,
  fetchUpdateOrder,
  fetchUpdateOrderRequestSuccess,
  setDeleteOrderSupplierStatus,
  setOrderDetailsStatus,
  setStatusFetchingData,
} from '@store/orderSupplier/actions';
import { FetchStatus } from '@store/constants';
import { FetchDeleteOrderItem } from '@store/orderSupplier/slice';
import {
  deleteOrderById,
  deleteOrderItemById,
  getOrderItemsById,
  getOrdersById,
  getOrdersByStatus,
  updateOrderValidityInvoice,
  updateStatusOrder,
} from './api/orderSupplier';

const fetchOrerBasicDataRequestActionHandler: Saga<FetchOrders> = function* ({
  payload,
}) {
  const { status } = payload;

  try {
    yield put(setStatusFetchingData({ status }));
    const data = yield call(getOrdersByStatus, { status });
    yield put(fetchOrerBasicDataRequestSeccess({ orders: data, status }));
  } catch (e) {
    yield call(handleError, e);
  }
};

const FetchOrerDetailsRequestActionHandle: Saga<FetchOrderList> = function* ({
  payload,
}) {
  const { orderId } = payload;
  try {
    yield put(setOrderDetailsStatus(FetchStatus.Fetching));
    const data = yield call(getOrderItemsById, { orderId });

    yield put(fetchOrerDetailsRequestActionSuccess(data));
  } catch (e) {
    yield call(handleError, e);
  }
};

const FetchUpdateOrerStatusRequestActionHandle: Saga<FetchOrderStatus> =
  function* ({ payload }) {
    const { orderId, status } = payload;
    try {
      yield put(fetchUpdateOrder());
      yield call(updateStatusOrder, { orderId, status });
      yield put(FetchUpdateCurrentOrderRequestAction({ orderId }));
    } catch (e) {
      yield call(handleError, e);
    }
  };

const FetchUpdateCurrentOrderRequestActionHandle: Saga<FetchOrder> =
  function* ({ payload }) {
    const { orderId } = payload;
    try {
      yield put(fetchUpdateOrder());
      const data = yield call(getOrdersById, { orderId });
      yield put(fetchUpdateOrderRequestSuccess(data));
    } catch (e) {
      yield call(handleError, e);
    }
  };

const fetchDeleteOrerRequestActionHandle: Saga<FetchOrderList> = function* ({
  payload,
}) {
  const { orderId } = payload;
  try {
    yield put(setDeleteOrderSupplierStatus(FetchStatus.Fetching));
    yield call(deleteOrderById, { orderId });
    yield put(setDeleteOrderSupplierStatus(FetchStatus.Fetched));
  } catch (e) {
    yield put(setDeleteOrderSupplierStatus(FetchStatus.Error));
    yield call(handleError, e);
  }
};

const FetchUpdateCurrentOrderValidityInvoiceRequestActionHandler: Saga<FetchUpdateOrderInvoiceValidity> =
  function* ({ payload }) {
    const { invoiceValidity, orderId } = payload;
    try {
      yield put(fetchUpdateOrder());
      yield call(updateOrderValidityInvoice, { invoiceValidity, orderId });
      yield put(
        FetchUpdateCurrentOrderValidityInvoiceRequestActionSuccess({
          invoiceValidity,
        })
      );
    } catch (error) {
      yield call(handleError, error);
    }
  };

const fetchDeleteOrderItemsRequestHandler: Saga<FetchDeleteOrderItem> =
  function* ({ payload }) {
    const { orderItemId, orderId } = payload;
    try {
      yield call(deleteOrderItemById, { orderItemId });
      yield put(FetchOrerDetailsRequestAction({ orderId }));
    } catch (error) {
      yield call(handleError, error);
    }
  };

export default function* root() {
  yield takeEvery(
    FetchOrerBasicDataRequestAction,
    fetchOrerBasicDataRequestActionHandler
  );
  yield takeLatest(
    FetchOrerDetailsRequestAction,
    FetchOrerDetailsRequestActionHandle
  );

  yield takeLatest(
    FetchUpdateStatusORderRequestAction,
    FetchUpdateOrerStatusRequestActionHandle
  );

  yield takeLatest(
    FetchUpdateCurrentOrderRequestAction,
    FetchUpdateCurrentOrderRequestActionHandle
  );

  yield takeLatest(
    FetchDeleteOrerRequestAction,
    fetchDeleteOrerRequestActionHandle
  );

  yield takeLatest(
    FetchUpdateCurrentOrderValidityInvoiceRequestAction,
    FetchUpdateCurrentOrderValidityInvoiceRequestActionHandler
  );
  yield takeLatest(
    fetchDeleteOrderItemsRequest,
    fetchDeleteOrderItemsRequestHandler
  );
}
