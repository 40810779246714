import React, { FC, useEffect } from 'react';
import { useAppDispatch } from '@hooks/redux';
import { CompanyObjectId } from '@store/companyObjects/slice';
import {
  createCostEstimateRequest,
  setActiveCreateNewCostEstimate,
} from '@store/costEstimates/actions';
import { useForm } from 'react-hook-form';
import { useFormErrors } from '@hooks/useFormErrors';
import { useTranslation } from 'react-i18next';
import { CompanyId } from '@store/company/slice';
import { ANIMATION_TIME } from '@constants/modal';

type CreateSectionModalProps = {
  companyId: CompanyId;
  objectId: CompanyObjectId;
  isActiveModal: boolean;
  onClick: () => void;
};

type FormData = {
  name: string;
};

export const CreateSectionModal: FC<CreateSectionModalProps> = ({
  companyId,
  objectId,
  isActiveModal,
  onClick,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setFocus,
    reset,
    formState: { errors: formErrors },
  } = useForm<FormData>({
    mode: 'onBlur',
  });
  const errors = useFormErrors(formErrors);

  const submitHandler = handleSubmit((data, event) => {
    event?.preventDefault();
    dispatch(createCostEstimateRequest({ data, companyId, id: objectId }));
    onClick();
    dispatch(setActiveCreateNewCostEstimate(false));
  });

  const handleBlurInput = (name: keyof FormData) => {
    const trimedValue = getValues(name).trim();

    setValue(name, trimedValue);
  };

  const handleErrors = () => {
    if (errors.name?.type === 'required') {
      return (
        <div>
          <s />
          <small>{t('auth.validation-empty')}</small>
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    reset({ name: '' });

    const timeoutId = setTimeout(() => setFocus('name'), ANIMATION_TIME);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isActiveModal, reset, setFocus]);

  return (
    <div className="modal__min">
      <div className="modal__close" onClick={onClick} />
      <div className="modal__min-title">
        <h4>{t('about-object.new-section')}</h4>
      </div>
      <form action="#" className="modal__min-form form">
        <div className="form__group">
          <div className="input border error">
            <input
              type="text"
              placeholder="0"
              {...register('name', {
                required: true,
                onBlur: () => handleBlurInput('name'),
              })}
            />
            <label>{t('about-object.section-name')}</label>
            {handleErrors()}
          </div>
        </div>
        <div className="modal__min-but">
          <a className="link transparent" onClick={onClick}>
            {t('about-object.cancel')}
          </a>
          <button onClick={submitHandler} type="submit" className="link">
            {t('about-object.done')}
          </button>
        </div>
      </form>
    </div>
  );
};
