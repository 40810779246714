import { format, fromUnixTime } from 'date-fns';
import { enUS, ru } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

export const useCurrentLocaleDate = (
  timestampDate: number,
  toRuFormat?: string,
  toEuFormat?: string
) => {
  const {
    i18n: { language },
  } = useTranslation();

  const ruFormat = toRuFormat || 'd MMM yyyy';

  const ruDate = format(fromUnixTime(timestampDate), ruFormat, {
    locale: ru,
  });

  const euFormat = toEuFormat || 'MMM d yyyy';
  const euDate = format(fromUnixTime(timestampDate), euFormat, {
    locale: enUS,
  });
  const currentLocale = language === 'ru' ? ruDate : euDate;

  return currentLocale;
};
