import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useCompanyId } from '@hooks/useCompanyId';
import { ROOT_FOLDER_ID } from '@constants/folders';
import { useSelectedResources } from '@hooks/useSelectedResources';
import {
  ResourcesCatalogSize,
  ResourcesCatalog,
} from '@components/ResourcesCatalog';
import { ResourceFolderId } from '@store/resourceFolders/slice';
import { getResourcesUrl } from '@pages';
import { ResourcesBottomPanel } from '@pages/Settings/Directory/Resources/BottomPanel';
import { deleteResourcesRequest } from '@store/resources/actions';
import { getRootFolders } from '@store/resourceFolders/selectors';
import { SettingsDirectoryResourcesEmpty } from '@pages/Settings/Directory/Resources/Empty';
import { fetchResourceFoldersRequest } from '@store/resourceFolders/actions';

export const SettingsDirectoryResources = () => {
  const { folderId = ROOT_FOLDER_ID } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const companyId = useCompanyId();
  const [selectedResources, handleSelectResources] = useSelectedResources();
  const rootFolders = useAppSelector(getRootFolders);

  useEffect(() => {
    if (companyId !== undefined) {
      dispatch(fetchResourceFoldersRequest({ companyId }));
    }
  }, [companyId, dispatch]);

  const handleChangeFolder = (id: ResourceFolderId) => {
    navigate(getResourcesUrl(id));
  };

  const handleDeleteSelected = () => {
    if (companyId !== undefined) {
      handleSelectResources(selectedResources, false);
      dispatch(
        deleteResourcesRequest({ companyId, data: { ids: selectedResources } })
      );
    }
  };

  if (rootFolders.length === 0) {
    return <SettingsDirectoryResourcesEmpty />;
  }

  return (
    <>
      <ResourcesCatalog
        size={ResourcesCatalogSize.Normal}
        folderId={folderId}
        onChangeFolder={handleChangeFolder}
        selectedResources={selectedResources}
        onSelectResources={handleSelectResources}
      />
      {selectedResources.length > 0 && (
        <ResourcesBottomPanel
          selectedCount={selectedResources.length}
          onDeleteClick={handleDeleteSelected}
        />
      )}
    </>
  );
};
