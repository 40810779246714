import { t } from 'i18next';

export const inputErrors = (
  field: string,
  type: string | undefined
): string | null => {
  switch (field) {
    case 'email':
      switch (type) {
        case 'required':
          return t('auth.validation-empty');
        case 'minLength':
          return t('auth.validation-email-short');
        case 'pattern':
          return t('auth.validation-email');

        default:
          return null;
      }
    case 'repeatPassword':
    case 'password':
      switch (type) {
        case 'required':
          return t('auth.validation-empty');
        case 'passwordsNotMatch':
          return t('auth.password-not-match');
        case 'minLength':
          return t('auth.validation-password-short');
        default:
          return null;
      }
    default:
      return null;
  }
};
