import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { fetchRequestCustomerRequest } from '@store/requestCustomer/actions';
import { useTranslation } from 'react-i18next';
import Empty from '@components/commons/Empty';
import { isFetchRequestCustomerStatusFetching } from '@store/requestCustomer/selectors';
import { isCostEstimatesFetching } from '@store/costEstimates/selectors';
import Loader from '@components/commons/Loader';
import TableRequestsSection from '../TableRequestsSection';
import { DataForRender } from '../../type';
import TableRequestsHeader from '../TableRequestsHeader';

type Props = {
  data: DataForRender;
  isRequestItems: boolean;
  requestId?: string;
  isViewSuppliers: boolean;
  isViewChoise: boolean;
  selectedIds: string[];
  handleAddResourceIds: (id: string, checked: boolean) => void;
  handleAddSectionIds: (ids: string[], checked: boolean) => void;
  handleOpenSupplierOffers: () => void;
  handleChoiseClose: () => void;
  handleOpenDeleteItemModal: (id: string) => void;
};

const TableRequests = ({
  data,
  requestId,
  isRequestItems,
  isViewSuppliers,
  isViewChoise,
  selectedIds,
  handleAddSectionIds,
  handleAddResourceIds,
  handleOpenSupplierOffers,
  handleChoiseClose,
  handleOpenDeleteItemModal,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isFetchRequestCustomerLoading = useAppSelector(
    isFetchRequestCustomerStatusFetching
  );
  const isCostEstimatesLoading = useAppSelector(isCostEstimatesFetching);

  useEffect(() => {
    if (requestId) {
      dispatch(fetchRequestCustomerRequest({ id: requestId }));
    }
  }, [dispatch, requestId, t]);

  return (
    <div className="obj__supply">
      <>
        {isFetchRequestCustomerLoading || isCostEstimatesLoading ? (
          <Loader />
        ) : (
          <>
            {requestId && isRequestItems ? (
              <>
                <TableRequestsHeader isViewSuppliers={isViewSuppliers} />
                <div className="obj__supply-block">
                  <TableRequestsSection
                    requestId={requestId}
                    data={data}
                    isViewSuppliers={isViewSuppliers}
                    isViewChoise={isViewChoise}
                    selectedIds={selectedIds}
                    handleAddResourceIds={handleAddResourceIds}
                    handleOpenSupplierOffers={handleOpenSupplierOffers}
                    handleChoiseClose={handleChoiseClose}
                    handleAddSectionIds={handleAddSectionIds}
                    handleOpenDeleteItemModal={handleOpenDeleteItemModal}
                  />
                </div>
              </>
            ) : (
              <Empty firstLine="supply.you-dont-have-any-applications" />
            )}
          </>
        )}
      </>
    </div>
  );
};

export default TableRequests;
