import { call, select } from 'redux-saga/effects';
import { ENDPOINTS } from '@config';
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  putRequest,
} from '@sagas/api';
import { urlFromTemplate } from '@utils/url';
import { CompanyObject, CompanyObjectId } from '@store/companyObjects/slice';
import { CompanyId } from '@store/company/slice';
import { MoveObject, UpdateStatusObject } from '@store/companyObjects/actions';
import { getCompanyObjectId } from '@store/companyObjects/selectors';
import { Contact } from '@store/contacts/slice';
import { DocumentId } from '@store/documents/slice';

export type CreateObjectData = {
  address: string;
  description: string;
  name: string;
  organizationId: string;
  contactId: string;
  cost: number;
};

export type UpdateObjectData = {
  id: string;
  address: string;
  description: string;
  name: string;
  organizationId: string;
  contactId: string;
  cost: number;
};

export function* editDocumentCompanyObject(
  id: DocumentId,
  originalDocumentName: string
) {
  yield call(
    patchRequest,
    urlFromTemplate(ENDPOINTS.DOCUMENTS.UPDATE, {
      id,
    }),
    { originalDocumentName }
  );
}

export function* deleteDocumentCompanyObject(id: DocumentId) {
  yield call(
    deleteRequest,
    urlFromTemplate(ENDPOINTS.DOCUMENTS.DELETE, {
      id,
    })
  );
}

export function* getCompanyObjects(companyId: CompanyId) {
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.COMPANY_OBJECTS.OBJECTS.GETLIST, {
      id: companyId,
    })
  );
  return data as CompanyObject;
}

export function* createObject(params: CreateObjectData, companyId: CompanyId) {
  const { data } = yield call(
    postRequest,
    urlFromTemplate(ENDPOINTS.COMPANY_OBJECTS.OBJECTS.CREATE, {
      id: companyId,
    }),
    params
  );

  return data as CompanyObject;
}

export function* getCompanyObject(companyId: CompanyId) {
  const buildingId: CompanyObjectId = yield select(getCompanyObjectId);
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.COMPANY_OBJECTS.OBJECTS.GET, {
      companyId,
      id: buildingId,
    })
  );

  return data as CompanyObject;
}

export function* updateCompanyObject(
  companyId: CompanyId,
  id: CompanyObjectId,
  params: UpdateObjectData
) {
  const { data } = yield call(
    putRequest,
    urlFromTemplate(ENDPOINTS.COMPANY_OBJECTS.OBJECTS.UPDATE, {
      companyId,
      id,
    }),
    params
  );
  return data as Contact;
}

export function* archiveObject(companyId: CompanyId, id: CompanyObjectId) {
  yield call(
    patchRequest,
    urlFromTemplate(ENDPOINTS.COMPANY_OBJECTS.OBJECTS.ARCHIVE, {
      companyId,
      id,
    }),
    {}
  );
}

export function* updateStatus(
  companyId: CompanyId,
  id: CompanyObjectId,
  params: UpdateStatusObject
) {
  yield call(
    putRequest,
    urlFromTemplate(ENDPOINTS.COMPANY_OBJECTS.OBJECTS.UPDATESTAUS, {
      companyId,
      id,
    }),
    params
  );
}

export function* moveObject(companyId: CompanyId, params: MoveObject) {
  yield call(
    putRequest,
    urlFromTemplate(ENDPOINTS.COMPANY_OBJECTS.OBJECTS.MOVE, {
      companyId,
    }),
    params
  );
}
