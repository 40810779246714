import React, { FC, memo, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { ROOT_FOLDER_ID } from '@constants/folders';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useCompanyId } from '@hooks/useCompanyId';
import { ResourcesCatalogContext } from '@components/ResourcesCatalog';
import { getCompanyCurrency } from '@store/company/selectors';
import { ResourceType } from '@store/resources/slice';
import { getResourcesByFolder } from '@store/resources/selectors';
import { getUnits } from '@store/vocabulary/selectors';
import { fetchCompanyRequest } from '@store/company/actions';
import { EntityStatus } from '@store/constants';
import addResourceIcon from '@static/img/icon-add6.svg';
import { ResourcesCatalogResource } from '../Resource';
import './styles.sass';

type ResourcesCatalogResourceListType = {
  createResource: boolean;
  onCreateResourceClick: () => void;
  onCancelResourceEdit: () => void;
};

const ResourcesCatalogResourceListRaw: FC<ResourcesCatalogResourceListType> = ({
  createResource,
  onCreateResourceClick,
  onCancelResourceEdit,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const companyId = useCompanyId();
  const { folderId, size, selectedResources, onSelectResources } = useContext(
    ResourcesCatalogContext
  );
  const resources = useAppSelector((state) =>
    folderId ? getResourcesByFolder(state, folderId) : []
  );
  const companyCurrency = useAppSelector(getCompanyCurrency);
  const units = useAppSelector(getUnits);

  useEffect(() => {
    if (companyId !== undefined) {
      dispatch(fetchCompanyRequest({ id: companyId }));
    }
  }, [companyId, dispatch]);

  const createResourceHandler = () => {
    onCreateResourceClick();
  };

  if (folderId === ROOT_FOLDER_ID) {
    return null;
  }

  if (companyCurrency === undefined) {
    return null;
  }

  if (!createResource && resources.length === 0) {
    return (
      <div className={cn('manager__resources', size)}>
        <div className="manager__add-resource" onClick={createResourceHandler}>
          <img
            src={addResourceIcon}
            alt={t('directory.create-resource') ?? ''}
          />
          {t('directory.create-resource')}
        </div>
      </div>
    );
  }

  const allSelected = resources.every((resource) =>
    selectedResources.includes(resource.id)
  );

  const handleCheckboxClick = () => {
    onSelectResources(resources.map((resource) => resource.id));
  };

  return (
    <div className={cn('manager__resources', size)}>
      <div className="manager__resources-cap">
        <p>{t('directory.resource')}</p>
        <p>{t('directory.unit')}</p>
        <p>{t('directory.price')}</p>
        <p>{t('directory.markup')}</p>
        <p>{t('directory.for-customer')}</p>
        <span className="check" onClick={handleCheckboxClick}>
          <input
            type="checkbox"
            name="operation_all"
            value="1"
            checked={allSelected}
            readOnly
          />
          <label />
        </span>
      </div>
      <div className="manager__add-resource" onClick={createResourceHandler}>
        <img src={addResourceIcon} alt={t('directory.create-resource') ?? ''} />
        {t('directory.create-resource')}
      </div>
      {resources.map((resource) => (
        <ResourcesCatalogResource key={resource.id} resource={resource} />
      ))}
      {createResource && (
        <ResourcesCatalogResource
          create
          resource={{
            status: EntityStatus.New,
            id: '',
            resourceFolderId: folderId,
            name: '',
            type: ResourceType.MAT,
            price: 0,
            salePrice: 0,
            markup: 0,
            unitId: units[0].id,
            currency: companyCurrency,
          }}
          onCancel={onCancelResourceEdit}
        />
      )}
      {resources.length > 3 && (
        <div className="manager__add-resource" onClick={createResourceHandler}>
          <img
            src={addResourceIcon}
            alt={t('directory.create-resource') ?? ''}
          />
          {t('directory.create-resource')}
        </div>
      )}
    </div>
  );
};

export const ResourcesCatalogResourceList = memo(
  ResourcesCatalogResourceListRaw
);
