import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

export default function Table({ children }: PropsWithChildren) {
  const { t } = useTranslation();
  return (
    <div className="req__table">
      <div className="req__table-cap border">
        <p>{t('table.column-type')}</p>
        <p>{t('table.column-name')}</p>
        <p>{t('table.column-category')}</p>
        <p>{t('table.column-count')}</p>
        <p>{t('directory.unit')}</p>
        <p>{t('table.column-price-unit')}</p>
        <p>{t('table.column-summa')}</p>
        <p>{t('table.column-delivery')}</p>
      </div>
      {children}
    </div>
  );
}
