import React, { useRef } from 'react';
import cn from 'classnames';
import {
  CompanyObject,
  CompanyObjectId,
  ObjectStatus,
} from '@store/companyObjects/slice';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { getLatestAddedCompanyObjectId } from '@store/companyObjects/selectors';
import {
  setCurrentCompanyObjectId,
  updateObjectStatusRequest,
} from '@store/companyObjects/actions';
import { useNavigate } from 'react-router-dom';
import { getObjectUrl } from '@pages';
import addIcon from '@static/img/add__ObjectButton.svg';
import { CompanyOrganizations } from '@store/organizations/slice';
import { Contact } from '@store/contacts/slice';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { TaskInfo } from '../TaskInfo';

type TaskColumnProps = {
  active: boolean;
  companyObjects: CompanyObject[];
  priceCurrencyCode: string;
  onClick: (id: CompanyObjectId) => void;
  handleCreateButtonClick: () => void;
  phaseName: string;
};

export const TaskColumn: React.FC<TaskColumnProps> = ({
  active,
  companyObjects,
  priceCurrencyCode,
  handleCreateButtonClick,
  phaseName,
}) => {
  const { t } = useTranslation();
  const latestAddedCompanyObjectId = useAppSelector(
    getLatestAddedCompanyObjectId
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClickIDObject = (id: string) => {
    dispatch(setCurrentCompanyObjectId(id));
    navigate(getObjectUrl(id));
  };

  const ref = useRef<HTMLDivElement>(null);
  const [{ isOver }, dropRef] = useDrop({
    accept: ['cardObject'],
    drop: (item: Pick<CompanyObject, 'id' | 'status'>) => {
      const status = ref.current?.getAttribute('id');
      if (status && status !== item.status) {
        dispatch(
          updateObjectStatusRequest({
            status: status as ObjectStatus,
            id: item.id,
          })
        );
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  dropRef(ref);

  return (
    <div
      id={phaseName}
      ref={ref}
      className={cn('object__column', { active })}
      style={{ background: isOver ? '#F1F1F1' : 'none' }}
    >
      {companyObjects.map((companyObject) => {
        const isActiveBlink =
          companyObject.status === ObjectStatus.NEW &&
          companyObject.id === latestAddedCompanyObjectId;

        return (
          <TaskInfo
            key={companyObject.id}
            id={companyObject.id}
            name={companyObject.name}
            organization={companyObject.organization as CompanyOrganizations}
            contact={companyObject.contact as Contact}
            price={companyObject.cost}
            priceCurrencyCode={priceCurrencyCode}
            isActiveBlink={isActiveBlink}
            onClick={handleClickIDObject}
            status={companyObject.status}
          />
        );
      })}
      <div className="object__add" onClick={handleCreateButtonClick}>
        <i>
          <img src={addIcon} />
        </i>
        {t('object.add-an-object')}
      </div>
    </div>
  );
};
