import { createAction } from '@reduxjs/toolkit';
import { FetchStatus } from '@store/constants';
import { profileSlice, ProfileData } from '@store/profile/slice';
import { Language, LanguageId } from '@store/vocabulary/slice';

export type SaveProfileRequestData = {
  firstName: string;
  lastName: string;
  phone: string;
  languageId: LanguageId;
};

export type ChangePasswordRequestData = {
  currentPassword: string;
  newPassword: string;
  repeatNewPassword: string;
};

export type FetchProfileRequestAction = void;

export type FetchProfileSuccessAction = {
  data: ProfileData;
};

export type SaveProfileRequestAction = {
  data: SaveProfileRequestData;
  language: Language;
};

export type SaveProfileSuccessAction = {
  data: ProfileData;
};

export type UpdatePasswordProfileRequestAction = {
  data: ChangePasswordRequestData;
};

export type UpdatePasswordProfileSuccessAction = {
  status: FetchStatus;
};

export const saveProfileRequest = createAction<SaveProfileRequestAction>(
  'profile/saveProfileRequest'
);

export const updatePasswordProfileRequest =
  createAction<UpdatePasswordProfileRequestAction>(
    'profile/updatePasswordProfileRequest'
  );

export const {
  setPasswordProfileStatus,
  fetchProfileRequest,
  fetchProfileSuccess,
  saveProfileSuccess,
  setPhotoProfile,
} = profileSlice.actions;
