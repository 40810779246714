import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useFormErrors } from '@hooks/useFormErrors';
import { signOutRequest } from '@store/user/actions';
import { getLanguages } from '@store/vocabulary/selectors';
import { LanguageId } from '@store/vocabulary/slice';
import {
  fetchProfileRequest,
  saveProfileRequest,
  setPhotoProfile,
} from '@store/profile/actions';
import {
  getProfile,
  isProfileFetched,
  isProfileNotFetched,
} from '@store/profile/selectors';
import { Input } from '@components/Form/Input';
import { Select } from '@components/EditCompany/Select';
import { isAuthorized } from '@store/user/selectors';
import { resetErrorFields } from '@store/errors/actions';
import { phoneFormat } from '@utils/phoneFormat';
import { useLocalizedVocabulary } from '@hooks/useLocalizedVocabulary';
import { PHONE_PATTERN } from '@constants/patterns';
import { ReactComponent as IconRestore } from '@static/img/icon-deleteMin.svg';
import { Modal, ModalSize } from '@components/Modal';
import { ReactComponent as UploadIcon } from '@static/img/icon-upload.svg';
import Image from '@components/commons/Image';
import { setSizeImage } from '@utils/formatImage';
import InputFileUpload from '@components/commons/FieldFile';
import { ObjectModal } from '@pages/Dashboard/ObjectModal';
import { getError } from './utils';
import { EditPassword } from './EditPassword';

type EditProfileModalFormData = {
  firstName: string;
  // email: string;
  lastName: string;
  password: string;
  phone: string;
  post: string;
  languageId: LanguageId;
};

export const EditProfileModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const authorized = useAppSelector(isAuthorized);
  const profileNotFetched = useAppSelector(isProfileNotFetched);
  const profileFetched = useAppSelector(isProfileFetched);
  const profile = useAppSelector(getProfile);
  const languagesCollection = useAppSelector(getLanguages);
  const languages = useLocalizedVocabulary(languagesCollection, 'languages');
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors: formErrors },
  } = useForm<EditProfileModalFormData>();
  const errors = useFormErrors(formErrors);

  const [isViewChangePassword, setIsViewChangePassword] = useState(false);
  const handleViewChangePasswordClose = () => setIsViewChangePassword(false);

  const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = `+${event.target.value.replace(/[^\d- ()]/g, '')}`;

    setValue('phone', phoneFormat(value));
  };

  const saveProfileHandler = handleSubmit((data) => {
    const currentLanguage = languages.find(
      (language) => language.id === data.languageId
    );

    if (currentLanguage) {
      dispatch(resetErrorFields());
      dispatch(saveProfileRequest({ data, language: currentLanguage }));
    }
  });

  const onLogout = () => {
    dispatch(signOutRequest());
  };

  useEffect(() => {
    if (authorized && profileNotFetched) {
      dispatch(fetchProfileRequest());
    }
  }, [authorized, dispatch, profileNotFetched]);

  useEffect(() => {
    if (profileFetched) {
      reset({
        firstName: profile?.firstName,
        // email: profile?.email,
        lastName: profile?.lastName,
        password: profile?.password,
        post: profile?.post,
        phone: profile?.phone,
        languageId: profile?.language.id,
      });
    }
  }, [profileFetched, profile, reset]);

  const handleSetUploadFileUrl = (url: string) => {
    dispatch(setPhotoProfile({ filePath: url }));
  };

  return (
    <>
      <div className="modal__block height-full">
        <div className="modal__core">
          <div className="modal__close">{t('confirm.cancel')}</div>
          <form action="#" className="form" onSubmit={saveProfileHandler}>
            <div className="form__cap">
              <h4>{t('profile.profile-info')}</h4>
            </div>
            <div className="form__group">
              <InputFileUpload
                setUploadFileUrl={handleSetUploadFileUrl}
                partLoadFile="profileImage"
              >
                <div className="upload-image">
                  <input type="hidden" name="image" />
                  <i>
                    {profile?.filePath && (
                      <Image url={setSizeImage(profile?.filePath)} />
                    )}
                  </i>
                  <div>
                    <UploadIcon style={{ marginRight: '5px' }} />
                    {t('commons.upload-logo')}
                  </div>
                  <p>{t('commons.recommended-size-picture')}</p>
                </div>
              </InputFileUpload>
              <Input
                title={t('auth.lastname') ?? ''}
                {...register('lastName', {
                  required: true,
                })}
                error={getError('lastName', errors, t)}
              />
              <Input
                title={t('auth.firstname') ?? ''}
                {...register('firstName')}
                error={getError('firstName', errors, t)}
              />
              <Input
                title={t('auth.phone') ?? ''}
                {...register('phone', {
                  required: true,
                  pattern: PHONE_PATTERN,
                  onChange: handleChangePhone,
                })}
                error={getError('phone', errors, t)}
              />
              {
                // Пока что не требуется. В дальнейшем будет возможность смены email.
                /* <Input
                title={t('auth.email') ?? ''}
                {...register('email', {
                  required: true,
                  pattern: EMAIL_PATTERN,
                  minLength: 3,
                })}
                error={getError('email')}
              /> */
              }
              <Input
                title={t('auth.post') ?? ''}
                {...register('post')}
                error={getError('post', errors, t)}
              />
              <Select
                title={t('auth.language')}
                listItems={languages}
                selectedId={watch('languageId')}
                onSelect={(id) => {
                  setValue('languageId', id);
                }}
              />
            </div>
            <div className="form__group">
              <button type="submit" className="link">
                {t('profile.save')}
              </button>
              <a className="link transparent border" onClick={onLogout}>
                {t('profile.profile-exit')}
              </a>
              <div
                className="link min transparent"
                onClick={() => setIsViewChangePassword(!isViewChangePassword)}
              >
                <span>
                  <IconRestore className="svg" />
                  {t('auth.change-password')}
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Modal
        visible={isViewChangePassword}
        size={ModalSize.Auto}
        onBackgroundClick={handleViewChangePasswordClose}
      >
        <ObjectModal onClose={handleViewChangePasswordClose}>
          <EditPassword onClose={handleViewChangePasswordClose} />
        </ObjectModal>
      </Modal>
    </>
  );
};
