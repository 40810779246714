import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { getUnit } from '@store/vocabulary/selectors';
import { useTranslation } from 'react-i18next';
import { TypeBid, type Request } from '@store/requestSupplier/slice';
import { ReactComponent as IconPen } from '@static/img/icon-edit2.svg';
import { ReactComponent as IconAnalogue } from '@static/img/analogue.svg';
import {
  createResponseItem,
  updateResponseItem,
} from '@store/requestSupplier/actions';
import ItemReady from './ItemReady';

interface IItemProps {
  requestId: string;
  number: number;
  request: Request;
  part: keyof TypeBid;
  currencySymbolById: string | null | undefined;
}

export default function Item({
  requestId,
  request,
  number,
  currencySymbolById,
  part,
}: IItemProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const unitName = useAppSelector((state) => getUnit(state, request.unitId));

  const handleCreateResponse = () => {
    dispatch(
      createResponseItem({
        part,
        requestId,
        item: {
          resourceId: request.id,
          name: request.name,
          type: request.type,
          amount: request.amount,
          price: request.price,
          cost: request.cost,
          unitId: request.unitId,
          deliveryTime: null,
          categoryId: request.category.id,
        },
      })
    );
  };

  const handleBluerInput = (nameField: string, value: string) => {
    dispatch(
      updateResponseItem({
        part,
        requestId,
        item: {
          ...request.response,
          [nameField]: value,
        },
      })
    );
  };

  const handleSetDeliveryTime = (ms: number) => {
    if (request.response)
      dispatch(
        updateResponseItem({
          part,
          requestId,
          item: {
            ...request.response,
            deliveryTime: ms,
          },
        })
      );
  };

  return (
    <div className="req__item" style={{ padding: '10px' }}>
      <div className={cn('req__cot', { grey: !!request.response })}>
        <sub>{`# ${number}`}</sub>
        <s className="color-warning">{request.type}</s>
        <h4>{request.name}</h4>
        <small>{request.category.name}</small>
        <p>{request.amount}</p>
        <p>{t(`units.${unitName?.name}`)}</p>
        {!request.response ? (
          <a href="#" className="snap" onClick={handleCreateResponse}>
            {t('bid.give-response-position')}
          </a>
        ) : (
          <>
            {request.name === request.response?.name ? (
              <span className="green">
                {t('bid.specify-manually')}
                <IconPen style={{ marginTop: '5px', marginLeft: '5px' }} />
              </span>
            ) : (
              <span className="orange">
                {t('bid.specify-analogue')}
                <IconAnalogue style={{ marginTop: '5px', marginLeft: '5px' }} />
              </span>
            )}
          </>
        )}
      </div>
      {request.response && (
        <ItemReady
          categoryName={request.category.name}
          currencySymbolById={currencySymbolById}
          handleBluerInput={handleBluerInput}
          handleSetDeliveryTime={handleSetDeliveryTime}
          response={request.response}
          unitName={unitName?.name || ''}
          requestId={requestId}
        />
      )}
    </div>
  );
}
