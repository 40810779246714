import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Saga } from '@sagas/types';
import { handleError } from '@sagas/errors';
import {
  FetchResponseSupplierRequestAction,
  SelectResponseSupplierRequestAction,
  SelectSelfDeliveryRequestAction,
  fetchResponseSupplierRequest,
  fetchResponseSupplierSuccess,
  resetSelectResponseSupplierStatus,
  resetSelectSelfDeliveryStatus,
  selectResponseSupplierRequest,
  selectResponseSupplierRequestAction,
  selectResponseSupplierSuccess,
  selectSelfDeliveryRequest,
  selectSelfDeliveryRequestAction,
  selectSelfDeliverySuccess,
} from '@store/responseSupplier/actions';
import { addMessage } from '@store/errors/actions';
import { NoticeTypes } from '@utils/notifications';
import { fetchRequestCustomerObjectSuccess } from '@store/requestCustomer/actions';
import { fetchCostEstimateResourceRequest } from '@store/costEstimateResources/actions';
import { fetchCostEstimateOperationRequest } from '@store/costEstimateOperations/actions';
import { fetchCostEstimateSectionRequest } from '@store/costEstimateSections/actions';
import {
  getResponseSupplier,
  selectResponseSupplier,
  selectSelfDelivery,
} from './api/responseSupplier';
import { getRequestCustomer } from './api/requestCustomer';

const getResponseSupplierRequestHandler: Saga<FetchResponseSupplierRequestAction> =
  function* ({ payload }) {
    try {
      const data = yield call(getResponseSupplier, payload.resourceId);
      yield put(fetchResponseSupplierSuccess(data));
    } catch (e) {
      yield call(handleError, e);
    }
  };

const selectSelfDeliveryRequestHandler: Saga<SelectSelfDeliveryRequestAction> =
  function* ({ payload }) {
    try {
      yield put(selectSelfDeliveryRequest());
      const resourceObject = yield call(
        selectSelfDelivery,
        payload.resourceId,
        payload.requestId
      );
      yield put(selectSelfDeliverySuccess({ data: resourceObject }));
      yield put(
        addMessage({
          id: 'selectSelfDeliveryRequest',
          message: 'notice.you-have-successfully-confirmed-the-self-delivery',
          type: NoticeTypes.success,
        })
      );
      yield put(resetSelectSelfDeliveryStatus());

      if (payload.withUpdate === 'updateSupply') {
        const data = yield call(getRequestCustomer, payload.requestId);
        yield put(fetchRequestCustomerObjectSuccess(data));
      }

      if (payload.withUpdate === 'updateCostEstimate') {
        yield put(
          fetchCostEstimateResourceRequest({ resourceId: resourceObject.id })
        );
        yield put(
          fetchCostEstimateSectionRequest({
            sectionId: resourceObject.section.id,
          })
        );
        yield put(
          fetchCostEstimateOperationRequest({
            operationId: resourceObject.operationId,
          })
        );
      }
    } catch (e) {
      yield call(handleError, e);
    }
  };

const selectResponseSupplierRequestHandler: Saga<SelectResponseSupplierRequestAction> =
  function* ({ payload }) {
    try {
      yield put(selectResponseSupplierRequest());
      const resourceObject = yield call(
        selectResponseSupplier,
        payload.resourceId,
        payload.responseId
      );
      yield put(selectResponseSupplierSuccess({ data: resourceObject }));
      yield put(
        addMessage({
          id: 'selectResponseSupplier',
          message: 'notice.you-have-successfully-confirmed-the-supplier',
          type: NoticeTypes.success,
        })
      );
      yield put(resetSelectResponseSupplierStatus());

      if (payload.withUpdate === 'updateSupply') {
        const data = yield call(getRequestCustomer, payload.requestId);
        yield put(fetchRequestCustomerObjectSuccess(data));
      }

      if (payload.withUpdate === 'updateCostEstimate') {
        yield put(
          fetchCostEstimateResourceRequest({ resourceId: resourceObject.id })
        );
        yield put(
          fetchCostEstimateSectionRequest({
            sectionId: resourceObject.section.id,
          })
        );
        yield put(
          fetchCostEstimateOperationRequest({
            operationId: resourceObject.operationId,
          })
        );
      }
    } catch (e) {
      yield call(handleError, e);
    }
  };

export default function* root() {
  yield all([
    takeLatest(fetchResponseSupplierRequest, getResponseSupplierRequestHandler),
    takeLatest(
      selectSelfDeliveryRequestAction,
      selectSelfDeliveryRequestHandler
    ),
    takeLatest(
      selectResponseSupplierRequestAction,
      selectResponseSupplierRequestHandler
    ),
  ]);
}
