import React, { FC, memo, SyntheticEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ResourcesCatalogHeaderTitle } from '@components/ResourcesCatalog/Header/Title';
import {
  ResourcesCatalogContext,
  ResourcesCatalogSize,
} from '@components/ResourcesCatalog';
import addResourceIcon from '@static/img/icon-add6.svg';
import addFolderIcon from '@static/img/icon-add7.svg';
import { ROOT_FOLDER_ID } from '@constants/folders';
import { ResourcesCatalogBreadcrumbs } from './Breadcrumbs';

type ResourcesCatalogHeaderType = {
  onCreateResourceClick: () => void;
  onCreateFolderClick: () => void;
  onImportClick: () => void;
};

const ResourcesCatalogHeaderRaw: FC<ResourcesCatalogHeaderType> = ({
  onCreateResourceClick,
  onCreateFolderClick,
}) => {
  const { t } = useTranslation();
  const { size, folderId } = useContext(ResourcesCatalogContext);

  const createResourceHandler = (e: SyntheticEvent) => {
    e.preventDefault();
    onCreateResourceClick();
  };
  const createFolderHandler = (e: SyntheticEvent) => {
    e.preventDefault();
    onCreateFolderClick();
  };

  return (
    <div className="manager__cap">
      <ResourcesCatalogBreadcrumbs />
      {size !== ResourcesCatalogSize.Minimal && (
        <div className="manager__but">
          {folderId !== ROOT_FOLDER_ID && (
            <a href="#" onClick={createResourceHandler}>
              <img
                src={addResourceIcon}
                alt={t('directory.create-resource') ?? ''}
              />
              {t('directory.create-resource')}
            </a>
          )}
          <a href="#" onClick={createFolderHandler}>
            <img src={addFolderIcon} alt={t('directory.create-folder') ?? ''} />
            {t('directory.create-folder')}
          </a>
          {/* <a href="#" className="link min transparent" onClick={importHandler}><span><ImportIcon />{t('directory.import')}</span></a> */}
          {/* <form className="manager__search"> */}
          {/*  <input type="text" name="search" placeholder={t('directory.search')} /> */}
          {/* </form> */}
        </div>
      )}
      <ResourcesCatalogHeaderTitle />
    </div>
  );
};

export const ResourcesCatalogHeader = memo(ResourcesCatalogHeaderRaw);
