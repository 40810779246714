import Tabs from '@components/commons/Tabs';
import Toggle from '@components/commons/Toggle';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  setTab: Dispatch<SetStateAction<string>>;
  handleClickToggle: () => void;
};

const TabSupply = ({ setTab, handleClickToggle }: Props) => {
  const { t } = useTranslation();

  const [type, setType] = useState('requests');

  const tabs = [
    { title: t('supply.applications'), value: 'requests' },
    { title: t('supply.orders'), value: 'orders' },
  ];

  useEffect(() => {
    setTab(type);
  }, [setTab, type]);

  return (
    <>
      <div className="top desctop">
        <Tabs
          listItems={tabs}
          onChange={setType}
          activeTab={type}
          className="tab border"
        />
        {type === 'requests' && (
          <Toggle
            firstPosition={t('supply.by-sections')}
            secondPosition={t('supply.by-suppliers')}
            handleClickToggle={handleClickToggle}
          />
        )}
      </div>
      {/* <div className="top mobile">
        <h4>{t('supply.facility-supply')}</h4>
        <Toggle
          firstPosition={t('supply.by-sections')}
          secondPosition={t('supply.by-suppliers')}
          handleClickToggle={handleClickToggle}
        />
      </div> */}
    </>
  );
};

export default TabSupply;
