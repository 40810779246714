import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import {
  getCompanyObjectById,
  getCompanyObjectId,
} from '@store/companyObjects/selectors';
import { getCurrencies } from '@store/vocabulary/selectors';
import { getCompanyCurrencyCode } from '@store/company/selectors';
import {
  getCurrentCostEstimateId,
  getObjectCostEstimates,
  isObjectCostEstimatesFetching,
} from '@store/costEstimates/selectors';
import {
  clearCostEstimates,
  fetchObjectCostEstimatesRequest,
} from '@store/costEstimates/actions';
import { getProfileCompanyId } from '@store/profile/selectors';
import { fetchCostEstimateSectionsRequest } from '@store/costEstimateSections/actions';
import { fetchCostEstimateResourcesRequest } from '@store/costEstimateResources/actions';
import Loader from '@components/commons/Loader';
import { useSearchParams } from 'react-router-dom';
import { ObjectWithCostEstimates } from './ObjectWithCostEstimates';
import { NewObject } from './NewObject';
import { ObjectInfo } from './ObjectInfo';

export const AboutObjectObject = () => {
  const dispatch = useAppDispatch();
  const companyCurrencyName = useAppSelector(getCompanyCurrencyCode);
  const objectId = useAppSelector(getCompanyObjectId);
  const companyId = useAppSelector(getProfileCompanyId);
  const isCostEstimateLoading = useAppSelector(isObjectCostEstimatesFetching);

  const [searchParams] = useSearchParams();
  const costEstimateIdFromQuery = searchParams.get('costEstimateId');

  const objectCostEstimates = useAppSelector(getObjectCostEstimates);

  const costEstimateId = useAppSelector(getCurrentCostEstimateId);

  const currentObject = useAppSelector((state) =>
    getCompanyObjectById(state, objectId)
  );

  useEffect(() => {
    if (companyId !== undefined) {
      dispatch(fetchObjectCostEstimatesRequest({ companyId, id: objectId }));
    }
    return () => {
      dispatch(clearCostEstimates());
    };
  }, [companyId, objectId, dispatch]);

  useEffect(() => {
    if (companyId !== undefined && costEstimateIdFromQuery !== null) {
      dispatch(
        fetchCostEstimateSectionsRequest({
          companyId,
          objectId,
          costEstimateId: costEstimateIdFromQuery,
        })
      );
      dispatch(
        fetchCostEstimateResourcesRequest({
          companyId,
          objectId,
          costEstimateId: costEstimateIdFromQuery,
        })
      );
    }
  }, [companyId, objectId, costEstimateId, dispatch, costEstimateIdFromQuery]);

  const currencies = useAppSelector(getCurrencies);

  if (!currencies || !companyCurrencyName || !companyId || !currentObject) {
    return null;
  }

  const content =
    objectCostEstimates.length === 0 ? (
      <NewObject objectId={objectId} />
    ) : (
      <ObjectWithCostEstimates objectCostEstimates={objectCostEstimates} />
    );

  return (
    <div className="content__core">
      {isCostEstimateLoading ? (
        <Loader />
      ) : (
        <>
          {content}
          <div className="content__aside">
            {currencies.map((currency) => {
              if (currency.code === companyCurrencyName) {
                return (
                  <ObjectInfo
                    key={currency.id}
                    objectId={objectId}
                    companyId={companyId}
                    currentObject={currentObject}
                    currencyCode={currency.code}
                  />
                );
              }
              return null;
            })}
          </div>
        </>
      )}
    </div>
  );
};
