import { createAction } from '@reduxjs/toolkit';
import {
  CostEstimatesData,
  CreateCommercialProposalsData,
  ObjectCostEstimateData,
  UpdateCommercialProposalsData,
} from '@sagas/api/costEstimates';
import { CompanyId } from '@store/company/slice';
import { CompanyObjectId } from '@store/companyObjects/slice';
import {
  CommercialProposalsId,
  CompletelyFilledCommercialProposal,
  CostEstimate,
  CostEstimateId,
  costEstimatesSlice,
  HistoricalCostEstimateId,
} from '@store/costEstimates/slice';

export type FetchObjectCostEstimatesRequestAction = {
  companyId: CompanyId;
  id: CompanyObjectId;
};

export type FetchObjectCostEstimatesSuccessAction = {
  data: ObjectCostEstimateData[];
};

export type FetchCostEstimateRequestAction = {
  companyId: CompanyId;
  objectId: CompanyObjectId;
  id: CostEstimateId;
};
export type FetchCostEstimateSuccessAction = {
  data: CostEstimate;
};
export type CreateCostEstimateRequestAction = {
  data: CostEstimatesData;
  companyId: CompanyId;
  id: CompanyObjectId;
};
export type CreateCostEstimateSuccessAction = {
  data: CostEstimate;
};

export type CopyCostEstimateRequestAction = {
  companyId: CompanyId;
  objectId: CompanyObjectId;
  id: HistoricalCostEstimateId;
};

export type CopyEstimateRequestAction = {
  companyId: CompanyId;
  objectId: CompanyObjectId;
  id: CostEstimateId;
};

export type CopyCostEstimateSuccessAction = {
  data: ObjectCostEstimateData;
};

export type DeleteCostEstimateRequestAction = {
  companyId: CompanyId;
  objectId: CompanyObjectId;
  id: CostEstimateId;
};
export type DeleteCostEstimateSuccessAction = {
  id: CostEstimateId;
};

export type FetchProposalRequestAction = {
  companyId: CompanyId;
  objectId: CompanyObjectId;
  costEstimateId: CostEstimateId;
  id: CommercialProposalsId;
};
export type UpdateCommercialProposalRequestAction = {
  data: UpdateCommercialProposalsData;
  companyId: CompanyId;
  objectId: CompanyObjectId;
  costEstimateId: CostEstimateId;
  id: HistoricalCostEstimateId;
};
export type UpdateCommercialProposalSuccessAction = {
  data: UpdateCommercialProposalsData;
  costEstimateId: CostEstimateId;
  id: HistoricalCostEstimateId;
};

export type DeleteCommercialProposalRequestAction = {
  companyId: CompanyId;
  objectId: CompanyObjectId;
  commercialProposalId: CommercialProposalsId;
  id: HistoricalCostEstimateId;
};
export type DeleteCommercialProposalSuccessAction = {
  commercialProposalId: CommercialProposalsId;
};

export type CreateCommercialProposalRequestAction = {
  data: CreateCommercialProposalsData;
  objectId: CompanyObjectId;
  companyId: CompanyId;
  id: CostEstimateId;
};

export type CreateCommercialProposalSuccessAction = {
  data: CreateCommercialProposalsData;
  id: CostEstimateId;
};

export type FetchCompletelyFilledCommercialProposalRequestAction = {
  companyId: CompanyId;
  objectId: CompanyObjectId;
  id: HistoricalCostEstimateId;
};

export type FetchCompletelyFilledCommercialProposalSuccessAction = {
  data: CompletelyFilledCommercialProposal;
};

export const createCostEstimateRequest =
  createAction<CreateCostEstimateRequestAction>(
    'costEstimates/createCostEstimateRequest'
  );

// TODO: change type
export const copyCostEstimateRequest =
  createAction<CopyCostEstimateRequestAction>(
    'costEstimates/copyCostEstimateRequest'
  );

export const copyEstimateRequestAction =
  createAction<CopyEstimateRequestAction>('costEstimates/copyEstimateRequest');

export const fetchPreviousProposalRequest =
  createAction<FetchProposalRequestAction>(
    'costEstimates/fetchPreviousProposalRequest'
  );
export const fetchCompletelyFilledCommercialProposalRequest =
  createAction<FetchCompletelyFilledCommercialProposalRequestAction>(
    'costEstimates/fetchCompletelyFilledCommercialProposalRequest'
  );

export const updateCommercialProposalRequest =
  createAction<UpdateCommercialProposalRequestAction>(
    'costEstimates/updateCommercialProposalRequest'
  );
export const updateCommercialProposalSuccess =
  createAction<UpdateCommercialProposalSuccessAction>(
    'costEstimates/updateCommercialProposalSuccess'
  );

export const deleteCommercialProposalRequest =
  createAction<DeleteCommercialProposalRequestAction>(
    'costEstimates/deleteCommercialProposalRequest'
  );

export const {
  setErrorDeleteCostEstimateStatus,
  resetDeleteCostEstimateStatus,
  clearCostEstimates,
  deleteSelectedIds,
  clearSelectedIds,
  setSelectedId,
  setCurrentEstimate,
  setActiveCreateNewCostEstimate,
  resetCommercialProposalStatus,
  fetchObjectCostEstimatesRequest,
  fetchObjectCostEstimatesSuccess,
  fetchCostEstimateRequest,
  fetchCostEstimateSuccess,
  fetchCompletelyFilledCommercialProposalSuccess,
  clearCompletelyFilledCommercialProposal,
  createCostEstimateSuccess,
  copyCostEstimateSuccess,
  createCommercialProposalRequest,
  createCommercialProposalSuccess,
  deleteCommercialProposalSuccess,
  deleteCostEstimateRequest,
  deleteCostEstimateSuccess,
  copyEstimateSuccess,
} = costEstimatesSlice.actions;
