import { useAppSelector } from '@hooks/redux';
import { getUnits } from '@store/vocabulary/selectors';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useLocalizedUnits = () => {
  const { t } = useTranslation();
  const units = useAppSelector(getUnits);

  return useMemo(
    () =>
      units.map((unit) => {
        const translationNameKey = unit.name.replace('.', '');
        const translatedAliases = unit.aliases.map((alias) =>
          t(`units.${alias.replace('.', '')}`)
        );

        return {
          id: unit.id,
          name: t(`units.${translationNameKey}`),
          aliases: translatedAliases,
        };
      }),
    [t, units]
  );
};
