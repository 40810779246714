import { Modal, ModalSize } from '@components/Modal';
import { ObjectModal } from '@pages/Dashboard/ObjectModal';
import ChoiceModal from '@pages/Dashboard/ObjectModal/ChoiseModal';
import CreateContactForm from '@pages/Dashboard/ObjectModal/CreateContactForm';
import CreateOrganizationForm from '@pages/Dashboard/ObjectModal/CreateOrganizationForm';
import { useState } from 'react';

type ObjectButtonBlockProps = {
  type: 'contact' | 'organization';
  oneLogo: JSX.Element;
  secondLogo: JSX.Element;
  oneName: string;
  secondName: string;
  onSelect: (data: unknown) => void;
};

const ObjectButtonBlock = ({
  type,
  oneName,
  secondName,
  oneLogo,
  secondLogo,
  onSelect,
}: ObjectButtonBlockProps) => {
  const [isViewChoise, setIsViewChoise] = useState(false);
  const [isViewCreate, setIsViewCreate] = useState(false);

  const handleClickModalChoise = () => {
    setIsViewChoise(!isViewChoise);
  };

  const handleClickModalCreate = () => {
    setIsViewCreate(!isViewCreate);
  };

  const handleChoiseClose = () => setIsViewChoise(false);
  const handleCreateClose = () => setIsViewCreate(false);
  const onCreateFormOpen = () => setIsViewCreate(true);

  return (
    <>
      <div className="form__group">
        <span
          onClick={handleClickModalChoise}
          className="link white medium open-over-modal"
        >
          {oneLogo}
          {oneName}
        </span>
        <span
          onClick={handleClickModalCreate}
          className="link white medium open-over-modal"
        >
          {secondLogo}
          {secondName}
        </span>
      </div>
      <Modal
        visible={isViewChoise}
        size={ModalSize.Auto}
        onBackgroundClick={handleChoiseClose}
      >
        <ObjectModal onClose={handleChoiseClose}>
          <ChoiceModal
            viewModal={type}
            onClose={handleChoiseClose}
            onCreateFormOpen={onCreateFormOpen}
            onSelect={onSelect}
          />
        </ObjectModal>
      </Modal>
      <Modal
        visible={isViewCreate}
        size={ModalSize.Auto}
        onBackgroundClick={handleCreateClose}
      >
        <ObjectModal onClose={handleCreateClose}>
          {type === 'contact' ? (
            <CreateContactForm onClose={handleCreateClose} />
          ) : (
            <CreateOrganizationForm onClose={handleCreateClose} />
          )}
        </ObjectModal>
      </Modal>
    </>
  );
};

export default ObjectButtonBlock;
