import { TFunction } from 'i18next';
import { FieldValues } from 'react-hook-form';

const emailErrors = {
  minLength: 'auth.validation-email-short',
  pattern: 'auth.validation-email',
};

const phoneErrors = {
  pattern: 'auth.validation-phone-pattern',
};

const companyErrors = {
  minLength: 'auth.validation-url-short',
  pattern: 'auth.validation-url-pattern',
};

const passwordErrors = {
  minLength: 'auth.validation-password-short',
};

export const inputErrors = (
  name: string,
  errors: FieldValues,
  t: TFunction<'translation', undefined, 'translation'>
) => {
  const isEmail = name === 'email';
  const isPassword = name === 'password';
  const isPhone = name === 'phone';
  const isWebsite = name === 'website';

  // TODO: should be refactored
  switch (errors?.[name]?.type) {
    case 'required':
      return t('auth.validation-empty');
    case 'minLength':
      if (isEmail) {
        return t(emailErrors.minLength);
      }
      if (isPassword) {
        return t(passwordErrors.minLength);
      }
      if (isWebsite) {
        return t(companyErrors.minLength);
      }
      return '';
    case 'pattern':
      if (isEmail) {
        return t(emailErrors.pattern);
      }
      if (isPhone) {
        return t(phoneErrors.pattern);
      }
      if (isWebsite) {
        return t(companyErrors.pattern);
      }
      return '';
    case 'server':
      return errors[name]?.message;
    default:
      return '';
  }
};

export const textAreaErrors = (
  errors: FieldValues,
  name: string,
  t: TFunction<'translation', undefined, 'translation'>
) => {
  switch (errors?.[name]?.type) {
    case 'required':
      return t('auth.validation-empty');
    default:
      return '';
  }
};
