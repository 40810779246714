import { RootState } from '@store';
import { FetchStatus } from '@store/constants';
import { OperationId } from '@store/operations/slice';
import { OperationFolderId } from '@store/operationFolders/slice';

export const getRootState = (state: RootState) => state.operations;
export const getStatus = (state: RootState) => getRootState(state).status;
export const getOperations = (state: RootState) => getRootState(state).items;

export const isNotFetched = (state: RootState) =>
  getStatus(state) === FetchStatus.NotFetched;
export const isFetching = (state: RootState) =>
  getStatus(state) === FetchStatus.Fetching;
export const isFetched = (state: RootState) =>
  getStatus(state) === FetchStatus.Fetched;

export const getOperation = (state: RootState, id: OperationId) => {
  return getOperations(state).find((operation) => operation.id === id);
};

export const getOperationsByFolder = (
  state: RootState,
  folderId: OperationFolderId
) => {
  return getOperations(state).filter(
    (operation) => operation.operationFolderId === folderId
  );
};

export const getOperationsCountByFolder = (
  state: RootState,
  folderId: OperationFolderId
) => {
  return getOperationsByFolder(state, folderId).length;
};
