import React, { useEffect } from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { isAuthorized } from '@store/user/selectors';
import { useAppSelector } from '@hooks/redux';
import { getHomeUrl } from '@pages';
import { AuthForm } from './Form';
import { ChangeLanguage } from './ChangeLanguage';
import { AuthBackground } from './Background';
import { AuthLogo } from './Logo';
import styles from './styles.module.sass';

export const Auth = () => {
  const navigate = useNavigate();
  const authorized = useAppSelector(isAuthorized);

  useEffect(() => {
    if (authorized) {
      navigate(getHomeUrl(), { replace: true });
    }
  }, [authorized, navigate]);

  return (
    <section className="login">
      <AuthBackground />
      <div className={cn('login__block', styles.login_block)}>
        <div className={styles.wrapper}>
          <AuthLogo />
          <AuthForm />
        </div>
        <ChangeLanguage />
      </div>
    </section>
  );
};
