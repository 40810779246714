import {
  CSSProperties,
  ForwardedRef,
  ReactNode,
  forwardRef,
  useId,
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { nanoid } from '@reduxjs/toolkit';
import Loader from '../Loader';
import TableHeader from '../TableHeader';

export type Columns<T> = {
  className?: string;
  children: ReactNode;
  render?: (record: T, index: number) => ReactNode;
  rowClassName?: string;
};

type Props<T> = {
  columns: Columns<T>[];
  data: T[];
  onRowClick: (id: string, index: number) => void;
  onScroll: () => void;
  total: number;
  bodyStyles?: CSSProperties;
  rowKey: string;
  disableHeader?: boolean;
  classNameTable?: string;
  classNameTableItem?: string;
};

const Table = <T,>(
  {
    columns,
    data,
    onRowClick,
    onScroll,
    total,
    bodyStyles,
    rowKey,
    disableHeader,
    classNameTable,
    classNameTableItem,
  }: Props<T>,
  ref: ForwardedRef<InfiniteScroll>
) => {
  const getRowKey = (item: unknown, key: string) => {
    if (item && typeof item === 'object') {
      const newItem = item as Record<string, string>;
      return newItem[key];
    }
    return '';
  };

  const id = useId();

  return (
    <>
      {!disableHeader && <TableHeader columns={columns} />}
      <div style={bodyStyles} id={id}>
        <InfiniteScroll
          dataLength={data.length}
          next={onScroll}
          hasMore={data.length < total}
          loader={<Loader />}
          scrollableTarget={id}
          // height={bodyStyles?.height}
          ref={ref}
        >
          {data.map((item, rowIndex) => (
            <div
              className={classNameTable}
              onClick={() => onRowClick(getRowKey(item, rowKey), rowIndex)}
              key={nanoid()}
            >
              <span className={classNameTableItem}>
                {columns.map(({ rowClassName, render }) => (
                  <span className={rowClassName} key={nanoid()}>
                    {render && render(item, rowIndex)}
                  </span>
                ))}
              </span>
            </div>
          ))}
        </InfiniteScroll>
      </div>
    </>
  );
};

const TableWithVirtualScroll = forwardRef(Table) as <T>(
  props: Props<T> & { ref?: React.ForwardedRef<InfiniteScroll> }
) => ReturnType<typeof Table>;
export default TableWithVirtualScroll;
