import { useTranslation } from 'react-i18next';
import { ReactComponent as OptionIcon } from '@static/img/icon-sett.svg';
import { ReactComponent as CloseIcon } from '@static/img/icon-close.svg';
import { useState } from 'react';
import cn from 'classnames';
import styles from './styles.module.sass';

type HeaderProps = {
  company: string;
  reason: string;
  isModal: boolean;
  onClose?: () => void;
};

export default function Header({
  company,
  reason,
  isModal,
  onClose,
}: HeaderProps) {
  const [isViewOptions, setIsViewOptions] = useState(false);
  const { t } = useTranslation();
  const handleclickOptions = () => {
    setIsViewOptions(!isViewOptions);
  };
  return (
    <div className="chat__title">
      <h4
        className={cn('chat__title-name', styles.chatTitleNameText)}
        title={`${company}`}
      >
        {t('chat.chat-with')}
        {` ${company}`}
      </h4>
      <p className="chat__title-desc">{reason}</p>
      {!isModal && (
        <div
          className={cn('chat__title-setting drop', { active: isViewOptions })}
        >
          <button className="border snap drop-but" onClick={handleclickOptions}>
            <OptionIcon />
            {t('chat.options')}
          </button>
          <ul className="drop-box">
            <li>{t('chat.exit')}</li>
            <li>{t('chat.turn-off-notifications')}</li>
            <li>{t('chat.clear-history')}</li>
            <li>{t('chat.delete-all-user')}</li>
          </ul>
        </div>
      )}
      {isModal && (
        <div className={cn('chat__title-setting drop')}>
          <button onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
      )}

      <s className="chat__title-prev" />
    </div>
  );
}
