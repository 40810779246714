import { RootState } from '@store';
import { FetchStatus } from '@store/constants';

export const getRootState = (state: RootState) => state.requestCustomer;
export const createRequestCustomerStatus = (state: RootState) =>
  getRootState(state).createRequestCustomerStatus;
export const createOrderCustomerStatus = (state: RootState) =>
  getRootState(state).createOrderCustomerStatus;
export const getRequestCustomer = (state: RootState) =>
  getRootState(state).request;
export const getCurrencyCode = (state: RootState) =>
  getRootState(state).request?.currencyCode;
export const getSelectedIds = (state: RootState) =>
  getRootState(state).selectedIds;
export const deleteRequestCustomerItemStatus = (state: RootState) =>
  getRootState(state).deleteRequestCustomerItemStatus;

export const fetchRequestCustomerStatus = (state: RootState) =>
  getRootState(state).fetchRequestCustomerStatus;
export const isFetchRequestCustomerStatusFetching = (state: RootState) =>
  fetchRequestCustomerStatus(state) === FetchStatus.Fetching;
