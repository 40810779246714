import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import {
  getCompanyObjectById,
  getCompanyObjectId,
} from '@store/companyObjects/selectors';
import { getProfileCompanyId } from '@store/profile/selectors';
import { fetchCompanyRequest } from '@store/company/actions';
import { MainLayout } from '@layouts/MainLayout';
import { CreateButton } from '@components/Header/CreateButton';
import { OfferGeneratorModal } from '@components/OfferGeneratorModal';
import { fetchCompanyObjectsRequest } from '@store/companyObjects/actions';
import {
  getActiveCreateNewCostEstimate,
  getCurrentCostEstimate,
  getObjectCostEstimates,
} from '@store/costEstimates/selectors';

import { TogglePlanActual } from '@components/Toggle/planActual';
import { getHomeUrl } from '@pages';
import { setCurrentEstimate } from '@store/costEstimates/actions';
import { getCompanyDataForGenerateCp } from '@store/company/selectors';
import { AboutObject } from './AboutObject';

export const AboutObjectPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [generateOffer, setGenerateOffer] = useState(false);

  const [activeCreate, setActiveCreate] = useState(false);

  const companyId = useAppSelector(getProfileCompanyId);
  const objectId = useAppSelector(getCompanyObjectId);
  const currentCostEstimate = useAppSelector(getCurrentCostEstimate);
  const activeCreateNewCostEstimate = useAppSelector(
    getActiveCreateNewCostEstimate
  );
  const objectCostEstimates = useAppSelector(getObjectCostEstimates);

  const isValid = useAppSelector(getCompanyDataForGenerateCp);

  const currentObject = useAppSelector((state) =>
    getCompanyObjectById(state, objectId)
  );

  useEffect(() => {
    if (currentCostEstimate) {
      if (currentCostEstimate.saleCost === 0 || !isValid) {
        setActiveCreate(false);
      } else if (activeCreateNewCostEstimate) {
        setActiveCreate(false);
      } else {
        setActiveCreate(true);
      }
    }
  }, [currentCostEstimate, activeCreateNewCostEstimate, activeCreate, isValid]);

  useEffect(() => {
    if (companyId !== undefined && objectId !== undefined) {
      dispatch(fetchCompanyRequest({ id: companyId }));
      dispatch(fetchCompanyObjectsRequest({ id: companyId }));
    }
  }, [dispatch, companyId, objectId]);

  useEffect(() => {
    if (!objectCostEstimates.length) {
      setActiveCreate(false);
    }
  }, [dispatch, objectCostEstimates]);

  const handleCreateButtonClick = () => {
    if (activeCreate) {
      setGenerateOffer(true);
    }
  };

  const handleGenerateOfferCancel = () => {
    setGenerateOffer(false);
  };

  const handleBackClick = () => {
    navigate(getHomeUrl());
  };
  useEffect(() => {
    dispatch(setCurrentEstimate(null));

    return () => {
      dispatch(setCurrentEstimate(null));
    };
  }, [dispatch]);

  if (!currentObject) {
    return null;
  }

  const isCostEstimates = () => {
    return location.pathname.indexOf('/cost-estimates') !== -1;
  };

  return (
    <MainLayout
      title={currentObject.name}
      onBackClick={handleBackClick}
      buttons={
        <CreateButton
          title={t('about-object.generate-offer')}
          isOpacity={!activeCreate}
          onClick={handleCreateButtonClick}
        />
      }
      toggle={isCostEstimates() ? <TogglePlanActual /> : undefined}
    >
      <AboutObject />
      <OfferGeneratorModal
        visible={generateOffer}
        onCancel={handleGenerateOfferCancel}
      />
    </MainLayout>
  );
};
