import { useCallback, useState } from 'react';
import { getAccessToken } from '@store/user/selectors';
import { axiosInstance } from '@utils/axios';
import { useAppSelector } from './redux';
// TODO: загрузчиик 1 а старый код присылает filePath, пока проверка для безопастности, бэк поправит вернем
export type ResponseUplodFile = {
  fileName: string;
  filePath: string;
  id: string;
  originalFileName: string;
};
type Data = {
  data: ResponseUplodFile;
};
export const useUpload = () => {
  const [idFile, setIdFile] = useState<string | undefined>();

  const [urlFile, setUrlFile] = useState<string | undefined>();

  const [originalFileName, setOriginalFileName] = useState<
    string | undefined
  >();

  const [loading, setLoading] = useState(false);

  const accessToken = useAppSelector(getAccessToken);

  const loadFile = useCallback(
    async (file: Blob, endpoint: string) => {
      try {
        setLoading(true);

        const formData = new FormData();

        formData.append('file', file);
        const { data } = (await axiosInstance.post<ResponseUplodFile>(
          endpoint,
          formData,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )) as Data;

        setUrlFile(data.fileName || data.filePath);

        setIdFile(data.id);

        setOriginalFileName(data.originalFileName);

        setLoading(false);
      } catch (e) {
        setLoading(false);

        throw e;
      }
    },
    [accessToken]
  );

  return { loadFile, loading, urlFile, idFile, originalFileName };
};
