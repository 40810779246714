import { Select } from '@components/commons/Select';
import { useAppDispatch } from '@hooks/redux';
import { updateObjectStatusRequest } from '@store/companyObjects/actions';
import { CompanyObject, ObjectStatus } from '@store/companyObjects/slice';
import { useTranslation } from 'react-i18next';

type StatusObjectProps = {
  currentObject: CompanyObject;
};

export default function ObjectStatusUpdate({
  currentObject,
}: StatusObjectProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const statuses = [
    { title: t('object.new-application'), value: ObjectStatus.NEW },
    {
      title: t('object.cost-estimate-preparation'),
      value: ObjectStatus.PREPARE,
    },
    {
      title: t('object.commercial-proposal-approval'),
      value: ObjectStatus.AGREEMENT,
    },

    { title: t('object.in-work'), value: ObjectStatus.IN_WORK },
  ];

  const currentValue = statuses.find((i) => i.value === currentObject.status);

  const handleChoiseStatus = (value: unknown) => {
    dispatch(
      updateObjectStatusRequest({
        id: currentObject.id,
        status: value as ObjectStatus,
      })
    );
  };

  return (
    <>
      <Select
        choise={(status) => handleChoiseStatus(status)}
        items={statuses}
        currentValue={(currentValue && currentValue.title) || ''}
      />
    </>
  );
}
