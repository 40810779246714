import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EntityStatus, FetchStatus } from '@store/constants';
import { refreshTokenError, signOutSuccess } from '@store/user/actions';
import {
  FetchResourceFoldersRequestAction,
  FetchResourceFoldersSuccessAction,
  CreateResourceFolderRequestAction,
  CreateResourceFolderSuccessAction,
  CreateResourceFolderErrorAction,
  RenameResourceFolderRequestAction,
  DeleteResourceFolderRequestAction,
} from '@store/resourceFolders/actions';

export type ResourceFolderId = string;

export type ResourceFolder = {
  status: EntityStatus;
  id: ResourceFolderId;
  name: string;
  path: string;
  level: number;
};

export type ResourceFoldersState = {
  status: FetchStatus;
  items: ResourceFolder[];
};

const initialState: ResourceFoldersState = {
  status: FetchStatus.NotFetched,
  items: [],
};

export const resourceFoldersSlice = createSlice({
  name: 'resourceFolders',
  initialState,
  reducers: {
    fetchResourceFoldersRequest: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<FetchResourceFoldersRequestAction>
    ) => {
      state.status = FetchStatus.Fetching;
    },
    fetchResourceFoldersSuccess: (
      state,
      action: PayloadAction<FetchResourceFoldersSuccessAction>
    ) => {
      const { data } = action.payload;

      state.status = FetchStatus.Fetched;
      state.items = [
        ...state.items.filter((item) => item.status !== EntityStatus.Fetched),
        ...data.map((d) => ({ ...d, status: EntityStatus.Fetched })),
      ];
    },
    createResourceFolderRequest: (
      state,
      action: PayloadAction<CreateResourceFolderRequestAction>
    ) => {
      const { data, temporaryId } = action.payload;

      state.items.push({
        ...data,
        id: temporaryId,
        status: EntityStatus.Fetching,
      });
    },
    createResourceFolderSuccess: (
      state,
      action: PayloadAction<CreateResourceFolderSuccessAction>
    ) => {
      const { data, temporaryId } = action.payload;
      const index = state.items.findIndex((item) => item.id === temporaryId);

      state.items[index] = {
        ...data,
        status: EntityStatus.Fetched,
      };
    },
    createResourceFolderError: (
      state,
      action: PayloadAction<CreateResourceFolderErrorAction>
    ) => {
      const { temporaryId } = action.payload;

      state.items = state.items.filter((item) => item.id !== temporaryId);
    },
    renameResourceFolderRequest: (
      state,
      action: PayloadAction<RenameResourceFolderRequestAction>
    ) => {
      const { id, data } = action.payload;
      const index = state.items.findIndex((item) => item.id === id);

      state.items[index].name = data.name;
    },
    deleteResourceFolderRequest: (
      state,
      action: PayloadAction<DeleteResourceFolderRequestAction>
    ) => {
      const { id } = action.payload;

      state.items = state.items.filter((item) => item.id !== id);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signOutSuccess, () => initialState);
    builder.addCase(refreshTokenError, () => initialState);
  },
});

export default resourceFoldersSlice.reducer;
