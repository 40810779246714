import { ButtonHTMLAttributes } from 'react';
import cn from 'classnames';

export const SecondaryButton = ({
  className,
  ...restProps
}: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      className={cn('link transparent border', className)}
      {...restProps}
    />
  );
};
