import { FC } from 'react';
import cn from 'classnames';
import offerImage from '@static/img/KPbacground.jpg';

import { ReactComponent as IconProfile } from '@static/img/profile5.svg';
import { ReactComponent as IconDoc } from '@static/img/document.svg';
// import { ReactComponent as IconEdit } from '@static/img/edit.svg';
import { ReactComponent as IconCheck } from '@static/img/icon-check8.svg';

import { useTranslation } from 'react-i18next';

import { useCurrentLocaleDate } from '@hooks/useCurrentLocaleDate';
import { CommercialProposal } from '@store/costEstimates/slice';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { updateCommercialProposalRequest } from '@store/costEstimates/actions';
import { getCompanyObjectId } from '@store/companyObjects/selectors';
import { getProfile, getProfileCompanyId } from '@store/profile/selectors';
import {
  getCurrentCostEstimate,
  getCurrentCostEstimateId,
  getObjectCostEstimates,
} from '@store/costEstimates/selectors';

import { RadialPrimaryButton } from '@components/commons/RadialPrimaryButton';
import { getAccessToken } from '@store/user/selectors';
import { axiosInstance } from '@utils/axios';
import styles from './styles.module.sass';

export type IterationItemProps = {
  isApproved: boolean;
  creationDate: number;
  url: string;
  commercialProposal: CommercialProposal;
  onApproved: (obj: CommercialProposal | null) => void;
};

export const IterationItem: FC<IterationItemProps> = ({
  isApproved,
  creationDate,
  url,
  commercialProposal,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const companyId = useAppSelector(getProfileCompanyId);
  const objectId = useAppSelector(getCompanyObjectId);
  const costEstimateId = useAppSelector(getCurrentCostEstimateId);
  const objectCostEstimates = useAppSelector(getObjectCostEstimates);
  const currentLocale = useCurrentLocaleDate(creationDate);
  const userName = useAppSelector(getProfile);
  const currentCostEstimate = useAppSelector(getCurrentCostEstimate);
  const accessToken = useAppSelector(getAccessToken);

  const countIndex =
    objectCostEstimates.findIndex(
      (item) => item?.id === currentCostEstimate?.id
    ) + 1;

  const urlDownloadPDF = `/company/${companyId}/building-object/${objectId}/cost-estimate/${costEstimateId}/presentation`;
  const namePDF = `${t(
    'about-object.commercial-offer-on-the-estimate'
  )} №${countIndex}.pdf`;

  const handleClickApproveButton = () => {
    const historicalCostEstimateId = commercialProposal.costEstimateId;

    const data = { approved: !isApproved };

    if (companyId !== undefined && costEstimateId !== undefined) {
      dispatch(
        updateCommercialProposalRequest({
          data,
          companyId,
          objectId,
          costEstimateId,
          id: historicalCostEstimateId,
        })
      );
    }
  };

  const handleClickDownloadPDF = () => {
    axiosInstance
      .get(urlDownloadPDF, {
        headers: { Authorization: `Bearer ${accessToken}` },
        responseType: 'blob',
      })
      .then((response) => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', namePDF);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };

  const handleClickLookButton = () => {
    window.open(url);
  };

  return (
    <div className="obj__tender">
      <div className="obj__tender-img">
        <img src={offerImage} alt={offerImage} />
      </div>
      <div className="obj__tender-block">
        <h4 className="obj__tender-name">
          {t('about-object.commercial-offer-on-the-estimate')} № {countIndex}
        </h4>
        <ul className="obj__tender-info">
          <li>
            <IconDoc
              style={{
                height: '12px',
                width: '12px',
                marginRight: '8px',
                marginTop: '4px',
              }}
            />
            <time>{t('about-object.date', { string: currentLocale })}</time>
          </li>
          {
            // Пока что неизвестно нужно это или нет.
            /* <li>
            <IconEdit
              style={{
                height: '12px',
                width: '12px',
                marginRight: '8px',
                marginTop: '4px',
              }}
            />
            Изменено 2 часа назад
          </li> */
          }
          <li>
            <IconProfile
              style={{
                height: '18px',
                width: '18px',
                marginRight: '8px',
                marginTop: '2px',
                stroke: 'white',
              }}
            />
            {userName?.firstName}
          </li>
        </ul>
        <ul className="obj__tender-but">
          <RadialPrimaryButton onClick={handleClickLookButton}>
            {t('about-object.quick-view')}
          </RadialPrimaryButton>
          <RadialPrimaryButton
            className="white border"
            onClick={handleClickDownloadPDF}
          >
            {t('about-object.download-the-offer')}
          </RadialPrimaryButton>
          <RadialPrimaryButton
            className={cn('white border', isApproved && styles.success)}
            icon={<IconCheck />}
            onClick={handleClickApproveButton}
            style={{
              background: isApproved ? '#67C15E' : '',
              border: isApproved ? 'none' : '',
            }}
          >
            {isApproved
              ? t('about-object.approved')
              : t('about-object.approve')}
          </RadialPrimaryButton>
        </ul>
      </div>
    </div>
  );
};
