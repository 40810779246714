import React, { useState } from 'react';
import { Navbar } from '@components/Navbar';
import { Header } from '@components/Header';

export type MainLayoutProps = {
  title: string;
  hasBackForMobile?: boolean;
  buttons?: JSX.Element;
  toggle?: JSX.Element;
  onBackClick?: () => void;
  children: React.ReactNode;
};

export const MainLayout: React.FC<MainLayoutProps> = ({
  title,
  hasBackForMobile,
  buttons,
  toggle,
  onBackClick,
  children,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleHumbClick = () => {
    setMenuOpen((prevState) => !prevState);
  };

  const handleNavbarClose = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <Navbar open={menuOpen} onClose={handleNavbarClose} />

      <Header
        title={title}
        hasBackForMobile={hasBackForMobile}
        buttons={buttons}
        toggle={toggle}
        onBackClick={onBackClick}
        onHumbClick={handleHumbClick}
      />
      <section className="content">{children}</section>
    </>
  );
};
