import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Saga } from '@sagas/types';
import { handleError } from '@sagas/errors';
import {
  CreateContactRequestAction,
  DeleteContactRequestAction,
  FetchContactsRequestAction,
  UpdateContactRequestAction,
  createContactRequest,
  createContactSuccess,
  deleteContactRequest,
  deleteContactSuccess,
  fetchContactsObjectRequest,
  fetchContactsObjectSuccess,
  fetchFullContactsObjectRequest,
  fetchFullContactsObjectSuccess,
  resetCreateContactStatus,
  resetUpdateContactStatus,
  setActiveBlinkWhenCreatedNewContact,
  setCurrentCompanyObjectId,
  updateContactRequest,
  updateContactSuccess,
} from '@store/contacts/actions';
import {
  createContact,
  deleteContact,
  getCompanyContactsObjects,
  getFullCompanyContactsObjects,
  updateContact,
} from './api/contacts';

const createContactRequestHandler: Saga<CreateContactRequestAction> =
  function* ({ payload }) {
    const { companyId } = payload;
    try {
      const object = yield call(createContact, payload.data, companyId);
      yield put(createContactSuccess({ data: object }));
      yield put(setActiveBlinkWhenCreatedNewContact(true));
      yield put(setCurrentCompanyObjectId(object.id));
    } catch (e) {
      yield call(handleError, e);
      yield put(resetCreateContactStatus());
    }
  };

const getContactsRequestHandler: Saga<FetchContactsRequestAction> = function* ({
  payload,
}) {
  try {
    const { items } = yield call(getCompanyContactsObjects, payload.id);
    yield put(fetchContactsObjectSuccess(items));
  } catch (e) {
    yield call(handleError, e);
  }
};

const getFullContactsRequestHandler: Saga<FetchContactsRequestAction> =
  function* ({ payload }) {
    try {
      const { data } = yield call(getFullCompanyContactsObjects, payload.id, {
        max: payload.max,
        page: payload.page,
      });
      yield put(
        fetchFullContactsObjectSuccess({
          response: data,
          isUpdatePage: payload.isUpdatePage,
        })
      );
    } catch (e) {
      yield call(handleError, e);
    }
  };

const updateContactRequestHandler: Saga<UpdateContactRequestAction> =
  function* ({ payload }) {
    try {
      const { companyId, contactId } = payload;
      const newData = yield call(
        updateContact,
        companyId,
        contactId,
        payload.data
      );
      yield put(updateContactSuccess(newData));
    } catch (e) {
      yield call(handleError, e);
      yield put(resetUpdateContactStatus());
    }
  };

const deleteContactRequestHandler: Saga<DeleteContactRequestAction> =
  function* ({ payload }) {
    const { companyId, id } = payload;

    try {
      yield call(deleteContact, companyId, id);
      yield put(deleteContactSuccess({ id }));
      // const { data } = yield call(getFullCompanyContactsObjects, companyId);
      // yield put(fetchFullContactsObjectSuccess(data));
    } catch (e) {
      yield call(handleError, e);
    }
  };

export default function* root() {
  yield all([
    takeLatest(createContactRequest, createContactRequestHandler),
    takeLatest(fetchContactsObjectRequest, getContactsRequestHandler),
    takeLatest(fetchFullContactsObjectRequest, getFullContactsRequestHandler),
    takeLatest(updateContactRequest, updateContactRequestHandler),
    takeLatest(deleteContactRequest, deleteContactRequestHandler),
  ]);
}
