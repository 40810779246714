import { ReactComponent as IconAdd } from '@static/img/icon-add.svg';
import { ReactComponent as IconArrow } from '@static/img/icon-downSection.svg';
import InputFileUpload from '@components/commons/FieldFile';
import { t } from 'i18next';
import IconButton from '@components/commons/IconButton';
import { useState } from 'react';
import { DocumentsType } from '@store/documents/slice';
import ItemDocument from './ItemDocument';
import styles from './styles.module.sass';

type Props = {
  docsForRender: DocumentsType[];
  onSuccessUpload: () => void;
  params: { [key: string]: string | number };
  handleDeleteDocument: (id: string) => void;
  handleEditDocument: (id: string, originalDocumentName: string) => void;
  title: string;
};

const Documents = ({
  title,
  docsForRender,
  params,
  onSuccessUpload,
  handleDeleteDocument,
  handleEditDocument,
}: Props) => {
  const [isDocumentsShow, setIsDocumentsShow] = useState(false);

  const documentsShow = () => {
    const documentsCopy = [...docsForRender];
    const documentsSplice = documentsCopy.splice(-4, 4);
    return (
      <div className="obj__doc-all" style={{ margin: '0' }}>
        {documentsSplice.map((e) => (
          <ItemDocument
            key={e.id}
            fileName={e.fileName}
            originalFileName={e.originalFileName}
            id={e.id}
            handleDeleteDocument={handleDeleteDocument}
            handleEditDocument={handleEditDocument}
          />
        ))}

        <IconButton
          onClick={() => setIsDocumentsShow(!isDocumentsShow)}
          className={styles.buttonShow}
        >
          <span>{`Показать еще ${documentsCopy.length}`}</span>
          <IconArrow />
        </IconButton>
      </div>
    );
  };

  return (
    <div className="obj__doc-cat">
      <div className="obj__doc-title">
        <div className="obj__doc-name">{t(title)}</div>
        <div className="obj__doc-add">
          <InputFileUpload
            params={params}
            setUploadFileUrl={onSuccessUpload}
            partLoadFile="document"
            stylesLabel
          >
            <IconAdd className={styles.cursorPointer} />
          </InputFileUpload>
        </div>
      </div>
      <div className="obj__doc-block">
        {docsForRender.length > 4 && !isDocumentsShow
          ? documentsShow()
          : docsForRender.map((e) => (
              <ItemDocument
                key={e.id}
                fileName={e.fileName}
                originalFileName={e.originalFileName}
                id={e.id}
                handleDeleteDocument={handleDeleteDocument}
                handleEditDocument={handleEditDocument}
              />
            ))}
      </div>
    </div>
  );
};

export default Documents;
