import { StatusOrder } from '@utils/enums';
import { useAppSelector } from '@hooks/redux';
import {
  getOrdersByStatus,
  getOrdersStatusByType,
} from '@store/orderSupplier/selectors';
import { toValidDataTime } from '@components/commons/Calendar';
import { FetchStatus } from '@store/constants';
import Loader from '@components/commons/Loader';
import ColumnOrderItemInfo from '../ColumnOrderItemInfo';

interface IColumnsData {
  showDetails: () => void;
  status: keyof typeof StatusOrder;
}

export default function ColumnsOrdersData({
  showDetails,
  status,
}: IColumnsData) {
  const currentStatusOrder = useAppSelector((state) =>
    getOrdersByStatus(state, status)
  );

  const statuscolumn = useAppSelector((state) =>
    getOrdersStatusByType(state, status)
  );

  return (
    <div className="object__column">
      {statuscolumn === FetchStatus.Fetched ? (
        <>
          {currentStatusOrder.map((e) => {
            return (
              <ColumnOrderItemInfo
                showDetails={showDetails}
                number={e.number}
                key={e.id}
                orderId={e.id}
                createdAt={e.createdAt}
                date={toValidDataTime(e.deliveryTime)}
                name={e.buildingObject.name}
                status={status}
                attention={e.needInvoice}
              />
            );
          })}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}
