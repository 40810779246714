import Calendar, { toValidDataTime } from '@components/commons/Calendar';
import TimerBar, { TimeUnit } from '@components/commons/Timer';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { addMessage } from '@store/errors/actions';
import { FetchResponseRequestAction } from '@store/requestSupplier/actions';
import { RequestBasicData } from '@store/requestSupplier/slice';
import { StatusRequest } from '@utils/enums';
import { NoticeTypes } from '@utils/notifications';
import { CSSProperties, useState } from 'react';
import { useTranslation } from 'react-i18next';
import lodingLogoLigth from '@static/img/logo4.svg';
import { fetchIsChekConversationRequestAction } from '@store/chat/actions';
import { getSupplier, getSupplierId } from '@store/supplier/selectors';
import { useNavigate } from 'react-router-dom';
import { getMessengerUrl } from '@pages';
import { currencyFormat } from '@utils/currency';
import { useCurrentLocale } from '@hooks/useCurrentLocale';
import sass from './style.module.sass';

type HeaderProps = {
  requestBasicData: Pick<
    RequestBasicData,
    | 'allResponsesCost'
    | 'number'
    | 'updatedAt'
    | 'company'
    | 'validity'
    | 'loadStatus'
  >;
  requestId: string;
  status: keyof typeof StatusRequest;
};

const styleButton: CSSProperties = {
  color: 'transparent',
};

export default function Header({
  requestBasicData,
  requestId,
  status,
}: HeaderProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const locale = useCurrentLocale();
  const currencyCode = useAppSelector(getSupplier)?.currency.code;

  const { allResponsesCost, number, updatedAt, company } = requestBasicData;
  const [curretDateOffer, setCurretDateOffer] = useState(
    requestBasicData.validity || Date.now() + 86400000 * 3
  );

  const supplierId = useAppSelector(getSupplierId);
  const handleSendResponse = () => {
    dispatch(
      FetchResponseRequestAction({
        requestId,
        validity: toValidDataTime(curretDateOffer),
      })
    );
  };

  const handleOpenChat = () => {
    if (supplierId) {
      dispatch(fetchIsChekConversationRequestAction({ requestId, supplierId }));
      navigate(getMessengerUrl());
    } else {
      dispatch(
        addMessage({
          id: 'not-actual-data',
          message: t('errors.oops-something-went-wrong'),
          type: NoticeTypes.error,
        })
      );
    }
  };

  const leftDateDays = Math.ceil(
    (toValidDataTime(curretDateOffer) - Date.now()) / TimeUnit.day
  );

  const leftDateHours = Math.ceil(
    // время на ответ по заявке в часах при необходимиости ее изменения вынесем в настройки покупателя пока так
    24 +
      (toValidDataTime(updatedAt) / TimeUnit.hour - Date.now() / TimeUnit.hour)
  );

  const loading = requestBasicData.loadStatus === 1;

  return (
    <>
      <div className="req__cap">
        <h2>{`${t('bid.request')} №${number} ${t('bid.from')} ${
          company.name
        }`}</h2>
        <div className="req__button">
          <div className="req__data">
            <p>
              <Calendar
                popup
                date={toValidDataTime(curretDateOffer)}
                setDate={setCurretDateOffer}
              />
            </p>
            <small> {t('bid.current-offer')}</small>
          </div>
          {status === 'NEW' && (
            <button
              className="link"
              onClick={handleSendResponse}
              style={loading ? styleButton : {}}
            >
              {loading && (
                <div className={sass.preloader}>
                  <img src={lodingLogoLigth} alt="" />
                </div>
              )}
              {t('bid.send-offer')}
            </button>
          )}

          {status === 'PROCESSED' && (
            <button
              className="link"
              onClick={handleSendResponse}
              style={loading ? styleButton : {}}
            >
              {loading && (
                <div className={sass.preloader}>
                  <img src={lodingLogoLigth} alt="" />
                </div>
              )}

              {t('bid.send-offer-update')}
            </button>
          )}
          {status === 'SELECTED' && (
            <button className="link" onClick={handleOpenChat}>
              {t('bid.chat-customer')}
            </button>
          )}
        </div>
      </div>
      <div className="req__info">
        <div>
          <p>{t('bid.data-bid')}</p>
          <span>
            <Calendar short date={updatedAt} />
          </span>
        </div>
        <div>
          <p>{t('bid.deadline-response')}</p>

          <span>
            <i>
              <TimerBar
                dateСreate={updatedAt}
                notVisibleTime
                timer={leftDateDays}
                unit={TimeUnit.day}
              />
            </i>
            {t('commons.time-left')}
            {status !== 'NEW' && ` ${leftDateDays} ${t('commons.time-day')}`}
            {status === 'NEW' && ` ${leftDateHours} ${t('commons.time-hour')}`}
          </span>
        </div>
        {/* TODO: пока не понятно какая тут должна быть дата  */}
        {/* <div>
          <p>{t('bid.deadline-delivery')}</p>
          <span>18 окт 2022</span>
        </div> */}
        <div>
          <p>{t('bid.total-request')}</p>
          <span>
            {currencyCode !== undefined
              ? currencyFormat(allResponsesCost, currencyCode, locale)
              : allResponsesCost}
          </span>
        </div>
      </div>
    </>
  );
}
