import { RootState } from '@store';
import { FetchStatus } from '@store/constants';
import { CompanyObjectId } from './slice';

export const getRootState = (state: RootState) => state.companyObjects;
export const getStatus = (state: RootState) => getRootState(state).status;
export const getCreateObjectStatus = (state: RootState) =>
  getRootState(state).createObjectStatus;
export const getEditObjectStatus = (state: RootState) =>
  getRootState(state).editObjectStatus;
export const getCompanyObjects = (state: RootState) =>
  getRootState(state).companyObjects;
export const getCurrentCompanyObject = (state: RootState) =>
  getRootState(state).currentCompanyObject;
export const getCompanyObjectId = (state: RootState) =>
  getRootState(state).currentCompanyObjectId;
export const getActiveBlinkWhenCreatedNewObject = (state: RootState) =>
  getRootState(state).activeBlinkWhenCreatedNewObject;
export const getLatestAddedCompanyObjectId = (state: RootState) =>
  getRootState(state).latestAddedCompanyObjectId;
export const getArchiveObjectStatus = (state: RootState) =>
  getRootState(state).archiveObjectStatus;

export const getCompanyObjectById = (state: RootState, id: CompanyObjectId) => {
  if (id === undefined) {
    return undefined;
  }
  return getCompanyObjects(state).filter(
    (companyObject) => companyObject.id === id
  )[0];
};

export const getAllCompanyObjectsChoisedContact = (
  state: RootState,
  id: string
) => {
  if (id === undefined) {
    return undefined;
  }
  return getCompanyObjects(state).filter((e) => e.contact?.id === id);
};

export const getAllCompanyObjectsChoisedOrganization = (
  state: RootState,
  id: string
) => {
  if (id === undefined) {
    return undefined;
  }
  return getCompanyObjects(state).filter((e) => e.organization?.id === id);
};

export const isCreateObjectNotFetched = (state: RootState) =>
  getCreateObjectStatus(state) === FetchStatus.NotFetched;
export const isCreateObjectFetching = (state: RootState) =>
  getCreateObjectStatus(state) === FetchStatus.Fetching;
export const isCreateObjectFetched = (state: RootState) =>
  getCreateObjectStatus(state) === FetchStatus.Fetched;

export const isCompanyNotFetched = (state: RootState) =>
  getStatus(state) === FetchStatus.NotFetched;
export const isCompanyFetching = (state: RootState) =>
  getStatus(state) === FetchStatus.Fetching;
export const isCompanyFetched = (state: RootState) =>
  getStatus(state) === FetchStatus.Fetched;

export const isViewObjectFunnel = (state: RootState) =>
  state.companyObjects.typeView;
