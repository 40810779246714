import { all, call, takeLatest, put, takeEvery } from 'redux-saga/effects';
import { Saga } from '@sagas/types';
import {
  FetchCreateChatFromOrder,
  FetchCreateChatFromRequest,
  fetchConversationFromOrderRequestAction,
  fetchConversationFromRequestRequestAction,
  setActiveConversation,
  fetchConversationsRequestAction,
  fetchConversationsRequestActionSuccess,
  fetchCreateConversationFromOrderRequestAction,
  fetchCreateConversationFromRequestRequestAction,
  fetchIsChekConversationOrderAction,
  fetchIsChekConversationRequestAction,
  fetchSendMessageRequestAction,
  FetchSendMessage,
  fetchSendMessageRequestActionSuccess,
  fetchMessagesConversationIdRequestAction,
  FetchMessages,
  fetchMessagesConversationIdRequestActionSuccess,
  fetchNumberOfConversationsWithUnreadedRequestAction,
  fetchNumberOfConversationsWithUnreadedRequestActionSuccess,
  setIsSendingMessage,
  fetchNewMessagecurrentConversationAction,
  FetchNewMessage,
  fetchNewMessagecurrentConversationActionSuccess,
  setFetchStatusLoadingMessages,
  setFetchStatus,
  fetchAcessToConversationRequestAction,
  fetchConversationRequestAction,
  fetchConversationRequestActionSuccess,
} from '@store/chat/actions';
import { ChatState, Conversation, Message } from '@store/chat/slice';

import { FetchStatus } from '@store/constants';
import {
  createChatByOrder,
  createChatByRequest,
  getAcessToConversation,
  getConversation,
  getConversationById,
  getConversationByOrder,
  getConversationByRequest,
  getConversations,
  getNewMessage,
  getNumberOfConversationsWithUnreadedMessages,
  isConversationOrder,
  isConversationRequest,
  sendMessage,
} from './api/chat';
import { handleError } from './errors';

const fetchIsChekConversationRequestActionHandler: Saga<FetchCreateChatFromRequest> =
  function* ({ payload }) {
    const { requestId, supplierId } = payload;

    try {
      const { conversationExist } = yield call(
        isConversationRequest,
        requestId,
        supplierId
      );
      if (conversationExist) {
        yield put(
          fetchConversationFromRequestRequestAction({ requestId, supplierId })
        );
      } else {
        yield put(
          fetchCreateConversationFromRequestRequestAction({
            requestId,
            supplierId,
          })
        );
      }
    } catch (e) {
      yield call(handleError, e);
    }
  };

const fetchCreateConversationFromRequestRequestActionHandler: Saga<FetchCreateChatFromRequest> =
  function* ({ payload }) {
    const { requestId, supplierId } = payload;

    try {
      const data: Conversation = yield call(
        createChatByRequest,
        requestId,
        supplierId
      );
      yield put(setActiveConversation(data));
      yield put(fetchConversationsRequestAction());
    } catch (e) {
      yield call(handleError, e);
    }
  };

const fetchConversationFromRequestRequestActionHandler: Saga<FetchCreateChatFromRequest> =
  function* ({ payload }) {
    const { requestId, supplierId } = payload;

    try {
      const data: Conversation = yield call(
        getConversationByRequest,
        requestId,
        supplierId
      );
      yield put(setActiveConversation(data));
    } catch (e) {
      yield call(handleError, e);
    }
  };

const fetchIsChekConversationOrderActionHandler: Saga<FetchCreateChatFromOrder> =
  function* ({ payload }) {
    const { orderId } = payload;

    try {
      const { conversationExist } = yield call(isConversationOrder, orderId);
      if (conversationExist) {
        yield put(fetchConversationFromOrderRequestAction({ orderId }));
      } else {
        yield put(
          fetchCreateConversationFromOrderRequestAction({
            orderId,
          })
        );
      }
    } catch (e) {
      yield call(handleError, e);
    }
  };

const fetchCreateConversationFromOrderRequestActionHandler: Saga<FetchCreateChatFromOrder> =
  function* ({ payload }) {
    const { orderId } = payload;

    try {
      const data = yield call(createChatByOrder, orderId);
      yield put(fetchConversationsRequestAction());
      yield put(setActiveConversation(data));
    } catch (e) {
      yield call(handleError, e);
    }
  };

const fetchConversationFromOrderRequestActionHandler: Saga<FetchCreateChatFromOrder> =
  function* ({ payload }) {
    const { orderId } = payload;

    try {
      const data = yield call(getConversationByOrder, orderId);
      yield put(setActiveConversation(data));
    } catch (e) {
      yield call(handleError, e);
    }
  };

const fetchConversationsHandler = function* () {
  try {
    yield put(setFetchStatus(FetchStatus.Fetching));
    const data: Conversation[] = yield call(getConversations);
    yield put(fetchConversationsRequestActionSuccess(data));
  } catch (e) {
    yield call(handleError, e);
  }
};

const fetchSendMessageRequestActionHandler: Saga<FetchSendMessage> =
  function* ({ payload }) {
    const { content, conversationId } = payload;
    try {
      yield put(setIsSendingMessage(true));
      const data: Message = yield call(sendMessage, content, conversationId);
      yield put(setIsSendingMessage(false));
      yield put(
        fetchSendMessageRequestActionSuccess({ message: data, conversationId })
      );
    } catch (e) {
      yield call(handleError, e);
    }
  };

const fetchMessagesConversationIdRequestActionHandler: Saga<FetchMessages> =
  function* ({ payload }) {
    const { conversationId } = payload;

    try {
      yield put(setFetchStatusLoadingMessages(FetchStatus.Fetching));
      const data: Message[] = yield call(getConversationById, conversationId);
      yield put(
        fetchMessagesConversationIdRequestActionSuccess({
          conversationId,
          message: data,
        })
      );
    } catch (e) {
      yield call(handleError, e);
    }
  };

const fetchNumberOfConversationsWithUnreadedRequestActionHandler =
  function* () {
    try {
      const data: Pick<ChatState, 'numberOfConversationsWithUnreadedMessages'> =
        yield call(getNumberOfConversationsWithUnreadedMessages);

      yield put(
        fetchNumberOfConversationsWithUnreadedRequestActionSuccess(data)
      );
    } catch (e) {
      yield call(handleError, e);
    }
  };

const fetchNewMessagecurrentConversationActionHandler: Saga<FetchNewMessage> =
  function* ({ payload }) {
    const { id, conversationId } = payload;
    try {
      const data: Message[] = yield call(getNewMessage, id);
      yield put(
        fetchNewMessagecurrentConversationActionSuccess({
          messages: data,
          conversationId,
        })
      );
    } catch (e) {
      yield call(handleError, e);
    }
  };

const fetchAcessToConversationRequestActionHandler: Saga<{
  conversationId: string;
  add?: boolean;
}> = function* ({ payload }) {
  const { conversationId, add } = payload;
  try {
    const { accessToConversation } = yield call(
      getAcessToConversation,
      conversationId
    );
    if (accessToConversation) {
      yield put(fetchNumberOfConversationsWithUnreadedRequestAction());
      if (add) yield put(fetchConversationRequestAction({ conversationId }));
    }
  } catch (error) {
    yield call(handleError, error);
  }
};

const fetchConversationRequestActionHandler: Saga<FetchMessages> = function* ({
  payload,
}) {
  try {
    const { conversationId } = payload;
    const data: Conversation = yield call(getConversation, conversationId);
    yield put(fetchConversationRequestActionSuccess(data));
  } catch (e) {
    yield call(handleError, e);
  }
};

export default function* root() {
  yield all([
    takeLatest(
      fetchCreateConversationFromRequestRequestAction,
      fetchCreateConversationFromRequestRequestActionHandler
    ),
    takeLatest(
      fetchCreateConversationFromOrderRequestAction,
      fetchCreateConversationFromOrderRequestActionHandler
    ),
    takeLatest(
      fetchConversationFromRequestRequestAction,
      fetchConversationFromRequestRequestActionHandler
    ),
    takeLatest(
      fetchConversationFromOrderRequestAction,
      fetchConversationFromOrderRequestActionHandler
    ),
    takeLatest(fetchConversationsRequestAction, fetchConversationsHandler),
    takeLatest(
      fetchIsChekConversationRequestAction,
      fetchIsChekConversationRequestActionHandler
    ),
    takeLatest(
      fetchIsChekConversationOrderAction,
      fetchIsChekConversationOrderActionHandler
    ),
    takeEvery(
      fetchSendMessageRequestAction,
      fetchSendMessageRequestActionHandler
    ),
    takeLatest(
      fetchMessagesConversationIdRequestAction,
      fetchMessagesConversationIdRequestActionHandler
    ),
    takeLatest(
      fetchNumberOfConversationsWithUnreadedRequestAction,
      fetchNumberOfConversationsWithUnreadedRequestActionHandler
    ),
    takeLatest(
      fetchNewMessagecurrentConversationAction,
      fetchNewMessagecurrentConversationActionHandler
    ),
    takeEvery(
      fetchAcessToConversationRequestAction,
      fetchAcessToConversationRequestActionHandler
    ),
    takeEvery(
      fetchConversationRequestAction,
      fetchConversationRequestActionHandler
    ),
  ]);
}
