import { toValidDataTime } from '@utils/date';
import { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import styles from './styles.module.sass';

export enum TimeUnit {
  second = 60,
  minute = 60000,
  hour = 3600000,
  day = 86400000,
}

interface ITimerBar {
  dateСreate: number;
  timer?: number;
  unit?: TimeUnit;
  notVisibleTime?: boolean;
  hours?: boolean;
  svg?: boolean;
}

const setColorTimerBar = (p: number) => {
  if (p > 0.99) return '#EBEBEF';
  if (p > 0.75) return '#FE4848';
  if (p > 0.5) return '#FEA548';
  return '#7173DF';
};

export default function TimerBar({
  dateСreate,
  timer = 24,
  unit = TimeUnit.hour,
  notVisibleTime = false,
  hours,
  svg,
}: ITimerBar) {
  const validDate = toValidDataTime(dateСreate);
  // узнаем остаток времени до истечения 1 часа или 1 минуты или 1 секунды
  // в зависимоти от выбранной еденицы измерения
  // это время через которое сработает первый таймер
  // секунды так больше для теста далее уберем
  const [timeUpToFullUnit] = useState(validDate % unit);

  // инициализируем переменную которая подсчитывает сколько единиц измерения прошло с начала наймера
  const [, setTimePassed] = useState(0);

  // узнаем сколько времени прошло с даты создания заявки
  const timeFromStart = (Date.now() - validDate) / unit;

  const ref = useRef(timer - timeFromStart);

  // узнаем процент выполнения для отрисовки на круге
  const percentFinish = (timeFromStart * 100) / timer / 100;

  // значение svg константы для 100% заполнения
  const lengthCircle = 264;

  const length =
    ref.current > 0
      ? Math.round(lengthCircle - (lengthCircle * timeFromStart) / timer)
      : 0;

  const hoursLeft = percentFinish < 1 ? Math.ceil(ref.current) : 0;

  useEffect(() => {
    if (ref.current > 0) {
      let timerId = setTimeout(function run() {
        timerId = setTimeout(run, unit);
        ref.current -= 1;
        setTimePassed(ref.current);
        if (ref.current === 0) clearTimeout(timerId);
      }, timeUpToFullUnit);

      return () => clearTimeout(timerId);
    }
  }, [timeUpToFullUnit, unit]);

  return (
    <>
      <svg
        width="100"
        height="100"
        viewBox="0 0 100 100"
        className={cn(svg && styles.svg)}
      >
        <circle
          cx="50"
          cy="50"
          r="42"
          strokeDasharray="264 0"
          style={{
            fill: 'transparent',
            stroke: '#EBEBEF',
            strokeWidth: 16,
            strokeDashoffset: 66,
          }}
        />
        <circle
          cx="50"
          cy="50"
          r="42"
          strokeDasharray={`${length} ${lengthCircle - length}`}
          style={{
            fill: 'transparent',
            stroke: setColorTimerBar(percentFinish),
            strokeWidth: 16,
            strokeDashoffset: 66,
          }}
        />
      </svg>
      {!notVisibleTime && (
        <b className={cn(hours && styles.hours)}>
          {hoursLeft > 0 ? hoursLeft : 0}
        </b>
      )}
    </>
  );
}
