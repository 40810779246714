import axios from 'axios';

const envViteApiBaseUrl = import.meta.env.VITE_API_BASE_URL || '';

export const axiosInstance = axios.create({
  baseURL: `${envViteApiBaseUrl}/api/v1`,
});

export const tokenFromLocalStorage = () => {
  const persistRoot = localStorage.getItem('persist:root');
  if (persistRoot) {
    const user = JSON.parse(persistRoot);

    const authData = JSON.parse(user.user);
    return authData.authData?.accessToken || undefined;
  }
};

export const axiosInstanceFile = axios.create({
  baseURL: `${envViteApiBaseUrl}/`,
  headers: { Authorization: `Bearer ${tokenFromLocalStorage()}` },
});

export const axiosInstanceFileFn = (token: string) => {
  axiosInstanceFile.defaults.headers.common.Authorization = `Bearer ${token}`;
  return axiosInstanceFile;
};

export type { RawAxiosRequestConfig } from 'axios/index';
