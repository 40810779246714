import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EntityStatus, FetchStatus } from '@store/constants';
import { CostEstimateId } from '@store/costEstimates/slice';
import {
  FetchCostEstimateSectionsRequestAction,
  FetchCostEstimateSectionsSuccessAction,
  CreateCostEstimateSectionRequestAction,
  CreateCostEstimateSectionSuccessAction,
  UpdateCostEstimateSectionRequestAction,
  UpdateCostEstimateSectionSuccessAction,
  DeleteCostEstimateSectionRequestAction,
  DeleteCostEstimateSectionSuccessAction,
  MoveCostEstimateSectionsAction,
  FetchCostEstimateSectionRequestAction,
  FetchCostEstimateSectionSuccessAction,
} from '@store/costEstimateSections/actions';
import { ResourceType } from '@store/resources/slice';

export type CostEstimateSectionId = string;

export type CostEstimateSection = {
  status: EntityStatus;
  id: CostEstimateSectionId;
  costEstimateId: CostEstimateId;
  name: string;
  items: Record<ResourceType, CostEstimateSectionItems>[];
  cost: number;
  saleCost: number;
  ordering: number;
};

export type CostEstimateSectionItems = {
  cost: number;
  saleCost: number;
};

export type CostEstimatesState = {
  status: FetchStatus;
  deleteStatus: FetchStatus;
  items: CostEstimateSection[];
};

const initialState: CostEstimatesState = {
  status: FetchStatus.NotFetched,
  deleteStatus: FetchStatus.NotFetched,
  items: [],
};

export const costEstimateSectionsSlice = createSlice({
  name: 'costEstimateSections',
  initialState,
  reducers: {
    moveCostEstimateRequest: (
      state,
      action: PayloadAction<MoveCostEstimateSectionsAction>
    ) => {
      const indexObjectElement = state.items.findIndex(
        (item) => item.id === action.payload.objectElementId
      );

      const indexSubjectElement = state.items.findIndex(
        (item) =>
          item.ordering ===
          state.items[indexObjectElement].ordering + action.payload.toMove
      );
      state.items[indexObjectElement].ordering += action.payload.toMove;
      state.items[indexSubjectElement].ordering -= action.payload.toMove;
    },
    resetCostEstimateDeleteStatus: (state) => {
      state.deleteStatus = FetchStatus.NotFetched;
    },

    fetchCostEstimateSectionRequest: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<FetchCostEstimateSectionRequestAction>
    ) => {
      state.status = FetchStatus.Fetching;
    },
    fetchCostEstimateSectionSuccess: (
      state,
      { payload }: PayloadAction<FetchCostEstimateSectionSuccessAction>
    ) => {
      const { data } = payload;

      const index = state.items.findIndex((s) => s.id === data.id);
      if (index !== -1) {
        state.items[index] = { ...data, status: EntityStatus.Fetched };
      }
      state.status = FetchStatus.Fetched;
    },

    fetchCostEstimateSectionsRequest: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<FetchCostEstimateSectionsRequestAction>
    ) => {
      state.status = FetchStatus.Fetching;
    },
    fetchCostEstimateSectionsSuccess: (
      state,
      { payload }: PayloadAction<FetchCostEstimateSectionsSuccessAction>
    ) => {
      const { data } = payload;

      state.status = FetchStatus.Fetched;
      state.items = data.map((d) => ({ ...d, status: EntityStatus.Fetched }));
    },
    createCostEstimateSectionRequest: (
      state,
      { payload }: PayloadAction<CreateCostEstimateSectionRequestAction>
    ) => {
      const { costEstimateId, temporaryId, data } = payload;

      state.items.push({
        costEstimateId,
        items: [],
        cost: 0,
        saleCost: 0,
        ...data,
        id: temporaryId,
        status: EntityStatus.Fetching,
        ordering: state.items.length + 1,
      });
    },
    createCostEstimateSectionSuccess: (
      state,
      { payload }: PayloadAction<CreateCostEstimateSectionSuccessAction>
    ) => {
      const { temporaryId, data } = payload;
      const index = state.items.findIndex((item) => item.id === temporaryId);

      state.items[index] = {
        ...data,
        status: EntityStatus.Fetched,
      };
    },
    updateCostEstimateSectionRequest: (
      state,
      { payload }: PayloadAction<UpdateCostEstimateSectionRequestAction>
    ) => {
      const { id, data } = payload;
      const index = state.items.findIndex((item) => item.id === id);

      state.items[index] = {
        ...state.items[index],
        ...data,
      };
    },
    updateCostEstimateSectionSuccess: (
      state,
      { payload }: PayloadAction<UpdateCostEstimateSectionSuccessAction>
    ) => {
      const { id, data } = payload;
      const index = state.items.findIndex((item) => item.id === id);

      state.items[index] = {
        ...state.items[index],
        ...data,
      };
    },
    deleteCostEstimateSectionRequest: (
      state,
      { payload }: PayloadAction<DeleteCostEstimateSectionRequestAction>
    ) => {
      const { id } = payload;

      state.items = state.items.filter((item) => item.id !== id);
      state.deleteStatus = FetchStatus.Fetching;
    },
    deleteCostEstimateSectionSuccess: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<DeleteCostEstimateSectionSuccessAction>
    ) => {
      state.deleteStatus = FetchStatus.Fetched;
    },
    resetDeleteCostEstimateSectionStatus: (state) => {
      state.deleteStatus = FetchStatus.NotFetched;
    },
    setErrorDeleteCostEstimateSectionStatus: (state) => {
      state.deleteStatus = FetchStatus.Error;
    },
  },
});

export default costEstimateSectionsSlice.reducer;
