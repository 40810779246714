import newAddIcon from '@static/img/new-add.gif';
import { useTranslation } from 'react-i18next';

type Props = {
  firstLine?: string;
  secondLine?: string;
};

const Empty = ({ firstLine, secondLine }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="outlay__add">
      <i>
        <img src={newAddIcon} alt={(firstLine && t(firstLine)) ?? ''} />
      </i>
      {firstLine && <h6>{t(firstLine)}</h6>}
      {secondLine && <p>{t(secondLine)}</p>}
    </div>
  );
};

export default Empty;
