import { useTranslation } from 'react-i18next';
// import { ReactComponent as IconLocation } from '@static/img/icon-location.svg';
import { ReactComponent as IconChat } from '@static/img/icon-chat2.svg';
import { ReactComponent as IconChat2 } from '@static/img/icon-chat3.svg';
import Image from '@components/commons/Image';
import { TypeSize, setSizeImage } from '@utils/formatImage';
import { RadialPrimaryButton } from '@components/commons/RadialPrimaryButton';
import { setIsViewChatDrawer } from '@store/modalControl/actions';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { getisViewChatDrawer } from '@store/modalControl/selectors';
import { fetchIsChekConversationOrderAction } from '@store/chat/actions';
import styles from './styles.module.sass';

type Props = {
  supplierName: string;
  supplierLogo?: string;
  orderId: string;
};

const OrderModalTableHeader = ({
  supplierName,
  supplierLogo,
  orderId,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isViewChatDrawer = useAppSelector(getisViewChatDrawer);
  const handleOpenChat = () => {
    dispatch(fetchIsChekConversationOrderAction({ orderId }));
    dispatch(setIsViewChatDrawer());
  };

  return (
    <>
      <div className="order__table-title">
        <div>
          {supplierLogo && (
            <i>
              <Image
                url={setSizeImage(supplierLogo, TypeSize.secondSupplierLogo)}
                className={styles.orderTableHeaderSupplierLogo}
              />
            </i>
          )}
          <h4>{supplierName}</h4>
        </div>
        {/* Пока что убрали. В дальнейшем планируется добавить адрес. */}
        {/* <span>
          {t('about-object.address')}:
          <i>
            <IconLocation />
          </i>
        </span> */}
        <span>
          <RadialPrimaryButton
            className="border"
            style={{ border: '1px solid #F5F5F9' }}
            onClick={handleOpenChat}
          >
            {isViewChatDrawer ? (
              <>
                {t('bid.close-chat')}{' '}
                <IconChat2 style={{ marginLeft: '10px' }} />
              </>
            ) : (
              <>
                {t('orders.chat-with-the-supplier')}
                <IconChat style={{ marginLeft: '10px' }} />
              </>
            )}
          </RadialPrimaryButton>
        </span>
      </div>
      <div className="order__table-cap">
        <p>{t('table.column-type')}</p>
        <p>{t('table.column-name')}</p>
        <p>{t('table.column-count')}</p>
        <p>{t('table.column-price-unit')}</p>
        <p>{t('directory.cost')}</p>
        <p>{t('estimate.delivery-time')}</p>
      </div>
    </>
  );
};

export default OrderModalTableHeader;
