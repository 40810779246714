import { Modal, ModalSize } from '@components/Modal';

import { ObjectModal } from '@pages/Dashboard/ObjectModal';
import Chat from '@pages/Messenger/Chat';

type Props = {
  visible: boolean;
  onClose: () => void;
};

const ChatModal = ({ visible, onClose }: Props) => {
  return (
    <Modal visible={visible} size={ModalSize.Auto} onBackgroundClick={onClose}>
      <ObjectModal onClose={onClose} styles={{ padding: '0px' }}>
        <Chat onClose={onClose} />
      </ObjectModal>
    </Modal>
  );
};

export default ChatModal;
