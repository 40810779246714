/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from '@store/constants';
import { CostEstimateResource } from '@store/costEstimateResources/slice';
import {
  FetchResponseSupplierRequestAction,
  SelectResponseSupplierSuccessAction,
  SelectSelfDeliverySuccessAction,
} from './actions';

export type Responses = {
  id: string;
  name: string;
  amount: number;
  type: string;
  price: number;
  cost: number;
  unitId: string;
  deliveryTime: number;
  createdAt: number;
  validity: number;
  resource: {
    id: string;
    name: string;
    deliveryTime: number;
  };
  category: {
    id: string;
    name: string;
  };

  supplier: {
    id: string;
    name: string;
    cityId: string;
  };
};

export type ResponseSupplierState = {
  responses: Responses[];
  fetchResourceFromResponse: CostEstimateResource | null;
  fetchResponseSupplierStatus: FetchStatus;
  selectSelfDeliveryStatus: FetchStatus;
  selectResponseSupplierStatus: FetchStatus;
};
const initialState: ResponseSupplierState = {
  responses: [],
  fetchResourceFromResponse: null,
  fetchResponseSupplierStatus: FetchStatus.NotFetched,
  selectSelfDeliveryStatus: FetchStatus.NotFetched,
  selectResponseSupplierStatus: FetchStatus.NotFetched,
};

export const responseSupplierSlice = createSlice({
  name: 'responseSupplier',
  initialState,
  reducers: {
    selectSelfDeliveryRequest: (state) => {
      state.selectSelfDeliveryStatus = FetchStatus.Fetching;
    },
    selectSelfDeliverySuccess: (
      state,
      { payload }: PayloadAction<SelectSelfDeliverySuccessAction>
    ) => {
      const { data } = payload;
      state.fetchResourceFromResponse = data;
      state.selectSelfDeliveryStatus = FetchStatus.Fetched;
    },
    resetSelectSelfDeliveryStatus: (state) => {
      state.selectSelfDeliveryStatus = FetchStatus.NotFetched;
    },

    selectResponseSupplierRequest: (state) => {
      state.selectResponseSupplierStatus = FetchStatus.Fetching;
    },
    selectResponseSupplierSuccess: (
      state,
      { payload }: PayloadAction<SelectResponseSupplierSuccessAction>
    ) => {
      const { data } = payload;
      state.fetchResourceFromResponse = data;
      state.selectResponseSupplierStatus = FetchStatus.Fetched;
    },
    resetSelectResponseSupplierStatus: (state) => {
      state.selectResponseSupplierStatus = FetchStatus.NotFetched;
    },

    setStatusResponseSupplierFetching: (state) => {
      state.fetchResponseSupplierStatus = FetchStatus.Fetching;
    },

    fetchResponseSupplierRequest: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<FetchResponseSupplierRequestAction>
    ) => {
      state.fetchResponseSupplierStatus = FetchStatus.Fetching;
    },

    fetchResponseSupplierSuccess: (
      state,
      action: PayloadAction<Responses[]>
    ) => {
      state.responses = action.payload;
      state.fetchResponseSupplierStatus = FetchStatus.Fetched;
    },
  },
});

export default responseSupplierSlice.reducer;
