import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROOT_FOLDER_ID } from '@constants/folders';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useCompanyId } from '@hooks/useCompanyId';
import { useSelectedResources } from '@hooks/useSelectedResources';
import { ResourceId } from '@store/resources/slice';
import { OperationFolderId } from '@store/operationFolders/slice';
import { Operation, OperationId } from '@store/operations/slice';
import { OperationsCatalog } from '@components/OperationsCatalog';
import { getOperationsUrl } from '@pages';
import { OperationsBottomPanel } from '@pages/Settings/Directory/Operations/BottomPanel';
import { SettingsDirectoryOperationsEmpty } from '@pages/Settings/Directory/Operations/Empty';
import {
  deleteOperationsRequest,
  deleteOperationResourcesRequest,
} from '@store/operations/actions';
import { getOperationFolders } from '@store/operationFolders/selectors';
import { getOperations } from '@store/operations/selectors';
import { fetchOperationFoldersRequest } from '@store/operationFolders/actions';

export const SettingsDirectoryOperations = () => {
  const { folderId = ROOT_FOLDER_ID } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const companyId = useCompanyId();
  const folders = useAppSelector(getOperationFolders);
  const operations = useAppSelector(getOperations);

  useEffect(() => {
    if (companyId !== undefined) {
      dispatch(fetchOperationFoldersRequest({ companyId }));
    }
  }, [dispatch, companyId]);

  const [selectedResources, handleSelectResources] = useSelectedResources();
  const selectResources = useCallback(
    (
      operationId: OperationId,
      resourceIds: ResourceId[],
      forceSelected?: boolean
    ) => {
      handleSelectResources(
        resourceIds.map((resourceId) => `${operationId} ${resourceId}`),
        forceSelected
      );
    },
    [handleSelectResources]
  );
  const isSelectedResource = useCallback(
    (operationId: OperationId, resourceId: ResourceId) =>
      selectedResources.includes(`${operationId} ${resourceId}`),
    [selectedResources]
  );
  const isSelectedOperation = useCallback(
    (operation: Operation) =>
      operation.resources.length > 0 &&
      operation.resources.every((resource) =>
        isSelectedResource(operation.id, resource.id)
      ),
    [isSelectedResource]
  );

  const handleChangeFolder = useCallback(
    (id: OperationFolderId) => {
      navigate(getOperationsUrl(id));
    },
    [navigate]
  );
  const handleDeleteSelected = useCallback(() => {
    if (companyId === undefined) {
      return;
    }

    handleSelectResources(selectedResources, false);

    const list = selectedResources.reduce((acc, resource) => {
      const [operationId, resourceId] = resource.split(' ');
      acc[operationId] ??= [];
      acc[operationId].push(resourceId);
      return acc;
    }, {} as Record<OperationId, ResourceId[]>);

    const selectedOperationIds = Object.keys(list).filter((operationId) => {
      const operation = operations.find((item) => item.id === operationId);

      // if all operation resources selected, return operation id
      if (list[operationId].length === operation?.resources.length) {
        return true;
      }

      // else remove selected resources
      dispatch(
        deleteOperationResourcesRequest({
          companyId,
          id: operationId,
          data: {
            ids: list[operationId],
          },
        })
      );
      return false;
    });

    if (selectedOperationIds.length > 0) {
      dispatch(
        deleteOperationsRequest({
          companyId,
          data: { ids: selectedOperationIds },
        })
      );
    }
  }, [
    selectedResources,
    operations,
    companyId,
    handleSelectResources,
    dispatch,
  ]);

  if (folders.length === 0) {
    return <SettingsDirectoryOperationsEmpty />;
  }

  return (
    <>
      <OperationsCatalog
        folderId={folderId}
        onChangeFolder={handleChangeFolder}
        selectedResources={selectedResources}
        isSelectedResource={isSelectedResource}
        isSelectedOperation={isSelectedOperation}
        onSelectResources={selectResources}
      />
      {selectedResources.length > 0 && (
        <OperationsBottomPanel
          selectedCount={selectedResources.length}
          onDeleteClick={handleDeleteSelected}
        />
      )}
    </>
  );
};
