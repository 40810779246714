/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from '@store/constants';
import { ResourceType } from '@store/resources/slice';
import {
  DeleteOrderCustomerRequestAction,
  DeleteOrderCustomerSuccessAction,
  FetchOrderCustomerItemsRequestAction,
  FetchOrderCustomerItemsSuccessAction,
  FetchOrderCustomerRequestAction,
  RequestAnInvoiceRequestAction,
  RequestAnInvoiceSuccessAction,
  UpdateOrderCustomerFinishedStatusRequestAction,
  UpdateOrderCustomerFinishedStatusSuccessAction,
} from './actions';

export type OrderId = string;

export enum OrderStatus {
  ACCEPTED = 'ACCEPTED',
  PAYMENT = 'PAYMENT',
  PAID = 'PAID',
  PRODUCTION = 'PRODUCTION',
  READY = 'READY',
  ISSUED = 'ISSUED',
}

export type OrderDocuments = {
  id: string;
  fileName: string;
  originalFileName: string;
};

export type OrderCustomerDataItem = {
  id: string;
  status: OrderStatus;
  number: number;
  supplier: OrderCustomerDataItemSupplier;
  request: {
    id: string;
    number: number;
    validity: number;
  };
  needInvoice: boolean;
  invoiceValidity: number;
  deliveryTime: number;
  plannedBudget: number;
  actualBudget: number;
  invoice: OrderDocuments;
  payment: OrderDocuments;
  proxy: OrderDocuments;
};

export type OrderCustomerDataItemSupplier = {
  address: string;
  cityId: string | null;
  countryId: string | null;
  description: string;
  email: string;
  id: string;
  image: {
    id: string;
    name: string;
    fileName: string;
  } | null;
  name: string;
  phone: string;
  website: string;
};

export type OrdersCustomerData = {
  id: string;
  budget: number;
  overBudget: number;
  remainsToPurchase: number;
  savings: number;
  currencyCode: string;
  items: OrderCustomerDataItem[];
};

export type OrderCustomerItem = {
  id: string;
  amount: number;
  type: ResourceType;
  price: number;
  cost: number;
  unitId: string;
  deliveryTime: number;
  name: {
    itemName: string;
    sectionName: string;
  };
};

export type OrderCustomerItems = {
  total: number;
  currencyCode: string;
  items: OrderCustomerItem[];
};

export type OrderCustomerState = {
  orders: OrdersCustomerData | null;
  orderItems: OrderCustomerItems | null;
  fetchOrderCustomerStatus: FetchStatus;
  fetchOrderCustomerItemsStatus: FetchStatus;
  deleteItemOrderCustomerStatus: FetchStatus;
  requestAnInvoiceStatus: FetchStatus;
  updateOrderCustomerFinishedStatus: FetchStatus;
  deleteOrderCustomerProxyDocument: FetchStatus;
};
const initialState: OrderCustomerState = {
  orders: null,
  orderItems: null,
  fetchOrderCustomerStatus: FetchStatus.NotFetched,
  fetchOrderCustomerItemsStatus: FetchStatus.NotFetched,
  deleteItemOrderCustomerStatus: FetchStatus.NotFetched,
  requestAnInvoiceStatus: FetchStatus.NotFetched,
  updateOrderCustomerFinishedStatus: FetchStatus.NotFetched,
  deleteOrderCustomerProxyDocument: FetchStatus.NotFetched,
};

export const orderCustomerSlice = createSlice({
  name: 'orderCustomer',
  initialState,
  reducers: {
    fetchOrderCustomerRequest: (
      state,
      action: PayloadAction<FetchOrderCustomerRequestAction>
    ) => {
      state.fetchOrderCustomerStatus = FetchStatus.Fetching;
    },
    fetchOrderCustomerSuccess: (
      state,
      action: PayloadAction<OrdersCustomerData>
    ) => {
      state.orders = action.payload;
      state.fetchOrderCustomerStatus = FetchStatus.Fetched;
    },

    fetchOrderCustomerItemsRequest: (
      state,
      action: PayloadAction<FetchOrderCustomerItemsRequestAction>
    ) => {
      state.fetchOrderCustomerItemsStatus = FetchStatus.Fetching;
    },
    fetchOrderCustomerItemsSuccess: (
      state,
      action: PayloadAction<FetchOrderCustomerItemsSuccessAction>
    ) => {
      const { data } = action.payload;

      state.fetchOrderCustomerItemsStatus = FetchStatus.Fetched;
      state.orderItems = {
        ...data,
      };
    },

    requestAnInvoiceRequest: (
      state,
      action: PayloadAction<RequestAnInvoiceRequestAction>
    ) => {
      state.requestAnInvoiceStatus = FetchStatus.Fetching;
    },
    requestAnInvoiceSuccess: (
      state,
      action: PayloadAction<RequestAnInvoiceSuccessAction>
    ) => {
      state.requestAnInvoiceStatus = FetchStatus.Fetched;
    },

    deleteOrderCustomerItemRequest: (
      state,
      action: PayloadAction<DeleteOrderCustomerRequestAction>
    ) => {
      state.deleteItemOrderCustomerStatus = FetchStatus.Fetching;
    },
    deleteOrderCustomerItemSuccess: (
      state,
      action: PayloadAction<DeleteOrderCustomerSuccessAction>
    ) => {
      state.deleteItemOrderCustomerStatus = FetchStatus.Fetched;
    },
    resetDeleteOrderCustomerItemStatus: (state) => {
      state.deleteItemOrderCustomerStatus = FetchStatus.NotFetched;
    },
    setErrorDeleteOrderCustomerItemStatus: (state) => {
      state.deleteItemOrderCustomerStatus = FetchStatus.Error;
    },

    updateOrderCustomerFinishedStatusRequest: (
      state,
      action: PayloadAction<UpdateOrderCustomerFinishedStatusRequestAction>
    ) => {
      state.updateOrderCustomerFinishedStatus = FetchStatus.Fetching;
    },

    updateOrderCustomerFinishedStatusSuccess: (
      state,
      action: PayloadAction<UpdateOrderCustomerFinishedStatusSuccessAction>
    ) => {
      state.updateOrderCustomerFinishedStatus = FetchStatus.Fetched;
    },

    deleteOrderCustomerProxyDocumentSuccess: (state) => {
      state.deleteOrderCustomerProxyDocument = FetchStatus.Fetched;
    },
    setDeleteOrderCustomerProxyDocumentStatus: (
      state,
      action: PayloadAction<FetchStatus>
    ) => {
      state.deleteOrderCustomerProxyDocument = action.payload;
    },
  },
});

export default orderCustomerSlice.reducer;
