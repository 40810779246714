import Timer, { TimeUnit } from '@components/commons/Timer';
import { useAppDispatch } from '@hooks/redux';
import { setActiveRequestId } from '@store/requestSupplier/actions';
import { StatusRequest } from '@utils/enums';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconTime } from '@static/img/icon-error.svg';
import { toValidDataTime } from '@components/commons/Calendar';

interface IColumnItemInfo {
  showDetails: () => void;
  number: number;
  requestId: string;
  data: number;
  name: string;
  validity: number;
  status: keyof typeof StatusRequest;
  needClarification: boolean;
}

export default function ColumnItemInfo({
  showDetails,
  number,
  requestId,
  data,
  name,
  validity,
  status,
  needClarification,
}: IColumnItemInfo) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleClickRequest = () => {
    showDetails();
    dispatch(setActiveRequestId(requestId));
  };
  // для новых заявок отсчитыватся 24 часа
  // для других актуальность предложения в днях
  return (
    <div className="object__office open-modal">
      <span>
        {status === 'NEW' && <Timer dateСreate={data} timer={24} />}
        {status !== 'NEW' && validity && (
          <Timer
            dateСreate={toValidDataTime(data)}
            timer={Math.ceil(
              (toValidDataTime(validity) - Date.now()) / TimeUnit.day
            )}
            unit={TimeUnit.day}
          />
        )}
        {status !== 'NEW' && !validity && <Timer dateСreate={data} timer={0} />}
      </span>
      <h4>
        {status === 'PROCESSED' && !validity && (
          <i title={t('bid.not-answer') || ''} data-tooltip="left">
            <IconTime />
          </i>
        )}
        {status === 'PROCESSED' && needClarification && validity && (
          <i title={t('bid.required') || ''} data-tooltip="left">
            <IconTime />
          </i>
        )}

        {name}
      </h4>
      <p>{`${t('bid.request')} №${number}`}</p>
      <button aria-label="openModal" onClick={handleClickRequest} />
    </div>
  );
}
