import React, {
  FC,
  memo,
  SyntheticEvent,
  useCallback,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import { useOutsideClickListener } from '@hooks/useOutsideClickListener';
import { useTranslation } from 'react-i18next';

type ResourcesCatalogFolderModalProps = {
  onRename: () => void;
  onDelete: () => void;
};

const ResourcesCatalogFolderModalRaw: FC<ResourcesCatalogFolderModalProps> = ({
  onRename,
  onDelete,
}) => {
  const { t } = useTranslation();

  const [activeModal, setActiveModal] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleArrowClick = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    setActiveModal((active) => !active);
  }, []);
  const handleOutsideClick = useCallback(() => {
    setActiveModal(false);
  }, []);
  const handleRename = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      setActiveModal(false);
      onRename();
    },
    [onRename]
  );
  const handleDelete = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      setActiveModal(false);
      onDelete();
    },
    [onDelete]
  );

  useOutsideClickListener(containerRef, handleOutsideClick);

  return (
    <span
      ref={containerRef}
      className={cn('dropdown', { index: activeModal, open: activeModal })}
    >
      <s onClick={handleArrowClick} />
      <ul>
        <li onClick={handleRename} className="open-modal center">
          {t('directory.rename')}
        </li>
        {/* <li onClick={() => {}} className="open-modal center">Переместить</li> */}
        <li onClick={handleDelete} className="open-modal center">
          {t('directory.delete')}
        </li>
      </ul>
    </span>
  );
};

export const ResourcesCatalogFolderModal = memo(ResourcesCatalogFolderModalRaw);
