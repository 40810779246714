import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { getRestoreUrl } from '@pages';
import { Link } from 'react-router-dom';
import iconRestore from '@static/img/icon-deleteMin.svg';
import styles from './styles.module.sass';

export const SupportInfo = () => {
  const { t } = useTranslation();

  return (
    <Link
      to={getRestoreUrl()}
      className={cn('link min transparent', styles.link)}
    >
      <span>
        <img src={iconRestore} className="svg" />
        {t('auth.resort-password')}
      </span>
    </Link>
  );
};
