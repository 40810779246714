import { Request, TypeBid } from '@store/requestSupplier/slice';
import { nanoid } from '@reduxjs/toolkit';
import { isArray } from 'lodash';
import Item from './Item';

interface PartProps {
  title: string;
  part: keyof TypeBid;
  items: Request[];
  currencySymbolById: string | null | undefined;
}

export default function Part({
  part,
  items,
  title,
  currencySymbolById,
}: PartProps) {
  if (!isArray(items)) return <></>;
  return (
    <>
      <div className="req__table-title">
        <h4>{title}</h4>
      </div>
      <div className="req__item" style={{ padding: '10px' }}>
        {items &&
          items.map((e, i) => {
            return (
              <Item
                part={part}
                requestId={e.id}
                currencySymbolById={currencySymbolById}
                number={i + 1}
                request={e}
                key={nanoid()}
              />
            );
          })}
      </div>
    </>
  );
}
