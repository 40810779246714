/* eslint-disable import/no-cycle */
import { CostEstimateId } from '@store/costEstimates/slice';
import { ResourceId } from '@store/resources/slice';
import { createAction } from '@reduxjs/toolkit';
import { requestCustomerSlice } from './slice';

export type RequestId = string;

export type CreateRequestCustomerRequestAction = {
  costEstimateItemsIds: string[];
  id: CostEstimateId;
};

export type FetchRequestCustomerRequestAction = {
  id: RequestId;
};

export type CreateOrderCustomerRequestAction = {
  requestItemsIds: string[];
  requestId: RequestId;
};

export type FetchOrderCustomerRequestAction = {
  costEstimateId: CostEstimateId;
};

export type DeleteRequestCustomerItemRequestAction = {
  resourceId: ResourceId;
  requestId: RequestId;
};

export const deleteRequestCustomerItemRequest =
  createAction<DeleteRequestCustomerItemRequestAction>(
    'requestCustomer/deleteRequestCustomerItemRequest'
  );

export const {
  setDeleteRequestCustomerItemStatus,
  deleteRequestCustomerItemSuccess,
  setErrorCreateRequestCustomerStatus,
  setErrorCreateOrderCustomerStatus,
  resetCreateOrderCustomerStatus,
  createOrderCustomerRequest,
  createOrderCustomerSuccess,
  fullClearSelectedIds,
  deleteSelectedIds,
  setSelectedId,
  createRequestCustomerRequest,
  createRequestCustomerSuccess,
  fetchRequestCustomerRequest,
  fetchRequestCustomerObjectSuccess,
  resetCreateRequestCustomerStatus,
} = requestCustomerSlice.actions;
