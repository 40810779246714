/* eslint-disable react-hooks/exhaustive-deps */
import { MainLayout } from '@layouts/MainLayout';
import { useTranslation } from 'react-i18next';
import { StatusOrder, StatusRequest } from '@utils/enums';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { getRequestsByCost } from '@store/requestSupplier/selectors';
import { Modal, ModalSize } from '@components/Modal';
import { useEffect, useState } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { getCurrencySymbolById } from '@store/vocabulary/selectors';
import { getProfileSuggestedCurrencyId } from '@store/profile/selectors';
import {
  getCurrentOrder,
  getOrdersByCost,
} from '@store/orderSupplier/selectors';
import { fetchIsChekConversationOrderAction } from '@store/chat/actions';
import { getSupplierId } from '@store/supplier/selectors';
import { FetchRequestBasicDataRequestAction } from '@store/requestSupplier/actions';
import { FetchOrerBasicDataRequestAction } from '@store/orderSupplier/actions';
import ColumnsName from './ColumnsName';
import ColumnsData from './ColumnsData';
import DetailsRequest from './DetailsRequest';
import ColumnsOrdersData from './ColumnsOrdersData';
import DetailsOrder from './DetailsOrder';

type RequestsOrders = {
  cost: number;
  status: string;
};

export default function SupplyOffice() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const columnNames: { title: string; status: StatusRequest | StatusOrder }[] =
    [
      {
        title: t('bid.new-request'),
        status: StatusRequest.NEW,
      },
      {
        title: t('bid.processed'),
        status: StatusRequest.PROCESSED,
      },
      {
        title: t('bid.selected'),
        status: StatusRequest.SELECTED,
      },
      {
        title: t('bid.order'),
        status: StatusOrder.ORDERED,
      },
      {
        title: t('bid.production'),
        status: StatusOrder.PRODUCTION,
      },
    ];

  const [isViewChat, setIsViewChat] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [status, setStatus] = useState<
    keyof typeof StatusRequest | keyof typeof StatusOrder
  >('NEW');

  const requests = useAppSelector(getRequestsByCost) as RequestsOrders[];

  const order = useAppSelector(getCurrentOrder);

  const orders = useAppSelector(getOrdersByCost) as RequestsOrders[];

  const supplierId = useAppSelector(getSupplierId);

  const profileSuggestedCurrencyId = useAppSelector(
    getProfileSuggestedCurrencyId
  );

  const currencySymbolById = useAppSelector((state) =>
    getCurrencySymbolById(state, profileSuggestedCurrencyId)
  );

  const handleOpenModelClick = (
    s: keyof typeof StatusRequest | keyof typeof StatusOrder
  ) => {
    setIsVisibleModal(true);
    setStatus(s);
  };

  const handlecloseModal = () => {
    setIsVisibleModal(false);
    setIsViewChat(false);
  };

  const handleOpenChat = (isView: boolean) => {
    if (order) {
      dispatch(fetchIsChekConversationOrderAction({ orderId: order?.id }));
      setIsViewChat(isView);
    }
  };

  useEffect(() => {
    if (supplierId) {
      columnNames.forEach((e) => {
        if (e.status in StatusRequest) {
          dispatch(
            FetchRequestBasicDataRequestAction({
              status: e.status as StatusRequest,
              supplierId,
            })
          );
        } else {
          dispatch(
            FetchOrerBasicDataRequestAction({ status: e.status as StatusOrder })
          );
        }
      });
    }
  }, [dispatch, supplierId]);

  return (
    <>
      {(status === 'NEW' ||
        status === 'PROCESSED' ||
        status === 'SELECTED') && (
        <Modal
          visible={isVisibleModal}
          size={ModalSize.Max}
          onBackgroundClick={() => setIsVisibleModal(false)}
        >
          <DetailsRequest
            currencySymbolById={currencySymbolById}
            status={status}
          />
        </Modal>
      )}
      {(status === 'ORDERED' || status === 'PRODUCTION') && order && (
        <Modal
          visible={isVisibleModal}
          size={isViewChat ? ModalSize.Max : ModalSize.MediumPlus}
          onBackgroundClick={handlecloseModal}
        >
          <DetailsOrder
            currencySymbolById={currencySymbolById}
            status={status}
            order={order}
            isViewChat={isViewChat}
            openChat={handleOpenChat}
          />
        </Modal>
      )}

      <MainLayout title="Кабинет поставщика">
        <section className="content" style={{ padding: '0 25px 20px 0px' }}>
          <div className="object">
            <div className="object__cap">
              {columnNames.map((e) => {
                const listRequestByStatus = requests
                  .concat(orders)
                  .filter((i) => i.status === e.status);
                const quantity = listRequestByStatus.length;

                const cost = listRequestByStatus.reduce(
                  (sum, currentCost) => sum + currentCost.cost,
                  0
                );

                return (
                  <ColumnsName
                    name={t(e.title)}
                    quatity={quantity}
                    cost={cost}
                    key={nanoid()}
                    status={e.status}
                  />
                );
              })}
            </div>
            <div className="object__cot gray">
              {columnNames.map((e) => {
                if (e.status in StatusRequest) {
                  return (
                    <ColumnsData
                      key={nanoid()}
                      showDetails={() => handleOpenModelClick(e.status)}
                      status={e.status as StatusRequest}
                    />
                  );
                }
                return (
                  <ColumnsOrdersData
                    key={nanoid()}
                    showDetails={() => handleOpenModelClick(e.status)}
                    status={e.status as StatusOrder}
                  />
                );
              })}
            </div>
          </div>
        </section>
      </MainLayout>
    </>
  );
}
