import { BID_PHASE } from '@constants/phases';
import { StatusOrder, StatusRequest } from '@utils/enums';
import { useTranslation } from 'react-i18next';

interface IColumnsName {
  name: string;
  quatity: number;
  cost: number;
  status: keyof typeof StatusRequest | keyof typeof StatusOrder;
}

export default function ColumnsName({
  name,
  cost,
  quatity,
  status,
}: IColumnsName) {
  const phaseClass = BID_PHASE[status];
  const { t } = useTranslation();
  return (
    <a className="object__title active">
      <i className={phaseClass} />
      <h4>{name}</h4>
      <p>
        {quatity} {t('bid.object')} {status === 'NEW' ? '' : `на ${cost} руб`}
      </p>
      <b className="bg-primary">{quatity}</b>
    </a>
  );
}
