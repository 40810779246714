import React, { FC } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { PHASE_CLASSES } from '@constants/phases';

type SuccessModalProps = {
  successTitle: string;
  onCancel: () => void;
};

export const SuccessModal: FC<SuccessModalProps> = ({
  successTitle,
  onCancel,
}) => {
  const { t } = useTranslation();

  const successClass = PHASE_CLASSES[3];

  return (
    <div className="modal__min">
      <div className="modal__close" onClick={onCancel} />
      <div className="modal__min-title center">
        <h4>{t('confirm.success')}</h4>
        <p>{successTitle}</p>
      </div>
      <div className="modal__min-submit">
        <button className={cn('link', successClass)} onClick={onCancel}>
          {t('confirm.continue')}
        </button>
      </div>
    </div>
  );
};
