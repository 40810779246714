import { ReactComponent as IconAttenеion } from '@static/img/icon-error.svg';
import { Order } from '@store/orderSupplier/slice';
import { StatusOrder } from '@utils/enums';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useEffect, useState } from 'react';
import { useDownloadDocument } from '@hooks/useDownloadDocument';
import {
  getCurrentOrderList,
  getDeleteOrderSupplierStatus,
} from '@store/orderSupplier/selectors';
import {
  FetchDeleteOrerRequestAction,
  FetchOrerDetailsRequestAction,
  FetchUpdateStatusORderRequestAction,
  setDeleteOrderSupplierStatus,
} from '@store/orderSupplier/actions';
import { useTranslation } from 'react-i18next';
import Chat from '@pages/Messenger/Chat';
import { ConfirmationModal } from '@components/commons/ConfirmationModal';
import { FetchStatus } from '@store/constants';
import { DetailsProps } from '../types';
import Status from './Status';
import Header from './Header';
import Table from './Table';
import StatusBar from './StatusBar';
import OrderAction from './StatusAction';

type DetailsOrderProps = {
  status: keyof typeof StatusOrder;
  order: Order;
  isViewChat?: boolean;
  openChat: (isView: boolean) => void;
} & DetailsProps;

export default function DetailsOrder({
  order,
  isViewChat = false,
  openChat,
}: DetailsOrderProps) {
  const { t } = useTranslation();
  const handleDownloadDocument = useDownloadDocument();
  const dispatch = useAppDispatch();
  const deleteOrderStatus = useAppSelector(getDeleteOrderSupplierStatus);
  const currentOrderList = useAppSelector(getCurrentOrderList);
  const [isVisibleCancelOrderModal, setIsVisibleCancelOrderModal] =
    useState(false);

  useEffect(() => {
    dispatch(FetchOrerDetailsRequestAction({ orderId: order.id }));
  }, [dispatch, order.id]);

  const setActionFromStatus = () => {
    switch (order.status) {
      case 'ACCEPTED':
      case 'PAYMENT':
      case 'ISSUED':
      case 'PRODUCTION':
        return openChat(true);
      case 'PAID':
        return (
          order.payment?.fileName &&
          handleDownloadDocument(
            `/private-order-doc/${order.payment.fileName}`,
            order.payment?.originalFileName
          )
        );
      case 'READY':
        return (
          order.proxy?.fileName &&
          handleDownloadDocument(
            `/private-order-doc/${order.proxy.fileName}`,
            order.proxy?.originalFileName
          )
        );
      default:
        return () => {};
    }
  };
  const setActionByOrder = () => {
    switch (order.status) {
      case 'ACCEPTED':
        return (function name(id: string) {
          const input = document.querySelector(
            `[id=${id}]`
          ) as HTMLInputElement;
          input.click();
        })('uploadInvoice');
      case 'PAYMENT':
        return (
          order.invoice?.fileName &&
          handleDownloadDocument(
            `/private-order-doc/${order.invoice.fileName}`,
            order.invoice?.originalFileName
          )
        );
      case 'PAID':
        return dispatch(
          FetchUpdateStatusORderRequestAction({
            orderId: order.id,
            status: 'PRODUCTION',
          })
        );

      case 'PRODUCTION':
        return dispatch(
          FetchUpdateStatusORderRequestAction({
            orderId: order.id,
            status: 'READY',
          })
        );
      case 'READY':
        return dispatch(
          FetchUpdateStatusORderRequestAction({
            orderId: order.id,
            status: 'ISSUED',
          })
        );
      case 'ISSUED':
        return () => {};
      // return dispatch(
      //   FetchUpdateStatusORderRequestAction({
      //     orderId: order.id,
      //     status: 'FINISHED',
      //   })
      // );
      default:
        return () => {};
    }
  };

  const dataByStatus = () => {
    return (
      <StatusBar
        action={setActionFromStatus}
        status={order.status}
        isProxy={!!order.proxy?.fileName}
      />
    );
  };
  const statusAction = () => (
    <OrderAction
      action={() => setActionByOrder()}
      status={order.status}
      loadStatus={order.loadStatus}
    />
  );

  const handleOpenDeleteOrderModal = () => {
    dispatch(setDeleteOrderSupplierStatus(FetchStatus.NotFetched));
    setIsVisibleCancelOrderModal(true);
  };

  const handleClickAbortCancelOrderButton = () => {
    setIsVisibleCancelOrderModal(false);
  };

  const handleDeleteOrderSupplier = () => {
    dispatch(FetchDeleteOrerRequestAction({ orderId: order.id }));
  };

  return (
    <>
      <ConfirmationModal
        confirmTitle={t('confirm.cancel-order')}
        isVisibleModal={isVisibleCancelOrderModal}
        status={deleteOrderStatus}
        confirmButtonClassName="bg-danger"
        onCancel={handleClickAbortCancelOrderButton}
        onConfirm={handleDeleteOrderSupplier}
      />
      <div
        className={`modal__block ${isViewChat ? 'max' : 'medium-plus'} grey`}
        style={{ display: 'flex' }}
      >
        <div className="order-modal">
          <Header
            orderId={order.id}
            numberOrder={order.number}
            numberRequest={order.request.number}
            status={order.status}
            dateValidCheck={order.invoiceValidity}
            invoice={order.invoice}
            StatusAction={statusAction}
            loadStatus={order.loadStatus}
          />
          <Status status={order.status} StatusInfo={dataByStatus} />
          {currentOrderList && (
            <Table
              orderId={order.id}
              status={order.status}
              orderList={currentOrderList}
              company={order.company}
              openChat={openChat}
              isViewChat={isViewChat}
            />
          )}
          <button
            className="order-modal__refuse"
            onClick={handleOpenDeleteOrderModal}
          >
            <IconAttenеion />
            <span>{t('bid.cancel-order')}</span>
          </button>
          <p>{t('bid.low-reputation')}</p>
        </div>
        {isViewChat && <Chat onClose={() => openChat(false)} />}
      </div>
    </>
  );
}
