import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import IconButton from '../IconButton';
import styles from './styles.module.sass';

type Props = {
  buttonClassName?: string;
  disabled?: boolean;
  onClick?: () => void;
  title?: string;
  count: number;
};

const Counter = ({
  disabled,
  title,
  count,
  onClick,
  buttonClassName,
}: Props) => {
  const { t } = useTranslation();

  return (
    <IconButton
      className={cn('open-modal tooltip', buttonClassName)}
      title={t('estimate.selection-of-supplier-offers') || ''}
      data-tooltip="left"
      disabled={disabled}
      onClick={onClick}
    >
      <s
        className={cn(
          styles.countSupplierOffers,
          count > 99 && styles.lengthCounterIsMore
        )}
        title={title}
      >
        {count}
      </s>
    </IconButton>
  );
};

export default Counter;
