import { ReactComponent as IconEmail } from '@static/img/icon-email1.svg';
import { ReactComponent as IconPhone } from '@static/img/icon-phone1.svg';
import { ReactComponent as IconBackPage } from '@static/img/operation2.svg';
import { FullContacts } from '@store/contacts/slice';
import { Dispatch, RefObject, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import TableWithVirtualScroll, {
  Columns,
} from '@components/commons/TableWithVirtualScroll';
import { setChoiseFullContact } from '@store/contacts/actions';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { isFetchContactsFetched } from '@store/contacts/selectors';
import Empty from '@components/commons/Empty';
import InfiniteScroll from 'react-infinite-scroll-component';

type Props = {
  handleSetItemId: (id: string, index: number) => void;
  contactsForRender: FullContacts[];
  totalContacts: number;
  onContactsPageChange: Dispatch<SetStateAction<number>>;
  clientsRef: RefObject<InfiniteScroll>;
};

const ClientsTable = ({
  handleSetItemId,
  contactsForRender,
  totalContacts,
  onContactsPageChange,
  clientsRef,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const fetchContactsStatus = useAppSelector(isFetchContactsFetched);

  const columns: Columns<FullContacts>[] = [
    {
      className: 'client__cap-num',
      children: '№',
      render: (record, index: number) => index + 1,
      rowClassName: 'client__table-num',
    },
    {
      className: 'client__cap-name',
      children: t('clients.name'),
      render: (record) => `${record.firstName} ${record.lastName}`,
      rowClassName: 'client__table-name',
    },
    {
      className: 'client__cap-phone',
      children: t('clients.phone'),
      render: (record) => (
        <>
          <IconPhone /> {record.phone}
        </>
      ),
      rowClassName: 'client__table-phone',
    },
    {
      className: 'client__cap-email',
      children: t('clients.email'),
      render: (record) => (
        <>
          <IconEmail style={{ height: '14px', width: '14px' }} /> {record.email}
        </>
      ),
      rowClassName: 'client__table-email',
    },
    {
      className: 'client__cap-company',
      children: t('clients.company'),
      render: (record) =>
        record.organizations &&
        record.organizations.length > 0 && (
          <>
            <IconBackPage /> {record.organizations[0].name}
          </>
        ),
      rowClassName: 'client__table-company',
    },
  ];

  const onRowClick = (id: string, index: number) => {
    dispatch(setChoiseFullContact(id));
    handleSetItemId(id, index);
  };

  return (
    <>
      {contactsForRender.length === 0 && fetchContactsStatus ? (
        <Empty
          firstLine="clients.to-get-started-add-your-first-client"
          secondLine="clients.after-that-the-functionality-of-working-with-the-customer-directory"
        />
      ) : (
        <TableWithVirtualScroll
          columns={columns}
          data={contactsForRender}
          onScroll={() => onContactsPageChange((prev) => prev + 1)}
          bodyStyles={{ height: 'calc(100vh - 270px)', overflow: 'auto' }}
          onRowClick={onRowClick}
          rowKey="id"
          total={totalContacts}
          classNameTable="client__table"
          classNameTableItem="client__table-item open-modal"
          ref={clientsRef}
        />
      )}
    </>
  );
};
export default ClientsTable;
