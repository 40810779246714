import {
  KeyboardEvent,
  RefObject,
  SyntheticEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import debounce from 'lodash/debounce';
import { useOutsideClickListener } from '@hooks/useOutsideClickListener';

type UseTableRowFormProps = {
  defaultEditable: boolean;
  onInputClick?: () => void;
  onInputFocus?: (e?: SyntheticEvent) => void;
  onInputBlur?: (e?: SyntheticEvent) => void;
  onInputCancel?: () => void;
  onOutsideClick?: () => void;
  onEnter?: () => void;
  onFormSubmit?: () => void | Promise<void>;
};

type UseTableRowFormResult = {
  rowRef: RefObject<HTMLDivElement>;
  editable: boolean;
  handleInputClick: () => void;
  handleInputFocus: (e?: SyntheticEvent) => void;
  handleInputBlur: (e?: SyntheticEvent) => void;
  handleInputKeyUp: (e: KeyboardEvent) => void;
  handleInputCancel: () => void;
  handleFormSubmit: (e?: SyntheticEvent) => void;
};

const prepareOnFocus = (e?: SyntheticEvent) => {
  if (e?.target instanceof HTMLInputElement) {
    if (e.target.name !== 'name' && parseFloat(e.target.value) === 0) {
      e.target.value = '';
    }
  }
};

const prepareOnBlur = (e?: SyntheticEvent) => {
  if (e?.target instanceof HTMLInputElement) {
    if (e.target.name !== 'name' && e.target.value === '') {
      e.target.value = '0';
    }
  }
};

export const useTableRowForm = ({
  defaultEditable,
  onInputCancel,
  onInputClick,
  onInputFocus,
  onInputBlur,
  onFormSubmit,
  onOutsideClick,
  onEnter,
}: UseTableRowFormProps): UseTableRowFormResult => {
  const rowRef = useRef(null);
  const [editable, setEditable] = useState(defaultEditable);
  const debouncedSetEditable = useMemo(() => debounce(setEditable, 200), []);

  const handleFormSubmit = useCallback(
    (e?: SyntheticEvent) => {
      e?.preventDefault();
      onFormSubmit?.();
    },
    [onFormSubmit]
  );
  const handleInputCancel = useCallback(() => {
    setEditable(false);
    onInputCancel?.();
  }, [onInputCancel]);
  const handleInputClick = useCallback(() => {
    debouncedSetEditable(true);
    onInputClick?.();
  }, [onInputClick, debouncedSetEditable]);
  const handleInputFocus = useCallback(
    (e?: SyntheticEvent) => {
      prepareOnFocus(e);
      debouncedSetEditable(true);
      onInputFocus?.(e);
    },
    [onInputFocus, debouncedSetEditable]
  );
  const handleInputBlur = useCallback(
    (e?: SyntheticEvent) => {
      prepareOnBlur(e);
      debouncedSetEditable(false);
      onInputBlur?.(e);
    },
    [debouncedSetEditable, onInputBlur]
  );
  const handleInputKeyUp = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        (document?.activeElement as HTMLInputElement)?.blur();
        onEnter?.();
      }
      if (e.key === 'Escape') {
        handleInputCancel();
      }
    },
    [handleInputCancel, onEnter]
  );
  const handleOutsideClick = useCallback(() => {
    setEditable(false);
    onOutsideClick?.();
  }, [onOutsideClick]);

  useOutsideClickListener(rowRef, handleOutsideClick, editable);

  return {
    rowRef,
    editable,
    handleFormSubmit,
    handleInputClick,
    handleInputFocus,
    handleInputBlur,
    handleInputKeyUp,
    handleInputCancel,
  };
};
