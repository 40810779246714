import React from 'react';
import { addPopUp } from '@utils/notifications/popup';
import { Notice } from '@components/Notification/Notice';

export enum NoticeTypes {
  success,
  error,
  warning,
}

const notice = (text: string, type: NoticeTypes, timeout = 5000) => {
  addPopUp((close) => (
    <Notice
      date="Now"
      type={type}
      timeout={timeout}
      onClose={close}
      message={text}
    />
  ));
};

export default notice;
