import { RootState } from '@store';
import { FetchStatus } from '@store/constants';

export const getRootState = (state: RootState) => state.organization;
export const getStatus = (state: RootState) => getRootState(state).status;
export const getCreateOrganizationStatus = (state: RootState) =>
  getRootState(state).createOrganizationStatus;
export const getFetchOrganizationsStatus = (state: RootState) =>
  getRootState(state).getOrganizationsStatus;
export const getUpdateOrganizationStatus = (state: RootState) =>
  getRootState(state).updateOrganizationStatus;
export const getTotalOrganizations = (state: RootState) =>
  getRootState(state).totalOrganizations;

export const getOrganizations = (state: RootState) =>
  getRootState(state).organization;
export const getFullOrganizations = (state: RootState) =>
  getRootState(state).fullOrganizations;
export const isFetchOrganizationsFetched = (state: RootState) =>
  getFetchOrganizationsStatus(state) === FetchStatus.Fetched;

export const getOrganizationObjectId = (state: RootState) =>
  getRootState(state).currentCompanyObjectId;
export const getActiveBlinkWhenCreatedNewOrganization = (state: RootState) =>
  getRootState(state).activeBlinkWhenCreatedNewOrganization;
export const getLatestAddedCompanyObjectId = (state: RootState) =>
  getRootState(state).latestAddedCompanyObjectId;
export const getlastUpdatedOrganization = (state: RootState) =>
  getRootState(state).lastUpdatedOrganization;

export const getOrganizationById = (state: RootState, id: string) => {
  if (id === undefined) {
    return undefined;
  }
  return getOrganizations(state).filter(
    (organization) => organization.id === id
  )[0];
};

export const getChoiseOrganization = (state: RootState) =>
  getRootState(state).choiseOrganization;
export const getChoiseFullOrganization = (state: RootState) =>
  getRootState(state).choiseFullOrganization;

export const isOrganizationNotFetched = (state: RootState) =>
  getCreateOrganizationStatus(state) === FetchStatus.NotFetched;
export const isOrganizationFetching = (state: RootState) =>
  getCreateOrganizationStatus(state) === FetchStatus.Fetching;
export const isCreateOrganizationFetched = (state: RootState) =>
  getCreateOrganizationStatus(state) === FetchStatus.Fetched;

// export const isOrganizationNotFetched = (state: RootState) =>
//   getStatus(state) === FetchStatus.NotFetched;
// export const isCompanyFetching = (state: RootState) =>
//   getStatus(state) === FetchStatus.Fetching;
// export const isCompanyFetched = (state: RootState) =>
//   getStatus(state) === FetchStatus.Fetched;

export const isViewObjectFunnel = (state: RootState) =>
  state.organization.typeView;
