import { useState } from 'react';
import SupplierOffersModal from '@components/SupplierOffersModal';
import { setIsViewChatSuppliersOfferDrawer } from '@store/modalControl/actions';
import { useAppDispatch } from '@hooks/redux';
import { DataForRender } from '../../type';
import TableRequestsSectionObject from '../TableRequestsSectionObject';

type Props = {
  data: DataForRender;
  isViewSuppliers: boolean;
  isViewChoise: boolean;
  selectedIds: string[];
  requestId: string;
  handleAddSectionIds: (ids: string[], checked: boolean) => void;
  handleAddResourceIds: (id: string, checked: boolean) => void;
  handleOpenSupplierOffers: () => void;
  handleChoiseClose: () => void;
  handleOpenDeleteItemModal: (id: string) => void;
};

const TableRequestsSection = ({
  data,
  isViewSuppliers,
  isViewChoise,
  selectedIds,
  requestId,
  handleAddSectionIds,
  handleAddResourceIds,
  handleOpenSupplierOffers,
  handleChoiseClose,
  handleOpenDeleteItemModal,
}: Props) => {
  const dispatch = useAppDispatch();

  const [resourceId, setResourceId] = useState('');
  const [responseIdFromResource, setResponseIdFromResource] = useState('');

  const handleOpenResponse = (id: string, responseId: string) => {
    setResourceId(id);
    setResponseIdFromResource(responseId);
    handleOpenSupplierOffers();
  };

  const handleSupplierOffersCloseChat = () => {
    dispatch(setIsViewChatSuppliersOfferDrawer());
  };

  return (
    <div className="obj__supply-object">
      <TableRequestsSectionObject
        data={data}
        requestId={requestId}
        isViewSuppliers={isViewSuppliers}
        selectedIds={selectedIds}
        handleAddResourceIds={handleAddResourceIds}
        handleOpenResponse={handleOpenResponse}
        handleAddSectionIds={handleAddSectionIds}
        handleOpenDeleteItemModal={handleOpenDeleteItemModal}
      />
      <SupplierOffersModal
        isViewChoise={isViewChoise}
        handleChoiseClose={handleChoiseClose}
        resourceId={resourceId}
        withUpdate="updateSupply"
        responseIdFromResource={responseIdFromResource}
        onCloseChat={handleSupplierOffersCloseChat}
      />
    </div>
  );
};

export default TableRequestsSection;
