import { CSSProperties, FC } from 'react';
import { Modal } from '@components/AboutObjectModal';
import { FetchStatus } from '@store/constants';
import { ConfirmModal } from './ConfirmModal/ConfirmModal';

export type ConfirmationModalProps = {
  confirmTitle: string;
  isVisibleModal: boolean;
  status: FetchStatus;
  confirmButtonClassName?: string;
  portalKey?: string;
  contentStyles?: CSSProperties;
  onSuccess?: () => void;
  onCancel: () => void;
  onConfirm: () => void;
};

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  confirmTitle,
  isVisibleModal,
  status,
  confirmButtonClassName,
  contentStyles,
  portalKey = 'confirmation',
  onSuccess,
  onCancel,
  onConfirm,
}) => {
  const handleClickCloseModal = () => {
    onCancel();
    if (status === FetchStatus.Fetched) {
      onSuccess?.();
    }
  };

  const handleClickConfirmButton = () => {
    onConfirm();
  };

  return (
    <Modal
      visible={isVisibleModal}
      isCenter
      portalKey={portalKey}
      contentStyles={contentStyles}
      onBackgroundClick={handleClickCloseModal}
    >
      <ConfirmModal
        confirmTitle={confirmTitle}
        status={status}
        confirmButtonClassName={confirmButtonClassName}
        onCancel={handleClickCloseModal}
        onConfirm={handleClickConfirmButton}
      />
    </Modal>
  );
};
