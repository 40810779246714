import { ReactComponent as IconCard } from '@static/img/icon-cart2.svg';
import IconButton from '@components/commons/IconButton';
import Toggle from '@components/commons/Toggle';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { RequestCustomerData } from '@store/requestCustomer/slice';
import { OrdersCustomerData } from '@store/ordersCustomer/slice';
import SupplyBudget from '../SupplyBudget';
import styles from './styles.module.sass';

type Props = {
  tab: string;
  request: RequestCustomerData;
  orders: OrdersCustomerData;
  requestId?: string;
  handleClickToggle: () => void;
  handleOpenConfirmModal: () => void;
};

const HeaderSupply = ({
  tab,
  request,
  requestId,
  orders,
  handleClickToggle,
  handleOpenConfirmModal,
}: Props) => {
  const { t } = useTranslation();

  const budget = tab === 'requests' ? request?.budget : orders?.budget;
  // Ждем когда будет готово на бэке и уберем коммент

  // const overBudget =
  //   tab === 'requests' ? request?.overBudget : orders?.overBudget;
  // const remainsToPurchase =
  //   tab === 'requests' ? request?.remainsToPurchase : orders?.remainsToPurchase;
  // const savings = tab === 'requests' ? request?.savings : orders?.savings;

  const currencyCode =
    tab === 'requests' ? request?.currencyCode : orders?.currencyCode;

  return (
    <>
      <div className="obj__stat">
        <SupplyBudget
          budget={budget}
          // overBudget={overBudget}
          // remainsToPurchase={remainsToPurchase}
          // savings={savings}
          currencyCode={currencyCode}
        />
        {tab === 'requests' && (
          <IconButton
            className={cn('link green open-modal', styles.positionButton)}
            onClick={handleOpenConfirmModal}
            disabled={!requestId || !request?.items.length}
          >
            <span>
              <IconCard />
              Закупить все на Строй.рф
            </span>
          </IconButton>
        )}
      </div>
      <div className="top mobile">
        <h4>{t('supply.applications')}</h4>
        <Toggle
          firstPosition={t('supply.by-sections')}
          secondPosition={t('supply.by-suppliers')}
          handleClickToggle={handleClickToggle}
        />
      </div>
    </>
  );
};

export default HeaderSupply;
