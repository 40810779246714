import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

export type CreateButtonProps = {
  onClick: () => void;
  title: string;
  isOpacity?: boolean;
};
export const CreateButton: React.FC<CreateButtonProps> = ({
  onClick,
  title,
  isOpacity,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    onClick();
  };

  return (
    <div className="cap__but-link">
      {/* open-modal */}
      <Link
        to="/"
        onClick={handleClick}
        className={cn('snap', isOpacity && 'opacity')}
      >
        {title}
      </Link>
    </div>
  );
};
