import React, { CSSProperties, useEffect, useState } from 'react';
import cn from 'classnames';
import { ANIMATION_TIME } from '@constants/modal';
import { createPortal } from 'react-dom';

export type ModalProps = {
  visible: boolean;
  onBackgroundClick?: () => void;
  isCenter?: boolean;
  children: JSX.Element;
  portalKey?: string;
  contentStyles?: CSSProperties;
};

export const Modal: React.FC<ModalProps> = ({
  visible,
  onBackgroundClick,
  isCenter,
  children,
  portalKey,
  contentStyles,
}) => {
  // Чтобы определять рендерить ли портал
  const [canRenderModal, setCanRenderModal] = useState(visible);

  // Чтобы после создания портала и перед его закрытием показывать анимацию
  const [isModalHide, setIsModalHide] = useState(!visible);

  useEffect(() => {
    const body = document.querySelector('body');
    if (visible) {
      if (body) {
        body.style.overflow = 'hidden';
      }
      /* 
        Сначала врендериваем портал, затем через какое то время запускаем анимацию
      */
      setCanRenderModal(true);
      setTimeout(() => setIsModalHide(false), 100);
    } else {
      if (body) {
        body.style.overflow = 'auto';
      }

      /* 
        Сначала запускаем анимацию, затем, как только она закончится,
        удаляем портал из dom дерева
      */
      setIsModalHide(true);
      setTimeout(() => setCanRenderModal(false), ANIMATION_TIME);
    }
  }, [visible]);

  if (!canRenderModal) {
    return null;
  }
  return createPortal(
    <div className={cn('modal', { hide: isModalHide })}>
      <div className="modal__bg" onClick={onBackgroundClick} />
      <div
        className={cn('modal__cot', isCenter && 'center')}
        style={contentStyles}
      >
        {children}
      </div>
    </div>,
    document.body,
    portalKey
  );
};
