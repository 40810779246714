import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@store';
import { FetchStatus } from '@store/constants';
import { CostEstimateId } from '@store/costEstimates/slice';

export const getRootState = (state: RootState) => state.costEstimateSections;
export const getStatus = (state: RootState) => getRootState(state).status;
export const getDeleteStatus = (state: RootState) =>
  getRootState(state).deleteStatus;
export const getCostEstimateSections = (state: RootState) =>
  getRootState(state).items;

export const getCostEstimateSectionsByCostEstimateId = createSelector(
  [
    getCostEstimateSections,
    (_, costEstimateId: CostEstimateId | undefined) => costEstimateId,
  ],
  (items, costEstimateId) => {
    if (costEstimateId === undefined) {
      return [];
    }
    return items.filter((item) => item.costEstimateId === costEstimateId);
  }
);

export const isCostEstimateDeleteSectionNotFetched = (state: RootState) =>
  getDeleteStatus(state) === FetchStatus.NotFetched;
export const isCostEstimateDeleteSectionFetching = (state: RootState) =>
  getDeleteStatus(state) === FetchStatus.Fetching;
export const isCostEstimateDeleteSectionFetched = (state: RootState) =>
  getDeleteStatus(state) === FetchStatus.Fetched;

export const isCostEstimateSectionsNotFetched = (state: RootState) =>
  getStatus(state) === FetchStatus.NotFetched;
export const isCostEstimateSectionsFetching = (state: RootState) =>
  getStatus(state) === FetchStatus.Fetching;
export const isCostEstimateSectionsFetched = (state: RootState) =>
  getStatus(state) === FetchStatus.Fetched;
export const getCostEstimateSectionEvent = (
  state: RootState,
  ordering: number
) => getRootState(state).items.find((i) => i.ordering === ordering)?.id;
