import { TFunction } from 'i18next';
import { FieldValues } from 'react-hook-form';

export const getError = (
  name: string,
  errors: FieldValues,
  t: TFunction<'translation', undefined, 'translation'>
) => {
  switch (errors?.[name]?.type) {
    case 'required':
      return t('auth.validation-empty');
    case 'pattern':
      switch (name) {
        case 'phone':
          return t('auth.validation-phone-pattern');
        // case 'email':
        //   return t('auth.validation-email');
        case 'password':
          return t('auth.validation-password-short');
        default:
          break;
      }
      break;
    case 'minLength':
      return t('auth.validation-phone-short');
    case 'server':
      return `${errors?.[name]?.message}`;
    default:
      break;
  }

  return '';
};
