/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from '@store/constants';
import { CostEstimateId } from '@store/costEstimates/slice';
import { ResourceType } from '@store/resources/slice';
import { ResourceParent } from '@store/costEstimateResources/slice';
import {
  CreateOrderCustomerRequestAction,
  CreateRequestCustomerRequestAction,
  FetchRequestCustomerRequestAction,
} from './actions';

export type RequestCustomer = {
  id: CostEstimateId;
};
export type RequestCustomerDataItem = {
  id: string;
  amount: number;
  category: { id: string; name: string };
  cost: number;
  markup: number;
  name: string;
  operationId: string;
  ordering: number;
  defaultPrice: number | null;
  resourceParent: ResourceParent | null;
  price: number;
  saleCost: number;
  salePrice: number;
  type: ResourceType;
  unitId: string;
  numberOfResponses: number | null;
  deliveryTime: number;
  updateAt: string;
  section: {
    id: string;
    name: string;
  };
  supplier: RequestCustomerDataItemSupplier;
  response: RequestCustomerDataItemResponse;
};

export type RequestCustomerDataItemSupplier = {
  address: string;
  cityId: string | null;
  countryId: string | null;
  description: string;
  email: string;
  id: string;
  image: {
    id: string;
    name: string;
    fileName: string;
  } | null;
  name: string;
  phone: string;
  website: string;
};

export type RequestCustomerDataItemResponse = {
  id: string;
  name: string;
  amount: number;
  type: string;
  price: number;
  cost: number;
  expired: boolean;
  unitId: string;
  deliveryTime: number;
  validity: number;
};

export type RequestCustomerData = {
  id: string;
  budget: number;
  overBudget: number;
  remainsToPurchase: number;
  savings: number;
  currencyCode: string;
  items: RequestCustomerDataItem[];
};

export type RequestCustomerState = {
  request: RequestCustomerData | null;
  selectedIds: string[];
  createRequestCustomerStatus: FetchStatus;
  createOrderCustomerStatus: FetchStatus;
  fetchRequestCustomerStatus: FetchStatus;
  deleteRequestCustomerItemStatus: FetchStatus;
};
const initialState: RequestCustomerState = {
  request: null,
  selectedIds: [],
  createRequestCustomerStatus: FetchStatus.NotFetched,
  createOrderCustomerStatus: FetchStatus.NotFetched,
  fetchRequestCustomerStatus: FetchStatus.NotFetched,
  deleteRequestCustomerItemStatus: FetchStatus.NotFetched,
};

export const requestCustomerSlice = createSlice({
  name: 'requestCustomer',
  initialState,
  reducers: {
    setSelectedId: (state, action: PayloadAction<string[]>) => {
      state.selectedIds = [...state.selectedIds, ...action.payload];
    },
    deleteSelectedIds: (state, action: PayloadAction<string[]>) => {
      state.selectedIds = state.selectedIds.filter(
        (elem) => !action.payload.includes(elem)
      );
    },
    fullClearSelectedIds: (state) => {
      state.selectedIds = [];
    },

    createRequestCustomerRequest: (
      state,
      { payload }: PayloadAction<CreateRequestCustomerRequestAction>
    ) => {
      state.createRequestCustomerStatus = FetchStatus.Fetching;
    },
    createRequestCustomerSuccess: (state) => {
      state.createRequestCustomerStatus = FetchStatus.Fetched;
    },
    resetCreateRequestCustomerStatus: (state) => {
      state.createRequestCustomerStatus = FetchStatus.NotFetched;
    },
    setErrorCreateRequestCustomerStatus: (state) => {
      state.createRequestCustomerStatus = FetchStatus.Error;
    },

    createOrderCustomerRequest: (
      state,
      { payload }: PayloadAction<CreateOrderCustomerRequestAction>
    ) => {
      state.createOrderCustomerStatus = FetchStatus.Fetching;
    },
    createOrderCustomerSuccess: (state) => {
      state.createOrderCustomerStatus = FetchStatus.Fetched;
    },
    resetCreateOrderCustomerStatus: (state) => {
      state.createOrderCustomerStatus = FetchStatus.NotFetched;
    },
    setErrorCreateOrderCustomerStatus: (state) => {
      state.createOrderCustomerStatus = FetchStatus.Error;
    },

    fetchRequestCustomerRequest: (
      state,
      action: PayloadAction<FetchRequestCustomerRequestAction>
    ) => {
      state.fetchRequestCustomerStatus = FetchStatus.Fetching;
    },
    fetchRequestCustomerObjectSuccess: (
      state,
      action: PayloadAction<RequestCustomerData>
    ) => {
      state.request = action.payload;
      state.fetchRequestCustomerStatus = FetchStatus.Fetched;
    },

    deleteRequestCustomerItemSuccess: (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state
    ) => {
      state.deleteRequestCustomerItemStatus = FetchStatus.Fetched;
    },
    setDeleteRequestCustomerItemStatus: (
      state,
      action: PayloadAction<FetchStatus>
    ) => {
      state.deleteRequestCustomerItemStatus = action.payload;
    },
  },
});

export default requestCustomerSlice.reducer;
