import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { Saga } from '@sagas/types';
import { handleError } from '@sagas/errors';
import {
  FetchRequest,
  FetchRequestBasicDataRequestAction,
  FetchRequestById,
  FetchRequestRequestAction,
  FetchResponseRequestAction,
  FetchSendResponse,
  fetchRequestListRequestSuccess,
  fetchRequestRequestSuccess,
  fetchResponseRequestSuccess,
  setFetchingStatusRequest,
  setStatusFetchingData,
} from '@store/requestSupplier/actions';
import { Response } from '@store/requestSupplier/slice';
import { NoticeTypes } from '@utils/notifications';
import { addMessage } from '@store/errors/actions';
import { getResponse } from '@store/requestSupplier/selectors';
import { FetchStatus } from '@store/constants';
import {
  getRequestBySupplier,
  getRequestListBySupplier,
  sendResponseFromRequest,
} from './api/requestSupplier';

const fetchRequestHandler: Saga<FetchRequest> = function* ({ payload }) {
  try {
    const { status, supplierId } = payload;

    yield put(setStatusFetchingData({ status }));

    const data = yield call(getRequestBySupplier, { status, supplierId });
    yield put(fetchRequestRequestSuccess({ requests: data, status }));
  } catch (e) {
    yield call(handleError, e);
  }
};

const fetchRequestListHandler: Saga<FetchRequestById> = function* ({
  payload,
}) {
  try {
    const { requestId, status } = payload;

    const data = yield call(getRequestListBySupplier, {
      requestId,
      status,
    });
    yield put(
      fetchRequestListRequestSuccess({
        needClarification: data.needClarification || null,
        newResources: status === 'NEW' ? data : data.newResources || null,
        unansweredResources: data.unansweredResources || null,
        processedResources: data.processedResources || null,
        selectedResponses: data.selectedResponses || null,
      })
    );
  } catch (e) {
    yield call(handleError, e);
  }
};

const fetchResponseHandler: Saga<FetchSendResponse> = function* ({ payload }) {
  try {
    const { requestId, validity } = payload;

    yield put(
      setFetchingStatusRequest({ id: requestId, status: FetchStatus.Fetching })
    );

    const response: Response[] = yield select(getResponse);

    if (response.length === 0) {
      yield put(
        addMessage({
          id: 'errorSendResponse',
          message: 'notice.error-send-response',
          type: NoticeTypes.error,
        })
      );
      yield put(
        setFetchingStatusRequest({ id: requestId, status: FetchStatus.Fetched })
      );
      return;
    }

    yield call(sendResponseFromRequest, {
      requestId,
      response,
      validity,
    });

    yield put(fetchResponseRequestSuccess({ id: requestId, validity }));

    yield put(
      addMessage({
        id: 'createdResponse',
        message: 'notice.created-response',
        type: NoticeTypes.success,
      })
    );
  } catch (e) {
    yield call(handleError, e);
  }
};

export default function* root() {
  yield takeEvery(FetchRequestBasicDataRequestAction, fetchRequestHandler);
  yield takeLatest(FetchRequestRequestAction, fetchRequestListHandler);
  yield takeLatest(FetchResponseRequestAction, fetchResponseHandler);
}
