import React, { FC, memo, useRef } from 'react';

import { useOutsideClickListener } from '@hooks/useOutsideClickListener';
import { useTranslation } from 'react-i18next';

type EstimateMarkupSettingsProps = {
  onClick?: () => void;
  onCancel?: () => void;
};

const EstimateMarkupSettingsRaw: FC<EstimateMarkupSettingsProps> = ({
  onCancel,
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);

  useOutsideClickListener(ref, onCancel);

  return (
    <span ref={ref}>
      <p>{t('directory.markup')}</p>
      {/* <span ref={ref} onClick={onClick}> */}
      {/*  <p>{t('directory.markup')} <SettingIcon /></p> */}
      <div>
        <ul>
          <li>
            <s className="color-primary">{t('about-object.people-short')}</s>
            <b>{t('about-object.people')}</b>
            <input type="text" name="" />
            <small>%</small>
          </li>
          <li>
            <s className="color-warning">{t('about-object.materials-short')}</s>
            <b>{t('about-object.materials')}</b>
            <input type="text" name="" />
            <small>%</small>
          </li>
          <li>
            <s className="color-main">{t('about-object.meh-short')}</s>
            <b>{t('about-object.meh')}</b>
            <input type="text" name="" />
            <small>%</small>
          </li>
          <li>
            <s className="color-default">{t('about-object.overhead-short')}</s>
            <b>{t('about-object.overhead')}</b>
            <input type="text" name="" />
            <small>%</small>
          </li>
        </ul>
        <button className="snap">{t('estimate.refresh-estimate')}</button>
      </div>
    </span>
  );
};

export const EstimateMarkupSettings = memo(EstimateMarkupSettingsRaw);
