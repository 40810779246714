import classNames from 'classnames';
import './radio.sass';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IRadioButton {
  name: string;
  value: string;
}

interface IRadioGroup {
  buttons: IRadioButton[];
  label: string;
  error?: string;
  defaulValue?: string;
  setValue: (val: string) => void;
}

export default function RadioGroup({
  buttons,
  label,
  error,
  setValue,
  defaulValue,
}: IRadioGroup) {
  const [val, setVal] = useState(defaulValue);
  const { t } = useTranslation();
  useEffect(() => {
    if (val) setValue(val);
  }, [setValue, val]);

  return (
    <div className="radioGroup">
      <label>{t(label)}</label>
      <div className="radioGroup__group">
        {buttons.map((e) => {
          return (
            <div key={`radioitem-${e.value}`}>
              <div
                className={classNames('radioGroup__button', {
                  chek: e.value === val,
                })}
                onClick={() => setVal(e.value)}
              />
              {t(e.name)}
            </div>
          );
        })}
      </div>

      {error && <small>{t(error)}</small>}
    </div>
  );
}
