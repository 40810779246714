import React from 'react';
import { ReactComponent as LoginLogo1 } from '@static/img/login-logo1.svg';
import { ReactComponent as LoginLogo2 } from '@static/img/login-logo2.svg';

export const AuthLogo = () => {
  return (
    <div className="login__start">
      <i>
        <LoginLogo1 />
      </i>
      <s>
        <LoginLogo2 />
      </s>
    </div>
  );
};
