import { useAppSelector } from '@hooks/redux';

import { getRoles } from '@store/profile/selectors';
import { Role } from '@utils/enums';

export const useRole = () => {
  const roles = useAppSelector(getRoles);

  const isCustomer = roles?.includes(Role.customer);

  const isSupplier = roles?.includes(Role.supplier);

  return { isCustomer, isSupplier };
};
