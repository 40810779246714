import { axiosInstanceFile } from '@utils/axios';

export const useDownloadDocument = () => {
  const handleDownloadDocument = (
    urlDownloadDocument: string,
    originalFileName: string
  ) => {
    axiosInstanceFile
      .get(urlDownloadDocument, {
        responseType: 'blob',
      })
      .then((response) => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', originalFileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };

  return handleDownloadDocument;
};
