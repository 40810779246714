import { call } from 'redux-saga/effects';
import { ENDPOINTS } from '@config';
import { getRequest, postRequest, putRequest } from '@sagas/api';
import { CompanyId } from '@store/company/slice';
import { UnitId } from '@store/vocabulary/slice';
import { Resource, ResourceId, ResourceType } from '@store/resources/slice';
import { ResourceFolderId } from '@store/resourceFolders/slice';
import { urlFromTemplate } from '@utils/url';

export interface CreateResourceParams {
  name: string;
  type: ResourceType;
  price: number;
  markup: number;
  salePrice: number;
  unitId: UnitId;
  resourceFolderId: ResourceFolderId;
}

export interface UpdateResourceParams {
  name: string;
  type: ResourceType;
  price: number;
  markup: number;
  salePrice: number;
  unitId: UnitId;
}

export interface DeleteResourcesParams {
  ids: ResourceId[];
}

export function* getResources(companyId: CompanyId) {
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.RESOURCES.ITEMS.GET, { companyId })
  );

  return data as Resource[];
}

export function* createResource(
  companyId: CompanyId,
  params: CreateResourceParams
) {
  const { data } = yield call(
    postRequest,
    urlFromTemplate(ENDPOINTS.RESOURCES.ITEMS.CREATE, { companyId }),
    params
  );

  return data as Resource;
}

export function* updateResource(
  companyId: CompanyId,
  id: ResourceId,
  params: UpdateResourceParams
) {
  yield call(
    putRequest,
    urlFromTemplate(ENDPOINTS.RESOURCES.ITEMS.UPDATE, { companyId, id }),
    params
  );
}

export function* deleteResources(
  companyId: CompanyId,
  params: DeleteResourcesParams
) {
  yield call(
    postRequest,
    urlFromTemplate(ENDPOINTS.RESOURCES.ITEMS.DELETE, { companyId }),
    params
  );
}
