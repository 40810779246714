import { Modal, ModalSize } from '@components/Modal';
import Button from '@components/commons/Button';
import CategorySelect from '@components/commons/CategorySelect';
import { SecondaryButton } from '@components/commons/SecondaryButton';
import { ObjectModal } from '@pages/Dashboard/ObjectModal';
import { useTranslation } from 'react-i18next';

type Props = {
  isViewChoise: boolean;
  handleChoiseClose: () => void;
  handleSelectedCategory: (
    category: { id: string; name: string }[] | string[]
  ) => void;
  handleClickAddCategory: () => void;
};
const CategorySelectModal = ({
  isViewChoise,
  handleChoiseClose,
  handleSelectedCategory,
  handleClickAddCategory,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      visible={isViewChoise}
      size={ModalSize.Auto}
      onBackgroundClick={handleChoiseClose}
    >
      <ObjectModal onClose={handleChoiseClose}>
        <div className="outlay__resource-background-category">
          <CategorySelect onSelect={handleSelectedCategory} selected={[]} />
          <div className="outlay__resource-category-button">
            <Button
              name={t('estimate.select-a-category')}
              onClick={handleClickAddCategory}
            />
          </div>
          <SecondaryButton onClick={handleChoiseClose}>
            {t('confirm.cancel')}
          </SecondaryButton>
        </div>
      </ObjectModal>
    </Modal>
  );
};

export default CategorySelectModal;
