import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompanyObjectId } from '@store/companyObjects/slice';
import { FetchStatus } from '@store/constants';
import {
  CopyCostEstimateSuccessAction,
  CreateCommercialProposalRequestAction,
  CreateCommercialProposalSuccessAction,
  CreateCostEstimateSuccessAction,
  DeleteCommercialProposalSuccessAction,
  DeleteCostEstimateRequestAction,
  DeleteCostEstimateSuccessAction,
  FetchCompletelyFilledCommercialProposalSuccessAction,
  FetchCostEstimateRequestAction,
  FetchCostEstimateSuccessAction,
  FetchObjectCostEstimatesRequestAction,
  FetchObjectCostEstimatesSuccessAction,
  UpdateCommercialProposalSuccessAction,
} from '@store/costEstimates/actions';
import { CostEstimateSection } from '@store/costEstimateSections/slice';
import {
  refreshTokenError,
  signInSuccess,
  signOutSuccess,
} from '@store/user/actions';
import { getUnixTime } from 'date-fns';

export type HistoricalCostEstimateId = string;
export type CostEstimateId = string;
export type CommercialProposalsId = string;
export type SectionId = string;

export type CommercialProposalApproved = boolean;

export enum BidStatus {
  NEW = 'NEW',
  PROCESSED = 'PROCESSED',
  SELECTED = 'SELECTED',
  ORDERED = 'ORDERED',
  PRODUCTION = 'PRODUCTION',
}

export type Section = {
  id: SectionId;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: any; // TODO change to res type
  name: string;
};

export type CompletelyFilledCommercialProposal = {
  id: CommercialProposalsId;
  name: string;
  url: string;
  approved: boolean;
  startWorkAt: string;
  endWorkAt: string;
  content: string;
  clientFullName: string;
  managerFullName: string;
  managerPhone: string;
  includeFullCostEstimate: boolean;
  firstPayment: number;
  screenOrdering: number[];
  costEstimateId: HistoricalCostEstimateId;
  creationDate: number;
};

export type CommercialProposal = {
  id: CommercialProposalsId;
  url: string;
  startWorkAt: number;
  endWorkAt: number;
  creationDate: number;
  costEstimateId: HistoricalCostEstimateId;
  approved: CommercialProposalApproved;
};

export type CostEstimate = {
  id: CostEstimateId;
  buildingObjectId: CompanyObjectId;
  commercialProposalId: CommercialProposalsId;
  parentId: CostEstimateId | null;
  createdAt: number;
  currencyCode: string;
  cost: number;
  saleCost: number;
  sections: CostEstimateSection[];
  requestId: string;
};

export type ObjectCostEstimate = {
  id: CostEstimateId;
  buildingObjectId: CompanyObjectId;
  createdAt: number;
  currencyCode: string;
  cost: number;
  saleCost: number;
  costDetails: {
    MAT: { cost: number; saleCost: number };
    MEC: { cost: number; saleCost: number };
    HUM: { cost: number; saleCost: number };
    EXT: { cost: number; saleCost: number };
  };
  commercialProposals: CommercialProposal[];
  requestId: string;
};

// TODO: change currentEstimate persist logic
export type CostEstimatesState = {
  objectCostEstimatesStatus: FetchStatus;
  costEstimatesStatus: FetchStatus;
  commercialProposalStatus: FetchStatus;
  selectedIds: string[];
  objectCostEstimates: ObjectCostEstimate[];
  costEstimates: CostEstimate[];
  currentEstimate: ObjectCostEstimate | null;
  currentObjectCostEstimate: CostEstimate | null;
  сompletelyFilledCommercialProposal: CompletelyFilledCommercialProposal | null;
  activeCreateNewCostEstimate: boolean;
  deleteCostEstimateStatus: FetchStatus;
};

const initialState: CostEstimatesState = {
  objectCostEstimatesStatus: FetchStatus.NotFetched,
  costEstimatesStatus: FetchStatus.NotFetched,
  commercialProposalStatus: FetchStatus.NotFetched,
  selectedIds: [],
  objectCostEstimates: [],
  costEstimates: [],
  currentEstimate: null,
  currentObjectCostEstimate: null,
  сompletelyFilledCommercialProposal: null,
  activeCreateNewCostEstimate: false,
  deleteCostEstimateStatus: FetchStatus.NotFetched,
};

export const costEstimatesSlice = createSlice({
  name: 'costEstimates',
  initialState,
  reducers: {
    setSelectedId: (state, action: PayloadAction<string[]>) => {
      state.selectedIds = [...state.selectedIds, ...action.payload];
    },
    deleteSelectedIds: (state, action: PayloadAction<string[]>) => {
      state.selectedIds = state.selectedIds.filter(
        (elem) => !action.payload.includes(elem)
      );
    },
    clearSelectedIds: (state) => {
      state.selectedIds = [];
    },
    setCurrentEstimate: (state, { payload }) => {
      state.currentEstimate = payload;
    },
    setActiveCreateNewCostEstimate: (state, { payload }) => {
      state.activeCreateNewCostEstimate = payload;
    },
    resetCommercialProposalStatus: (state) => {
      state.commercialProposalStatus = FetchStatus.NotFetched;
    },
    fetchObjectCostEstimatesRequest: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<FetchObjectCostEstimatesRequestAction>
    ) => {
      state.objectCostEstimatesStatus = FetchStatus.Fetching;
    },
    fetchObjectCostEstimatesSuccess: (
      state,
      action: PayloadAction<FetchObjectCostEstimatesSuccessAction>
    ) => {
      const { data } = action.payload;

      state.objectCostEstimates = data;
      state.objectCostEstimatesStatus = FetchStatus.Fetched;
    },
    clearCostEstimates: (state) => {
      state.objectCostEstimates = [];
      state.costEstimates = [];
      state.currentObjectCostEstimate = null;
      state.costEstimatesStatus = FetchStatus.NotFetched;
      state.objectCostEstimatesStatus = FetchStatus.NotFetched;
    },

    fetchCostEstimateRequest: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<FetchCostEstimateRequestAction>
    ) => {
      state.costEstimatesStatus = FetchStatus.Fetching;
    },
    fetchCostEstimateSuccess: (
      state,
      action: PayloadAction<FetchCostEstimateSuccessAction>
    ) => {
      const { data } = action.payload;
      const index = state.costEstimates.findIndex(
        (costEstimate) => costEstimate.id === data.id
      );
      const indexObjectsCostEstimates = state.objectCostEstimates.findIndex(
        (i) => i.id === data.id
      );

      if (index === -1) {
        state.costEstimates.push(data);
      } else {
        state.costEstimates[index] = data;
        state.objectCostEstimates[indexObjectsCostEstimates] = {
          ...state.objectCostEstimates[indexObjectsCostEstimates],
          saleCost: data.saleCost,
        };
      }
      state.currentObjectCostEstimate = data;
      state.costEstimatesStatus = FetchStatus.Fetched;
    },
    fetchCompletelyFilledCommercialProposalSuccess: (
      state,
      action: PayloadAction<FetchCompletelyFilledCommercialProposalSuccessAction>
    ) => {
      const { data } = action.payload;

      state.сompletelyFilledCommercialProposal = data;
    },
    clearCompletelyFilledCommercialProposal: (state) => {
      state.сompletelyFilledCommercialProposal = null;
    },
    createCostEstimateSuccess: (
      state,
      { payload }: PayloadAction<CreateCostEstimateSuccessAction>
    ) => {
      const { data } = payload;

      const objData: ObjectCostEstimate = {
        id: data.id,
        buildingObjectId: data.buildingObjectId,
        createdAt: data.createdAt,
        currencyCode: data.currencyCode,
        cost: data.cost,
        saleCost: data.saleCost,
        costDetails: {
          MAT: { cost: 0, saleCost: 0 },
          MEC: { cost: 0, saleCost: 0 },
          HUM: { cost: 0, saleCost: 0 },
          EXT: { cost: 0, saleCost: 0 },
        },
        commercialProposals: [],
        requestId: '',
      };

      state.currentEstimate = objData;
      state.objectCostEstimates = [...state.objectCostEstimates, objData];
      state.costEstimates = [...state.costEstimates, data];
    },
    copyCostEstimateSuccess: (
      state,
      { payload }: PayloadAction<CopyCostEstimateSuccessAction>
    ) => {
      const { data } = payload;

      state.objectCostEstimates = [...state.objectCostEstimates, data];
      state.currentEstimate = data;
    },
    copyEstimateSuccess: (
      state,
      { payload }: PayloadAction<ObjectCostEstimate>
    ) => {
      state.objectCostEstimates.push(payload);
    },
    createCommercialProposalRequest: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<CreateCommercialProposalRequestAction>
    ) => {
      state.commercialProposalStatus = FetchStatus.Fetching;
    },
    createCommercialProposalSuccess: (
      state,
      { payload }: PayloadAction<CreateCommercialProposalSuccessAction>
    ) => {
      const { data, id } = payload;

      const index = state.objectCostEstimates.findIndex(
        (item) => item.id === id
      );

      const objData: CommercialProposal = {
        id: data.id || '',
        url: data.url || '',
        startWorkAt: getUnixTime(new Date(data.startWorkAt)),
        endWorkAt: getUnixTime(new Date(data.endWorkAt)),
        creationDate: getUnixTime(new Date(data.creationDate)),
        costEstimateId: data.costEstimateId || '',
        approved: data.approved || false,
      };

      state.commercialProposalStatus = FetchStatus.Fetched;
      state.objectCostEstimates[index].commercialProposals = [
        objData,
        ...state.objectCostEstimates[index].commercialProposals,
      ];
    },
    updateCommercialProposalSuccess: (
      state,
      { payload }: PayloadAction<UpdateCommercialProposalSuccessAction>
    ) => {
      const { data, costEstimateId, id } = payload;

      // current cost estimate index
      const costEstimateIndex = state.objectCostEstimates.findIndex(
        (objectCostEstimate) => objectCostEstimate.id === costEstimateId
      );
      // current CP index
      const commercialProposalIndex = state.objectCostEstimates[
        costEstimateIndex
      ].commercialProposals.findIndex((item) => item.costEstimateId === id);

      // TODO: Raise higher than saga
      // set {approved: false} for all estimates
      state.objectCostEstimates.forEach((objectCostEstimate, estimateIndex) => {
        objectCostEstimate.commercialProposals.forEach(
          (commercialProposal, proposalIndex) => {
            if (commercialProposal.approved === true) {
              state.objectCostEstimates[estimateIndex].commercialProposals[
                proposalIndex
              ] = {
                ...state.objectCostEstimates[estimateIndex].commercialProposals[
                  proposalIndex
                ],
                ...{ approved: false },
              };
            }
          }
        );
      });

      // set {approved: true} for current CP
      state.objectCostEstimates[costEstimateIndex].commercialProposals[
        commercialProposalIndex
      ] = {
        ...state.objectCostEstimates[costEstimateIndex].commercialProposals[
          commercialProposalIndex
        ],
        ...data,
      };
    },
    deleteCommercialProposalSuccess: (
      state,
      { payload }: PayloadAction<DeleteCommercialProposalSuccessAction>
    ) => {
      const { commercialProposalId } = payload;

      state.objectCostEstimates.forEach((objectCostEstimate, index) => {
        state.objectCostEstimates[index].commercialProposals =
          objectCostEstimate.commercialProposals.filter(
            (commercialProposal) => {
              return commercialProposal.id !== commercialProposalId;
            }
          );
      });
    },
    deleteCostEstimateRequest: (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<DeleteCostEstimateRequestAction>
    ) => {
      state.deleteCostEstimateStatus = FetchStatus.Fetching;
    },
    deleteCostEstimateSuccess: (
      state,
      { payload }: PayloadAction<DeleteCostEstimateSuccessAction>
    ) => {
      const { id } = payload;

      state.objectCostEstimates = state.objectCostEstimates.filter(
        (objectCostEstimate) => objectCostEstimate.id !== id
      );
      state.deleteCostEstimateStatus = FetchStatus.Fetched;
    },
    resetDeleteCostEstimateStatus: (state) => {
      state.deleteCostEstimateStatus = FetchStatus.NotFetched;
    },
    setErrorDeleteCostEstimateStatus: (state) => {
      state.deleteCostEstimateStatus = FetchStatus.Error;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signOutSuccess, () => initialState);
    builder.addCase(signInSuccess, () => initialState);
    builder.addCase(refreshTokenError, () => initialState);
  },
});

export default costEstimatesSlice.reducer;
