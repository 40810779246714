import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { Input } from '@components/EditCompany/Input';
import { useTranslation } from 'react-i18next';
import { inputErrors } from '@components/EditCompany/utils';
import { SettingsFormDataSupplier } from './type';

export type CompanyBankDetailsProps = {
  register: UseFormRegister<SettingsFormDataSupplier>;
  setValue: UseFormSetValue<SettingsFormDataSupplier>;
  control: Control<SettingsFormDataSupplier>;
  errors: FieldErrors<SettingsFormDataSupplier>;
  onBlur: (name: string) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

export default function BankDetails({
  errors,
  onBlur,
  register,
  onKeyDown,
}: CompanyBankDetailsProps) {
  const { t } = useTranslation();

  const inputNames = [
    {
      title: t('company.checking-account'),
      name: 'bankAccount' as const,
      maxLength: 20,
    },
    {
      title: t('company.name-bank'),
      name: 'bankName' as const,
      maxLength: 300,
    },
    {
      title: t('company.correspondent-account'),
      name: 'correspondentAccount' as const,
      maxLength: 20,
    },
    { title: t('company.bic'), name: 'bic' as const, maxLength: 9 },
  ];

  return (
    <div className="content__block">
      <div className="form__cot">
        <div className="form__cap">
          <h4>{t('company.bank-details')}</h4>
        </div>
      </div>
      <div className="form__cot">
        <div className="form__group">
          {inputNames.map((e) => {
            return (
              <Input
                {...register(e.name, {
                  onBlur: () => onBlur('bankAccount'),
                })}
                key={e.name}
                title={e.title}
                error={inputErrors(e.name, errors, t)}
                onKeyDown={onKeyDown}
                maxLength={e.maxLength}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
