import { FetchStatus } from '@store/constants';
import { OrderDocuments, OrderStatus } from '@store/ordersCustomer/slice';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconDoc } from '@static/img/document.svg';
import { ReactComponent as IconUpdate } from '@static/img/icon-update.svg';
import { ReactComponent as IconRemove } from '@static/img/icon-remove2.svg';
import InputFileUpload from '@components/commons/FieldFile';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import {
  deleteOrderCustomerProxyDocumentRequest,
  fetchOrderCustomerRequest,
  updateOrderCustomerFinishedStatusRequest,
} from '@store/ordersCustomer/actions';
import cn from 'classnames';
import { getUpdateOrderCustomerFinishedStatus } from '@store/ordersCustomer/selectors';
import IconButton from '@components/commons/IconButton';
import { useSearchParams } from 'react-router-dom';
import styles from './styles.module.sass';

type Props = {
  orderId: string;
  invoice: OrderDocuments;
  proxy: OrderDocuments;

  requestAnInvoiceStatus: FetchStatus;
  requestAnInvoice: () => void;
  needInvoice: boolean;
  status: OrderStatus;
};

const OrderModalHeaderButton = ({
  orderId,
  invoice,
  proxy,
  requestAnInvoice,
  needInvoice,
  status,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const finishedStatus = useAppSelector(getUpdateOrderCustomerFinishedStatus);
  const finishedStatusFetched = finishedStatus === FetchStatus.Fetched;

  const [searchParams] = useSearchParams();
  const costEstimateIdFromQuery = searchParams.get('costEstimateId');

  const getOrders = () => {
    if (costEstimateIdFromQuery) {
      dispatch(
        fetchOrderCustomerRequest({ costEstimateId: costEstimateIdFromQuery })
      );
    }
  };

  const handleClickUpdateOrderStatus = () => {
    if (costEstimateIdFromQuery) {
      dispatch(
        updateOrderCustomerFinishedStatusRequest({
          orderId,
          costEstimateId: costEstimateIdFromQuery,
          status: 'FINISHED',
        })
      );
    }
  };

  const handleDeleteProxyDocument = () => {
    if (costEstimateIdFromQuery) {
      dispatch(
        deleteOrderCustomerProxyDocumentRequest({
          orderDocumentId: proxy.id,
          costEstimateId: costEstimateIdFromQuery,
        })
      );
    }
  };

  const buttonView = {
    ACCEPTED: {
      invoiceLoadButton: (
        <IconButton
          className={cn('link', styles.button)}
          onClick={requestAnInvoice}
        >
          {t('orders.request-an-invoice-for-payment')}
        </IconButton>
      ),
    },
    PAYMENT: {
      paymentLoadButton: (
        <IconButton
          className="link upload"
          disabled={!invoice?.fileName}
          style={{ width: 'auto' }}
        >
          <InputFileUpload
            setUploadFileUrl={getOrders}
            partLoadFile="orderDocument"
            disabled={!invoice?.fileName}
            params={{ orderId, category: 'PAYMENT' }}
          >
            <span style={{ padding: '13px 30px', cursor: 'pointer' }}>
              {t('orders.upload-a-payment-order')}
            </span>
          </InputFileUpload>
        </IconButton>
      ),
    },
    READY: {
      proxyLoadButton: (
        <InputFileUpload
          setUploadFileUrl={getOrders}
          partLoadFile="orderDocument"
          params={{ orderId, category: 'PROXY' }}
        >
          <a className="link upload">
            {t('orders.download-the-power-of-attorney')}
          </a>
        </InputFileUpload>
      ),
      proxyFileName: (
        <div className="dct__item" style={{ height: '45px' }}>
          <i>
            <IconDoc />
          </i>
          <span>
            <h4>
              <b>{proxy?.originalFileName}</b>
            </h4>
            <input type="text" />
          </span>
          <ul>
            <InputFileUpload
              setUploadFileUrl={getOrders}
              partLoadFile="orderDocument"
              params={{ orderId, category: 'PROXY' }}
            >
              <li className="replace">
                <IconUpdate />
              </li>
            </InputFileUpload>
            <li className="remove">
              <IconButton
                onClick={handleDeleteProxyDocument}
                className={styles.removeProxyButton}
              >
                <IconRemove />
              </IconButton>
            </li>
          </ul>
        </div>
      ),
    },
    ISSUED: {
      issuedButton: (
        <IconButton
          className={cn(
            'link',
            finishedStatusFetched && 'light',
            styles.button
          )}
          onClick={handleClickUpdateOrderStatus}
        >
          {finishedStatusFetched
            ? t('orders.the-order-completed')
            : t('orders.confirm-successful-shipment')}
        </IconButton>
      ),
    },
  };

  return (
    <>
      {status === 'ACCEPTED' &&
        !needInvoice &&
        buttonView.ACCEPTED.invoiceLoadButton}
      {status === 'ACCEPTED' &&
        needInvoice &&
        buttonView.PAYMENT.paymentLoadButton}
      {status === 'PAYMENT' && buttonView.PAYMENT.paymentLoadButton}
      {status === 'READY' && (
        <>
          {proxy?.originalFileName
            ? buttonView.READY.proxyFileName
            : buttonView.READY.proxyLoadButton}
        </>
      )}
      {status === 'ISSUED' && buttonView.ISSUED.issuedButton}
    </>
  );
};

export default OrderModalHeaderButton;
