import { nanoid } from '@reduxjs/toolkit';
import { StatusRequest } from '@utils/enums';
import {
  getRequestsByStatus,
  getStatusByType,
} from '@store/requestSupplier/selectors';
import { useAppSelector } from '@hooks/redux';
import { FetchStatus } from '@store/constants';
import Loader from '@components/commons/Loader';
import ColumnItemInfo from '../ColumnItemInfo';

interface IColumnsData {
  showDetails: () => void;
  status: keyof typeof StatusRequest;
}

export default function ColumnsData({ showDetails, status }: IColumnsData) {
  const currentStatusRequest = useAppSelector((state) =>
    getRequestsByStatus(state, status)
  );

  const statusColumn = useAppSelector((state) =>
    getStatusByType(state, status)
  );

  return (
    <div className="object__column active" key={nanoid()}>
      {statusColumn === FetchStatus.Fetched ? (
        <>
          {currentStatusRequest.map((e) => {
            return (
              <ColumnItemInfo
                needClarification={e.needClarification}
                showDetails={showDetails}
                number={e.number}
                key={nanoid()}
                requestId={e.id}
                data={e.updatedAt}
                name={e.buildingObject.name}
                validity={e.validity}
                status={e.status}
              />
            );
          })}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}
