/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { RootState } from '@store';
import { nanoid } from '@reduxjs/toolkit';
import { toValidDataTime } from '@components/commons/Calendar';
import { Message } from './slice';

export const getRootChat = (state: RootState) => state.chat;

export const getConversations = (
  state: RootState,
  field: 'company' | 'supplier'
) =>
  getRootChat(state).conversations.filter((i) =>
    i[field].name.includes(getRootChat(state).strSearch)
  );

export const getConversationsOrderByNotRead = (
  state: RootState,
  field: 'company' | 'supplier'
) => {
  return [
    ...getConversations(state, field).filter(
      (i) => i.numberOfUnreadMessages > 0
    ),
    ...getConversations(state, field).filter(
      (i) => i.numberOfUnreadMessages === 0
    ),
  ];
};
export const getConversationIds = (state: RootState) =>
  getRootChat(state).conversations.map((e) => e.id);
export const getCurrentConversation = (state: RootState) =>
  getRootChat(state).currentСonversation;

export const getCurrentConversationId = (state: RootState) =>
  getRootChat(state).currentСonversation?.id;
export const getMessagesActiveConversation = (state: RootState, id?: string) =>
  getRootChat(state).messages.filter((i) => i.id === id);

export const getMessagesByConversationId = (state: RootState, id: string) =>
  getRootChat(state).messages.filter((i) => i.conversationId === id);

export const getMessages = (state: RootState, conversationId?: string) => {
  const msg: Record<string, Message[]> = {};
  const groupMessages = [];
  getRootChat(state)
    .messages.filter((i) => i.conversationId === conversationId)
    .forEach((i) => {
      const day = new Date(toValidDataTime(i.createdAt))
        .toUTCString()
        .split(' ')
        .slice(1, 3)
        .join(':');
      if (day in msg) {
        msg[day].push(i);
      } else {
        msg[day] = [];
        msg[day].push(i);
      }
    });

  for (const key in msg) {
    groupMessages.push({ day: key, messages: msg[key], id: nanoid() });
  }
  return groupMessages;
};

export const getUnreadchats = (state: RootState) =>
  getRootChat(state).numberOfConversationsWithUnreadedMessages;

export const getIdLastMessageConversationById = (
  state: RootState,
  id: string
) => {
  const idMessage = getMessagesByConversationId(state, id)[
    getMessagesByConversationId(state, id).length - 1
  ];
  return idMessage?.id || undefined;
};

export const getFetchStatusLoadingMessages = (state: RootState) =>
  getRootChat(state).fetchStatusLoadingMessages;

export const getFetchStatus = (state: RootState) =>
  getRootChat(state).fetchStatus;

export const getIsSendingMessage = (state: RootState) =>
  getRootChat(state).isSendingMessage;
