import cn from 'classnames';
import { SetStateAction } from 'react';
import { ListItems } from './types';

type Props = {
  activeTab?: string;
  listItems: ListItems[];
  onChange: (value: SetStateAction<string>) => void;
  className?: string;
};

const Tabs = ({ onChange, listItems, activeTab, className }: Props) => {
  return (
    <div className={className} data-tab="client-client">
      <ul>
        {listItems?.map((e) => (
          <li
            className={cn({ active: e.value === activeTab })}
            onClick={() => onChange(e.value)}
            key={e.value}
          >
            {e.title}
          </li>
        ))}
      </ul>
    </div>
  );
};
export default Tabs;
