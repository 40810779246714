import { CompanyId } from '@store/company/slice';
import { CompanyObjectId } from '@store/companyObjects/slice';
import { CostEstimateId, SectionId } from '@store/costEstimates/slice';
import {
  CostEstimateSectionId,
  costEstimateSectionsSlice,
} from '@store/costEstimateSections/slice';
import {
  FetchedCostEstimateSection,
  CreateCostEstimateSectionData,
  UpdateCostEstimateSectionData,
} from '@sagas/api/costEstimateSections';

export type FetchCostEstimateSectionRequestAction = {
  sectionId: SectionId;
};
export type FetchCostEstimateSectionSuccessAction = {
  data: FetchedCostEstimateSection;
};

export type FetchCostEstimateSectionsRequestAction = {
  companyId: CompanyId;
  objectId: CompanyObjectId;
  costEstimateId: CostEstimateId;
};
export type FetchCostEstimateSectionsSuccessAction = {
  data: FetchedCostEstimateSection[];
};

export type CreateCostEstimateSectionRequestAction = {
  temporaryId: string;
  companyId: CompanyId;
  objectId: CompanyObjectId;
  costEstimateId: CostEstimateId;
  data: CreateCostEstimateSectionData;
};
export type CreateCostEstimateSectionSuccessAction = {
  temporaryId: string;
  companyId: CompanyId;
  objectId: CompanyObjectId;
  costEstimateId: CostEstimateId;
  data: FetchedCostEstimateSection;
};

export type UpdateCostEstimateSectionRequestAction = {
  companyId: CompanyId;
  objectId: CompanyObjectId;
  costEstimateId: CostEstimateId;
  id: CostEstimateSectionId;
  data: UpdateCostEstimateSectionData;
};
export type UpdateCostEstimateSectionSuccessAction = {
  companyId: CompanyId;
  objectId: CompanyObjectId;
  costEstimateId: CostEstimateId;
  id: CostEstimateSectionId;
  data: FetchedCostEstimateSection;
};

export type DeleteCostEstimateSectionRequestAction = {
  companyId: CompanyId;
  objectId: CompanyObjectId;
  costEstimateId: CostEstimateId;
  id: CostEstimateSectionId;
};
export type DeleteCostEstimateSectionSuccessAction = {
  companyId: CompanyId;
  objectId: CompanyObjectId;
  costEstimateId: CostEstimateId;
  id: CostEstimateSectionId;
};

export type MoveCostEstimateSectionsAction = {
  companyId: CompanyId;
  objectId: CompanyObjectId;
  costEstimateId: CostEstimateId;
  toMove: -1 | 1;
  objectElementId: string;
  orderingObjectElement: number;
};

export const {
  fetchCostEstimateSectionSuccess,
  fetchCostEstimateSectionRequest,
  setErrorDeleteCostEstimateSectionStatus,
  resetDeleteCostEstimateSectionStatus,
  resetCostEstimateDeleteStatus,
  fetchCostEstimateSectionsRequest,
  fetchCostEstimateSectionsSuccess,
  createCostEstimateSectionRequest,
  createCostEstimateSectionSuccess,
  updateCostEstimateSectionRequest,
  updateCostEstimateSectionSuccess,
  deleteCostEstimateSectionRequest,
  deleteCostEstimateSectionSuccess,
  moveCostEstimateRequest,
} = costEstimateSectionsSlice.actions;
