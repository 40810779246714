import { useAppSelector } from '@hooks/redux';
import {
  getProfileCompanyId,
  isProfileFetched,
} from '@store/profile/selectors';

export const useCompanyId = () => {
  const isFetched = useAppSelector(isProfileFetched);
  const companyId = useAppSelector(getProfileCompanyId);

  if (isFetched && companyId === undefined) {
    throw Error('Something wrong with your company.');
  }

  return companyId;
};
