import { call } from 'redux-saga/effects';
import { ENDPOINTS } from '@config/endpoints';
import { getRequest } from '@sagas/api';
import {
  Category,
  City,
  Country,
  Currency,
  FoundCategory,
  Language,
  Unit,
} from '@store/vocabulary/slice';
import { urlFromTemplate } from '@utils/url';

type GetVocabularyResponse = {
  countries: Country[];
  cities: City[];
  currencies: Currency[];
  languages: Language[];
  units: Unit[];
  categories: Category[];
};

export function* getVocabulary() {
  const { data } = yield call(getRequest, ENDPOINTS.VOCABULARY.GET);

  return data as GetVocabularyResponse;
}

export function* getFoundCategory(request: string) {
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.VOCABULARY.GET_SEARCH_CATEGORY, { request })
  );

  return data as FoundCategory;
}
