import React, { FC } from 'react';
import styles from './styles.module.sass';

type SignUpHeaderProps = {
  title: string;
  subtitle: string;
};

export const SignUpHeader: FC<SignUpHeaderProps> = ({ title, subtitle }) => {
  return (
    <div className={styles.sign_up_header}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.subtitle}>{subtitle}</p>
    </div>
  );
};
