import { useEffect } from 'react';
import { currencyFormat, currencyParse } from '@utils/currency';
import { percentFormat, percentParse } from '@utils/percent';

type UseOfferGeneratorCalculationProps = {
  prePayment: string;
  postPayment: string;
  prePaymentPercent: string;
  postPaymentPercent: string;
  totalCost: number;
  currencyCode: string;
  locale: string;
  onChange: (
    name:
      | 'prePayment'
      | 'postPayment'
      | 'prePaymentPercent'
      | 'postPaymentPercent',
    value: string
  ) => void;
};

// TODO: should be refactored
export const useOfferGeneratorCalculation = ({
  prePayment,
  postPayment,
  prePaymentPercent,
  postPaymentPercent,
  totalCost,
  currencyCode,
  locale,
  onChange,
}: UseOfferGeneratorCalculationProps) => {
  useEffect(() => {
    if (
      prePayment === '' ||
      prePayment === `${currencyParse(prePayment, locale)}`
    ) {
      const newprePayment = prePayment === '' ? 0 : parseFloat(prePayment);
      onChange(
        'postPayment',
        currencyFormat(totalCost - newprePayment, currencyCode, locale)
      );
      onChange(
        'prePaymentPercent',
        percentFormat((newprePayment / totalCost) * 100, locale)
      );
      onChange(
        'postPaymentPercent',
        percentFormat(((totalCost - newprePayment) / totalCost) * 100, locale)
      );
    }
  }, [prePayment, totalCost, locale, currencyCode, onChange]);

  useEffect(() => {
    if (
      postPayment === '' ||
      postPayment === `${currencyParse(postPayment, locale)}`
    ) {
      const newpostPayment = postPayment === '' ? 0 : parseFloat(postPayment);
      onChange(
        'prePayment',
        currencyFormat(totalCost - newpostPayment, currencyCode, locale)
      );
      onChange(
        'prePaymentPercent',
        percentFormat(((totalCost - newpostPayment) / totalCost) * 100, locale)
      );
      onChange(
        'postPaymentPercent',
        percentFormat((newpostPayment / totalCost) * 100, locale)
      );
    }
  }, [postPayment, totalCost, locale, currencyCode, onChange]);

  useEffect(() => {
    if (
      prePaymentPercent === '' ||
      prePaymentPercent === `${percentParse(prePaymentPercent, locale)}`
    ) {
      const newprePaymentPercent =
        prePaymentPercent === '' ? 0 : parseFloat(prePaymentPercent);
      onChange(
        'prePayment',
        currencyFormat(
          totalCost * (newprePaymentPercent / 100),
          currencyCode,
          locale
        )
      );
      onChange(
        'postPayment',
        currencyFormat(
          totalCost - totalCost * (newprePaymentPercent / 100),
          currencyCode,
          locale
        )
      );
      onChange(
        'postPaymentPercent',
        percentFormat(100 - newprePaymentPercent, locale)
      );
    }
  }, [prePaymentPercent, totalCost, locale, currencyCode, onChange]);

  useEffect(() => {
    if (
      postPaymentPercent === '' ||
      postPaymentPercent === `${percentParse(postPaymentPercent, locale)}`
    ) {
      const newpostPaymentPercent =
        postPaymentPercent === '' ? 0 : parseFloat(postPaymentPercent);
      onChange(
        'prePayment',
        currencyFormat(
          totalCost - totalCost * (newpostPaymentPercent / 100),
          currencyCode,
          locale
        )
      );
      onChange(
        'postPayment',
        currencyFormat(
          totalCost * (newpostPaymentPercent / 100),
          currencyCode,
          locale
        )
      );
      onChange(
        'prePaymentPercent',
        percentFormat(100 - newpostPaymentPercent, locale)
      );
    }
  }, [postPaymentPercent, totalCost, locale, currencyCode, onChange]);
};
