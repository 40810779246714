import React from 'react';
import checkIcon from '@static/img/icon-check3.svg';
import { useTranslation } from 'react-i18next';
import './styles.sass';

export const SaveCompanyButton = () => {
  const { t } = useTranslation();

  return (
    <button type="submit">
      {t('profile.save-changes')}
      <img src={checkIcon} alt={t('profile.save-changes') ?? ''} />
    </button>
  );
};
