import {
  all,
  call,
  put,
  takeLatest,
  takeEvery,
  debounce,
} from 'redux-saga/effects';
import { Saga } from '@sagas/types';
import { handleError } from '@sagas/errors';
import {
  FetchCostEstimateOperationsRequestAction,
  CreateCostEstimateOperationRequestAction,
  UpdateCostEstimateOperationRequestAction,
  DeleteCostEstimateOperationRequestAction,
  CreateCostEstimateOperationsWithResourcesRequestAction,
  fetchCostEstimateOperationsRequest,
  fetchCostEstimateOperationsSuccess,
  createCostEstimateOperationRequest,
  createCostEstimateOperationSuccess,
  updateCostEstimateOperationRequest,
  updateCostEstimateOperationSuccess,
  deleteCostEstimateOperationRequest,
  deleteCostEstimateOperationSuccess,
  createCostEstimateOperationsWithResourcesRequest,
  CostEstimateOperationMoveRequestActions,
  fetchostEstimateOperationMoveRequest,
  setErrorDeleteCostEstimateOperationStatus,
  FetchCostEstimateOperationRequestAction,
  fetchCostEstimateOperationRequest,
  fetchCostEstimateOperationSuccess,
} from '@store/costEstimateOperations/actions';
import {
  createCostEstimateOperation,
  createCostEstimateOperationWithResources,
  deleteCostEstimateOperation,
  getCostEstimateOperation,
  getCostEstimateOperations,
  moveCostEstimateOperation,
  updateCostEstimateOperation,
} from '@sagas/api/costEstimateOperations';
import {
  fetchCostEstimateResourcesRequest,
  createCostEstimateResourceSuccess,
  updateCostEstimateResourceSuccess,
  deleteCostEstimateResourceSuccess,
} from '@store/costEstimateResources/actions';
import { CompanyId } from '@store/company/slice';
import { CompanyObjectId } from '@store/companyObjects/slice';
import { CostEstimateId } from '@store/costEstimates/slice';
import { fetchCostEstimateSectionsRequest } from '@store/costEstimateSections/actions';

export type RefreshCostEstimateOperationsAction = {
  companyId: CompanyId;
  objectId: CompanyObjectId;
  costEstimateId: CostEstimateId;
};

const fetchCostEstimateOperationRequestHandler: Saga<FetchCostEstimateOperationRequestAction> =
  function* ({ payload }) {
    const { operationId } = payload;

    try {
      const costEstimateOperation = yield call(
        getCostEstimateOperation,
        operationId
      );
      yield put(
        fetchCostEstimateOperationSuccess({ data: costEstimateOperation })
      );
    } catch (e) {
      yield call(handleError, e);
    }
  };

const fetchCostEstimateOperationsRequestHandler: Saga<FetchCostEstimateOperationsRequestAction> =
  function* ({ payload }) {
    const { companyId, objectId, costEstimateId } = payload;

    try {
      const costEstimateOperations = yield call(
        getCostEstimateOperations,
        companyId,
        objectId,
        costEstimateId
      );
      yield put(
        fetchCostEstimateOperationsSuccess({ data: costEstimateOperations })
      );
    } catch (e) {
      yield call(handleError, e);
    }
  };

const createCostEstimateOperationRequestHandler: Saga<CreateCostEstimateOperationRequestAction> =
  function* ({ payload }) {
    const {
      companyId,
      objectId,
      costEstimateId,
      sectionId,
      temporaryId,
      data,
    } = payload;

    try {
      const costEstimateOperation = yield call(
        createCostEstimateOperation,
        companyId,
        objectId,
        costEstimateId,
        data
      );
      yield put(
        createCostEstimateOperationSuccess({
          companyId,
          objectId,
          costEstimateId,
          sectionId,
          temporaryId,
          data: costEstimateOperation,
        })
      );
    } catch (e) {
      yield put(
        fetchCostEstimateOperationsRequest({
          companyId,
          objectId,
          costEstimateId,
        })
      );
      yield call(handleError, e);
    }
  };

const updateCostEstimateOperationRequestHandler: Saga<UpdateCostEstimateOperationRequestAction> =
  function* ({ payload }) {
    const { companyId, objectId, costEstimateId, id, data } = payload;

    try {
      const costEstimate = yield call(
        updateCostEstimateOperation,
        companyId,
        objectId,
        costEstimateId,
        id,
        data
      );
      yield put(
        updateCostEstimateOperationSuccess({
          companyId,
          objectId,
          costEstimateId,
          id,
          data: costEstimate,
        })
      );
    } catch (e) {
      yield put(
        fetchCostEstimateOperationsRequest({
          companyId,
          objectId,
          costEstimateId,
        })
      );
      yield call(handleError, e);
    }
  };

const deleteCostEstimateOperationRequestHandler: Saga<DeleteCostEstimateOperationRequestAction> =
  function* ({ payload }) {
    const { companyId, objectId, costEstimateId, id } = payload;

    try {
      yield call(
        deleteCostEstimateOperation,
        companyId,
        objectId,
        costEstimateId,
        id
      );
      yield put(
        deleteCostEstimateOperationSuccess({
          companyId,
          objectId,
          costEstimateId,
          id,
        })
      );
    } catch (e) {
      yield put(
        fetchCostEstimateOperationsRequest({
          companyId,
          objectId,
          costEstimateId,
        })
      );
      yield put(setErrorDeleteCostEstimateOperationStatus());
      yield call(handleError, e);
    }
  };
const fetchCostEstimateOperationMoveRequestHandler: Saga<CostEstimateOperationMoveRequestActions> =
  function* ({ payload }) {
    const {
      companyId,
      costEstimateId,
      objectId,
      objectElementId,
      subjectElementId,
      toMove,
    } = payload;

    const countPoint = toMove === -1 ? 'before' : 'after';
    const elementType = 'operation';
    try {
      yield call(
        moveCostEstimateOperation,
        companyId,
        objectId,
        costEstimateId,
        { countPoint, elementType, objectElementId, subjectElementId }
      );
    } catch (e) {
      yield call(handleError, e);
    }
  };
const createCostEstimateOperationWithResourcesRequestHandler: Saga<CreateCostEstimateOperationsWithResourcesRequestAction> =
  function* ({ payload }) {
    const { companyId, objectId, costEstimateId, operationsData } = payload;

    if (operationsData.length === 0) {
      return;
    }

    try {
      const operations = [];
      for (let o = 0; o < operationsData.length; o = +1) {
        operations.push({
          id: operationsData[o].id,
          amount: operationsData[o].amount,
        });
      }

      yield call(
        createCostEstimateOperationWithResources,
        companyId,
        objectId,
        costEstimateId,
        {
          sectionId: operationsData[0].sectionId,
          operations,
        }
      );

      yield put(
        fetchCostEstimateSectionsRequest({
          companyId,
          objectId,
          costEstimateId,
        })
      );
      yield put(
        fetchCostEstimateOperationsRequest({
          companyId,
          objectId,
          costEstimateId,
        })
      );
      yield put(
        fetchCostEstimateResourcesRequest({
          companyId,
          objectId,
          costEstimateId,
        })
      );
    } catch (e) {
      yield put(
        fetchCostEstimateOperationsRequest({
          companyId,
          objectId,
          costEstimateId,
        })
      );
      yield call(handleError, e);
    }
  };

const refreshCostEstimateOperationsHandler: Saga<RefreshCostEstimateOperationsAction> =
  function* ({ payload }) {
    const { companyId, objectId, costEstimateId } = payload;

    yield put(
      fetchCostEstimateOperationsRequest({
        companyId,
        objectId,
        costEstimateId,
      })
    );
  };

export default function* root() {
  yield all([
    takeLatest(
      fetchCostEstimateOperationRequest,
      fetchCostEstimateOperationRequestHandler
    ),
    takeLatest(
      fetchCostEstimateOperationsRequest,
      fetchCostEstimateOperationsRequestHandler
    ),
    takeEvery(
      createCostEstimateOperationRequest,
      createCostEstimateOperationRequestHandler
    ),
    takeEvery(
      updateCostEstimateOperationRequest,
      updateCostEstimateOperationRequestHandler
    ),
    takeEvery(
      deleteCostEstimateOperationRequest,
      deleteCostEstimateOperationRequestHandler
    ),
    takeEvery(
      createCostEstimateOperationsWithResourcesRequest,
      createCostEstimateOperationWithResourcesRequestHandler
    ),
    takeEvery(
      fetchostEstimateOperationMoveRequest,
      fetchCostEstimateOperationMoveRequestHandler
    ),
    debounce(
      500,
      [
        updateCostEstimateOperationSuccess.type,
        createCostEstimateResourceSuccess.type,
        updateCostEstimateResourceSuccess.type,
        deleteCostEstimateResourceSuccess.type,
      ],
      refreshCostEstimateOperationsHandler
    ),
  ]);
}
