import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EntityStatus, FetchStatus } from '@store/constants';
import {
  FetchCostEstimateOperationsRequestAction,
  FetchCostEstimateOperationsSuccessAction,
  CreateCostEstimateOperationRequestAction,
  CreateCostEstimateOperationSuccessAction,
  UpdateCostEstimateOperationRequestAction,
  UpdateCostEstimateOperationSuccessAction,
  DeleteCostEstimateOperationRequestAction,
  DeleteCostEstimateOperationSuccessAction,
  CostEstimateOperationMoveRequestActions,
  FetchCostEstimateOperationRequestAction,
  FetchCostEstimateOperationSuccessAction,
} from '@store/costEstimateOperations/actions';
import { UnitId } from '@store/vocabulary/slice';

import { CostEstimateSectionId } from '@store/costEstimateSections/slice';

export type CostEstimateOperationId = string;

export type CostEstimateOperation = {
  status: EntityStatus;
  id: CostEstimateOperationId;
  sectionId: CostEstimateSectionId;
  name: string;
  amount: number;
  price: number;
  cost: number;
  salePrice: number;
  saleCost: number;
  markup: number;
  unitId: UnitId;
  fromCopyId?: string;
  ordering: number;
};

export type CostEstimatesState = {
  status: FetchStatus;
  items: CostEstimateOperation[];
  copyFromId: string;
  lastCreatedId: string | null;
  deleteOperationStatus: FetchStatus;
};

const initialState: CostEstimatesState = {
  status: FetchStatus.NotFetched,
  items: [],
  copyFromId: '',
  lastCreatedId: null,
  deleteOperationStatus: FetchStatus.NotFetched,
};

export const costEstimateOperationsSlice = createSlice({
  name: 'costEstimateOperations',
  initialState,
  reducers: {
    fetchostEstimateOperationMoveRequest: (
      state,
      action: PayloadAction<CostEstimateOperationMoveRequestActions>
    ) => {
      const indexSubjectElement = state.items.findIndex(
        (i) => i.id === action.payload.subjectElementId
      );
      const indexObjectElement = state.items.findIndex(
        (i) => i.id === action.payload.objectElementId
      );

      const indexStartInsert =
        indexObjectElement < indexSubjectElement
          ? indexSubjectElement - (action.payload.toMove === -1 ? 1 : 0)
          : indexSubjectElement + (action.payload.toMove === -1 ? 0 : 1);

      const data = [...state.items];

      data.splice(indexObjectElement, 1);

      data.splice(indexStartInsert, 0, {
        ...state.items[indexObjectElement],
      });

      state.items = data.map((e, i) => {
        return { ...e, ordering: i + 1 };
      });
    },
    setCopyOperation: (state, action) => {
      state.copyFromId = action.payload;
    },

    fetchCostEstimateOperationRequest: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<FetchCostEstimateOperationRequestAction>
    ) => {
      state.status = FetchStatus.Fetching;
    },
    fetchCostEstimateOperationSuccess: (
      state,
      { payload }: PayloadAction<FetchCostEstimateOperationSuccessAction>
    ) => {
      const { data } = payload;
      const index = state.items.findIndex((o) => o.id === data.id);
      if (index !== -1) {
        state.items[index] = { ...data, status: EntityStatus.Fetched };
      }
      state.status = FetchStatus.Fetched;
    },

    fetchCostEstimateOperationsRequest: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<FetchCostEstimateOperationsRequestAction>
    ) => {
      state.status = FetchStatus.Fetching;
    },
    fetchCostEstimateOperationsSuccess: (
      state,
      { payload }: PayloadAction<FetchCostEstimateOperationsSuccessAction>
    ) => {
      const { data } = payload;

      state.status = FetchStatus.Fetched;
      state.items = data.map((d) => ({ ...d, status: EntityStatus.Fetched }));
    },

    createCostEstimateOperationRequest: (
      state,
      { payload }: PayloadAction<CreateCostEstimateOperationRequestAction>
    ) => {
      const { temporaryId, data } = payload;

      state.items.push({
        ...data,
        id: temporaryId,
        status: EntityStatus.Fetching,
      });
    },
    createCostEstimateOperationSuccess: (
      state,
      { payload }: PayloadAction<CreateCostEstimateOperationSuccessAction>
    ) => {
      const { temporaryId, data } = payload;
      state.lastCreatedId = data.id;
      const index = state.items.findIndex((item) => item.id === temporaryId);

      state.items[index] = {
        ...data,
        fromCopyId: state.copyFromId,
        status: EntityStatus.Fetched,
      };
    },
    updateCostEstimateOperationRequest: (
      state,
      { payload }: PayloadAction<UpdateCostEstimateOperationRequestAction>
    ) => {
      const { id, data } = payload;
      const index = state.items.findIndex((item) => item.id === id);

      state.items[index] = {
        ...state.items[index],
        ...data,
      };
    },
    updateCostEstimateOperationSuccess: (
      state,
      { payload }: PayloadAction<UpdateCostEstimateOperationSuccessAction>
    ) => {
      const { id, data } = payload;
      const index = state.items.findIndex((item) => item.id === id);

      state.items[index] = {
        ...state.items[index],
        ...data,
      };
    },
    deleteCostEstimateOperationRequest: (
      state,
      { payload }: PayloadAction<DeleteCostEstimateOperationRequestAction>
    ) => {
      const { id } = payload;

      state.items = state.items.filter((item) => item.id !== id);
      state.deleteOperationStatus = FetchStatus.Fetching;
    },
    deleteCostEstimateOperationSuccess: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<DeleteCostEstimateOperationSuccessAction>
    ) => {
      state.deleteOperationStatus = FetchStatus.Fetched;
    },
    resetDeleteCostEstimateOperationStatus: (state) => {
      state.deleteOperationStatus = FetchStatus.NotFetched;
    },
    setErrorDeleteCostEstimateOperationStatus: (state) => {
      state.deleteOperationStatus = FetchStatus.Error;
    },
  },
});

export default costEstimateOperationsSlice.reducer;
