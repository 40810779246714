import React, { ForwardedRef, forwardRef } from 'react';
import cn from 'classnames';
import { ChangeHandler } from 'react-hook-form';

export type InputProps = {
  maxLength?: number;
  title: string;
  name: string;
  onChange: ChangeHandler;
  onBlur: ChangeHandler;
  isSmall?: boolean;
  error?: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

const InputWithRef = (
  {
    title,
    name,
    onChange,
    onBlur,
    isSmall,
    error,
    onKeyDown,
    maxLength = 16,
  }: InputProps,
  ref: ForwardedRef<HTMLInputElement>
) => {
  return (
    <div
      className={cn('input', 'border', {
        small: isSmall,
        error,
      })}
    >
      <input
        placeholder="0"
        type="text"
        name={name}
        ref={ref}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        maxLength={maxLength}
      />
      <label>{title}</label>
      {error && <small>{error}</small>}
    </div>
  );
};

export const Input = forwardRef<HTMLInputElement, InputProps>(InputWithRef);
