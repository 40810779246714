import React, { memo } from 'react';
import { OperationCatalogBreadcrumbs } from './Breadcrumbs';

const OperationCatalogHeaderRaw: React.FC = () => {
  return (
    <div className="manager__cap">
      <OperationCatalogBreadcrumbs />
      <div className="manager__but" />
    </div>
  );
};

export const OperationCatalogHeader = memo(OperationCatalogHeaderRaw);
