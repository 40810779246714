import React, {
  FC,
  memo,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '@hooks/redux';
import { useCompanyId } from '@hooks/useCompanyId';
import { useTableRowForm } from '@hooks/useTableRowForm';
import { useOperationCalculation } from '@hooks/useOperationCalculation';
import { useCurrentLocale } from '@hooks/useCurrentLocale';
import { useLocalizedUnits } from '@hooks/useUnits';
import { ResourceId } from '@store/resources/slice';
import { Operation, OperationId } from '@store/operations/slice';
import { OperationFolderId } from '@store/operationFolders/slice';
import { Unit, UnitId } from '@store/vocabulary/slice';
import {
  createOperationRequest,
  updateOperationRequest,
  deleteOperationsRequest,
  addOperationResourcesRequest,
} from '@store/operations/actions';
import { OperationCatalogContext } from '@components/OperationsCatalog';
import { TableSelect } from '@components/Table/Select';
import { ResourcesCatalogModal } from '@components/ResourcesCatalogModal';
import { currencyFormat } from '@utils/currency';
import { percentFormat } from '@utils/percent';
import { ReactComponent as ArrowIcon } from '@static/img/icon-arrow6.svg';
import removeIcon from '@static/img/icon-remove-blue.svg';
import addIcon from '@static/img/icon-add5.svg';
import { nanoid } from '@reduxjs/toolkit';
import { EntityStatus } from '@store/constants';
import { OperationsCatalogOperationResource } from '../OperationResource';
import './styles.sass';

type OperationsCatalogOperationProps = {
  operation: Operation;
  create?: boolean;
  opened: boolean;
  onCancel?: () => void;
  onOperationOpenChange: (id: OperationId, opened: boolean) => void;
};

type FormData = {
  id: OperationId;
  name: string;
  amount: number;
  unitId: UnitId;
  operationFolderId: OperationFolderId;
};

const OperationsCatalogOperationRaw: FC<OperationsCatalogOperationProps> = ({
  operation,
  create = false,
  opened = false,
  onCancel,
  onOperationOpenChange,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const locale = useCurrentLocale();
  const units = useLocalizedUnits();
  const { isSelectedOperation, onSelectResources } = useContext(
    OperationCatalogContext
  );
  const [createResource, setCreateResource] = useState(false);
  const companyId = useCompanyId();
  const { operationPrice, operationMarkup, operationSalePrice } =
    useOperationCalculation(operation);
  const fetching = operation.status === EntityStatus.Fetching;

  const selectedOperation = isSelectedOperation(operation);

  const handleCreateResource = () => {
    setCreateResource(true);
  };

  const handleArrowClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    onOperationOpenChange(operation.id, !opened);
  };

  const handleCheckboxClick = () => {
    onSelectResources(
      operation.id,
      operation.resources.map((resource) => resource.id)
    );
  };

  const handleDeleteClick = useCallback(() => {
    if (companyId !== undefined) {
      dispatch(
        deleteOperationsRequest({ companyId, data: { ids: [operation.id] } })
      );
    }
  }, [companyId, operation.id, dispatch]);

  const handleAddResources = (resourceIds: ResourceId[]) => {
    if (companyId === undefined) {
      return;
    }

    dispatch(
      addOperationResourcesRequest({
        companyId,
        id: operation.id,
        data: {
          resources: resourceIds.map((id) => ({ id, amount: 1 })),
        },
      })
    );
  };

  const handleCreateResourceCancel = () => {
    setCreateResource(false);
  };

  const { register, control, handleSubmit, setValue, getValues, reset } =
    useForm<FormData>({
      defaultValues: operation,
    });

  const {
    rowRef,
    editable,
    handleInputFocus,
    handleInputBlur,
    handleInputKeyUp,
    handleFormSubmit,
  } = useTableRowForm({
    defaultEditable: create,
    onInputCancel: () => {
      reset(operation);
      onCancel?.();
    },
    onEnter: () => {
      onCancel?.();
    },
    onOutsideClick: () => {
      // for some reason outside click doesn't trigger blur with form `reset()`
      (document?.activeElement as HTMLInputElement)?.blur();
      onCancel?.();
    },
    onInputBlur: () => {
      handleFormSubmit();
    },
    onFormSubmit: handleSubmit((formData) => {
      const { id, ...data } = formData;

      if (companyId === undefined) {
        return;
      }

      if (data.name.trim() === '') {
        return;
      }

      if (create) {
        onCancel?.();
        dispatch(
          createOperationRequest({
            companyId,
            temporaryId: `create-${nanoid()}`,
            data: {
              name: data.name,
              amount: data.amount,
              unitId: data.unitId,
              operationFolderId: data.operationFolderId,
            },
          })
        );
      } else {
        dispatch(
          updateOperationRequest({
            companyId,
            id,
            data: {
              name: data.name,
              amount: data.amount,
              unitId: data.unitId,
            },
          })
        );
      }
    }),
  });

  useEffect(() => {
    if (!editable) {
      onCancel?.();
    }
  }, [editable, onCancel]);

  return (
    <>
      <div className="manager__operation" ref={rowRef}>
        <div
          className={cn('manager__operation-item', {
            open: opened,
            edit: editable,
            fetching,
          })}
        >
          {fetching && <div className="manager__operation-edit" />}
          <span
            className="manager__operation-check check"
            onClick={handleCheckboxClick}
          >
            <input
              type="checkbox"
              name="resources"
              value="1"
              checked={selectedOperation}
              readOnly
            />
            {!create && <label />}
          </span>
          {!create && (
            <div
              className="manager__operation-slider"
              onClick={handleArrowClick}
            >
              <i>
                <ArrowIcon />
              </i>
            </div>
          )}
          <div className="manager__operation-name" title={getValues('name')}>
            <input
              type="text"
              placeholder="Название операции"
              autoFocus={create}
              onFocus={handleInputFocus}
              onKeyUp={handleInputKeyUp}
              {...register('name', {
                setValueAs: (v) => v.trim(),
                onBlur: handleInputBlur,
              })}
            />
          </div>
          <div
            className="manager__operation-count"
            data-title={t('directory.quantity')}
          >
            <input
              type="text"
              onFocus={handleInputFocus}
              onKeyUp={handleInputKeyUp}
              {...register('amount', {
                onChange: (e) => {
                  setValue('amount', e.target.value.replace(/[^\d.]/g, ''));
                },
                setValueAs: (v) => parseFloat(v),
                onBlur: handleInputBlur,
              })}
            />
          </div>
          <div
            className="manager__operation-unit"
            data-title={t('directory.unit')}
          >
            <TableSelect<Unit, FormData>
              name="unitId"
              control={control}
              listItems={units}
              onSelectFocus={handleInputFocus}
              onSelectBlur={handleInputBlur}
              onSelectChange={handleFormSubmit}
            />
          </div>
          <div
            className="manager__operation-price"
            data-title={t('directory.price')}
          >
            <input
              type="text"
              readOnly
              value={currencyFormat(
                operationPrice,
                operation.currency.code,
                locale
              )}
            />
          </div>
          <div
            className="manager__operation-markup"
            data-title={t('directory.markup')}
          >
            <input
              type="text"
              readOnly
              value={percentFormat(operationMarkup, locale)}
            />
          </div>
          <div
            className="manager__operation-client"
            data-title={t('directory.for-customer')}
          >
            <input
              type="text"
              readOnly
              value={currencyFormat(
                operationSalePrice,
                operation.currency.code,
                locale
              )}
            />
          </div>
          {!editable && (
            <div
              className="manager__operation-remove"
              onClick={handleDeleteClick}
            >
              <img src={removeIcon} alt={t('directory.remove') ?? ''} />
            </div>
          )}
        </div>
        {!create && (
          <div className={cn('manager__operation-list', { open: opened })}>
            {operation.resources.map((resource) => (
              <OperationsCatalogOperationResource
                key={resource.id}
                operationId={operation.id}
                operationResource={resource}
              />
            ))}
            <div className="manager__add-res" onClick={handleCreateResource}>
              <i>
                <img src={addIcon} alt={t('directory.add-resource') ?? ''} />
              </i>
              {t('directory.add-resource')}
            </div>
          </div>
        )}
      </div>
      <ResourcesCatalogModal
        visible={createResource}
        onAddResources={handleAddResources}
        onCancel={handleCreateResourceCancel}
      />
    </>
  );
};

export const OperationsCatalogOperation = memo(OperationsCatalogOperationRaw);
