import React, { FC, memo, useContext } from 'react';
import cn from 'classnames';
import { useAppSelector } from '@hooks/redux';
import { ResourcesCatalogContext } from '@components/ResourcesCatalog';
import { getSubFolders } from '@store/resourceFolders/selectors';
import { ResourcesCatalogAddFolder } from '@components/ResourcesCatalog/FolderList/AddFolder';
import { ResourcesCatalogAddFolderButton } from './AddFolderButton';
import { ResourcesCatalogFolder } from './Folder';

type ResourcesCatalogFolderListType = {
  createFolder: boolean;
  onCreateFolderClick: () => void;
  onSubmitFolder: () => void;
  onCancelFolderEdit: () => void;
};

const ResourcesCatalogFolderListRaw: FC<ResourcesCatalogFolderListType> = ({
  createFolder,
  onCreateFolderClick,
  onCancelFolderEdit,
}) => {
  const { folderId, onChangeFolder, size } = useContext(
    ResourcesCatalogContext
  );
  const folders = useAppSelector((state) => getSubFolders(state, folderId));

  return (
    <div className={cn('manager__folders', size)}>
      {folders?.map((folder) => (
        <ResourcesCatalogFolder
          key={folder.id}
          folder={folder}
          onClick={onChangeFolder}
        />
      ))}
      {!createFolder ? (
        <ResourcesCatalogAddFolderButton onClick={onCreateFolderClick} />
      ) : (
        <ResourcesCatalogAddFolder
          parentFolderId={folderId}
          onSubmit={onCancelFolderEdit}
          onCancel={onCancelFolderEdit}
        />
      )}
    </div>
  );
};

export const ResourcesCatalogFolderList = memo(ResourcesCatalogFolderListRaw);
