import { SecondaryButton } from '@components/commons/SecondaryButton';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import {
  getFullContacts,
  getTotalContacts,
  getlastUpdatedContact,
  isFetchContactsFetched,
} from '@store/contacts/selectors';
import {
  getFullOrganizations,
  getTotalOrganizations,
  getlastUpdatedOrganization,
  isFetchOrganizationsFetched,
} from '@store/organizations/selectors';
import { ReactComponent as IconAdd } from '@static/img/icon-add.svg';
import Button from '@components/commons/Button';
import { FormEventHandler, useEffect, useRef, useState } from 'react';
import { getProfileCompanyId } from '@store/profile/selectors';
import { fetchFullContactsObjectRequest } from '@store/contacts/actions';
import { fetchFullOrganizationsObjectRequest } from '@store/organizations/actions';
import TableWithVirtualScroll, {
  Columns,
} from '@components/commons/TableWithVirtualScroll';
import { FullContacts } from '@store/contacts/slice';
import { FullOrganizations } from '@store/organizations/slice';
import { useTranslation } from 'react-i18next';
import { useDidUpdateEffect } from '@hooks/useDidUpdateEffect';
import Empty from '@components/commons/Empty';
import InfiniteScroll from 'react-infinite-scroll-component';
import InputChoiseModal from '../InputChoiseModal';

type ChoiceModalProps = {
  viewModal: 'contact' | 'organization';
  onClose: () => void;
  onCreateFormOpen: () => void;
  onSelect: (data: unknown) => void;
};

const max = 10;

const ChoiceModal = ({
  viewModal,
  onClose,
  onCreateFormOpen,
  onSelect,
}: ChoiceModalProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const companyId = useAppSelector(getProfileCompanyId);
  const fullContacts = useAppSelector(getFullContacts);
  const fullOrganizations = useAppSelector(getFullOrganizations);
  const totalContacts = useAppSelector(getTotalContacts);
  const totalOrganizations = useAppSelector(getTotalOrganizations);
  const fetchContactsStatus = useAppSelector(isFetchContactsFetched);
  const fetchOrganizationsStatus = useAppSelector(isFetchOrganizationsFetched);
  const lastUpdatedContact = useAppSelector(getlastUpdatedContact);
  const lastUpdatedOrganization = useAppSelector(getlastUpdatedOrganization);

  const clientsRef = useRef<InfiniteScroll>(null);
  const companyRef = useRef<InfiniteScroll>(null);

  const [contactsPage, setContactsPage] = useState(1);
  const [organizationsPage, setOrganizationsPage] = useState(1);

  const [selectedOrganization, setSelectedOrganization] = useState<
    string | undefined
  >('');
  const [selectedContact, setSelectedContact] = useState<string | undefined>(
    ''
  );

  const currentContact = fullContacts.find((e) => e.id === selectedContact);
  const currentOrganization = fullOrganizations.find(
    (e) => e.id === selectedOrganization
  );

  const handleChoiseContact = (id: string) => {
    setSelectedContact(id);
  };
  const handleChoiseOrganization = (id: string) => {
    setSelectedOrganization(id);
  };

  const handleClickModalContacts = () => {
    onCreateFormOpen();
  };

  const handleCancelClick = () => {
    setSelectedContact(undefined);
    setSelectedOrganization(undefined);
    onClose();
  };

  const columnsContacts: Columns<FullContacts>[] = [
    {
      children: '',
      render: (record) => (
        <InputChoiseModal
          name={record.firstName}
          lastNameOrOrganizationForm={record.lastName}
        />
      ),
    },
  ];

  const columnsOrganizations: Columns<FullOrganizations>[] = [
    {
      children: '',
      render: (record) => (
        <InputChoiseModal
          name={record.name}
          lastNameOrOrganizationForm={record.organizationForm}
        />
      ),
    },
  ];

  let formData = {
    title: t('object.select-clients'),
    content: (
      <>
        {fullContacts.length === 0 && fetchContactsStatus ? (
          <Empty
            firstLine="clients.to-get-started-add-your-first-client"
            secondLine="clients.after-that-you-will-be-able-to-select-a-contact-person"
          />
        ) : (
          <TableWithVirtualScroll
            columns={columnsContacts}
            data={fullContacts}
            onScroll={() => setContactsPage((prev) => prev + 1)}
            bodyStyles={{
              minHeight: '60px',
              maxHeight: 'calc(100vh - 270px)',
              overflow: 'auto',
            }}
            onRowClick={handleChoiseContact}
            rowKey="id"
            total={totalContacts}
            disableHeader
            ref={clientsRef}
          />
        )}
      </>
    ),
    addNewButtonText: t('object.add-a-new-client'),
    successButtonText: t('object.select-a-contact-person'),
  };

  if (viewModal === 'organization') {
    formData = {
      title: t('object.select-organization'),
      content: (
        <>
          {fullOrganizations.length === 0 && fetchOrganizationsStatus ? (
            <Empty
              firstLine="organization.to-get-started-add-your-first-company"
              secondLine="organization.after-that-you-will-be-able-to-choose-an-organization"
            />
          ) : (
            <TableWithVirtualScroll
              columns={columnsOrganizations}
              data={fullOrganizations}
              onScroll={() => setOrganizationsPage((prev) => prev + 1)}
              bodyStyles={{
                minHeight: '60px',
                maxHeight: 'calc(100vh - 270px)',
                overflow: 'auto',
              }}
              onRowClick={handleChoiseOrganization}
              rowKey="id"
              total={totalOrganizations}
              disableHeader
              ref={companyRef}
            />
          )}
        </>
      ),
      addNewButtonText: t('object.add-a-new-organization'),
      successButtonText: t('object.select-an-organization'),
    };
  }

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (viewModal === 'organization') {
      onSelect(currentOrganization);
    } else {
      onSelect(currentContact);
    }
  };

  /* Этот эффект реагирует на создание и изменение контакта. При создании скидывает страницу на первую, чтобы стриггерить загрузку.
  А если страница и так была первая, перезагружает ее. */
  useDidUpdateEffect(() => {
    if (lastUpdatedContact?.action === 'CREATE') {
      clientsRef.current?.getScrollableTarget()?.scrollTo({ top: 0 });
      if (contactsPage === 1 && companyId) {
        dispatch(
          fetchFullContactsObjectRequest({
            id: companyId,
            page: 1,
            max,
            isUpdatePage: true,
          })
        );
      } else {
        setContactsPage(1);
      }
    }
  }, [lastUpdatedContact]);

  /* Этот эффект реагирует на создание и изменение организации. При создании скидывает страницу на первую, чтобы стриггерить загрузку.
  А если страница и так была первая, перезагружает ее. */
  useDidUpdateEffect(() => {
    if (lastUpdatedOrganization?.action === 'CREATE') {
      companyRef.current?.getScrollableTarget()?.scrollTo({ top: 0 });
      if (organizationsPage === 1 && companyId) {
        dispatch(
          fetchFullOrganizationsObjectRequest({
            id: companyId,
            page: 1,
            max,
            isUpdatePage: true,
          })
        );
      } else {
        setOrganizationsPage(1);
      }
    }
  }, [lastUpdatedOrganization]);

  useEffect(() => {
    if (viewModal === 'contact' && companyId) {
      dispatch(
        fetchFullContactsObjectRequest({
          id: companyId,
          page: contactsPage,
          max: 10,
        })
      );
    }
  }, [companyId, contactsPage, dispatch, viewModal]);

  useEffect(() => {
    if (viewModal === 'organization' && companyId) {
      dispatch(
        fetchFullOrganizationsObjectRequest({
          id: companyId,
          page: organizationsPage,
          max: 10,
        })
      );
    }
  }, [companyId, dispatch, organizationsPage, viewModal]);

  return (
    <form action="#" className="form" onSubmit={handleSubmit}>
      <div className="form__cap">
        <h4>{formData.title}</h4>
      </div>
      <div className="database">
        {/* Будет использоваться после реализации на бэке <div className="database__search">
          <input
            type="text"
            name="name"
            placeholder={t('object.database-search') as string}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div> */}
        <div className="database__check">
          {formData.content}
          <div className="database__add">
            <span onClick={handleClickModalContacts} className="link min white">
              <IconAdd />
              {formData.addNewButtonText}
            </span>
          </div>
        </div>
      </div>
      <div className="form__group">
        <Button name={formData.successButtonText} />
        <SecondaryButton onClick={handleCancelClick}>
          {t('object.cancel-the-selection')}
        </SecondaryButton>
      </div>
    </form>
  );
};

export default ChoiceModal;
