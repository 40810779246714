import React, {
  createContext,
  FC,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import cn from 'classnames';
import { ROOT_FOLDER_ID } from '@constants/folders';
import { useAppDispatch } from '@hooks/redux';
import { useCompanyId } from '@hooks/useCompanyId';
import { fetchResourcesRequest } from '@store/resources/actions';
import { ResourceId } from '@store/resources/slice';
import { fetchOperationsRequest } from '@store/operations/actions';
import { Operation, OperationId } from '@store/operations/slice';
import { fetchOperationFoldersRequest } from '@store/operationFolders/actions';
import { OperationFolderId } from '@store/operationFolders/slice';
import { ResourceFolderId } from '@store/resourceFolders/slice';
import { OperationCatalogHeader } from './Header';
import { OperationCatalogFolderList } from './FolderList';
import { OperationsCatalogOperationList } from './OperationList';

export enum OperationsCatalogSize {
  Minimal = 'min',
  Normal = '',
}

type OperationCatalogContextValue = {
  size: OperationsCatalogSize;
  folderId: OperationFolderId;
  onChangeFolder: (id: OperationFolderId) => void;
  selectedResources: string[];
  isSelectedResource: (
    operationId: OperationId,
    resourceId: ResourceId
  ) => boolean;
  isSelectedOperation: (operation: Operation) => boolean;
  onSelectResources: (
    operationId: OperationId,
    resourceIds: ResourceId[],
    forceSelected?: boolean
  ) => void;
};

export const OperationCatalogContext =
  createContext<OperationCatalogContextValue>({
    size: OperationsCatalogSize.Normal,
    folderId: ROOT_FOLDER_ID,
    onChangeFolder: () => {},
    selectedResources: [],
    isSelectedResource: () => false,
    isSelectedOperation: () => false,
    onSelectResources: () => {},
  });

type OperationsCatalogProps = {
  className?: string;
  size?: OperationsCatalogSize;
  folderId: ResourceFolderId;
  onChangeFolder: (id: ResourceFolderId) => void;
  selectedResources: ResourceId[];
  isSelectedResource: (
    operationId: OperationId,
    resourceId: ResourceId
  ) => boolean;
  isSelectedOperation: (operation: Operation) => boolean;
  onSelectResources: (
    operationId: OperationId,
    resourceIds: ResourceId[],
    forceSelected?: boolean
  ) => void;
};

export const OperationsCatalog: FC<OperationsCatalogProps> = ({
  className,
  size = OperationsCatalogSize.Normal,
  folderId,
  onChangeFolder,
  selectedResources,
  isSelectedResource,
  isSelectedOperation,
  onSelectResources,
}) => {
  const dispatch = useAppDispatch();
  const [activeAddButton, setActiveAddButton] = React.useState(false);
  const companyId = useCompanyId();

  useEffect(() => {
    if (companyId !== undefined) {
      dispatch(fetchOperationFoldersRequest({ companyId }));
      dispatch(fetchOperationsRequest({ companyId }));
      dispatch(fetchResourcesRequest({ companyId }));
    }
  }, [dispatch, companyId]);

  const handleCreateFolder = useCallback(() => setActiveAddButton(true), []);
  const handleSubmitFolder = useCallback(() => setActiveAddButton(false), []);
  const handleCancelFolderEdit = useCallback(
    () => setActiveAddButton(false),
    []
  );

  const catalogContextProps = useMemo(
    () => ({
      size,
      folderId,
      onChangeFolder,
      selectedResources,
      isSelectedResource,
      isSelectedOperation,
      onSelectResources,
    }),
    [
      size,
      folderId,
      onChangeFolder,
      selectedResources,
      isSelectedResource,
      isSelectedOperation,
      onSelectResources,
    ]
  );

  return (
    <OperationCatalogContext.Provider value={catalogContextProps}>
      <div className={cn('manager', className)}>
        <OperationCatalogHeader onCreateFolderClick={handleCreateFolder} />
        {!folderId && (
          <OperationCatalogFolderList
            activeAddButton={activeAddButton}
            onCreateFolderClick={handleCreateFolder}
            onSubmitFolder={handleSubmitFolder}
            onCancelFolderEdit={handleCancelFolderEdit}
          />
        )}
        {folderId && (
          <OperationsCatalogOperationList onCreateOperationClick={() => {}} />
        )}
      </div>
    </OperationCatalogContext.Provider>
  );
};
