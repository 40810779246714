import { ReactElement } from 'react';
import ReactDOM from 'react-dom/client';

export const addPopUp = (callback: (close: () => void) => ReactElement) => {
  const popUpRoot = ReactDOM.createRoot(
    document.getElementById('popup') as HTMLElement
  );
  const close = () => popUpRoot.unmount();

  popUpRoot.render(callback(close));
};
