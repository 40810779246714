const symbols = {
  RUB: '₽',
  USD: '$',
} as Record<string, string>;

export const currencyFormat = (
  value: number,
  currency: string,
  locale: string
): string => {
  const formattedValue = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay: 'code',
  }).format(parseFloat(value.toFixed(2)));

  // Fix currency symbol for case: locale - en, currency - 'RUB'
  return formattedValue.replace(currency, symbols[currency]);
};

export const currencyParse = (value: string, locale: string) => {
  if (value === '') {
    return 0;
  }

  const thousandSeparator = Intl.NumberFormat(locale)
    .format(11111)
    .replace(/\p{Number}/gu, '');
  const decimalSeparator = Intl.NumberFormat(locale)
    .format(1.1)
    .replace(/\p{Number}/gu, '');

  return parseFloat(
    value
      .replace(new RegExp(`\\${thousandSeparator}`, 'g'), '')
      .replace(new RegExp(`\\${decimalSeparator}`), '.')
      .replace(/[^\d.,]/g, '')
  );
};
