import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useTranslation } from 'react-i18next';
import { getProfileCompanyId } from '@store/profile/selectors';
import {
  fetchCompanyRequest,
  saveCompanyRequest,
  setImageProfileCompany,
  setSaveCompanyStatus,
} from '@store/company/actions';
import {
  getCompany,
  getCompanySaveStatus,
  isCompanyFetched,
} from '@store/company/selectors';
import { Input } from '@components/EditCompany/Input';
import { NoticeTypes } from '@utils/notifications/notice';
import { Notice } from '@components/Notification/Notice';
import { useFormErrors } from '@hooks/useFormErrors';
import { inputErrors } from '@components/EditCompany/utils';
import { SaveCompanyButton } from '@components/SaveCompanyButton';
// import { DeleteCompanyButton } from '@components/DeleteCompanyButton';
import BankDetails from '../BankDetails/BankDetails';
import { ContactInfo } from '../ContactInfo/ContactInfo';
import { CompanyInfo } from '../CompanyInfo/CompanyInfo';

import styles from './styles.module.sass';
import { SettingsFormData } from './type';

import { ProfileCompany } from '../ProfileCompany/ProfileCompany';

export const SettingsCompany = () => {
  const dispatch = useAppDispatch();
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors: formErrors },
    watch,
  } = useForm<SettingsFormData>();
  const errors = useFormErrors(formErrors);
  const { t } = useTranslation();
  const companyId = useAppSelector(getProfileCompanyId);
  const companyFetched = useAppSelector(isCompanyFetched);
  const company = useAppSelector(getCompany);
  const companySaveStatus = useAppSelector(getCompanySaveStatus);

  const [isShowNotification, setShowNotification] = useState(false);

  const handleBlurInput = (name: string) => {
    const trimedValue = String(
      getValues(name as keyof SettingsFormData)
    ).trim();

    setValue(name as keyof SettingsFormData, trimedValue);
  };

  useEffect(() => {
    if (companyId !== undefined) {
      dispatch(fetchCompanyRequest({ id: companyId }));
    }
  }, [dispatch, companyId]);

  useEffect(() => {
    if (companyFetched && company !== null) {
      reset({
        id: company.id,
        name: company.name,
        email: company.email,
        phone: company.phone,
        website: company.website,
        countryId: company.countryId,
        cityId: company.cityId,
        address: company.address,
        description: company.description,
        employeesAmount: company.employeesAmount,
        marketExperience: company.marketExperience,
        projectsAmount: company.projectsAmount,
        bankName: company.bankName,
        bankAccount: company.bankAccount,
        bic: company.bic,
        correspondentAccount: company.correspondentAccount,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyFetched, reset]);

  const saveCompanyHandler = handleSubmit((data) => {
    dispatch(saveCompanyRequest({ data }));
  });

  const handleKeyDownInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter') {
      event.currentTarget.blur();

      saveCompanyHandler();
    }
  };

  useEffect(() => {
    if (companySaveStatus) {
      setTimeout(() => {
        setShowNotification(false);
        dispatch(setSaveCompanyStatus(false));
      }, 2000);
      setShowNotification(true);
    }
  }, [companySaveStatus, dispatch]);

  const handleSetUploadFileId = (imageId: string) => {
    setValue('imageId', imageId);
  };

  const handleSetUploadFileUrl = (url: string) => {
    dispatch(setImageProfileCompany({ filePath: url }));

    // диспачим url в сущность или во времменное использоание если сущности нет такой в сторе
  };

  return (
    <>
      {isShowNotification && (
        <Notice
          date="now"
          type={NoticeTypes.success}
          timeout={2000}
          onClose={() => setShowNotification(false)}
          message={t('confirm.data-saved')}
        />
      )}
      <form className="content__core" onSubmit={saveCompanyHandler}>
        <input type="hidden" {...register('id')} />
        <input type="hidden" {...register('imageId')} />
        <div className="content__base">
          <div className="content__block">
            <div className="form__cot">
              <div className="form__cap">
                <h4 className={styles.header}>{t('profile.base-info')}</h4>
                <SaveCompanyButton />
                {/* Ждем когда бэк у себя поправят и дальше уберем коммент */}
                {/* <DeleteCompanyButton /> */}
              </div>
              <div className="form__group">
                <Input
                  {...register('name', {
                    onBlur: () => handleBlurInput('name'),
                  })}
                  title={t('profile.company-name')}
                  error={inputErrors('name', errors, t)}
                  onKeyDown={handleKeyDownInput}
                  maxLength={100}
                />
              </div>
            </div>
            <ContactInfo
              watch={watch}
              register={register}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              onBlur={handleBlurInput}
              onKeyDown={handleKeyDownInput}
            />
            <CompanyInfo
              register={register}
              control={control}
              setValue={setValue}
              errors={errors}
              onBlur={handleBlurInput}
              onKeyDown={handleKeyDownInput}
            />
          </div>
        </div>
        <div className="content__aside">
          <ProfileCompany
            setUploadFileId={handleSetUploadFileId}
            setUploadFile={handleSetUploadFileUrl}
            image={company?.filePath}
          />
          <BankDetails
            register={register}
            control={control}
            setValue={setValue}
            errors={errors}
            onBlur={handleBlurInput}
            onKeyDown={handleKeyDownInput}
          />
        </div>
      </form>
    </>
  );
};
