/* eslint-disable jsx-a11y/control-has-associated-label */
import { ReactComponent as IconUpload } from '@static/img/icon-upload2.svg';
import { useAppDispatch, useAppSelector } from '@hooks/redux';

import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import {
  fetchMessagesConversationIdRequestAction,
  fetchNewMessagecurrentConversationAction,
  fetchSendMessageRequestAction,
  setReadAllMessageCurrentConversation,
} from '@store/chat/actions';
import WebSocketContext from '@context/WebSocketContext';
import { getIdLastMessageConversationById } from '@store/chat/selectors';

type ControlProps = {
  conversationId: string;
};

export default function Control({ conversationId }: ControlProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const lastMessgeId = useAppSelector((state) =>
    getIdLastMessageConversationById(state, conversationId)
  );

  const { receiveMessage, sendMessageToJson } = useContext(WebSocketContext);

  const handleSend = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      const currentElement = e.target as HTMLTextAreaElement;
      dispatch(
        fetchSendMessageRequestAction({
          content: currentElement.value,
          conversationId,
        })
      );
      setTimeout(
        () =>
          sendMessageToJson({
            id: conversationId,
          }),
        1000
      );
      currentElement.value = '';
    }
  };

  useEffect(() => {
    if (receiveMessage && receiveMessage.id === conversationId) {
      if (lastMessgeId) {
        dispatch(
          fetchNewMessagecurrentConversationAction({
            id: lastMessgeId,
            conversationId,
          })
        );
      } else {
        dispatch(
          fetchMessagesConversationIdRequestAction({
            conversationId,
          })
        );
      }
    }
  }, [conversationId, dispatch, lastMessgeId, receiveMessage]);

  useEffect(() => {
    dispatch(setReadAllMessageCurrentConversation({ id: conversationId }));
  }, [conversationId, dispatch]);

  return (
    <div className="chat__control">
      <div className="chat__form">
        <i>
          <IconUpload />
        </i>

        <textarea
          name="text"
          placeholder="Сообщение..."
          onKeyUp={(e) => handleSend(e)}
        />
        <span>{t('chat.message')}</span>
        <button />
      </div>
    </div>
  );
}
