import React, { useEffect, useMemo } from 'react';
import i18next from 'i18next';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { isAuthorized } from '@store/user/selectors';
import { isVocabularyNotFetched } from '@store/vocabulary/selectors';
import { fetchVocabularyRequest } from '@store/vocabulary/actions';
import { getProfile, isProfileNotFetched } from '@store/profile/selectors';
import { fetchProfileRequest } from '@store/profile/actions';
import { Routing } from '@pages';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import MessageQueue from '@components/messageQueue';
import { useWs } from '@hooks/useWs';
import WebSocketContext from '@context/WebSocketContext';
import { getCurrentConversationId } from '@store/chat/selectors';
import { fetchAcessToConversationRequestAction } from '@store/chat/actions';

const App = () => {
  const dispatch = useAppDispatch();
  const authorized = useAppSelector(isAuthorized);
  const vocabularyNotFetched = useAppSelector(isVocabularyNotFetched);
  const profileNotFetched = useAppSelector(isProfileNotFetched);

  const profile = useAppSelector(getProfile);

  const { receiveMessage, sendMessageToJson, closeListiner } = useWs(
    'wss://46.8.19.222:8000'
  );

  const socket = useMemo(
    () => ({ receiveMessage, sendMessageToJson, closeListiner }),
    [closeListiner, receiveMessage, sendMessageToJson]
  );

  useEffect(() => {
    if (authorized && vocabularyNotFetched) {
      dispatch(fetchVocabularyRequest());
    }
  }, [authorized, vocabularyNotFetched, dispatch]);

  useEffect(() => {
    if (authorized && profileNotFetched) {
      dispatch(fetchProfileRequest());
    }
  }, [authorized, profileNotFetched, dispatch]);

  const changeLanguage = (lng: string) => {
    i18next.changeLanguage(lng);
  };

  useEffect(() => {
    if (profile && i18next.language !== profile?.language.code) {
      changeLanguage(profile?.language.code);
    }
  }, [profile]);

  const conversationId = useAppSelector(getCurrentConversationId);

  useEffect(() => {
    if (receiveMessage && receiveMessage.id !== conversationId) {
      dispatch(
        fetchAcessToConversationRequestAction({
          conversationId: receiveMessage.id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiveMessage]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="app">
        <WebSocketContext.Provider value={socket}>
          <Routing />
          <MessageQueue />
        </WebSocketContext.Provider>
      </div>
    </DndProvider>
  );
};

export default App;
