import React, { FC } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { PHASE_CLASSES } from '@constants/phases';

export type ConfirmModalProps = {
  confirmTitle: string;
  onCancel: () => void;
  onConfirm: () => void;
};

export const ConfirmModal: FC<ConfirmModalProps> = ({
  confirmTitle,
  onCancel,
  onConfirm,
}) => {
  const { t } = useTranslation();

  const dangerClass = PHASE_CLASSES[1];

  return (
    <div className="modal__min">
      <div className="modal__close" onClick={onCancel} />
      <div className="modal__min-title center">
        <h4>{t('confirm.attention')}</h4>
        <p>{confirmTitle}</p>
      </div>
      <div className="modal__min-submit">
        <a className="link modal-close transparent border" onClick={onCancel}>
          {t('confirm.cancel')}
        </a>
        <button className={cn('link', dangerClass)} onClick={onConfirm}>
          {t('confirm.confirm')}
        </button>
      </div>
    </div>
  );
};
