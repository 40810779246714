import { call } from 'redux-saga/effects';
import { ENDPOINTS } from '@config';
import { deleteRequest, getRequest, postRequest, putRequest } from '@sagas/api';
import { CompanyObjectId } from '@store/companyObjects/slice';
import { CostEstimateId } from '@store/costEstimates/slice';
import {
  CostEstimateSectionId,
  CostEstimateSectionItems,
} from '@store/costEstimateSections/slice';
import { ResourceType } from '@store/resources/slice';
import { CompanyId } from '@store/company/slice';
import { urlFromTemplate } from '@utils/url';
import { SectionId } from '@components/Estimate';

export type FetchedCostEstimateSection = {
  id: CostEstimateSectionId;
  costEstimateId: CostEstimateId;
  name: string;
  items: Record<ResourceType, CostEstimateSectionItems>[];
  cost: number;
  saleCost: number;
  ordering: number;
};

export type CreateCostEstimateSectionData = {
  name: string;
};

export type UpdateCostEstimateSectionData = {
  name: string;
};

export type ParamsMoveSection = {
  subjectElementId: string;
  countPoint: string;
  elementType: string;
  objectElementId: string;
};

export function* moveCostEstimateSections(
  companyId: string,
  objectId: string,
  costEstimateId: string,
  params: ParamsMoveSection
) {
  const { data } = yield call(
    putRequest,
    urlFromTemplate(ENDPOINTS.COMPANY_OBJECTS.COST_ESTIMATES.MOVEITEMS, {
      companyId,
      objectId,
      costEstimateId,
    }),
    params
  );

  return data as FetchedCostEstimateSection[];
}

export function* getCostEstimateSection(sectionId: SectionId) {
  const { data } = yield call(
    getRequest,
    urlFromTemplate(
      ENDPOINTS.COMPANY_OBJECTS.COST_ESTIMATE_SECTION.GET_SECTION,
      {
        sectionId,
      }
    )
  );

  return data as FetchedCostEstimateSection;
}

export function* getCostEstimateSections(
  companyId: CompanyId,
  objectId: CompanyObjectId,
  costEstimateId: CostEstimateId
) {
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.COMPANY_OBJECTS.COST_ESTIMATE_SECTION.GET, {
      companyId,
      objectId,
      costEstimateId,
    })
  );

  return data as FetchedCostEstimateSection[];
}

export function* createCostEstimateSection(
  companyId: CompanyId,
  objectId: CompanyObjectId,
  costEstimateId: CostEstimateId,
  params: CreateCostEstimateSectionData
) {
  const { data } = yield call(
    postRequest,
    urlFromTemplate(ENDPOINTS.COMPANY_OBJECTS.COST_ESTIMATE_SECTION.CREATE, {
      companyId,
      objectId,
      costEstimateId,
    }),
    params
  );

  return data as FetchedCostEstimateSection;
}

export function* updateCostEstimateSection(
  companyId: CompanyId,
  objectId: CompanyObjectId,
  costEstimateId: CostEstimateId,
  id: CostEstimateSectionId,
  params: UpdateCostEstimateSectionData
) {
  const { data } = yield call(
    putRequest,
    urlFromTemplate(ENDPOINTS.COMPANY_OBJECTS.COST_ESTIMATE_SECTION.UPDATE, {
      companyId,
      objectId,
      costEstimateId,
      id,
    }),
    params
  );

  return data as FetchedCostEstimateSection;
}

export function* deleteCostEstimateSection(
  companyId: CompanyId,
  objectId: CompanyObjectId,
  costEstimateId: CostEstimateId,
  id: CostEstimateSectionId
) {
  yield call(
    deleteRequest,
    urlFromTemplate(ENDPOINTS.COMPANY_OBJECTS.COST_ESTIMATE_SECTION.DELETE, {
      companyId,
      objectId,
      costEstimateId,
      id,
    })
  );
}
