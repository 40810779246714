import { useEffect } from 'react';
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from 'react-router-dom';
import { useAppSelector } from '@hooks/redux';
import { isAuthorized } from '@store/user/selectors';
import { ResourceFolderId } from '@store/resourceFolders/slice';
import { OperationFolderId } from '@store/operationFolders/slice';
import { CompanyObjectId } from '@store/companyObjects/slice';
import { RedirectBaseUrl, RedirectByRole } from '@components/Navigate';
import { Auth } from './Auth';
import { Restore } from './Restore';
import { SettingsPage } from './SettingsPage';
import { SettingsCompany } from './Settings/Company';
import { SettingsDirectory } from './Settings/Directory';
import { SettingsDirectoryOperations } from './Settings/Directory/Operations';
import { SettingsDirectoryResources } from './Settings/Directory/Resources';
import { SettingsLog } from './Settings/Log';
import { SettingsStaff } from './Settings/Staff';
import { SettingsSystem } from './Settings/System';
import { SignUp } from './SignUp';
import { ProfileCreated } from './SignUp/ProfileCreated';
import { AboutObjectPage } from './AboutObjectPage';
import { AboutObjectObject } from './AboutObject/Object';
import { AboutObjectCostEstimates } from './AboutObject/CostEstimates';
import { ResetPassword } from './ResetPassword';
import SupplyOffice from './SupplyOffice/SupplyOffice';
import Supply from './AboutObject/Supply';

import ClientsBasePage from './ClientsBasePage';
import { SettingsSuppliner } from './Settings/Supplier';
import Messenger from './Messenger';

export const getHomeUrl = () => '/';
export const getAboutObjectUrl = () => '/about-object';
export const getObjectUrl = (objectId?: CompanyObjectId) =>
  `${getAboutObjectUrl()}/object${objectId ? `/${objectId}` : ''}`;
export const getCostEstimatesUrl = (objectId?: CompanyObjectId) =>
  `${getAboutObjectUrl()}/cost-estimates${objectId ? `/${objectId}` : ''}`;

export const getSupplyUrl = (objectId?: CompanyObjectId) =>
  `${getAboutObjectUrl()}/supply${objectId ? `/${objectId}` : ''}`;

export const getAuthUrl = () => '/auth';
export const getRestoreUrl = () => '/restore';
export const getResetPasswordUrl = () => '/reset';
export const getClientsUrl = () => `/clients`;
export const getMessengerUrl = () => `/messenger`;
export const getOfficeUrl = () => `/office`;
export const getSettingsUrl = () => `/settings`;
export const getCompanyUrl = (type?: string) =>
  `${getSettingsUrl()}/${type || 'company'}`;
export const getStaffUrl = () => `${getSettingsUrl()}/staff`;
export const getSystemUrl = () => `${getSettingsUrl()}/system`;
export const getLogUrl = () => `${getSettingsUrl()}/log`;
export const getDirectoryUrl = () => `${getSettingsUrl()}/directory`;
export const getResourcesUrl = (folderId?: ResourceFolderId) =>
  `${getDirectoryUrl()}/resources${folderId ? `/${folderId}` : ''}`;
export const getOperationsUrl = (folderId?: OperationFolderId) =>
  `${getDirectoryUrl()}/operations${folderId ? `/${folderId}` : ''}`;

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const authorized = useAppSelector(isAuthorized);

  useEffect(() => {
    if (!authorized) {
      navigate(getAuthUrl(), { replace: true, state: { from: location } });
    }
  }, [authorized, location, navigate]);

  return children;
};

export const Routing = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth>
            <RedirectBaseUrl />
          </RequireAuth>
        }
      />

      <Route
        path="about-object"
        element={
          <RequireAuth>
            <AboutObjectPage />
          </RequireAuth>
        }
      >
        <Route index element={<Navigate to="object" replace />} />
        <Route path="object/:objectId?" element={<AboutObjectObject />} />
        <Route
          path="cost-estimates/:objectId?"
          element={<AboutObjectCostEstimates />}
        />
        <Route path="supply/:objectId?" element={<Supply />} />
      </Route>
      <Route
        path="office"
        element={
          <RequireAuth>
            <SupplyOffice />
          </RequireAuth>
        }
      />
      <Route
        path="clients"
        element={
          <RequireAuth>
            <ClientsBasePage />
          </RequireAuth>
        }
      />
      <Route
        path="messenger"
        element={
          <RequireAuth>
            <Messenger />
          </RequireAuth>
        }
      />
      <Route
        path="settings"
        element={
          <RequireAuth>
            <SettingsPage />
          </RequireAuth>
        }
      >
        <Route index element={<RedirectByRole />} />
        <Route path="company" element={<SettingsCompany />} />
        <Route path="suppliner" element={<SettingsSuppliner />} />
        <Route path="staff" element={<SettingsStaff />} />
        <Route path="system" element={<SettingsSystem />} />
        <Route path="log" element={<SettingsLog />} />

        <Route path="directory" element={<SettingsDirectory />}>
          <Route index element={<Navigate to="resources" replace />} />
          <Route
            path="resources/:folderId?"
            element={<SettingsDirectoryResources />}
          />
          <Route
            path="operations/:folderId?"
            element={<SettingsDirectoryOperations />}
          />
        </Route>
      </Route>
      <Route path="/auth" element={<Auth />} />
      <Route path="/restore" element={<Restore />} />
      <Route path="/register/:referral?" element={<SignUp />} />
      <Route path="/reset/:token?" element={<ResetPassword />} />
      <Route
        path="/sign-up-success"
        element={
          <RequireAuth>
            <ProfileCreated />
          </RequireAuth>
        }
      />
    </Routes>
  );
};
