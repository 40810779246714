import React, { FC } from 'react';
import { ReactComponent as IconProfile2 } from '@static/img/icon-profile2.svg';
import styles from './styles.module.sass';

export const SignUpLogo: FC = () => {
  return (
    <div className="login__start">
      <i>
        <IconProfile2 className={styles.image} />
      </i>
    </div>
  );
};
