import { Select } from '@components/EditCompany/Select';
import { useAppSelector } from '@hooks/redux';
import { getProfile } from '@store/profile/selectors';
import { useTranslation } from 'react-i18next';

type SelectProps = {
  isSmall?: boolean;
  onSelect: (id: string) => void;
  selectedId?: string;
};

const ResponsibleSelect = ({ isSmall, onSelect }: SelectProps) => {
  const { t } = useTranslation();
  const userName = useAppSelector(getProfile);

  return (
    <Select
      title={t('company.responsible')}
      listItems={[{ id: '1', name: userName?.firstName }]}
      isSmall={isSmall}
      onSelect={onSelect}
      selectedId="1"
    />
  );
};
export default ResponsibleSelect;
