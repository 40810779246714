import { ReactComponent as IconWatch } from '@static/img/icon-watch.svg';
import { ReactComponent as IconChat } from '@static/img/icon-chat3.svg';

import Image from '@components/commons/Image';
import { TypeSize, setSizeImage } from '@utils/formatImage';
import { useTranslation } from 'react-i18next';
import IconButton from '@components/commons/IconButton';
import { useAppDispatch } from '@hooks/redux';
import { setIsViewChat } from '@store/modalControl/actions';
import { fetchIsChekConversationRequestAction } from '@store/chat/actions';
import styles from './styles.module.sass';

type Props = {
  supplierName: string;
  supplierLogo?: string;
  supplierId: string;
  requestId: string;
};

const TableRequestsSectionItemSupplier = ({
  supplierName,
  supplierLogo,
  requestId,
  supplierId,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleOpenchatFromRequest = () => {
    dispatch(fetchIsChekConversationRequestAction({ requestId, supplierId }));
    dispatch(setIsViewChat());
  };

  return (
    <>
      {supplierId ? (
        <div className="obj__supply-supplier">
          {supplierLogo && (
            <i>
              <Image
                url={setSizeImage(supplierLogo, TypeSize.firstSupplierLogo)}
                styles={{ borderRadius: '2px', maxWidth: '22px' }}
              />
            </i>
          )}
          <p className={styles.textStyle} title={supplierName}>
            {supplierName}
          </p>
          <IconButton
            className={styles.objSupplySupplierChatButton}
            onClick={handleOpenchatFromRequest}
          >
            <IconChat />
          </IconButton>
        </div>
      ) : (
        <div className="obj__supply-progress">
          <p>
            <IconWatch style={{ margin: 'auto 4px auto 0' }} />
            {t('directory.we-select-the-best-on-AlbinaMarket')}
          </p>
          <span>
            <i style={{ width: '46px' }} />
          </span>
        </div>
      )}
    </>
  );
};

export default TableRequestsSectionItemSupplier;
