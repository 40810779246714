import { all, call, put, takeLatest, SagaReturnType } from 'redux-saga/effects';
import { Saga } from '@sagas/types';
import { handleError } from '@sagas/errors';
import {
  emailConfirmRequest,
  EmailConfirmRequestAction,
  emailConfirmSuccess,
  setEmailConfirmStatus,
  signInRequest,
  SignInRequestAction,
  signInSuccess,
  signOutRequest,
  signOutSuccess,
  signUpRequest,
  SignUpRequestAction,
  signUpSuccess,
} from '@store/user/actions';
import { login, logout, register, emailConfirm } from '@sagas/api/auth';
import { FetchStatus } from '@store/constants';
import { addMessage } from '@store/errors/actions';
import { NoticeTypes } from '@utils/notifications';

const signInRequestHandler: Saga<SignInRequestAction> = function* ({
  payload,
}) {
  const { email, password, languageCode } = payload;

  try {
    const response: SagaReturnType<typeof login> = yield call(login, {
      email,
      password,
      languageCode,
    });
    const { token: accessToken, refreshToken } = response;

    yield put(
      signInSuccess({
        data: {
          accessToken,
          refreshToken,
        },
      })
    );
  } catch (e) {
    yield call(handleError, e);
  }
};

const signUpRequestHandler: Saga<SignUpRequestAction> = function* ({
  payload,
}) {
  const { data } = payload;

  try {
    const response: SagaReturnType<typeof register> = yield call(
      register,
      data
    );
    const { token: accessToken, refreshToken } = response;

    yield put(
      signUpSuccess({
        data: {
          accessToken,
          refreshToken,
        },
      })
    );
  } catch (e) {
    yield call(handleError, e);
  }
};

const signOutRequestHandler: Saga = function* () {
  try {
    yield call(logout);
    yield put(signOutSuccess());
  } catch (e) {
    yield call(handleError, e);
  }
};

const emailConfirmRequestHandler: Saga<EmailConfirmRequestAction> = function* ({
  payload,
}) {
  const { email, languageCode } = payload;
  try {
    yield put(setEmailConfirmStatus(FetchStatus.Fetching));
    yield call(emailConfirm, { email, languageCode });
    yield put(emailConfirmSuccess());
    yield put(
      addMessage({
        id: 'emailConfirm',
        message: 'notice.the-verification-code-email',
        type: NoticeTypes.success,
      })
    );
  } catch (e) {
    yield put(setEmailConfirmStatus(FetchStatus.Error));
    yield call(handleError, e);
  }
};

export default function* root() {
  yield all([
    takeLatest(signInRequest, signInRequestHandler),
    takeLatest(signUpRequest, signUpRequestHandler),
    takeLatest(signOutRequest, signOutRequestHandler),
    takeLatest(emailConfirmRequest, emailConfirmRequestHandler),
  ]);
}
