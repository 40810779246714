import React, { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingsDirectoryResourcesCreateFolderModal } from '@pages/Settings/Directory/Resources/CreateFolderModal';
import newAddIcon from '@static/img/new-add.gif';

export const SettingsDirectoryResourcesEmpty = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const handleCreateFolderClick = (e: SyntheticEvent) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <div className="manager">
      <div className="outlay__add">
        <i>
          <img src={newAddIcon} alt={t('directory.create-folder') ?? ''} />
        </i>
        <h6>{t('directory.new-resource-title')}</h6>
        <p>{t('directory.new-resource-description')}</p>
        <a
          href="#"
          className="link open-modal center"
          onClick={handleCreateFolderClick}
        >
          {t('directory.create-folder')}
        </a>
      </div>
      <SettingsDirectoryResourcesCreateFolderModal
        visible={showModal}
        onClose={handleModalClose}
      />
    </div>
  );
};
