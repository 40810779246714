import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@store';
import { StatusOrder } from '@utils/enums';

export const getRootOrder = (state: RootState) => state.orderSupplier;

export const getOrdersByStatus = (
  state: RootState,
  status: keyof typeof StatusOrder
) => {
  return getRootOrder(state).orders[status];
};

export const getOrdersStatusByType = (
  state: RootState,
  status: keyof typeof StatusOrder
) => getRootOrder(state).status[status];

export const getCurrentOrder = (state: RootState) =>
  getRootOrder(state).currentOrder;

export const getCurrentOrderList = (state: RootState) =>
  getRootOrder(state).orderList;

export const getOrderByStatusOrdered = (state: RootState) =>
  getRootOrder(state).orders.ORDERED;

export const getOrderByStatusProduction = (state: RootState) =>
  getRootOrder(state).orders.PRODUCTION;

export const getOrdersByCost = createSelector(
  [
    (state: RootState) =>
      getOrderByStatusOrdered(state).map((i) => {
        return { cost: i.allItemsCost, status: 'ORDERED' };
      }),
    (state: RootState) =>
      getOrderByStatusProduction(state).map((i) => {
        return { cost: i.allItemsCost, status: 'PRODUCTION' };
      }),
  ],
  (ordered, production) => ordered.concat(production)
);

export const getDeleteOrderSupplierStatus = (state: RootState) =>
  getRootOrder(state).deleteOrderStatus;
