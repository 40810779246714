import React, { FC, memo, SyntheticEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ROOT_FOLDER_ID } from '@constants/folders';
import { useAppSelector } from '@hooks/redux';
import { OperationFolder } from '@store/operationFolders/slice';
import { getFolder } from '@store/operationFolders/selectors';
import { EstimateOperationsCatalogContext } from '@components/EstimateOperationsCatalog';
import homeIcon from '@static/img/icon-home.svg';

const Home: FC = () => {
  const { t } = useTranslation();
  const { onChangeFolder } = useContext(EstimateOperationsCatalogContext);

  const handleClick = (e: SyntheticEvent) => {
    e.preventDefault();

    onChangeFolder(ROOT_FOLDER_ID);
  };

  return (
    <li>
      <a href="#" onClick={handleClick}>
        <img src={homeIcon} alt={t('directory.root-folder') ?? ''} />
        {t('directory.catalog')}
      </a>
    </li>
  );
};

type FolderProps = {
  folder: OperationFolder;
};
const Folder: React.FC<FolderProps> = ({ folder }) => {
  const handleClick = (e: SyntheticEvent) => {
    e.preventDefault();
  };

  return (
    <li>
      <a href="#" onClick={handleClick}>
        {folder.name}
      </a>
    </li>
  );
};

const BreadcrumbsRaw: React.FC = () => {
  const { folderId } = useContext(EstimateOperationsCatalogContext);
  const folder = useAppSelector((state) => getFolder(state, folderId));

  return (
    <ul className="manager__bread">
      <Home />
      {folder && <Folder folder={folder} />}
    </ul>
  );
};

export const OperationCatalogBreadcrumbs = memo(BreadcrumbsRaw);
