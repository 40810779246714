import { RootState } from '@store';
import { StatusRequest } from '@utils/enums';

export const getRootRequest = (state: RootState) => state.requestSupplier;

export const getRequests = (state: RootState) =>
  getRootRequest(state).requestsBasicData;

export const getRequestsByCost = (state: RootState) =>
  getRootRequest(state).requestsBasicData.map((i) => {
    return { cost: i.allResponsesCost, status: i.status };
  });

export const getActiveRequestId = (state: RootState) =>
  getRootRequest(state).activeRequestId;

export const getRequestsByStatus = (
  state: RootState,
  status: keyof typeof StatusRequest
) => getRootRequest(state).requestsBasicData.filter((i) => i.status === status);

export const getStatusByType = (
  state: RootState,
  status: keyof typeof StatusRequest
) => getRootRequest(state).status[status];

export const getRequestBasicData = (state: RootState) =>
  getRootRequest(state).requestsBasicData.find(
    (i) => i.id === getActiveRequestId(state)
  );

export const getUnansweredResources = (state: RootState) =>
  getRootRequest(state).bid.unansweredResources;

export const getNewResources = (state: RootState) =>
  getRootRequest(state).bid.newResources;

export const getProcessedResources = (state: RootState) =>
  getRootRequest(state).bid.processedResources;

export const getBid = (state: RootState) => getRootRequest(state).bid;

export const getResponse = (state: RootState) => {
  return Object.values(getBid(state))
    .flat()
    .map((i) => {
      if (i && i.response && Number(i.response.price) > 0)
        return {
          ...i.response,
          resourceId: i.id,
          categoryId: i.category.id,
        };
      return null;
    })
    .filter((i) => i);
};
