import InputFileUpload from '@components/commons/FieldFile';
import Image from '@components/commons/Image';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import cardCompanyIcon from '@static/img/icon-add3.svg';
import uploadLogoCompany from '@static/img/icon-upload.svg';
import { addMessage } from '@store/errors/actions';
import { getSupplierId } from '@store/supplier/selectors';

import { setSizeImage } from '@utils/formatImage';

import { useTranslation } from 'react-i18next';

interface IProfileCompany {
  setUploadFile: (url: string) => void;
  image?: string;
}

export const ProfileSupplier = ({ image, setUploadFile }: IProfileCompany) => {
  const supplierId = useAppSelector(getSupplierId);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  // TODO пока не доступен бэк заглушка для пользователя
  const handleClick = () => {
    dispatch(
      addMessage({
        id: 'functionality-not-unavailable',
        message: t('commons.functionality-not-unavailable') || '',
      })
    );
  };

  return (
    <div className="content__block">
      <div className="form__cot">
        <div className="form__cap">
          <h4>{t('company.profile-company')}</h4>
        </div>
      </div>
      <div className="form__cot">
        {supplierId && (
          <InputFileUpload
            setUploadFileUrl={setUploadFile}
            partLoadFile="settingSupplierLogo"
            params={{ supplierId }}
          >
            <div className="upload-image min">
              <input type="hidden" name="image" />
              <i>
                {/* выводим изображение если оно есть, по ссылке из данных компании  */}
                {image && <Image url={setSizeImage(image)} />}
              </i>
              <div>
                <img src={uploadLogoCompany} />

                {t('commons.upload-logo')}
              </div>
              <p>{t('commons.recommended-size-picture')}</p>
            </div>
          </InputFileUpload>
        )}
      </div>
      <div className="form__cot" onClick={handleClick}>
        <a href="#" className="snap transparent">
          <span>
            <img src={cardCompanyIcon} className="svg" />
            {t('company.upload-card')}
          </span>
        </a>
      </div>
    </div>
  );
};
