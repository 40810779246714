import React from 'react';
import { Link } from 'react-router-dom';

type ToSignUpButtonProps = {
  title: string;
};

export const ToSignUpButton: React.FC<ToSignUpButtonProps> = ({ title }) => {
  return (
    <div className="form__group">
      <Link to="/register" className="link white border">
        {title}
      </Link>
    </div>
  );
};
