import { OrderStatus } from '@store/ordersCustomer/slice';
import { useTranslation } from 'react-i18next';

type Props = {
  status: OrderStatus;
  needInvoice: boolean;
};

const OrdersTableItemsStatus = ({ status, needInvoice }: Props) => {
  const { t } = useTranslation();

  const statusPaid = status === 'PAID';
  const statusPayment =
    (status === 'ACCEPTED' && needInvoice) ||
    (status === 'PAYMENT' && !needInvoice);
  const statusProduction = status === 'PRODUCTION';
  const statusReady = status === 'READY';
  const statusIssued = status === 'ISSUED';
  const multiStatus =
    statusPaid || statusProduction || statusReady || statusIssued;

  return (
    <>
      <div className="obj__order-status">
        {statusIssued ? (
          <span className="success">
            {t('orders.the-order-has-been-shipped')}
          </span>
        ) : (
          <>
            <span className={statusPaid ? 'warning' : 'success'}>
              {statusPayment && t('orders.not-paid-for')}
              {multiStatus && t('orders.paid-for')}
            </span>
            <span className={statusProduction ? 'warning' : 'success'}>
              {statusProduction && t('orders.expected')}
              {statusReady && t('orders.ready-for-shipment')}
              {statusIssued && t('orders.the-order-has-been-shipped')}
            </span>
          </>
        )}
      </div>
    </>
  );
};

export default OrdersTableItemsStatus;
