import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@store';
import { FetchStatus } from '@store/constants';
import {
  City,
  CityId,
  CountryId,
  CurrencyId,
  UnitId,
} from '@store/vocabulary/slice';

export const getRootState = (state: RootState) => state.vocabulary;
export const getStatus = (state: RootState) => getRootState(state).status;
export const getCountries = (state: RootState) => getRootState(state).countries;
export const getCities = (state: RootState) => getRootState(state).cities;
export const getCity = (state: RootState, id: CityId) =>
  getCities(state).find((city) => city.id === id);
export const getCurrencies = (state: RootState) =>
  getRootState(state).currencies;
export const getCurrency = (state: RootState, id: CurrencyId) =>
  getCurrencies(state).find((currency) => currency.id === id);
export const getCurrencySymbolById = (state: RootState, id?: CurrencyId) => {
  if (id === undefined) {
    return null;
  }

  return getCurrencies(state).find((currency) => currency.id === id)?.symbol;
};
export const getLanguages = (state: RootState) => getRootState(state).languages;
export const getUnits = (state: RootState) => getRootState(state).units;
export const getUnit = (state: RootState, id: UnitId) =>
  getUnits(state).find((unit) => unit.id === id);
export const getDefaultUnit = (state: RootState) =>
  getRootState(state).units.find(
    (i) => i.id === '7df2ea41-8c6d-4a58-9da6-5ed008436bb5'
  ) || getRootState(state).units[60];
export const getCategories = (state: RootState) => getRootState(state).category;

export const getCitiesByCountry = createSelector(getCities, (cities) => {
  return cities.reduce((acc, city: City) => {
    acc[city.countryId] ??= [];
    acc[city.countryId].push(city);
    return acc;
  }, {} as Record<CountryId, City[]>);
});

export const getFoundCategories = (state: RootState) =>
  getRootState(state).foundCategories;
export const getFoundCategoriesStatus = (state: RootState) =>
  getRootState(state).foundCategoryStatus;

export const getCitiesByCountryId = (state: RootState, id: CountryId) =>
  getCitiesByCountry(state)[id];

export const isVocabularyNotFetched = (state: RootState) =>
  getStatus(state) === FetchStatus.NotFetched;
export const isVocabularyFetching = (state: RootState) =>
  getStatus(state) === FetchStatus.Fetching;
export const isVocabularyFetched = (state: RootState) =>
  getStatus(state) === FetchStatus.Fetched;
