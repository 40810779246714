import Calendar, { toValidDataTime } from '@components/commons/Calendar';
import Image from '@components/commons/Image';
import { RESOURCE_TYPES } from '@constants/resource';
import { nanoid } from '@reduxjs/toolkit';
import { ReactComponent as IconDelete } from '@static/img/icon-delete.svg';
import { Order, OrderItem } from '@store/orderSupplier/slice';
import { setSizeImage } from '@utils/formatImage';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconChat } from '@static/img/icon-chat2.svg';
import { useAppDispatch } from '@hooks/redux';
import { fetchDeleteOrderItemsRequest } from '@store/orderSupplier/actions';
import { OrderStatus } from '@utils/enums';

type CompanyProps = Pick<Order, 'company'>;

type TableOrderProps = {
  orderId: string;
  status: keyof typeof OrderStatus;
  orderList: { total: number; items: OrderItem[] };
  openChat: (isView: boolean) => void;
  isViewChat: boolean;
} & CompanyProps;

export default function Table({
  orderId,
  orderList: { items, total },
  openChat,
  isViewChat,
  company: { name, image },
  status,
}: TableOrderProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleDeleteItemFromOrder = (orderItemId: string) => {
    dispatch(fetchDeleteOrderItemsRequest({ orderItemId, orderId }));
  };

  return (
    <div className="order-modal__table">
      <div className="table-order">
        <div className="table-order__title">
          {image && <Image url={setSizeImage(image.fileName)} />}
          <div>{name}</div>

          <button onClick={() => openChat(!isViewChat)}>
            {isViewChat ? t('bid.close-chat') : t('bid.open-chat')}
            <IconChat />
          </button>
        </div>

        <div className="table-order__row thead">
          <div className="table-order__cell -type">
            {t('table.column-type')}
          </div>
          <div className="table-order__cell -name">
            {t('table.column-name')}
          </div>
          <div className="table-order__cell -quantity">
            {t('table.column-count')}
          </div>
          <div className="table-order__cell -price">
            {t('table.column-price-unit')}
          </div>
          <div className="table-order__cell -cost">
            {t('table.column-summa')}
          </div>
          <div className="table-order__cell -delivery">
            {t('table.column-delivery')}
          </div>
          <div className="table-order__cell -delete" />
        </div>

        {items.map((e) => {
          return (
            <div className="table-order__row" key={nanoid()}>
              <div className="table-order__cell -type">
                <s className={`${e.type}`}>
                  {t(RESOURCE_TYPES[e.type].title).toLocaleUpperCase()}
                </s>
              </div>
              <div className="table-order__cell -name">
                <h4>{e.name.itemName}</h4>
                <small>{e.name.sectionName}</small>
              </div>
              <div className="table-order__cell -quantity">{e.amount}</div>
              <div className="table-order__cell -price">{e.price}</div>
              <div className="table-order__cell -cost">{e.cost}</div>
              <div className="table-order__cell -delivery">
                <Calendar
                  short
                  date={toValidDataTime(e.deliveryTime)}
                  size="medium"
                />
              </div>
              <div className="table-order__cell -delete">
                {/* TODO: удаление элемента из заказа возможно только при статусе ACCEPTED, на данном этапе */}
                {status === 'ACCEPTED' && (
                  <IconDelete onClick={() => handleDeleteItemFromOrder(e.id)} />
                )}
              </div>
            </div>
          );
        })}

        <div className="table-order__total">
          <h3>{t('bid.total-request')}</h3>
          <h3>{total.toFixed(2)}</h3>
        </div>
      </div>
    </div>
  );
}
