import { RootState } from '@store';

export const getRootState = (state: RootState) => state.modalControl;

export const getisViewChat = (state: RootState) =>
  getRootState(state).isViewChat;
export const getisViewChatDrawer = (state: RootState) =>
  getRootState(state).isViewChatDrawer;
export const getisViewChatSupplierOffersDrawer = (state: RootState) =>
  getRootState(state).isViewChatSupplierOffersDrawer;
