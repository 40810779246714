import { ReactComponent as IconCheck } from '@static/img/icon-big-check.svg';
import { ReactComponent as IconHourGlass } from '@static/img/icon-hourglass.svg';
import { ReactComponent as IconExclamationMark } from '@static/img/icon-exclamation-mark.svg';
import { useTranslation } from 'react-i18next';
import { RadialPrimaryButton } from '@components/commons/RadialPrimaryButton';
import cn from 'classnames';
import { OrderDocuments, OrderStatus } from '@store/ordersCustomer/slice';
import { useDownloadDocument } from '@hooks/useDownloadDocument';
import { useAppDispatch } from '@hooks/redux';
import { addMessage } from '@store/errors/actions';
import { NoticeTypes } from '@utils/notifications';
import Toggle from '@components/commons/Toggle';
import { useCurrentLocaleDate } from '@hooks/useCurrentLocaleDate';
import { setIsViewChatDrawer } from '@store/modalControl/actions';
import { fetchIsChekConversationOrderAction } from '@store/chat/actions';
import styles from './styles.module.sass';

type Props = {
  status: OrderStatus;
  invoice: OrderDocuments;
  invoiceValidity: number;
  needInvoice: boolean;
  orderId: string;
};

const OrderModalStatusAlert = ({
  status,
  invoiceValidity,
  needInvoice,
  invoice,
  orderId,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const downloadInvoice = useDownloadDocument();

  const paymentInvoice = invoiceValidity
    ? Number(invoiceValidity.toString().slice(0, -3))
    : 0;
  const date = useCurrentLocaleDate(paymentInvoice, 'd MMM yyyy', 'MMM d yyyy');

  const handleClickDownloadInvoice = () => {
    if (!invoice?.fileName) {
      dispatch(
        addMessage({
          id: 'orderStatus',
          message: 'orders.the-supplier-has-not-uploaded-the-invoice',
          type: NoticeTypes.warning,
        })
      );
    } else {
      downloadInvoice(
        `/private-order-doc/${invoice?.fileName}`,
        invoice?.originalFileName
      );
    }
  };

  const addErrorMessage = () => {
    dispatch(
      addMessage({
        id: 'funcErrorMessage',
        message: 'errors.the-functionality-is-under-development',
        type: NoticeTypes.error,
      })
    );
  };

  const handleOpenChat = () => {
    dispatch(fetchIsChekConversationOrderAction({ orderId }));
    dispatch(setIsViewChatDrawer());
  };

  const statusAlert = {
    ACCEPTED: {
      alertTitle: t('orders.your-order-has-been-accepted-lets-get-started'),
      alertClassname: 'alert success',
      alertIcon: <IconCheck />,
      alertPlaceholder: t('orders.check-the-composition-of-the-order'),
      alertButton: (
        <RadialPrimaryButton
          className={cn('white border', styles.buttonHover)}
          onClick={handleOpenChat}
        >
          {t('orders.discuss-the-order')}
        </RadialPrimaryButton>
      ),
    },
    PAYMENT: {
      alertTitle:
        invoiceValidity !== null
          ? t('orders.you-have-been-billed-for-the-order')
          : t('orders.an-invoice-is-pending'),
      alertClassname: 'alert warning',
      alertIcon: <IconExclamationMark />,
      alertPlaceholder:
        invoiceValidity !== null
          ? t('orders.pay-it-by-and-upload-the-payment-order', {
              validity: date,
            })
          : t('orders.the-supplier-will-upload-the-invoice'),
      alertButton: (
        <RadialPrimaryButton
          className={cn('white border', styles.buttonHover)}
          onClick={handleClickDownloadInvoice}
        >
          {t('orders.download-the-invoice-for-payment')}
        </RadialPrimaryButton>
      ),
    },
    PAID: {
      alertTitle: t(
        'orders.payment-of-the-invoice-has-been-confirmed-transferred to work'
      ),
      alertIcon: <IconCheck />,
      alertClassname: 'alert primary',
      alertPlaceholder: t(
        'orders.now-you-can-agree-on-the-contract-in-the-chat'
      ),
      alertButton: (
        <RadialPrimaryButton
          className={cn('white border', styles.buttonHover)}
          onClick={handleOpenChat}
        >
          {t('orders.open-a-chat')}
        </RadialPrimaryButton>
      ),
    },
    PRODUCTION: {
      alertTitle: t('orders.your-order-is-being-prepared'),
      alertClassname: 'alert main',
      alertIcon: <IconHourGlass />,
      alertPlaceholder: t(
        'orders.the-supplier-will-inform-you-that-the-order-is-ready-for-shipment'
      ),
      alertButton: (
        <RadialPrimaryButton
          className={cn('white border', styles.buttonHover)}
          onClick={handleOpenChat}
        >
          {t('orders.open-a-chat')}
        </RadialPrimaryButton>
      ),
    },
    READY: {
      alertTitle: t(
        'orders.your-order-is-ready-for-shipment-there-is-very-little-left'
      ),
      alertClassname: 'alert danger',
      alertIcon: <IconExclamationMark />,
      alertPlaceholder: t(
        'orders.choose-the-delivery-method-of-your-order-pickup-or-via-Albina.Logistics'
      ),
      alertButton: (
        <Toggle
          firstPosition={t('orders.pickup')}
          secondPosition={t('orders.delivery')}
          disable
          styles={{ position: 'static' }}
        />
      ),
    },
    ISSUED: {
      alertTitle: t(
        'orders.the-supplier-informed-about-the-successful-shipment-of-the-order'
      ),
      alertClassname: 'alert info',
      alertIcon: <IconCheck />,
      alertPlaceholder: t(
        'orders.confirm-the-shipment-to-complete-the-order-and-close-it'
      ),
      alertButton: (
        <RadialPrimaryButton
          className={cn('white border', styles.buttonHover)}
          onClick={addErrorMessage}
        >
          {t('orders.challenge')}
        </RadialPrimaryButton>
      ),
    },
  };

  return (
    <>
      {(status === 'ACCEPTED' && needInvoice) ||
      (status === 'PAYMENT' && !needInvoice) ? (
        <div className={statusAlert.PAYMENT.alertClassname}>
          <i>{statusAlert.PAYMENT.alertIcon}</i>
          <b>{statusAlert.PAYMENT.alertTitle}</b>
          <p>{statusAlert.PAYMENT.alertPlaceholder}</p>
          <a>{statusAlert.PAYMENT.alertButton}</a>
        </div>
      ) : (
        <div className={statusAlert[status].alertClassname}>
          <i>{statusAlert[status].alertIcon}</i>
          <b>{statusAlert[status].alertTitle}</b>
          <p>{statusAlert[status].alertPlaceholder}</p>
          <a>{statusAlert[status].alertButton}</a>
        </div>
      )}
    </>
  );
};
export default OrderModalStatusAlert;
