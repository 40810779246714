import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import {
  fetchResponseSupplierRequest,
  selectResponseSupplierRequestAction,
  selectSelfDeliveryRequestAction,
} from '@store/responseSupplier/actions';
import { useEffect, useState } from 'react';
import {
  fetchSelectResponseSupplierStatus,
  fetchSelectSelfDeliveryStatus,
  getResponseSupplier,
  isFetchResponseSupplierStatusFetching,
} from '@store/responseSupplier/selectors';
import IconButton from '@components/commons/IconButton';
import { FetchStatus } from '@store/constants';
import { getCurrentEstimateByObject } from '@store/costEstimates/selectors';
import Loader from '@components/commons/Loader';
import Empty from '@components/commons/Empty';
import { useSearchParams } from 'react-router-dom';
import Offer from './Offer';

import SelfDelivery from './SelfDelivery';

type Props = {
  onClose: () => void;
  resourceId: string;
  defaultActive?: string;
  withUpdate: 'updateCostEstimate' | 'updateSupply';
  responseIdFromResource: string;
};

const SuppliersOffers = ({
  resourceId,
  defaultActive = '',
  withUpdate,
  onClose,
  responseIdFromResource,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const requestId = useAppSelector(getCurrentEstimateByObject)?.requestId;
  const data = useAppSelector(getResponseSupplier);
  const selectResponseSupplierStatus = useAppSelector(
    fetchSelectResponseSupplierStatus
  );

  const selectSelfDeliveryStatus = useAppSelector(
    fetchSelectSelfDeliveryStatus
  );
  const isResponseSupplierLoading = useAppSelector(
    isFetchResponseSupplierStatusFetching
  );

  const [searchParams] = useSearchParams();
  const costEstimateIdFromQuery = searchParams.get('costEstimateId');

  const [responseId, setResponseId] = useState('');
  const [active, setActive] = useState(defaultActive); // response.id

  const onClick = (id: string) => {
    if (id === active) {
      setActive('');
    } else {
      setActive(id);
      setResponseId(id);
    }
  };

  const handleClickConfirmSelect = () => {
    if (active === '') return;
    if (
      active === 'self' &&
      selectSelfDeliveryStatus !== FetchStatus.Fetching &&
      requestId &&
      costEstimateIdFromQuery
    ) {
      dispatch(
        selectSelfDeliveryRequestAction({
          resourceId,
          requestId,
          withUpdate,
        })
      );
    } else if (
      selectResponseSupplierStatus !== FetchStatus.Fetching &&
      requestId &&
      costEstimateIdFromQuery
    ) {
      dispatch(
        selectResponseSupplierRequestAction({
          resourceId,
          responseId,
          requestId,
          withUpdate,
        })
      );
    }
    onClose();
  };

  useEffect(() => {
    dispatch(fetchResponseSupplierRequest({ resourceId }));
  }, [dispatch, resourceId]);

  useEffect(() => {
    setActive(responseIdFromResource);
    setResponseId(responseIdFromResource);
  }, [responseIdFromResource]);

  return (
    <div className="offer__base">
      <div className="offer__scroll">
        <div className="offer__title">
          <h4>{t('supply.supplier-offers')}</h4>
          <p>{data[0]?.resource.name}</p>
        </div>
        <div className="offer__items">
          <div className="offer__item">
            <SelfDelivery t={t} onClick={onClick} active={active === 'self'} />
          </div>
          <>
            {isResponseSupplierLoading ? (
              <Loader />
            ) : (
              <>
                {data.length !== 0 ? (
                  data.map((item) => (
                    <div className="offer__item" key={item.id}>
                      <Offer
                        id={item.id}
                        requestId={requestId}
                        responseName={item.name}
                        resourceName={item.resource.name}
                        supplierName={item.supplier.name}
                        supplierId={item.supplier.id}
                        amount={item.amount}
                        price={item.price}
                        cost={item.cost}
                        unitId={item.unitId}
                        onClick={onClick}
                        active={item.id === active}
                        validity={item.validity}
                        deliveryTime={item.deliveryTime}
                        categoryName={item.category.name}
                        cityId={item.supplier.cityId}
                        createdAt={item.createdAt}
                      />
                    </div>
                  ))
                ) : (
                  <div style={{ background: '#fff', marginTop: '20px' }}>
                    <Empty firstLine="supply.you-dont-have-any-offers-from-suppliers" />
                  </div>
                )}
              </>
            )}
          </>
        </div>
      </div>
      <div className="offer__but">
        <IconButton
          className="link desc"
          onClick={handleClickConfirmSelect}
          disabled={active !== 'self' && active === ''}
        >
          <span>
            <b>{t('supply.confirm-the-supplier-selection')}</b>
            <p>{t('supply.send-an-invoice-request')}</p>
          </span>
        </IconButton>
      </div>
    </div>
  );
};

export default SuppliersOffers;
