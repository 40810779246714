export const enum Role {
  supplier = 'ROLE_SUPPLIER',
  customer = 'ROLE_CUSTOMER',
}

export const enum StatusClasses {
  accepted = 'accepted',
  payment = 'payment',
  paid = 'paid',
  production = 'production',
  ready = 'ready',
  issued = 'issued',
}

export enum StatusRequest {
  NEW = 'NEW',
  PROCESSED = 'PROCESSED',
  SELECTED = 'SELECTED',
}

export enum StatusOrder {
  ORDERED = 'ORDERED',
  PRODUCTION = 'PRODUCTION',
}

export const enum StatusRequestElement {
  newResources = 'newResources',
  unansweredResources = 'unansweredResources',
  processedResources = 'processedResources',
  needClarification = 'needClarification',
  selectedResponses = 'selectedResponses',
}

export const enum OrderStatus {
  ACCEPTED = 'ACCEPTED',
  PAYMENT = 'PAYMENT',
  PAID = 'PAID',
  PRODUCTION = 'PRODUCTION',
  READY = 'READY',
  ISSUED = 'ISSUED',
}
