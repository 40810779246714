import { CompanyObject, ObjectStatus } from '@store/companyObjects/slice';

export class ObjectCollection {
  private objects: { [key in ObjectStatus]: CompanyObject[] };

  private cost: { [key in ObjectStatus]: number };

  constructor(objects: CompanyObject[]) {
    this.objects = {} as Record<ObjectStatus, CompanyObject[]>;
    this.cost = {} as Record<ObjectStatus, number>;

    objects.map((obj) => {
      if (!(obj.status in this.objects)) {
        this.objects[obj.status] = [];
        this.cost[obj.status] = 0;
      }

      this.objects[obj.status].push(obj);
      if (obj.cost) {
        this.cost[obj.status] += obj.cost;
      }

      return null;
    });
  }

  getCostByKey(status: ObjectStatus): number {
    return status in this.cost ? this.cost[status] : 0;
  }

  getObjectsByKey(status: ObjectStatus): CompanyObject[] {
    return status in this.objects ? this.objects[status] : [];
  }
}
