import { RootState } from '@store';
import { FetchStatus } from '@store/constants';

export const getRootState = (state: RootState) => state.responseSupplier;

export const getResponseSupplier = (state: RootState) =>
  getRootState(state).responses;

export const getResourceFromResponse = (state: RootState) =>
  getRootState(state).fetchResourceFromResponse;

export const fetchSelectResponseSupplierStatus = (state: RootState) =>
  getRootState(state).selectResponseSupplierStatus;

export const fetchSelectSelfDeliveryStatus = (state: RootState) =>
  getRootState(state).selectSelfDeliveryStatus;

export const fetchResponseSupplierStatus = (state: RootState) =>
  getRootState(state).fetchResponseSupplierStatus;
export const isFetchResponseSupplierStatusFetching = (state: RootState) =>
  fetchResponseSupplierStatus(state) === FetchStatus.Fetching;
