import { OrderCustomerItems, OrderStatus } from '@store/ordersCustomer/slice';
import Loader from '@components/commons/Loader';
import OrderModalTableHeader from '../OrderModalTableHeader';
import OrderModalTableItems from '../OrderModalTableItems';
import OrderModalTableTotal from '../OrderModalTableTotal';

type Props = {
  supplierName: string;
  supplierLogo?: string;
  orderItemsData: OrderCustomerItems | null;
  needInvoice: boolean;
  status: OrderStatus;
  orderId: string;
  onDeleteItem: (id: string) => void;
};

const OrderModalTable = ({
  supplierName,
  supplierLogo,
  orderItemsData,
  needInvoice,
  status,
  orderId,
  onDeleteItem,
}: Props) => {
  return (
    <div className="order__table">
      <OrderModalTableHeader
        supplierName={supplierName}
        supplierLogo={supplierLogo}
        orderId={orderId}
      />
      <div className="order__table-block">
        {orderItemsData ? (
          orderItemsData?.items.map((item) => (
            <div key={item.id}>
              <OrderModalTableItems
                itemId={item.id}
                type={item.type}
                itemName={item.name.itemName}
                sectionName={item.name.sectionName}
                amount={item.amount}
                price={item.price}
                cost={item.cost}
                deliveryTime={item.deliveryTime}
                needInvoice={needInvoice}
                currencyCode={orderItemsData?.currencyCode as string}
                status={status}
                onDeleteItem={onDeleteItem}
              />
            </div>
          ))
        ) : (
          <Loader />
        )}
      </div>
      <OrderModalTableTotal
        total={orderItemsData?.total as number}
        currencyCode={orderItemsData?.currencyCode as string}
      />
    </div>
  );
};

export default OrderModalTable;
