import { useAppSelector } from '@hooks/redux';
import { t } from 'i18next';
import {
  getAllCompanyObjectsChoisedContact,
  getAllCompanyObjectsChoisedOrganization,
} from '@store/companyObjects/selectors';
import ClientCompanyObject from '../ClientCompanyObject';

type ObjectsClientCompanyProps = {
  type: 'clientObjects' | 'organizationObjects';
  clientOrOrganizationId: string;
  handleTransitionObject: (id: string) => void;
};

const ObjectsClientCompany = ({
  type,
  clientOrOrganizationId,
  handleTransitionObject,
}: ObjectsClientCompanyProps) => {
  const objectsClient = useAppSelector((state) =>
    getAllCompanyObjectsChoisedContact(state, clientOrOrganizationId)
  );

  const objectsOrganization = useAppSelector((state) =>
    getAllCompanyObjectsChoisedOrganization(state, clientOrOrganizationId)
  );

  let objectsForRender = objectsOrganization;
  let title = t('company.company-facilities');

  if (type === 'clientObjects') {
    objectsForRender = objectsClient;
    title = t('clients.client-object');
  }

  return (
    <>
      <div className="form__cap" style={{ marginTop: '0px' }}>
        <h4>{title}</h4>
      </div>
      {objectsForRender?.map((e) => (
        <ClientCompanyObject
          key={e.id}
          id={e.id}
          name={e.name}
          contactName={e.contact?.firstName}
          organizationName={e.organization?.name}
          cost={e.cost}
          handleTransitionObject={handleTransitionObject}
        />
      ))}
    </>
  );
};
export default ObjectsClientCompany;
