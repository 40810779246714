import { all, call, put, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { Saga } from '@sagas/types';
import { handleError } from '@sagas/errors';
import {
  fetchObjectCostEstimatesRequest,
  fetchObjectCostEstimatesSuccess,
  UpdateCommercialProposalRequestAction,
  updateCommercialProposalRequest,
  updateCommercialProposalSuccess,
  deleteCommercialProposalRequest,
  DeleteCommercialProposalRequestAction,
  deleteCommercialProposalSuccess,
  fetchCostEstimateRequest,
  fetchCostEstimateSuccess,
  createCostEstimateRequest,
  createCostEstimateSuccess,
  deleteCostEstimateRequest,
  deleteCostEstimateSuccess,
  FetchObjectCostEstimatesRequestAction,
  FetchCostEstimateRequestAction,
  CreateCostEstimateRequestAction,
  DeleteCostEstimateRequestAction,
  FetchCompletelyFilledCommercialProposalRequestAction,
  fetchCompletelyFilledCommercialProposalRequest,
  fetchCompletelyFilledCommercialProposalSuccess,
  CreateCommercialProposalRequestAction,
  createCommercialProposalRequest,
  createCommercialProposalSuccess,
  copyCostEstimateSuccess,
  CopyCostEstimateRequestAction,
  copyCostEstimateRequest,
  copyEstimateRequestAction,
  CopyEstimateRequestAction,
  copyEstimateSuccess,
  setCurrentEstimate,
  setErrorDeleteCostEstimateStatus,
} from '@store/costEstimates/actions';
import { fetchCompanyObjectsRequest } from '@store/companyObjects/actions';
import { addMessage } from '@store/errors/actions';
import { nanoid } from '@reduxjs/toolkit';
import { NoticeTypes } from '@utils/notifications';
import {
  getCostEstimate,
  createCostEstimate,
  deleteCommercialProposal,
  deleteCostEstimate,
  getObjectCostEstimates,
  getCompletelyFilledCommercialProposal,
  updateCommercialProposal,
  createCommercialProposal,
  copyCostEstimate,
  copyEstimate,
} from './api/costEstimates';

// Object Cost Estimates Handler
const fetchObjectCostEstimatesRequestHandler: Saga<FetchObjectCostEstimatesRequestAction> =
  function* ({ payload }) {
    const { companyId, id } = payload;

    try {
      const objectCostEstimate: SagaReturnType<typeof getObjectCostEstimates> =
        yield call(getObjectCostEstimates, companyId, id);
      yield put(fetchObjectCostEstimatesSuccess({ data: objectCostEstimate }));
    } catch (e) {
      yield call(handleError, e);
    }
  };

// Cost Estimate Handlers
const fetchCostEstimateRequestHandler: Saga<FetchCostEstimateRequestAction> =
  function* ({ payload }) {
    const { companyId, objectId, id } = payload;

    try {
      const objectCostEstimate = yield call(
        getCostEstimate,
        companyId,
        objectId,
        id
      );
      yield put(fetchCostEstimateSuccess({ data: objectCostEstimate }));
    } catch (e) {
      yield call(handleError, e);
    }
  };

const fetchCompletelyFilledCommercialProposalRequestHandler: Saga<FetchCompletelyFilledCommercialProposalRequestAction> =
  function* ({ payload }) {
    const { companyId, objectId, id } = payload;

    try {
      const completelyFilledCommercialProposal: SagaReturnType<
        typeof getCompletelyFilledCommercialProposal
      > = yield call(
        getCompletelyFilledCommercialProposal,
        companyId,
        objectId,
        id
      );
      yield put(
        fetchCompletelyFilledCommercialProposalSuccess({
          data: completelyFilledCommercialProposal,
        })
      );
    } catch (e) {
      yield call(handleError, e);
    }
  };

const createCostEstimateRequestHandler: Saga<CreateCostEstimateRequestAction> =
  function* ({ payload }) {
    const { data, companyId, id } = payload;

    try {
      const costEstimate: SagaReturnType<typeof createCostEstimate> =
        yield call(createCostEstimate, data, companyId, id);
      yield put(createCostEstimateSuccess({ data: costEstimate }));
    } catch (e) {
      yield call(handleError, e);
    }
  };

const copyCostEstimateRequestHandler: Saga<CopyCostEstimateRequestAction> =
  function* ({ payload }) {
    const { companyId, objectId, id } = payload;

    try {
      const costEstimate: SagaReturnType<typeof copyCostEstimate> = yield call(
        copyCostEstimate,
        companyId,
        objectId,
        id
      );

      yield put(copyCostEstimateSuccess({ data: costEstimate }));
    } catch (e) {
      yield call(handleError, e);
    }
  };

const deleteCostEstimateRequestHandler: Saga<DeleteCostEstimateRequestAction> =
  function* ({ payload }) {
    const { companyId, objectId, id } = payload;

    try {
      yield call(deleteCostEstimate, companyId, objectId, id);
      yield put(deleteCostEstimateSuccess({ id }));
    } catch (e) {
      yield put(setErrorDeleteCostEstimateStatus());
      yield call(handleError, e);
    }
  };

// Commercial Proposal Handlers
const createCommercialProposalRequestHandler: Saga<CreateCommercialProposalRequestAction> =
  function* ({ payload }) {
    const { data, objectId, companyId, id } = payload;

    try {
      const proposal = yield call(
        createCommercialProposal,
        data,
        companyId,
        objectId,
        id
      );
      yield put(createCommercialProposalSuccess({ data: proposal, id }));
      yield put(fetchCompanyObjectsRequest({ id: companyId }));

      const newWindow = window.open(proposal.url, '_blank');

      if (newWindow) {
        newWindow.focus();
      }
    } catch (e) {
      yield call(handleError, e);
    }
  };

const updateCommercialProposalRequestHandler: Saga<UpdateCommercialProposalRequestAction> =
  function* ({ payload }) {
    const { data, companyId, objectId, costEstimateId, id } = payload;

    try {
      yield call(updateCommercialProposal, data, companyId, objectId, id);
      yield put(updateCommercialProposalSuccess({ data, costEstimateId, id }));
      yield put(fetchCompanyObjectsRequest({ id: companyId }));
    } catch (e) {
      yield call(handleError, e);
    }
  };

const deleteCommercialProposalRequestHandler: Saga<DeleteCommercialProposalRequestAction> =
  function* ({ payload }) {
    const { companyId, objectId, id, commercialProposalId } = payload;

    try {
      yield call(deleteCommercialProposal, companyId, objectId, id);
      yield put(deleteCommercialProposalSuccess({ commercialProposalId }));
      yield put(fetchCompanyObjectsRequest({ id: companyId }));
    } catch (e) {
      yield call(handleError, e);
    }
  };

const copyEstimateRequestHandler: Saga<CopyEstimateRequestAction> = function* ({
  payload,
}) {
  try {
    const { companyId, objectId, id } = payload;
    const data = yield call(copyEstimate, companyId, objectId, id);
    yield put(copyEstimateSuccess(data));
    yield put(setCurrentEstimate(data));
    yield put(
      addMessage({
        id: nanoid(),
        message: 'estimate.copy',
        type: NoticeTypes.success,
      })
    );
  } catch (e) {
    yield call(handleError, e);
  }
};

export default function* root() {
  yield all([
    takeLatest(
      fetchObjectCostEstimatesRequest,
      fetchObjectCostEstimatesRequestHandler
    ),
    takeLatest(
      fetchCompletelyFilledCommercialProposalRequest,
      fetchCompletelyFilledCommercialProposalRequestHandler
    ),
    takeLatest(fetchCostEstimateRequest, fetchCostEstimateRequestHandler),
    takeLatest(createCostEstimateRequest, createCostEstimateRequestHandler),
    takeLatest(copyCostEstimateRequest, copyCostEstimateRequestHandler),
    takeLatest(
      updateCommercialProposalRequest,
      updateCommercialProposalRequestHandler
    ),
    takeLatest(
      createCommercialProposalRequest,
      createCommercialProposalRequestHandler
    ),
    takeLatest(deleteCostEstimateRequest, deleteCostEstimateRequestHandler),
    takeLatest(
      deleteCommercialProposalRequest,
      deleteCommercialProposalRequestHandler
    ),
    takeLatest(copyEstimateRequestAction, copyEstimateRequestHandler),
  ]);
}
