/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from '@store/constants';
import {
  ArchiveOrganizationSuccessAction,
  CreateOrganizationRequestAction,
  CreateOrganizationSuccessAction,
  DeleteOrganizationRequestAction,
  DeleteOrganizationSuccessAction,
  FetchFullOrganizationsSuccessAction,
  FetchOrganizationRequestAction,
  FetchOrganizationSuccessAction,
  UpdateOrganizationRequestAction,
} from '@store/organizations/actions';
import { PaginationQuery, ResponseWithPagination } from '@store/types';

export type ContactId = string;
export type CompanyObjectId = string;
export type OrganizationId = string;

export enum OrganizationStatus {
  NEW = 'NEW',
  PREPARE = 'PREPARE',
  AGREEMENT = 'APPROVAL',
  IN_WORK = 'PROCESS',
  DONE = 'DONE',
  ARCHIVED = 'ARCHIVED',
}

export type CompanyOrganizations = {
  id: string; // to clarify
  name: string;
  organizationForm: string;
  phone: string;
  email: string;
  website: string;
  responsible: string;
  buildingObject?: string;
  createdAt: number;
  status?: OrganizationStatus; // to clarify
};

export type FullOrganizations = {
  id: string; // to clarify
  name: string;
  organizationForm: string;
  phone: string;
  email: string;
  website: string;
  responsible: string;
  buildingObject?: string;
  createdAt: number;
  contacts: {
    id: ContactId;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    comment: string;
    whatsappAvailable: boolean;
  }[];
};

export type OrganizationState = {
  status: FetchStatus;
  organization: CompanyOrganizations[];
  fullOrganizations: FullOrganizations[];
  choiseOrganization: undefined | CompanyOrganizations;
  choiseFullOrganization: undefined | FullOrganizations;
  currentCompanyObjectId: CompanyObjectId; // OrganizationId
  activeBlinkWhenCreatedNewOrganization: boolean;
  latestAddedCompanyObjectId: CompanyObjectId | null; // OrganizationId
  createOrganizationStatus: FetchStatus;
  getOrganizationsStatus: FetchStatus;
  updateOrganizationStatus: FetchStatus;
  deleteOrganizationStatus: FetchStatus;
  typeView: boolean;
  totalOrganizations: number;
  lastUpdatedOrganization: {
    id: string;
    action: 'CREATE' | 'UPDATE' | 'DELETE';
  } | null;
};

const initialState: OrganizationState = {
  status: FetchStatus.NotFetched,
  organization: [],
  fullOrganizations: [],
  choiseOrganization: undefined,
  choiseFullOrganization: undefined,
  currentCompanyObjectId: '',
  activeBlinkWhenCreatedNewOrganization: false,
  latestAddedCompanyObjectId: null,
  createOrganizationStatus: FetchStatus.NotFetched,
  getOrganizationsStatus: FetchStatus.NotFetched,
  updateOrganizationStatus: FetchStatus.NotFetched,
  deleteOrganizationStatus: FetchStatus.NotFetched,
  typeView: true,
  totalOrganizations: 0,
  lastUpdatedOrganization: null,
};

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setChoiseOrganization: (state, action: PayloadAction<OrganizationId>) => {
      state.choiseOrganization = state.organization.find(
        (i) => i.id === action.payload
      );
    },
    setChoiseFullOrganization: (
      state,
      action: PayloadAction<OrganizationId>
    ) => {
      state.choiseFullOrganization = state.fullOrganizations.find(
        (i) => i.id === action.payload
      );
    },

    deleteChoiseOrganization: (state) => {
      state.choiseOrganization = undefined;
    },

    fetchOrganizationsObjectRequest: (
      state,
      action: PayloadAction<FetchOrganizationRequestAction>
    ) => {
      state.status = FetchStatus.Fetching;
    },
    fetchOrganizationsObjectSuccess: (
      state,
      action: PayloadAction<CompanyOrganizations[]>
    ) => {
      state.organization = action.payload;
    },

    fetchFullOrganizationsObjectRequest: (
      state,
      action: PayloadAction<FetchOrganizationRequestAction>
    ) => {
      state.getOrganizationsStatus = FetchStatus.Fetching;
    },
    fetchFullOrganizationsObjectSuccess: (
      state,
      action: PayloadAction<FetchFullOrganizationsSuccessAction>
    ) => {
      const {
        payload: { response, isUpdatePage },
      } = action;
      state.totalOrganizations = response.totalItems;
      if (isUpdatePage) {
        const prevItems = [...state.fullOrganizations].splice(
          0,
          (response.currentPage - 1) * response.maxPerPage
        );
        state.fullOrganizations = [...prevItems, ...response.items];
      } else if (response.currentPage === 1) {
        state.fullOrganizations = response.items;
      } else {
        state.fullOrganizations = [
          ...state.fullOrganizations,
          ...response.items,
        ];
      }
      state.getOrganizationsStatus = FetchStatus.Fetched;
    },

    updateOrganizationRequest: (
      state,
      action: PayloadAction<UpdateOrganizationRequestAction>
    ) => {
      state.updateOrganizationStatus = FetchStatus.Fetching;
    },
    updateOrganizationSuccess: (
      state,
      action: PayloadAction<CompanyOrganizations>
    ) => {
      state.lastUpdatedOrganization = {
        id: action.payload.id,
        action: 'UPDATE',
      };
      state.updateOrganizationStatus = FetchStatus.Fetched;
    },

    deleteOrganizationRequest: (
      state,
      action: PayloadAction<DeleteOrganizationRequestAction>
    ) => {
      state.deleteOrganizationStatus = FetchStatus.Fetching;
    },
    deleteOrganizationSuccess: (
      state,
      action: PayloadAction<DeleteOrganizationSuccessAction>
    ) => {
      state.lastUpdatedOrganization = {
        id: action.payload.id,
        action: 'DELETE',
      };
      state.deleteOrganizationStatus = FetchStatus.Fetched;
    },

    setViewOrganization: (state, action: PayloadAction<boolean>) => {
      state.typeView = action.payload;
    },
    setActiveBlinkWhenCreatedNewOrganization: (state, { payload }) => {
      state.activeBlinkWhenCreatedNewOrganization = payload;
    },
    resetCreateOrganizationStatus: (state) => {
      state.createOrganizationStatus = FetchStatus.NotFetched;
    },
    resetUpdateOrganizationStatus: (state) => {
      state.updateOrganizationStatus = FetchStatus.NotFetched;
    },
    resetLatestAddedCompanyObjectId: (state) => {
      state.latestAddedCompanyObjectId = null;
    },
    setcurrentCompanyObjectId: (
      state,
      { payload }: PayloadAction<CompanyObjectId>
    ) => {
      const id = payload;

      state.currentCompanyObjectId = id;
    },

    fetchOrganizationSuccess: (
      state,
      action: PayloadAction<FetchOrganizationSuccessAction>
    ) => {
      const { data } = action.payload;

      state.organization = data;
      state.status = FetchStatus.Fetched;
    },

    createOrganizationRequest: (
      state,
      { payload }: PayloadAction<CreateOrganizationRequestAction>
    ) => {
      state.latestAddedCompanyObjectId = null;
      state.createOrganizationStatus = FetchStatus.Fetching;
    },
    createOrganizationSuccess: (
      state,
      { payload }: PayloadAction<CreateOrganizationSuccessAction>
    ) => {
      const { data } = payload;

      state.lastUpdatedOrganization = { id: data.id, action: 'CREATE' };
      state.latestAddedCompanyObjectId = data.id;
      state.organization = [data, ...state.organization];
      state.createOrganizationStatus = FetchStatus.Fetched;
    },
    archiveOrganizationSuccess: (
      state,
      { payload }: PayloadAction<ArchiveOrganizationSuccessAction>
    ) => {
      const { id } = payload;

      const index = state.organization.findIndex((object) => object.id === id);

      state.organization[index].status = OrganizationStatus.ARCHIVED;
    },
  },
});
export default organizationSlice.reducer;
