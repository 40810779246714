import { useEffect } from 'react';
import { useAppSelector } from '@hooks/redux';
import { useNavigate } from 'react-router-dom';
import { isAuthorized } from '@store/user/selectors';
import { useTranslation } from 'react-i18next';
import { SignUpForm } from './Form';
import { SignUpBackground } from './Background';
import { SignUpLogo } from './Logo';
import { SignUpHeader } from './Header';
import { ToAuthButton } from './ToAuthButton';

export const SignUp = () => {
  const navigate = useNavigate();
  const authorized = useAppSelector(isAuthorized);

  const { t } = useTranslation();

  useEffect(() => {
    if (authorized) {
      navigate('/sign-up-success', { replace: true });
    }
  }, [authorized, navigate]);

  return (
    <section className="login">
      <SignUpBackground />
      <div className="login__block">
        <SignUpLogo />
        <SignUpHeader
          title={t('auth.sign-up-header')}
          subtitle={t('auth.sign-up-description')}
        />
        <SignUpForm />
        <ToAuthButton />
      </div>
    </section>
  );
};
