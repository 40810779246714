import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EntityStatus, FetchStatus } from '@store/constants';
import {
  FetchCostEstimateResourcesRequestAction,
  FetchCostEstimateResourcesSuccessAction,
  CreateCostEstimateResourceRequestAction,
  CreateCostEstimateResourceSuccessAction,
  UpdateCostEstimateResourceRequestAction,
  UpdateCostEstimateResourceSuccessAction,
  DeleteCostEstimateResourceRequestAction,
  DeleteCostEstimateResourceSuccessAction,
  CostEstimateResourcesMoveRequestActions,
  FetchCostEstimateResourceRequestAction,
  FetchCostEstimateResourceSuccessAction,
} from '@store/costEstimateResources/actions';
import { CostEstimateId } from '@store/costEstimates/slice';
import { ResourceType } from '@store/resources/slice';
import { UnitId } from '@store/vocabulary/slice';
import { CostEstimateOperationId } from '@store/costEstimateOperations/slice';

export type CostEstimateResourceId = string;

export type CostEstimateResource = {
  status: EntityStatus;
  id: CostEstimateResourceId;
  name: string;
  category?: { id: string; name: string };
  costEstimateId: CostEstimateId;
  items: CostEstimateResourceItems[];
  operationId: CostEstimateOperationId;
  type: ResourceType;
  unitId: UnitId;
  amount: number;
  price: number;
  salePrice: number;
  markup: number;
  cost: number;
  saleCost: number;
  numberOfResponses: number | null;
  defaultPrice: number | null;
  response: {
    id: string;
    name: string;
    amount: number;
    type: string;
    price: number;
    cost: number;
    expired: boolean;
    unitId: string;
    deliveryTime: number;
    validity: number;
  } | null;
  resourceParent: ResourceParent | null;
  ordered: boolean;
  ordering: number;
  deliveryTime: number | null;
};

export type ResourceParent = {
  id: string;
  name: string;
};

export type CostEstimateResourceItems = {
  cost: number;
  saleCost: number;
};

export type CostEstimatesState = {
  status: FetchStatus;
  fetchCostEstimateResourceStatus: FetchStatus;
  deleteResourceStatus: FetchStatus;
  items: CostEstimateResource[];
};

const initialState: CostEstimatesState = {
  status: FetchStatus.NotFetched,
  fetchCostEstimateResourceStatus: FetchStatus.NotFetched,
  deleteResourceStatus: FetchStatus.NotFetched,
  items: [],
};

export const costEstimateResourcesSlice = createSlice({
  name: 'costEstimateResources',
  initialState,
  reducers: {
    fetchCommercialProposalSuccess: (state) => {
      state.status = FetchStatus.Fetched;
    },
    fetchCommercialProposalRequest: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<FetchCostEstimateResourcesRequestAction>
    ) => {
      state.status = FetchStatus.Fetching;
    },
    fetchostEstimateResourcesMoveRequest: (
      state,
      action: PayloadAction<CostEstimateResourcesMoveRequestActions>
    ) => {
      const indexSubjectElement = state.items.findIndex(
        (i) => i.id === action.payload.subjectElementId
      );
      const indexObjectElement = state.items.findIndex(
        (i) => i.id === action.payload.objectElementId
      );

      const indexStartInsert =
        indexObjectElement < indexSubjectElement
          ? indexSubjectElement - (action.payload.toMove === -1 ? 1 : 0)
          : indexSubjectElement + (action.payload.toMove === -1 ? 0 : 1);

      const data = [...state.items];

      data.splice(indexObjectElement, 1);

      data.splice(indexStartInsert, 0, {
        ...state.items[indexObjectElement],
      });

      state.items = data.map((e, i) => {
        return { ...e, ordering: i + 1 };
      });
    },

    fetchCostEstimateResourceRequest: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<FetchCostEstimateResourceRequestAction>
    ) => {
      state.fetchCostEstimateResourceStatus = FetchStatus.Fetching;
    },
    fetchCostEstimateResourceSuccess: (
      state,
      action: PayloadAction<FetchCostEstimateResourceSuccessAction>
    ) => {
      const { data } = action.payload;
      const index = state.items.findIndex((res) => res.id === data.id);
      if (index !== -1) {
        state.items[index] = data;
      } else {
        state.items = [...state.items, data];
      }
      state.fetchCostEstimateResourceStatus = FetchStatus.Fetched;
    },

    fetchCostEstimateResourcesRequest: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<FetchCostEstimateResourcesRequestAction>
    ) => {
      state.status = FetchStatus.Fetching;
    },
    fetchCostEstimateResourcesSuccess: (
      state,
      { payload }: PayloadAction<FetchCostEstimateResourcesSuccessAction>
    ) => {
      const { data } = payload;

      state.status = FetchStatus.Fetched;
      state.items = data.map((d) => ({ ...d, status: EntityStatus.Fetched }));
    },
    createCostEstimateResourceRequest: (
      state,
      { payload }: PayloadAction<CreateCostEstimateResourceRequestAction>
    ) => {
      const { costEstimateId, temporaryId, data } = payload;

      state.items.push({
        costEstimateId,
        items: [],
        ...data,
        id: temporaryId,
        ordering: 0,
        status: EntityStatus.Fetching,
        response: null,
        deliveryTime: 0,
        numberOfResponses: null,
        resourceParent: null,
        ordered: false,
        defaultPrice: null,
      });
    },
    createCostEstimateResourceSuccess: (
      state,
      { payload }: PayloadAction<CreateCostEstimateResourceSuccessAction>
    ) => {
      const { temporaryId, data } = payload;
      const index = state.items.findIndex((item) => item.id === temporaryId);

      state.items[index] = {
        ...data,
        status: EntityStatus.Fetched,
      };
    },
    updateCostEstimateResourceRequest: (
      state,
      { payload }: PayloadAction<UpdateCostEstimateResourceRequestAction>
    ) => {
      const { id, data } = payload;
      const index = state.items.findIndex((item) => item.id === id);

      state.items[index] = {
        ...state.items[index],
        ...data,
        category: {
          name: data.categoryName,
          id: data.categoryId,
        },
        // status: EntityStatus.Fetching,
      };
    },
    updateCostEstimateResourceSuccess: (
      state,
      { payload }: PayloadAction<UpdateCostEstimateResourceSuccessAction>
    ) => {
      const { id, data } = payload;
      const index = state.items.findIndex((item) => item.id === id);

      state.items[index] = {
        ...state.items[index],
        // status: EntityStatus.Fetched,
        ...data,
      }; // Ничего не происходит, т.к пэйлоад приходит пустой
    },
    deleteCostEstimateResourceRequest: (
      state,
      { payload }: PayloadAction<DeleteCostEstimateResourceRequestAction>
    ) => {
      const { id } = payload;

      state.items = state.items.filter((item) => item.id !== id);
      state.deleteResourceStatus = FetchStatus.Fetching;
    },
    deleteCostEstimateResourceSuccess: (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<DeleteCostEstimateResourceSuccessAction>
    ) => {
      state.deleteResourceStatus = FetchStatus.Fetched;
    },
    resetDeleteResourceStatus: (state) => {
      state.deleteResourceStatus = FetchStatus.NotFetched;
    },
    setErrorDeleteResourceStatus: (state) => {
      state.deleteResourceStatus = FetchStatus.Error;
    },
  },
});

export default costEstimateResourcesSlice.reducer;
