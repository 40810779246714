import { call } from 'redux-saga/effects';
import { ENDPOINTS } from '@config';
import { deleteRequest, getRequest, postRequest, putRequest } from '@sagas/api';
import { urlFromTemplate } from '@utils/url';
import { CompanyId } from '@store/company/slice';
import { Contact, ContactId, FullContacts } from '@store/contacts/slice';

export type CreateContactData = {
  organizationId?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  comment?: string;
  whatsappAvailable: boolean;
};

export type UpdateContactData = {
  organizationId?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  comment: string;
  whatsappAvailable: boolean;
};

export function* getCompanyContactsObjects(companyId: CompanyId) {
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.CONTACT.GET, { id: companyId })
  );

  return data as Contact;
}

export function* getFullCompanyContactsObjects(
  companyId: CompanyId,
  query?: { page: number; max?: number }
) {
  const data: FullContacts[] = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.CONTACT.GET_FULL, { id: companyId }),
    query
  );

  return data;
}

export function* createContact(
  params: CreateContactData,
  companyId: CompanyId
) {
  const { data } = yield call(
    postRequest,
    urlFromTemplate(ENDPOINTS.CONTACT.CREATE, {
      id: companyId,
    }),
    params
  );

  return data as Contact;
}

export function* updateContact(
  companyId: CompanyId,
  contactId: ContactId,
  params: UpdateContactData
) {
  const { data } = yield call(
    putRequest,
    urlFromTemplate(ENDPOINTS.CONTACT.UPDATE, {
      companyId,
      id: contactId,
    }),
    params
  );
  return data as Contact;
}

export function* deleteContact(companyId: CompanyId, id: ContactId) {
  yield call(
    deleteRequest,
    urlFromTemplate(ENDPOINTS.CONTACT.DELETE, {
      companyId,
      id,
    })
  );
}

// export function* archiveObject(companyId: CompanyId, id: CompanyObjectId) {
//   yield call(
//     patchRequest,
//     urlFromTemplate(ENDPOINTS.COMPANY_OBJECTS.OBJECTS.ARCHIVE, {
//       companyId,
//       id,
//     }),
//     {}
//   );
// }
