import React, {
  ChangeEvent,
  ReactElement,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useOutsideClickListener } from '@hooks/useOutsideClickListener';
import styles from './styles.module.sass';

type ListItemId = string;

type ListItemBase = {
  id: ListItemId;
  name: string;
  aliases: string[];
};

type SelectProps<
  ListItemType extends ListItemBase,
  FormData extends FieldValues
> = {
  name: Path<FormData>;
  control: Control<FormData>;
  listItems: ListItemType[];
  onSelectFocus?: () => void;
  onSelectBlur?: () => void;
  onSelectChange?: () => void;
  disabled?: boolean;
};

export const TableSelect = <
  ListItemType extends ListItemBase,
  FormData extends FieldValues
>({
  name,
  control,
  listItems,
  disabled,
  onSelectFocus,
  onSelectBlur,
  onSelectChange,
}: SelectProps<ListItemType, FormData>): ReactElement => {
  const { t } = useTranslation();
  const selectRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const { field } = useController({ name, control });

  const sortListItems = useMemo(
    () =>
      listItems.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      }),
    [listItems]
  );

  const activeItem = useMemo(
    () => sortListItems.find((item) => item.id === field.value),
    [field.value, sortListItems]
  );

  const handleTitleClick = () => {
    setOpen(true);
    onSelectFocus?.();
  };
  const handleItemClick = (id: ListItemId) => () => {
    setOpen(false);
    setSearchQuery('');
    field.onChange(id);
    onSelectBlur?.();
    onSelectChange?.();
  };
  const handleOutsideClick = () => {
    setOpen(false);
    setSearchQuery('');
    onSelectBlur?.();
  };
  const handlerSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value.trimStart().toLowerCase());
  };

  useOutsideClickListener(selectRef, handleOutsideClick, open);

  return (
    <div ref={selectRef} className={cn('sel', { open, index: open })}>
      <button
        onClick={handleTitleClick}
        title={activeItem?.name}
        className={styles.selectButton}
        disabled={disabled}
      >
        {activeItem?.name}
      </button>
      <ul>
        <input
          type="text"
          value={searchQuery}
          placeholder={t('table.search') ?? ''}
          onChange={handlerSearchChange}
        />
        {listItems
          .filter((listItem) =>
            listItem.aliases.find((alias) =>
              alias.toLowerCase().startsWith(searchQuery)
            )
          )
          .map((item) => (
            <li
              key={item.id}
              className={cn({ active: activeItem?.id === item.id })}
              onClick={handleItemClick(item.id)}
            >
              {item.name}
            </li>
          ))}
      </ul>
    </div>
  );
};
