import { useRole } from '@hooks/useRole';
import { DashboardPage } from '@pages/DashboardPage';
import SupplyOffice from '@pages/SupplyOffice/SupplyOffice';
import { Navigate } from 'react-router-dom';

export const RedirectBaseUrl = () => {
  const { isSupplier } = useRole();

  return <>{isSupplier ? <SupplyOffice /> : <DashboardPage />}</>;
};

export const RedirectByRole = () => {
  const { isSupplier } = useRole();
  return <Navigate to={isSupplier ? 'suppliner' : 'company'} />;
};
