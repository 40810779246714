import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useCurrentLocale } from '@hooks/useCurrentLocale';
import { useFormErrors } from '@hooks/useFormErrors';
import {
  createObjectRequest,
  resetLatestAddedCompanyObjectId,
  resetCreateObjectStatus,
} from '@store/companyObjects/actions';
import {
  getCompanyObjectById,
  getCurrentCompanyObject,
  getLatestAddedCompanyObjectId,
  isCreateObjectFetched,
} from '@store/companyObjects/selectors';
import { CompanyId } from '@store/company/slice';
import { getCurrencies } from '@store/vocabulary/selectors';
import { getObjectUrl } from '@pages';
import { currencyFormat, currencyParse } from '@utils/currency';
import PhotoCompany from '@components/PhotoCompany/PhotoCompany';
import ObjectButtonBlock from '@components/ObjectButtonBlock';
import { fetchFullOrganizationsObjectRequest } from '@store/organizations/actions';
import { CompanyObject } from '@store/companyObjects/slice';
import { ReactComponent as IconHuman } from '@static/img/human__ObjectButton.svg';
import { ReactComponent as IconAdd } from '@static/img/add__ObjectButton.svg';
import { ReactComponent as IconBackPack } from '@static/img/backpack__ObjectButton.svg';
import { Input } from './Input';
import { TextArea } from './TextArea';
import { CreateObjectButton } from './CreateObjectButton';
import { inputErrors } from './utils';
import { CreateObjectFormData } from './types';
import ChoisedContact from '../ChoisedContact';
import ChoisedOrganization from '../ChoisedOrganization';

type CreateObjectFormProps = {
  companyId: CompanyId;
  companyCurrencyCode: string;
};

export const CreateObjectForm: FC<CreateObjectFormProps> = ({
  companyId,
  companyCurrencyCode,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const locale = useCurrentLocale();
  const currencies = useAppSelector(getCurrencies);
  const latestAddedCompanyObjectId = useAppSelector(
    getLatestAddedCompanyObjectId
  );
  const dataObject = useAppSelector(getCurrentCompanyObject);

  const currentObject = useAppSelector((state) =>
    latestAddedCompanyObjectId
      ? getCompanyObjectById(state, latestAddedCompanyObjectId)
      : undefined
  );
  const createObjectFetched = useAppSelector(isCreateObjectFetched);

  const [selectedContact, setSelectedContact] = useState(dataObject?.contact);
  const [selectedOrganization, setSelectedOrganization] = useState(
    dataObject?.organization
  );

  useEffect(() => {
    dispatch(resetLatestAddedCompanyObjectId());
  }, [dispatch]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors: formErrors, isValid },
  } = useForm<CreateObjectFormData>({ mode: 'onBlur' });
  const errors = useFormErrors(formErrors);

  const handleContactChange = (value: unknown) => {
    const typedValue = value as CompanyObject['contact'];
    setValue('contactId', typedValue?.id || '');
    setSelectedContact(typedValue);
  };

  const handleOrganizationChange = (value: unknown) => {
    const typedValue = value as CompanyObject['organization'];
    setValue('organizationId', typedValue?.id || '');
    setSelectedOrganization(typedValue);
  };

  const submitHandler = handleSubmit((data, event) => {
    event?.preventDefault();

    const dataWithCostNumber = {
      ...data,
      cost: currencyParse(data.cost, locale),
    };
    if (!currentObject) {
      dispatch(createObjectRequest({ data: dataWithCostNumber, companyId }));
    }
  });

  const handleBlur = (name: keyof CreateObjectFormData) => {
    const value = getValues(name);

    if (value === '') {
      return;
    }

    if (name === 'cost') {
      setValue(
        name,
        currencyFormat(parseFloat(value), companyCurrencyCode, locale)
      );
    } else {
      setValue(name, value.trim());
    }

    if (currentObject) {
      submitHandler();
    }
  };

  const handleCreate = () => {
    submitHandler();

    return isValid;
  };

  const handleCostFocus = (e: SyntheticEvent) => {
    if (e?.target instanceof HTMLInputElement) {
      if (e.target.value === '') {
        return;
      }

      setValue('cost', `${currencyParse(e.target.value, locale)}`);
    }
  };

  const setImageId = (id: string) => {
    setValue('imageId', id);
  };

  useEffect(() => {
    reset({
      address: '',
      description: '',
      name: '',
      organizationId: '',
      contactId: '',
      cost: '',
    });
  }, [reset]);

  useEffect(() => {
    return () => {
      dispatch(resetCreateObjectStatus());
    };
  }, [dispatch]);

  const isLoading = (name: keyof CreateObjectFormData) => {
    if (!currentObject) {
      return false;
    }

    switch (name) {
      case 'cost':
        return (
          currentObject?.cost !== currencyParse(getValues('cost') ?? '', locale)
        );
      default:
        if (name === 'organizationId') {
          return currentObject.organization?.id === getValues(name);
        }
        if (name === 'contactId') {
          return currentObject.contact?.id === getValues(name);
        }
        return currentObject?.[name] !== getValues(name);
    }
  };

  const handleTransition = () => {
    if (latestAddedCompanyObjectId !== null) {
      navigate(getObjectUrl(latestAddedCompanyObjectId));
    }
  };

  useEffect(() => {
    if (companyId) {
      dispatch(
        fetchFullOrganizationsObjectRequest({
          id: companyId,
          page: 1,
          max: 10000,
        })
      );
    }
  }, [companyId, dispatch]);

  if (!currencies) {
    return null;
  }

  return (
    <form action="#" className="form" onSubmit={(e) => e.preventDefault()}>
      <div className="form__cap">
        <h4>{t('object.newObject')}</h4>
      </div>
      <div className="form__group">
        <div className="upload-object">
          <PhotoCompany setImageId={setImageId} create />
        </div>
        <Input
          title={t('object.objectName')}
          type="text"
          load={isLoading('name')}
          maxLength={191}
          {...register('name', {
            required: true,
            onBlur: () => handleBlur('name'),
          })}
          error={inputErrors('name', errors, t)}
        />
        <Input
          title={t('object.currentPrice')}
          type="text"
          load={isLoading('cost')}
          onFocus={handleCostFocus}
          {...register('cost', {
            // required: true,
            // validate: (value) => currencyParse(value, locale) > 0,
            onBlur: () => handleBlur('cost'),
            onChange: (e) => {
              setValue('cost', e.target.value.replace(/[^\d.]/g, ''));
            },
          })}
          // error={inputErrors('cost', errors, t)}
        />
        <Input
          title={t('object.objectAddress')}
          type="text"
          load={isLoading('address')}
          {...register('address', {
            onBlur: () => handleBlur('address'),
          })}
          error={inputErrors('address', errors, t)}
        />
      </div>
      <div className="form__cap">
        <h5>{t('organization.contactPerson')}</h5>
      </div>
      {watch('contactId') && selectedContact ? (
        <ChoisedContact
          firstName={selectedContact.firstName}
          lastName={selectedContact.lastName}
          email={selectedContact.email}
          phone={selectedContact.phone}
          onDelete={() => handleContactChange(undefined)}
        />
      ) : (
        <ObjectButtonBlock
          oneName={t('object.select-from-database')}
          secondName={t('object.create-new-one')}
          oneLogo={<IconHuman />}
          secondLogo={<IconAdd />}
          type="contact"
          onSelect={handleContactChange}
        />
      )}
      <div className="form__cap">
        <h5>{t('organization.organization')}</h5>
      </div>

      {watch('organizationId') && selectedOrganization ? (
        <ChoisedOrganization
          name={selectedOrganization?.name}
          setValue={setValue}
          onDelete={() => handleOrganizationChange(undefined)}
        />
      ) : (
        <ObjectButtonBlock
          oneName={t('object.select-from-database')}
          secondName={t('object.create-a-new-one')}
          oneLogo={<IconBackPack />}
          secondLogo={<IconAdd />}
          type="organization"
          onSelect={handleOrganizationChange}
        />
      )}
      <div className="form__group">
        <TextArea
          title={t('object.taskDescription')}
          maxLength={200}
          load={isLoading('description')}
          {...register('description', {
            onBlur: () => handleBlur('description'),
          })}
        />
      </div>
      <CreateObjectButton
        createObjectFetched={createObjectFetched}
        onCreateClick={handleCreate}
        onTransitionClick={handleTransition}
      />
    </form>
  );
};
