import { useForm, SubmitHandler } from 'react-hook-form';
import Button from '@components/commons/Button';
import { inputErrors } from '@utils/inputErrors';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import {
  setPasswordProfileStatus,
  updatePasswordProfileRequest,
} from '@store/profile/actions';
import { useEffect } from 'react';
import { FetchStatus } from '@store/constants';
import { getStatusPasswordProfile } from '@store/profile/selectors';
import { Input } from '@pages/Dashboard/ObjectModal/CreateObjectForm/Input';
import { t } from 'i18next';
import { SecondaryButton } from '@components/commons/SecondaryButton';

type ResetPasswordFormData = {
  currentPassword: string;
  newPassword: string;
  repeatNewPassword: string;
};

type ResetPasswordFormProps = {
  onClose: () => void;
};

export const ResetPasswordForm = ({ onClose }: ResetPasswordFormProps) => {
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<ResetPasswordFormData>();

  const status = useAppSelector(getStatusPasswordProfile);

  const onSubmit: SubmitHandler<ResetPasswordFormData> = (data) => {
    if (data.newPassword !== data.repeatNewPassword) {
      return setError('repeatNewPassword', {
        type: 'passwordsNotMatch',
        message: '',
      });
    }
    if (status === FetchStatus.NotFetched) {
      dispatch(updatePasswordProfileRequest({ data }));
    }
  };

  const handleCancelClick = () => {
    onClose();
  };

  useEffect(() => {
    if (status === FetchStatus.Fetched) {
      setTimeout(() => {
        dispatch(setPasswordProfileStatus(FetchStatus.NotFetched));
        onClose();
      }, 500);
    }
  }, [dispatch, onClose, status]);

  return (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      <div className="form__group">
        <Input
          {...register('currentPassword', {
            required: true,
          })}
          title={t('auth.current-password')}
          error={
            inputErrors('password', errors.currentPassword?.type) as string
          }
          type="password"
        />
        <Input
          {...register('newPassword', {
            required: true,
            minLength: 6,
          })}
          title={t('auth.new-password')}
          type="password"
          error={inputErrors('password', errors.newPassword?.type) as string}
        />
        <Input
          {...register('repeatNewPassword', {
            required: true,
            minLength: 6,
          })}
          title={t('auth.repeat-password')}
          error={
            inputErrors('password', errors.repeatNewPassword?.type) as string
          }
          type="password"
        />

        <Button name="confirm.confirm" status={status} />
        <SecondaryButton onClick={() => handleCancelClick()} type="button">
          {t('object.cancel-the-selection')}
        </SecondaryButton>
      </div>
    </form>
  );
};
