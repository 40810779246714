import { ReactComponent as LodingLogo } from '@static/img/logo3.svg';
import css from './styles.module.sass';

const Loader = () => {
  return (
    <span className={css.container}>
      <LodingLogo className={css.preloaderFile} />
    </span>
  );
};
export default Loader;
