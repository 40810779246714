import React, { createContext, FC, useEffect, useMemo } from 'react';
import cn from 'classnames';
import { ROOT_FOLDER_ID } from '@constants/folders';
import { useAppDispatch } from '@hooks/redux';
import { useCompanyId } from '@hooks/useCompanyId';
import { fetchResourcesRequest } from '@store/resources/actions';
import { ResourceId } from '@store/resources/slice';
import { fetchOperationsRequest } from '@store/operations/actions';
import { Operation, OperationId } from '@store/operations/slice';
import { fetchOperationFoldersRequest } from '@store/operationFolders/actions';
import { OperationFolderId } from '@store/operationFolders/slice';
import { ResourceFolderId } from '@store/resourceFolders/slice';
import { OperationCatalogHeader } from './Header';
import { OperationCatalogFolderList } from './FolderList';
import { OperationsCatalogOperationList } from './OperationList';

export enum EstimateOperationsCatalogSize {
  Minimal = 'min',
  Normal = '',
}

type EstimateOperationsCatalogContextValue = {
  size: EstimateOperationsCatalogSize;
  folderId: OperationFolderId;
  onChangeFolder: (id: OperationFolderId) => void;
  selectedResources: string[];
  isSelectedResource: (
    operationId: OperationId,
    resourceId: ResourceId
  ) => boolean;
  isSelectedOperation: (operation: Operation) => boolean;
  onSelectResources: (
    operationId: OperationId,
    resourceIds: ResourceId[],
    forceSelected?: boolean
  ) => void;
  onOperationChange: (
    id: OperationId,
    value: {
      amount: number;
      price: number;
      markup: number;
      salePrice: number;
    }
  ) => void;
};

export const EstimateOperationsCatalogContext =
  createContext<EstimateOperationsCatalogContextValue>({
    size: EstimateOperationsCatalogSize.Normal,
    folderId: ROOT_FOLDER_ID,
    onChangeFolder: () => {},
    selectedResources: [],
    isSelectedResource: () => false,
    isSelectedOperation: () => false,
    onSelectResources: () => {},
    onOperationChange: () => {},
  });

type EstimateOperationsCatalogProps = {
  className?: string;
  size?: EstimateOperationsCatalogSize;
  folderId: ResourceFolderId;
  onChangeFolder: (id: ResourceFolderId) => void;
  selectedResources: ResourceId[];
  isSelectedResource: (
    operationId: OperationId,
    resourceId: ResourceId
  ) => boolean;
  isSelectedOperation: (operation: Operation) => boolean;
  onSelectResources: (
    operationId: OperationId,
    resourceIds: ResourceId[],
    forceSelected?: boolean
  ) => void;
  onOperationChange: (
    id: OperationId,
    value: {
      amount: number;
      price: number;
      markup: number;
      salePrice: number;
    }
  ) => void;
};

export const EstimateOperationsCatalog: FC<EstimateOperationsCatalogProps> = ({
  className,
  size = EstimateOperationsCatalogSize.Normal,
  folderId,
  onChangeFolder,
  selectedResources,
  isSelectedResource,
  isSelectedOperation,
  onSelectResources,
  onOperationChange,
}) => {
  const dispatch = useAppDispatch();

  const companyId = useCompanyId();

  useEffect(() => {
    if (companyId !== undefined) {
      dispatch(fetchOperationFoldersRequest({ companyId }));
      dispatch(fetchOperationsRequest({ companyId }));
      dispatch(fetchResourcesRequest({ companyId }));
    }
  }, [dispatch, companyId]);

  const catalogContextProps = useMemo(
    () => ({
      size,
      folderId,
      onChangeFolder,
      selectedResources,
      isSelectedResource,
      isSelectedOperation,
      onSelectResources,
      onOperationChange,
    }),
    [
      size,
      folderId,
      onChangeFolder,
      selectedResources,
      isSelectedResource,
      isSelectedOperation,
      onSelectResources,
      onOperationChange,
    ]
  );

  return (
    <EstimateOperationsCatalogContext.Provider value={catalogContextProps}>
      <div className={cn('manager', className)}>
        <OperationCatalogHeader />
        {!folderId && <OperationCatalogFolderList />}
        {folderId && <OperationsCatalogOperationList />}
      </div>
    </EstimateOperationsCatalogContext.Provider>
  );
};
