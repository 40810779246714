import { ResourceType } from '@store/resources/slice';

export const RESOURCE_TYPES: Record<
  ResourceType,
  { title: string; description: string; className: string }
> = {
  // 1: { title: 'тип', className: 'color-default' },

  [ResourceType.HUM]: {
    title: 'about-object.people-short',
    description: 'about-object.people',
    className: 'color-primary',
  },
  [ResourceType.MAT]: {
    title: 'about-object.materials-short',
    description: 'about-object.materials',
    className: 'color-warning',
  },
  [ResourceType.MEC]: {
    title: 'about-object.meh-short',
    description: 'about-object.meh',
    className: 'color-main',
  },
  [ResourceType.EXT]: {
    title: 'about-object.overhead-short',
    description: 'about-object.overhead',
    className: 'color-info',
  },
};
