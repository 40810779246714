import { AuthBackground } from '@pages/Auth/Background';
import React from 'react';
import cn from 'classnames';
import { useAppSelector } from '@hooks/redux';
import { getStatusResortPassword } from '@store/account/selectors';
import { useTranslation } from 'react-i18next';
import { ToAuthButton } from '@pages/SignUp/ToAuthButton';
import { RestoreLogo } from './RestoreLogo';
import { RestoreForm } from './Form';

export const Restore = () => {
  const { t } = useTranslation();
  const status = useAppSelector(getStatusResortPassword);

  return (
    <section className="login">
      <AuthBackground />
      <div className={cn('login__block')}>
        <div className="login__title">
          <RestoreLogo />
          <h1>{t('auth.resort-password')}</h1>
          <p>
            {t(
              status === 2
                ? 'auth.forgot-password-success'
                : 'auth.forgot-password'
            )}
          </p>
        </div>
        <RestoreForm />
        <ToAuthButton />
      </div>
    </section>
  );
};
