import { call } from 'redux-saga/effects';
import { ENDPOINTS } from '@config';
import { getRequest, patchRequest, postRequest, putRequest } from '@sagas/api';
import { CompanyId } from '@store/company/slice';
import { OperationFolderId } from '@store/operationFolders/slice';
import {
  Operation,
  OperationId,
  OperationResource,
} from '@store/operations/slice';
import { ResourceId } from '@store/resources/slice';
import { UnitId } from '@store/vocabulary/slice';
import { urlFromTemplate } from '@utils/url';

export interface CreateOperationParams {
  name: string;
  amount: number;
  unitId: UnitId;
  operationFolderId: OperationFolderId;
}

export interface UpdateOperationParams {
  name: string;
  amount: number;
  unitId: UnitId;
}

export interface DeleteOperationsParams {
  ids: OperationId[];
}

export interface AddOperationResourcesParams {
  resources: OperationResource[];
}

export interface UpdateOperationResourcesParams {
  resources: OperationResource[];
}

export interface DeleteOperationResourcesParams {
  ids: ResourceId[];
}

export function* getOperations(companyId: CompanyId) {
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.OPERATIONS.ITEMS.GET, { companyId })
  );

  return data as Operation[];
}

export function* createOperation(
  companyId: CompanyId,
  params: CreateOperationParams
) {
  const { data } = yield call(
    postRequest,
    urlFromTemplate(ENDPOINTS.OPERATIONS.ITEMS.CREATE, { companyId }),
    params
  );

  return data as Operation;
}

export function* updateOperation(
  companyId: CompanyId,
  id: OperationId,
  params: UpdateOperationParams
) {
  yield call(
    putRequest,
    urlFromTemplate(ENDPOINTS.OPERATIONS.ITEMS.UPDATE, { companyId, id }),
    params
  );
}

export function* deleteOperations(
  companyId: CompanyId,
  params: DeleteOperationsParams
) {
  yield call(
    postRequest,
    urlFromTemplate(ENDPOINTS.OPERATIONS.ITEMS.DELETE, { companyId }),
    params
  );
}

export function* addOperationResources(
  companyId: CompanyId,
  id: OperationId,
  params: AddOperationResourcesParams
) {
  yield call(
    patchRequest,
    urlFromTemplate(ENDPOINTS.OPERATIONS.RESOURCES.ADD, { companyId, id }),
    params
  );
}

export function* updateOperationResources(
  companyId: CompanyId,
  id: OperationId,
  params: UpdateOperationResourcesParams
) {
  yield call(
    patchRequest,
    urlFromTemplate(ENDPOINTS.OPERATIONS.RESOURCES.UPDATE, { companyId, id }),
    params
  );
}
