import {
  Category,
  CategoryId,
  City,
  Country,
  Currency,
  Language,
  Unit,
  vocabularySlice,
} from '@store/vocabulary/slice';

export type FetchVocabularyRequestAction = never;

export type FetchVocabularySuccessAction = {
  countries: Country[];
  cities: City[];
  currencies: Currency[];
  languages: Language[];
  units: Unit[];
  categories: Category[];
};

export type FetchFoundCategoryRequestAction = {
  request: string;
};

export type FetchFoundCategorySuccessAction = {
  id: CategoryId;
  name: string;
  parentId: string;
  path: string;
}[];

export const {
  fetchVocabularyRequest,
  fetchVocabularySuccess,
  fetchFoundCategoryRequest,
  fetchFoundCategorySuccess,
} = vocabularySlice.actions;
