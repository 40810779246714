import React, { FC, useState } from 'react';
import { Modal } from '@components/AboutObjectModal';
import { ConfirmModal } from './ConfirmModal';
import { SuccessModal } from './SuccessModal';

export type ConfirmationModalProps = {
  confirmTitle: string;
  successTitle: string;
  isVisibleModal: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  onSuccess?: () => void;
};

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  successTitle,
  confirmTitle,
  isVisibleModal,
  onSuccess,
  onCancel,
  onConfirm,
}) => {
  const [isShowConfirm, setShowConfirm] = useState(true);

  const handleClickCloseModal = () => {
    if (!isShowConfirm) {
      onSuccess?.();
    }

    setTimeout(() => {
      setShowConfirm(true);
    }, 300);

    onCancel();
  };

  const handleClickConfirmButton = () => {
    setShowConfirm(false);
    onConfirm();
  };

  return (
    <>
      <Modal
        visible={isVisibleModal}
        isCenter
        onBackgroundClick={handleClickCloseModal}
      >
        {isShowConfirm ? (
          <ConfirmModal
            confirmTitle={confirmTitle}
            onCancel={handleClickCloseModal}
            onConfirm={handleClickConfirmButton}
          />
        ) : (
          <SuccessModal
            successTitle={successTitle}
            onCancel={handleClickCloseModal}
          />
        )}
      </Modal>
    </>
  );
};
