import { RootState } from '@store';
import { FetchStatus } from '@store/constants';

export const getRootState = (state: RootState) => state.orderCustomer;
export const getOrdersCustomer = (state: RootState) =>
  getRootState(state).orders;
export const getCurrencyCodeOrdersCustomer = (state: RootState) =>
  getRootState(state).orders?.currencyCode;
export const getOrderItemsCustomer = (state: RootState) =>
  getRootState(state).orderItems;
export const getDeleteItemOrderCustomerStatus = (state: RootState) =>
  getRootState(state).deleteItemOrderCustomerStatus;
export const getRequestAnInvoiceStatus = (state: RootState) =>
  getRootState(state).requestAnInvoiceStatus;
export const getUpdateOrderCustomerFinishedStatus = (state: RootState) =>
  getRootState(state).updateOrderCustomerFinishedStatus;

export const fetchOrdersCustomerStatus = (state: RootState) =>
  getRootState(state).fetchOrderCustomerStatus;
export const isFetchOrdersCustomerStatusFetching = (state: RootState) =>
  fetchOrdersCustomerStatus(state) === FetchStatus.Fetching;
