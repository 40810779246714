import { RootState } from '@store';
import { FetchStatus } from '@store/constants';

export const getRootState = (state: RootState) => state.profile;
export const getStatus = (state: RootState) => getRootState(state).status;
export const getStatusPasswordProfile = (state: RootState) =>
  getRootState(state).passwordProfileStatus;
export const getProfile = (state: RootState) => getRootState(state).profileData;
export const getProfileCompanyId = (state: RootState) =>
  getProfile(state)?.currentCompanyId;
export const getProfileSuggestedCurrencyId = (state: RootState) =>
  getProfile(state)?.language.suggestedCurrencyId;
export const getProfileFullName = (state: RootState) =>
  `${getProfile(state)?.firstName} ${getProfile(state)?.lastName}`;

export const isProfileNotFetched = (state: RootState) =>
  getStatus(state) === FetchStatus.NotFetched;
export const isProfileFetching = (state: RootState) =>
  getStatus(state) === FetchStatus.Fetching;
export const isProfileFetched = (state: RootState) =>
  getStatus(state) === FetchStatus.Fetched;

export const getProfileShortName = (state: RootState) =>
  `${getProfile(state)?.firstName} ${getProfile(state)?.lastName.charAt(0)}`;

export const getRoles = (state: RootState) =>
  getRootState(state).profileData?.roles;
