import { MainLayout } from '@layouts/MainLayout';
import { useTranslation } from 'react-i18next';
import Chat from './Chat';
import ChatList from './ChatList';

export default function Messenger() {
  const { t } = useTranslation();
  return (
    <MainLayout title={t('chat.chats')} hasBackForMobile>
      <div className="content__block">
        <div className="chat">
          <ChatList />
          <Chat />
        </div>
      </div>
    </MainLayout>
  );
}
