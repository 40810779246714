import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import {
  getClientsUrl,
  getOfficeUrl,
  getHomeUrl,
  getSettingsUrl,
  getMessengerUrl,
} from '@pages';
import { useRole } from '@hooks/useRole';
import { ReactComponent as DashboardIcon } from '@static/img/menu1.svg';
import { ReactComponent as ClientsIcon } from '@static/img/menu2.svg';
import { ReactComponent as SettingsIcon } from '@static/img/menu5.svg';
import { ReactComponent as OfficeIcon } from '@static/img/menu4.svg';
import { ReactComponent as MessangerIcon } from '@static/img/icon-chat2.svg';
import { useAppSelector } from '@hooks/redux';
import { getUnreadchats } from '@store/chat/selectors';
import { getProfileFullName } from '@store/profile/selectors';
import { Profile } from './Profile';
import { Icon } from './Icon';
import { Logo } from './Logo';
import './styles.sass';

type NavbarProps = {
  open: boolean;
  onClose: () => void;
};

export const Navbar: FC<NavbarProps> = ({ open, onClose }) => {
  const { t } = useTranslation();

  const profileFullName = useAppSelector(getProfileFullName);

  const { isCustomer, isSupplier } = useRole();

  const unreadChats = useAppSelector(getUnreadchats);

  const navNames = [
    {
      title: t('navigation.home'),
      href: getHomeUrl(),
      iconElement: <DashboardIcon />,
      visible: isCustomer,
    },
    {
      title: t('navigation.cabinet'),
      href: getOfficeUrl(),
      iconElement: <OfficeIcon />,
      visible: isSupplier,
    },
    {
      title: t('navigation.clients'),
      href: getClientsUrl(),
      iconElement: <ClientsIcon />,
      visible: isCustomer,
    },
    {
      title: t('navigation.chats'),
      href: getMessengerUrl(),
      iconElement: <MessangerIcon />,
      visible: true,
      unreadChats,
    },

    {
      title: t('navigation.settings'),
      href: getSettingsUrl(),
      iconElement: <SettingsIcon />,
      visible: true,
    },
  ];

  return (
    <section className={cn('menu', { open })}>
      <div className="menu__close" onClick={onClose} />
      <div className="menu__mob">
        <span onClick={onClose}>{t('navigation.close')}</span>
        <h4>{t('navigation.navigation')}</h4>
      </div>
      <Logo />
      <ul className="menu__list">
        {navNames
          .filter((navName) => navName.visible)
          .map((e) => (
            <li key={e.href}>
              <Icon
                iconElement={e.iconElement}
                linkHref={e.href}
                title={e.title}
              />
              {typeof e.unreadChats === 'number' && e.unreadChats > 0 && (
                <div className="amount-unread-chats">{e.unreadChats}</div>
              )}
            </li>
          ))}
      </ul>
      <Profile
        name={profileFullName}
        // positionInCompany="Руководитель компании"
      />
    </section>
  );
};
