import { call } from 'redux-saga/effects';
import { ENDPOINTS } from '@config';
import { deleteRequest, getRequest, postRequest, putRequest } from '@sagas/api';

import { urlFromTemplate } from '@utils/url';
import { CompanyId } from '@store/company/slice';
import {
  CompanyOrganizations,
  FullOrganizations,
  OrganizationId,
} from '@store/organizations/slice';

export type CreateOrganizationsData = {
  name: string;
  organizationForm?: string;
  phone: string;
  email: string;
  website?: string;
  responsible: string;
};

export type UpdateOrganizationsData = {
  id: OrganizationId;
  name: string;
  organizationForm?: string;
  phone: string;
  email: string;
  website?: string;
};

export function* getCompanyOrganizationObjects(companyId: CompanyId) {
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.ORGANIZATION.GET, {
      id: companyId,
    })
  );

  return data as CompanyOrganizations;
}

export function* getFullCompanyOrganizationObjects(
  companyId: CompanyId,
  query?: { page: number; max?: number }
) {
  const data: FullOrganizations[] = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.ORGANIZATION.GET_FULL, {
      id: companyId,
    }),
    query
  );
  return data;
}

export function* createOrganizationObject(
  params: CreateOrganizationsData,
  companyId: CompanyId
) {
  const { data } = yield call(
    postRequest,
    urlFromTemplate(ENDPOINTS.ORGANIZATION.POST, {
      id: companyId,
    }),
    params
  );

  return data as CompanyOrganizations;
}

export function* updateOrganization(
  companyId: CompanyId,
  organizationId: OrganizationId,
  params: UpdateOrganizationsData
) {
  const { data } = yield call(
    putRequest,
    urlFromTemplate(ENDPOINTS.ORGANIZATION.PUT, {
      companyId,
      id: organizationId,
    }),
    params
  );
  return data as CompanyOrganizations;
}

export function* deleteOrganization(companyId: CompanyId, id: OrganizationId) {
  yield call(
    deleteRequest,
    urlFromTemplate(ENDPOINTS.ORGANIZATION.DELETE, {
      companyId,
      id,
    })
  );
}

// export function* archiveOrganizationObject(
//   organizationId: OrganizationId,
//   id: string
// ) {
//   yield call(
//     patchRequest,
//     urlFromTemplate(ENDPOINTS.ORGANIZATION., {
//       organizationId,
//       id,
//     }),
//     {}
//   );
// }
