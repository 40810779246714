import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EntityStatus, FetchStatus } from '@store/constants';
import { refreshTokenError, signOutSuccess } from '@store/user/actions';
import { ResourceId } from '@store/resources/slice';
import { OperationFolderId } from '@store/operationFolders/slice';
import {
  FetchOperationsRequestAction,
  FetchOperationsSuccessAction,
  CreateOperationRequestAction,
  CreateOperationSuccessAction,
  CreateOperationErrorAction,
  UpdateOperationRequestAction,
  DeleteOperationsRequestAction,
  AddOperationResourcesRequestAction,
  UpdateOperationResourcesRequestAction,
  DeleteOperationResourcesRequestAction,
} from '@store/operations/actions';
import { Currency, UnitId } from '@store/vocabulary/slice';

export type OperationId = string;

export type OperationResource = {
  id: ResourceId;
  amount: number;
};

export type Operation = {
  status: EntityStatus;
  id: OperationId;
  name: string;
  amount: number;
  unitId: UnitId;
  operationFolderId: OperationFolderId;
  resources: OperationResource[];
  price: number;
  salePrice: number;
  markup: number;
  currency: Currency;
};

export type OperationsState = {
  status: FetchStatus;
  items: Operation[];
};

const initialState: OperationsState = {
  status: FetchStatus.NotFetched,
  items: [],
};

export const operationsSlice = createSlice({
  name: 'operations',
  initialState,
  reducers: {
    fetchOperationsRequest: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<FetchOperationsRequestAction>
    ) => {
      state.status = FetchStatus.Fetching;
    },
    fetchOperationsSuccess: (
      state,
      action: PayloadAction<FetchOperationsSuccessAction>
    ) => {
      const { data } = action.payload;

      state.status = FetchStatus.Fetched;
      state.items = data.map((d) => ({ ...d, status: EntityStatus.Fetched }));
    },
    createOperationRequest: (
      state,
      action: PayloadAction<CreateOperationRequestAction>
    ) => {
      const { data, temporaryId } = action.payload;

      state.items.push({
        ...data,
        id: temporaryId,
        resources: [],
        price: 0,
        markup: 0,
        salePrice: 0,
        currency: { id: '', name: 'dollar', code: 'USD', symbol: '$' },
        status: EntityStatus.Fetching,
      });
    },
    createOperationSuccess: (
      state,
      action: PayloadAction<CreateOperationSuccessAction>
    ) => {
      const { data, temporaryId } = action.payload;
      const index = state.items.findIndex((item) => item.id === temporaryId);

      state.items[index] = {
        ...data,
        status: EntityStatus.Fetched,
      };
    },
    createOperationError: (
      state,
      action: PayloadAction<CreateOperationErrorAction>
    ) => {
      const { temporaryId } = action.payload;

      state.items = state.items.filter((item) => item.id !== temporaryId);
    },
    updateOperationRequest: (
      state,
      action: PayloadAction<UpdateOperationRequestAction>
    ) => {
      const { id, data } = action.payload;
      const index = state.items.findIndex((item) => item.id === id);

      state.items[index] = {
        ...state.items[index],
        ...data,
      };
    },
    deleteOperationsRequest: (
      state,
      { payload }: PayloadAction<DeleteOperationsRequestAction>
    ) => {
      const { ids } = payload.data;

      state.items = state.items.filter((item) => !ids.includes(item.id));
    },
    addOperationResourcesRequest: (
      state,
      action: PayloadAction<AddOperationResourcesRequestAction>
    ) => {
      const { id, data } = action.payload;
      const { resources } = data;
      const index = state.items.findIndex((item) => item.id === id);

      const restResources = state.items[index].resources.filter(
        (currentResource) =>
          resources.every(
            (newResource) => newResource.id !== currentResource.id
          )
      );

      state.items[index].resources = [...restResources, ...resources];
    },
    updateOperationResourcesRequest: (
      state,
      action: PayloadAction<UpdateOperationResourcesRequestAction>
    ) => {
      const { id, data } = action.payload;
      const { resources } = data;
      const index = state.items.findIndex((item) => item.id === id);

      state.items[index].resources = state.items[index].resources.map(
        (resource) => resources.find((r) => r.id === resource.id) ?? resource
      );
    },
    deleteOperationResourcesRequest: (
      state,
      action: PayloadAction<DeleteOperationResourcesRequestAction>
    ) => {
      const { id, data } = action.payload;
      const { ids } = data;
      const index = state.items.findIndex((item) => item.id === id);

      state.items[index].resources = state.items[index].resources.filter(
        (resource) => !ids.includes(resource.id)
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signOutSuccess, () => initialState);
    builder.addCase(refreshTokenError, () => initialState);
  },
});

export default operationsSlice.reducer;
