/* eslint-disable react/display-name */
import checkIcon from '@static/img/icon-check9.svg';
import loading from '@static/img/logo4.svg';
import { FetchStatus } from '@store/constants';
import { OrderStatus } from '@utils/enums';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

type InProps = {
  action: () => void;
  status: keyof typeof OrderStatus;
  loadStatus?: FetchStatus;
};

type InjectProps = {
  title: string;
};

const style: CSSProperties = {
  background: 'rgba(113, 115, 223, 0.1)',
  borderColor: 'rgba(113, 115, 223, 0.1)',
  color: '#7173DF',
};

const buttonStatus = {
  0: '',
  1: 'loading',
  2: 'dynamic',
  3: 'error',
};

function Btn({ title, action, status, loadStatus = 0 }: InProps & InjectProps) {
  return (
    <button
      className={`link  add ${buttonStatus[loadStatus]}`}
      onClick={action}
      style={status === 'ISSUED' ? style : undefined}
    >
      <span style={{ fontWeight: '100' }}>{title}</span>
      <span>
        <img src={checkIcon} alt="" />
      </span>
      <i>
        <img src={loading} alt="" />
      </i>
    </button>
  );
}

export const insertAction = (
  Component: React.ComponentType<InProps & InjectProps>
) => {
  return ({ status, ...props }: InProps) => {
    const { t } = useTranslation();

    const actions: Record<keyof typeof OrderStatus, string> = {
      [OrderStatus.ACCEPTED]: t('bid.download-invoice'),
      [OrderStatus.PAYMENT]: t('bid.download-invoice'),
      [OrderStatus.PAID]: t('bid.payment-ready'),
      [OrderStatus.PRODUCTION]: t('bid.order-ready'),
      [OrderStatus.READY]: t('bid.order-successfully'),
      [OrderStatus.ISSUED]: t('bid.customer-notified'),
    };

    return (
      <Component
        {...(props as InProps)}
        title={actions[status]}
        status={status}
      />
    );
  };
};

const OrderAction = insertAction(Btn);

export default OrderAction;
