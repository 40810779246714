import {
  CSSProperties,
  ForwardedRef,
  SyntheticEvent,
  forwardRef,
  useState,
} from 'react';
import cn from 'classnames';
import { ReactComponent as IconOpen } from '@static/img/icon-open.svg';
import { ChangeHandler } from 'react-hook-form';
import styles from './styles.module.sass';

export type InputProps = {
  type?: string;
  title: string;
  name?: string;
  onChange?: ChangeHandler;
  onBlur?: ChangeHandler;
  error?: string;
  load?: boolean;
  maxLength?: number;
  svg?: JSX.Element;
  values?: string | number;
  placeholder?: string;
  style?: CSSProperties;
  // currencyValue?: string;
  // companyCurrencyCode?: string;
  // currencies?: Currency[];
  onFocus?: (event: SyntheticEvent) => void;
};

export const InputWithRef = (
  {
    type,
    title,
    name,
    onChange,
    onBlur,
    maxLength,
    error,
    load,
    onFocus,
    svg,
    values,
    placeholder,
    style,
  }: InputProps,
  ref: ForwardedRef<HTMLInputElement>
): JSX.Element => {
  const [isHideInput, setIsHideInput] = useState(false);

  const handleClickResetType = () => {
    setIsHideInput(!isHideInput);
  };
  return (
    <div
      className={cn('input open', styles.input, {
        error,
        load,
        password: isHideInput,
      })}
    >
      <input
        placeholder={placeholder}
        type={!isHideInput ? type : 'text'}
        name={name}
        maxLength={maxLength}
        ref={ref}
        onFocus={onFocus}
        onChange={onChange}
        onBlur={onBlur}
        value={values}
        style={style}
      />
      <label htmlFor="#">{title}</label>
      {type === 'password' && (
        <i>
          <IconOpen onClick={handleClickResetType} />
        </i>
      )}
      {error && <small>{error}</small>}
      <s />
      {
        // Кажется, что не используется. Возможно в дальнейшем убирем.
        /* {currencyValue && (
        <div className={styles.currency}>
          <span className={styles.value}>{currencyValue}</span>
          <span className={styles.symbol}>
            {
              currencies?.find((item) => item.code === companyCurrencyCode)
                ?.symbol
            }
          </span>
        </div>
      )} */
      }
      {!error ? svg : ''}
    </div>
  );
};
export const Input = forwardRef<HTMLInputElement, InputProps>(InputWithRef);
