export const sumTotalCost = (
  forCustomerPrices: number[] | null, // Kirill
  forSalePrices: number[] | null
) => {
  if (forCustomerPrices && forSalePrices) {
    return forCustomerPrices.map((forCustomerPrice, index: number) => {
      if (forSalePrices[index] === 0 || forCustomerPrice === 0) {
        return 0;
      }
      return forCustomerPrice - forSalePrices[index];
    });
  }
  return null;
};
