type InputChoiseModalProps = {
  name: string;
  lastNameOrOrganizationForm: string;
};

const InputChoiseModal = ({
  name,
  lastNameOrOrganizationForm,
}: InputChoiseModalProps) => {
  return (
    <div className="radio">
      <input type="radio" />
      <label>
        <b>{name}</b>
        <p>{lastNameOrOrganizationForm}</p>
      </label>
    </div>
  );
};
export default InputChoiseModal;
