import React, { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import './styles.sass';

type ListItemType = {
  id: string;
  name?: string;
  path?: string;
};
export type SelectProps = {
  title?: string | null;
  listItems: ListItemType[];
  isSmall?: boolean;
  onSelect: (id: string) => void;
  selectedId?: string;
  error?: string;
  onSearch?: (query: string) => void;
  onBlur?: () => void;
  selectedItem?: ListItemType;
  classNameInput?: string;
  disabled?: boolean;
  maxLength?: number;
};

const SelectWithRef = (
  {
    listItems,
    isSmall,
    onSelect,
    selectedId,
    error,
    onSearch,
    onBlur,
    selectedItem,
    disabled,
    classNameInput,
    maxLength,
  }: SelectProps,
  ref: ForwardedRef<HTMLInputElement>
): JSX.Element => {
  const { t } = useTranslation();

  const [activeModal, setActiveModal] = useState(false);
  const [value, setValue] = useState('');

  const selected = listItems.find((i) => i.id === selectedId);

  const modalHandler = () => {
    setValue('');
    setActiveModal(!activeModal);
    if (activeModal && onSearch) {
      setValue(selectedItem?.name || '');
    }
  };

  const onClickActive = (listItem: ListItemType) => {
    onSelect(listItem.id);
    setActiveModal(false);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onSearch?.(e.target.value);
  };

  const onBlurHandler = () => {
    setActiveModal(false);
    onBlur?.();
  };

  const getOptions = () => {
    let options = [...listItems];
    if (selectedItem) {
      options = [
        selectedItem,
        ...listItems.filter((item) => item.id !== selectedItem.id),
      ];
    }
    return options;
  };

  useEffect(() => {
    if (selected && selected.name) {
      setValue(selected.name);
    }
  }, [selected]);

  useEffect(() => {
    if (selectedItem && selectedItem.name && !activeModal) {
      setValue(selectedItem.name);
    }
  }, [activeModal, selectedItem]);

  return (
    <div
      className={cn(
        'sel active',
        isSmall && 'small',
        activeModal && 'open',
        activeModal && 'index',
        classNameInput,
        { error }
      )}
    >
      <input
        type="text"
        onClick={modalHandler}
        placeholder={t('estimate.select-a-category') as string}
        value={value}
        title={value}
        readOnly={!onSearch}
        ref={ref}
        onChange={onInputChange}
        onBlur={onBlurHandler}
        className="sel__input"
        disabled={disabled}
        maxLength={maxLength}
      />
      {error && <small>{error}</small>}
      <ul>
        {getOptions().map((listItem) => (
          <li
            key={listItem.id}
            className={cn({ active: listItem.id === selectedId })}
            onClick={() => onClickActive(listItem)}
          >
            <span className="sel__listitem-path">{listItem.path}</span>
            <span title={listItem.name}>{listItem.name}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};
export const SelectWithSearch = forwardRef<HTMLInputElement, SelectProps>(
  SelectWithRef
);
