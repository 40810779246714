import { call } from 'redux-saga/effects';
import { ENDPOINTS } from '@config';
import { getRequest, putRequest, deleteRequest } from '@sagas/api';
import { CompanyData, CompanyId } from '@store/company/slice';
import { SaveCompanyRequestData } from '@store/company/actions';
import { urlFromTemplate } from '@utils/url';

export function* getCompany(id: CompanyId) {
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.COMPANY.GET, { id })
  );

  return data as CompanyData;
}

export function* saveCompany(params: SaveCompanyRequestData) {
  yield call(
    putRequest,
    urlFromTemplate(ENDPOINTS.COMPANY.SAVE, { id: params.id }),
    params
  );
}

export function* deleteCompany(id: CompanyId) {
  yield call(deleteRequest, urlFromTemplate(ENDPOINTS.COMPANY.GET, { id }));
}
