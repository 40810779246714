import { Modal, ModalSize } from '@components/Modal';
import { ObjectModal } from '@pages/Dashboard/ObjectModal';
import {
  getDeleteItemOrderCustomerStatus,
  getOrderItemsCustomer,
  getRequestAnInvoiceStatus,
} from '@store/ordersCustomer/selectors';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import {
  deleteOrderCustomerItemRequest,
  fetchOrderCustomerRequest,
  requestAnInvoiceRequest,
  resetDeleteOrderCustomerItemStatus,
} from '@store/ordersCustomer/actions';
import { OrderDocuments, OrderStatus } from '@store/ordersCustomer/slice';
import { useTranslation } from 'react-i18next';
import { useCurrentLocale } from '@hooks/useCurrentLocale';
import { currencyFormat } from '@utils/currency';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getisViewChatDrawer } from '@store/modalControl/selectors';
import { ConfirmationModal } from '@components/commons/ConfirmationModal';
import { useDidUpdateEffect } from '@hooks/useDidUpdateEffect';
import Chat from '@pages/Messenger/Chat';
import { setIsViewChatDrawer } from '@store/modalControl/actions';
import OrderModalTable from './OrderModalTable';
import OrdersModalStatus from './OrderModalStatus';
import OrderModalHeaderButton from './OrderModalHeaderButton';
import styles from './styles.module.sass';

type Props = {
  orderId: string;
  invoiceValidity: number;
  currencyCode: string;
  orderNumber: number;
  totalCost: number;
  invoice: OrderDocuments;
  proxy: OrderDocuments;
  isViewChoise: boolean;
  supplierName: string;
  supplierLogo?: string;
  needInvoice: boolean;
  status: OrderStatus;
  handleChoiseClose: () => void;
};

const OrderModal = ({
  orderId,
  invoiceValidity,
  currencyCode,
  orderNumber,
  totalCost,
  invoice,
  proxy,
  isViewChoise,
  needInvoice,
  supplierLogo,
  supplierName,
  status,
  handleChoiseClose,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const locale = useCurrentLocale();
  const orderItemsData = useAppSelector(getOrderItemsCustomer);
  const requestAnInvoiceStatus = useAppSelector(getRequestAnInvoiceStatus);
  const isViewChatDrawer = useAppSelector(getisViewChatDrawer);
  const deleteItemrderCustomerStatus = useAppSelector(
    getDeleteItemOrderCustomerStatus
  );

  const total = currencyFormat(totalCost, currencyCode, locale);

  const [searchParams] = useSearchParams();
  const costEstimateIdFromQuery = searchParams.get('costEstimateId');

  const [isViewDeleteItemModal, setIsViewDeleteItemModal] = useState(false);
  const [itemId, setItemId] = useState('');

  const handleCloseDeleteItemModal = () => {
    setIsViewDeleteItemModal(false);
  };

  const handleOpenDeleteItemModal = (id: string) => {
    dispatch(resetDeleteOrderCustomerItemStatus());
    setIsViewDeleteItemModal(true);
    setItemId(id);
  };

  const handleClickDeleteItemOrderCustomer = () => {
    dispatch(deleteOrderCustomerItemRequest({ orderitemId: itemId, orderId }));
  };

  const requestAnInvoice = () => {
    if (costEstimateIdFromQuery) {
      dispatch(
        requestAnInvoiceRequest({
          orderId,
          costEstimateId: costEstimateIdFromQuery,
        })
      );
    }
  };

  useEffect(() => {
    if (costEstimateIdFromQuery && isViewChoise) {
      dispatch(
        fetchOrderCustomerRequest({ costEstimateId: costEstimateIdFromQuery })
      );
    }
  }, [costEstimateIdFromQuery, dispatch, isViewChoise]);

  useDidUpdateEffect(() => {
    if (
      !orderItemsData?.items.length &&
      costEstimateIdFromQuery &&
      isViewChoise
    ) {
      dispatch(
        fetchOrderCustomerRequest({ costEstimateId: costEstimateIdFromQuery })
      );
    }
  }, [orderItemsData, costEstimateIdFromQuery, isViewChoise]);

  return (
    <Modal
      visible={isViewChoise}
      size={isViewChatDrawer ? ModalSize.Max : ModalSize.MediumPlus}
      onBackgroundClick={handleChoiseClose}
    >
      <ObjectModal onClose={handleChoiseClose} styles={{ padding: '0px' }}>
        <>
          <ConfirmationModal
            confirmTitle={t('confirm.delete-resource-order-confirm')}
            isVisibleModal={isViewDeleteItemModal}
            status={deleteItemrderCustomerStatus}
            confirmButtonClassName="bg-danger"
            onCancel={handleCloseDeleteItemModal}
            onConfirm={handleClickDeleteItemOrderCustomer}
          />
          <div style={{ display: 'flex' }}>
            <div className="order">
              <div className="order__cap">
                <div
                  className="order__cap-full"
                  style={{ justifyContent: 'space-between' }}
                >
                  <h4>
                    {t('orders.order-for-the-amount', {
                      orderNumber,
                      totalCost: total,
                    })}
                  </h4>
                  <OrderModalHeaderButton
                    requestAnInvoiceStatus={requestAnInvoiceStatus}
                    requestAnInvoice={requestAnInvoice}
                    needInvoice={needInvoice}
                    orderId={orderId}
                    invoice={invoice}
                    proxy={proxy}
                    status={status}
                  />
                </div>
              </div>
              <OrdersModalStatus
                status={status}
                invoiceValidity={invoiceValidity}
                needInvoice={needInvoice}
                invoice={invoice}
                orderId={orderId}
              />
              <OrderModalTable
                orderItemsData={orderItemsData}
                supplierName={supplierName}
                supplierLogo={supplierLogo}
                needInvoice={needInvoice}
                status={status}
                orderId={orderId}
                onDeleteItem={handleOpenDeleteItemModal}
              />
            </div>
            {isViewChatDrawer && (
              <Chat
                onClose={() => dispatch(setIsViewChatDrawer())}
                rootClassName={styles.chatWidthSize}
              />
            )}
          </div>
        </>
      </ObjectModal>
    </Modal>
  );
};
export default OrderModal;
