import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

export type ObjectModalProps = {
  children: JSX.Element;
  styles?: React.CSSProperties;
  onClose: () => void;
};

export const ObjectModal: FC<ObjectModalProps> = ({
  children,
  onClose,
  styles,
}) => {
  const { t } = useTranslation();

  return (
    <div className="modal__core" style={styles}>
      <div className="modal__close" onClick={onClose}>
        {t('object.cancel')}
      </div>
      {children}
    </div>
  );
};
