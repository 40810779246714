import { FC, memo, SyntheticEvent, useContext } from 'react';
import { useAppSelector } from '@hooks/redux';
import { getFolder, getParentFolder } from '@store/resourceFolders/selectors';
import { ResourcesCatalogContext } from '@components/ResourcesCatalog';
import { ReactComponent as ArrowIcon } from '@static/img/icon-arrow7.svg';

const ResourcesCatalogHeaderTitleRaw: FC = () => {
  const { folderId, onChangeFolder } = useContext(ResourcesCatalogContext);
  const currentFolder = useAppSelector((state) => getFolder(state, folderId));
  const prevFolder = useAppSelector((state) =>
    getParentFolder(state, folderId)
  );

  if (prevFolder === undefined || currentFolder === undefined) {
    return null;
  }

  const handleClick = (e: SyntheticEvent) => {
    e.preventDefault();

    onChangeFolder(prevFolder.id);
  };

  return (
    <div className="manager__title">
      <a href="#" className="cap__prev" onClick={handleClick}>
        <ArrowIcon />
      </a>
      <h2>{currentFolder.name}</h2>
    </div>
  );
};

export const ResourcesCatalogHeaderTitle = memo(ResourcesCatalogHeaderTitleRaw);
