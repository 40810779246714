import { HTMLAttributes } from 'react';

type Props = {
  disabled?: boolean;
  children: React.ReactNode;
} & HTMLAttributes<HTMLButtonElement>;

export default function IconButton({ children, ...restProps }: Props) {
  return <button {...restProps}>{children}</button>;
}
