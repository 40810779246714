import React, { FC, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useCurrentLocale } from '@hooks/useCurrentLocale';
import {
  CompanyObject,
  CompanyObjectId,
  ObjectStatus,
} from '@store/companyObjects/slice';
import {
  archiveObjectRequest,
  setArchiveObjectStatus,
} from '@store/companyObjects/actions';
import { CompanyId } from '@store/company/slice';
import { getHomeUrl } from '@pages';
import { currencyFormat } from '@utils/currency';
import iconWhatsapp from '@static/img/icon-whatsapp.svg';
import iconEmail from '@static/img/icon-email2.svg';
import { nanoid } from '@reduxjs/toolkit';
import { ConfirmationModal } from '@components/commons/ConfirmationModal';
import { getArchiveObjectStatus } from '@store/companyObjects/selectors';
import { FetchStatus } from '@store/constants';
import ObjectImage from '../ObjectImage';
import ObjectStatusUpdate from '../ObjectStatusUpdate';
import { ObjectPhase } from '../ObjectPhase';
import styles from './styles.module.sass';

type ObjectInfoProps = {
  currentObject: CompanyObject;
  currencyCode: string;
  companyId: CompanyId;
  objectId: CompanyObjectId;
};

export const ObjectInfo: FC<ObjectInfoProps> = ({
  companyId,
  objectId,
  currentObject,
  currencyCode,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const locale = useCurrentLocale();
  const archiveObjectStatus = useAppSelector(getArchiveObjectStatus);

  const [isVisibleModal, setVisibleModal] = useState(false);

  const navigate = useNavigate();

  const { name, cost, address, organization, contact, status, description } =
    currentObject;

  const infoItems = [
    {
      title: t('about-object.object-name'),
      info: name,
      status: ObjectStatus.NEW,
      statusName: 'new-application',
    },
    {
      title: t('about-object.current-value'),
      info: currencyFormat(cost, currencyCode, locale),
      status: ObjectStatus.PREPARE,
      statusName: 'cost-estimate-preparation',
    },
    {
      title: t('about-object.address'),
      info: address,
      status: ObjectStatus.AGREEMENT,
      statusName: 'commercial-proposal-approval',
    },
    {
      title: t('about-object.organization'),
      info: organization?.name,
      status: ObjectStatus.IN_WORK,
      statusName: 'in-work',
    },
    {
      title: t('object.taskDescription'),
      info: description,
    },
  ];

  const handleClickOpenArchiveButton = (event: SyntheticEvent) => {
    dispatch(setArchiveObjectStatus(FetchStatus.NotFetched));
    event.preventDefault();
    setVisibleModal(true);
  };

  const handleConfirmButton = () => {
    dispatch(archiveObjectRequest({ companyId, id: objectId }));
  };

  const handleConfirmSuccess = () => {
    dispatch(setArchiveObjectStatus(FetchStatus.NotFetched));
    navigate(getHomeUrl());
  };

  const handleClickCancelButton = () => {
    setVisibleModal(false);
  };

  return (
    <>
      <ConfirmationModal
        confirmTitle={t('confirm.archive-confirm')}
        isVisibleModal={isVisibleModal}
        status={archiveObjectStatus}
        confirmButtonClassName="bg-danger"
        onCancel={handleClickCancelButton}
        onConfirm={handleConfirmButton}
        onSuccess={handleConfirmSuccess}
      />
      <div className="obj__info">
        <div className="obj__tab">
          <div className="tab border switch" data-tab="obj-info">
            <ul>
              <li className="active">{t('about-object.info')}</li>
              {/* TODO: development functiomnal */}
              {/* <li>Лента</li> */}
            </ul>
          </div>
        </div>
        <div className="tab__block obj-info">
          <div className="tab__item active">
            <div className="obj__info-group">
              <div className="obj__cap">
                <h4>{t('about-object.info')}</h4>
                {infoItems.map((item) => {
                  return (
                    item.status === status && (
                      <small key={nanoid()}>
                        {t(`object.${item.statusName}`)}
                      </small>
                    )
                  );
                })}
              </div>
              <ObjectImage filePath={currentObject.filePath} />
              <ObjectStatusUpdate currentObject={currentObject} />
            </div>

            <ObjectPhase status={status} />

            <div className="obj__info-list">
              {infoItems.map((item) => {
                return (
                  item.info && (
                    <div key={nanoid()} className="obj__info-text">
                      <p>{item.title}</p>
                      <b
                        className={styles.objInfoListItemText}
                        title={item.info}
                      >
                        {item.info}
                      </b>
                    </div>
                  )
                );
              })}
              {contact && (
                <div className="obj__info-contact">
                  <b>
                    {contact.firstName} {contact.lastName}
                  </b>
                  <ul>
                    <li>
                      <img src={iconWhatsapp} alt="Whatsapp" />
                      {contact.phone}
                    </li>

                    <li className={styles.objInfoListItemText}>
                      <img src={iconEmail} alt="Email" />
                      {contact.email}
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="obj__info-but">
        <Link
          to={getHomeUrl()}
          className="link white border"
          onClick={handleClickOpenArchiveButton}
        >
          {t('about-object.archive-object')}
        </Link>
      </div>
    </>
  );
};
