import React, { FC, useCallback, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { ROOT_FOLDER_ID } from '@constants/folders';
import { useSelectedResources } from '@hooks/useSelectedResources';
import { Modal } from '@components/Modal';
import {
  EstimateOperationsCatalogSize,
  EstimateOperationsCatalog,
} from '@components/EstimateOperationsCatalog';
import { OperationFolderId } from '@store/operationFolders/slice';
import { Operation, OperationId } from '@store/operations/slice';
import { ResourceId } from '@store/resources/slice';
import styles from './styles.module.sass';

type OperationData = {
  amount: number;
  price: number;
  markup: number;
  salePrice: number;
};

type EstimateOperationsCatalogModalProps = {
  visible: boolean;
  onAddOperations: (
    selectedItems: string[],
    operations: Record<OperationId, OperationData>
  ) => void;
  onCancel: () => void;
};

export const EstimateOperationsCatalogModal: FC<
  EstimateOperationsCatalogModalProps
> = ({ visible, onAddOperations, onCancel }) => {
  const { t } = useTranslation();
  const [folderId, setFolderId] = useState(ROOT_FOLDER_ID);
  const [operations, setOperations] = useState<
    Record<OperationId, OperationData>
  >({});

  const [selectedResources, handleSelectResources] = useSelectedResources();
  const selectResources = useCallback(
    (
      operationId: OperationId,
      resourceIds: ResourceId[],
      forceSelected?: boolean
    ) => {
      handleSelectResources(
        resourceIds.map((resourceId) => `${operationId} ${resourceId}`),
        forceSelected
      );
    },
    [handleSelectResources]
  );
  const isSelectedResource = useCallback(
    (operationId: OperationId, resourceId: ResourceId) =>
      selectedResources.includes(`${operationId} ${resourceId}`),
    [selectedResources]
  );
  const isSelectedOperation = useCallback(
    (operation: Operation) =>
      operation.resources.length > 0 &&
      operation.resources.some((resource) =>
        isSelectedResource(operation.id, resource.id)
      ),
    [isSelectedResource]
  );

  const activeButtons = selectedResources.length > 0;

  const handleChangeFolder = (id: OperationFolderId) => {
    setFolderId(id);
  };

  const handleOperationChange = (id: OperationId, value: OperationData) => {
    setOperations((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleAddOperationsClick = () => {
    onAddOperations(selectedResources, operations);
    onCancel();
  };
  const handleCancelClick = () => {
    onCancel();
  };

  return (
    <Modal visible={visible} onBackgroundClick={onCancel}>
      <EstimateOperationsCatalog
        className={cn('modal-scroll', {
          [styles.modalScroll]: !activeButtons,
          [styles.activeButtons]: activeButtons,
        })}
        size={EstimateOperationsCatalogSize.Minimal}
        folderId={folderId}
        onChangeFolder={handleChangeFolder}
        selectedResources={selectedResources}
        isSelectedResource={isSelectedResource}
        isSelectedOperation={isSelectedOperation}
        onSelectResources={selectResources}
        onOperationChange={handleOperationChange}
      />
      {activeButtons && (
        <div className="modal__but">
          <a
            className="link white border modal-close"
            onClick={handleCancelClick}
          >
            {t('confirm.cancel')}
          </a>
          <a className="link" onClick={handleAddOperationsClick}>
            {t('estimate.add-operations')}
          </a>
        </div>
      )}
    </Modal>
  );
};
