import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { Saga } from '@sagas/types';
import { handleError } from '@sagas/errors';
import { signOutRequest } from '@store/user/actions';
import {
  deleteCompanyRequest,
  DeleteCompanyRequestAction,
  deleteCompanySuccess,
  fetchCompanyRequest,
  FetchCompanyRequestAction,
  fetchCompanySuccess,
  saveCompanyRequest,
  SaveCompanyRequestAction,
  saveCompanySuccess,
  setDeleteCompanyStatus,
} from '@store/company/actions';
import { getCompany } from '@store/company/selectors';
import { FetchStatus } from '@store/constants';
import {
  deleteCompany,
  getCompany as getCompanyApi,
  saveCompany,
} from './api/company';

const fetchCompanyRequestHandler: Saga<FetchCompanyRequestAction> = function* ({
  payload,
}) {
  const { id } = payload;

  try {
    const data = yield call(getCompanyApi, id);

    yield put(fetchCompanySuccess({ data }));
  } catch (e) {
    yield call(handleError, e);
  }
};

const saveCompanyRequestHandler: Saga<SaveCompanyRequestAction> = function* ({
  payload,
}) {
  const { data } = payload;
  const company: ReturnType<typeof getCompany> = yield select(getCompany);

  if (company === null) {
    // TODO: show error, may be dispatch log out
    return;
  }

  try {
    yield call(saveCompany, data);
    yield put(
      saveCompanySuccess({
        data: {
          ...company,
          ...data,
        },
      })
    );
  } catch (e) {
    yield call(handleError, e);
  }
};

const deleteCompanyRequestHandler: Saga<DeleteCompanyRequestAction> =
  function* ({ payload }) {
    const { id } = payload;

    try {
      yield put(setDeleteCompanyStatus(FetchStatus.Fetching));
      yield call(deleteCompany, id);
      yield put(deleteCompanySuccess());
      yield put(setDeleteCompanyStatus(FetchStatus.Fetched));
      yield put(signOutRequest());
    } catch (e) {
      yield put(setDeleteCompanyStatus(FetchStatus.Error));
      yield call(handleError, e);
    }
  };

export default function* root() {
  yield all([
    takeLatest(fetchCompanyRequest, fetchCompanyRequestHandler),
    takeLatest(saveCompanyRequest, saveCompanyRequestHandler),
    takeLatest(deleteCompanyRequest, deleteCompanyRequestHandler),
  ]);
}
