import { call } from 'redux-saga/effects';
import { ENDPOINTS } from '@config';
import { getRequest, putRequest, deleteRequest, postRequest } from '@sagas/api';
import { urlFromTemplate } from '@utils/url';
import { SupplierData, SupplierId } from '@store/supplier/slice';
import { SaveSupplierRequestData } from '@store/supplier/actions';

type CategoryReguest = {
  categoriesIds: string[];
  id: string;
};

export function* getSupplier(id: SupplierId) {
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.SUPPLIER.GET, { id })
  );
  return data as SupplierData;
}

export function* saveSupplier(params: SaveSupplierRequestData) {
  yield call(
    putRequest,
    urlFromTemplate(ENDPOINTS.SUPPLIER.SAVE, { id: params.id }),
    params
  );
}

export function* updateSupplierCategories(params: CategoryReguest) {
  yield call(
    postRequest,
    urlFromTemplate(ENDPOINTS.SUPPLIER.CATEGORY.UPDATE, { id: params.id }),
    params
  );
}

export function* deleteSupplier(id: SupplierId) {
  yield call(deleteRequest, urlFromTemplate(ENDPOINTS.SUPPLIER.GET, { id }));
}
