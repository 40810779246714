import { errorsSlice } from '@store/errors/slice';
import { NoticeTypes } from '@utils/notifications';

export type AddErrorMessageAction = {
  id: string;
  message: string;
  type?: NoticeTypes;
};

export type RemoveErrorMessageAction = {
  id: string;
};

export type SetErrorFieldsAction = {
  fields: Record<string, string>;
};

export type ResetErrorFieldsAction = void;

export const { addMessage, removeMessage, setErrorFields, resetErrorFields } =
  errorsSlice.actions;
