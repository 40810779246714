import { useForm, SubmitHandler } from 'react-hook-form';

import Field from '@components/commons/Field';
import Button from '@components/commons/Button';
import { inputErrors } from '@utils/inputErrors';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { getStatusSavePassword } from '@store/account/selectors';
import { savePasswordRequest } from '@store/account/actions';
import { useParams } from 'react-router-dom';

type FormData = {
  password: string;
  repeatPassword: string;
  token: string;
};

export const ResetForm = () => {
  const dispatch = useAppDispatch();
  const { token } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormData>({ defaultValues: { token } });
  const onSubmit: SubmitHandler<FormData> = (data) => {
    if (data.password !== data.repeatPassword) {
      return setError('repeatPassword', {
        type: 'passwordsNotMatch',
        message: '',
      });
    }
    dispatch(savePasswordRequest(data));
  };
  const status = useAppSelector(getStatusSavePassword);
  return (
    <form className="login__form" onSubmit={handleSubmit(onSubmit)}>
      <Field
        register={() =>
          register('password', {
            required: true,
            minLength: 6,
          })
        }
        placeholder="auth.password"
        type="password"
        errorInput={() => inputErrors('password', errors.password?.type)}
      />
      <Field
        register={() =>
          register('repeatPassword', {
            required: true,
            minLength: 6,
          })
        }
        placeholder="auth.repeat-password"
        errorInput={() =>
          inputErrors('repeatPassword', errors.repeatPassword?.type)
        }
        type="password"
      />

      <Button name="confirm.confirm" status={status} />
    </form>
  );
};
