import { FC, memo } from 'react';
import { Modal, ModalSize } from '@components/Modal';
import { OfferGenerator } from '@components/OfferGenerator';

type OfferGeneratorModalProps = {
  visible: boolean;
  onCancel: () => void;
};

const OfferGeneratorModalRaw: FC<OfferGeneratorModalProps> = ({
  visible,
  onCancel,
}) => {
  const handleCancelClick = () => {
    onCancel();
  };

  return (
    <Modal
      size={ModalSize.MediumPlus}
      visible={visible}
      onBackgroundClick={onCancel}
    >
      <OfferGenerator onCancel={handleCancelClick} />
    </Modal>
  );
};

export const OfferGeneratorModal = memo(OfferGeneratorModalRaw);
