import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type BaseVocabulary = { id: string; name: string };

type I18nextTranslationCategory = string;

export const useLocalizedVocabulary = <T extends BaseVocabulary>(
  vocabulary: T[],
  translationCategory: I18nextTranslationCategory
): T[] => {
  const { t } = useTranslation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sortVocabularyNameByAlphabetical = (a: T, b: T) => {
    if (a.name < b.name) {
      return -1;
    }

    if (a.name > b.name) {
      return 1;
    }

    return 0;
  };

  return useMemo(
    () =>
      vocabulary
        ?.map((v) => ({
          ...v,
          name: t(`${translationCategory}.${v.name}`),
        }))
        .sort(sortVocabularyNameByAlphabetical),
    [vocabulary, sortVocabularyNameByAlphabetical, t, translationCategory]
  );
};
