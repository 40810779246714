// TODO: change any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sumCostEstimates = (obj: any, key: string) => {
  if (obj) {
    const humPrice = parseFloat(obj.costDetails.HUM?.[key].toFixed(2)) || 0;
    const matPrice = parseFloat(obj.costDetails.MAT?.[key].toFixed(2)) || 0;
    const mecPrice = parseFloat(obj.costDetails.MEC?.[key].toFixed(2)) || 0;
    const extPrice = parseFloat(obj.costDetails.EXT?.[key].toFixed(2)) || 0;

    const total = humPrice + matPrice + mecPrice + extPrice;

    return [total || 0, humPrice, matPrice, mecPrice, extPrice];
  }
  return null;
};
