import { call } from 'redux-saga/effects';
import { ENDPOINTS } from '@config';
import { getRequest, patchRequest, postRequest, putRequest } from '@sagas/api';
import { CompanyObjectId } from '@store/companyObjects/slice';
import { CostEstimateId } from '@store/costEstimates/slice';
import {
  CostEstimateResource,
  CostEstimateResourceId,
  CostEstimateResourceItems,
  ResourceParent,
} from '@store/costEstimateResources/slice';
import { CompanyId } from '@store/company/slice';
import { urlFromTemplate } from '@utils/url';
import { ResourceId, ResourceType } from '@store/resources/slice';
import { UnitId } from '@store/vocabulary/slice';
import { CostEstimateOperationId } from '@store/costEstimateOperations/slice';

export type FetchedCostEstimateResource = {
  id: CostEstimateResourceId;
  name: string;
  costEstimateId: CostEstimateId;
  items: CostEstimateResourceItems[];
  operationId: CostEstimateOperationId;
  type: ResourceType;
  unitId: UnitId;
  amount: number;
  price: number;
  salePrice: number;
  markup: number;
  cost: number;
  saleCost: number;
  numberOfResponses: number | null;
  defaultPrice: number | null;
  response: {
    id: string;
    name: string;
    amount: number;
    type: string;
    price: number;
    cost: number;
    expired: boolean;
    unitId: string;
    deliveryTime: number;
    validity: number;
  } | null;
  resourceParent: ResourceParent;
  ordered: boolean;
  ordering: number;
  deliveryTime: number | null;
};

export type CreateCostEstimateResourceData = {
  operationId: CostEstimateOperationId;
  categoryId?: string;
  categoryName?: string;
  name: string;
  type: ResourceType;
  unitId: UnitId;
  amount: number;
  price: number;
  salePrice: number;
  markup: number;
  cost: number;
  saleCost: number;
};

export type UpdateCostEstimateResourceData = {
  operationId: CostEstimateOperationId;
  categoryId: string;
  categoryName: string;
  name: string;
  type: ResourceType;
  unitId: UnitId;
  amount: number;
  price: number;
  salePrice: number;
  markup: number;
  cost: number;
  saleCost: number;
};

export type ParamsMoveResource = {
  subjectElementId: string;
  countPoint: string;
  elementType: string;
  objectElementId: string;
};

export function* getCommercialProposal(
  companyId: CompanyId,
  objectId: CompanyObjectId,
  costEstimateId: CostEstimateId
) {
  yield call(
    getRequest,
    urlFromTemplate(
      ENDPOINTS.COMPANY_OBJECTS.COST_ESTIMATES.COMMERCIAL_PROPOSAL.GET_CP,
      {
        companyId,
        objectId,
        costEstimateId,
      }
    )
  );
}

export function* getCostEstimateResource(resourceId: ResourceId) {
  const { data } = yield call(
    getRequest,
    urlFromTemplate(
      ENDPOINTS.COMPANY_OBJECTS.COST_ESTIMATE_RESOURCES.GET_RESOURCE,
      {
        resourceId,
      }
    )
  );
  return data as CostEstimateResource;
}

export function* getCostEstimateResources(
  companyId: CompanyId,
  objectId: CompanyObjectId,
  costEstimateId: CostEstimateId
) {
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.COMPANY_OBJECTS.COST_ESTIMATE_RESOURCES.GET, {
      companyId,
      objectId,
      costEstimateId,
    })
  );

  return data as FetchedCostEstimateResource[];
}

export function* createCostEstimateResource(
  companyId: CompanyId,
  objectId: CompanyObjectId,
  costEstimateId: CostEstimateId,
  params: CreateCostEstimateResourceData
) {
  const { data } = yield call(
    postRequest,
    urlFromTemplate(ENDPOINTS.COMPANY_OBJECTS.COST_ESTIMATE_RESOURCES.CREATE, {
      companyId,
      objectId,
      costEstimateId,
    }),
    params
  );

  return data as FetchedCostEstimateResource;
}

export function* updateCostEstimateResource(
  companyId: CompanyId,
  objectId: CompanyObjectId,
  costEstimateId: CostEstimateId,
  id: CostEstimateResourceId,
  params: UpdateCostEstimateResourceData
) {
  const { data } = yield call(
    putRequest,
    urlFromTemplate(ENDPOINTS.COMPANY_OBJECTS.COST_ESTIMATE_RESOURCES.UPDATE, {
      companyId,
      objectId,
      costEstimateId,
      id,
    }),
    params
  );

  return data as FetchedCostEstimateResource;
}

export function* deleteCostEstimateResource(
  companyId: CompanyId,
  objectId: CompanyObjectId,
  costEstimateId: CostEstimateId,
  id: CostEstimateResourceId
) {
  yield call(
    patchRequest,
    urlFromTemplate(ENDPOINTS.COMPANY_OBJECTS.COST_ESTIMATE_RESOURCES.DELETE, {
      companyId,
      objectId,
      costEstimateId,
    }),
    {
      resources: [id],
    }
  );
}

export function* moveCostEstimateResource(
  companyId: string,
  objectId: string,
  costEstimateId: string,
  params: ParamsMoveResource
) {
  const { data } = yield call(
    putRequest,
    urlFromTemplate(ENDPOINTS.COMPANY_OBJECTS.COST_ESTIMATES.MOVEITEMS, {
      companyId,
      objectId,
      costEstimateId,
    }),
    params
  );

  return data as FetchedCostEstimateResource[];
}
