import { call } from 'redux-saga/effects';
import { ENDPOINTS } from '@config';
import {
  getRequest,
  patchRequest,
  postRequest,
  deleteRequest,
} from '@sagas/api';
import { urlFromTemplate } from '@utils/url';
import { CompanyObjectId } from '@store/companyObjects/slice';
import {
  CommercialProposal,
  CommercialProposalApproved,
  CommercialProposalsId,
  CostEstimate,
  CostEstimateId,
  HistoricalCostEstimateId,
  ObjectCostEstimate,
  SectionId,
} from '@store/costEstimates/slice';
import { CompanyId } from '@store/company/slice';

export type UpdateCommercialProposalsData = {
  approved: CommercialProposalApproved;
};

export type CostEstimatesData = {
  name: string;
};

export type CommercialProposalsData = {
  id: CommercialProposalsId;
  url: string;
  startWorkAt: number;
  endWorkAt: number;
  creationDate: number;
  costEstimateId: CostEstimateId;
  approved: boolean;
};

export type ObjectCostEstimateData = {
  id: CostEstimateId;
  buildingObjectId: CompanyObjectId;
  createdAt: number;
  currencyCode: string;
  cost: number;
  saleCost: number;
  costDetails: {
    MAT: { cost: number; saleCost: number };
    MEC: { cost: number; saleCost: number };
    HUM: { cost: number; saleCost: number };
    EXT: { cost: number; saleCost: number };
  };
  commercialProposals: CommercialProposalsData[];
  requestId: string;
};

export type Section = {
  id: SectionId;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: any; // TODO change to res type
  name: string;
};

export type CreateCommercialProposalsData = {
  name: string;
  startWorkAt: string;
  endWorkAt: string;
  content: string;
  clientFullName: string;
  managerFullName: string;
  managerPhone: string;
  includeFullCostEstimate: boolean;
  creationDate: string;
  screenOrdering: number[];
  prePaymentPercent: number;
  postPaymentPercent: number;
  prePayment: number | null;
  postPayment: number | null;
  approved?: boolean;
  costEstimateId?: HistoricalCostEstimateId;
  createdAt?: number;
  id?: CommercialProposalsId;
  url?: string;
};

export type CreateCostEstimateData = {
  buildingObjectId: CompanyObjectId;
  commercialProposalId?: CommercialProposalsId;
  cost: number;
  createdAt: number;
  currencyCode: string;
  id: CostEstimateId;
  parentId?: HistoricalCostEstimateId;
  saleCost: number;
  sections: Section[];
};

export type CompletelyFilledCommercialProposalData = {
  id: CommercialProposalsId;
  name: string;
  url: string;
  approved: boolean;
  startWorkAt: string;
  endWorkAt: string;
  content: string;
  clientFullName: string;
  createdAt: Date;
  managerFullName: string;
  managerPhone: string;
  includeFullCostEstimate: boolean;
  firstPayment: number;
  screenOrdering: number[];
  costEstimateId: HistoricalCostEstimateId;
  creationDate: number;
  postPayment: number;
  postPaymentPercent: number;
  prePayment: number;
  prePaymentPercent: number;
};

// For copy data prev iteration
export function* getCompletelyFilledCommercialProposal(
  companyId: CompanyId,
  objectId: CompanyObjectId,
  id: HistoricalCostEstimateId
) {
  const { data } = yield call(
    getRequest,
    urlFromTemplate(
      ENDPOINTS.COMPANY_OBJECTS.COST_ESTIMATES.COMMERCIAL_PROPOSAL.GET,
      {
        companyId,
        objectId,
        id,
      }
    )
  );

  return data as CompletelyFilledCommercialProposalData;
}

export function* getObjectCostEstimates(
  companyId: CompanyId,
  id: CompanyObjectId
) {
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.COMPANY_OBJECTS.COST_ESTIMATES.GET_ALL, {
      companyId,
      id,
    })
  );

  return data as ObjectCostEstimate[];
}

export function* getCostEstimate(
  companyId: CompanyId,
  objectId: CompanyObjectId,
  id: CostEstimateId
) {
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.COMPANY_OBJECTS.COST_ESTIMATES.GET, {
      companyId,
      objectId,
      id,
    })
  );

  return data as CostEstimate;
}

export function* createCostEstimate(
  params: CostEstimatesData,
  companyId: CompanyId,
  id: CompanyObjectId
) {
  const { data } = yield call(
    postRequest,
    urlFromTemplate(ENDPOINTS.COMPANY_OBJECTS.COST_ESTIMATES.CREATE, {
      companyId,
      id,
    }),
    params
  );

  return data as CostEstimate;
}

export function* copyCostEstimate(
  companyId: CompanyId,
  objectId: CompanyObjectId,
  id: HistoricalCostEstimateId
) {
  const { data } = yield call(
    postRequest,
    urlFromTemplate(ENDPOINTS.COMPANY_OBJECTS.COST_ESTIMATES.COPY, {
      companyId,
      objectId,
      id,
    }),
    {}
  );

  return data as ObjectCostEstimateData;
}

export function* createCommercialProposal(
  params: CreateCommercialProposalsData,
  companyId: CompanyId,
  objectId: CompanyObjectId,
  id: CostEstimateId
) {
  const { data } = yield call(
    postRequest,
    urlFromTemplate(
      ENDPOINTS.COMPANY_OBJECTS.COST_ESTIMATES.COMMERCIAL_PROPOSAL.CREATE,
      {
        companyId,
        objectId,
        id,
      }
    ),
    params
  );

  return data as CommercialProposal;
}

export function* updateCommercialProposal(
  params: UpdateCommercialProposalsData,
  companyId: CompanyId,
  objectId: CompanyObjectId,
  id: HistoricalCostEstimateId
) {
  yield call(
    patchRequest,
    urlFromTemplate(
      ENDPOINTS.COMPANY_OBJECTS.COST_ESTIMATES.COMMERCIAL_PROPOSAL.UPDATE,
      {
        companyId,
        objectId,
        id,
      }
    ),
    params
  );
}

export function* deleteCommercialProposal(
  companyId: CompanyId,
  objectId: CompanyObjectId,
  id: HistoricalCostEstimateId
) {
  yield call(
    deleteRequest,
    urlFromTemplate(
      ENDPOINTS.COMPANY_OBJECTS.COST_ESTIMATES.COMMERCIAL_PROPOSAL.DELETE,
      {
        companyId,
        objectId,
        id,
      }
    )
  );
}

export function* deleteCostEstimate(
  companyId: CompanyId,
  objectId: CompanyObjectId,
  id: CostEstimateId
) {
  yield call(
    deleteRequest,
    urlFromTemplate(ENDPOINTS.COMPANY_OBJECTS.COST_ESTIMATES.DELETE, {
      companyId,
      objectId,
      id,
    })
  );
}

export function* copyEstimate(
  companyId: CompanyId,
  objectId: CompanyObjectId,
  id: HistoricalCostEstimateId
) {
  const { data } = yield call(
    postRequest,
    urlFromTemplate(ENDPOINTS.COMPANY_OBJECTS.COST_ESTIMATES.COPY, {
      companyId,
      objectId,
      id,
    }),
    {}
  );

  return data as CostEstimate;
}
