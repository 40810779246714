import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconArrow1 } from '@static/img/icon-arrow1.svg';

export const ToAuthButton = () => {
  const { t } = useTranslation();

  return (
    <div className="login__but">
      <Link to="/auth" className="link min white">
        <span>
          <IconArrow1 />
          {t('auth.to-auth')}
        </span>
      </Link>
    </div>
  );
};
