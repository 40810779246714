import React, { FC, ReactElement } from 'react';
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '@components/EditCompany/Input';
// import { TextArea } from '@components/EditCompany/TextArea';
import { inputErrors, textAreaErrors } from '@components/EditCompany/utils';
import { TextArea } from '@components/EditCompany/Textarea';
import { SettingsFormData } from '../Company/type';

export type CompanyInfoProps = {
  register: UseFormRegister<SettingsFormData>;
  setValue: UseFormSetValue<SettingsFormData>;
  control: Control<SettingsFormData>;
  errors: FieldErrors<SettingsFormData>;
  onBlur: (name: string) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

const MAX_DESCRIPTION_COUNT = 200;

export const CompanyInfo: FC<CompanyInfoProps> = ({
  register,
  setValue,
  control,
  errors,
  onBlur,
  onKeyDown,
}): ReactElement => {
  const { t } = useTranslation();
  const description = useWatch({ name: 'description', control });

  const inputNames = [
    { title: t('profile.total-projects'), name: 'projectsAmount' as const },
    { title: t('profile.exp'), name: 'marketExperience' as const },
    { title: t('profile.total-employees'), name: 'employeesAmount' as const },
  ];

  return (
    <div className="form__cot">
      <div className="form__cap">
        <h5>{t('profile.company-info')}</h5>
      </div>
      <div className="form__group">
        <div className="form__column small">
          {inputNames.map((inputName) => (
            <Input
              {...register(inputName.name, {
                onBlur: () => onBlur(inputName.name),
              })}
              key={inputName.name}
              title={inputName.title}
              error={inputErrors(inputName.name, errors, t)}
              onKeyDown={onKeyDown}
              maxLength={300}
            />
          ))}
        </div>
        <div className="form__column average">
          <TextArea
            title={t('profile.company-desc')}
            count={MAX_DESCRIPTION_COUNT - (description?.length ?? 0)}
            {...register('description', {
              onBlur: () => onBlur('description'),
              onChange: (e) => {
                const textareaValue = e.target.value;
                if (textareaValue.length >= MAX_DESCRIPTION_COUNT) {
                  setValue(
                    'description',
                    textareaValue.slice(0, MAX_DESCRIPTION_COUNT)
                  );
                }
              },
            })}
            error={textAreaErrors(errors, 'description', t)}
          />
        </div>
      </div>
    </div>
  );
};
