/* eslint-disable import/no-cycle */
import { CostEstimateId } from '@store/costEstimates/slice';
import { createAction } from '@reduxjs/toolkit';
import { OrderCustomerItems, OrderId, orderCustomerSlice } from './slice';

export type FetchOrderCustomerRequestAction = {
  costEstimateId: CostEstimateId;
};

export type FetchOrderCustomerItemsRequestAction = {
  orderId: OrderId;
};

export type FetchOrderCustomerItemsSuccessAction = {
  data: OrderCustomerItems;
};

export type RequestAnInvoiceRequestAction = {
  orderId: OrderId;
  costEstimateId: string; // Получаем все заказы, чтобы сразу обновить на странице. Временный вариант.
};

export type RequestAnInvoiceSuccessAction = {
  orderId: string;
};

export type DeleteOrderCustomerRequestAction = {
  orderId: OrderId; // Получаем все позиции по заказу, чтобы сразу обновить на странице. Временный вариант.
  orderitemId: string;
};

export type DeleteOrderCustomerSuccessAction = {
  orderitemId: string;
};

export type UpdateOrderCustomerFinishedStatusRequestAction = {
  orderId: OrderId;
  costEstimateId: string; // Получаем все заказы, чтобы сразу обновить на странице. Временный вариант.
  status: 'FINISHED';
};

export type UpdateOrderCustomerFinishedStatusSuccessAction = {
  orderId: string;
};

export type DeleteOrderCustomerProxyDocumentRequestAction = {
  costEstimateId: CostEstimateId; // временно
  orderDocumentId: string;
};

export const deleteOrderCustomerProxyDocumentRequest =
  createAction<DeleteOrderCustomerProxyDocumentRequestAction>(
    'orderCustomer/deleteOrderCustomerProxyDocumentRequest'
  );

export const {
  setDeleteOrderCustomerProxyDocumentStatus,
  deleteOrderCustomerProxyDocumentSuccess,
  setErrorDeleteOrderCustomerItemStatus,
  resetDeleteOrderCustomerItemStatus,
  updateOrderCustomerFinishedStatusRequest,
  updateOrderCustomerFinishedStatusSuccess,
  requestAnInvoiceRequest,
  requestAnInvoiceSuccess,
  fetchOrderCustomerItemsSuccess,
  fetchOrderCustomerItemsRequest,
  fetchOrderCustomerRequest,
  fetchOrderCustomerSuccess,
  deleteOrderCustomerItemRequest,
  deleteOrderCustomerItemSuccess,
} = orderCustomerSlice.actions;
