import {
  all,
  call,
  put,
  takeLatest,
  takeEvery,
  debounce,
  select,
} from 'redux-saga/effects';
import { Saga } from '@sagas/types';
import { handleError } from '@sagas/errors';
import {
  FetchCostEstimateSectionsRequestAction,
  CreateCostEstimateSectionRequestAction,
  UpdateCostEstimateSectionRequestAction,
  DeleteCostEstimateSectionRequestAction,
  fetchCostEstimateSectionsRequest,
  fetchCostEstimateSectionsSuccess,
  createCostEstimateSectionRequest,
  createCostEstimateSectionSuccess,
  updateCostEstimateSectionRequest,
  updateCostEstimateSectionSuccess,
  deleteCostEstimateSectionRequest,
  deleteCostEstimateSectionSuccess,
  moveCostEstimateRequest,
  MoveCostEstimateSectionsAction,
  setErrorDeleteCostEstimateSectionStatus,
  FetchCostEstimateSectionRequestAction,
  fetchCostEstimateSectionSuccess,
  fetchCostEstimateSectionRequest,
} from '@store/costEstimateSections/actions';
import {
  getCostEstimateSections,
  createCostEstimateSection,
  updateCostEstimateSection,
  deleteCostEstimateSection,
  moveCostEstimateSections,
  getCostEstimateSection,
} from '@sagas/api/costEstimateSections';
import {
  createCostEstimateResourceSuccess,
  updateCostEstimateResourceSuccess,
  deleteCostEstimateResourceSuccess,
} from '@store/costEstimateResources/actions';
import {
  createCostEstimateOperationSuccess,
  updateCostEstimateOperationSuccess,
  deleteCostEstimateOperationSuccess,
} from '@store/costEstimateOperations/actions';
import { CompanyId } from '@store/company/slice';
import { CompanyObjectId } from '@store/companyObjects/slice';
import { CostEstimateId } from '@store/costEstimates/slice';
import { getCostEstimateSectionEvent } from '@store/costEstimateSections/selectors';
import { RootState } from '@store';
import { fetchCostEstimateRequest } from '@store/costEstimates/actions';

export type RefreshCostEstimateSectionsAction = {
  companyId: CompanyId;
  objectId: CompanyObjectId;
  costEstimateId: CostEstimateId;
};

const fetchMoveCostEstimateSectionsRequestHandler: Saga<MoveCostEstimateSectionsAction> =
  function* ({ payload }) {
    const {
      toMove,
      objectElementId,
      companyId,
      objectId,
      costEstimateId,
      orderingObjectElement,
    } = payload;

    const elementType = 'section';
    const subjectElementId = yield select(
      (state: RootState) =>
        getCostEstimateSectionEvent(
          state,
          orderingObjectElement + toMove
        ) as string
    );
    const countPoint = toMove === -1 ? 'before' : 'after';
    try {
      yield call(
        moveCostEstimateSections,
        companyId,
        objectId,
        costEstimateId,
        { countPoint, elementType, objectElementId, subjectElementId }
      );
    } catch (e) {
      yield call(handleError, e);
    }
  };

const fetchCostEstimateSectionRequestHandler: Saga<FetchCostEstimateSectionRequestAction> =
  function* ({ payload }) {
    const { sectionId } = payload;

    try {
      const costEstimateSection = yield call(getCostEstimateSection, sectionId);
      yield put(fetchCostEstimateSectionSuccess({ data: costEstimateSection }));
    } catch (e) {
      yield call(handleError, e);
    }
  };

const fetchCostEstimateSectionsRequestHandler: Saga<FetchCostEstimateSectionsRequestAction> =
  function* ({ payload }) {
    const { companyId, objectId, costEstimateId } = payload;

    try {
      const costEstimateSections = yield call(
        getCostEstimateSections,
        companyId,
        objectId,
        costEstimateId
      );
      yield put(
        fetchCostEstimateSectionsSuccess({ data: costEstimateSections })
      );
    } catch (e) {
      yield call(handleError, e);
    }
  };

const createCostEstimateSectionRequestHandler: Saga<CreateCostEstimateSectionRequestAction> =
  function* ({ payload }) {
    const { companyId, objectId, costEstimateId, temporaryId, data } = payload;

    try {
      const costEstimateSection = yield call(
        createCostEstimateSection,
        companyId,
        objectId,
        costEstimateId,
        data
      );
      yield put(
        createCostEstimateSectionSuccess({
          companyId,
          objectId,
          costEstimateId,
          temporaryId,
          data: costEstimateSection,
        })
      );
    } catch (e) {
      yield put(
        fetchCostEstimateSectionsRequest({
          companyId,
          objectId,
          costEstimateId,
        })
      );
      yield call(handleError, e);
    }
  };

const updateCostEstimateSectionRequestHandler: Saga<UpdateCostEstimateSectionRequestAction> =
  function* ({ payload }) {
    const { companyId, objectId, costEstimateId, id, data } = payload;

    try {
      const costEstimate = yield call(
        updateCostEstimateSection,
        companyId,
        objectId,
        costEstimateId,
        id,
        data
      );
      yield put(
        updateCostEstimateSectionSuccess({
          companyId,
          objectId,
          costEstimateId,
          id,
          data: costEstimate,
        })
      );
    } catch (e) {
      yield put(
        fetchCostEstimateSectionsRequest({
          companyId,
          objectId,
          costEstimateId,
        })
      );
      yield call(handleError, e);
    }
  };

const deleteCostEstimateSectionRequestHandler: Saga<DeleteCostEstimateSectionRequestAction> =
  function* ({ payload }) {
    const { companyId, objectId, costEstimateId, id } = payload;

    try {
      yield call(
        deleteCostEstimateSection,
        companyId,
        objectId,
        costEstimateId,
        id
      );
      yield put(
        deleteCostEstimateSectionSuccess({
          companyId,
          objectId,
          costEstimateId,
          id,
        })
      );
    } catch (e) {
      yield put(
        fetchCostEstimateSectionsRequest({
          companyId,
          objectId,
          costEstimateId,
        })
      );
      yield put(setErrorDeleteCostEstimateSectionStatus());
      yield call(handleError, e);
    }
  };

const refreshCostEstimateSectionsHandler: Saga<RefreshCostEstimateSectionsAction> =
  function* ({ payload }) {
    const { companyId, objectId, costEstimateId } = payload;

    yield put(
      fetchCostEstimateRequest({ companyId, objectId, id: costEstimateId })
    );
    yield put(
      fetchCostEstimateSectionsRequest({ companyId, objectId, costEstimateId })
    );
  };

export default function* root() {
  yield all([
    takeLatest(
      fetchCostEstimateSectionRequest,
      fetchCostEstimateSectionRequestHandler
    ),
    takeLatest(
      fetchCostEstimateSectionsRequest,
      fetchCostEstimateSectionsRequestHandler
    ),
    takeEvery(
      createCostEstimateSectionRequest,
      createCostEstimateSectionRequestHandler
    ),
    takeEvery(
      updateCostEstimateSectionRequest,
      updateCostEstimateSectionRequestHandler
    ),
    takeEvery(
      deleteCostEstimateSectionRequest,
      deleteCostEstimateSectionRequestHandler
    ),
    takeEvery(
      moveCostEstimateRequest,
      fetchMoveCostEstimateSectionsRequestHandler
    ),
    debounce(
      500,
      [
        createCostEstimateOperationSuccess.type,
        updateCostEstimateOperationSuccess.type,
        deleteCostEstimateOperationSuccess.type,
        createCostEstimateResourceSuccess.type,
        updateCostEstimateResourceSuccess.type,
        deleteCostEstimateResourceSuccess.type,
      ],
      refreshCostEstimateSectionsHandler
    ),
  ]);
}
