import { ReactComponent as IconWatch } from '@static/img/icon-watch.svg';
import { ReactComponent as IconChat } from '@static/img/icon-chat3.svg';
import { ReactComponent as IconDownload } from '@static/img/icon-download2.svg';
import { ReactComponent as IconDownload2 } from '@static/img/icon-download3.svg';
import { ReactComponent as IconCheck } from '@static/img/icon-check10.svg';
import { useTranslation } from 'react-i18next';
import IconButton from '@components/commons/IconButton';
import { useState } from 'react';
import Image from '@components/commons/Image';
import { TypeSize, setSizeImage } from '@utils/formatImage';
import Calendar from '@components/commons/Calendar';
import { useCurrentLocale } from '@hooks/useCurrentLocale';
import { currencyFormat } from '@utils/currency';
import { OrderDocuments, OrderStatus } from '@store/ordersCustomer/slice';
import { fetchOrderCustomerItemsRequest } from '@store/ordersCustomer/actions';
import { useAppDispatch } from '@hooks/redux';
import { useDownloadDocument } from '@hooks/useDownloadDocument';
import cn from 'classnames';
import {
  setIsViewChat,
  setIsViewChatDrawerFalse,
} from '@store/modalControl/actions';
import { fetchIsChekConversationOrderAction } from '@store/chat/actions';
import OrderModal from '../../OrderModal';
import OrdersTableItemsStatus from '../OrdersTableItemsStatus';
import styles from './styles.module.sass';

type Props = {
  orderId: string;
  orderNumber: number;
  invoiceValidity: number;
  deliveryTimeDate: number;
  supplierName: string;
  supplierLogo?: string;
  plan: number;
  fact: number;
  invoice: OrderDocuments;
  proxy: OrderDocuments;
  currencyCode: string;
  needInvoice: boolean;
  status: OrderStatus;
};
const OrdersTableIttems = ({
  orderId,
  orderNumber,
  invoiceValidity,
  deliveryTimeDate,
  supplierName,
  supplierLogo,
  plan,
  fact,
  status,
  invoice,
  proxy,
  needInvoice,
  currencyCode,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const locale = useCurrentLocale();

  const colorGreenFact = fact < plan;
  const colorRedFact = fact > plan;

  const [isViewChoise, setIsViewChoise] = useState(false);

  const statusPayment = status === 'ACCEPTED' && needInvoice;
  const statusPayment2 = status === 'PAYMENT' && !needInvoice;

  const currencyFormatInput = (value: number) => {
    return currencyCode !== undefined
      ? currencyFormat(value, currencyCode, locale)
      : `${value}`;
  };

  const handleOpenOrderModal = () => {
    dispatch(fetchOrderCustomerItemsRequest({ orderId }));
    setIsViewChoise(true);
  };

  const handleChoiseClose = () => {
    setIsViewChoise(false);
    dispatch(setIsViewChatDrawerFalse());
  };

  const handleClickDownloadInvoice = useDownloadDocument();

  const handleOpenChat = () => {
    dispatch(setIsViewChat());
    dispatch(fetchIsChekConversationOrderAction({ orderId }));
  };

  return (
    <>
      <div className="obj__order-item">
        <div className="obj__order-num">{orderNumber}</div>
        <div className="obj__order-progress">
          <p style={{ justifyContent: 'flex-start' }}>
            <IconWatch className={styles.objOrderProgressIconWatch} />
            {deliveryTimeDate && (
              <Calendar date={deliveryTimeDate} size="small" short />
            )}
          </p>
          <span>
            <i style={{ width: '46%' }} />
          </span>
        </div>
        <div className="obj__order-supplier">
          <p
            className={styles.orderTableItemSupplierBlock}
            title={supplierName}
          >
            {supplierLogo && (
              <i>
                <Image
                  url={setSizeImage(supplierLogo, TypeSize.firstSupplierLogo)}
                  className={styles.orderTableItemSupplierLogo}
                />
              </i>
            )}
            {supplierName}
          </p>
          <IconButton style={{ marginLeft: '10px' }} onClick={handleOpenChat}>
            <IconChat />
          </IconButton>
        </div>
        <div
          className={cn('obj__order-plan', styles.orderTableItemTextOverflow)}
          title={currencyFormatInput(plan)}
        >
          {currencyFormatInput(plan)}
        </div>
        <div
          className={cn(
            'obj__order-fact',
            styles.orderTableItemTextOverflow,
            colorGreenFact && 'color-success',
            colorRedFact && 'color-danger'
          )}
          title={currencyFormatInput(fact)}
        >
          {currencyFormatInput(fact)}
        </div>
        <div className="obj__order-doc">
          {status === 'PAID' && (
            <>
              <IconDownload className={styles.marginRight} />
              {t('orders.the-bill-is-paid')}
            </>
          )}
          {statusPayment && <>{t('orders.the-bill-is-expected')}</>}
          {invoice?.fileName && statusPayment2 && (
            <>
              <IconButton
                onClick={() =>
                  handleClickDownloadInvoice(
                    `/private-order-doc/${invoice?.fileName}`,
                    invoice?.originalFileName
                  )
                }
              >
                <IconDownload2 className={styles.marginRight} />
                {t('orders.download-the-invoice')}
              </IconButton>
            </>
          )}
        </div>
        <OrdersTableItemsStatus status={status} needInvoice={needInvoice} />
        <div className="obj__order-but">
          <IconButton
            className={styles.objOrderButTitle}
            onClick={handleOpenOrderModal}
          >
            {t('orders.open-an-order')}
            <IconCheck className={styles.marginLeft} />
          </IconButton>
        </div>
      </div>
      <OrderModal
        isViewChoise={isViewChoise}
        invoiceValidity={invoiceValidity}
        currencyCode={currencyCode}
        orderNumber={orderNumber}
        totalCost={fact}
        handleChoiseClose={handleChoiseClose}
        orderId={orderId}
        supplierName={supplierName}
        supplierLogo={supplierLogo}
        needInvoice={needInvoice}
        invoice={invoice}
        proxy={proxy}
        status={status}
      />
    </>
  );
};
export default OrdersTableIttems;
