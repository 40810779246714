import './select.sass';
import { nanoid } from '@reduxjs/toolkit';
import cn from 'classnames';
import { Category } from '@store/vocabulary/slice';
import { useAppSelector } from '@hooks/redux';
import { getCategories } from '@store/vocabulary/selectors';
import { useState } from 'react';

type CategorySelectProps = {
  categories: Category[];
  visible?: boolean;
  depth?: number;
  multiselect?: boolean;
  selected?: string[];
  onSelect: (id: string, name: string, multiselect: boolean) => void;
  visibleGroup: string[];
  controlLevel: (id: string) => void;
  isModeSearch: boolean;
};

function Select({
  categories,
  visible = false,
  depth = 0,
  multiselect = false,
  selected = [],
  onSelect,
  visibleGroup,
  controlLevel,
  isModeSearch,
}: CategorySelectProps) {
  const [selectedItems, setSelectedItems] = useState<string[]>(selected);

  const handleClickCategory = (
    id: string,
    name: string,
    item: 'group' | 'item'
  ) => {
    if (isModeSearch && item === 'group') return;
    if (item === 'group') return controlLevel(id);

    const array: string[] = [];

    onSelect(id, name, multiselect);

    if (array.includes(id)) {
      setSelectedItems(array.filter((i) => i !== id));
      return;
    }
    array.push(id);
    setSelectedItems(array);
  };

  return (
    <div className={cn('selectCategory', { visible, mr: depth })}>
      {categories.map((e) => {
        const isOpen = visibleGroup.includes(e.id);
        const selectedItem = selectedItems.includes(e.id);
        const isChildren = e.children && e.children.length > 0;
        return (
          <>
            <div
              onClick={() =>
                handleClickCategory(e.id, e.name, isChildren ? 'group' : 'item')
              }
              className={cn('selectCategory__item', {
                open: (isModeSearch && isChildren) || isOpen,
              })}
              key={e.id}
            >
              <div className="selectCategory__name">{e.name}</div>
              <div
                style={{ width: '20px' }}
                className={cn('selectCategory__option', {
                  open: isChildren,
                  check: !e.children || e.children.length === 0,
                  selected: selectedItem,
                  close: isModeSearch || isOpen,
                })}
              />
            </div>
            {isChildren && (
              <Select
                visibleGroup={visibleGroup}
                categories={e.children}
                visible={isModeSearch || visibleGroup.includes(e.id)}
                depth={depth + 1}
                key={nanoid()}
                selected={selected}
                onSelect={onSelect}
                multiselect={multiselect}
                controlLevel={controlLevel}
                isModeSearch={isModeSearch}
              />
            )}
          </>
        );
      })}
    </div>
  );
}

type SelectProps = {
  selected: string[];
  onSelect: (ids: { id: string; name: string }[] | string[]) => void;
  multiselect?: boolean;
};

export default function CategorySelect({
  onSelect,
  selected,
  multiselect = false,
}: SelectProps) {
  const basicCategory = useAppSelector(getCategories);

  const [categories, setCategories] = useState(basicCategory);
  const [currentCategory, setCurrentCategory] = useState<string[]>([]);
  const [isModeSearch, setIsModeSeatch] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<string[]>(
    selected || []
  );

  const controlLevel = (id: string) => {
    const array = [...currentCategory];
    if (array.includes(id)) {
      setCurrentCategory(array.filter((i) => i !== id));
      return;
    }

    array.push(id);
    setCurrentCategory(array);
  };

  const select = (id: string, name: string, isManyChoise: boolean) => {
    const array = [...selectedCategories];

    if (!isManyChoise) {
      setSelectedCategories([id]);
      // На выход помимо id будем отдавать name, чтобы не производить лишних операций поиска. Пока это работает только для одиночного выбора.
      // Мультивыбор все так-же отправляет id. В дальнейшем при необходимости стоит доработать мультивыбор, чтобы в onSelect тоже отдавались имена.
      onSelect([{ id, name }]);
      return;
    }

    if (array.includes(id)) {
      setSelectedCategories(array.filter((i) => i !== id));
      onSelect([{ id, name }]);
      const updatedArray = array.filter((i) => i !== id);
      setSelectedCategories(updatedArray);
      return onSelect(updatedArray);
    }

    array.push(id);
    onSelect(array);
    setSelectedCategories(array);
  };
  const fastSearchCategiry = (
    cat: Category[],
    seachValue: string
  ): Category[] => {
    const arr: Category[] = [];
    cat.forEach((item) => {
      if (!item.children || item.children?.length === 0) {
        if (
          item.name.toLocaleLowerCase().includes(seachValue.toLocaleLowerCase())
        ) {
          arr.push(item);
        }
      }
      if (item.children && item.children.length > 0) {
        const children = fastSearchCategiry(item.children, seachValue);
        if (children.length > 0)
          arr.push({
            ...item,
            children: fastSearchCategiry(item.children, seachValue),
          });
      }
    });

    return arr;
  };

  const handleSearch = (e: string) => {
    if (e.length === 0) {
      setCategories(basicCategory);
      setIsModeSeatch(false);
      return;
    }
    setIsModeSeatch(true);
    setCategories(fastSearchCategiry(basicCategory, e));
  };

  return (
    <>
      <div
        className={cn('input', 'border', {
          error: categories.length === 0,
        })}
      >
        <input
          placeholder="0"
          type="text"
          onChange={(e) => handleSearch(e.target.value)}
        />
        <label>Поиск по категориям</label>
        {categories.length === 0 && <small>Не найдено</small>}
      </div>
      {categories.length > 0 && (
        <Select
          categories={categories}
          onSelect={select}
          visibleGroup={currentCategory}
          controlLevel={controlLevel}
          multiselect={multiselect}
          visible
          selected={selectedCategories}
          isModeSearch={isModeSearch}
        />
      )}
    </>
  );
}
