import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getHomeUrl } from '@pages';
import { SignUpBackground } from '../Background';
import { SignUpHeader } from '../Header';
import { SignUpLogo } from '../Logo';
import { EnterButton } from './EnterButton';
import styles from './styles.module.sass';

export const ProfileCreated = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate(getHomeUrl());
    }, 10000);

    return () => {
      clearTimeout(timeout);
    };
  }, [navigate]);

  return (
    <section className="login">
      <SignUpBackground />
      <div className="login__block">
        <SignUpLogo />
        <SignUpHeader
          title={t('auth.profile-created')}
          subtitle={t('auth.sign-up-success')}
        />
        <div className={styles.button}>
          <EnterButton title={t('auth.continue')} path="/" />
        </div>
      </div>
    </section>
  );
};
