import { call } from 'redux-saga/effects';
import { ENDPOINTS } from '@config';
import { getRequest, putRequest } from '@sagas/api';
import { ProfileData } from '@store/profile/slice';
import { LanguageId } from '@store/vocabulary/slice';
import { ChangePasswordRequestData } from '@store/profile/actions';
import { urlFromTemplate } from '@utils/url';

export interface SaveProfileRequest {
  phone: string;
  firstName: string;
  lastName: string;
  languageId: LanguageId;
}

export function* getProfile() {
  const { data } = yield call(getRequest, ENDPOINTS.PROFILE.GET);

  return data as ProfileData;
}

export function* saveProfile(params: SaveProfileRequest) {
  yield call(putRequest, ENDPOINTS.PROFILE.UPDATE, params);
}

export function* updatePasswordProfile(params: ChangePasswordRequestData) {
  yield call(
    putRequest,
    urlFromTemplate(ENDPOINTS.PROFILE.UPDATEPASSWORD, {}),
    params
  );
}
