import {
  RestoreRequestAction,
  SavePasswordRequestAction,
} from '@store/user/actions';
import { createAction } from '@reduxjs/toolkit';
import { accountSlice } from './slice';

export const restoreRequest = createAction<RestoreRequestAction>(
  'user/restoreRequest'
);

export const savePasswordRequest = createAction<SavePasswordRequestAction>(
  'user/restPasswordRequest'
);

export const { setStatusResotrPassword, setStatusSaveNewPassword } =
  accountSlice.actions;
