import React, { FC, memo, useContext, useEffect } from 'react';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { RESOURCE_TYPES } from '@constants/resource';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useCompanyId } from '@hooks/useCompanyId';
import { useTableRowForm } from '@hooks/useTableRowForm';
import { OperationId, OperationResource } from '@store/operations/slice';
import {
  deleteOperationResourcesRequest,
  updateOperationResourcesRequest,
} from '@store/operations/actions';
import { ResourceType } from '@store/resources/slice';
import { getResource } from '@store/resources/selectors';
import { getDefaultUnit, getUnit } from '@store/vocabulary/selectors';
import { OperationCatalogContext } from '@components/OperationsCatalog';
import removeIcon from '@static/img/icon-remove-blue.svg';
import './styles.sass';
import { useTranslation } from 'react-i18next';
import { currencyFormat } from '@utils/currency';
import { useCurrentLocale } from '@hooks/useCurrentLocale';
import { percentFormat } from '@utils/percent';

type OperationsCatalogOperationResourceRawProps = {
  operationId: OperationId;
  operationResource: OperationResource;
  create?: boolean;
};

type FormData = {
  id: OperationId;
  amount: number;
};

const OperationsCatalogOperationResourceRaw: FC<
  OperationsCatalogOperationResourceRawProps
> = ({ operationId, operationResource }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const locale = useCurrentLocale();
  const { onSelectResources, isSelectedResource } = useContext(
    OperationCatalogContext
  );
  const resource = useAppSelector((state) =>
    getResource(state, operationResource.id)
  );
  const unit = useAppSelector((state) =>
    resource ? getUnit(state, resource.unitId) : getDefaultUnit(state)
  );
  const companyId = useCompanyId();
  const selected = isSelectedResource(operationId, operationResource.id);

  const handleCheckboxClick = () => {
    onSelectResources(operationId, [operationResource.id]);
  };

  const handleDeleteClick = () => {
    if (companyId === undefined) {
      return;
    }

    if (selected) {
      onSelectResources(operationId, [operationResource.id]);
    }

    dispatch(
      deleteOperationResourcesRequest({
        companyId,
        id: operationId,
        data: {
          ids: [operationResource.id],
        },
      })
    );
  };

  const { register, handleSubmit, setValue, reset } = useForm<FormData>({
    defaultValues: operationResource,
  });

  useEffect(() => {
    reset(operationResource);
  }, [operationResource, reset]);

  const {
    rowRef,
    editable,
    handleInputFocus,
    handleInputBlur,
    handleInputKeyUp,
    handleFormSubmit,
  } = useTableRowForm({
    defaultEditable: false,
    onInputCancel: () => {
      reset(operationResource);
    },
    onInputBlur: () => {
      handleFormSubmit();
    },
    onFormSubmit: handleSubmit((formData) => {
      const { id, ...data } = formData;

      if (companyId === undefined) {
        return;
      }

      dispatch(
        updateOperationResourcesRequest({
          companyId,
          id: operationId,
          data: {
            resources: [{ id, amount: data.amount }],
          },
        })
      );
    }),
  });

  if (resource === undefined) {
    return null;
  }

  return (
    <div
      ref={rowRef}
      className={cn('manager__operation-resource', { edit: editable })}
    >
      {/* <div className="manager__operation-edit"></div> */}
      <span
        className="manager__operation-check check"
        onClick={handleCheckboxClick}
      >
        <input
          type="checkbox"
          name="resources"
          value="1"
          checked={selected}
          readOnly
        />
        <label />
      </span>
      <div className="manager__operation-group">
        <div className="sel">
          <a>
            <s
              className={
                RESOURCE_TYPES[resource?.type ?? ResourceType.HUM].className
              }
            >
              {t(RESOURCE_TYPES[resource?.type ?? ResourceType.HUM].title)}
            </s>
          </a>
        </div>
      </div>
      <div className="manager__operation-name" title={resource.name}>
        <input
          readOnly
          type="text"
          name="name"
          value={resource.name}
          placeholder="Название ресурса"
        />
      </div>
      <div
        className="manager__operation-count"
        data-title={t('directory.quantity')}
      >
        <input
          type="text"
          onFocus={handleInputFocus}
          onKeyUp={handleInputKeyUp}
          {...register('amount', {
            onChange: (e) => {
              setValue('amount', e.target.value.replace(/[^\d.]/g, ''));
            },
            setValueAs: (v) => parseFloat(v),
            onBlur: handleInputBlur,
          })}
        />
      </div>
      <div className="manager__operation-unit" data-title={t('directory.unit')}>
        <div className="sel">
          <a>{t(`units.${unit?.name}`) ?? ''}</a>
        </div>
      </div>
      <div
        className="manager__operation-price"
        data-title={t('directory.price')}
      >
        <input
          readOnly
          type="text"
          name="price"
          value={currencyFormat(resource.price, resource.currency.code, locale)}
        />
      </div>
      <div
        className="manager__operation-markup"
        data-title={t('directory.markup')}
      >
        <input
          readOnly
          type="text"
          name="markup"
          value={percentFormat(resource.markup, locale)}
        />
      </div>
      <div
        className="manager__operation-client"
        data-title={t('directory.for-customer')}
      >
        <input
          readOnly
          type="text"
          name="client"
          value={currencyFormat(
            resource.salePrice,
            resource.currency.code,
            locale
          )}
        />
      </div>
      <div className="manager__operation-remove" onClick={handleDeleteClick}>
        <img src={removeIcon} alt="Удалить ресурс" />
      </div>
    </div>
  );
};

export const OperationsCatalogOperationResource = memo(
  OperationsCatalogOperationResourceRaw
);
