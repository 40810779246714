import React from 'react';
import {
  Path,
  PathValue,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { currencyFormat, currencyParse } from '@utils/currency';

type OfferGeneratorCurrencyInputProps<FormData extends FieldValues> = {
  name: Path<FormData>;
  register: UseFormRegister<FormData>;
  setValue: UseFormSetValue<FormData>;
  title: string;
  currency: string;
  locale: string;
};

export const OfferGeneratorCurrencyInput = <FormData extends FieldValues>({
  name,
  register,
  setValue,
  title,
  currency,
  locale,
}: OfferGeneratorCurrencyInputProps<FormData>): JSX.Element => {
  return (
    <div className="input border medium">
      <input
        type="text"
        placeholder="0"
        onFocus={(e) => {
          setValue(
            name,
            `${currencyParse(e.target.value, locale)}` as PathValue<
              FormData,
              Path<FormData>
            >
          );
        }}
        {...register(name, {
          required: true,
          onChange: (e) => {
            setValue(name, e.target.value.replace(/[^\d.]/g, ''));
          },
          onBlur: (e) => {
            setValue(
              name,
              currencyFormat(
                parseFloat(e.target.value),
                currency,
                locale
              ) as PathValue<FormData, Path<FormData>>
            );
          },
        })}
      />
      <label>{title}</label>
    </div>
  );
};
