import Image from '@components/commons/Image';
import noImageOject from '@static/img/imgObjectPhoto.png';
import { TypeSize, setSizeImage } from '@utils/formatImage';

type Props = {
  filePath?: string;
};

export default function ObjectImage({ filePath }: Props) {
  return (
    <div className="upload-object">
      <input type="hidden" name="image" value="" />
      <div>
        <i>
          {filePath ? (
            <Image url={setSizeImage(filePath, TypeSize.object)} />
          ) : (
            <img src={noImageOject} />
          )}
        </i>
        {/* 
        TODO: development uploads images 
        <ul>
          <li className="js-upload-object-replace">
            <img src="../img/icon-replace.svg" />
            Заменить
          </li>
          <li className="js-upload-object-remove">
            Удалить
            <img src="../img/icon-remove3.svg" />
          </li>
        </ul> */}
      </div>
    </div>
  );
}
