import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useTranslation } from 'react-i18next';
import { useCompanyId } from '@hooks/useCompanyId';
import { getCompanyCurrencyCode } from '@store/company/selectors';
import {
  getCompanyObjectById,
  getCompanyObjectId,
} from '@store/companyObjects/selectors';
import {
  getCostEstimateByObjectId,
  getCostEstimates,
  getActiveCreateNewCostEstimate,
  getObjectCostEstimates,
  getCurrentCostEstimateId,
  isObjectCostEstimatesFetched,
  getDeleteCostEstimatesStatus,
} from '@store/costEstimates/selectors';
import {
  clearCostEstimates,
  deleteCostEstimateRequest,
  fetchCostEstimateRequest,
  fetchObjectCostEstimatesRequest,
  resetDeleteCostEstimateStatus,
  setActiveCreateNewCostEstimate,
} from '@store/costEstimates/actions';
import { getCurrencies } from '@store/vocabulary/selectors';
import { Estimate } from '@components/Estimate';
import { DirectoryModal } from '@components/DirectoryModal';
import Loader from '@components/commons/Loader';
import { useSearchParams } from 'react-router-dom';
import { ConfirmationModal } from '@components/commons/ConfirmationModal';
import { CostEstimatesButtonsList } from './CostEstimatesButtonsList';
import { CostEstimatesTotal } from './CostEstimatesTotal';
import { NewCostEstimate } from './NewCostEstimate';
import { CostEstimatesBar } from '../CostEstimatesBar';

export const AboutObjectCostEstimates = () => {
  const currentCostEstimateId = useAppSelector(getCurrentCostEstimateId);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const companyId = useCompanyId();
  const deleteCostEstimateStatus = useAppSelector(getDeleteCostEstimatesStatus);
  const objectId = useAppSelector(getCompanyObjectId);
  const currentObject = useAppSelector((state) =>
    getCompanyObjectById(state, objectId)
  );
  const costEstimates = useAppSelector(getCostEstimates);
  const costEstimate = useAppSelector((state) =>
    getCostEstimateByObjectId(state, objectId)
  );
  const companyCurrencyName = useAppSelector(getCompanyCurrencyCode);
  const currencies = useAppSelector(getCurrencies);
  const isObjectCostEstimatesLoaded = useAppSelector(
    isObjectCostEstimatesFetched
  );

  const [searchParams] = useSearchParams();
  const costEstimateIdFromQuery = searchParams.get('costEstimateId');

  const [directoryVisible, setDirectoryVisible] = useState(false);
  const [isVisibleDeleteEstimateModal, setVisibleDeleteEstimateModal] =
    useState(false);

  useEffect(() => {
    if (companyId !== undefined) {
      dispatch(fetchObjectCostEstimatesRequest({ companyId, id: objectId }));
    }
    return () => {
      dispatch(clearCostEstimates());
    };
  }, [companyId, objectId, dispatch]);

  // This is original data from fetch on AboutObjectPage. (Including, needed for fetch cost estimates)
  const objectCostEstimates = useAppSelector(getObjectCostEstimates);
  // This is need to create new cost estimate
  const activeCreateNewCostEstimate = useAppSelector(
    getActiveCreateNewCostEstimate
  );

  useEffect(() => {
    if (companyId !== undefined && costEstimateIdFromQuery !== null) {
      dispatch(
        fetchCostEstimateRequest({
          companyId,
          objectId,
          id: costEstimateIdFromQuery,
        })
      );
    }
  }, [companyId, objectId, costEstimateIdFromQuery, dispatch]);

  // This set inactive for create new cost when leaving page
  useEffect(() => {
    return () => {
      dispatch(setActiveCreateNewCostEstimate(false));
    };
  }, [dispatch]);

  const handleSettingsClick = () => {
    setDirectoryVisible(true);
  };

  const handleCatalogCancel = () => {
    setDirectoryVisible(false);
  };

  if (
    !currentObject ||
    !currencies ||
    !companyCurrencyName ||
    !costEstimates ||
    !companyId
  ) {
    return null;
  }

  const handleClickCancelCostEstimateButton = () => {
    setVisibleDeleteEstimateModal(false);
  };
  const handleClickOpenCostEstimateButtonModal = () => {
    dispatch(resetDeleteCostEstimateStatus());
    setVisibleDeleteEstimateModal(true);
  };

  const handleDelete = () => {
    if (companyId === undefined || currentCostEstimateId === undefined) {
      return;
    }

    dispatch(
      deleteCostEstimateRequest({
        companyId,
        objectId,
        id: currentCostEstimateId,
      })
    );
  };

  return (
    <>
      <ConfirmationModal
        confirmTitle={t('confirm.copy-estimate-confirm')}
        isVisibleModal={isVisibleDeleteEstimateModal}
        status={deleteCostEstimateStatus}
        confirmButtonClassName="bg-danger"
        onCancel={handleClickCancelCostEstimateButton}
        onConfirm={handleDelete}
      />
      <div className="content__core">
        {isObjectCostEstimatesLoaded ? (
          <div className="obj">
            <div className="obj__cap">
              <h4>{t('about-object.object-estimates')}</h4>
              <CostEstimatesBar />
              <CostEstimatesButtonsList
                active={costEstimate !== undefined}
                onSettingsClick={handleSettingsClick}
                onDeleteClick={handleClickOpenCostEstimateButtonModal}
              />
            </div>
            {activeCreateNewCostEstimate ||
            objectCostEstimates.length === 0 ||
            costEstimate === undefined ? (
              <NewCostEstimate objectId={objectId} companyId={companyId} />
            ) : (
              <Estimate />
            )}
            <CostEstimatesTotal />
          </div>
        ) : (
          <Loader />
        )}
        <DirectoryModal
          visible={directoryVisible}
          onCancel={handleCatalogCancel}
        />
      </div>
    </>
  );
};
