import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchStatus } from '@store/constants';
import { StatusRequest } from '@utils/enums';

export type RequestId = string;

export type CompanyCustomerShortData = {
  id: string;
  name: string;
};

export type BuildingObjectShortData = {
  id: string;
  name: string;
};

export type RequestBasicData = {
  id: RequestId;
  status: keyof typeof StatusRequest;
  number: number;
  validity: number;
  allResponsesCost: number;
  buildingObject: BuildingObjectShortData;
  company: CompanyCustomerShortData;
  createdAt: number;
  updatedAt: number;
  cost: number;
  needClarification: boolean;
  loadStatus: FetchStatus;
};

export type Response = {
  resourceId: string;
  name: string;
  type: string;
  amount: number;
  price: string;
  cost: number;
  unitId: string;
  deliveryTime: number | null;
  categoryId: string;
};

export type Request = {
  id: string;
  name: string;
  amount: number;
  type: string;
  price: string;
  cost: number;
  unitId: string;
  deliveryTime: number;
  number: number;
  status: string;
  response: Response;
  category: {
    id: string;
    name: string;
  };
};
export type TypeBid = {
  processedResources: Request[];
  newResources: Request[];
  unansweredResources: Request[];
  needClarification: Request[];
  selectedResponses: Request[];
};

type Status = {
  NEW: FetchStatus;
  PROCESSED: FetchStatus;
  SELECTED: FetchStatus;
};

type Requeststate = {
  requestsBasicData: RequestBasicData[];
  status: Status;
  bid: TypeBid;
  activeRequestId: string;
};

const initialState: Requeststate = {
  requestsBasicData: [],
  status: {
    NEW: FetchStatus.NotFetched,
    PROCESSED: FetchStatus.NotFetched,
    SELECTED: FetchStatus.NotFetched,
  },
  bid: {
    newResources: [],
    unansweredResources: [],
    processedResources: [],
    needClarification: [],
    selectedResponses: [],
  },
  activeRequestId: '',
};

export const requestSuplierSlice = createSlice({
  name: 'requestSupplier',
  initialState,
  reducers: {
    createResponseItem: (
      state,
      action: PayloadAction<{
        part: keyof TypeBid;
        item: Response;
        requestId: string;
      }>
    ) => {
      const index = state.bid[action.payload.part].findIndex(
        (i) => i.id === action.payload.requestId
      );
      state.bid[action.payload.part][index].response = action.payload.item;
    },
    updateResponseItem: (
      state,
      action: PayloadAction<{
        part: keyof TypeBid;
        item: Response;
        requestId: string;
      }>
    ) => {
      const index = state.bid[action.payload.part].findIndex(
        (i) => i.id === action.payload.requestId
      );

      const cost =
        action.payload.item.amount * Number(action.payload.item.price);

      state.bid[action.payload.part][index].response = {
        ...state.bid[action.payload.part][index].response,
        ...action.payload.item,
        cost,
      };
    },
    setStatusFetchingData: (
      state,
      action: PayloadAction<{ status: keyof typeof StatusRequest }>
    ) => {
      state.status[action.payload.status] = FetchStatus.Fetching;
    },
    fetchRequestRequestSuccess: (
      state,
      action: PayloadAction<{
        requests: RequestBasicData[];
        status: keyof typeof StatusRequest;
      }>
    ) => {
      const { requests, status } = action.payload;
      state.requestsBasicData = state.requestsBasicData
        .filter((i) => i.status !== status)
        .concat(requests);

      state.status[status] = FetchStatus.Fetched;
    },
    fetchRequestListRequestSuccess: (state, action: PayloadAction<TypeBid>) => {
      state.bid = action.payload;
    },
    setActiveRequestId: (state, action: PayloadAction<string>) => {
      state.activeRequestId = action.payload;
    },
    setFetchingStatusRequest: (
      state,
      action: PayloadAction<Pick<Request, 'id'> & { status: FetchStatus }>
    ) => {
      const index = state.requestsBasicData.findIndex(
        (i) => i.id === action.payload.id
      );

      state.requestsBasicData[index] = {
        ...state.requestsBasicData[index],
        loadStatus: action.payload.status,
      };
    },
    fetchResponseRequestSuccess: (
      state,
      action: PayloadAction<Pick<Request, 'id'> & { validity: number }>
    ) => {
      const index = state.requestsBasicData.findIndex(
        (i) => i.id === action.payload.id
      );
      state.requestsBasicData[index] = {
        ...state.requestsBasicData[index],
        validity: action.payload.validity,
        loadStatus: FetchStatus.Fetched,
        status: 'PROCESSED',
      };
    },
  },
});

export default requestSuplierSlice.reducer;
