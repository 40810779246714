import React, { FC, useState } from 'react';
import cn from 'classnames';
import { ReactComponent as IconOpen } from '@static/img/icon-open.svg';
import { UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.sass';

type PasswordInputProps = {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>; // TODO: set type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: any;
};

export const PasswordInput: FC<PasswordInputProps> = ({
  name,
  register,
  errors,
}) => {
  const { t } = useTranslation();

  const [isShowPassword, setIsShowPassword] = useState(false);

  const onClickShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const passwordErrors = () => {
    switch (errors[name]?.type) {
      case 'required':
        return <small>{t('auth.validation-empty')}</small>;
      case 'minLength':
        return <small>{t('auth.validation-password-short')}</small>;
      default:
        return null;
    }
  };

  return (
    <div
      className={cn('input', 'password', {
        open: isShowPassword,
        error: errors[name],
      })}
    >
      <input
        type={isShowPassword ? 'text' : 'password'}
        placeholder="0"
        {...register(name, {
          required: true,
          minLength: 6,
        })}
      />
      <label>{t('auth.password')}</label>
      {passwordErrors()}
      <i onClick={onClickShowPassword} className={styles.image}>
        <IconOpen />
      </i>
    </div>
  );
};
