import {
  CommercialProposal,
  CostEstimateId,
  ObjectCostEstimate,
} from '@store/costEstimates/slice';

type CostEstimateTitle = string;
type CostEstimateTitleCount = number;

type CommercialProposalTitle = string;
type CommercialProposalTitleCount = number;

export interface CommercialProposalWithTitles extends CommercialProposal {
  title: CommercialProposalTitle;
  titleCount: CommercialProposalTitleCount;
  titleCostEstimate: CostEstimateTitle;
  titleCostEstimateCount: CostEstimateTitleCount;
}

export interface CostEstimateObject extends ObjectCostEstimate {
  title: CostEstimateTitle;
  titleCount: CostEstimateTitleCount;
}

export class CostEstimateObjectCollection {
  private objects: { [key in CostEstimateId]: CostEstimateObject[] };

  private commercialProposals: {
    [key in CostEstimateId]: CommercialProposalWithTitles[];
  };

  // private costDetails;

  // private totalCostDetails;

  private allCommercialProposals: CommercialProposalWithTitles[];

  constructor(objects: ObjectCostEstimate[]) {
    this.objects = {};
    this.commercialProposals = {};
    // this.costDetails = {};
    // this.totalCostDetails = {};
    this.allCommercialProposals = [];

    objects.forEach((obj, index) => {
      if (!(obj.id in this.objects)) {
        this.objects[obj.id] = [];
        this.commercialProposals[obj.id] = [];
        // this.costDetails[obj.id] = [];
        // this.totalCostDetails[obj.id] = [];
      }

      this.objects[obj.id].push({
        ...obj,
        title: 'about-object.estimate-of-object',
        titleCount: index + 1,
      });

      obj.commercialProposals.forEach((commercialProposal, i) => {
        this.commercialProposals[obj.id].push({
          ...commercialProposal,
          title: 'about-object.iteration',
          titleCount: obj.commercialProposals.length - i,
          titleCostEstimate: 'about-object.estimate-of-object',
          titleCostEstimateCount: index + 1,
        });
      });

      // for (let key in obj.costDetails) {
      //   this.costDetails[obj.id].push(obj.costDetails[key]);
      //   this.totalCostDetails[obj.id].push(
      //     obj.costDetails[key].saleCost + obj.costDetails[key].salePrice
      //   );
      // }

      this.allCommercialProposals.push(...this.commercialProposals[obj.id]);
    });
  }

  getObjectsByKey(costEstimateId: CostEstimateId) {
    return costEstimateId in this.objects ? this.objects[costEstimateId] : [];
  }

  getCommercialProposalsByKey(costEstimateId: CostEstimateId) {
    return costEstimateId in this.commercialProposals
      ? this.commercialProposals[costEstimateId]
      : [];
  }

  getAllCommercialProposals() {
    return this.allCommercialProposals;
  }

  // getCostDetailsByKey(costEstimateId) {
  //   return costEstimateId in this.costDetails
  //     ? this.costDetails[costEstimateId]
  //     : [];
  // }

  // getTotalCostDetailsByKey(costEstimateId) {
  //   return costEstimateId in this.totalCostDetails
  //     ? this.totalCostDetails[costEstimateId]
  //     : [];
  // }
}
