import { FieldErrors } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { resetErrorFields } from '@store/errors/actions';
import { getErrorFields } from '@store/errors/selectors';
import { useEffect } from 'react';

export const useFormErrors = <FormData extends FieldValues>(
  formErrors: FieldErrors<FormData>
) => {
  const dispatch = useAppDispatch();
  const errorFields = useAppSelector(getErrorFields);
  const serverErrors = Object.keys(errorFields).reduce((acc, name) => {
    acc[name] = {
      type: 'server',
      message: errorFields[name],
    };
    return acc;
  }, {} as Record<string, object>);

  useEffect(() => {
    return () => {
      dispatch(resetErrorFields());
    };
  }, [dispatch]);

  return {
    ...serverErrors,
    ...formErrors,
  };
};
