import { ReactComponent as IconCheck } from '@static/img/icon-check2.svg';
import { ReactComponent as IconProfile } from '@static/img/icon-profile1.svg';

type Props = {
  id: string;
  name: string;
  organizationName?: string;
  contactName?: string;
  cost: number;
  handleTransitionObject: (id: string) => void;
};
const ClientCompanyObject = ({
  id,
  name,
  organizationName,
  cost,
  contactName,
  handleTransitionObject,
}: Props) => {
  return (
    <div className="client__object" onClick={() => handleTransitionObject(id)}>
      <div className="object__item">
        <div className="object__item-cap">
          <i
            title="Процесс подбора материалов"
            data-tooltip="bottom"
            className="tooltip"
          >
            <IconCheck />
          </i>
          <h4>{name}</h4>
        </div>
        <div className="object__item-info">
          <p>{organizationName}</p>
          <b>{cost}</b>
        </div>
        <div className="object__item-profile">
          <i>
            <IconProfile />
          </i>
          <p>{contactName}</p>
        </div>
      </div>
    </div>
  );
};

export default ClientCompanyObject;
