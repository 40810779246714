import { useFormErrors } from '@hooks/useFormErrors';
import { Input } from '@pages/Dashboard/ObjectModal/CreateObjectForm/Input';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EMAIL_PATTERN } from '@constants/patterns';
import { TextArea } from '@pages/Dashboard/ObjectModal/CreateObjectForm/TextArea';
import {
  deleteContactRequest,
  resetUpdateContactStatus,
  updateContactRequest,
} from '@store/contacts/actions';
import { useDispatch } from 'react-redux';
import { Select } from '@components/EditCompany/Select';
import { useAppSelector } from '@hooks/redux';
import { getCompany } from '@store/company/selectors';
import {
  getChoiseFullContact,
  getUpdateContactStatus,
} from '@store/contacts/selectors';
import { ReactComponent as IconWhatsApp } from '@static/img/icon-whatsapp.svg';
import { FetchStatus } from '@store/constants';
import {
  ChangeEvent,
  FormEventHandler,
  MouseEventHandler,
  useEffect,
} from 'react';
import { phoneFormat } from '@utils/phoneFormat';
import Checkbox from '@components/commons/Checkbox';
import cn from 'classnames';
import Button from '@components/commons/Button';
import { getFullOrganizations } from '@store/organizations/selectors';
import { ContactId } from '@store/contacts/slice';
// import { SecondaryButton } from '@components/commons/SecondaryButton';
import { fetchFullOrganizationsObjectRequest } from '@store/organizations/actions';
import { SecondaryButton } from '@components/commons/SecondaryButton';
import {
  emailErrors,
  inputErrors,
} from '../../../pages/Dashboard/ObjectModal/utils';

type ContactFormData = {
  id: ContactId;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  comment: string;
  whatsappAvailable: boolean;
  // responsible?: string;
  organizationId: string;
};

type EditContactFormProps = {
  onClose: () => void;
};

const EditContactForm = ({ onClose }: EditContactFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const statusContact = useAppSelector(getUpdateContactStatus);
  const allOrganizations = useAppSelector(getFullOrganizations);
  const choisedContact = useAppSelector(getChoiseFullContact);
  const companyId = useAppSelector(getCompany)?.id;
  const contactId = choisedContact?.id;

  const {
    register,
    setValue,
    watch,
    getValues,
    trigger,
    formState: { errors: formErrors },
  } = useForm<ContactFormData>({
    mode: 'onBlur',
    defaultValues: {
      firstName: choisedContact?.firstName,
      lastName: choisedContact?.lastName,
      email: choisedContact?.email,
      phone: choisedContact?.phone,
      comment: choisedContact?.comment,
      whatsappAvailable: choisedContact?.whatsappAvailable,
      organizationId: choisedContact?.organizations[0]?.id || '',
    },
  });
  const errors = useFormErrors(formErrors);

  const handleDeleteClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (companyId && contactId) {
      dispatch(
        deleteContactRequest({
          companyId,
          id: contactId,
        })
      );
      onClose();
    }
  };

  const handleChangePhone = (event: ChangeEvent<HTMLInputElement>) => {
    setValue('phone', phoneFormat(event.target.value));
  };

  const submit: SubmitHandler<ContactFormData> = (data) => {
    if (companyId && contactId && statusContact === FetchStatus.NotFetched) {
      dispatch(
        updateContactRequest({
          data,
          companyId,
          contactId,
        })
      );
    }
  };

  const handleSubmitForm: FormEventHandler<HTMLFormElement> = async (e) => {
    e?.stopPropagation();
    e.preventDefault();
    const result = await trigger();
    if (result) {
      submit(getValues());
    }
    onClose();
  };

  useEffect(() => {
    if (statusContact === FetchStatus.Fetched) {
      setTimeout(() => {
        dispatch(resetUpdateContactStatus());
      }, 1000);
    }
  }, [dispatch, statusContact]);

  useEffect(() => {
    if (companyId) {
      dispatch(
        fetchFullOrganizationsObjectRequest({
          id: companyId,
          page: 1,
          max: 10000,
        })
      );
    }
  }, [companyId, dispatch]);
  return (
    <>
      <form action="#" className="form" onSubmit={handleSubmitForm}>
        <div className="form__cap">
          <h4>{t('clients.customer-information')}</h4>
        </div>
        <div className="form__group">
          <Input
            title={t('auth.lastname')}
            maxLength={30}
            type="text"
            {...register('lastName', {
              required: true,
            })}
            error={inputErrors('lastName', errors, t)}
          />
          <Input
            title={t('auth.firstname')}
            maxLength={30}
            type="text"
            {...register('firstName', {
              required: true,
            })}
            error={inputErrors('firstName', errors, t)}
          />
          <Input
            {...register('email', {
              required: true,
              minLength: 3,
              pattern: EMAIL_PATTERN,
            })}
            maxLength={180}
            title={t('auth.email')}
            error={emailErrors(errors, t)}
          />
          <div
            className={cn(
              !watch('whatsappAvailable') ? 'input-check' : 'input-check active'
            )}
          >
            <Input
              title={t('auth.phone')}
              type="text"
              svg={
                <i>
                  <IconWhatsApp className="svg" />
                </i>
              }
              maxLength={30}
              {...register('phone', {
                required: true,
                onChange: handleChangePhone,
                minLength: 7,
              })}
              error={inputErrors('phone', errors, t)}
            />
            <Checkbox
              onClick={(e) =>
                setValue('whatsappAvailable', e.currentTarget.checked)
              }
              defaultChecked={choisedContact?.whatsappAvailable}
              label={t('object.available-on-whatsapp')}
            />
          </div>
          <Select
            title={t('organization.organization')}
            listItems={allOrganizations}
            onSelect={(id) => {
              setValue('organizationId', id);
            }}
            selectedId={watch('organizationId')}
          />
          <TextArea
            title={t('object.comment')}
            maxLength={200}
            {...register('comment', {})}
          />
        </div>
        <div className="form__group">
          <Button name={t('clients.save-changes')} status={statusContact} />

          <SecondaryButton onClick={handleDeleteClick}>
            {t('clients.remove')}
          </SecondaryButton>
        </div>
      </form>
    </>
  );
};
export default EditContactForm;
