import Calendar, { toValidDataTime } from '@components/commons/Calendar';
import { OrderStatus } from '@utils/enums';
import { ReactComponent as IconDoc } from '@static/img/icon-doc2.svg';
import { ReactComponent as IconReset } from '@static/img/icon-reset2.svg';
import { ReactComponent as IcoClose } from '@static/img/icon-close3.svg';
import { DocumentOrder } from '@store/orderSupplier/slice';
import InputFileUpload from '@components/commons/FieldFile';
import { useAppDispatch } from '@hooks/redux';
import lodingLogoDark from '@static/img/logo3.svg';
import {
  FetchUpdateCurrentOrderRequestAction,
  FetchUpdateCurrentOrderValidityInvoiceRequestAction,
} from '@store/orderSupplier/actions';
import { FetchStatus } from '@store/constants';
import { useTranslation } from 'react-i18next';

type HeaderProps = {
  orderId: string;
  loadStatus: FetchStatus;
  StatusAction: () => JSX.Element;
  numberOrder: number;
  numberRequest: number;
  dateValidCheck: number;
  invoice: DocumentOrder | null;
  status: keyof typeof OrderStatus;
};

export default function Header({
  numberOrder,
  numberRequest,
  dateValidCheck,
  invoice,
  status,
  StatusAction,
  orderId,
  loadStatus,
}: HeaderProps) {
  const { t } = useTranslation();
  const isVisibleDate = status === 'PAYMENT';
  const dispatch = useAppDispatch();

  const handleUpdateIncoiceValidity = (newDate: number) => {
    dispatch(
      FetchUpdateCurrentOrderValidityInvoiceRequestAction({
        invoiceValidity: newDate,
        orderId,
      })
    );
  };

  return (
    <>
      <div className="order-modal__header">
        <div>
          <h4>{`${t('bid.order')} №${numberOrder}`}</h4>
          <small>{` / ${t(
            'bid.request'
          ).toLocaleLowerCase()} №${numberRequest} / ${t(
            'bid.order'
          ).toLocaleLowerCase()} №${numberOrder}`}</small>
        </div>

        {isVisibleDate && (
          <div className="order-modal__valid-date">
            {loadStatus === FetchStatus.Fetching && (
              <div className="order-modal__preloader date small">
                <img src={lodingLogoDark} alt="" />
              </div>
            )}

            <div className="order-modal__valid-date-block">
              <span> До </span>
              <Calendar
                date={toValidDataTime(dateValidCheck)}
                popup
                setDate={handleUpdateIncoiceValidity}
              />
            </div>
            <small>Счет действителен</small>
          </div>
        )}
        {invoice && isVisibleDate ? (
          <div className="order-modal__invoice">
            <IconDoc />
            <span>{invoice.originalFileName}</span>
            <IcoClose />
            <IconReset />
          </div>
        ) : (
          <div>
            <InputFileUpload
              partLoadFile="orderDocument"
              setUploadFileUrl={() => {}}
              htmlForId="uploadInvoice"
              params={{ orderId, category: 'INVOICE' }}
              sideEffect={() =>
                dispatch(FetchUpdateCurrentOrderRequestAction({ orderId }))
              }
              themeDownloader="ligth"
            >
              <div
                style={{
                  position: 'absolute',
                  zIndex: '100',
                }}
              />
            </InputFileUpload>
            <StatusAction />
          </div>
        )}
      </div>
    </>
  );
}
