import cn from 'classnames';
import { CSSProperties, InputHTMLAttributes, useId } from 'react';

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'value'> & {
  label?: string | null;
  rootClassName?: string;
  styles?: CSSProperties;
};

const Checkbox = ({ label, rootClassName, styles, ...restProps }: Props) => {
  const id = useId();

  return (
    <div className={cn('check', rootClassName)} style={styles}>
      <input
        type="checkbox"
        {...restProps}
        style={{ cursor: 'pointer' }}
        id={id}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};
export default Checkbox;
