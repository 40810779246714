import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@hooks/redux';
import { getCompanyObjects } from '@store/companyObjects/selectors';
import { getCompanyId } from '@store/company/selectors';
import { nanoid } from '@reduxjs/toolkit';
import TableRow from './tableRow';

export const Table = () => {
  const companyId = useAppSelector(getCompanyId);
  const companyObjects = useAppSelector(getCompanyObjects);
  const { t } = useTranslation();

  return (
    <div className="content__block">
      <div className="object">
        <div className="object__table">
          <div className="object__table-cap">
            <p>{t('directory.status')}</p>
            <p>{t('directory.title')}</p>
            <p>{t('directory.client')}</p>
            <p>{t('directory.cost')}</p>
            {/* TODO временно до полной работы функционала */}
            {/* <p>{t('directory.status')}</p> */}
          </div>

          {companyId &&
            companyObjects.map((e) => {
              return <TableRow row={e} key={nanoid()} companyId={companyId} />;
            })}
        </div>
      </div>
    </div>
  );
};
