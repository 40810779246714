import TableRequests from '@pages/AboutObject/Supply/Requests/TableRequests';
import HeaderSupply from '@pages/AboutObject/Supply/HeaderSupply';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { getCurrentEstimateByObject } from '@store/costEstimates/selectors';
import {
  createOrderCustomerRequest,
  deleteRequestCustomerItemRequest,
  deleteSelectedIds,
  fullClearSelectedIds,
  resetCreateOrderCustomerStatus,
  setDeleteRequestCustomerItemStatus,
  setSelectedId,
} from '@store/requestCustomer/actions';
import {
  createOrderCustomerStatus,
  deleteRequestCustomerItemStatus,
  getRequestCustomer,
  getSelectedIds,
} from '@store/requestCustomer/selectors';
import { RequestCustomerData } from '@store/requestCustomer/slice';
import { useTranslation } from 'react-i18next';
import { getOrdersCustomer } from '@store/ordersCustomer/selectors';
import { OrdersCustomerData } from '@store/ordersCustomer/slice';
import { ConfirmationModal } from '@components/commons/ConfirmationModal';
import { useCompanyId } from '@hooks/useCompanyId';
import { getCompanyObjectId } from '@store/companyObjects/selectors';
import { fetchCostEstimateRequest } from '@store/costEstimates/actions';
import { useSearchParams } from 'react-router-dom';
import ChatModal from '@components/ChatModal';
import { getisViewChat } from '@store/modalControl/selectors';
import {
  setIsViewChat,
  setIsViewChatSuppliersOfferDrawerFalse,
} from '@store/modalControl/actions';
import { FetchStatus } from '@store/constants';
import { ResourceId } from '@store/resources/slice';
import Orders from './Orders';
import TabSupply from './TabSupply';

type SectionData = {
  name: string;
  items: RequestCustomerData['items'];
};

type DataForRender = Record<string, SectionData>;

const Supply = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const companyId = useCompanyId();
  const objectId = useAppSelector(getCompanyObjectId);

  const request = useAppSelector(getRequestCustomer) as RequestCustomerData;
  const requestId = useAppSelector(getCurrentEstimateByObject)?.requestId;
  const selectedIds = useAppSelector(getSelectedIds);
  const ordersData = useAppSelector(getOrdersCustomer);
  const statusCreateOrderCustomer = useAppSelector(createOrderCustomerStatus);
  const isViewChat = useAppSelector(getisViewChat);
  const deleteItemStatus = useAppSelector(deleteRequestCustomerItemStatus);

  const isRequestItems = Boolean(request?.items.length);

  const [searchParams] = useSearchParams();
  const costEstimateIdFromQuery = searchParams.get('costEstimateId');

  const [isVisibleDeleteItemModal, setVisibleDeleteItemModal] = useState(false);
  const [resourceId, setResourceId] = useState('');

  const [isViewConfirmModal, setIsViewConfirmModal] = useState(false);
  const [isViewSuppliers, setIsViewSuppliers] = useState(false);
  const [isViewChoise, setIsViewChoise] = useState(false);
  const [dataForSuppliers, setDataForSuppliers] = useState<DataForRender>({});
  const [dataForSections, setDataForSections] = useState<DataForRender>({});
  const [tab, setTab] = useState('');

  const getItems = (fieldName: 'section' | 'supplier') => {
    const obj: DataForRender = {};
    if (request)
      request.items.forEach((item) => {
        const id = item[fieldName]?.id;
        if (obj[id]) {
          obj[id].items.push(item);
        } else {
          obj[id] = {
            name: item[fieldName]?.name,
            items: [item],
          };
        }
      });
    return obj;
  };

  useEffect(() => {
    if (companyId !== undefined && costEstimateIdFromQuery !== null) {
      dispatch(
        fetchCostEstimateRequest({
          companyId,
          objectId,
          id: costEstimateIdFromQuery,
        })
      );
    }
  }, [companyId, objectId, dispatch, costEstimateIdFromQuery]);

  useEffect(() => {
    setDataForSections(getItems('section'));
    setDataForSuppliers(getItems('supplier'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request]);

  const handleClickToggle = () => {
    setIsViewSuppliers(!isViewSuppliers);
  };

  const handleOpenSupplierOffers = () => {
    setIsViewChoise(true);
  };

  const handleChoiseClose = () => {
    setIsViewChoise(false);
    dispatch(setIsViewChatSuppliersOfferDrawerFalse());
  };

  const handleCloseConfirmModal = () => {
    setIsViewConfirmModal(false);
  };

  const handleOpenConfirmModal = () => {
    dispatch(resetCreateOrderCustomerStatus());
    setIsViewConfirmModal(true);
  };

  const handleAddSectionIds = (ids: string[], checked: boolean) => {
    if (checked) {
      dispatch(setSelectedId(ids));
    } else {
      dispatch(deleteSelectedIds(ids));
    }
  };

  const handleAddResourceIds = (id: string, checked: boolean) => {
    if (checked) {
      dispatch(setSelectedId([id]));
    } else {
      dispatch(deleteSelectedIds([id]));
    }
  };

  const handleClickAlbinaButton = () => {
    if (requestId) {
      dispatch(
        createOrderCustomerRequest({
          requestItemsIds: selectedIds,
          requestId,
        })
      );
      dispatch(fullClearSelectedIds());
    }
  };

  // Удаление Item в заявке
  const handleOpenDeleteItemModal = (id: ResourceId) => {
    dispatch(setDeleteRequestCustomerItemStatus(FetchStatus.NotFetched));
    setVisibleDeleteItemModal(true);
    setResourceId(id);
  };

  const handleConfirmDeleteButton = () => {
    if (requestId) {
      dispatch(deleteRequestCustomerItemRequest({ resourceId, requestId }));
    }
  };

  const handleClickCancelDeleteButton = () => {
    setVisibleDeleteItemModal(false);
  };

  return (
    <div className="content__core">
      <div className="obj">
        <TabSupply handleClickToggle={handleClickToggle} setTab={setTab} />
        <HeaderSupply
          tab={tab}
          request={request}
          requestId={requestId}
          orders={ordersData as OrdersCustomerData}
          handleClickToggle={handleClickToggle}
          handleOpenConfirmModal={handleOpenConfirmModal}
        />
        {tab === 'requests' ? (
          <TableRequests
            data={isViewSuppliers ? dataForSuppliers : dataForSections}
            requestId={requestId}
            isRequestItems={isRequestItems}
            isViewSuppliers={isViewSuppliers}
            isViewChoise={isViewChoise}
            selectedIds={selectedIds}
            handleAddSectionIds={handleAddSectionIds}
            handleAddResourceIds={handleAddResourceIds}
            handleOpenSupplierOffers={handleOpenSupplierOffers}
            handleChoiseClose={handleChoiseClose}
            handleOpenDeleteItemModal={handleOpenDeleteItemModal}
          />
        ) : (
          <Orders ordersData={ordersData} />
        )}
      </div>
      <ConfirmationModal
        confirmTitle={t(
          !selectedIds.length
            ? 'orders.create-an-order-for-all-items-with-the-selected-supplier'
            : 'confirm.to-form-an-order-for-the-selected-items'
        )}
        isVisibleModal={isViewConfirmModal}
        status={statusCreateOrderCustomer}
        confirmButtonClassName="bg-success"
        onCancel={handleCloseConfirmModal}
        onConfirm={handleClickAlbinaButton}
      />
      <ConfirmationModal
        confirmTitle={t('confirm.delete-resource-confirm')}
        isVisibleModal={isVisibleDeleteItemModal}
        status={deleteItemStatus}
        confirmButtonClassName="bg-danger"
        onCancel={handleClickCancelDeleteButton}
        onConfirm={handleConfirmDeleteButton}
      />
      <ChatModal
        visible={isViewChat}
        onClose={() => dispatch(setIsViewChat())}
      />
    </div>
  );
};

export default Supply;
