import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ROOT_FOLDER_ID } from '@constants/folders';
import { useAppSelector } from '@hooks/redux';
import { getParentFolder } from '@store/resourceFolders/selectors';
import { getHomeUrl, getOperationsUrl, getResourcesUrl } from '@pages/index';
import { MainLayout } from '@layouts/MainLayout';
import { Settings } from './Settings';

export const SettingsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { folderId = ROOT_FOLDER_ID } = useParams();

  const isResourcesUrl = pathname.startsWith(getResourcesUrl());
  const isOperationsUrl = pathname.startsWith(getOperationsUrl());

  const backUrl = useAppSelector((state) => {
    if (isResourcesUrl && folderId !== ROOT_FOLDER_ID) {
      const parentFolder = getParentFolder(state, folderId);
      return parentFolder ? getResourcesUrl(parentFolder.id) : getHomeUrl();
    }
    if (isOperationsUrl && folderId !== ROOT_FOLDER_ID) {
      return getOperationsUrl();
    }

    return getHomeUrl();
  });

  const handleBackClick = () => {
    navigate(backUrl);
  };

  return (
    <MainLayout
      title={t('navigation.settings')}
      onBackClick={handleBackClick}
      hasBackForMobile
    >
      <Settings />
    </MainLayout>
  );
};
