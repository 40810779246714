import { useFormErrors } from '@hooks/useFormErrors';
import { Input } from '@pages/Dashboard/ObjectModal/CreateObjectForm/Input';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EMAIL_PATTERN } from '@constants/patterns';
import { TextArea } from '@pages/Dashboard/ObjectModal/CreateObjectForm/TextArea';
import {
  createContactRequest,
  resetCreateContactStatus,
} from '@store/contacts/actions';
import { useDispatch } from 'react-redux';
import { Select } from '@components/EditCompany/Select';
import { useAppSelector } from '@hooks/redux';
import { getCompany } from '@store/company/selectors';
import ResponsibleSelect from '@components/ResponsibleSelect';
import { getCreateContactStatus } from '@store/contacts/selectors';
import { OrganizationId } from '@store/organizations/slice';
import { FetchStatus } from '@store/constants';
import {
  ChangeEvent,
  FormEventHandler,
  MouseEventHandler,
  useEffect,
} from 'react';
import { ReactComponent as IconWhatsApp } from '@static/img/icon-whatsapp.svg';
import Checkbox from '@components/commons/Checkbox';
import { phoneFormat } from '@utils/phoneFormat';
import { getFullOrganizations } from '@store/organizations/selectors';
import cn from 'classnames';
import Button from '@components/commons/Button';
import { SecondaryButton } from '@components/commons/SecondaryButton';
import { fetchFullOrganizationsObjectRequest } from '@store/organizations/actions';
import { emailErrors, inputErrors } from '../utils';

type CreateContactFormData = {
  organizationId?: OrganizationId;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  comment?: string;
  whatsappAvailable: boolean;
  responsible?: string;
};

type CreateContactFormProps = {
  onClose: () => void;
};

const CreateContactForm = ({ onClose }: CreateContactFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organizations = useAppSelector(getFullOrganizations);
  const statusContact = useAppSelector(getCreateContactStatus);
  const companyId = useAppSelector(getCompany)?.id as string;

  const {
    register,
    trigger,
    getValues,
    setValue,
    watch,
    formState: { errors: formErrors },
  } = useForm<CreateContactFormData>({ mode: 'onBlur' });
  const errors = useFormErrors(formErrors);

  const handleCancelClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onClose();
  };

  const handleChangePhone = (event: ChangeEvent<HTMLInputElement>) => {
    setValue('phone', phoneFormat(event.target.value));
  };

  const submit: SubmitHandler<CreateContactFormData> = (data) => {
    const newData = {
      ...data,
      // whatsappAvailable: activeWhatsApp,
    };
    if (statusContact === FetchStatus.NotFetched) {
      dispatch(
        createContactRequest({
          data: newData,
          companyId,
        })
      );
      onClose();
    }
  };

  const handleSubmitForm: FormEventHandler<HTMLFormElement> = async (e) => {
    e?.stopPropagation();
    e.preventDefault();
    const result = await trigger();
    if (result) {
      submit(getValues());
    }
  };

  useEffect(() => {
    if (statusContact === FetchStatus.Fetched) {
      setTimeout(() => {
        dispatch(resetCreateContactStatus());
      }, 1000);
    }
  }, [dispatch, statusContact]);

  useEffect(() => {
    if (companyId) {
      dispatch(
        fetchFullOrganizationsObjectRequest({
          id: companyId,
          page: 1,
          max: 100000,
        })
      );
    }
  }, [companyId, dispatch]);

  return (
    <>
      <form action="#" className="form" onSubmit={handleSubmitForm}>
        <div className="form__cap">
          <h4>{t('object.new-contact')}</h4>
        </div>
        <div className="form__group">
          <Input
            title={t('auth.lastname')}
            maxLength={35}
            type="text"
            {...register('lastName', {
              required: true,
            })}
            error={inputErrors('lastName', errors, t)}
          />
          <Input
            title={t('auth.firstname')}
            maxLength={35}
            type="text"
            {...register('firstName', {
              required: true,
            })}
            error={inputErrors('firstName', errors, t)}
          />
          <Input
            {...register('email', {
              required: true,
              minLength: 3,
              pattern: EMAIL_PATTERN,
            })}
            maxLength={180}
            title={t('auth.email')}
            error={emailErrors(errors, t)}
          />
          <div
            className={cn(
              !watch('whatsappAvailable') ? 'input-check' : 'input-check active'
            )}
          >
            <Input
              title={t('auth.phone')}
              type="text"
              svg={
                <i>
                  <IconWhatsApp />
                </i>
              }
              maxLength={100}
              {...register('phone', {
                required: true,
                onChange: handleChangePhone,
                minLength: 7,
              })}
              error={inputErrors('phone', errors, t)}
            />
            <Checkbox
              onClick={(e) =>
                setValue('whatsappAvailable', e.currentTarget.checked)
              }
              label={t('object.available-on-whatsapp')}
            />
          </div>
          <TextArea
            title={t('object.comment')}
            maxLength={200}
            {...register('comment', {})}
          />
          <ResponsibleSelect
            onSelect={(id) => {
              setValue('responsible', id);
            }}
            selectedId={watch('responsible')}
          />

          <Select
            title={t('organization.organization')}
            listItems={organizations && organizations}
            onSelect={(id) => {
              setValue('organizationId', id);
            }}
            selectedId={watch('organizationId') || ''}
          />
        </div>
        <div className="form__group">
          <Button name={t('object.create-an-contact')} status={statusContact} />

          <SecondaryButton onClick={handleCancelClick}>
            {t('object.cancel-the-selection')}
          </SecondaryButton>
        </div>
      </form>
    </>
  );
};
export default CreateContactForm;
