import {
  Operation,
  OperationId,
  operationsSlice,
} from '@store/operations/slice';
import { CompanyId } from '@store/company/slice';
import {
  CreateOperationParams,
  UpdateOperationParams,
  DeleteOperationsParams,
  AddOperationResourcesParams,
  UpdateOperationResourcesParams,
  DeleteOperationResourcesParams,
} from '@sagas/api/operations';
import { createAction } from '@reduxjs/toolkit';

export type FetchOperationsRequestAction = {
  companyId: CompanyId;
};

export type FetchOperationsSuccessAction = {
  companyId: CompanyId;
  data: Operation[];
};

export type CreateOperationRequestAction = {
  companyId: CompanyId;
  temporaryId: string;
  data: CreateOperationParams;
};
export type CreateOperationSuccessAction = {
  companyId: CompanyId;
  temporaryId: string;
  data: Operation;
};
export type CreateOperationErrorAction = {
  companyId: CompanyId;
  temporaryId: string;
};

export type UpdateOperationRequestAction = {
  companyId: CompanyId;
  id: OperationId;
  data: UpdateOperationParams;
};

export type UpdateOperationSuccessAction = {
  companyId: CompanyId;
  id: OperationId;
  data: UpdateOperationParams;
};

export type DeleteOperationsRequestAction = {
  companyId: CompanyId;
  data: DeleteOperationsParams;
};

export type DeleteOperationsSuccessAction = {
  companyId: CompanyId;
  ids: OperationId[];
};

export type AddOperationResourcesRequestAction = {
  companyId: CompanyId;
  id: OperationId;
  data: AddOperationResourcesParams;
};

export type AddOperationResourcesSuccessAction = {
  companyId: CompanyId;
  id: OperationId;
  data: AddOperationResourcesParams;
};

export type UpdateOperationResourcesRequestAction = {
  companyId: CompanyId;
  id: OperationId;
  data: UpdateOperationResourcesParams;
};

export type UpdateOperationResourcesSuccessAction = {
  companyId: CompanyId;
  id: OperationId;
  data: UpdateOperationResourcesParams;
};

export type DeleteOperationResourcesRequestAction = {
  companyId: CompanyId;
  id: OperationId;
  data: DeleteOperationResourcesParams;
};

export type DeleteOperationResourcesSuccessAction = {
  companyId: CompanyId;
  id: OperationId;
  data: DeleteOperationResourcesParams;
};

export const updateOperationSuccess =
  createAction<UpdateOperationSuccessAction>(
    'operations/updateOperationSuccess'
  );

export const deleteOperationsSuccess =
  createAction<DeleteOperationsSuccessAction>(
    'operations/deleteOperationsSuccess'
  );

export const addOperationResourcesSuccess =
  createAction<AddOperationResourcesSuccessAction>(
    'operations/addOperationResourcesSuccess'
  );

export const updateOperationResourcesSuccess =
  createAction<UpdateOperationResourcesSuccessAction>(
    'operations/updateOperationResourcesSuccess'
  );

export const deleteOperationResourcesSuccess =
  createAction<DeleteOperationResourcesSuccessAction>(
    'operations/deleteOperationResourcesSuccess'
  );

export const {
  fetchOperationsRequest,
  fetchOperationsSuccess,
  createOperationRequest,
  createOperationSuccess,
  createOperationError,
  updateOperationRequest,
  deleteOperationsRequest,
  addOperationResourcesRequest,
  updateOperationResourcesRequest,
  deleteOperationResourcesRequest,
} = operationsSlice.actions;
