import { useAppDispatch, useAppSelector } from '@hooks/redux';
import {
  getActiveRequestId,
  getBid,
  getRequestBasicData,
} from '@store/requestSupplier/selectors';
import { useEffect } from 'react';
import { FetchRequestRequestAction } from '@store/requestSupplier/actions';
import { StatusRequest, StatusRequestElement } from '@utils/enums';
import { nanoid } from '@reduxjs/toolkit';
import { TypeBid } from '@store/requestSupplier/slice';
import { useTranslation } from 'react-i18next';
import ModalHeader from './ModalHeader';
import Table from './Table';
import Part from './Part';
import { type DetailsProps } from '../types';

type DetailsRequestrProps = {
  currencySymbolById: string | null | undefined;
  status: keyof typeof StatusRequest;
} & DetailsProps;

export default function DetailsRequestSupliner({
  currencySymbolById,
  status,
}: DetailsRequestrProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const requestId = useAppSelector(getActiveRequestId);
  const requestBasicData = useAppSelector(getRequestBasicData);

  const bid = useAppSelector(getBid);

  const statusBid: { title: string; part: keyof TypeBid }[] = [
    {
      title: t('bid.needClarification-resources'),
      part: StatusRequestElement.needClarification,
    },
    {
      title: t('bid.new-resources'),
      part: StatusRequestElement.newResources,
    },
    {
      title: t('bid.unanswered-resources'),
      part: StatusRequestElement.unansweredResources,
    },
    {
      title: t('bid.processed-resources'),
      part: StatusRequestElement.processedResources,
    },

    {
      title: t('bid.selected-resources'),
      part: StatusRequestElement.selectedResponses,
    },
  ];

  useEffect(() => {
    if (requestId) dispatch(FetchRequestRequestAction({ requestId, status }));
  }, [dispatch, requestId, status]);
  return (
    <div className="modal__block max white">
      <div className="modal__close arrow" />
      <div className="req" style={{ padding: '20px' }}>
        {requestBasicData && (
          <>
            <ModalHeader
              requestBasicData={requestBasicData}
              requestId={requestId}
              status={status}
            />
            <Table>
              {statusBid.map((e) => {
                return (
                  <Part
                    part={e.part}
                    title={
                      status === 'NEW' || status === 'SELECTED' ? '' : e.title
                    }
                    key={nanoid()}
                    items={bid[e.part]}
                    currencySymbolById={currencySymbolById}
                  />
                );
              })}
            </Table>
          </>
        )}
      </div>
    </div>
  );
}
