import {
  all,
  call,
  put,
  SagaReturnType,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { Saga } from '@sagas/types';
import { handleError } from '@sagas/errors';
import {
  saveProfileRequest,
  saveProfileSuccess,
  SaveProfileRequestAction,
  fetchProfileRequest,
  FetchProfileRequestAction,
  fetchProfileSuccess,
  UpdatePasswordProfileRequestAction,
  updatePasswordProfileRequest,
  setPasswordProfileStatus,
} from '@store/profile/actions';
import { getProfile } from '@store/profile/selectors';
import { parseJwt } from '@utils/jwt';
import { tokenFromLocalStorage } from '@utils/axios';
import { fetchSupplierRequest } from '@store/supplier/actions';
import { fetchNumberOfConversationsWithUnreadedRequestAction } from '@store/chat/actions';
import { FetchStatus } from '@store/constants';
import {
  getProfile as getProfileApi,
  saveProfile,
  updatePasswordProfile,
} from './api/profile';

const fetchProfileRequestHandler: Saga<FetchProfileRequestAction> =
  function* () {
    try {
      const data: SagaReturnType<typeof getProfileApi> = yield call(
        getProfileApi
      );

      const payloadToken = tokenFromLocalStorage();
      if (payloadToken) data.roles = parseJwt(payloadToken).roles;

      if (data.roles.includes('ROLE_SUPPLIER'))
        yield put(fetchSupplierRequest({ id: data.currentCompanyId }));

      yield put(fetchProfileSuccess({ data }));
      yield put(fetchNumberOfConversationsWithUnreadedRequestAction());
    } catch (e) {
      yield call(handleError, e);
    }
  };

const saveProfileRequestHandler: Saga<SaveProfileRequestAction> = function* ({
  payload,
}) {
  const { data, language } = payload;
  const profile: ReturnType<typeof getProfile> = yield select(getProfile);

  if (profile === null) {
    // TODO: show error, may be dispatch log out
    return;
  }

  try {
    yield call(saveProfile, data);

    yield put(saveProfileSuccess({ data: { ...profile, ...data, language } }));
  } catch (e) {
    yield call(handleError, e);
  }
};

const updatePasswordProfileRequestHandler: Saga<UpdatePasswordProfileRequestAction> =
  function* ({ payload }) {
    try {
      yield put(setPasswordProfileStatus(FetchStatus.Fetching));
      yield call(updatePasswordProfile, payload.data);
      yield put(setPasswordProfileStatus(FetchStatus.Fetched));
    } catch (e) {
      yield call(handleError, e);
      yield put(setPasswordProfileStatus(FetchStatus.NotFetched));
    }
  };

export default function* root() {
  yield all([takeLatest(fetchProfileRequest, fetchProfileRequestHandler)]);
  yield all([takeLatest(saveProfileRequest, saveProfileRequestHandler)]);
  yield all([
    takeLatest(
      updatePasswordProfileRequest,
      updatePasswordProfileRequestHandler
    ),
  ]);
}
