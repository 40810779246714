import React, { FC, memo } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { RESOURCE_TYPES } from '@constants/resource';
import { useAppSelector } from '@hooks/redux';
import {
  Operation,
  OperationId,
  OperationResource,
} from '@store/operations/slice';
import { ResourceType } from '@store/resources/slice';
import { getResource } from '@store/resources/selectors';
import { getDefaultUnit, getUnit } from '@store/vocabulary/selectors';
import './styles.sass';
import { currencyFormat } from '@utils/currency';
import { percentFormat } from '@utils/percent';
import { useCurrentLocale } from '@hooks/useCurrentLocale';

type OperationsCatalogOperationResourceRawProps = {
  operationId: OperationId;
  operation: Operation;
  operationResource: OperationResource;
  operationAmount: number;
  create?: boolean;
};

const OperationsCatalogOperationResourceRaw: FC<
  OperationsCatalogOperationResourceRawProps
> = ({ operation, operationResource, operationAmount }) => {
  const { t } = useTranslation();
  const locale = useCurrentLocale();
  const resource = useAppSelector((state) =>
    getResource(state, operationResource.id)
  );
  const unit = useAppSelector((state) =>
    resource ? getUnit(state, resource.unitId) : getDefaultUnit(state)
  );

  const amount = Number.isNaN(operationAmount)
    ? 0
    : parseFloat(
        (
          ((operationResource?.amount ?? 0) / operation.amount) *
          operationAmount
        ).toFixed(2)
      );

  if (resource === undefined) {
    return null;
  }

  return (
    <div className={cn('manager__operation-resource')}>
      <span className="manager__operation-check" />
      <div className="manager__operation-group">
        <div className="sel">
          <a>
            <s
              className={
                RESOURCE_TYPES[resource?.type ?? ResourceType.HUM].className
              }
            >
              {t(RESOURCE_TYPES[resource?.type ?? ResourceType.HUM].title)}
            </s>
          </a>
        </div>
      </div>
      <div className="manager__operation-name" title={resource?.name ?? ''}>
        <input
          readOnly
          type="text"
          name="name"
          value={resource?.name ?? ''}
          placeholder="Название ресурса"
        />
      </div>
      <div className="manager__operation-count">
        <input readOnly type="text" name="amount" value={amount} />
      </div>
      <div className="manager__operation-unit">
        <div className="sel">
          <a>{t(`units.${unit?.name}`) ?? ''}</a>
        </div>
      </div>
      <div className="manager__operation-price">
        <input
          readOnly
          type="text"
          name="price"
          value={currencyFormat(resource.price, resource.currency.code, locale)}
        />
      </div>
      <div className="manager__operation-markup">
        <input
          readOnly
          type="text"
          name="markup"
          value={percentFormat(resource.markup, locale)}
        />
      </div>
      <div className="manager__operation-client">
        <input
          readOnly
          type="text"
          name="client"
          value={currencyFormat(
            resource.salePrice,
            resource.currency.code,
            locale
          )}
        />
      </div>
    </div>
  );
};

export const OperationsCatalogOperationResource = memo(
  OperationsCatalogOperationResourceRaw
);
