import React, { FC } from 'react';

type SignUpButtonProps = {
  title: string;
  onClick?: () => void;
};

export const SignUpButton: FC<SignUpButtonProps> = ({ title, onClick }) => {
  return (
    <button type="submit" className="link" onClick={onClick}>
      <span>{title}</span>
    </button>
  );
};
