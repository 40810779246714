import { t } from 'i18next';
import { ReactComponent as IconAddImage } from '@static/img/icon-image.svg';
import InputFileUpload, { Params } from '@components/commons/FieldFile';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { getProfileCompanyId } from '@store/profile/selectors';
import { useEffect, useState } from 'react';
import Image from '@components/commons/Image';
import { TypeSize, setSizeImage } from '@utils/formatImage';
import { getCompanyObjectId } from '@store/companyObjects/selectors';
import { setImageObject } from '@store/companyObjects/actions';

type Props = {
  setImageId: (id: string) => void;
  filePath?: string;
  create?: boolean;
};

const PhotoCompany = ({ setImageId, create = false, filePath }: Props) => {
  const dispatch = useAppDispatch();
  const companyId = useAppSelector(getProfileCompanyId);
  const objectId = useAppSelector(getCompanyObjectId);

  const [imageName, setImageName] = useState<undefined | string>(filePath);

  const handleSetUploadFileId = (id: string) => {
    setImageId(id);
  };

  const handleSetUploadFileUrl = (name: string) => {
    if (create) {
      setImageName(name);
    } else {
      dispatch(setImageObject({ filePath: name }));
    }
  };

  const isCreate = (): Params => {
    if (create) {
      return { companyId: companyId ?? '' };
    }
    return { companyId: companyId ?? '', objectId: objectId ?? '' };
  };
  useEffect(() => {
    setImageName(filePath);
  }, [filePath]);

  return (
    <InputFileUpload
      setUploadFileUrl={handleSetUploadFileUrl}
      setUploadFileId={handleSetUploadFileId}
      partLoadFile={create ? 'createObjectImage' : 'updateObjectImage'}
      params={isCreate}
    >
      <input type="hidden" name="image" value="" />
      <span style={{ alignItems: 'center' }}>
        {!imageName ? (
          <>
            <IconAddImage />
            <p>{t('object.add-a-photo-of-the-object')}</p>
          </>
        ) : (
          <Image
            url={setSizeImage(imageName, TypeSize.object)}
            styles={{ width: '354px' }}
          />
        )}
      </span>
    </InputFileUpload>
  );
};

export default PhotoCompany;
