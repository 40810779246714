export const percentFormat = (
  value: string | number,
  locale: string
): string => {
  const number = typeof value === 'string' ? parseFloat(value) : value;

  return `${new Intl.NumberFormat(locale).format(
    parseFloat(number.toFixed(2))
  )} %`;
};

export const percentParse = (value: string, locale: string): number => {
  if (value === '') {
    return 0;
  }

  const thousandSeparator = Intl.NumberFormat(locale)
    .format(11111)
    .replace(/\p{Number}/gu, '');
  const decimalSeparator = Intl.NumberFormat(locale)
    .format(1.1)
    .replace(/\p{Number}/gu, '');

  return parseFloat(
    value
      .replace(new RegExp(`\\${thousandSeparator}`, 'g'), '')
      .replace(new RegExp(`\\${decimalSeparator}`), '.')
      .replace(/[^\d.,]/, '')
  );
};
