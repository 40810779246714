import React, { memo, useContext } from 'react';
import cn from 'classnames';
import { useAppSelector } from '@hooks/redux';
import { getOperationFolders } from '@store/operationFolders/selectors';
import { EstimateOperationsCatalogContext } from '@components/EstimateOperationsCatalog';
import { OperationCatalogFolder } from './Folder';

const OperationCatalogFolderListRaw: React.FC = () => {
  const { size } = useContext(EstimateOperationsCatalogContext);
  const folders = useAppSelector(getOperationFolders);

  return (
    <div className="manager__base">
      <div className={cn('manager__folders', size)}>
        {folders?.map((folder) => (
          <OperationCatalogFolder key={folder.id} folder={folder} />
        ))}
      </div>
    </div>
  );
};

export const OperationCatalogFolderList = memo(OperationCatalogFolderListRaw);
