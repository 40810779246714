import React, { memo, SyntheticEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { OperationCatalogContext } from '@components/OperationsCatalog';

import importIcon from '@static/img/icon-add7.svg';
import { OperationCatalogBreadcrumbs } from './Breadcrumbs';

type OperationCatalogHeaderType = {
  onCreateFolderClick: () => void;
};

const OperationCatalogHeaderRaw: React.FC<OperationCatalogHeaderType> = ({
  onCreateFolderClick,
}) => {
  const { t } = useTranslation();
  const { folderId } = useContext(OperationCatalogContext);
  const rootFolder = !folderId;
  const createFolderHandler = (e: SyntheticEvent) => {
    e.preventDefault();
    onCreateFolderClick();
  };

  return (
    <div className="manager__cap">
      <OperationCatalogBreadcrumbs />
      <div className="manager__but">
        {rootFolder && (
          <a href="#" onClick={createFolderHandler}>
            <img src={importIcon} alt={t('directory.create-folder') ?? ''} />
            {t('directory.create-folder')}
          </a>
        )}
      </div>
      {/* <form className="manager__search"> */}
      {/*  <input type="text" name="search" placeholder={t('directory.search') ?? ''} /> */}
      {/* </form> */}
    </div>
  );
};

export const OperationCatalogHeader = memo(OperationCatalogHeaderRaw);
