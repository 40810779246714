import { all, call } from 'redux-saga/effects';

import api from './api';
import user from './user';
import profile from './profile';
import company from './company';
import companyObjects from './companyObjects';
import costEstimates from './costEstimates';
import costEstimateSection from './costEstimateSections';
import costEstimateOperations from './costEstimateOperations';
import costEstimateResources from './costEstimateResources';
import resourceFolders from './resourceFolders';
import resources from './resources';
import operationFolders from './operationFolders';
import operations from './operations';
import vocabulary from './vocabulary';
import organizations from './organizations';
import contacts from './contacts';
import account from './account';
import supplier from './supplier';
import request from './requestSupplier';
import requestCustomer from './requestCustomer';
import responseSupplier from './responseSupplier';
import orderCustomer from './orderCustomer';
import order from './orderSupplier';
import chat from './chat';

export default function* root() {
  yield all([
    call(api),
    call(user),
    call(profile),
    call(company),
    call(companyObjects),
    call(organizations),
    call(contacts),
    call(costEstimates),
    call(costEstimateSection),
    call(costEstimateOperations),
    call(costEstimateResources),
    call(resourceFolders),
    call(resources),
    call(operationFolders),
    call(operations),
    call(vocabulary),
    call(account),
    call(supplier),
    call(requestCustomer),
    call(responseSupplier),
    call(request),
    call(order),
    call(orderCustomer),
    call(chat),
  ]);
}
