import React, { useRef, useState } from 'react';
import { Control, FieldErrors, Path, useController } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import cn from 'classnames';
import { format } from 'date-fns';
import { ru, enUS } from 'date-fns/locale';
import { DayPicker, useInput } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { usePopper } from 'react-popper';
import FocusTrap from 'focus-trap-react';
import { InputSize } from '@components/Form/Input';
import { useCurrentLocale } from '@hooks/useCurrentLocale';
import styles from './styles.module.sass';
import './styles.sass';

export type DatePickerProps<FormData extends FieldValues> = {
  title?: string;
  name: Path<FormData>;
  defaultSelected?: Date;
  fromDate?: Date;
  control: Control<FormData>;
  error:
    | string
    | ((name: keyof FieldErrors<FormData>) => string | void)
    | undefined;
  size?: InputSize;
  border?: boolean;
};

export const DatePicker = <FormData extends FieldValues>({
  title,
  name,
  defaultSelected,
  fromDate,
  control,
  error,
  size = InputSize.Auto,
  border = false,
}: DatePickerProps<FormData>): JSX.Element => {
  const locale = useCurrentLocale();
  const [selected, setSelected] = useState<Date>();
  // const [inputValue, setInputValue] = useState<string>('');
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const { field } = useController({ name, control });

  const popperRef = useRef<HTMLDivElement>(null);
  // const buttonRef = useRef<HTMLButtonElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );

  const errorMessage = error instanceof Function ? error(name) : error;
  const hasError = errorMessage !== undefined;

  const { inputProps, dayPickerProps } = useInput({
    defaultSelected: defaultSelected || new Date(),
    fromDate: fromDate || new Date(),
    format: 'PPP',
    required: true,
    locale: locale === 'ru' ? ru : enUS,
  });

  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-start',
  });

  const closePopper = () => {
    setIsPopperOpen(false);
    // buttonRef?.current?.focus();
  };

  const handleInputClick = () => {
    setIsPopperOpen(true);
  };

  const handleDaySelect = (date?: Date) => {
    if (date === undefined) {
      return;
    }

    setSelected(date);
    if (date) {
      field.onChange(format(date, 'y-MM-dd'));
      // setInputValue(format(date, 'y-MM-dd'));
      closePopper();
    } else {
      field.onChange(format(date, ''));
      // setInputValue('');
    }
  };

  return (
    <div
      className={cn('input', size, {
        border,
        error: hasError,
      })}
    >
      <div ref={popperRef}>
        <input
          type="text"
          readOnly
          onClick={handleInputClick}
          {...inputProps}
        />
        {title && <label>{title}</label>}
        {hasError && <small>{errorMessage}</small>}
      </div>
      {isPopperOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onDeactivate: closePopper,
            // fallbackFocus: buttonRef.current,
          }}
        >
          <div
            tabIndex={-1}
            style={popper.styles.popper}
            className={cn('dialog-sheet', styles.datePicker)}
            {...popper.attributes.popper}
            ref={setPopperElement}
            role="dialog"
          >
            <DayPicker
              initialFocus={isPopperOpen}
              mode="single"
              showOutsideDays
              defaultMonth={selected}
              selected={selected}
              onSelect={handleDaySelect}
              {...dayPickerProps}
            />
          </div>
        </FocusTrap>
      )}
    </div>
  );
};
