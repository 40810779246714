import { createAction } from '@reduxjs/toolkit';
import { CreateObjectData, UpdateObjectData } from '@sagas/api/companyObjects';
import { CompanyId } from '@store/company/slice';
import { DocumentId } from '@store/documents/slice';

import {
  CompanyObject,
  CompanyObjectId,
  ObjectStatus,
  companyObjectsSlice,
} from './slice';

export type CategoryDocuments = 'general' | 'customer' | 'contractor';

export type DeleteDocumentCompanyObjectRequestAction = {
  id: DocumentId;
  category: CategoryDocuments;
};
export type DeleteDocumentCompanyObjectSuccessAction = {
  id: DocumentId;
  category: CategoryDocuments;
};

export type EditDocumentCompanyObjectRequestAction = {
  id: DocumentId;
  originalDocumentName: string;
  category: CategoryDocuments;
};
export type EditDocumentCompanyObjectSuccessAction = {
  id: DocumentId;
  originalDocumentName: string;
  category: CategoryDocuments;
};

export type FetchCompanyObjectsRequestAction = {
  id: CompanyId;
};
export type FetchCompanyObjectRequestAction = {
  id: CompanyId;
};

export type FetchCompanyObjectsSuccessAction = {
  data: CompanyObject[];
};

export type FetchCompanyObjectSuccessAction = {
  data: CompanyObject;
};

export type CreateObjectRequestAction = {
  data: CreateObjectData;
  companyId: CompanyId;
};

export type CreateObjectSuccessAction = {
  data: CompanyObject;
};

export type UpdateObjectRequestAction = {
  data: UpdateObjectData;
  companyId: CompanyId;
  id: CompanyObjectId;
};

export type UpdateObjectSuccessAction = {
  data: UpdateObjectData;
  id: CompanyObjectId;
};
export type UpdateCurrentObjectSuccessAction = {
  data: CompanyObject;
};

export type ArchiveObjectRequestAction = {
  companyId: CompanyId;
  id: CompanyObjectId;
};
export type ArchiveObjectSuccessAction = {
  id: CompanyObjectId;
};

export type UpdateStatusObject = {
  status: ObjectStatus;
  id: string;
};

export type ObjectMoveRequestActions = {
  companyId: CompanyId;
  objectElementId: string;
  subjectElementId: string;
  toMove: -1 | 1;
};

export type MoveObject = {
  countPoint: string;
  objectElementId: string;
  subjectElementId: string;
};

export const archiveObjectRequest = createAction<ArchiveObjectRequestAction>(
  'company/archiveObjectRequest'
);

export const updateObjectStatusRequest = createAction<UpdateStatusObject>(
  'company/updateObjectStatusRequest'
);

export const {
  setArchiveObjectStatus,
  setImageObject,
  editDocumentCompanyObjectSuccess,
  editDocumentCompanyObjectRequest,
  deleteDocumentCompanyObjectRequest,
  deleteDocumentCompanyObjectSuccess,
  resetEditObjectStatus,
  updateObjectSuccess,
  updateObjectRequest,
  fetchCompanyObjectsRequest,
  fetchCompanyObjectsSuccess,
  updateContactCurrentObject,
  updateOrganizationCurrentObject,
  deleteOrganizationCurrentObject,
  deleteContactCurrentObject,
  fetchCompanyObjectSuccess,
  fetchCompanyObjectRequest,
  createObjectRequest,
  createObjectSuccess,
  archiveObjectSuccess,
  setCurrentCompanyObjectId,
  setActiveBlinkWhenCreatedNewObject,
  resetCreateObjectStatus,
  resetLatestAddedCompanyObjectId,
  setViewObject,
  fetchUpdateStatusRequestSuccess,
  fetchObjectMoveRequest,
} = companyObjectsSlice.actions;
