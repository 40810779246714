import { call } from 'redux-saga/effects';
import { ENDPOINTS } from '@config';
import { getRequest, postRequest } from '@sagas/api';
import { urlFromTemplate } from '@utils/url';
import {
  RequestBasicData,
  Request,
  Response,
} from '@store/requestSupplier/slice';
import { FetchRequest, FetchRequestById } from '@store/requestSupplier/actions';

export function* getRequestBySupplier(params: FetchRequest) {
  const { status, supplierId } = params;
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.SUPPLIER.REQUEST.GET, { supplierId, status })
  );

  return data as RequestBasicData[];
}

export function* getRequestListBySupplier(params: FetchRequestById) {
  const { requestId, status } = params;
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.SUPPLIER.REQUEST.GETBYID, {
      status,
      requestId,
    })
  );

  return data as Request;
}

type FetchSendResponse = {
  requestId: string;
  response: Response[];
  validity: number;
};
export function* sendResponseFromRequest(params: FetchSendResponse) {
  const { requestId, response, validity } = params;

  const { data } = yield call(
    postRequest,
    urlFromTemplate(ENDPOINTS.SUPPLIER.RESPONSES.CREATE, {
      requestId,
    }),
    { responses: response, validity }
  );

  return data as Request;
}
