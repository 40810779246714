import { ReactComponent as IconClose } from '@static/img/icon-close2.svg';
import { ReactComponent as IconWhatsApp } from '@static/img/icon-whatsapp.svg';
import { ReactComponent as IconEmail } from '@static/img/icon-email2.svg';
import IconButton from '@components/commons/IconButton';
import styles from './styles.module.sass';

type ChoisedContactProps = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  onDelete: () => void;
};

const ChoisedContact = ({
  firstName,
  lastName,
  email,
  phone,
  onDelete,
}: ChoisedContactProps) => {
  return (
    <div className="repres">
      <div className="repres__item">
        <div className={styles.represItemContainerRemoveButton}>
          <i>
            <IconButton onClick={onDelete}>
              <IconClose />
            </IconButton>
          </i>
          <h4>{`${firstName} ${lastName}`}</h4>
        </div>
        <ul>
          <li className={styles.represItemContainerSVGButton}>
            <IconWhatsApp className={styles.iconSVG} />
            {phone}
          </li>
          <li className={styles.represItemContainerSVGButton}>
            <IconEmail className={styles.iconSVG} />
            {email}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ChoisedContact;
