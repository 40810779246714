import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { Saga } from '@sagas/types';
import { handleError } from '@sagas/errors';
import { signOutRequest } from '@store/user/actions';
import {
  DeleteSupplierRequestAction,
  FetchSupplierRequestAction,
  SaveSupplierRequestAction,
  deleteSupplierRequest,
  deleteSupplierSuccess,
  fetchSupplierRequest,
  fetchSupplierSuccess,
  saveSupplierRequest,
  saveSupplierSuccess,
  setDeleteSupplierStatus,
} from '@store/supplier/actions';

import { SupplierData } from '@store/supplier/slice';

import { getSupplier as getSupplierData } from '@store/supplier/selectors';
import { FetchStatus } from '@store/constants';
import {
  deleteSupplier,
  getSupplier,
  saveSupplier,
  updateSupplierCategories,
} from './api/supplier';

const fetchSupplierRequestHandler: Saga<FetchSupplierRequestAction> =
  function* ({ payload }) {
    const { id } = payload;

    try {
      const data = yield call(getSupplier, id);

      yield put(fetchSupplierSuccess({ data }));
    } catch (e) {
      yield call(handleError, e);
    }
  };

const saveSupplierRequestHandler: Saga<SaveSupplierRequestAction> = function* ({
  payload,
}) {
  const { data } = payload;

  const supplier: SupplierData = yield select(getSupplierData);

  if (supplier === null) {
    return;
  }

  try {
    yield call(saveSupplier, data);
    yield call(updateSupplierCategories, {
      id: data.id,
      categoriesIds: data.categories,
    });
    yield put(
      saveSupplierSuccess({
        ...supplier,
        ...data,
      })
    );
  } catch (e) {
    yield call(handleError, e);
  }
};

const deleteSupplierRequestHandler: Saga<DeleteSupplierRequestAction> =
  function* ({ payload }) {
    const { id } = payload;

    try {
      yield put(setDeleteSupplierStatus(FetchStatus.Fetching));
      yield call(deleteSupplier, id);
      yield put(deleteSupplierSuccess());
      yield put(setDeleteSupplierStatus(FetchStatus.Fetched));
      yield put(signOutRequest());
    } catch (e) {
      yield put(setDeleteSupplierStatus(FetchStatus.Error));
      yield call(handleError, e);
    }
  };

export default function* root() {
  yield all([
    takeLatest(fetchSupplierRequest, fetchSupplierRequestHandler),
    takeLatest(saveSupplierRequest, saveSupplierRequestHandler),
    takeLatest(deleteSupplierRequest, deleteSupplierRequestHandler),
  ]);
}
