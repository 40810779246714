import { useEffect } from 'react';
import { useAppDispatch } from '@hooks/redux';
import { fetchOrderCustomerRequest } from '@store/ordersCustomer/actions';
import { OrdersCustomerData } from '@store/ordersCustomer/slice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Empty from '@components/commons/Empty';
import OrdersTableHeader from './OrdersTableHeader';
import OrdersTableIttems from './OrdersTableItems';

type Props = {
  ordersData: OrdersCustomerData | null;
};

const Orders = ({ ordersData }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const costEstimateIdFromQuery = searchParams.get('costEstimateId');

  useEffect(() => {
    if (costEstimateIdFromQuery) {
      dispatch(
        fetchOrderCustomerRequest({ costEstimateId: costEstimateIdFromQuery })
      );
    }
  }, [costEstimateIdFromQuery, dispatch, navigate]);

  return (
    <>
      <div className="obj__supply">
        {ordersData?.items.length ? (
          <>
            <OrdersTableHeader />
            <div className="obj__order-block">
              {ordersData?.items.map((item) => (
                <div key={item.id}>
                  <OrdersTableIttems
                    orderId={item.id}
                    orderNumber={item.number}
                    deliveryTimeDate={item.deliveryTime}
                    supplierName={item.supplier.name}
                    supplierLogo={item.supplier?.image?.fileName}
                    plan={item.plannedBudget}
                    fact={item.actualBudget}
                    invoice={item.invoice}
                    proxy={item.proxy}
                    currencyCode={ordersData.currencyCode}
                    needInvoice={item.needInvoice}
                    status={item.status}
                    invoiceValidity={item?.invoiceValidity}
                  />
                </div>
              ))}
            </div>
          </>
        ) : (
          <Empty firstLine="supply.you-dont-have-any-orders" />
        )}
      </div>
    </>
  );
};
export default Orders;
