import { Message as MessageType } from '@store/chat/slice';
import { toValidDataTime } from '@components/commons/Calendar';
import Loader from '@components/commons/Loader';
import { useAppSelector } from '@hooks/redux';
import { getIsSendingMessage } from '@store/chat/selectors';
import Message from './Message';

type MessagesProps = {
  messages: MessageType[];
};

export default function MessageList({ messages }: MessagesProps) {
  const statusIsSendingMessage = useAppSelector(getIsSendingMessage);
  return (
    <>
      {messages.map((e) => {
        const UTCString = new Date(toValidDataTime(e.createdAt))
          .toUTCString()
          .split(' ');
        const time = UTCString[4].split(':').slice(0, 2).join(':');

        return (
          <Message
            key={e.id}
            author={e.author}
            message={e.content}
            time={time}
          />
        );
      })}
      {statusIsSendingMessage === true && (
        <div className="chat__message">
          <Loader />
        </div>
      )}
    </>
  );
}
