import React, { FC, memo, useCallback, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { nanoid } from '@reduxjs/toolkit';
import { useAppDispatch } from '@hooks/redux';
import { useTableRowForm } from '@hooks/useTableRowForm';
import { useCompanyId } from '@hooks/useCompanyId';
import { useCurrentLocale } from '@hooks/useCurrentLocale';
import { useLocalizedUnits } from '@hooks/useUnits';
import { ResourcesCatalogContext } from '@components/ResourcesCatalog';
import { EntityStatus } from '@store/constants';
import { Resource, ResourceId, ResourceType } from '@store/resources/slice';
import {
  createResourceRequest,
  deleteResourcesRequest,
  updateResourceRequest,
} from '@store/resources/actions';
import { ResourceFolderId } from '@store/resourceFolders/slice';
import { Unit, UnitId } from '@store/vocabulary/slice';
import { TableSelect } from '@components/Table/Select';
import { percentFormat, percentParse } from '@utils/percent';
import { currencyFormat, currencyParse } from '@utils/currency';
import removeOperationIcon from '@static/img/icon-remove-blue.svg';
import { ResourcesCatalogResourceSelect } from '../ResourceTypeSelector';
import './styles.sass';

type ResourcesCatalogResourceProps = {
  resource: Resource;
  create?: boolean;
  onCancel?: () => void;
};

type FormData = {
  id: ResourceId;
  resourceFolderId: ResourceFolderId;
  type: ResourceType;
  name: string;
  unitId: UnitId;
  price: string;
  markup: string;
  salePrice: string;
};

const ResourcesCatalogResourceRaw: FC<ResourcesCatalogResourceProps> = ({
  resource,
  create = false,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { selectedResources, onSelectResources } = useContext(
    ResourcesCatalogContext
  );

  const dispatch = useAppDispatch();
  const locale = useCurrentLocale();
  const units = useLocalizedUnits();
  const companyId = useCompanyId();
  const fetching = resource.status === EntityStatus.Fetching;

  const selectedResource = selectedResources.includes(resource.id);

  const handleCheckboxClick = () => {
    onSelectResources([resource.id]);
  };
  const handleDeleteClick = useCallback(() => {
    if (companyId !== undefined) {
      if (selectedResource) {
        onSelectResources([resource.id]);
      }

      dispatch(
        deleteResourcesRequest({ companyId, data: { ids: [resource.id] } })
      );
    }
  }, [companyId, selectedResource, resource.id, onSelectResources, dispatch]);

  const {
    register,
    handleSubmit,
    setFocus,
    setValue,
    getValues,
    reset,
    control,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      ...resource,
      markup: `${percentFormat(resource.markup, locale)}`,
      price: `${currencyFormat(
        resource.price,
        resource.currency.code,
        locale
      )}`,
      salePrice: `${currencyFormat(
        resource.salePrice,
        resource.currency.code,
        locale
      )}`,
    },
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type !== 'change') {
        return;
      }

      const price =
        value.price !== undefined ? currencyParse(value.price, locale) : 0;
      const markup =
        value.markup !== undefined ? percentParse(value.markup, locale) : 0;
      const salePrice =
        value.salePrice !== undefined
          ? currencyParse(value.salePrice, locale)
          : 0;

      switch (name) {
        case 'price':
        case 'markup': {
          const calculatedSalePrice = price * (1 + markup / 100);
          setValue(
            'salePrice',
            currencyFormat(calculatedSalePrice, resource.currency.code, locale)
          );
          break;
        }
        case 'salePrice': {
          const calculatedPrice = salePrice / (1 + markup / 100);
          setValue(
            'price',
            currencyFormat(calculatedPrice, resource.currency.code, locale)
          );
          break;
        }
        default:
          break;
      }
    });

    return () => subscription.unsubscribe();
  }, [locale, resource.currency.code, setValue, watch]);

  const handlerTypeSelected = (value: ResourceType) => {
    setValue('type', value);
    setFocus('name');
  };
  const handlerUnitSelected = () => {
    setFocus('price');
  };

  const {
    rowRef,
    editable,
    handleInputFocus,
    handleInputBlur,
    handleInputKeyUp,
    handleFormSubmit,
  } = useTableRowForm({
    defaultEditable: create,
    onEnter: () => {
      onCancel?.();
    },
    onOutsideClick: () => {
      // for some reason outside click doesn't trigger blur with form `reset()`
      (document?.activeElement as HTMLInputElement)?.blur();
      onCancel?.();
    },
    onInputFocus: (e) => {
      if (e?.target instanceof HTMLInputElement) {
        if (e.target.value === '') {
          return;
        }
        if (e.target.name === 'markup') {
          e.target.value = `${percentParse(e.target.value, locale)}`;
        }
        if (e.target.name === 'price' || e.target.name === 'salePrice') {
          e.target.value = `${currencyParse(e.target.value, locale)}`;
        }
      }
    },
    onInputCancel: () => {
      reset({
        ...resource,
        markup: `${percentFormat(resource.markup, locale)}`,
        price: `${currencyFormat(
          resource.price,
          resource.currency.code,
          locale
        )}`,
        salePrice: `${currencyFormat(
          resource.salePrice,
          resource.currency.code,
          locale
        )}`,
      });
      onCancel?.();
    },
    onInputBlur: (e) => {
      if (e?.target instanceof HTMLInputElement) {
        if (e.target.name === 'markup') {
          e.target.value = `${percentFormat(e.target.value, locale)}`;
        }
        if (e.target.name === 'price' || e.target.name === 'salePrice') {
          e.target.value = `${currencyFormat(
            parseFloat(e.target.value),
            resource.currency.code,
            locale
          )}`;
        }
      }

      handleFormSubmit();
    },
    onFormSubmit: handleSubmit((formData) => {
      const { id, ...data } = formData;

      if (companyId === undefined) {
        return;
      }

      if (data.name.trim() === '') {
        return;
      }

      if (create) {
        onCancel?.();
        dispatch(
          createResourceRequest({
            companyId,
            temporaryId: `create-${nanoid()}`,
            data: {
              ...data,
              price: currencyParse(data.price, locale) ?? 0,
              markup: percentParse(data.markup, locale) ?? 0,
              salePrice: currencyParse(data.salePrice, locale) ?? 0,
            },
          })
        );
      } else {
        dispatch(
          updateResourceRequest({
            companyId,
            id,
            data: {
              ...data,
              price: currencyParse(data.price, locale) ?? 0,
              markup: percentParse(data.markup, locale) ?? 0,
              salePrice: currencyParse(data.salePrice, locale) ?? 0,
            },
          })
        );
      }
    }),
  });

  return (
    <>
      <div
        ref={rowRef}
        className={cn('manager__resource', { edit: editable, fetching })}
      >
        {fetching && <div className="manager__resource-edit" />}
        <input type="hidden" value={resource.id} {...register('id')} />
        <input
          type="hidden"
          value={resource.resourceFolderId}
          {...register('resourceFolderId')}
        />
        <span
          className="manager__resource-check check"
          onClick={handleCheckboxClick}
        >
          <input
            type="checkbox"
            name="resources"
            value="1"
            checked={selectedResource}
            readOnly
          />
          <label />
        </span>
        <ResourcesCatalogResourceSelect
          editable={editable}
          value={getValues('type')}
          onSelected={handlerTypeSelected}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          register={register('type')}
        />
        <div className="manager__resource-name">
          <input
            type="text"
            placeholder="Ресурс"
            autoFocus={create}
            onFocus={handleInputFocus}
            onKeyUp={handleInputKeyUp}
            {...register('name', {
              setValueAs: (v) => v.trim(),
              onBlur: handleInputBlur,
            })}
          />
        </div>
        <div
          className="manager__resource-unit"
          data-title={t('directory.unit')}
        >
          <TableSelect<Unit, FormData>
            name="unitId"
            control={control}
            listItems={units}
            onSelectFocus={handleInputFocus}
            onSelectBlur={handleInputBlur}
            onSelectChange={handlerUnitSelected}
          />
        </div>
        <div
          className="manager__resource-count"
          data-title={t('directory.price')}
        >
          <input
            type="text"
            onFocus={handleInputFocus}
            onKeyUp={handleInputKeyUp}
            {...register('price', {
              onChange: (e) => {
                setValue('price', e.target.value.replace(/[^\d.]/g, ''));
              },
              // setValueAs: (v) => (v !== '' ? parseFloat(v) : 0),
              onBlur: handleInputBlur,
            })}
          />
        </div>
        <div
          className="manager__resource-price"
          data-title={t('directory.markup')}
        >
          <input
            type="text"
            onFocus={handleInputFocus}
            onKeyUp={handleInputKeyUp}
            {...register('markup', {
              onChange: (e) => {
                setValue('markup', e.target.value.replace(/[^\d.-]/g, ''));
              },
              // setValueAs: (v) => (v !== '' ? parseFloat(v) : 0),
              onBlur: handleInputBlur,
            })}
          />
        </div>
        <div
          className="manager__resource-client"
          data-title={t('directory.for-customer')}
        >
          <input
            type="text"
            onFocus={handleInputFocus}
            onKeyUp={handleInputKeyUp}
            {...register('salePrice', {
              onChange: (e) => {
                setValue('salePrice', e.target.value.replace(/[^\d.]/g, ''));
              },
              // setValueAs: (v) => (v !== '' ? parseFloat(v) : 0),
              onBlur: handleInputBlur,
            })}
          />
        </div>
        {!editable && (
          <div className="manager__resource-remove" onClick={handleDeleteClick}>
            <img src={removeOperationIcon} alt={t('directory.remove') ?? ''} />
          </div>
        )}
      </div>
      {/* {editable && ( */}
      {/*  <div className="outlay__action"> */}
      {/*    <div className="modal__but"> */}
      {/*      <a className="link white border" onClick={handleInputCancel}>Отменить</a> */}
      {/*      <a className="link">Применить</a> */}
      {/*    </div> */}
      {/*  </div> */}
      {/* )} */}
    </>
  );
};

export const ResourcesCatalogResource = memo(ResourcesCatalogResourceRaw);
