import cn from 'classnames';
import checkIcon from '@static/img/icon-check9.svg';
import logo from '@static/img/logo4.svg';
import { FetchStatus } from '@store/constants';
import { useTranslation } from 'react-i18next';

interface IButton {
  name: string;
  status?: FetchStatus;
  onClick?: () => void;
}
function Button({ name, status = 0, onClick }: IButton) {
  const { t } = useTranslation();
  const buttonStatus = {
    0: '',
    1: 'loading',
    2: 'dynamic',
    3: 'error',
  };

  return (
    <button
      className={cn(`link add ${buttonStatus[status]}`)}
      onClick={onClick}
    >
      <span>{t(name)}</span>
      <span>
        <img src={checkIcon} alt="" />
      </span>
      <i>
        <img src={logo} alt="" />
      </i>
    </button>
  );
}
export default Button;
