export enum FetchStatus {
  NotFetched,
  Fetching,
  Fetched,
  Error,
}

export enum EntityStatus {
  New,
  Fetching,
  Fetched,
  Error,
}

export type WithStatus<T> = T & {
  status: EntityStatus;
};
