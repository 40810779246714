import React from 'react';
import {
  Path,
  PathValue,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { percentFormat, percentParse } from '@utils/percent';
import { useCurrentLocale } from '@hooks/useCurrentLocale';

type OfferGeneratorPercentInputProps<FormData extends FieldValues> = {
  name: Path<FormData>;
  register: UseFormRegister<FormData>;
  setValue: UseFormSetValue<FormData>;
};

export const OfferGeneratorPercentInput = <FormData extends FieldValues>({
  name,
  register,
  setValue,
}: OfferGeneratorPercentInputProps<FormData>): JSX.Element => {
  const locale = useCurrentLocale();

  return (
    <input
      type="text"
      placeholder="0"
      onFocus={(e) => {
        setValue(
          name,
          `${percentParse(e.target.value, locale)}` as PathValue<
            FormData,
            Path<FormData>
          >
        );
      }}
      {...register(name, {
        required: true,
        onChange: (e) => {
          const value = e.target.value.replace(/[^\d.]/g, '');

          if (parseFloat(value) > 100) {
            setValue(name, '100' as PathValue<FormData, Path<FormData>>);
          } else {
            setValue(name, value);
          }
        },
        onBlur: (e) => {
          setValue(
            name,
            percentFormat(e.target.value, locale) as PathValue<
              FormData,
              Path<FormData>
            >
          );
        },
      })}
    />
  );
};
