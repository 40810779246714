import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useCurrentLocale } from '@hooks/useCurrentLocale';
import { useFormErrors } from '@hooks/useFormErrors';
import {
  updateObjectRequest,
  resetEditObjectStatus,
} from '@store/companyObjects/actions';
import {
  getCompanyObjectId,
  getEditObjectStatus,
} from '@store/companyObjects/selectors';
import { ReactComponent as IconHuman } from '@static/img/human__ObjectButton.svg';
import { ReactComponent as IconAdd } from '@static/img/add__ObjectButton.svg';
import { ReactComponent as IconBackPack } from '@static/img/backpack__ObjectButton.svg';
import { getCurrencies } from '@store/vocabulary/selectors';
import { currencyFormat, currencyParse } from '@utils/currency';
import PhotoCompany from '@components/PhotoCompany/PhotoCompany';
import ObjectButtonBlock from '@components/ObjectButtonBlock';
import Button from '@components/commons/Button';
import { fetchFullOrganizationsObjectRequest } from '@store/organizations/actions';
import { getProfileCompanyId } from '@store/profile/selectors';
import { FetchStatus } from '@store/constants';
import { CompanyObject } from '@store/companyObjects/slice';
import { Input } from '../CreateObjectForm/Input/index';
import { inputErrors } from '../utils';
import { CreateObjectFormData } from '../CreateObjectForm/types';
import ChoisedContact from '../ChoisedContact';
import ChoisedOrganization from '../ChoisedOrganization';
import { TextArea } from '../CreateObjectForm/TextArea';

type EditObjectFormProps = {
  companyCurrencyCode: string;
  dataObject: CompanyObject | null;
};

export const EditObjectForm: FC<EditObjectFormProps> = ({
  companyCurrencyCode,
  dataObject,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const locale = useCurrentLocale();
  const currencies = useAppSelector(getCurrencies);
  const companyId = useAppSelector(getProfileCompanyId);
  const editObjectStatus = useAppSelector(getEditObjectStatus);

  const currentObjectId = useAppSelector(getCompanyObjectId);

  const [selectedContact, setSelectedContact] = useState(dataObject?.contact);
  const [selectedOrganization, setSelectedOrganization] = useState(
    dataObject?.organization
  );

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors: formErrors },
  } = useForm<CreateObjectFormData>({
    mode: 'onBlur',
    defaultValues: {
      name: dataObject?.name,
      cost: `${dataObject?.cost}`,
      address: dataObject?.address,
      description: dataObject?.description,
      organizationId: dataObject?.organization?.id || '',
      contactId: dataObject?.contact?.id || '',
      filePath: dataObject?.filePath || '',
    },
  });
  const errors = useFormErrors(formErrors);

  const handleContactChange = (value: unknown) => {
    const typedValue = value as CompanyObject['contact'];
    setValue('contactId', typedValue?.id || '');
    setSelectedContact(typedValue);
  };

  const handleOrganizationChange = (value: unknown) => {
    const typedValue = value as CompanyObject['organization'];
    setValue('organizationId', typedValue?.id || '');
    setSelectedOrganization(typedValue);
  };

  const submitHandler = handleSubmit((data, event) => {
    event?.preventDefault();

    const dataWithCostNumber = {
      ...data,
      id: currentObjectId,
      cost: currencyParse(data.cost, locale),
    };

    if (
      currentObjectId &&
      companyId &&
      editObjectStatus === FetchStatus.NotFetched
    ) {
      dispatch(
        updateObjectRequest({
          data: {
            ...dataWithCostNumber,
          },
          companyId,
          id: currentObjectId,
        })
      );
    }
  });

  const handleBlur = (name: keyof CreateObjectFormData) => {
    const value = getValues(name);

    if (value === '') {
      return;
    }

    if (name === 'cost') {
      setValue(
        name,
        currencyFormat(parseFloat(value), companyCurrencyCode, locale)
      );
    } else {
      setValue(name, value.trim());
    }
  };

  const handleCostFocus = (e: SyntheticEvent) => {
    if (e?.target instanceof HTMLInputElement) {
      if (e.target.value === '') {
        return;
      }

      setValue('cost', `${currencyParse(e.target.value, locale)}`);
    }
  };

  const setImageId = (id: string) => {
    setValue('imageId', id);
  };

  useEffect(() => {
    if (editObjectStatus === FetchStatus.Fetched) {
      setTimeout(() => {
        dispatch(resetEditObjectStatus());
      }, 1000);
    }
  }, [dispatch, editObjectStatus]);

  useEffect(() => {
    if (companyId) {
      dispatch(
        fetchFullOrganizationsObjectRequest({
          id: companyId,
          page: 1,
          max: 10000,
        })
      );
    }
  }, [companyId, dispatch]);

  if (!currencies) {
    return null;
  }

  return (
    <form action="#" className="form" onSubmit={submitHandler}>
      <div className="form__cap">
        <h4>{t('object.edit-an-object')}</h4>
      </div>
      <div className="form__group">
        <div className="upload-object">
          <PhotoCompany
            setImageId={setImageId}
            filePath={dataObject?.filePath}
          />
        </div>
        <Input
          title={t('object.objectName')}
          type="text"
          maxLength={191}
          {...register('name', {
            required: true,
            onBlur: () => handleBlur('name'),
          })}
          error={inputErrors('name', errors, t)}
        />
        <Input
          title={t('object.currentPrice')}
          type="text"
          onFocus={handleCostFocus}
          {...register('cost', {
            // required: true,
            // validate: (value) => currencyParse(value, locale) > 0,
            onBlur: () => handleBlur('cost'),
            onChange: (e) => {
              setValue('cost', e.target.value.replace(/[^\d.]/g, ''));
            },
          })}
          error={inputErrors('cost', errors, t)}
        />
        <Input
          title={t('object.objectAddress')}
          type="text"
          {...register('address', {
            onBlur: () => handleBlur('address'),
          })}
          error={inputErrors('address', errors, t)}
        />
      </div>
      <div className="form__cap">
        <h5>{t('organization.contactPerson')}</h5>
      </div>
      {selectedContact ? (
        <ChoisedContact
          firstName={selectedContact?.firstName}
          lastName={selectedContact?.lastName}
          email={selectedContact?.email}
          phone={selectedContact?.phone}
          onDelete={() => handleContactChange(undefined)}
        />
      ) : (
        <ObjectButtonBlock
          oneName={t('object.select-from-database')}
          secondName={t('object.create-new-one')}
          oneLogo={<IconHuman />}
          secondLogo={<IconAdd />}
          type="contact"
          onSelect={handleContactChange}
        />
      )}
      <div className="form__cap">
        <h5>{t('organization.organization')}</h5>
      </div>

      {selectedOrganization?.name ? (
        <ChoisedOrganization
          name={selectedOrganization?.name}
          setValue={setValue}
          onDelete={() => handleOrganizationChange(undefined)}
        />
      ) : (
        <ObjectButtonBlock
          oneName={t('object.select-from-database')}
          secondName={t('object.create-a-new-one')}
          oneLogo={<IconBackPack />}
          secondLogo={<IconAdd />}
          type="organization"
          onSelect={handleOrganizationChange}
        />
      )}
      <div className="form__group">
        <TextArea
          title={t('object.taskDescription')}
          maxLength={200}
          {...register('description', {
            onBlur: () => handleBlur('description'),
          })}
        />
      </div>
      <div className="form__group">
        <Button name={t('object.edit-an-object')} status={editObjectStatus} />
      </div>
    </form>
  );
};
