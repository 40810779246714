import React, { FC, useCallback, useState } from 'react';
import cn from 'classnames';
import { ROOT_FOLDER_ID } from '@constants/folders';
import { useSelectedResources } from '@hooks/useSelectedResources';
import {
  OperationsCatalogSize,
  OperationsCatalog,
} from '@components/OperationsCatalog';
import { OperationFolderId } from '@store/operationFolders/slice';
import { Operation, OperationId } from '@store/operations/slice';
import { ResourceId } from '@store/resources/slice';

export const DirectoryModalOperations: FC = () => {
  const [folderId, setFolderId] = useState(ROOT_FOLDER_ID);

  const [selectedResources, handleSelectResources] = useSelectedResources();
  const selectResources = useCallback(
    (
      operationId: OperationId,
      resourceIds: ResourceId[],
      forceSelected?: boolean
    ) => {
      handleSelectResources(
        resourceIds.map((resourceId) => `${operationId} ${resourceId}`),
        forceSelected
      );
    },
    [handleSelectResources]
  );
  const isSelectedResource = useCallback(
    (operationId: OperationId, resourceId: ResourceId) =>
      selectedResources.includes(`${operationId} ${resourceId}`),
    [selectedResources]
  );
  const isSelectedOperation = useCallback(
    (operation: Operation) =>
      operation.resources.length > 0 &&
      operation.resources.some((resource) =>
        isSelectedResource(operation.id, resource.id)
      ),
    [isSelectedResource]
  );

  const handleChangeFolder = (id: OperationFolderId) => {
    setFolderId(id);
  };

  return (
    <OperationsCatalog
      className={cn('modal-scroll')}
      size={OperationsCatalogSize.Minimal}
      folderId={folderId}
      onChangeFolder={handleChangeFolder}
      selectedResources={selectedResources}
      isSelectedResource={isSelectedResource}
      isSelectedOperation={isSelectedOperation}
      onSelectResources={selectResources}
    />
  );
};
