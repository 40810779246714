import React, { useContext, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { Conversation as ConversationType } from '@store/chat/slice';
import {
  getConversationIds,
  getConversationsOrderByNotRead,
  getCurrentConversationId,
  getFetchStatus,
} from '@store/chat/selectors';
import {
  fetchAcessToConversationRequestAction,
  fetchConversationsRequestAction,
  setActiveConversation,
} from '@store/chat/actions';
import { FetchStatus } from '@store/constants';
import Loader from '@components/commons/Loader';
import WebSocketContext from '@context/WebSocketContext';
import { useRole } from '@hooks/useRole';
import Search from './Search';
import Conversation from './Conversation';

export default function ChatList() {
  const dispatch = useAppDispatch();

  const { isCustomer } = useRole();
  const conversations = useAppSelector((state) =>
    getConversationsOrderByNotRead(state, isCustomer ? 'supplier' : 'company')
  );

  const conversationId = useAppSelector(getCurrentConversationId);

  const { receiveMessage } = useContext(WebSocketContext);

  const fetchStatus = useAppSelector(getFetchStatus);

  const conversationIds = useAppSelector(getConversationIds);

  const handleSelectConversation = (c: ConversationType) => {
    dispatch(setActiveConversation(c));
  };

  useEffect(() => {
    dispatch(fetchConversationsRequestAction());
  }, [dispatch]);

  useEffect(() => {
    if (receiveMessage && !conversationIds.includes(receiveMessage.id))
      dispatch(
        fetchAcessToConversationRequestAction({
          conversationId: receiveMessage.id,
          add: true,
        })
      );
  }, [conversationIds, dispatch, receiveMessage]);
  return (
    <div className="chat__aside">
      {fetchStatus === FetchStatus.Fetching ? (
        <div style={{ marginTop: '50px' }}>
          <Loader />
        </div>
      ) : (
        <>
          <Search />
          {conversations.length === 0 && (
            <small style={{ margin: '20px' }}>Нет активных чатов</small>
          )}
          {conversations.length > 0 && (
            <div className="chat__list">
              <div className="chat__group">
                <div className="chat__group-block" style={{ display: 'block' }}>
                  {conversations.map((e) => {
                    return (
                      <Conversation
                        key={e.id}
                        conversation={e}
                        currentConversationId={conversationId}
                        setActiveConversation={handleSelectConversation}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
