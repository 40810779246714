import { ReactComponent as IconRemove } from '@static/img/icon-remove.svg';
import Calendar from '@components/commons/Calendar';
import { useCurrentLocale } from '@hooks/useCurrentLocale';
import { currencyFormat } from '@utils/currency';
import { RESOURCE_TYPES } from '@constants/resource';
import { ResourceType } from '@store/resources/slice';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.sass';

type Props = {
  itemId: string;
  type: ResourceType;
  itemName: string;
  sectionName: string;
  amount: number;
  price: number;
  cost: number;
  deliveryTime: number;
  currencyCode: string;
  needInvoice: boolean;
  status: string;
  onDeleteItem: (id: string) => void;
};

const OrderModalTableItems = ({
  itemId,
  type,
  itemName,
  sectionName,
  amount,
  price,
  cost,
  deliveryTime,
  currencyCode,
  needInvoice,
  status,
  onDeleteItem,
}: Props) => {
  const { t } = useTranslation();
  const locale = useCurrentLocale();

  const currencyFormatInput = (value: number) => {
    return currencyCode !== undefined
      ? currencyFormat(value, currencyCode, locale)
      : `${value}`;
  };

  return (
    <div className="order__table-item">
      <s className={RESOURCE_TYPES[type].className}>
        {t(RESOURCE_TYPES[type].title)}
      </s>
      <div>
        <h4 title={itemName}>{itemName}</h4>
        <small>{sectionName}</small>
      </div>
      <p>
        <input
          type="text"
          value={amount}
          readOnly
          className={styles.orderTableItemTextOverflow}
          title={`${amount}`}
        />
      </p>
      <p
        className={styles.orderTableItemTextOverflow}
        title={currencyFormatInput(price)}
      >
        {currencyFormatInput(price)}
      </p>
      <p
        className={styles.orderTableItemTextOverflow}
        title={currencyFormatInput(cost)}
      >
        {currencyFormatInput(cost)}
      </p>
      <p>
        {deliveryTime && (
          <div>
            <Calendar
              date={deliveryTime}
              size="small"
              short
              styles={{ paddingRight: '70px' }}
            />
          </div>
        )}
      </p>
      {status === 'ACCEPTED' && !needInvoice && (
        <button
          onClick={() => onDeleteItem(itemId)}
          className={styles.orderRemoveButton}
        >
          <IconRemove />
        </button>
      )}
    </div>
  );
};

export default OrderModalTableItems;
