import { CompanyId } from '@store/company/slice';
import { CompanyObjectId } from '@store/companyObjects/slice';
import { CostEstimateId } from '@store/costEstimates/slice';
import {
  CostEstimateResource,
  CostEstimateResourceId,
  costEstimateResourcesSlice,
} from '@store/costEstimateResources/slice';
import {
  FetchedCostEstimateResource,
  CreateCostEstimateResourceData,
  UpdateCostEstimateResourceData,
} from '@sagas/api/costEstimateResources';
import { ResourceId } from '@store/resources/slice';

export type FetchCostEstimateResourceRequestAction = {
  resourceId: ResourceId;
};

export type FetchCostEstimateResourceSuccessAction = {
  data: CostEstimateResource;
};

export type FetchCostEstimateResourcesRequestAction = {
  companyId: CompanyId;
  objectId: CompanyObjectId;
  costEstimateId: CostEstimateId;
};
export type FetchCostEstimateResourcesSuccessAction = {
  data: FetchedCostEstimateResource[];
};

export type CreateCostEstimateResourceRequestAction = {
  temporaryId: string;
  companyId: CompanyId;
  objectId: CompanyObjectId;
  costEstimateId: CostEstimateId;
  data: CreateCostEstimateResourceData;
};
export type CreateCostEstimateResourceSuccessAction = {
  temporaryId: string;
  companyId: CompanyId;
  objectId: CompanyObjectId;
  costEstimateId: CostEstimateId;
  data: FetchedCostEstimateResource;
};

export type UpdateCostEstimateResourceRequestAction = {
  companyId: CompanyId;
  objectId: CompanyObjectId;
  costEstimateId: CostEstimateId;
  id: CostEstimateResourceId;
  data: UpdateCostEstimateResourceData;
};
export type UpdateCostEstimateResourceSuccessAction = {
  id: CostEstimateResourceId;
  companyId: CompanyId;
  objectId: CompanyObjectId;
  costEstimateId: CostEstimateId;
  data: FetchedCostEstimateResource;
};

export type DeleteCostEstimateResourceRequestAction = {
  companyId: CompanyId;
  objectId: CompanyObjectId;
  costEstimateId: CostEstimateId;
  id: CostEstimateResourceId;
};
export type DeleteCostEstimateResourceSuccessAction = {
  companyId: CompanyId;
  objectId: CompanyObjectId;
  costEstimateId: CostEstimateId;
  id: CostEstimateResourceId;
};

export type CostEstimateResourcesMoveRequestActions = {
  companyId: CompanyId;
  objectId: CompanyObjectId;
  costEstimateId: CostEstimateId;
  objectElementId: string;
  subjectElementId: string;
  toMove: -1 | 1;
};

export const {
  fetchCostEstimateResourceSuccess,
  fetchCostEstimateResourceRequest,
  setErrorDeleteResourceStatus,
  resetDeleteResourceStatus,
  fetchCommercialProposalRequest,
  fetchCommercialProposalSuccess,
  fetchCostEstimateResourcesRequest,
  fetchCostEstimateResourcesSuccess,
  createCostEstimateResourceRequest,
  createCostEstimateResourceSuccess,
  updateCostEstimateResourceRequest,
  updateCostEstimateResourceSuccess,
  deleteCostEstimateResourceRequest,
  deleteCostEstimateResourceSuccess,
  fetchostEstimateResourcesMoveRequest,
} = costEstimateResourcesSlice.actions;
