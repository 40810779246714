import React, { FC, memo, SyntheticEvent, useContext } from 'react';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import { useAppSelector } from '@hooks/redux';
import { useTranslation } from 'react-i18next';
import { useOperationCalculation } from '@hooks/useOperationCalculation';
import { useTableRowForm } from '@hooks/useTableRowForm';
import { Operation, OperationId } from '@store/operations/slice';
import { OperationFolderId } from '@store/operationFolders/slice';
import { UnitId } from '@store/vocabulary/slice';
import { getDefaultUnit, getUnit } from '@store/vocabulary/selectors';
import { EstimateOperationsCatalogContext } from '@components/EstimateOperationsCatalog';
import { ReactComponent as ArrowIcon } from '@static/img/icon-arrow6.svg';
import { currencyFormat } from '@utils/currency';
import { percentFormat } from '@utils/percent';
import { useCurrentLocale } from '@hooks/useCurrentLocale';
import { OperationsCatalogOperationResource } from '../OperationResource';
import './styles.sass';

type OperationsCatalogOperationProps = {
  operation: Operation;
  create?: boolean;
  opened: boolean;
  onCancel?: () => void;
  onOperationOpenChange: (id: OperationId, opened: boolean) => void;
};

type FormData = {
  id: OperationId;
  name: string;
  amount: number;
  unitId: UnitId;
  operationFolderId: OperationFolderId;
};

const OperationsCatalogOperationRaw: FC<OperationsCatalogOperationProps> = ({
  operation,
  create = false,
  opened = false,
  onCancel,
  onOperationOpenChange,
}) => {
  const { t } = useTranslation();
  const locale = useCurrentLocale();
  const { isSelectedOperation, onSelectResources, onOperationChange } =
    useContext(EstimateOperationsCatalogContext);
  const unit = useAppSelector((state) =>
    operation ? getUnit(state, operation.unitId) : getDefaultUnit(state)
  );

  const selectedOperation = isSelectedOperation(operation);

  const handleArrowClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    onOperationOpenChange(operation.id, !opened);
  };

  const { register, handleSubmit, setValue, watch, reset } = useForm<FormData>({
    defaultValues: {
      ...operation,
      amount: 1,
    },
  });

  const operationAmount = watch('amount');
  const { operationPrice, operationMarkup, operationSalePrice } =
    useOperationCalculation(operation);

  const {
    rowRef,
    editable,
    handleInputFocus,
    handleInputBlur,
    handleInputKeyUp,
    handleFormSubmit,
  } = useTableRowForm({
    defaultEditable: create,
    onInputCancel: () => {
      reset({ amount: 1 });
      onCancel?.();
    },
    // onOutsideClick: () => {
    //   if (create) {
    //     onCancel?.();
    //   }
    // },
    onInputBlur: () => {
      handleFormSubmit();
    },
    onFormSubmit: handleSubmit((formData) => {
      const { id, amount } = formData;

      onOperationChange(id, {
        amount,
        price: operationPrice,
        markup: operationMarkup,
        salePrice: operationSalePrice,
      });
    }),
  });

  const handleCheckboxClick = () => {
    handleFormSubmit();
    onSelectResources(
      operation.id,
      operation.resources.map((resource) => resource.id)
    );
  };

  return (
    <>
      <div className="manager__operation" ref={rowRef}>
        <div
          className={cn('manager__operation-item', {
            open: opened,
            edit: editable,
          })}
        >
          <span
            className="manager__operation-check check"
            onClick={handleCheckboxClick}
          >
            <input
              type="checkbox"
              name="resources"
              value="1"
              checked={selectedOperation}
              readOnly
            />
            {!create && <label />}
          </span>
          <div className="manager__operation-slider" onClick={handleArrowClick}>
            <i>
              <ArrowIcon />
            </i>
          </div>
          <div className="manager__operation-name">
            <input type="text" readOnly value={operation.name} />
          </div>
          <div
            className="manager__operation-count"
            data-title={t('directory.quantity')}
          >
            <input
              type="text"
              onFocus={handleInputFocus}
              onKeyUp={handleInputKeyUp}
              {...register('amount', {
                onChange: (e) => {
                  setValue('amount', e.target.value.replace(/[^\d.]/g, ''));
                },
                setValueAs: (v) => parseFloat(v),
                onBlur: handleInputBlur,
              })}
            />
          </div>
          <div
            className="manager__operation-unit"
            data-title={t('directory.unit')}
          >
            <div className="sel">
              <a>{t(`units.${unit?.name}`)}</a>
            </div>
          </div>
          <div
            className="manager__operation-price"
            data-title={t('directory.price')}
          >
            <input
              type="text"
              readOnly
              value={currencyFormat(
                operationPrice,
                operation.currency.code,
                locale
              )}
            />
          </div>
          <div
            className="manager__operation-markup"
            data-title={t('directory.markup')}
          >
            <input
              type="text"
              readOnly
              value={percentFormat(operationMarkup, locale)}
            />
          </div>
          <div
            className="manager__operation-client"
            data-title={t('directory.for-customer')}
          >
            <input
              type="text"
              readOnly
              value={currencyFormat(
                operationSalePrice,
                operation.currency.code,
                locale
              )}
            />
          </div>
        </div>
        <div className={cn('manager__operation-list', { open: opened })}>
          {operation.resources.map((resource) => (
            <OperationsCatalogOperationResource
              key={resource.id}
              operationId={operation.id}
              operation={operation}
              operationResource={resource}
              operationAmount={operationAmount}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export const OperationsCatalogOperation = memo(OperationsCatalogOperationRaw);
