/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

type Message = {
  id: string;
};

export function useWs(host: string) {
  const ws = new WebSocket(host);

  const [socket] = useState(ws);

  const [receiveMessage, setReceiveMessage] = useState<Message>();

  const sendMessageToJson = (msg: Message) => {
    socket.send(JSON.stringify(msg));
  };

  const closeListiner = () => {
    socket.close();
  };

  useEffect(() => {
    socket.addEventListener('message', (message: MessageEvent) => {
      setReceiveMessage(JSON.parse(message.data));
    });
    return () => socket.close();
  }, []);

  return { socket, sendMessageToJson, receiveMessage, closeListiner };
}
