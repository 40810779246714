import { axiosInstanceFile } from '@utils/axios';
import { CSSProperties, useEffect, useState } from 'react';

interface IImage {
  url: string;
  alt?: string;
  className?: string;
  styles?: CSSProperties;
}

export default function Image({ url, alt, className, styles }: IImage) {
  const [blob, setBlob] = useState<unknown>();

  const load = async () => {
    const data2 = await axiosInstanceFile.get(`/private/${url}`, {
      responseType: 'blob',
    });
    setBlob(URL.createObjectURL(data2.data));
  };

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return (
    <img src={blob as string} alt={alt} className={className} style={styles} />
  );
}
