import { createAction } from '@reduxjs/toolkit';
import { chatSlice } from './slice';

export type FetchCreateChatFromRequest = {
  requestId: string;
  supplierId: string;
};

export type FetchCreateChatFromOrder = {
  orderId: string;
};

export type FetchSendMessage = {
  content: string;
  conversationId: string;
};

export type FetchMessages = {
  conversationId: string;
};

export type FetchNewMessage = {
  id: string;
  conversationId: string;
};

export const fetchCreateConversationFromRequestRequestAction =
  createAction<FetchCreateChatFromRequest>(
    'chat/fetchCreateChatByRequestRequestAction'
  );

export const fetchCreateConversationFromOrderRequestAction =
  createAction<FetchCreateChatFromOrder>(
    'chat/fetchCreateChatByOrderRequestAction'
  );

export const fetchConversationsRequestAction = createAction(
  'chat/fetchConversations'
);

export const fetchConversationRequestAction = createAction<{
  conversationId: string;
}>('chat/fetchConversationRequestAction');

export const fetchIsChekConversationRequestAction =
  createAction<FetchCreateChatFromRequest>(
    'chat/fetchIsChekConversationRequestAction'
  );

export const fetchIsChekConversationOrderAction =
  createAction<FetchCreateChatFromOrder>(
    'chat/fetchIsChekConversationOrderAction'
  );

export const fetchConversationFromRequestRequestAction =
  createAction<FetchCreateChatFromRequest>(
    'chat/fetchConversationFromRequestRequestAction'
  );

export const fetchConversationFromOrderRequestAction =
  createAction<FetchCreateChatFromOrder>(
    'chat/fetchConversationFromOrderRequestAction'
  );
export const fetchSendMessageRequestAction = createAction<FetchSendMessage>(
  'chat/fetchSendMessageRequestAction'
);

export const fetchMessagesConversationIdRequestAction =
  createAction<FetchMessages>('chat/fetchMessagesConversationIdRequestAction');

export const fetchNumberOfConversationsWithUnreadedRequestAction = createAction(
  'chat/fetchNumberOfConversationsWithUnreadedRequestAction'
);

export const fetchNewMessagecurrentConversationAction =
  createAction<FetchNewMessage>('chat/fetchNewMessagecurrentConversation');

export const fetchAcessToConversationRequestAction = createAction<{
  conversationId: string;
  add?: boolean;
}>('chat/fetchAcessToConversationRequestAction');

export const {
  setActiveConversation,
  fetchConversationsRequestActionSuccess,
  fetchSendMessageRequestActionSuccess,
  fetchMessagesConversationIdRequestActionSuccess,
  fetchNumberOfConversationsWithUnreadedRequestActionSuccess,
  setReadAllMessageCurrentConversation,
  setIsSendingMessage,
  fetchNewMessagecurrentConversationActionSuccess,
  setFetchStatusLoadingMessages,
  setFetchStatus,
  fetchConversationRequestActionSuccess,
  searchConversationByCompany,
} = chatSlice.actions;
