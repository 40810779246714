import { t } from 'i18next';
import { UseFormSetValue } from 'react-hook-form';
import { ReactComponent as RemoveIcon } from '@static/img/icon-close2.svg';
import { ReactComponent as LinkIcon } from '@static/img/icon-link.svg';
import { CreateObjectFormData } from '../CreateObjectForm/types';
import styles from './styles.module.sass';

type ChoisedOrganizationProps = {
  name?: string;
  setValue: UseFormSetValue<CreateObjectFormData>;
  onDelete: () => void;
};

const ChoisedOrganization = ({
  name,
  setValue,
  onDelete,
}: ChoisedOrganizationProps) => {
  const handleDeleteChoiseOrganization = () => {
    onDelete();
    setValue('organizationId', '');
  };

  return (
    <div className="org">
      <div className="org__item">
        <p>{t('about-object.organization')}</p>
        <div className={styles.orgItemContainerRemoveButton}>
          <i>
            <RemoveIcon onClick={handleDeleteChoiseOrganization} />
          </i>
          <h4 className={styles.orgItemName}>{name}</h4>
        </div>
        <a href="#">
          <LinkIcon className={styles.icon_link} />
        </a>
      </div>
    </div>
  );
};
export default ChoisedOrganization;
