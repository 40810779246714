import { call } from 'redux-saga/effects';
import { ENDPOINTS } from '@config';
import { deleteRequest, getRequest, patchRequest } from '@sagas/api';
import { urlFromTemplate } from '@utils/url';
import { CostEstimateId } from '@store/costEstimates/slice';
import {
  OrdersCustomerData,
  OrderCustomerItems,
  OrderId,
} from '@store/ordersCustomer/slice';

export function* getOrderCustomer(costEstimateId: CostEstimateId) {
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.ORDER_CUSTOMER.GET, { costEstimateId })
  );
  return data as OrdersCustomerData;
}

export function* getOrderCustomerItems(orderId: OrderId) {
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.ORDER_CUSTOMER.GET_ITEMS, { orderId })
  );
  return data as OrderCustomerItems;
}

export function* requestAnInvoice(orderId: OrderId) {
  yield call(
    patchRequest,
    urlFromTemplate(ENDPOINTS.ORDER_CUSTOMER.REQUEST_AN_INVOICE, { orderId })
  );
}

export function* updateOrderCustomerFinishedStatus(
  orderId: OrderId,
  status: 'FINISHED'
) {
  yield call(
    patchRequest,
    urlFromTemplate(ENDPOINTS.ORDER_CUSTOMER.UPDATE_STATUS, { orderId, status })
  );
}

export function* deleteOrderCustomerItem(orderitemId: string) {
  yield call(
    deleteRequest,
    urlFromTemplate(ENDPOINTS.ORDER_CUSTOMER.DELETE_ITEM, {
      orderitemId,
    })
  );
}

export function* deleteOrderCustomerProxyDocument(orderDocumentId: string) {
  yield call(
    deleteRequest,
    urlFromTemplate(ENDPOINTS.ORDER_CUSTOMER.DELETE_PROXY, {
      orderDocumentId,
    })
  );
}
