import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EntityStatus, FetchStatus } from '@store/constants';
import { refreshTokenError, signOutSuccess } from '@store/user/actions';
import {
  FetchOperationFoldersRequestAction,
  FetchOperationFoldersSuccessAction,
  CreateOperationFolderRequestAction,
  CreateOperationFolderSuccessAction,
  CreateOperationFolderErrorAction,
  RenameOperationFolderRequestAction,
  DeleteOperationFolderRequestAction,
} from '@store/operationFolders/actions';

export type OperationFolderId = string;

export type OperationFolder = {
  status: EntityStatus;
  id: OperationFolderId;
  name: string;
};

export type OperationFoldersState = {
  status: FetchStatus;
  items: OperationFolder[];
};

const initialState: OperationFoldersState = {
  status: FetchStatus.NotFetched,
  items: [],
};

export const operationFoldersSlice = createSlice({
  name: 'operationFolders',
  initialState,
  reducers: {
    fetchOperationFoldersRequest: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<FetchOperationFoldersRequestAction>
    ) => {
      state.status = FetchStatus.Fetching;
    },
    fetchOperationFoldersSuccess: (
      state,
      action: PayloadAction<FetchOperationFoldersSuccessAction>
    ) => {
      const { data } = action.payload;

      state.status = FetchStatus.Fetched;
      state.items = [
        ...state.items.filter((item) => item.status !== EntityStatus.Fetched),
        ...data.map((d) => ({ ...d, status: EntityStatus.Fetched })),
      ];
    },
    createOperationFolderRequest: (
      state,
      action: PayloadAction<CreateOperationFolderRequestAction>
    ) => {
      const { data, temporaryId } = action.payload;

      state.items.push({
        ...data,
        id: temporaryId,
        status: EntityStatus.Fetching,
      });
    },
    createOperationFolderSuccess: (
      state,
      action: PayloadAction<CreateOperationFolderSuccessAction>
    ) => {
      const { data, temporaryId } = action.payload;
      const index = state.items.findIndex((item) => item.id === temporaryId);

      state.items[index] = {
        ...data,
        status: EntityStatus.Fetched,
      };
    },
    createOperationFolderError: (
      state,
      action: PayloadAction<CreateOperationFolderErrorAction>
    ) => {
      const { temporaryId } = action.payload;

      state.items = state.items.filter((item) => item.id !== temporaryId);
    },
    renameOperationFolderRequest: (
      state,
      action: PayloadAction<RenameOperationFolderRequestAction>
    ) => {
      const { id, data } = action.payload;
      const index = state.items.findIndex((item) => item.id === id);

      state.items[index].name = data.name;
    },
    deleteOperationFolderRequest: (
      state,
      action: PayloadAction<DeleteOperationFolderRequestAction>
    ) => {
      const { id } = action.payload;

      state.items = state.items.filter((item) => item.id !== id);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signOutSuccess, () => initialState);
    builder.addCase(refreshTokenError, () => initialState);
  },
});

export default operationFoldersSlice.reducer;
