import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from '@reduxjs/toolkit';
import { useAppDispatch } from '@hooks/redux';
import { useCompanyId } from '@hooks/useCompanyId';
import { ROOT_FOLDER_ID } from '@constants/folders';
import { Modal, ModalPosition, ModalSize, ModalType } from '@components/Modal';
import { createResourceFolderRequest } from '@store/resourceFolders/actions';
import { SettingsDirectoryResourcesCreateFolderModalForm } from '@pages/Settings/Directory/Resources/CreateFolderModal/Form';

type SettingsDirectoryResourcesCreateFolderModalProps = {
  visible: boolean;
  onClose: () => void;
};

export const SettingsDirectoryResourcesCreateFolderModal: FC<
  SettingsDirectoryResourcesCreateFolderModalProps
> = ({ visible, onClose }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const companyId = useCompanyId();

  const handleSubmit = (folderName: string) => {
    if (companyId === undefined) {
      return;
    }

    dispatch(
      createResourceFolderRequest({
        companyId,
        temporaryId: `create-${nanoid()}`,
        data: {
          name: folderName,
          level: 1,
          path: '/',
          parentFolderId: ROOT_FOLDER_ID,
        },
      })
    );
    onClose();
  };

  return (
    <Modal
      visible={visible}
      onBackgroundClick={onClose}
      size={ModalSize.Auto}
      type={ModalType.Min}
      position={ModalPosition.Center}
    >
      <div className="modal__close" onClick={onClose} />
      <div className="modal__min-title">
        <h4>{t('directory.create-folder')}</h4>
      </div>
      <SettingsDirectoryResourcesCreateFolderModalForm
        onSubmit={handleSubmit}
        onCancelClick={onClose}
      />
    </Modal>
  );
};
