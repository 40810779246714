/* eslint-disable react/no-this-in-sfc */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useTranslation } from 'react-i18next';
import { NoticeTypes } from '@utils/notifications/notice';
import { Notice } from '@components/Notification/Notice';
import { useFormErrors } from '@hooks/useFormErrors';
import { getProfileCompanyId } from '@store/profile/selectors';
import { getSupplier, getSupplierSaveStatus } from '@store/supplier/selectors';
import { Input } from '@components/EditCompany/Input';
import { inputErrors } from '@components/EditCompany/utils';
import {
  fetchSupplierRequest,
  saveSupplierRequest,
  setImageProfileSupplier,
  setSaveSupplierStatus,
} from '@store/supplier/actions';
import CategorySelect from '@components/commons/CategorySelect';
import BankDetails from './BankDetails';
import { ContactInfo } from './ContactInfo';
// import { DeleteCompanyButton } from '../../../components/DeleteCompanyButton';
import { SaveCompanyButton } from '../../../components/SaveCompanyButton';
import { SettingsFormDataSupplier } from './type';
import { ProfileSupplier } from './ProfileSupplier';
import styles from './styles.module.sass';

export const SettingsSuppliner = () => {
  const dispatch = useAppDispatch();
  const [isShowNotification, setShowNotification] = useState(false);
  const parentCompanyId = useAppSelector(getProfileCompanyId);
  const supplier = useAppSelector(getSupplier);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors: formErrors },
    watch,
  } = useForm<SettingsFormDataSupplier>();

  useEffect(() => {
    if (parentCompanyId !== undefined) {
      dispatch(fetchSupplierRequest({ id: parentCompanyId }));
    }
  }, [dispatch, parentCompanyId, setValue]);

  useEffect(() => {
    if (supplier !== null) {
      reset({
        id: supplier.id,
        name: supplier.name,
        email: supplier.email,
        phone: supplier.phone,
        website: supplier.website,
        countryId: supplier.countryId,
        cityId: supplier.cityId,
        address: supplier.address,
        description: supplier.description,
        bankName: supplier.bankName,
        bankAccount: supplier.bankAccount,
        bic: supplier.bic,
        correspondentAccount: supplier.correspondentAccount,
        categories: supplier.categories,
      });
    }
  }, [supplier, reset]);

  const errors = useFormErrors(formErrors);
  const { t } = useTranslation();

  const companySaveStatus = useAppSelector(getSupplierSaveStatus);
  const handleBlurInput = (name: string) => {
    const trimedValue = String(
      getValues(name as keyof SettingsFormDataSupplier)
    ).trim();

    setValue(name as keyof SettingsFormDataSupplier, trimedValue);
  };

  const saveCompanyHandler = handleSubmit((data) => {
    if (parentCompanyId) dispatch(saveSupplierRequest({ data }));
  });

  const handleKeyDownInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter') {
      event.currentTarget.blur();

      saveCompanyHandler();
    }
  };

  const handleSetUploadFileUrl = (url: string) => {
    dispatch(setImageProfileSupplier({ filePath: url }));
  };

  useEffect(() => {
    if (companySaveStatus) {
      setTimeout(() => {
        setShowNotification(false);
        dispatch(setSaveSupplierStatus(false));
      }, 2000);
      setShowNotification(true);
    }
  }, [companySaveStatus, dispatch]);

  const handleSelectedItemCategory = (
    arr: { id: string; name: string }[] | string[]
  ) => {
    const newArr = arr as string[];
    setValue('categories', newArr);
  };

  return (
    <>
      {isShowNotification && (
        <Notice
          date="now"
          type={NoticeTypes.success}
          timeout={2000}
          onClose={() => setShowNotification(false)}
          message={t('confirm.data-saved')}
        />
      )}
      <form className="content__core" onSubmit={saveCompanyHandler}>
        <input type="hidden" {...register('id')} />
        <div className="content__base">
          <div className="content__block">
            <div className="form__cot">
              <div className="form__cap">
                <h4 className={styles.header}>{t('profile.base-info')}</h4>
                <SaveCompanyButton />
                {/* Ждем когда бэк у себя поправят и дальше уберем коммент */}
                {/* <DeleteCompanyButton /> */}
              </div>
              <div className="form__group">
                <Input
                  {...register('name', {
                    onBlur: () => handleBlurInput('name'),
                  })}
                  title={t('profile.company-name')}
                  error={inputErrors('name', errors, t)}
                  onKeyDown={handleKeyDownInput}
                  maxLength={150}
                />
              </div>
            </div>
            <ContactInfo
              watch={watch}
              register={register}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              onBlur={handleBlurInput}
              onKeyDown={handleKeyDownInput}
            />
            <div className="form__cot" style={{ width: '400px' }}>
              <div className="form__cap">
                <h5 className={styles.productCategoryHeader}>
                  {t('profile.category')}
                </h5>
              </div>
              {supplier && (
                <CategorySelect
                  onSelect={handleSelectedItemCategory}
                  multiselect
                  selected={supplier.categories}
                />
              )}
            </div>
          </div>
        </div>
        <div className="content__aside">
          <ProfileSupplier
            setUploadFile={handleSetUploadFileUrl}
            image={supplier?.filePath}
          />
          <BankDetails
            register={register}
            control={control}
            setValue={setValue}
            errors={errors}
            onBlur={handleBlurInput}
            onKeyDown={handleKeyDownInput}
          />
        </div>
      </form>
    </>
  );
};
