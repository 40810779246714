import { RootState } from '@store';
import { FetchStatus } from '@store/constants';
import { ResourceFolder, ResourceFolderId } from '@store/resourceFolders/slice';
import { ROOT_FOLDER_ID, ROOT_FOLDER_LEVEL } from '@constants/folders';
import { getResourcesByFolder } from '@store/resources/selectors';

export const getRootState = (state: RootState) => state.resourceFolders;
export const getStatus = (state: RootState) => getRootState(state).status;
export const getResourceFolders = (state: RootState) =>
  getRootState(state).items;

export const isResourceFoldersNotFetched = (state: RootState) =>
  getStatus(state) === FetchStatus.NotFetched;
export const isResourceFoldersFetching = (state: RootState) =>
  getStatus(state) === FetchStatus.Fetching;
export const isResourceFoldersFetched = (state: RootState) =>
  getStatus(state) === FetchStatus.Fetched;

export const getRootFolder = () => {
  return {
    id: ROOT_FOLDER_ID,
    name: '',
    level: ROOT_FOLDER_LEVEL,
    path: '',
  } as ResourceFolder;
};

export const getFolder = (state: RootState, id: ResourceFolderId) => {
  if (id === ROOT_FOLDER_ID) {
    return getRootFolder();
  }

  return getResourceFolders(state).find((folder) => folder.id === id);
};

export const getFoldersByPath = (state: RootState, path: string) => {
  return getResourceFolders(state).filter((folder) => folder.path === path);
};

export const getFoldersByLevel = (state: RootState, level: number) => {
  return getResourceFolders(state).filter((folder) => folder.level === level);
};

export const getRootFolders = (state: RootState) => {
  return getFoldersByLevel(state, 1);
};

export const getSubFolders = (state: RootState, id: ResourceFolderId) => {
  const folder = getFolder(state, id);

  if (!folder) {
    return undefined;
  }

  return getFoldersByPath(state, `${folder.path}${folder.id}/`);
};

export const getParentFolder = (state: RootState, id: ResourceFolderId) => {
  const folder = getFolder(state, id);

  if (!folder) {
    return undefined;
  }

  if (folder.level === 1) {
    return getRootFolder();
  }

  const pathIdList = folder.path.split('/').filter((pathId) => pathId !== '');
  const parentFolderId = pathIdList[pathIdList.length - 1];

  return getFolder(state, parentFolderId);
};

export const getBreadcrumbs = (state: RootState, id: ResourceFolderId) => {
  const folder = getFolder(state, id);

  if (!folder) {
    return undefined;
  }

  const pathIdList = folder.path
    .split('/')
    .filter((pathId) => pathId !== '' && pathId !== ROOT_FOLDER_ID);
  const pathFolderList = pathIdList
    .map((pathId) => getFolder(state, pathId))
    .filter(
      (pathFolder): pathFolder is ResourceFolder => pathFolder !== undefined
    );

  return [...pathFolderList, folder];
};

export const getFolderResourcesCount = (
  state: RootState,
  id: ResourceFolderId
) => {
  const subFolders = getSubFolders(state, id);
  let count = 0;

  if (subFolders !== undefined) {
    count += subFolders.reduce((acc, subFolder) => {
      return acc + getFolderResourcesCount(state, subFolder.id);
    }, 0);
  }

  count += getResourcesByFolder(state, id).length;

  return count;
};
