import Image from '@components/commons/Image';
import { TypeSize, setSizeImage } from '@utils/formatImage';
import { ReactComponent as NoUserIcon } from '@static/img/profile6.svg';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { ImageParticipantsConversation } from '@store/chat/slice';

type MessageProps = {
  author: {
    id: string;
    firstName: string;
    lastName: string;
    image: ImageParticipantsConversation;
  } | null;
  time: string;
  message: string;
};
export default function Message({ author, message, time }: MessageProps) {
  const { t } = useTranslation();
  const title = author
    ? `${author.firstName} ${author.lastName}`
    : t('chat.system');
  return (
    <div className="chat__message">
      <i className="chat__message-img">
        {author && (
          <>
            {author?.image?.fileName ? (
              <Image
                url={setSizeImage(author?.image.fileName, TypeSize.avatar)}
              />
            ) : (
              <NoUserIcon />
            )}
          </>
        )}
      </i>
      <div className="chat__message-cot">
        <div className="chat__message-cap">
          <h4>{title}</h4>
          <time>{time}</time>
        </div>
        <div className={cn('chat__message-text', { system: !author })}>
          <p style={{ overflowWrap: 'break-word' }}>{message}</p>
        </div>
      </div>
    </div>
  );
}
