import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchStatus } from '@store/constants';
import { refreshTokenError, signOutSuccess } from '@store/user/actions';
import { Language } from '@store/vocabulary/slice';
import { CompanyId } from '@store/company/slice';
import {
  FetchProfileSuccessAction,
  SaveProfileSuccessAction,
} from '@store/profile/actions';

export type ProfileData = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  post: string;
  currentCompanyId: CompanyId;
  lastLogin: string;
  language: Language;
  filePath: string;
  roles: string[];
  id: string;
};

export type ProfileState = {
  status: FetchStatus;
  profileData: ProfileData | null;
  passwordProfileStatus: FetchStatus;
};

const initialState: ProfileState = {
  status: FetchStatus.NotFetched,
  profileData: null,
  passwordProfileStatus: FetchStatus.NotFetched,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setPhotoProfile: (
      state,
      action: PayloadAction<Pick<ProfileData, 'filePath'>>
    ) => {
      if (state.profileData) {
        state.profileData = {
          ...state.profileData,
          filePath: action.payload.filePath,
        };
      }
    },
    fetchProfileRequest: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetchProfileSuccess: (
      state,
      action: PayloadAction<FetchProfileSuccessAction>
    ) => {
      const { data } = action.payload;

      state.status = FetchStatus.Fetched;
      state.profileData = data;
    },
    saveProfileSuccess: (
      state,
      action: PayloadAction<SaveProfileSuccessAction>
    ) => {
      const { data } = action.payload;

      state.profileData = data;
    },

    setPasswordProfileStatus: (state, action: PayloadAction<FetchStatus>) => {
      state.passwordProfileStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signOutSuccess, () => initialState);
    builder.addCase(refreshTokenError, () => initialState);
  },
});

export default profileSlice.reducer;
