import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { getCostEstimatesUrl, getObjectUrl, getSupplyUrl } from '@pages';
import { useTranslation } from 'react-i18next';
import { getCompanyObjectId } from '@store/companyObjects/selectors';
import { useAppSelector } from '@hooks/redux';
import cn from 'classnames';
import { OptionObject } from '../Options';
import styles from './styles.module.sass';

export const AboutObjectTabs = () => {
  const { t } = useTranslation();
  const objectId = useAppSelector(getCompanyObjectId);

  const location = useLocation();

  const tabs = [
    {
      title: t('tabs.object'),
      href: { pathname: getObjectUrl(objectId), search: location.search },
    },
    {
      title: t('tabs.estimate'),
      href: {
        pathname: getCostEstimatesUrl(objectId),
        search: location.search,
      },
    },
    {
      title: t('tabs.supply'),
      href: { pathname: getSupplyUrl(objectId), search: location.search },
    },
  ];

  return (
    <div className={cn('content__cap', styles.alignItemsCenter)}>
      <div className="tab">
        <ul>
          {tabs.map((tab) => (
            <li key={tab.title}>
              <NavLink
                to={tab.href}
                className={({ isActive }) => (isActive ? 'active' : undefined)}
              >
                {tab.title}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
      {/* TODO: in the process of development */}
      <OptionObject />
    </div>
  );
};
