import {
  all,
  call,
  put,
  SagaReturnType,
  select,
  take,
  takeLeading,
} from 'redux-saga/effects';
import { refreshToken as refreshTokenAPI } from '@sagas/api/auth';
import { getAccessToken, getRefreshToken } from '@store/user/selectors';
import { axiosInstance, RawAxiosRequestConfig } from '@utils/axios';
import { isTokenExpired } from '@utils/jwt';
import {
  refreshTokenRequest,
  RefreshTokenRequestAction,
  refreshTokenSuccess,
  refreshTokenError,
} from '@store/user/actions';
import { Saga } from '@sagas/types';

function* processAccessToken() {
  const accessToken: ReturnType<typeof getAccessToken> = yield select(
    getAccessToken
  );

  if (accessToken && !isTokenExpired(accessToken)) {
    return accessToken;
  }

  const refreshToken: ReturnType<typeof getRefreshToken> = yield select(
    getRefreshToken
  );

  if (refreshToken === undefined) {
    yield put(refreshTokenError());
    return null;
  }

  yield put(refreshTokenRequest({ refreshToken }));

  const action: ReturnType<typeof refreshTokenSuccess> = yield take(
    refreshTokenSuccess
  );
  const { data } = action.payload;

  return data.accessToken;
}

const refreshTokenHandler: Saga<RefreshTokenRequestAction> = function* ({
  payload,
}) {
  const { refreshToken } = payload;

  try {
    const data: SagaReturnType<typeof refreshTokenAPI> = yield call(
      refreshTokenAPI,
      { refreshToken }
    );

    yield put(
      refreshTokenSuccess({
        data: {
          accessToken: data.token,
          refreshToken: data.refreshToken,
        },
      })
    );
  } catch (e) {
    yield put(refreshTokenError());
  }
};

function* prepareConfig(query?: Record<string, string | number>) {
  const accessToken: SagaReturnType<typeof processAccessToken> = yield call(
    processAccessToken
  );

  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: query,
    withCredentials: true,
  } as RawAxiosRequestConfig;
}

export function* postRequest(url: string, params?: unknown) {
  const config: SagaReturnType<typeof prepareConfig> = yield call(
    prepareConfig
  );
  const { data, status } = yield axiosInstance.post(url, params, config);

  return { data, status };
}

export function* postRequestNotAutorization(url: string, params?: unknown) {
  const { data, status } = yield axiosInstance.post(url, params);

  return { data, status };
}

export function* postRequestWithoutAuthorization(
  url: string,
  params?: unknown
) {
  const { data, status } = yield axiosInstance.post(url, params);

  return { data, status };
}

export function* postRequestWithoutRefresh(url: string, params?: unknown) {
  const accessToken: ReturnType<typeof getAccessToken> = yield select(
    getAccessToken
  );
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    withCredentials: true,
  } as RawAxiosRequestConfig;
  const { data, status } = yield axiosInstance.post(url, params, config);

  return { data, status };
}

export function* getRequest(
  url: string,
  query?: Record<string, string | number>
) {
  const config: SagaReturnType<typeof prepareConfig> = yield call(
    prepareConfig,
    query
  );
  const { data, status } = yield axiosInstance.get(url, config);

  return { data, status };
}

export function* getRequestWithoutAuthorization(url: string) {
  const { data, status } = yield axiosInstance.get(url);

  return { data, status };
}

export function* deleteRequest(url: string) {
  const config: SagaReturnType<typeof prepareConfig> = yield call(
    prepareConfig
  );
  const { data, status } = yield axiosInstance.delete(url, config);

  return { data, status };
}

export function* putRequest(url: string, params?: unknown) {
  const config: SagaReturnType<typeof prepareConfig> = yield call(
    prepareConfig
  );
  const { data, status } = yield axiosInstance.put(url, params, config);

  return { data, status };
}

export function* putRequestNotAutorization(url: string, params?: unknown) {
  const { data, status } = yield axiosInstance.put(url, params);

  return { data, status };
}

export function* patchRequest(url: string, params?: unknown) {
  const config: SagaReturnType<typeof prepareConfig> = yield call(
    prepareConfig
  );
  const { data, status } = yield axiosInstance.patch(url, params, config);

  return { data, status };
}

export default function* root() {
  yield all([takeLeading(refreshTokenRequest, refreshTokenHandler)]);
}
