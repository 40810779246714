import Checkbox from '@components/commons/Checkbox';
import { RequestCustomerDataItem } from '@store/requestCustomer/slice';
import TableRequestsSectionItem from '../TableRequestsSectionItem';
import { DataForRender } from '../../type';
import SectionName from '../SectionName/SectionName';

type Props = {
  data: DataForRender;
  isViewSuppliers: boolean;
  selectedIds: string[];
  requestId: string;
  handleAddSectionIds: (ids: string[], checked: boolean) => void;
  handleAddResourceIds: (id: string, checked: boolean) => void;
  handleOpenDeleteItemModal: (id: string) => void;
  handleOpenResponse: (id: string, responseId: string) => void;
};
const TableRequestsSectionObject = ({
  data,
  isViewSuppliers,
  selectedIds,
  requestId,
  handleAddSectionIds,
  handleAddResourceIds,
  handleOpenResponse,
  handleOpenDeleteItemModal,
}: Props) => {
  return (
    <div>
      {Object.entries(data).map(([id, item]) => {
        const itemsForRender = item.items.map((i) => (
          <div
            key={i.id}
            className="obj__supply-item"
            style={{ position: 'relative' }}
          >
            <div className="obj__supply-check">
              <Checkbox
                onChange={(e) =>
                  handleAddResourceIds(i.response?.id, e.currentTarget.checked)
                }
                checked={selectedIds.includes(i.response?.id)}
                disabled={!i.supplier || i.response?.expired}
              />
            </div>
            <TableRequestsSectionItem
              requestId={requestId}
              responseId={i.response?.id}
              type={i.type}
              resourceName={i.name}
              category={i.category?.name}
              amount={i.response?.amount || i.amount}
              unitId={i.response?.unitId || i.unitId}
              resourceParent={i.resourceParent}
              price={i.response?.price || i.price}
              cost={i.response?.cost || i.cost}
              responseExpired={i.response?.expired}
              deliveryTime={i.response?.deliveryTime || i.deliveryTime}
              supplierName={i.supplier?.name}
              supplierId={i.supplier?.id}
              supplierLogo={i.supplier?.image?.fileName}
              sectionName={i.section.name}
              isViewSuppliers={isViewSuppliers}
              handleOpenResponse={handleOpenResponse}
              resourceId={i.id}
              responseUnitId={i.response?.unitId}
              isViewOfferFromTheSupplier={
                !!i.defaultPrice &&
                i.response &&
                i.defaultPrice !== i.response?.price
              }
              numberOfResponses={i.numberOfResponses}
              handleOpenDeleteItemModal={handleOpenDeleteItemModal}
            />
          </div>
        ));
        const sectionItemIds = item.items.reduce(
          (res: string[], sectionItem: RequestCustomerDataItem) => {
            if (sectionItem.supplier && !sectionItem.response?.expired) {
              res.push(sectionItem.response?.id);
            }
            return res;
          },
          []
        );
        return (
          <div key={id}>
            <SectionName
              selectedIds={selectedIds}
              sectionItemIds={sectionItemIds}
              sectionName={item.name}
              isViewSuppliers={isViewSuppliers}
              supplier={item.items[0].supplier}
              supplierLogo={item.items[0].supplier?.image?.fileName}
              requestId={requestId}
              handleAddSectionIds={handleAddSectionIds}
            />
            {itemsForRender}
          </div>
        );
      })}
    </div>
  );
};

export default TableRequestsSectionObject;
