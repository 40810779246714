import React from 'react';
import Slider, { Settings } from 'react-slick';
import login1 from '@static/img/login1.jpg';
import login2 from '@static/img/login2.jpg';
import login3 from '@static/img/login3.jpg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.sass';

const sliderSettings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 1000,
  arrows: false,
  fade: true,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: false,
  pauseOnFocus: false,
  lazyLoad: 'progressive',
} as Settings;

export const SignUpBackground = () => {
  return (
    <Slider className="login__bg" {...sliderSettings}>
      <div>
        <i>
          <img src={login1} />
        </i>
      </div>
      <div>
        <i>
          <img src={login2} />
        </i>
      </div>
      <div>
        <i>
          <img src={login3} />
        </i>
      </div>
    </Slider>
  );
};
