import { call } from 'redux-saga/effects';
import { ENDPOINTS } from '@config';
import { deleteRequest, getRequest, postRequest } from '@sagas/api';
import { urlFromTemplate } from '@utils/url';
import { ResourceId } from '@store/resources/slice';
import { Responses } from '@store/responseSupplier/slice';
import { ResponseId } from '@store/responseSupplier/actions';
import { RequestId } from '@store/requestCustomer/actions';
import { CostEstimateResource } from '@store/costEstimateResources/slice';

export function* getResponseSupplier(resourceId: ResourceId) {
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.RESPONSE_SUPPLIER.GET_LIST_BY_RESOURCE, {
      resourceId,
    })
  );
  return data as Responses;
}

export function* selectSelfDelivery(
  resourceId: ResourceId,
  requestId: RequestId
) {
  const { data } = yield call(
    deleteRequest,
    urlFromTemplate(ENDPOINTS.REQUEST.SELF_DELIVERY, {
      resourceId,
      requestId,
    })
  );
  return data as CostEstimateResource;
}

export function* selectResponseSupplier(
  resourceId: ResourceId,
  responseId: ResponseId
) {
  const { data } = yield call(
    postRequest,
    urlFromTemplate(ENDPOINTS.RESPONSE_SUPPLIER.SELECT_SUPPLIER, {
      resourceId,
      responseId,
    })
  );
  return data as CostEstimateResource;
}
