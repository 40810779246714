import React, {
  FC,
  memo,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FieldErrors, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useOfferGeneratorCalculation } from '@hooks/useOfferGeneratorCalculation';
import { Input, InputSize } from '@components/Form/Input';
import { DatePicker } from '@components/Form/DatePicker';
import { OfferGeneratorItem } from '@components/OfferGenerator/Item';
import { OfferGeneratorSlide } from '@components/OfferGenerator/Slide';
import { OfferGeneratorPercentInput } from '@components/OfferGenerator/PercentInput';
import { OfferGeneratorCurrencyInput } from '@components/OfferGenerator/CurrencyInput';
import { getProfileCompanyId } from '@store/profile/selectors';

import { currencyFormat, currencyParse } from '@utils/currency';
import { percentFormat, percentParse } from '@utils/percent';

import offerImage1 from '@static/img/offer-screen1.png';
import offerImage2 from '@static/img/offer-screen2.png';
import offerImage3 from '@static/img/offer-screen3.png';
import offerImage4 from '@static/img/offer-screen4.png';
import offerImage5 from '@static/img/offer-screen5.png';
import offerImage6 from '@static/img/offer-screen6.png';
import { useCurrentLocaleDate } from '@hooks/useCurrentLocaleDate';
import { format } from 'date-fns';
import {
  createCommercialProposalRequest,
  resetCommercialProposalStatus,
} from '@store/costEstimates/actions';
import {
  getCompletelyCommercialProposal,
  getCurrentCostEstimate,
  isCommercialProposalFetched,
} from '@store/costEstimates/selectors';
import { useCurrentLocale } from '@hooks/useCurrentLocale';
import { getCostEstimateSectionsByCostEstimateId } from '@store/costEstimateSections/selectors';
import { getCompanyCurrency } from '@store/company/selectors';

const offerImages = [
  offerImage1,
  offerImage2,
  offerImage3,
  offerImage4,
  offerImage5,
  offerImage6,
];

type FormData = {
  name: string;
  startWorkAt: string;
  endWorkAt: string;
  content: string;
  clientFullName: string;
  managerFullName: string;
  managerPhone: string;
  includeFullCostEstimate: boolean;
  creationDate: string;
  createdAt: number;
  screenOrdering: number[];
  prePaymentPercent: string;
  postPaymentPercent: string;
  prePayment: string;
  postPayment: string;
};

type OfferGeneratorProps = {
  onCancel: () => void;
};

const OfferGeneratorRaw: FC<OfferGeneratorProps> = ({ onCancel }) => {
  const { t } = useTranslation();
  const locale = useCurrentLocale();

  const commercialProposalFetched = useAppSelector(isCommercialProposalFetched);

  const companyId = useAppSelector(getProfileCompanyId);
  const currentCostEstimate = useAppSelector(getCurrentCostEstimate);
  const completelyCommercialProposal = useAppSelector(
    getCompletelyCommercialProposal
  );

  const sections = useAppSelector((state) => {
    if (currentCostEstimate) {
      return getCostEstimateSectionsByCostEstimateId(
        state,
        currentCostEstimate.id
      );
    }
  });

  const dispatch = useAppDispatch();
  //  TODO: refac
  const currencyCode = useAppSelector(getCompanyCurrency)?.code ?? '';
  // const currencyCode = currency?.code ?? '';

  const [offerScreens, setOfferScreens] = useState([0, 1, 2, 3, 4, 5]);
  const [unixCreatedAt, setUnixCreatedAt] = useState(0);
  const [isShowMobileOfferScreens, setShowMobileOfferScreens] = useState(false);

  const totalCost = currentCostEstimate?.saleCost || 0;

  // TODO: remove offer mock
  const offer = {
    prePayment: Math.floor(totalCost / 2),
    postPayment: totalCost - Math.floor(totalCost / 2),
    prePaymentPercent: (Math.floor(totalCost / 2) / totalCost) * 100,
    postPaymentPercent:
      ((totalCost - Math.floor(totalCost / 2)) / totalCost) * 100,
  };

  const {
    register,
    control,
    setValue,
    watch,
    reset,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      ...offer,
      creationDate: format(new Date(), 'yyyy-MM-dd'),
      endWorkAt: format(new Date(), 'yyyy-MM-dd'),
      startWorkAt: format(new Date(), 'yyyy-MM-dd'),
      prePayment: currencyFormat(offer.prePayment, currencyCode, locale),
      postPayment: currencyFormat(offer.postPayment, currencyCode, locale),
      prePaymentPercent: percentFormat(offer.prePaymentPercent, locale),
      postPaymentPercent: percentFormat(offer.postPaymentPercent, locale),
    },
  });

  const startWorkAtDate = watch('startWorkAt');
  const endWorkAtDate = watch('endWorkAt');

  const handleFormSubmit = handleSubmit((data) => {
    const { creationDate, endWorkAt, startWorkAt } = data;
    const screenOrdering = offerScreens;

    const creationDateFormat = format(new Date(creationDate), 'yyyy-MM-dd');
    const endWorkAtFormat = format(new Date(endWorkAt), 'yyyy-MM-dd');
    const startWorkAtFormat = format(new Date(startWorkAt), 'yyyy-MM-dd');

    if (currentCostEstimate && companyId) {
      dispatch(
        createCommercialProposalRequest({
          data: {
            ...data,
            screenOrdering,
            creationDate: creationDateFormat,
            endWorkAt: endWorkAtFormat,
            startWorkAt: startWorkAtFormat,
            prePayment: currencyParse(data.prePayment, locale),
            postPayment: currencyParse(data.postPayment, locale),
            prePaymentPercent: percentParse(data.prePaymentPercent, locale),
            postPaymentPercent: percentParse(data.postPaymentPercent, locale),
          },
          companyId,
          objectId: currentCostEstimate.buildingObjectId,
          id: currentCostEstimate.id,
        })
      );
    }
  });

  useEffect(() => {
    if (!commercialProposalFetched) return;
    onCancel();
    dispatch(resetCommercialProposalStatus());
  }, [commercialProposalFetched, onCancel, dispatch]);

  const handleCancelClick = (e: SyntheticEvent) => {
    e.preventDefault();
    onCancel();
  };

  const handleSettingSequenceButtonClick = () => {
    setShowMobileOfferScreens(true);
  };

  const handleSettingPresentationButtonClick = () => {
    setShowMobileOfferScreens(false);
  };

  const handleOfferScreenPositionChange = (
    prevIndex: number,
    nextIndex: number
  ) => {
    if (nextIndex < 0 || nextIndex > offerScreens.length - 1) {
      return;
    }
    const offerScreen = offerScreens[prevIndex];
    let newOfferScreens = [
      ...offerScreens.slice(0, prevIndex),
      ...offerScreens.slice(prevIndex + 1),
    ];
    newOfferScreens = [
      ...newOfferScreens.slice(0, nextIndex),
      offerScreen,
      ...newOfferScreens.slice(nextIndex),
    ];

    setOfferScreens(newOfferScreens);
  };

  const getError = (name: keyof FieldErrors<FormData>) => {
    switch (errors?.[name]?.type) {
      case 'required':
        return t('auth.validation-empty');
      case 'pattern':
        switch (name) {
          case 'managerPhone':
            return t('auth.validation-phone-pattern');
          default:
            break;
        }
        break;
      default:
        break;
    }
  };

  const handleCalculationChange = useCallback(
    (
      name:
        | 'prePayment'
        | 'postPayment'
        | 'prePaymentPercent'
        | 'postPaymentPercent',
      value: string
    ) => {
      setValue(name, value);
    },
    [setValue]
  );

  const createdAtTitle = useCurrentLocaleDate(
    unixCreatedAt,
    'd MMM yyyy HH:mm',
    'MMM d yyyy HH:mm'
  );

  const handleBlurInput = (name: keyof FormData) => {
    const trimedValue = String(getValues(name)).trim();

    setValue(name, trimedValue);
  };

  useOfferGeneratorCalculation({
    prePayment: watch('prePayment'),
    postPayment: watch('postPayment'),
    prePaymentPercent: watch('prePaymentPercent'),
    postPaymentPercent: watch('postPaymentPercent'),
    totalCost,
    currencyCode,
    locale,
    onChange: handleCalculationChange,
  });

  useEffect(() => {
    const currentDate = Math.floor(Date.now() / 1000);
    setUnixCreatedAt(currentDate);
  }, []);

  useEffect(() => {
    setValue('endWorkAt', startWorkAtDate);
  }, [setValue, startWorkAtDate]);

  useEffect(() => {
    if (completelyCommercialProposal) {
      const {
        name,
        content,
        clientFullName,
        managerFullName,
        managerPhone,
        screenOrdering,
      } = completelyCommercialProposal;
      reset({
        name: name.trim(),
        content: content.trim(),
        clientFullName: clientFullName.trim(),
        managerFullName: managerFullName.trim(),
        managerPhone: managerPhone.trim(),
        screenOrdering,
        prePayment: currencyFormat(offer.prePayment, currencyCode, locale),
        postPayment: currencyFormat(offer.postPayment, currencyCode, locale),
        prePaymentPercent: percentFormat(offer.prePaymentPercent, locale),
        postPaymentPercent: percentFormat(offer.postPaymentPercent, locale),
      });

      setOfferScreens(screenOrdering);
    }
  }, [
    reset,
    completelyCommercialProposal,
    offer.prePayment,
    offer.postPayment,
    offer.prePaymentPercent,
    offer.postPaymentPercent,
    currencyCode,
    locale,
  ]);

  return (
    <>
      <div className="modal__close" onClick={onCancel}>
        {t('navigation.close')}
      </div>
      <div className="gener">
        <div
          className="gener__base"
          style={{ display: isShowMobileOfferScreens ? 'none' : '' }}
        >
          <div className="gener__title">
            <h2>{t('about-object.generation-offer')}</h2>
            <p>{t('about-object.created', { string: createdAtTitle })}</p>
          </div>
          <form className="gener__form form">
            <div className="form__group">
              <Input
                title={t('about-object.document-name') || ''}
                {...register('name', {
                  required: true,
                  onBlur: () => handleBlurInput('name'),
                })}
                error={getError('name')}
              />
              <DatePicker
                title={t('about-object.start-work') || ''}
                name="startWorkAt"
                control={control}
                error={getError}
                size={InputSize.Medium}
              />
              <DatePicker
                key={endWorkAtDate}
                title={t('about-object.end-work') || ''}
                name="endWorkAt"
                defaultSelected={
                  endWorkAtDate === undefined
                    ? new Date()
                    : new Date(endWorkAtDate)
                }
                fromDate={new Date(startWorkAtDate)}
                control={control}
                error={getError}
                size={InputSize.Medium}
              />
              <Input
                title={t('about-object.offer') || ''}
                {...register('content', {
                  required: true,
                  onBlur: () => handleBlurInput('content'),
                })}
                error={getError('content')}
              />
              <Input
                title={t('about-object.leader') || ''}
                {...register('managerFullName', {
                  required: true,
                  onBlur: () => handleBlurInput('managerFullName'),
                })}
                error={getError('managerFullName')}
              />
              <Input
                title={t('about-object.full-name-client') || ''}
                {...register('clientFullName', {
                  required: true,
                  onBlur: () => handleBlurInput('clientFullName'),
                })}
                error={getError('clientFullName')}
              />
              <Input
                title={t('about-object.leader-number') || ''}
                {...register('managerPhone', {
                  required: true,
                  maxLength: 30,
                  onBlur: () => handleBlurInput('managerPhone'),
                })}
                error={getError('managerPhone')}
                size={InputSize.Medium}
              />
              <DatePicker
                title={t('about-object.creation-date') || ''}
                fromDate={new Date(0)}
                name="creationDate"
                control={control}
                error={getError}
                size={InputSize.Medium}
              />
            </div>
          </form>
          <div className="gener__title">
            <h4>{t('about-object.current-estimate')}</h4>
          </div>
          <div className="gener__outlay">
            {sections?.map((section, index) => (
              <OfferGeneratorItem
                key={section.id}
                name={section.name}
                items={section.items}
                count={index + 1}
              />
            ))}

            <div className="gener__outlay-check">
              <div className="check">
                <input
                  type="checkbox"
                  {...register('includeFullCostEstimate')}
                />
                <label>{t('about-object.full-estimate')}</label>
              </div>
            </div>
            <div className="gener__outlay-total">
              <p>{t('about-object.estimate-total-cost')}</p>
              <b>{currencyFormat(totalCost, currencyCode, locale)}</b>
            </div>
          </div>
          <div className="gener__title">
            <h4>{t('about-object.graph')}</h4>
          </div>
          <div className="gener__chart">
            <div className="gener__chart-total">
              <p>{t('about-object.estimate-total-cost')}</p>
              <b>{currencyFormat(totalCost, currencyCode, locale)}</b>
            </div>
            <div className="gener__chart-input">
              <div className="gener__chart-item">
                <OfferGeneratorCurrencyInput<FormData>
                  name="prePayment"
                  register={register}
                  setValue={setValue}
                  title={t('about-object.prepaid') || ''}
                  currency={currencyCode}
                  locale={locale}
                />
                <OfferGeneratorPercentInput<FormData>
                  name="prePaymentPercent"
                  register={register}
                  setValue={setValue}
                />
              </div>
              <div className="gener__chart-item">
                <OfferGeneratorCurrencyInput<FormData>
                  name="postPayment"
                  register={register}
                  setValue={setValue}
                  title={t('about-object.postpaid') || ''}
                  currency={currencyCode}
                  locale={locale}
                />
                <OfferGeneratorPercentInput<FormData>
                  name="postPaymentPercent"
                  register={register}
                  setValue={setValue}
                />
              </div>
            </div>
          </div>
          <div className="gener__but">
            <a
              className="link transparent border mob"
              onClick={handleSettingSequenceButtonClick}
            >
              {t('about-object.set-order')}
            </a>
            <a className="link desc" onClick={handleFormSubmit}>
              <span>
                <b>{t('about-object.generate-offer')}</b>
                <p>{t('about-object.to-presentation')}</p>
              </span>
            </a>
            <a className="link transparent border" onClick={handleCancelClick}>
              {t('about-object.cancel')}
            </a>
          </div>
        </div>
        <div
          className="gener__aside"
          style={{ display: isShowMobileOfferScreens ? 'block' : '' }}
        >
          <div className="gener__title">
            <h2> {t('about-object.structure')}</h2>
            <p>{t('about-object.order-slide')}</p>
          </div>
          <div className="gener__slides">
            {offerScreens.map((offerScreen, index) => (
              <OfferGeneratorSlide
                key={offerScreen}
                index={index}
                imageNumber={offerScreen + 1}
                imageSrc={offerImages[offerScreen]}
                onPositionChange={handleOfferScreenPositionChange}
              />
            ))}
          </div>
          <div className="gener__but">
            <a
              className="link transparent border mob"
              onClick={handleSettingPresentationButtonClick}
            >
              {t('about-object.presentation-setting')}
            </a>
            <a className="link desc">
              <span>
                <b>{t('about-object.create-offer')}</b>
                <p>{t('about-object.to-presentation')}</p>
              </span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export const OfferGenerator = memo(OfferGeneratorRaw);
