import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import {
  copyEstimateRequestAction,
  setActiveCreateNewCostEstimate,
} from '@store/costEstimates/actions';
import { getCompanyObjectId } from '@store/companyObjects/selectors';

import { ReactComponent as IconAdd } from '@static/img/icon-add3.svg';
import { ReactComponent as IconSettings } from '@static/img/icon-sett.svg';
import { ReactComponent as IconRemove } from '@static/img/icon-remove5.svg';
import { ReactComponent as IconCopy } from '@static/img/copyEstimate.svg';

import { getCompanyId } from '@store/company/selectors';
import IconButton from '@components/commons/IconButton';
import { useSearchParams } from 'react-router-dom';

export type CostEstimatesButtonsListProps = {
  active?: boolean;
  onSettingsClick?: () => void;
  onDeleteClick?: () => void;
};

// TODO: delete <a style> blocks
export const CostEstimatesButtonsList: FC<CostEstimatesButtonsListProps> = ({
  active = true,
  onSettingsClick,
  onDeleteClick,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const companyId = useAppSelector(getCompanyId);
  const objectId = useAppSelector(getCompanyObjectId);

  const [searchParams] = useSearchParams();
  const costEstimateIdFromQuery = searchParams.get('costEstimateId');

  const handleAddEstimateClick = () => {
    dispatch(setActiveCreateNewCostEstimate(true));
  };

  const handleCopyEstimateClick = () => {
    if (companyId && costEstimateIdFromQuery)
      dispatch(
        copyEstimateRequestAction({
          companyId,
          id: costEstimateIdFromQuery,
          objectId,
        })
      );
  };

  const handleSettingsClick = () => {
    onSettingsClick?.();
  };

  return (
    <div className={cn('obj__cap-but', { opacity: !active })}>
      <IconButton
        onClick={handleAddEstimateClick}
        title={t('about-object.add-estimate') ?? ''}
      >
        <IconAdd title={t('about-object.add-estimate') ?? ''} />
      </IconButton>

      <IconButton
        onClick={handleCopyEstimateClick}
        title={t('about-object.copy-estimate') ?? ''}
      >
        <IconCopy title={t('about-object.copy-estimate') ?? ''} />
      </IconButton>

      <IconButton
        onClick={handleSettingsClick}
        title={t('directory.catalog') ?? ''}
      >
        <IconSettings title={t('directory.catalog') ?? ''} />
      </IconButton>

      <IconButton
        onClick={onDeleteClick}
        title={t('about-object.delete-estimate') ?? ''}
      >
        <IconRemove title={t('about-object.delete-estimate') ?? ''} />
      </IconButton>
    </div>
  );
};
