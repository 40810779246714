import { call } from 'redux-saga/effects';
import { ENDPOINTS } from '@config';
import { deleteRequest, getRequest, patchRequest } from '@sagas/api';
import { urlFromTemplate } from '@utils/url';
import { StatusOrder } from '@utils/enums';
import {
  FetchDeleteOrderItem,
  Order,
  OrderList,
} from '@store/orderSupplier/slice';
import {
  FetchOrder,
  FetchOrderStatus,
  FetchUpdateOrderInvoiceValidity,
} from '@store/orderSupplier/actions';

export function* getOrdersByStatus(params: {
  status: keyof typeof StatusOrder;
}) {
  const { status } = params;
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.SUPPLIER.ORDER.GETBYSTATUS, { status })
  );

  return data as Order[];
}

export function* updateStatusOrder(params: FetchOrderStatus) {
  const { orderId, status } = params;
  yield call(
    patchRequest,
    urlFromTemplate(ENDPOINTS.SUPPLIER.ORDER.UPDATESTATUS, {
      status,
      orderId,
    })
  );
}

export function* getOrdersById(params: FetchOrder) {
  const { orderId } = params;
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.SUPPLIER.ORDER.ORDER, { orderId })
  );

  return data as Order;
}

export function* getOrderItemsById(params: FetchOrder) {
  const { orderId } = params;
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.SUPPLIER.ORDER.GETITEMS, { orderId })
  );

  return data as OrderList;
}

export function* deleteOrderById(params: FetchOrder) {
  const { orderId } = params;
  yield call(
    deleteRequest,
    urlFromTemplate(ENDPOINTS.SUPPLIER.ORDER.ORDER, { orderId })
  );
}

export function* deleteOrderItemById(
  params: Pick<FetchDeleteOrderItem, 'orderItemId'>
) {
  const { orderItemId } = params;
  yield call(
    deleteRequest,
    urlFromTemplate(ENDPOINTS.SUPPLIER.ORDER.DELETEITEM, { orderItemId })
  );
}

export function* updateOrderValidityInvoice(
  params: FetchUpdateOrderInvoiceValidity
) {
  const { invoiceValidity, orderId } = params;
  yield call(
    patchRequest,
    urlFromTemplate(ENDPOINTS.SUPPLIER.ORDER.UPDATEVALIDITYINVOICE, {
      orderId,
    }),
    { invoiceValidity }
  );
}
