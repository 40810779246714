import ObjectsClientCompany from '@components/ClientCompany/ObjectsClientCompany';
import { Modal, ModalSize } from '@components/Modal';
import Tabs from '@components/commons/Tabs';
import { ObjectModal } from '@pages/Dashboard/ObjectModal';
import EditContactForm from '@components/ClientCompany/EditContactForm';
import EditOrganizationForm from '@components/ClientCompany/EditOrganizationForm';
import { Dispatch, RefObject, SetStateAction, useState } from 'react';
import { FullContacts } from '@store/contacts/slice';
import { FullOrganizations } from '@store/organizations/slice';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getObjectUrl } from '@pages';
import { setCurrentCompanyObjectId } from '@store/companyObjects/actions';
import { useAppDispatch } from '@hooks/redux';
import ClientsTable from './ClientsTable';
import CompanyTable from './CompanyTable';

type ClientCompanyProps = {
  type: string;
  setType: Dispatch<SetStateAction<string>>;
  onContactsPageChange: Dispatch<SetStateAction<number>>;
  onOrganizationsPageChange: Dispatch<SetStateAction<number>>;
  contactsForRender: FullContacts[];
  organizationsForRender: FullOrganizations[];
  totalContacts: number;
  totalOrganizations: number;
  clientsRef: RefObject<InfiniteScroll>;
  companyRef: RefObject<InfiniteScroll>;
};
const ClientCompany = ({
  type,
  contactsForRender,
  organizationsForRender,
  totalContacts,
  totalOrganizations,
  setType,
  onContactsPageChange,
  onOrganizationsPageChange,
  clientsRef,
  companyRef,
}: ClientCompanyProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const firstTabs = [
    { title: t('company.clients'), value: 'clients' },
    { title: t('company.companies'), value: 'company' },
  ];

  const secondTabs = [
    { title: t('company.profile'), value: 'profile' },
    { title: t('object.objects'), value: 'objects' },
  ];

  const [isViewChoise, setIsViewChoise] = useState(false);
  const [currentClientOrCompanyId, setCurrentClientOrCompanyId] = useState('');
  const [tab, setTab] = useState('profile');

  const handleChoiseClose = () => setIsViewChoise(false);

  const handleSetItemId = (id: string) => {
    setIsViewChoise(true);
    setCurrentClientOrCompanyId(id);
  };

  const handleTransitionObject = (id: string) => {
    dispatch(setCurrentCompanyObjectId(id));
    navigate(getObjectUrl(id));
  };

  const renderModalContent = () => {
    if (type === 'clients') {
      if (tab === 'objects') {
        return (
          <ObjectsClientCompany
            type="clientObjects"
            clientOrOrganizationId={currentClientOrCompanyId}
            handleTransitionObject={handleTransitionObject}
          />
        );
      }
      return <EditContactForm onClose={handleChoiseClose} />;
    }

    if (tab === 'objects') {
      return (
        <ObjectsClientCompany
          type="organizationObjects"
          clientOrOrganizationId={currentClientOrCompanyId}
          handleTransitionObject={handleTransitionObject}
        />
      );
    }
    return <EditOrganizationForm onClose={handleChoiseClose} />;
  };

  return (
    <>
      <div className="content__block">
        <div className="client">
          <div className="top" style={{ justifyContent: 'space-between' }}>
            <Tabs
              listItems={firstTabs}
              onChange={setType}
              activeTab={type}
              className="tab border"
            />
          </div>
          {type === 'clients' && (
            <ClientsTable
              handleSetItemId={handleSetItemId}
              onContactsPageChange={onContactsPageChange}
              totalContacts={totalContacts}
              contactsForRender={contactsForRender}
              clientsRef={clientsRef}
            />
          )}
          {type === 'company' && (
            <CompanyTable
              handleSetItemId={handleSetItemId}
              onOrganizationsPageChange={onOrganizationsPageChange}
              totalOrganizations={totalOrganizations}
              organizationsForRender={organizationsForRender}
              companyRef={companyRef}
            />
          )}
        </div>
      </div>
      <Modal
        visible={isViewChoise}
        size={ModalSize.Auto}
        onBackgroundClick={handleChoiseClose}
      >
        <div className="modal__cap">
          <Tabs
            activeTab={tab}
            listItems={secondTabs}
            onChange={setTab}
            className="tab border switch"
          />
        </div>
        <ObjectModal onClose={handleChoiseClose}>
          {renderModalContent()}
        </ObjectModal>
      </Modal>
    </>
  );
};

export default ClientCompany;
