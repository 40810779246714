import { FC } from 'react';
import { ReactComponent as IconError } from '@static/img/icon-error.svg';
import { ReactComponent as IconSuccess } from '@static/img/icon-check2.svg';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { FetchStatus } from '@store/constants';
import Loader from '../../Loader';
import styles from './styles.module.sass';

export type ConfirmModalProps = {
  confirmTitle: string;
  status: FetchStatus;
  confirmButtonClassName?: string;
  onCancel: () => void;
  onConfirm: () => void;
};

export const ConfirmModal: FC<ConfirmModalProps> = ({
  confirmTitle,
  status,
  confirmButtonClassName,
  onCancel,
  onConfirm,
}) => {
  const { t } = useTranslation();

  const objStatus = {
    notFetched: status === FetchStatus.NotFetched,
    fetched: status === FetchStatus.Fetched,
    fetching: status === FetchStatus.Fetching,
    error: status === FetchStatus.Error,
  };

  return (
    <>
      <div className="modal__min">
        <div className="modal__close" onClick={onCancel} />
        <div className="modal__min-title center">
          <h4>{t('confirm.attention')}</h4>
          {objStatus.notFetched && <p>{confirmTitle}</p>}
          {objStatus.fetching && <Loader />}
          {objStatus.fetched && (
            <p className={styles.imageContainer}>
              <i className={styles.image}>
                <IconSuccess />
              </i>
              {t('confirm.successfully')}
            </p>
          )}
          {objStatus.error && (
            <p className={styles.imageContainer}>
              <i className={styles.image}>
                <IconError />
              </i>
              {t('errors.oops-something-went-wrong')}
            </p>
          )}
        </div>
        <div className="modal__min-submit">
          {!objStatus.fetched && (
            <button
              className="link modal-close transparent border"
              onClick={onCancel}
            >
              {t('confirm.cancel')}
            </button>
          )}
          <button
            className={cn(
              'link',
              objStatus.fetched ? 'bg-success' : confirmButtonClassName
            )}
            onClick={objStatus.fetched ? onCancel : onConfirm}
            disabled={objStatus.fetching || objStatus.error}
          >
            {t('confirm.confirm')}
          </button>
        </div>
      </div>
    </>
  );
};
