import React, { FC, memo, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import arrowIcon from '@static/img/icon-downSection.svg';

import { nanoid } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useTableRowForm } from '@hooks/useTableRowForm';
import { useCurrentLocale } from '@hooks/useCurrentLocale';
import { EstimateContext } from '@components/Estimate';
import { EstimateOperation } from '@components/Estimate/List/Operation';
import {
  CostEstimateSectionId,
  CostEstimateSection,
} from '@store/costEstimateSections/slice';
import { getDefaultUnit } from '@store/vocabulary/selectors';
import { getCostEstimateOperations } from '@store/costEstimateOperations/selectors';
import {
  createCostEstimateSectionRequest,
  moveCostEstimateRequest,
  updateCostEstimateSectionRequest,
} from '@store/costEstimateSections/actions';
import addIcon from '@static/img/icon-add6.svg';
import removeIcon from '@static/img/icon-remove-blue.svg';
import { getCurrentCostEstimate } from '@store/costEstimates/selectors';
import { currencyFormat } from '@utils/currency';
import { EntityStatus } from '@store/constants';
import './styles.sass';
import { useSearchParams } from 'react-router-dom';

export type EstimateSectionProps = {
  section: CostEstimateSection;
  sectionOrdering: number;
  onDeleteSection: (id: CostEstimateSectionId) => void;
  create?: boolean;
  onCancel?: () => void;
  onDeleteOperation: (id: string) => void;
  onDeleteResource: (id: string) => void;
};

type FormData = {
  id: CostEstimateSectionId;
  name: string;
};

export const moveUp = -1;
export const moveDown = 1;

const EstimateSectionRaw: FC<EstimateSectionProps> = ({
  section,
  sectionOrdering,
  create = false,
  onDeleteSection,
  onCancel,
  onDeleteOperation,
  onDeleteResource,
}) => {
  const { companyId, objectId } = useContext(EstimateContext);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [createOperation, setCreateOperation] = useState(false);

  const { register, handleSubmit, watch, reset } = useForm<FormData>({
    defaultValues: section,
  });
  const title = watch('name');
  const defaultUnit = useAppSelector(getDefaultUnit);
  const costEstimate = useAppSelector(getCurrentCostEstimate);
  const operations = useAppSelector(getCostEstimateOperations);
  const locale = useCurrentLocale();
  const fetching = section.status === EntityStatus.Fetching;

  const [searchParams] = useSearchParams();
  const costEstimateIdFromQuery = searchParams.get('costEstimateId');

  const {
    rowRef,
    editable,
    handleInputClick,
    handleInputFocus,
    handleInputBlur,
    handleInputCancel,
    handleInputKeyUp,
    handleFormSubmit,
  } = useTableRowForm({
    defaultEditable: create,
    onInputCancel: () => {
      reset(section);
      onCancel?.();
    },
    onEnter: () => {
      onCancel?.();
    },
    onOutsideClick: () => {
      // for some reason outside click doesn't trigger blur with form `reset()`
      (document?.activeElement as HTMLInputElement)?.blur();
      onCancel?.();
    },
    onInputBlur: () => {
      handleFormSubmit();
    },
    onFormSubmit: handleSubmit((formData) => {
      const { id, ...data } = formData;

      if (data.name.trim() === '') {
        handleInputCancel();
        return;
      }

      if (costEstimateIdFromQuery) {
        if (create) {
          onCancel?.();
          dispatch(
            createCostEstimateSectionRequest({
              companyId,
              objectId,
              costEstimateId: costEstimateIdFromQuery,
              temporaryId: `create-${nanoid()}`,
              data,
            })
          );
        } else {
          dispatch(
            updateCostEstimateSectionRequest({
              companyId,
              objectId,
              costEstimateId: costEstimateIdFromQuery,
              id,
              data: {
                name: data.name,
              },
            })
          );
        }
      }
    }),
  });

  useEffect(() => {
    reset(section);
  }, [section, reset]);

  const handleAddOperationClick = () => {
    setCreateOperation(true);
  };

  const handleAddOperationCancel = () => {
    setCreateOperation(false);
  };

  const handleMove = (position: 1 | -1) => {
    if (costEstimateIdFromQuery) {
      dispatch(
        moveCostEstimateRequest({
          toMove: position,
          objectElementId: section.id,
          companyId,
          objectId,
          costEstimateId: costEstimateIdFromQuery,
          orderingObjectElement: section.ordering,
        })
      );
    }
  };

  return (
    <div
      ref={rowRef}
      className={cn('outlay__object', {
        add: create && editable,
        fetching,
      })}
    >
      <div className={cn('outlay__title', { edit: !create && editable })}>
        <div className="outlay__title-sort">
          <i className="up">
            <img
              src={arrowIcon}
              className="svg"
              onClick={() => handleMove(moveUp)}
            />
          </i>
          <i className="down">
            <img
              src={arrowIcon}
              className="svg"
              onClick={() => handleMove(moveDown)}
            />
          </i>
        </div>
        <u className="outlay__title-edit" onClick={handleInputClick} />

        {!editable && (
          <button
            onClick={() => onDeleteSection(section.id)}
            title={t('estimate.delete-a-section') ?? ''}
            className="outlay__title-remove-button"
          >
            <i>
              <img src={removeIcon} />
            </i>
          </button>
        )}

        {/* <i className="outlay__title-remove">
          <img src={removeIcon} />
        </i> */}
        {!create && <s className="outlay__title-number">{sectionOrdering}.</s>}
        <div className="outlay__title-name">
          <span>
            <b>{title}</b>
          </span>
          {editable && (
            <input
              type="text"
              autoFocus
              placeholder={t('estimate.section-name') ?? ''}
              onFocus={handleInputFocus}
              onKeyUp={handleInputKeyUp}
              {...register('name', {
                onBlur: handleInputBlur,
              })}
            />
          )}
        </div>
        <p className="outlay__title-price">
          {costEstimate?.currencyCode !== undefined
            ? currencyFormat(section.cost, costEstimate?.currencyCode, locale)
            : section.cost}
        </p>
        <p className="outlay__title-price">
          {costEstimate?.currencyCode !== undefined
            ? currencyFormat(
                section.saleCost,
                costEstimate?.currencyCode,
                locale
              )
            : section.saleCost}
        </p>
      </div>
      {!create && (
        <div className="outlay__block">
          {operations
            .filter((o) => o.sectionId === section.id)
            .sort((a, b) => a.ordering - b.ordering)
            .map((operation, index) => (
              <EstimateOperation
                key={operation.id}
                section={section}
                sectionOrdering={sectionOrdering}
                operation={operation}
                operationOrdering={index + 1}
                onDeleteOperation={onDeleteOperation}
                onDeleteResource={onDeleteResource}
              />
            ))}
          {createOperation && (
            <EstimateOperation
              create
              section={section}
              sectionOrdering={sectionOrdering}
              operation={{
                status: EntityStatus.New,
                id: '',
                sectionId: section.id,
                name: '',
                unitId: defaultUnit.id,
                amount: 1,
                price: 0,
                salePrice: 0,
                cost: 0,
                saleCost: 0,
                markup: 0,
                ordering: 0,
              }}
              operationOrdering={operations.length + 1}
              onCancel={handleAddOperationCancel}
              onDeleteOperation={onDeleteOperation}
              onDeleteResource={onDeleteResource}
            />
          )}
          {!createOperation && (
            <div
              className="outlay__add-operation"
              onClick={handleAddOperationClick}
            >
              <img src={addIcon} alt={t('estimate.add-operation') ?? ''} />
              {t('estimate.add-operation')}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const EstimateSection = memo(EstimateSectionRaw);
