/* eslint-disable import/no-cycle */
import { createAction } from '@reduxjs/toolkit';
import { ResourceId } from '@store/resources/slice';
import { RequestId } from '@store/requestCustomer/actions';
import { CostEstimateResource } from '@store/costEstimateResources/slice';
import { responseSupplierSlice } from './slice';

export type ResponseId = string;

export type SelectSelfDeliveryRequestAction = {
  resourceId: ResourceId;
  requestId: RequestId;
  // временно
  withUpdate: 'updateCostEstimate' | 'updateSupply';
};

export type SelectSelfDeliverySuccessAction = {
  data: CostEstimateResource;
};

export type SelectResponseSupplierRequestAction = {
  resourceId: ResourceId;
  responseId: ResponseId;
  // временно
  requestId: RequestId;
  withUpdate: 'updateCostEstimate' | 'updateSupply';
};

export type SelectResponseSupplierSuccessAction = {
  data: CostEstimateResource;
};

export type FetchResponseSupplierRequestAction = {
  resourceId: string;
};

export const selectSelfDeliveryRequestAction =
  createAction<SelectSelfDeliveryRequestAction>(
    'responseSupplier/selectSelfDeliveryRequestAction'
  );

export const selectResponseSupplierRequestAction =
  createAction<SelectResponseSupplierRequestAction>(
    'responseSupplier/selectResponseSupplierRequestAction'
  );

export const {
  fetchResponseSupplierRequest,
  resetSelectSelfDeliveryStatus,
  resetSelectResponseSupplierStatus,
  selectSelfDeliveryRequest,
  selectSelfDeliverySuccess,
  selectResponseSupplierRequest,
  selectResponseSupplierSuccess,
  setStatusResponseSupplierFetching,
  fetchResponseSupplierSuccess,
} = responseSupplierSlice.actions;
