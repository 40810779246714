import { useDispatch } from 'react-redux';
import { useFormErrors } from '@hooks/useFormErrors';
import { Input } from '@pages/Dashboard/ObjectModal/CreateObjectForm/Input';
import { EMAIL_PATTERN } from '@constants/patterns';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ObjectButtonBlock from '@components/ObjectButtonBlock';
import {
  deleteOrganizationRequest,
  resetUpdateOrganizationStatus,
  updateOrganizationRequest,
} from '@store/organizations/actions';
import ResponsibleSelect from '@components/ResponsibleSelect';
import { getCompany } from '@store/company/selectors';
import { useAppSelector } from '@hooks/redux';
import {
  getChoiseFullOrganization,
  getUpdateOrganizationStatus,
} from '@store/organizations/selectors';
import { ReactComponent as IconHuman } from '@static/img/human__ObjectButton.svg';
import { ReactComponent as IconAdd } from '@static/img/add__ObjectButton.svg';
import { phoneFormat } from '@utils/phoneFormat';
import {
  ChangeEvent,
  FormEventHandler,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react';
// import { CompanyObject } from '@store/companyObjects/slice';
import { FetchStatus } from '@store/constants';
import Button from '@components/commons/Button';
import { Contact, ContactId } from '@store/contacts/slice';
// import { CreateObjectFormData } from '../CreateObjectForm/types';
import { SecondaryButton } from '@components/commons/SecondaryButton';
import ChoisedContact from '../../../pages/Dashboard/ObjectModal/ChoisedContact';
import {
  emailErrors,
  inputErrors,
} from '../../../pages/Dashboard/ObjectModal/utils';

type OrganizationFormData = {
  contactId?: ContactId;
  id: string; // to clarify
  name: string;
  organizationForm: string;
  phone: string;
  email: string;
  website?: string;
  responsible: string;
};

type Props = {
  // setValue?: UseFormSetValue<CreateObjectFormData>;
  onClose: () => void;
  // dataObject?: CompanyObject | null;
};

const EditOrganizationForm = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const choisedOrganization = useAppSelector(getChoiseFullOrganization);
  const statusOrganization = useAppSelector(getUpdateOrganizationStatus);
  const companyId = useAppSelector(getCompany)?.id;
  const organizationId = choisedOrganization?.id;

  const [selectedContact, setSelectedContact] = useState(
    choisedOrganization?.contacts[0]
  );

  const {
    register,
    setValue: localSetValue,
    watch,
    getValues,
    trigger,
    formState: { errors: formErrors },
  } = useForm<OrganizationFormData>({
    mode: 'onBlur',
    defaultValues: {
      name: choisedOrganization?.name,
      organizationForm: choisedOrganization?.organizationForm,
      website: choisedOrganization?.website,
      email: choisedOrganization?.email,
      phone: choisedOrganization?.phone,
      contactId: choisedOrganization?.contacts[0]?.id || '',
    },
  });
  const errors = useFormErrors(formErrors);

  const handleContactChange = (value: unknown) => {
    const typedValue = value as Contact | undefined;
    localSetValue('contactId', typedValue?.id || '');
    setSelectedContact(typedValue);
  };

  const handleDeleteClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (companyId && organizationId) {
      dispatch(
        deleteOrganizationRequest({
          companyId,
          id: organizationId,
        })
      );
      onClose();
    }
  };

  const handleChangePhone = (event: ChangeEvent<HTMLInputElement>) => {
    localSetValue('phone', phoneFormat(event.target.value));
  };

  const submit: SubmitHandler<OrganizationFormData> = (data) => {
    const newData = {
      ...data,
      contactId: selectedContact?.id,
    };

    if (
      organizationId &&
      companyId &&
      statusOrganization === FetchStatus.NotFetched
    ) {
      dispatch(
        updateOrganizationRequest({
          data: newData,
          companyId,
          organizationId,
        })
      );
    }
  };

  const handleSubmitForm: FormEventHandler<HTMLFormElement> = async (e) => {
    e?.stopPropagation();
    e.preventDefault();
    const result = await trigger();
    if (result) {
      submit(getValues());
    }
  };
  useEffect(() => {
    if (statusOrganization === FetchStatus.Fetched) {
      setTimeout(() => {
        dispatch(resetUpdateOrganizationStatus());
      }, 1000);
    }
  }, [dispatch, statusOrganization]);

  return (
    <form action="#" className="form" onSubmit={handleSubmitForm}>
      <div className="form__cap">
        <h4>{t('company.company-information')}</h4>
      </div>
      <div className="form__group">
        <Input
          title={t('organization.company-name')}
          maxLength={50}
          type="text"
          {...register('name', {
            required: true,
          })}
          error={inputErrors('name', errors, t)}
        />
        <Input
          title={t('organization.form-organizations')}
          maxLength={200}
          type="text"
          {...register('organizationForm', {
            required: false,
          })}
        />
        <Input
          title={t('company.company-website')}
          maxLength={200}
          type="text"
          {...register('website', {
            required: false,
          })}
        />
        <Input
          title={t('auth.phone')}
          type="text"
          maxLength={100}
          {...register('phone', {
            required: true,
            onChange: handleChangePhone,
            minLength: 7,
          })}
          error={inputErrors('phone', errors, t)}
        />
        <Input
          {...register('email', {
            required: true,
            minLength: 3,
            pattern: EMAIL_PATTERN,
          })}
          maxLength={180}
          title={t('auth.email')}
          error={emailErrors(errors, t)}
        />
        <ResponsibleSelect
          onSelect={(id) => {
            localSetValue('responsible', id);
          }}
          selectedId={watch('responsible')}
        />
      </div>
      <div className="form__cap">
        <h5>{t('object.company-representatives')}</h5>
      </div>
      {watch('contactId', selectedContact?.id) && selectedContact ? (
        <ChoisedContact
          firstName={selectedContact?.firstName}
          lastName={selectedContact?.lastName}
          email={selectedContact?.email}
          phone={selectedContact?.phone}
          onDelete={() => handleContactChange(undefined)}
        />
      ) : (
        <ObjectButtonBlock
          oneName={t('object.select-from-database')}
          secondName={t('object.create-new-one')}
          oneLogo={<IconHuman />}
          secondLogo={<IconAdd />}
          type="contact"
          onSelect={handleContactChange}
        />
      )}
      <div className="form__group">
        <Button
          name={t('organization.change-the-organization')}
          status={statusOrganization}
        />
        <SecondaryButton onClick={handleDeleteClick}>
          {t('organization.remove')}
        </SecondaryButton>
      </div>
    </form>
  );
};

export default EditOrganizationForm;
