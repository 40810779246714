import dayjs from 'dayjs';

export const useDateProgressBar = (
  startDate: number,
  endDate: number,
  unit: 'days' | 'hours' | 'seconds'
) => {
  const startDateLocal = dayjs(startDate);
  const endDateLocal = dayjs(endDate);
  const nowDate = dayjs();
  const diff = endDateLocal.diff(nowDate, unit);

  const onePercent = endDateLocal.diff(startDateLocal, unit) / 100;

  const isExpired = endDateLocal.isBefore(nowDate);
  let progress = 0;

  if (!isExpired && onePercent !== 0) {
    progress = Math.trunc(diff / onePercent);
  }
  return { useDateProgressBar, progress, diff, isExpired };
};
