import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@hooks/redux';
import { useCurrentLocale } from '@hooks/useCurrentLocale';
import { getCurrentCostEstimate } from '@store/costEstimates/selectors';
import { currencyFormat } from '@utils/currency';
import { nanoid } from '@reduxjs/toolkit';
import { sumCostEstimates } from '../utils/sumCostEstimates';
import { sumTotalCost } from '../utils/sumTotalCost';

export const ObjectIndicators = () => {
  const { t } = useTranslation();
  const locale = useCurrentLocale();

  const currentCostEstimate = useAppSelector(getCurrentCostEstimate);

  const headers = [
    t('about-object.article'),
    t('about-object.total-2'),
    t('about-object.people'),
    t('about-object.materials'),
    t('about-object.meh'),
    t('about-object.overhead'),
  ];

  const saleCost = useMemo(
    () => sumCostEstimates(currentCostEstimate, 'saleCost'),
    [currentCostEstimate]
  );
  const cost = useMemo(
    () => sumCostEstimates(currentCostEstimate, 'cost'),
    [currentCostEstimate]
  );

  const total = useMemo(() => sumTotalCost(saleCost, cost), [saleCost, cost]);

  if (!saleCost || !cost) {
    return null;
  }

  return (
    <div className="obj__indicator">
      <div className="obj__indicator-cap">
        {headers.map((header) => (
          <p key={header}>{header}</p>
        ))}
      </div>
      <div className="obj__indicator-item">
        <p>{t('about-object.for-customer')}</p>
        {saleCost.map((item, index) => (
          <p key={nanoid()} data-title={headers[index + 1]}>
            {currentCostEstimate !== null
              ? currencyFormat(item, currentCostEstimate.currencyCode, locale)
              : item}
          </p>
        ))}
      </div>
      <div className="obj__indicator-item">
        <p>{t('about-object.for-self')}</p>
        {cost.map((item, index) => (
          <p key={nanoid()} data-title={headers[index + 1]}>
            {currentCostEstimate !== null
              ? currencyFormat(item, currentCostEstimate.currencyCode, locale)
              : item}
          </p>
        ))}
      </div>
      <div className="obj__indicator-item">
        <p>{t('about-object.profit')}</p>
        {total?.map((item, index) => (
          <p key={nanoid()} data-title={headers[index + 1]}>
            {currentCostEstimate !== null
              ? currencyFormat(item, currentCostEstimate.currencyCode, locale)
              : item}
          </p>
        ))}
      </div>
    </div>
  );
};
