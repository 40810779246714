import { createAction } from '@reduxjs/toolkit';
import {
  ResourceFolderId,
  ResourceFolder,
  resourceFoldersSlice,
} from '@store/resourceFolders/slice';
import { CompanyId } from '@store/company/slice';
import {
  CreateResourceFolderParams,
  RenameResourceFolderParams,
} from '@sagas/api/resourceFolders';

export type FetchResourceFoldersRequestAction = {
  companyId: CompanyId;
};

export type FetchResourceFoldersSuccessAction = {
  companyId: CompanyId;
  data: ResourceFolder[];
};

export type CreateResourceFolderRequestAction = {
  companyId: CompanyId;
  temporaryId: string;
  data: CreateResourceFolderParams;
};
export type CreateResourceFolderSuccessAction = {
  companyId: CompanyId;
  temporaryId: string;
  data: ResourceFolder;
};
export type CreateResourceFolderErrorAction = {
  companyId: CompanyId;
  temporaryId: string;
};

export type RenameResourceFolderRequestAction = {
  companyId: CompanyId;
  id: ResourceFolderId;
  data: RenameResourceFolderParams;
};

export type RenameResourceFolderSuccessAction = {
  companyId: CompanyId;
  id: ResourceFolderId;
};

export type DeleteResourceFolderRequestAction = {
  companyId: CompanyId;
  id: ResourceFolderId;
};

export type DeleteResourceFolderSuccessAction = {
  companyId: CompanyId;
  id: ResourceFolderId;
};

export const renameResourceFolderSuccess =
  createAction<RenameResourceFolderSuccessAction>(
    'resourceFolders/renameResourceFolderSuccess'
  );

export const deleteResourceFolderSuccess =
  createAction<DeleteResourceFolderSuccessAction>(
    'resourceFolders/deleteResourceFolderSuccess'
  );

export const {
  fetchResourceFoldersRequest,
  fetchResourceFoldersSuccess,
  createResourceFolderRequest,
  createResourceFolderSuccess,
  createResourceFolderError,
  renameResourceFolderRequest,
  deleteResourceFolderRequest,
} = resourceFoldersSlice.actions;
