import React, { FC, memo, SyntheticEvent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { ROOT_FOLDER_ID } from '@constants/folders';
import { useAppSelector } from '@hooks/redux';
import { getBreadcrumbs } from '@store/resourceFolders/selectors';
import { ResourceFolder, ResourceFolderId } from '@store/resourceFolders/slice';
import { ResourcesCatalogContext } from '@components/ResourcesCatalog';
import homeIcon from '@static/img/icon-home.svg';

const Home: FC = () => {
  const { t } = useTranslation();
  const { onChangeFolder } = useContext(ResourcesCatalogContext);

  const handleClick = (e: SyntheticEvent) => {
    e.preventDefault();

    onChangeFolder(ROOT_FOLDER_ID);
  };

  return (
    <li>
      <a href="#" onClick={handleClick}>
        <img src={homeIcon} alt={t('directory.root-folder') ?? ''} />
        {t('directory.catalog')}
      </a>
    </li>
  );
};

type RestProps = {
  folders: ResourceFolder[];
};

const Rest: FC<RestProps> = ({ folders }) => {
  const { onChangeFolder } = useContext(ResourcesCatalogContext);
  const [open, setOpen] = useState(false);

  const handleArrowClick = () => {
    setOpen((value) => !value);
  };

  const handleClick = (id: ResourceFolderId) => (e: SyntheticEvent) => {
    e.preventDefault();

    onChangeFolder(id);
  };

  return (
    <li>
      <span className={cn('dropdown', { index: open, open })}>
        <i />
        <i />
        <i />
        <s onClick={handleArrowClick} />
        <ul>
          {folders.map((folder) => (
            <li key={folder.id} onClick={handleClick(folder.id)}>
              <a href="#">{folder.name}</a>
            </li>
          ))}
        </ul>
      </span>
    </li>
  );
};

type FolderProps = {
  folder: ResourceFolder;
};
const Folder: FC<FolderProps> = ({ folder }) => {
  const { onChangeFolder } = useContext(ResourcesCatalogContext);

  const handleClick = (e: SyntheticEvent) => {
    e.preventDefault();

    onChangeFolder(folder.id);
  };

  return (
    <li>
      <a href="#" onClick={handleClick}>
        {folder.name}
      </a>
    </li>
  );
};

type BreadcrumbsType = Record<string, never>;

const Breadcrumbs: FC<BreadcrumbsType> = () => {
  const { folderId } = useContext(ResourcesCatalogContext);

  const breadcrumbs = useAppSelector((state) =>
    getBreadcrumbs(state, folderId)
  );

  if (breadcrumbs === undefined) {
    return (
      <ul className="manager__bread">
        <Home />
      </ul>
    );
  }

  if (breadcrumbs.length < 3) {
    return (
      <ul className="manager__bread">
        <Home />
        {breadcrumbs?.map((folder) => (
          <Folder key={folder.id} folder={folder} />
        ))}
      </ul>
    );
  }

  return (
    <ul className="manager__bread">
      <Home />
      <Folder folder={breadcrumbs[0]} />
      <Rest folders={breadcrumbs.slice(1, breadcrumbs.length - 1)} />
      <Folder folder={breadcrumbs[breadcrumbs.length - 1]} />
    </ul>
  );
};

export const ResourcesCatalogBreadcrumbs = memo(Breadcrumbs);
