import { createSlice } from '@reduxjs/toolkit';

export type ModalControl = {
  isViewChat: boolean;
  isViewChatDrawer: boolean;
  isViewChatSupplierOffersDrawer: boolean;
};
const initialState: ModalControl = {
  isViewChat: false,
  isViewChatDrawer: false,
  isViewChatSupplierOffersDrawer: false,
};

export const modalControlSlice = createSlice({
  name: 'modalControl',
  initialState,
  reducers: {
    setIsViewChat: (state) => {
      state.isViewChat = !state.isViewChat;
    },

    setIsViewChatDrawer: (state) => {
      state.isViewChatDrawer = !state.isViewChatDrawer;
    },
    setIsViewChatDrawerFalse: (state) => {
      state.isViewChatDrawer = false;
    },
    setIsViewChatSuppliersOfferDrawer: (state) => {
      state.isViewChatSupplierOffersDrawer =
        !state.isViewChatSupplierOffersDrawer;
    },
    setIsViewChatSuppliersOfferDrawerFalse: (state) => {
      state.isViewChatSupplierOffersDrawer = false;
    },
  },
});

export default modalControlSlice.reducer;
