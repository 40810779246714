import { call } from 'redux-saga/effects';
import { ENDPOINTS } from '@config';
import { getRequest, postRequest } from '@sagas/api';
import { urlFromTemplate } from '@utils/url';
import {
  ChatState,
  Conversation,
  ConversationId,
  Message,
} from '@store/chat/slice';

type ConversationExist = { conversationExist: boolean };

export function* createChatByRequest(requestId: string, supplierId: string) {
  const { data } = yield call(
    postRequest,
    urlFromTemplate(ENDPOINTS.CHAT.CREATECHATBYREQUEST, {
      requestId,
      supplierId,
    })
  );

  return data;
}

export function* getConversationByRequest(
  requestId: string,
  supplierId: string
) {
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.CHAT.GETCHATBYREQUEST, {
      requestId,
      supplierId,
    })
  );

  return data;
}

export function* createChatByOrder(orderId: string) {
  const { data } = yield call(
    postRequest,
    urlFromTemplate(ENDPOINTS.CHAT.CREATECHATBYORDER, {
      orderId,
    })
  );
  return data;
}

export function* getConversationByOrder(orderId: string) {
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.CHAT.GETCHATBYORDER, {
      orderId,
    })
  );

  return data;
}

export function* getConversations() {
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.CHAT.CHATS, {})
  );

  return data as Conversation[];
}

export function* sendMessage(content: string, conversationId: ConversationId) {
  const { data } = yield call(
    postRequest,
    urlFromTemplate(ENDPOINTS.CHAT.SENDMESSAGE, { conversationId }),
    {
      content,
    }
  );

  return data as Message;
}

export function* getConversationById(conversationId: ConversationId) {
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.CHAT.GETMESSAGES, { conversationId }),
    {}
  );

  return data as Message[];
}

export function* isConversationRequest(requestId: string, supplierId: string) {
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.CHAT.ISCONVERSATIONREQUEST, {
      requestId,
      supplierId,
    }),
    {}
  );

  return data as ConversationExist;
}

export function* isConversationOrder(orderId: string) {
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.CHAT.ISCONVERSOTIONBYORDER, {
      orderId,
    }),
    {}
  );

  return data as ConversationExist;
}

export function* getNumberOfConversationsWithUnreadedMessages() {
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.CHAT.UNREAD, {}),
    {}
  );

  return data as Pick<ChatState, 'numberOfConversationsWithUnreadedMessages'>;
}

export function* getNewMessage(messageId: string) {
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.CHAT.NEW, { messageId }),
    {}
  );

  return data as Message[];
}

export function* getAcessToConversation(conversationId: string) {
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.CHAT.ACCESSTOCONVERSATION, { conversationId }),
    {}
  );

  return data as { accessToConversation: boolean };
}

export function* getConversation(conversationId: string) {
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.CHAT.GETCONVERSATION, { conversationId })
  );

  return data as Conversation;
}
