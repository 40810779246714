import React, { FC, useState } from 'react';
import cn from 'classnames';
import { ROOT_FOLDER_ID } from '@constants/folders';
import { useSelectedResources } from '@hooks/useSelectedResources';
import {
  ResourcesCatalogSize,
  ResourcesCatalog,
} from '@components/ResourcesCatalog';
import { ResourceFolderId } from '@store/resourceFolders/slice';

export const DirectoryModalResources: FC = () => {
  const [folderId, setFolderId] = useState(ROOT_FOLDER_ID);
  const [selectedResources, handleSelectResources] = useSelectedResources();

  const handleChangeFolder = (id: ResourceFolderId) => {
    setFolderId(id);
  };

  return (
    <ResourcesCatalog
      className={cn('modal-scroll')}
      size={ResourcesCatalogSize.Minimal}
      folderId={folderId}
      onChangeFolder={handleChangeFolder}
      selectedResources={selectedResources}
      onSelectResources={handleSelectResources}
    />
  );
};
