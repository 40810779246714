import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getCompanyUrl, getDirectoryUrl } from '@pages';
import './styles.sass';
import { useRole } from '@hooks/useRole';

export const SettingTabs = () => {
  const { t } = useTranslation();
  const { isSupplier, isCustomer } = useRole();
  const tabs = [
    {
      title: t('tabs.company'),
      href: getCompanyUrl(isSupplier ? 'suppliner' : undefined),
      visible: true,
    },
    // { title: 't('staff.staff'), href: getStaffUrl() },
    // { title: t('system.system'), href: getSystemUrl() },
    // { title: t('log.log'), href: getLogUrl() },
    {
      title: t('tabs.reference'),
      href: getDirectoryUrl(),
      visible: isCustomer,
    },
  ];

  return (
    <div className="content__cap">
      <div className="tab">
        <ul>
          {tabs
            .filter((i) => i.visible)
            .map((tab) => (
              <li key={tab.title} className="tab__title">
                <NavLink
                  to={tab.href}
                  className={({ isActive }) =>
                    isActive ? 'active' : undefined
                  }
                >
                  {tab.title}
                </NavLink>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};
