import { RootState } from '@store';
import { FetchStatus } from '@store/constants';
import { ResourceId } from '@store/resources/slice';
import { ResourceFolderId } from '@store/resourceFolders/slice';

export const getRootState = (state: RootState) => state.resources;
export const getStatus = (state: RootState) => getRootState(state).status;
export const getResources = (state: RootState) => getRootState(state).items;

export const isResourceNotFetched = (state: RootState) =>
  getStatus(state) === FetchStatus.NotFetched;
export const isResourceFetching = (state: RootState) =>
  getStatus(state) === FetchStatus.Fetching;
export const isResourceFetched = (state: RootState) =>
  getStatus(state) === FetchStatus.Fetched;

export const getResource = (state: RootState, id: ResourceId) => {
  return getResources(state).find((resource) => resource.id === id);
};
export const getResourceList = (state: RootState, ids: ResourceId[]) => {
  return getResources(state).filter((resource) => ids.includes(resource.id));
};

export const getResourcesByFolder = (
  state: RootState,
  folderId: ResourceFolderId
) =>
  getResources(state).filter(
    (resource) => resource.resourceFolderId === folderId
  );
