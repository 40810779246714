import Timer, { TimeUnit } from '@components/commons/Timer';
import { useAppDispatch } from '@hooks/redux';
import { setCurrentOrderId } from '@store/orderSupplier/actions';
import { StatusOrder } from '@utils/enums';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconTime } from '@static/img/icon-error.svg';
import { toValidDataTime } from '@components/commons/Calendar';

interface IColumnItemInfo {
  showDetails: () => void;
  status: keyof typeof StatusOrder;
  number: number;
  orderId: string;
  date: number;
  name: string;
  attention?: boolean;
  createdAt: number;
}

export default function ColumnOrderItemInfo({
  showDetails,
  number,
  orderId,
  date,
  name,
  status,
  attention = false,
  createdAt,
}: IColumnItemInfo) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleClickRequest = () => {
    showDetails();
    dispatch(setCurrentOrderId({ status, orderId }));
  };
  return (
    <div className="object__office open-modal">
      <span>
        <Timer
          dateСreate={toValidDataTime(createdAt)}
          timer={Math.trunc(
            (toValidDataTime(date) - Date.now()) / TimeUnit.day
          )}
          unit={TimeUnit.day}
        />
      </span>
      <h4>
        {attention && (
          <i title="Нужно выставить счет" data-tooltip="left">
            <IconTime />
          </i>
        )}

        {name}
      </h4>
      <p>{`${t('bid.order')} №${number}`}</p>
      <button aria-label="openModal" onClick={handleClickRequest} />
    </div>
  );
}
