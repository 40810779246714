import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchStatus } from '@store/constants';
import { CountryId, Currency } from '@store/vocabulary/slice';
import { FetchCompanyRequestAction } from '@store/company/actions';
import { FetchSupplinerSuccessAction } from './actions';

export type SupplierId = string;

export type SupplierData = {
  id: SupplierId;
  ownerId: string;
  name: string;
  email: string;
  phone: string;
  website: string;
  countryId: CountryId | null;
  cityId: CountryId | null;
  address: string;
  description: string;
  bankAccount: string;
  correspondentAccount: string;
  bic: string;
  bankName: string;
  filePath: string;
  currency: Currency;
  categories: string[];
};

export type CompanyState = {
  status: FetchStatus;
  saveCompanyStatus: boolean;
  supplierData: SupplierData | null;
  deleteSupplierStatus: FetchStatus;
};

const initialState: CompanyState = {
  status: FetchStatus.NotFetched,
  saveCompanyStatus: false,
  supplierData: null,
  deleteSupplierStatus: FetchStatus.NotFetched,
};

export const supplierSlice = createSlice({
  name: 'supplier',
  initialState,
  reducers: {
    setDeleteSupplierStatus: (state, action: PayloadAction<FetchStatus>) => {
      state.deleteSupplierStatus = action.payload;
    },

    setImageProfileSupplier: (
      state,
      action: PayloadAction<Pick<SupplierData, 'filePath'>>
    ) => {
      if (state.supplierData)
        state.supplierData.filePath = action.payload.filePath;
    },
    setSaveSupplierStatus: (state, action: PayloadAction<boolean>) => {
      state.saveCompanyStatus = action.payload;
    },
    fetchSupplierRequest: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<FetchCompanyRequestAction>
    ) => {
      state.status = FetchStatus.Fetching;
    },
    fetchSupplierSuccess: (
      state,
      action: PayloadAction<FetchSupplinerSuccessAction>
    ) => {
      const { data } = action.payload;

      state.status = FetchStatus.Fetched;
      state.supplierData = {
        ...data,
      };
    },
    saveSupplierSuccess: (state, { payload }: PayloadAction<SupplierData>) => {
      state.supplierData = payload;
      state.saveCompanyStatus = true;
    },
    deleteSupplierSuccess: () => initialState,
  },
});

export default supplierSlice.reducer;
