import React, { FC } from 'react';
import { ObjectStatus } from '@store/companyObjects/slice';
import { AVAILABLE_PHASES } from '@constants/phases';
import { createArrayMock } from '@utils/createArrayMock';
import { nanoid } from '@reduxjs/toolkit';

type ObjectPhaseProps = {
  status: ObjectStatus;
};

export const ObjectPhase: FC<ObjectPhaseProps> = ({ status }) => {
  const phasesLength = createArrayMock(4, () => null);
  const phaseClasses = AVAILABLE_PHASES[status];

  return (
    <div className="obj__info-img">
      <span>
        {phasesLength.map((_, index) => {
          return (
            <i key={nanoid()}>
              {phaseClasses[index] && <s className={phaseClasses[index]} />}
            </i>
          );
        })}
      </span>
    </div>
  );
};
