import React, { ForwardedRef, forwardRef } from 'react';
import { ChangeHandler } from 'react-hook-form';
import cn from 'classnames';

type TextAreaProps = {
  title: string;
  values?: string | number;
  name: string;
  onChange: ChangeHandler;
  onBlur: ChangeHandler;
  error?: string;
  maxLength: number;
  load?: boolean;
  // errors: FieldValues;
};

export const TextAreaWithRef = (
  {
    title,
    maxLength,
    load = false,
    onChange,
    name,
    onBlur,
    values,
  }: TextAreaProps,
  ref: ForwardedRef<HTMLTextAreaElement>
): JSX.Element => {
  return (
    <div className={cn('textarea border', { load })}>
      <textarea
        onChange={onChange}
        ref={ref}
        onBlur={onBlur}
        name={name}
        placeholder="0"
        maxLength={maxLength}
        style={{ resize: 'none' }}
        value={values}
      />
      <label htmlFor="#">{title}</label>
      <s />
    </div>
  );
};
export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  TextAreaWithRef
);
