import { RootState } from '@store';
import { FetchStatus } from '@store/constants';
import { CompanyObjectId } from './slice';

export const getRootState = (state: RootState) => state.contact;

export const getStatus = (state: RootState) => getRootState(state).status;
export const getCreateContactStatus = (state: RootState) =>
  getRootState(state).createContactStatus;
export const geFetchContactStatus = (state: RootState) =>
  getRootState(state).fetchContactsStatus;
export const getUpdateContactStatus = (state: RootState) =>
  getRootState(state).updateContactStatus;

export const getContacts = (state: RootState) => getRootState(state).contacts;
export const getFullContacts = (state: RootState) =>
  getRootState(state).fullContacts;
export const getTotalContacts = (state: RootState) =>
  getRootState(state).totalContacts;
export const getCompanyObjectId = (state: RootState) =>
  getRootState(state).currentCompanyObjectId;
export const getActiveBlinkWhenCreatedNewContact = (state: RootState) =>
  getRootState(state).activeBlinkWhenCreatedNewContact;
export const getLatestAddedCompanyObjectId = (state: RootState) =>
  getRootState(state).latestAddedCompanyObjectId;
export const getlastUpdatedContact = (state: RootState) =>
  getRootState(state).lastUpdatedContact;
export const isFetchContactsFetched = (state: RootState) =>
  geFetchContactStatus(state) === FetchStatus.Fetched;

export const getContactById = (state: RootState, id?: CompanyObjectId) => {
  if (id === undefined) {
    return undefined;
  }
  return getContacts(state).filter(
    (companyObject) => companyObject.id === id
  )[0];
};

export const getChoiseContact = (state: RootState) =>
  getRootState(state).choiseContact;
export const getChoiseFullContact = (state: RootState) =>
  getRootState(state).choiseFullContact;

export const isCreateContactNotFetched = (state: RootState) =>
  getCreateContactStatus(state) === FetchStatus.NotFetched;
export const isCreateContactFetching = (state: RootState) =>
  getCreateContactStatus(state) === FetchStatus.Fetching;
export const isCreateContactFetched = (state: RootState) =>
  getCreateContactStatus(state) === FetchStatus.Fetched;

export const isViewObjectFunnel = (state: RootState) => state.contact.typeView;
