import { useCallback, useEffect, useRef } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { useCurrentLocale } from '@hooks/useCurrentLocale';
import { currencyParse } from '@utils/currency';
import { percentParse } from '@utils/percent';

interface EstimateFieldValues {
  amount: number;
  price: string;
  cost: string;
  markup: string;
  salePrice: string;
  saleCost: string;
}

const format = (value: number) => parseFloat(value.toFixed(2));
const isValid = (values: number[]) =>
  values.every((value) => !Number.isNaN(value));

export const useEstimateCalculation = <FormData extends EstimateFieldValues>(
  control: Control<FormData>,
  onChange: (name: keyof EstimateFieldValues, value: number) => void
) => {
  const locale = useCurrentLocale();
  const fields = useWatch({
    control,
  });

  // Use `useEffectEvent` in feature versions
  // const onCostChange = useEffectEvent(() => {
  //   onChange('cost', price * amount);
  // });
  const data = useRef({
    amount: 0,
    price: 0,
    cost: 0,
    markup: 0,
    salePrice: 0,
    saleCost: 0,
  });
  data.current = {
    amount: fields.amount ? fields.amount : 0,
    price: fields.price ? currencyParse(fields.price, locale) : 0,
    cost: fields.cost ? currencyParse(fields.cost, locale) : 0,
    markup: fields.markup ? percentParse(fields.markup, locale) : 0,
    salePrice: fields.salePrice ? currencyParse(fields.salePrice, locale) : 0,
    saleCost: fields.saleCost ? currencyParse(fields.saleCost, locale) : 0,
  };

  const handleChange = useCallback(
    (name: keyof EstimateFieldValues, value: number) => {
      data.current[name] = value;
      onChange(name, value);
    },
    [onChange]
  );

  useEffect(() => {
    const { price, amount } = data.current;

    if (isValid([price, amount])) {
      handleChange('cost', format(price * amount));
    }
  }, [data.current.price, data.current.amount, handleChange]);

  useEffect(() => {
    const { cost, amount } = data.current;

    if (isValid([cost, amount]) && amount !== 0) {
      handleChange('price', format(cost / amount));
    }
  }, [data.current.cost, handleChange]);

  useEffect(() => {
    const { price, markup } = data.current;

    if (isValid([price, markup])) {
      handleChange('salePrice', format(price + price * (markup / 100)));
    }
  }, [data.current.price, data.current.markup, handleChange]);

  useEffect(() => {
    const { cost, markup } = data.current;

    if (isValid([cost, markup])) {
      handleChange('saleCost', format(cost + cost * (markup / 100)));
    }
  }, [data.current.cost, data.current.markup, handleChange]);

  useEffect(() => {
    const { salePrice, markup } = data.current;

    if (isValid([salePrice, markup])) {
      handleChange('price', format(salePrice / (1 + markup / 100)));
    }
  }, [data.current.salePrice, handleChange]);

  useEffect(() => {
    const { saleCost, markup } = data.current;

    if (isValid([saleCost, markup])) {
      handleChange('cost', format(saleCost / (1 + markup / 100)));
    }
  }, [data.current.saleCost, handleChange]);
};
