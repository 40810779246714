import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { Saga } from '@sagas/types';
import { handleError } from '@sagas/errors';
import {
  CreateOrganizationRequestAction,
  DeleteOrganizationRequestAction,
  FetchOrganizationRequestAction,
  UpdateOrganizationRequestAction,
  createOrganizationRequest,
  createOrganizationSuccess,
  deleteOrganizationRequest,
  deleteOrganizationSuccess,
  fetchFullOrganizationsObjectRequest,
  fetchFullOrganizationsObjectSuccess,
  fetchOrganizationsObjectRequest,
  fetchOrganizationsObjectSuccess,
  resetCreateOrganizationStatus,
  resetUpdateOrganizationStatus,
  setActiveBlinkWhenCreatedNewOrganization,
  setcurrentCompanyObjectId,
  updateOrganizationRequest,
  updateOrganizationSuccess,
} from '@store/organizations/actions';
import {
  createOrganizationObject,
  deleteOrganization,
  getCompanyOrganizationObjects,
  getFullCompanyOrganizationObjects,
  updateOrganization,
} from './api/organizations';

const createOrganizationRequestHandler: Saga<CreateOrganizationRequestAction> =
  function* ({ payload }) {
    const { companyId } = payload;

    try {
      const object = yield call(
        createOrganizationObject,
        payload.data,
        companyId
      );
      yield put(createOrganizationSuccess({ data: object }));
      yield put(setActiveBlinkWhenCreatedNewOrganization(true));
      yield put(setcurrentCompanyObjectId(object.id));
    } catch (e) {
      yield call(handleError, e);
      yield put(resetCreateOrganizationStatus());
    }
  };

const getOrganizationsRequestHandler: Saga<FetchOrganizationRequestAction> =
  function* ({ payload }) {
    try {
      const { items } = yield call(getCompanyOrganizationObjects, payload.id);
      yield put(fetchOrganizationsObjectSuccess(items));
    } catch (e) {
      yield call(handleError, e);
    }
  };

const getFullOrganizationsRequestHandler: Saga<FetchOrganizationRequestAction> =
  function* ({ payload }) {
    try {
      const { data } = yield call(
        getFullCompanyOrganizationObjects,
        payload.id,
        {
          max: payload.max,
          page: payload.page,
        }
      );
      yield put(
        fetchFullOrganizationsObjectSuccess({
          response: data,
          isUpdatePage: payload.isUpdatePage,
        })
      );
    } catch (e) {
      yield call(handleError, e);
    }
  };

const updateOrganizationRequestHandler: Saga<UpdateOrganizationRequestAction> =
  function* ({ payload }) {
    try {
      const { companyId, organizationId } = payload;
      const newData = yield call(
        updateOrganization,
        companyId,
        organizationId,
        payload.data
      );
      yield put(updateOrganizationSuccess(newData));
    } catch (e) {
      yield call(handleError, e);
      yield put(resetUpdateOrganizationStatus());
    }
  };

const deleteOrganizationRequestHandler: Saga<DeleteOrganizationRequestAction> =
  function* ({ payload }) {
    const { companyId, id } = payload;

    try {
      yield call(deleteOrganization, companyId, id);
      yield put(deleteOrganizationSuccess({ id }));
      // const { data } = yield call(getFullCompanyOrganizationObjects, companyId);
      // yield put(fetchFullOrganizationsObjectSuccess(data));
    } catch (e) {
      yield call(handleError, e);
    }
  };

export default function* root() {
  yield all([
    takeLatest(createOrganizationRequest, createOrganizationRequestHandler),
    takeEvery(fetchOrganizationsObjectRequest, getOrganizationsRequestHandler),
    takeEvery(
      fetchFullOrganizationsObjectRequest,
      getFullOrganizationsRequestHandler
    ),
    takeLatest(updateOrganizationRequest, updateOrganizationRequestHandler),
    takeLatest(deleteOrganizationRequest, deleteOrganizationRequestHandler),
  ]);
}
