import React, { memo, useContext } from 'react';
import cn from 'classnames';
import { useAppSelector } from '@hooks/redux';
import { getOperationFolders } from '@store/operationFolders/selectors';
import { OperationCatalogContext } from '@components/OperationsCatalog';
import { OperationCatalogAddFolderButton } from './AddFolderButton';
import { OperationCatalogAddFolder } from './AddFolder';
import { OperationCatalogFolder } from './Folder';

type OperationCatalogFolderListType = {
  activeAddButton: boolean;
  onCreateFolderClick: () => void;
  onSubmitFolder: () => void;
  onCancelFolderEdit: () => void;
};

const OperationCatalogFolderListRaw: React.FC<
  OperationCatalogFolderListType
> = ({
  activeAddButton,
  onCreateFolderClick,
  onSubmitFolder,
  onCancelFolderEdit,
}) => {
  const { size } = useContext(OperationCatalogContext);
  const folders = useAppSelector(getOperationFolders);

  return (
    <div className="manager__base">
      <div className={cn('manager__folders', size)}>
        {folders?.map((folder) => (
          <OperationCatalogFolder key={folder.id} folder={folder} />
        ))}
        {!activeAddButton ? (
          <OperationCatalogAddFolderButton onClick={onCreateFolderClick} />
        ) : (
          <OperationCatalogAddFolder
            onSubmit={onSubmitFolder}
            onCancel={onCancelFolderEdit}
          />
        )}
      </div>
    </div>
  );
};

export const OperationCatalogFolderList = memo(OperationCatalogFolderListRaw);
