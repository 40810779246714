import { FC } from 'react';
import { useAppSelector } from '@hooks/redux';
import { getProfileCompanyId } from '@store/profile/selectors';
import { CommercialProposal, CostEstimateId } from '@store/costEstimates/slice';
import {
  CommercialProposalWithTitles,
  CostEstimateObject,
} from '../utils/CostEstimateObjectCollection';
import { IterationItem } from '../IterationItem';

type CommercialProposalsProps = {
  objectCollection: CostEstimateObject[];
  commercialProposal?: CommercialProposalWithTitles;
  currentCostEstimateId: CostEstimateId | undefined;
  onApproved: (obj: CommercialProposal | null) => void;
};

export const CommercialProposals: FC<CommercialProposalsProps> = ({
  commercialProposal,
  currentCostEstimateId,
  onApproved,
}) => {
  const companyId = useAppSelector(getProfileCompanyId);

  if (!companyId || !commercialProposal || !currentCostEstimateId) {
    return null;
  }

  return (
    <div className="obj__offer">
      <IterationItem
        commercialProposal={commercialProposal}
        isApproved={commercialProposal.approved}
        creationDate={commercialProposal.creationDate}
        url={commercialProposal.url}
        onApproved={onApproved}
      />
    </div>
  );
};
