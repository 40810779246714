import React from 'react';

type AuthButtonProps = {
  title: string;
  onClick?: () => void;
};

export const AuthButton: React.FC<AuthButtonProps> = ({ title, onClick }) => {
  return (
    <button type="submit" className="link" onClick={onClick}>
      <span>{title}</span>
    </button>
  );
};
