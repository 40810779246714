import { OrderStatus } from '@store/ordersCustomer/slice';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

type Props = {
  status: OrderStatus;
  needInvoice: boolean;
};
const OrderModalStatusList = ({ status, needInvoice }: Props) => {
  const { t } = useTranslation();

  const status1 = status !== 'ACCEPTED';
  const status2 = status !== 'PAYMENT';
  const status3 = status !== 'PAID';
  const status4 = status !== 'PRODUCTION';
  const status5 = status !== 'READY';
  const status6 = status === 'PAYMENT';
  const status7 = status === 'ACCEPTED' && needInvoice;
  const status8 = (status1 && status2) || status6 || status7;

  return (
    <ul>
      <li className="ready">
        <i className="bg-success" />
        <b>{t('orders.the-order-has-been-accepted')}</b>
      </li>
      <li className={cn(status8 && 'ready')}>
        <i className="bg-warning" />
        <b>{t('orders.an-invoice-is-pending')}</b>
      </li>
      <li className={cn(status1 && status2 && 'ready')}>
        <i className="bg-primary" />
        <b>{t('orders.the-bill-is-paid')}</b>
      </li>
      <li className={cn(status1 && status2 && status3 && 'ready')}>
        <i className="bg-main" />
        <b>{t('orders.preparing')}</b>
      </li>
      <li className={cn(status1 && status2 && status3 && status4 && 'ready')}>
        <i className="bg-danger" />
        <b>{t('orders.ready-for-shipment')}</b>
      </li>
      <li
        className={cn(
          status1 && status2 && status3 && status4 && status5 && 'ready'
        )}
      >
        <i className="bg-info" />
        <b>{t('orders.shipped')}</b>
      </li>
    </ul>
  );
};

export default OrderModalStatusList;
