import React, { FC, useEffect } from 'react';
import cn from 'classnames';
import logoIcon from '@static/img/logo2.svg';
import errorIcon from '@static/img/icon-error.svg';
import iconCheck from '@static/img/icon-check2.svg';
import { NoticeTypes } from '@utils/notifications/notice';

// // const date = "2 минуты назад";
// const date = 'только что';
// const message = 'Укажи количество, дружище';

type NoticeProps = {
  date: string;
  message: string;
  type: NoticeTypes;
  timeout: number;
  onClose: () => void;
  className?: string;
  componentSerialNumber?: number;
  biasBottomCoordinate?: number;
  defaultBottomCoordinate?: number;
};

export const Notice: FC<NoticeProps> = ({
  date,
  type,
  message,
  timeout = 5000,
  onClose,
  className,
  componentSerialNumber,
  biasBottomCoordinate = 30,
  defaultBottomCoordinate = 100,
}) => {
  useEffect(() => {
    const timeoutId = setTimeout(onClose, timeout);
    return () => clearTimeout(timeoutId);
  }, [onClose, timeout]);

  return (
    <div
      className={cn('notice', className)}
      style={{
        bottom: `${
          componentSerialNumber === 0 || componentSerialNumber === undefined
            ? biasBottomCoordinate
            : componentSerialNumber * defaultBottomCoordinate +
              biasBottomCoordinate
        }px`,
      }}
    >
      <div className="notice__cap">
        <div>
          <img src={logoIcon} alt="Строй.рф" />
          Строй.рф
        </div>
        <p>{date}</p>
        <i onClick={onClose} />
      </div>
      <div className="notice__cot">
        <p>
          <img
            src={type === NoticeTypes.success ? iconCheck : errorIcon}
            alt="Error"
          />
          {message}
        </p>
      </div>
    </div>
  );
};
