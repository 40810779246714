import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { useAppDispatch } from '@hooks/redux';
import { Notice } from '@components/Notification/Notice';
import { removeMessage } from '@store/errors/actions';
import { NoticeTypes } from '@utils/notifications';
import './styles.sass';
import { useTranslation } from 'react-i18next';

type ErrorMessageProps = {
  id: string;
  message: string;
  idx?: number;
  type?: NoticeTypes;
};

export const ErrorMessage: FC<ErrorMessageProps> = ({
  id,
  message,
  idx,
  type = NoticeTypes.error,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [status, setStatus] = useState('create');

  useEffect(() => {
    setStatus('show');
  }, []);

  const handleClose = () => {
    setStatus('close');

    setTimeout(() => {
      dispatch(removeMessage({ id }));
    }, 300);
  };

  return (
    <Notice
      date={t('commons.data-now')}
      type={type}
      timeout={5000}
      onClose={handleClose}
      message={t(message)}
      className={cn('animated', status)}
      componentSerialNumber={idx}
    />
  );
};
