import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Modal } from '@components/Modal';
import { DirectoryModalOperations } from '@components/DirectoryModal/Operations';
import { DirectoryModalResources } from '@components/DirectoryModal/Resources';

type DirectoryModalProps = {
  visible: boolean;
  onCancel: () => void;
};

export const DirectoryModal: FC<DirectoryModalProps> = ({
  visible,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [type, setType] = useState('resources');

  return (
    <Modal visible={visible} onBackgroundClick={onCancel}>
      <div className="modal__cap">
        <div className="modal__close arrow" onClick={onCancel} />
        <div className="tab border switch" data-tab="manager-tab">
          <ul>
            <li
              className={cn({ active: type === 'resources' })}
              onClick={() => setType('resources')}
            >
              {t('directory.resource')}
            </li>
            <li
              className={cn({ active: type === 'operations' })}
              onClick={() => setType('operations')}
            >
              {t('directory.operations')}
            </li>
          </ul>
        </div>
      </div>
      {type === 'operations' && <DirectoryModalOperations />}
      {type === 'resources' && <DirectoryModalResources />}
    </Modal>
  );
};
