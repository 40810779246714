import { AuthBackground } from '@pages/Auth/Background';
import { useAppSelector } from '@hooks/redux';
import { getStatusSavePassword } from '@store/account/selectors';
import { ToAuthButton } from '@pages/SignUp/ToAuthButton';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { ResetPasswordLogo } from './ResetPasswordLogo';
import { ResetForm } from './ResetForm';

export const ResetPassword = () => {
  const status = useAppSelector(getStatusSavePassword);
  const { t } = useTranslation();
  return (
    <section className="login">
      <AuthBackground />
      <div className={cn('login__block')}>
        <div className="login__title">
          <ResetPasswordLogo />
          <h1>{t('auth.changing-password')}</h1>
          <p>
            {t(
              status === 2
                ? 'auth.set-new-password-success'
                : 'auth.set-new-password'
            )}
          </p>
        </div>
        <ResetForm />
        <ToAuthButton />
      </div>
    </section>
  );
};
