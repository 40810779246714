import { call } from 'redux-saga/effects';
import { ENDPOINTS } from '@config';
import { deleteRequest, getRequest, postRequest } from '@sagas/api';
import { urlFromTemplate } from '@utils/url';
import { CostEstimateId } from '@store/costEstimates/slice';
import { RequestId } from '@store/requestCustomer/actions';
import { RequestCustomerData } from '@store/requestCustomer/slice';
import { ResourceId } from '@store/resources/slice';

export type CreateRequestCustomerData = {
  costEstimateItemsIds: string[] | [];
  id: CostEstimateId;
};

export type CreateOrderCustomerData = {
  requestItemsIds: string[] | [];
  requestId: RequestId;
};

export function* createRequestCustomer(params: CreateRequestCustomerData) {
  yield call(
    postRequest,
    urlFromTemplate(ENDPOINTS.REQUEST.CREATE, {
      id: params.id,
    }),
    { resourceIds: params.costEstimateItemsIds }
  );
}

export function* createOrderCustomer(params: CreateOrderCustomerData) {
  yield call(
    postRequest,
    urlFromTemplate(ENDPOINTS.REQUEST.CREATE_ORDER, {
      requestId: params.requestId,
    }),
    { responseIds: params.requestItemsIds }
  );
}

export function* getRequestCustomer(id: RequestId) {
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.REQUEST.GET, { id })
  );
  return data as RequestCustomerData;
}

export function* deleteRequestCustomerItem(
  resourceId: ResourceId,
  requestId: RequestId
) {
  yield call(
    deleteRequest,
    urlFromTemplate(ENDPOINTS.REQUEST.DELETE_ITEM, {
      resourceId,
      requestId,
    })
  );
}
