import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ANIMATION_TIME } from '@constants/modal';

type SettingsDirectoryResourcesCreateFolderModalFormProps = {
  onSubmit: (folderName: string) => void;
  onCancelClick: () => void;
};

type FormData = {
  name: string;
};

export const SettingsDirectoryResourcesCreateFolderModalForm: FC<
  SettingsDirectoryResourcesCreateFolderModalFormProps
> = ({ onSubmit, onCancelClick }) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setFocus,
    reset,
    formState: { errors },
  } = useForm<FormData>();

  const submitHandler = handleSubmit(({ name }, e) => {
    e?.preventDefault();
    onSubmit(name.trim());
  });

  const handleErrors = () => {
    if (errors.name?.type === 'required') {
      return (
        <div>
          <s />
          <small>{t('auth.validation-empty')}</small>
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => setFocus('name'), ANIMATION_TIME);

    return () => clearTimeout(timeoutId);
  }, [setFocus, reset]);

  return (
    <form action="#" className="modal__min-form form">
      <div className="form__group">
        <div className="input border error">
          <input
            type="text"
            placeholder="0"
            {...register('name', {
              required: true,
            })}
          />
          <label>{t('directory.folder-name')}</label>
          {handleErrors()}
        </div>
      </div>
      <div className="modal__min-but">
        <a className="link transparent" onClick={onCancelClick}>
          {t('modal.cancel')}
        </a>
        <button onClick={submitHandler} type="submit" className="link">
          {t('modal.done')}
        </button>
      </div>
    </form>
  );
};
