import { call, put, takeLatest } from 'redux-saga/effects';
import { Saga } from '@sagas/types';
import { FetchStatus } from '@store/constants';
import {
  RestoreRequestAction,
  SavePasswordRequestAction,
} from '@store/user/actions';
import {
  restoreRequest,
  savePasswordRequest,
  setStatusResotrPassword,
  setStatusSaveNewPassword,
} from '@store/account/actions';
import { handleError } from './errors';

import { reguestResetPassword, reguestRestorePassword } from './api/account';

const restoreRequestHandler: Saga<RestoreRequestAction> = function* ({
  payload,
}) {
  try {
    yield put(setStatusResotrPassword(FetchStatus.Fetching));

    yield call(reguestRestorePassword, payload);

    yield put(setStatusResotrPassword(FetchStatus.Fetched));
  } catch (e) {
    yield put(setStatusResotrPassword(FetchStatus.NotFetched));

    yield call(handleError, e);
  }
};

const savePasswordRequestHandler: Saga<SavePasswordRequestAction> = function* ({
  payload,
}) {
  try {
    yield put(setStatusSaveNewPassword(FetchStatus.Fetching));

    yield call(reguestResetPassword, payload);

    yield put(setStatusSaveNewPassword(FetchStatus.Fetched));
  } catch (e) {
    yield put(setStatusSaveNewPassword(FetchStatus.NotFetched));

    yield call(handleError, e);
  }
};

export default function* root() {
  yield takeLatest(restoreRequest, restoreRequestHandler);
  yield takeLatest(savePasswordRequest, savePasswordRequestHandler);
}
