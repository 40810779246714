import { ReactNode } from 'react';

type TableHeaderProps = {
  columns: { className?: string; children: ReactNode }[];
};

const TableHeader = ({ columns }: TableHeaderProps) => {
  return (
    <div className="client__cap">
      {columns.map(({ children, className }) => (
        <div className={className} key={className}>
          {children}
        </div>
      ))}
    </div>
  );
};

export default TableHeader;
