import React from 'react';
import { Link } from 'react-router-dom';
import logo from '@static/img/logo.svg';

export const Logo = () => {
  return (
    <Link to="/" className="menu__logo">
      <img src={logo} alt="Строй.рф MVP" />
    </Link>
  );
};
