import { CreateButton } from '@components/Header/CreateButton';
import { MainLayout } from '@layouts/MainLayout';
import { Modal, ModalSize } from '@components/Modal';
import { ObjectModal } from '@pages/Dashboard/ObjectModal';
import CreateContactForm from '@pages/Dashboard/ObjectModal/CreateContactForm';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getProfileCompanyId } from '@store/profile/selectors';
import { useAppSelector } from '@hooks/redux';
import CreateOrganizationForm from '@pages/Dashboard/ObjectModal/CreateOrganizationForm';
import { getCompanyObjectId } from '@store/companyObjects/selectors';
import {
  getFullContacts,
  getTotalContacts,
  getlastUpdatedContact,
} from '@store/contacts/selectors';
import {
  getFullOrganizations,
  getTotalOrganizations,
  getlastUpdatedOrganization,
} from '@store/organizations/selectors';
import ClientCompany from '@components/ClientCompany';
import { useTranslation } from 'react-i18next';
import { fetchFullContactsObjectRequest } from '@store/contacts/actions';
import { useDidUpdateEffect } from '@hooks/useDidUpdateEffect';
import { fetchFullOrganizationsObjectRequest } from '@store/organizations/actions';
import { fetchCompanyRequest } from '@store/company/actions';
import { fetchCompanyObjectsRequest } from '@store/companyObjects/actions';
import InfiniteScroll from 'react-infinite-scroll-component';

const max = 10;

const ClientsBasePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const companyId = useAppSelector(getProfileCompanyId);
  const objectId = useAppSelector(getCompanyObjectId);
  const contactsForRender = useAppSelector(getFullContacts);
  const organizationsForRender = useAppSelector(getFullOrganizations);
  const totalContacts = useAppSelector(getTotalContacts);
  const totalOrganizations = useAppSelector(getTotalOrganizations);
  const lastUpdatedContact = useAppSelector(getlastUpdatedContact);
  const lastUpdatedOrganization = useAppSelector(getlastUpdatedOrganization);

  const [type, setType] = useState('clients');
  const [isViewCreate, setIsViewCreate] = useState(false);
  const [contactsPage, setContactsPage] = useState(1);
  const [organizationsPage, setOrganizationsPage] = useState(1);

  const prevContactsPage = useRef(0);
  const prevOrganizationsPage = useRef(0);

  const clientsRef = useRef<InfiniteScroll>(null);
  const companyRef = useRef<InfiniteScroll>(null);

  const handleClickModalCreate = () => {
    setIsViewCreate(!isViewCreate);
  };

  const handleCreateClose = () => setIsViewCreate(false);

  /* Этот эффект реагирует на создание и изменение контакта. При создании скидывает страницу на первую, чтобы стриггерить загрузку.
  А если страница и так была первая, перезагружает ее.
  При изменении определяет на какой странице произошло изменение и выставляет ее в стейт, что вызовет реакцию другого эффекта.
  Если изменение произошло на последней загруженной странице, этот эффект сам обновляет ее.
  TODO: Отрефакторить это. */
  useDidUpdateEffect(() => {
    if (lastUpdatedContact?.action === 'CREATE') {
      clientsRef.current?.getScrollableTarget()?.scrollTo({ top: 0 });
      if (contactsPage === 1 && companyId) {
        dispatch(
          fetchFullContactsObjectRequest({
            id: companyId,
            page: 1,
            max,
            isUpdatePage: true,
          })
        );
      } else {
        setContactsPage(1);
      }
    } else if (
      lastUpdatedContact?.action === 'UPDATE' ||
      lastUpdatedContact?.action === 'DELETE'
    ) {
      const index = contactsForRender.findIndex(
        (elem) => elem.id === lastUpdatedContact.id
      );
      if (index > -1) {
        const page = Math.floor(index / max + 1);
        if (page === contactsPage) {
          if (companyId) {
            dispatch(
              fetchFullContactsObjectRequest({
                id: companyId,
                page,
                max,
                isUpdatePage: true,
              })
            );
          }
        } else {
          setContactsPage(page);
        }
      }
    }
  }, [lastUpdatedContact]);

  /* Этот эффект реагирует на создание и изменение организации. При создании скидывает страницу на первую, чтобы стриггерить загрузку.
  А если страница и так была первая, перезагружает ее.
  При изменении определяет на какой странице произошло изменение и выставляет ее в стейт, что вызовет реакцию другого эффекта.
  Если изменение произошло на последней загруженной странице, этот эффект сам обновляет ее.
  TODO: Отрефакторить это. */
  useDidUpdateEffect(() => {
    if (lastUpdatedOrganization?.action === 'CREATE') {
      companyRef.current?.getScrollableTarget()?.scrollTo({ top: 0 });
      if (organizationsPage === 1 && companyId) {
        dispatch(
          fetchFullOrganizationsObjectRequest({
            id: companyId,
            page: 1,
            max,
            isUpdatePage: true,
          })
        );
      } else {
        setOrganizationsPage(1);
      }
    } else if (
      lastUpdatedOrganization?.action === 'UPDATE' ||
      lastUpdatedOrganization?.action === 'DELETE'
    ) {
      const index = organizationsForRender.findIndex(
        (elem) => elem.id === lastUpdatedOrganization.id
      );
      if (index > -1) {
        const page = Math.floor(index / max + 1);
        if (page === organizationsPage) {
          if (companyId) {
            dispatch(
              fetchFullOrganizationsObjectRequest({
                id: companyId,
                page,
                max,
                isUpdatePage: true,
              })
            );
          }
        } else {
          setOrganizationsPage(page);
        }
      }
    }
  }, [lastUpdatedOrganization]);

  useEffect(() => {
    if (companyId) {
      dispatch(
        fetchFullContactsObjectRequest({
          id: companyId,
          page: contactsPage,
          max,
          isUpdatePage: contactsPage <= prevContactsPage.current,
        })
      );
      prevContactsPage.current = contactsPage;
    }
  }, [companyId, contactsPage, dispatch]);

  useEffect(() => {
    if (companyId) {
      dispatch(
        fetchFullOrganizationsObjectRequest({
          id: companyId,
          page: organizationsPage,
          max,
          isUpdatePage: organizationsPage <= prevOrganizationsPage.current,
        })
      );
      prevOrganizationsPage.current = organizationsPage;
    }
  }, [companyId, dispatch, organizationsPage]);

  useEffect(() => {
    if (companyId !== undefined && objectId !== undefined) {
      dispatch(fetchCompanyRequest({ id: companyId }));
      dispatch(fetchCompanyObjectsRequest({ id: companyId }));
    }
  }, [dispatch, companyId, objectId]);

  return (
    <>
      <MainLayout
        title={t('navigation.clients-base')}
        buttons={
          <CreateButton
            onClick={handleClickModalCreate}
            title={
              type === 'clients'
                ? t('clients.add-a-client')
                : t('organization.add-a-company')
            }
          />
        }
      >
        <ClientCompany
          type={type}
          setType={setType}
          onContactsPageChange={setContactsPage}
          onOrganizationsPageChange={setOrganizationsPage}
          contactsForRender={contactsForRender}
          organizationsForRender={organizationsForRender}
          totalContacts={totalContacts}
          totalOrganizations={totalOrganizations}
          clientsRef={clientsRef}
          companyRef={companyRef}
        />
      </MainLayout>
      <Modal
        visible={isViewCreate}
        size={ModalSize.Auto}
        onBackgroundClick={handleCreateClose}
      >
        <ObjectModal onClose={handleCreateClose}>
          {type === 'clients' ? (
            <CreateContactForm onClose={handleCreateClose} />
          ) : (
            <CreateOrganizationForm onClose={handleCreateClose} />
          )}
        </ObjectModal>
      </Modal>
    </>
  );
};

export default ClientsBasePage;
