import { call } from 'redux-saga/effects';
import { ENDPOINTS } from '@config';
import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
} from '@sagas/api';
import { CompanyId } from '@store/company/slice';
import { ResourceFolder, ResourceFolderId } from '@store/resourceFolders/slice';
import { urlFromTemplate } from '@utils/url';

export interface CreateResourceFolderParams {
  name: string;
  parentFolderId: ResourceFolderId;
  level: number;
  path: string;
}

export interface RenameResourceFolderParams {
  name: string;
}

export function* getResourceFolders(companyId: CompanyId) {
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.RESOURCES.FOLDERS.GET, { companyId })
  );

  return data as ResourceFolder[];
}

export function* createResourceFolder(
  companyId: CompanyId,
  params: CreateResourceFolderParams
) {
  const { data } = yield call(
    postRequest,
    urlFromTemplate(ENDPOINTS.RESOURCES.FOLDERS.CREATE, { companyId }),
    params
  );

  return data as ResourceFolder;
}

export function* renameResourceFolder(
  companyId: CompanyId,
  id: ResourceFolderId,
  params: RenameResourceFolderParams
) {
  yield call(
    patchRequest,
    urlFromTemplate(ENDPOINTS.RESOURCES.FOLDERS.RENAME, { companyId, id }),
    params
  );
}

export function* deleteResourceFolder(
  companyId: CompanyId,
  id: ResourceFolderId
) {
  yield call(
    deleteRequest,
    urlFromTemplate(ENDPOINTS.RESOURCES.FOLDERS.DELETE, { companyId, id })
  );
}
