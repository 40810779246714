import {
  all,
  call,
  put,
  SagaReturnType,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { Saga } from '@sagas/types';
import { handleError } from '@sagas/errors';
import {
  FetchResourceFoldersRequestAction,
  CreateResourceFolderRequestAction,
  RenameResourceFolderRequestAction,
  DeleteResourceFolderRequestAction,
  fetchResourceFoldersRequest,
  fetchResourceFoldersSuccess,
  createResourceFolderRequest,
  createResourceFolderSuccess,
  createResourceFolderError,
  renameResourceFolderRequest,
  renameResourceFolderSuccess,
  deleteResourceFolderRequest,
  deleteResourceFolderSuccess,
} from '@store/resourceFolders/actions';
import {
  getResourceFolders,
  createResourceFolder,
  renameResourceFolder,
  deleteResourceFolder,
} from '@sagas/api/resourceFolders';

const fetchResourceFoldersRequestHandler: Saga<FetchResourceFoldersRequestAction> =
  function* ({ payload }) {
    const { companyId } = payload;
    try {
      const data: SagaReturnType<typeof getResourceFolders> = yield call(
        getResourceFolders,
        companyId
      );

      yield put(fetchResourceFoldersSuccess({ companyId, data }));
    } catch (e) {
      yield call(handleError, e);
    }
  };

const createResourceFolderRequestHandler: Saga<CreateResourceFolderRequestAction> =
  function* ({ payload }) {
    const { companyId, temporaryId, data } = payload;

    try {
      const resourceFolder: SagaReturnType<typeof createResourceFolder> =
        yield call(createResourceFolder, companyId, data);

      yield put(
        createResourceFolderSuccess({
          companyId,
          temporaryId,
          data: resourceFolder,
        })
      );
    } catch (e) {
      yield put(createResourceFolderError({ companyId, temporaryId }));
      yield put(fetchResourceFoldersRequest({ companyId }));
      yield call(handleError, e);
    }
  };

const renameResourceFolderRequestHandler: Saga<RenameResourceFolderRequestAction> =
  function* ({ payload }) {
    const { companyId, id, data } = payload;

    try {
      yield call(renameResourceFolder, companyId, id, data);
      yield put(renameResourceFolderSuccess({ companyId, id }));
    } catch (e) {
      yield put(fetchResourceFoldersRequest({ companyId }));
      yield call(handleError, e);
    }
  };

const deleteResourceFolderRequestHandler: Saga<DeleteResourceFolderRequestAction> =
  function* ({ payload }) {
    const { companyId, id } = payload;

    try {
      yield call(deleteResourceFolder, companyId, id);
      yield put(deleteResourceFolderSuccess({ companyId, id }));
    } catch (e) {
      yield put(fetchResourceFoldersRequest({ companyId }));
      yield call(handleError, e);
    }
  };

export default function* root() {
  yield all([
    takeLatest(fetchResourceFoldersRequest, fetchResourceFoldersRequestHandler),
    takeEvery(createResourceFolderRequest, createResourceFolderRequestHandler),
    takeEvery(renameResourceFolderRequest, renameResourceFolderRequestHandler),
    takeEvery(deleteResourceFolderRequest, deleteResourceFolderRequestHandler),
  ]);
}
