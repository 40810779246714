import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { Saga } from '@sagas/types';
import { handleError } from '@sagas/errors';
import {
  fetchCompanyObjectsRequest,
  FetchCompanyObjectsRequestAction,
  fetchCompanyObjectsSuccess,
  createObjectRequest,
  CreateObjectRequestAction,
  createObjectSuccess,
  UpdateObjectRequestAction,
  updateObjectRequest,
  archiveObjectRequest,
  ArchiveObjectRequestAction,
  archiveObjectSuccess,
  setActiveBlinkWhenCreatedNewObject,
  setCurrentCompanyObjectId,
  UpdateStatusObject,
  fetchUpdateStatusRequestSuccess,
  updateObjectStatusRequest,
  fetchCompanyObjectSuccess,
  fetchCompanyObjectRequest,
  updateObjectSuccess,
  resetEditObjectStatus,
  fetchObjectMoveRequest,
  ObjectMoveRequestActions,
  DeleteDocumentCompanyObjectRequestAction,
  deleteDocumentCompanyObjectSuccess,
  deleteDocumentCompanyObjectRequest,
  EditDocumentCompanyObjectRequestAction,
  editDocumentCompanyObjectSuccess,
  editDocumentCompanyObjectRequest,
  resetCreateObjectStatus,
  setArchiveObjectStatus,
} from '@store/companyObjects/actions';
import { getCompanyId } from '@store/company/selectors';
import { getProfileCompanyId } from '@store/profile/selectors';
import { FetchStatus } from '@store/constants';
import {
  getCompanyObjects,
  createObject,
  archiveObject,
  updateStatus,
  getCompanyObject,
  updateCompanyObject,
  moveObject,
  deleteDocumentCompanyObject,
  editDocumentCompanyObject,
} from './api/companyObjects';

const editDocumentCompanyObjectRequestHandler: Saga<EditDocumentCompanyObjectRequestAction> =
  function* ({ payload }) {
    const { id, originalDocumentName, category } = payload;
    try {
      yield call(editDocumentCompanyObject, id, originalDocumentName);
      yield put(
        editDocumentCompanyObjectSuccess({ id, originalDocumentName, category })
      );
      const companyId = yield select(getProfileCompanyId);
      yield put(fetchCompanyObjectRequest({ id: companyId }));
    } catch (e) {
      yield call(handleError, e);
    }
  };

const deleteDocumentCompanyObjectRequestHandler: Saga<DeleteDocumentCompanyObjectRequestAction> =
  function* ({ payload }) {
    const { id, category } = payload;

    try {
      yield call(deleteDocumentCompanyObject, id);
      yield put(deleteDocumentCompanyObjectSuccess({ id, category }));
      const companyId = yield select(getProfileCompanyId);
      yield put(fetchCompanyObjectRequest({ id: companyId }));
    } catch (e) {
      yield call(handleError, e);
    }
  };

const fetchCompanyObjectsRequestHandler: Saga<FetchCompanyObjectsRequestAction> =
  function* ({ payload }) {
    const { id: companyId } = payload;

    try {
      const data = yield call(getCompanyObjects, companyId);

      yield put(fetchCompanyObjectsSuccess({ data }));
    } catch (e) {
      yield call(handleError, e);
    }
  };

const createObjectRequestHandler: Saga<CreateObjectRequestAction> = function* ({
  payload,
}) {
  const { data, companyId } = payload;

  try {
    const object = yield call(createObject, data, companyId);

    yield put(createObjectSuccess({ data: object }));
    yield put(setActiveBlinkWhenCreatedNewObject(true));
    yield put(setCurrentCompanyObjectId(object.id));
  } catch (e) {
    yield call(handleError, e);
    yield put(resetCreateObjectStatus());
  }
};

const getCompanyObjectRequestHandler: Saga<FetchCompanyObjectsRequestAction> =
  function* ({ payload }) {
    try {
      const data = yield call(getCompanyObject, payload.id);
      yield put(fetchCompanyObjectSuccess({ data }));
    } catch (e) {
      yield call(handleError, e);
    }
  };

const updateObjectRequestHandler: Saga<UpdateObjectRequestAction> = function* ({
  payload,
}) {
  try {
    const data = yield call(
      updateCompanyObject,
      payload.companyId,
      payload.id,
      payload.data
    );
    yield put(updateObjectSuccess({ data }));
    yield put(fetchCompanyObjectRequest({ id: payload.companyId }));
  } catch (e) {
    yield call(handleError, e);
    yield put(resetEditObjectStatus());
  }
};

const archiveObjectRequestHandler: Saga<ArchiveObjectRequestAction> =
  function* ({ payload }) {
    const { companyId, id } = payload;

    try {
      yield put(setArchiveObjectStatus(FetchStatus.Fetching));
      yield call(archiveObject, companyId, id);
      yield put(archiveObjectSuccess({ id }));
    } catch (e) {
      yield put(setArchiveObjectStatus(FetchStatus.Error));
      yield call(handleError, e);
    }
  };

const updateStatusObjectHandler: Saga<UpdateStatusObject> = function* ({
  payload,
}) {
  try {
    const id = payload.id;
    const companyId = yield select(getCompanyId);
    yield call(updateStatus, companyId, id, payload);

    yield put(
      fetchUpdateStatusRequestSuccess({
        status: payload.status,
        id: payload.id,
      })
    );
  } catch (e) {
    yield call(handleError, e);
  }
};

const objectMoveRequestHandler: Saga<ObjectMoveRequestActions> = function* ({
  payload,
}) {
  try {
    const { toMove, objectElementId, companyId, subjectElementId } = payload;

    const countPoint = toMove === -1 ? 'before' : 'after';
    yield call(moveObject, companyId, {
      countPoint,
      objectElementId,
      subjectElementId,
    });
  } catch (e) {
    yield call(handleError, e);
  }
};
export default function* root() {
  yield all([
    takeLatest(
      deleteDocumentCompanyObjectRequest,
      deleteDocumentCompanyObjectRequestHandler
    ),
    takeLatest(
      editDocumentCompanyObjectRequest,
      editDocumentCompanyObjectRequestHandler
    ),
    takeLatest(createObjectRequest, createObjectRequestHandler),
    takeLatest(fetchCompanyObjectsRequest, fetchCompanyObjectsRequestHandler),
    takeLatest(fetchCompanyObjectRequest, getCompanyObjectRequestHandler),
    takeLatest(updateObjectRequest, updateObjectRequestHandler),
    takeLatest(archiveObjectRequest, archiveObjectRequestHandler),
    takeLatest(updateObjectStatusRequest, updateStatusObjectHandler),
    takeLatest(fetchObjectMoveRequest, objectMoveRequestHandler),
  ]);
}
