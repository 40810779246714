import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchStatus } from '@store/constants';

export type Message = {
  id: string;
  author: {
    id: string;
    firstName: string;
    lastName: string;
    image: ImageParticipantsConversation;
  } | null;
  content: string;
  createdAt: number;
  conversationId: ConversationId;
};

export type ConversationId = string;

export type ImageParticipantsConversation = {
  fileName: string;
  id: string;
  name: string;
};

export type ParticipantsConversation = {
  id: string;
  name: string;
  image: ImageParticipantsConversation | null;
};

type Reason = { id: string; number: number };

export type Conversation = {
  id: ConversationId;
  numberOfUnreadMessages: number;
  createdAt: number;
  lastMessageId: number;
  order: Reason | null;
  request: Reason | null;
  company: ParticipantsConversation;
  supplier: ParticipantsConversation;
};

export type ChatState = {
  numberOfConversationsWithUnreadedMessages: number;
  strSearch: string;
  fetchStatus: FetchStatus;
  messages: Message[];
  conversations: Conversation[];
  currentСonversation: Conversation | null;
  isSendingMessage: boolean;
  fetchStatusLoadingMessages: FetchStatus;
};

const initialState: ChatState = {
  strSearch: '',
  fetchStatus: FetchStatus.NotFetched,
  conversations: [],
  currentСonversation: null,
  messages: [],
  numberOfConversationsWithUnreadedMessages: 0,
  isSendingMessage: false,
  fetchStatusLoadingMessages: FetchStatus.NotFetched,
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    searchConversationByCompany: (state, action: PayloadAction<string>) => {
      state.strSearch = action.payload;
    },
    setFetchStatus: (state, action: PayloadAction<FetchStatus>) => {
      state.fetchStatus = action.payload;
    },
    setFetchStatusLoadingMessages: (
      state,
      action: PayloadAction<FetchStatus>
    ) => {
      state.fetchStatusLoadingMessages = action.payload;
    },
    setIsSendingMessage: (state, action: PayloadAction<boolean>) => {
      state.isSendingMessage = action.payload;
    },
    setActiveConversation: (
      state,
      action: PayloadAction<Conversation | null>
    ) => {
      const index = state.conversations.findIndex(
        (i) => i.id === action.payload?.id
      );
      if (state.conversations[index]?.numberOfUnreadMessages > 0)
        state.numberOfConversationsWithUnreadedMessages -= 1;
      state.currentСonversation = action.payload;
    },
    fetchConversationsRequestActionSuccess: (
      state,
      action: PayloadAction<Conversation[]>
    ) => {
      state.conversations = action.payload;
      state.fetchStatus = FetchStatus.Fetched;
    },
    fetchConversationRequestActionSuccess: (
      state,
      action: PayloadAction<Conversation>
    ) => {
      const index = state.conversations.findIndex(
        (i) => i.id === action.payload.id
      );

      if (index === -1) {
        state.conversations.push(action.payload);
      } else {
        state.conversations[index] = { ...action.payload };
      }
    },
    fetchNumberOfConversationsWithUnreadedRequestActionSuccess: (
      state,
      action: PayloadAction<
        Pick<ChatState, 'numberOfConversationsWithUnreadedMessages'>
      >
    ) => {
      state.numberOfConversationsWithUnreadedMessages =
        action.payload.numberOfConversationsWithUnreadedMessages;
    },
    fetchSendMessageRequestActionSuccess: (
      state,
      action: PayloadAction<{ message: Message; conversationId: string }>
    ) => {
      state.messages.push({
        ...action.payload.message,
        conversationId: action.payload.conversationId,
      });
    },
    fetchMessagesConversationIdRequestActionSuccess: (
      state,
      action: PayloadAction<{ message: Message[]; conversationId: string }>
    ) => {
      const loadedMessages: string[] = [];

      state.messages.forEach((e) => {
        if (e.conversationId === action.payload.conversationId)
          loadedMessages.push(e.id);
      });

      const updateMessage: Message[] = [];
      action.payload.message.forEach((e) => {
        if (!loadedMessages.includes(e.id))
          updateMessage.push({
            ...e,
            conversationId: action.payload.conversationId,
          });
      });

      state.messages = state.messages.concat(updateMessage);
      state.fetchStatusLoadingMessages = FetchStatus.Fetched;
    },
    setReadAllMessageCurrentConversation: (
      state,
      action: PayloadAction<Pick<Conversation, 'id'>>
    ) => {
      const index = state.conversations.findIndex(
        (i) => i.id === action.payload.id
      );
      state.conversations[index] = {
        ...state.conversations[index],
        numberOfUnreadMessages: 0,
      };
    },
    fetchNewMessagecurrentConversationActionSuccess: (
      state,
      action: PayloadAction<{ messages: Message[]; conversationId: string }>
    ) => {
      state.messages = state.messages.concat(
        action.payload.messages.map((e) => {
          return { ...e, conversationId: action.payload.conversationId };
        })
      );
    },
  },
});

export default chatSlice.reducer;
