import { RootState } from '@store';
import { FetchStatus } from '@store/constants';

export const getUser = (state: RootState) => state.user;
export const getAuth = (state: RootState) => getUser(state).authData;
export const getAccessToken = (state: RootState) => getAuth(state)?.accessToken;

export const getRefreshToken = (state: RootState) =>
  getAuth(state)?.refreshToken;
export const isAuthorized = (state: RootState) => getAuth(state) !== null;

export const isEmailConfirmFetchedStatus = (state: RootState) =>
  getUser(state).emailConfirmStatus === FetchStatus.Fetched;
