import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@hooks/redux';
import { useCurrentLocale } from '@hooks/useCurrentLocale';
import {
  getCurrentCostEstimateId,
  getObjectCostEstimateById,
} from '@store/costEstimates/selectors';
import { getCompanyObjectId } from '@store/companyObjects/selectors';
import { currencyFormat } from '@utils/currency';
import { getCostEstimateSectionsByCostEstimateId } from '@store/costEstimateSections/selectors';
import { getCostEstimateOperations } from '@store/costEstimateOperations/selectors';
import { getCostEstimateResources } from '@store/costEstimateResources/selectors';

export const CostEstimatesTotal = () => {
  const { t } = useTranslation();
  const locale = useCurrentLocale();
  const objectId = useAppSelector(getCompanyObjectId);
  const objectCostEstimate = useAppSelector((state) =>
    getObjectCostEstimateById(state, objectId)
  );

  const currentCostEstimateId = useAppSelector(getCurrentCostEstimateId);

  const operations = useAppSelector(getCostEstimateOperations);
  const resources = useAppSelector(getCostEstimateResources);
  const sections = useAppSelector((state) =>
    getCostEstimateSectionsByCostEstimateId(state, currentCostEstimateId)
  );

  if (objectCostEstimate === undefined) {
    return null;
  }

  const { currencyCode } = objectCostEstimate;

  return (
    <div className="outlay__total">
      <div className="outlay__total-base">
        <div>
          <b>{t('about-object.total-estimated')}</b>
          <ul>
            <li>{t('about-object.section', { count: sections.length })}</li>
            <li>{t('about-object.operation', { count: operations.length })}</li>
            <li>{t('about-object.resource', { count: resources.length })}</li>
          </ul>
        </div>
        <span>
          <p>{t('about-object.cost-amount')}</p>
          <b>
            {currencyFormat(
              sections.reduce((acc, s) => acc + s.cost, 0),
              currencyCode,
              locale
            )}
          </b>
        </span>
        <span>
          <p>{t('about-object.total')}</p>
          <b>
            {currencyFormat(
              sections.reduce((acc, s) => acc + s.saleCost, 0),
              currencyCode,
              locale
            )}
          </b>
        </span>
      </div>
    </div>
  );
};
