import { useCallback, useState } from 'react';
import { ResourceId } from '@store/resources/slice';

type Return = [
  ResourceId[],
  (ids: ResourceId[], forceSelected?: boolean) => void,
  () => void
];

export const useSelectedResources = (): Return => {
  const [selectedIds, setResourceIds] = useState<ResourceId[]>([]);

  const handleSelectResources = (
    ids: ResourceId[],
    forceSelected?: boolean
  ) => {
    const select = forceSelected ?? ids.some((id) => !selectedIds.includes(id));

    setResourceIds((currentSelectedIds) => {
      if (select) {
        return [...new Set([...currentSelectedIds, ...ids])];
      }

      return currentSelectedIds.filter(
        (selectedId) => !ids.includes(selectedId)
      );
    });
  };

  const resetSelected = useCallback(() => {
    setResourceIds([]);
  }, []);

  return [selectedIds, handleSelectResources, resetSelected];
};
