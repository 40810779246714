import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormRegister } from 'react-hook-form';
import cn from 'classnames';
import { EMAIL_PATTERN } from '@constants/patterns';
import styles from './styles.module.sass';

type LoginInputProps = {
  name: string;
  // TODO: прописать типы
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: any;
  isViewConfirmEmailButton?: boolean;
  onBlur: () => void;
  onConfirmClick?: () => void;
  disabledConfirmButton?: boolean;
};

export const LoginInput: FC<LoginInputProps> = ({
  name,
  register,
  errors,
  isViewConfirmEmailButton,
  onConfirmClick,
  disabledConfirmButton,
  ...props
}) => {
  const { t } = useTranslation();

  const emailErrors = () => {
    switch (errors[name]?.type) {
      case 'required':
        return <small>{t('auth.validation-empty')}</small>;
      case 'minLength':
        return <small>{t('auth.validation-email-short')}</small>;
      case 'pattern':
        return <small>{t('auth.validation-email')}</small>;
      default:
        return null;
    }
  };

  return (
    <div className={cn('input', { error: errors[name] })}>
      <input
        type="text"
        placeholder="0"
        maxLength={50}
        {...register(name, {
          required: true,
          minLength: 3,
          pattern: EMAIL_PATTERN,
          ...props,
        })}
      />
      {emailErrors()}
      <label>{t('auth.email')}</label>
      {!emailErrors() && isViewConfirmEmailButton && (
        <button
          className={cn('success', styles.loginFormVerificationButton)}
          onClick={onConfirmClick}
          type="button"
          disabled={disabledConfirmButton}
        >
          {t('auth.click-to get-the-verification-code')}
        </button>
      )}
    </div>
  );
};
