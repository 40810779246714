import { RootState } from '@store';

export const getRootSuppliner = (state: RootState) => state.supplier;

export const getSupplier = (state: RootState) =>
  getRootSuppliner(state).supplierData;

export const getSupplierId = (state: RootState) =>
  getRootSuppliner(state).supplierData?.id;

export const getSupplierSaveStatus = (state: RootState) =>
  getRootSuppliner(state).saveCompanyStatus;

export const getDeleteSupplierStatus = (state: RootState) =>
  getRootSuppliner(state).deleteSupplierStatus;
