/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from '@store/constants';
import {
  ArchiveContactSuccessAction,
  CreateContactRequestAction,
  CreateContactSuccessAction,
  DeleteContactRequestAction,
  DeleteContactSuccessAction,
  FetchContactsRequestAction,
  FetchContactsSuccessAction,
  FetchFullContactsSuccessAction,
  UpdateContactRequestAction,
} from '@store/contacts/actions';

export type ContactId = string;
export type CompanyObjectId = string;
export type OrganizationId = string;

export enum ContactStatus {
  NEW = 'NEW',
  PREPARE = 'PREPARE',
  AGREEMENT = 'APPROVAL',
  IN_WORK = 'PROCESS',
  DONE = 'DONE',
  ARCHIVED = 'ARCHIVED',
}

export type Contact = {
  id: ContactId;
  organizationId?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  comment: string;
  whatsappAvailable: boolean;
  status?: ContactStatus;
};

export type FullContacts = {
  id: ContactId;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  comment: string;
  whatsappAvailable: boolean;
  organizations: {
    id: string;
    name: string;
    organizationForm: string;
    phone: string;
    email: string;
    website: string;
    createdAt: number;
  }[];
};

export type ContactsState = {
  status: FetchStatus;
  contacts: Contact[];
  fullContacts: FullContacts[];
  choiseContact: undefined | Contact;
  choiseFullContact: undefined | FullContacts;
  currentCompanyObjectId: CompanyObjectId;
  activeBlinkWhenCreatedNewContact: boolean;
  latestAddedCompanyObjectId: CompanyObjectId | null;
  createContactStatus: FetchStatus;
  fetchContactsStatus: FetchStatus;
  updateContactStatus: FetchStatus;
  deleteContactStatus: FetchStatus;
  typeView: boolean;
  totalContacts: number;
  lastUpdatedContact: {
    id: string;
    action: 'CREATE' | 'UPDATE' | 'DELETE';
  } | null;
};

export type ContactState = {
  contact: Contact[];
};

const initialState: ContactsState = {
  status: FetchStatus.NotFetched,
  contacts: [],
  fullContacts: [],
  choiseContact: undefined,
  choiseFullContact: undefined,
  currentCompanyObjectId: '',
  activeBlinkWhenCreatedNewContact: false,
  latestAddedCompanyObjectId: null,
  createContactStatus: FetchStatus.NotFetched,
  fetchContactsStatus: FetchStatus.NotFetched,
  updateContactStatus: FetchStatus.NotFetched,
  deleteContactStatus: FetchStatus.NotFetched,
  typeView: true,
  totalContacts: 0,
  lastUpdatedContact: null,
};

export const contactSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    setChoiseContact: (state, action: PayloadAction<ContactId>) => {
      state.choiseContact = state.contacts.find((i) => i.id === action.payload);
    },
    setChoiseFullContact: (state, action: PayloadAction<ContactId>) => {
      state.choiseFullContact = state.fullContacts.find(
        (i) => i.id === action.payload
      );
    },
    deleteChoiseContact: (state) => {
      state.choiseContact = undefined;
    },

    fetchContactsObjectSuccess: (state, action: PayloadAction<Contact[]>) => {
      state.contacts = action.payload;
    },
    fetchContactsObjectRequest: (
      state,
      action: PayloadAction<FetchContactsRequestAction>
    ) => {
      state.status = FetchStatus.Fetching;
    },

    fetchFullContactsObjectSuccess: (
      state,
      action: PayloadAction<FetchFullContactsSuccessAction>
    ) => {
      const {
        payload: { response, isUpdatePage },
      } = action;
      state.totalContacts = response.totalItems;
      if (isUpdatePage) {
        const prevItems = [...state.fullContacts].splice(
          0,
          (response.currentPage - 1) * response.maxPerPage
        );
        state.fullContacts = [...prevItems, ...response.items];
      } else if (response.currentPage === 1) {
        state.fullContacts = response.items;
      } else {
        state.fullContacts = [...state.fullContacts, ...response.items];
      }
      state.fetchContactsStatus = FetchStatus.Fetched;
    },
    fetchFullContactsObjectRequest: (
      state,
      action: PayloadAction<FetchContactsRequestAction>
    ) => {
      state.fetchContactsStatus = FetchStatus.Fetching;
    },

    updateContactRequest: (
      state,
      action: PayloadAction<UpdateContactRequestAction>
    ) => {
      state.updateContactStatus = FetchStatus.Fetching;
    },
    updateContactSuccess: (state, action: PayloadAction<Contact>) => {
      state.lastUpdatedContact = { id: action.payload.id, action: 'UPDATE' };
      state.updateContactStatus = FetchStatus.Fetched;
    },

    deleteContactRequest: (
      state,
      action: PayloadAction<DeleteContactRequestAction>
    ) => {
      state.deleteContactStatus = FetchStatus.Fetching;
    },
    deleteContactSuccess: (
      state,
      action: PayloadAction<DeleteContactSuccessAction>
    ) => {
      state.lastUpdatedContact = { id: action.payload.id, action: 'DELETE' };
      state.deleteContactStatus = FetchStatus.Fetched;
    },

    fetchAddContactRequest: (state, action: PayloadAction<Contact>) => {
      state.contacts.push(action.payload);
    },
    setViewContact: (state, action: PayloadAction<boolean>) => {
      state.typeView = action.payload;
    },
    setActiveBlinkWhenCreatedNewContact: (state, { payload }) => {
      state.activeBlinkWhenCreatedNewContact = payload;
    },
    resetCreateContactStatus: (state) => {
      state.createContactStatus = FetchStatus.NotFetched;
    },
    resetFetchContactsStatus: (state) => {
      state.fetchContactsStatus = FetchStatus.NotFetched;
    },
    resetUpdateContactStatus: (state) => {
      state.updateContactStatus = FetchStatus.NotFetched;
    },
    resetLatestAddedCompanyObjectId: (state) => {
      state.latestAddedCompanyObjectId = null;
    },
    setCurrentCompanyObjectId: (
      state,
      { payload }: PayloadAction<CompanyObjectId>
    ) => {
      const id = payload;

      state.currentCompanyObjectId = id;
    },
    fetchContactSuccess: (
      state,
      action: PayloadAction<FetchContactsSuccessAction>
    ) => {
      const { data } = action.payload;

      state.contacts = data;
    },
    createContactRequest: (
      state,
      { payload }: PayloadAction<CreateContactRequestAction>
    ) => {
      state.latestAddedCompanyObjectId = null;
      state.createContactStatus = FetchStatus.Fetching;
    },
    createContactSuccess: (
      state,
      { payload }: PayloadAction<CreateContactSuccessAction>
    ) => {
      const { data } = payload;

      state.lastUpdatedContact = { id: data.id, action: 'CREATE' };
      state.latestAddedCompanyObjectId = data.id;
      state.contacts = [data, ...state.contacts];
      state.createContactStatus = FetchStatus.Fetched;
    },
    archiveContactSuccess: (
      state,
      { payload }: PayloadAction<ArchiveContactSuccessAction>
    ) => {
      const { id } = payload;

      const index = state.contacts.findIndex((object) => object.id === id);

      state.contacts[index].status = ContactStatus.ARCHIVED;
    },
  },
});
export default contactSlice.reducer;
