import { CompanyObject, CompanyObjectId } from '@store/companyObjects/slice';
import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { currencyFormat } from '@utils/currency';
import { useCurrentLocale } from '@hooks/useCurrentLocale';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { Contact } from '@store/contacts/slice';
import { CompanyOrganizations } from '@store/organizations/slice';
import { getActiveBlinkWhenCreatedNewObject } from '@store/companyObjects/selectors';
import {
  fetchObjectMoveRequest,
  setActiveBlinkWhenCreatedNewObject,
} from '@store/companyObjects/actions';
import { getCompanyId } from '@store/company/selectors';
import { useTranslation } from 'react-i18next';
import { getProfile, getProfileShortName } from '@store/profile/selectors';
import noUserImageIcon from '@static/img/profile4.svg';
import { useDrag, useDrop } from 'react-dnd';
import Image from '@components/commons/Image';
import { TypeSize, setSizeImage } from '@utils/formatImage';
import styles from './styles.module.sass';

export type TaskInfoProps = {
  id: string;
  name: string;
  organization?: CompanyOrganizations;
  contact?: Contact;
  price: number;
  priceCurrencyCode: string;
  isActiveBlink: boolean;
  onClick: (id: CompanyObjectId) => void;
  status: string;
};

export const TaskInfo: React.FC<TaskInfoProps> = ({
  id,
  name,
  organization,
  contact,
  price,
  priceCurrencyCode,
  isActiveBlink,
  onClick,
  status,
}) => {
  const companyId = useAppSelector(getCompanyId);
  const dispatch = useAppDispatch();
  const [isActive, setActive] = useState(false);
  const activeBlinkWhenCreatedNewObject = useAppSelector(
    getActiveBlinkWhenCreatedNewObject
  );
  const currentUser = useAppSelector(getProfile);
  const shortName = useAppSelector(getProfileShortName);

  const { t } = useTranslation();

  useEffect(() => {
    if (isActiveBlink && activeBlinkWhenCreatedNewObject) {
      const intervalId = setInterval(() => setActive((prev) => !prev), 700);

      const timeoutId = setTimeout(() => {
        clearInterval(intervalId);
        setActive(false);
        dispatch(setActiveBlinkWhenCreatedNewObject(false));
      }, 4000);

      return () => {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
        setActive(false);
        dispatch(setActiveBlinkWhenCreatedNewObject(false));
      };
    }
  }, [dispatch, isActiveBlink, activeBlinkWhenCreatedNewObject]);

  const locale = useCurrentLocale();

  const handleMoveObject = (
    toMove: 1 | -1,
    objectElementId: string,
    subjectElementId: string
  ) => {
    if (companyId)
      dispatch(
        fetchObjectMoveRequest({
          companyId,
          toMove,
          objectElementId,
          subjectElementId,
        })
      );
  };

  const ref = useRef<HTMLDivElement>(null);

  const [, drag] = useDrag(() => ({
    type: 'cardObject',
    item: { id, status },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [, dropRef] = useDrop({
    accept: ['cardObject'],
    drop: (item: Pick<CompanyObject, 'id' | 'status'>, monitor) => {
      const hoverCardCompany = ref.current?.getAttribute('data-idobject');

      if (hoverCardCompany) {
        if (ref.current) {
          const currentRow = ref.current.getBoundingClientRect();
          if (currentRow) {
            const h = currentRow.height;
            const y = monitor.getClientOffset()?.y;
            if (y) {
              const hoverActualY: number = y - currentRow.top;
              if (hoverCardCompany)
                handleMoveObject(
                  hoverActualY > h / 2 ? 1 : -1,
                  item.id,
                  hoverCardCompany
                );
            }
          }
        }
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });
  drag(dropRef(ref));

  return (
    <div
      onClick={() => onClick(id)}
      className={cn('object__item', styles.item, isActive && styles.active)}
      ref={ref}
      data-idobject={id}
    >
      <div className="object__item-cap">
        <h4 className={styles.objItemText} title={name}>
          {name}
        </h4>
      </div>
      <div className="object__item-info">
        {(organization || contact) && (
          <p
            className={styles.objItemText}
            title={
              organization?.name ||
              `${contact?.firstName} ${contact?.lastName
                .charAt(0)
                .toLocaleUpperCase()}`
            }
          >
            {organization?.name ||
              `${contact?.firstName} ${contact?.lastName
                .charAt(0)
                .toLocaleUpperCase()}`}
          </p>
        )}
        {!organization && !contact && <p>{t('commons.not-indicated')}</p>}
        {price > 0 && (
          <b
            className={styles.objItemText}
            title={currencyFormat(price, priceCurrencyCode, locale) || ''}
          >
            {currencyFormat(price, priceCurrencyCode, locale)}
          </b>
        )}
      </div>
      <div className="object__item-profile">
        <i>
          {currentUser?.filePath ? (
            <Image url={setSizeImage(currentUser?.filePath, TypeSize.avatar)} />
          ) : (
            <img src={noUserImageIcon} />
          )}
        </i>
        <p className={styles.objItemText} title={shortName}>
          {shortName}
        </p>
      </div>
    </div>
  );
};
