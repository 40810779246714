import { Resource, ResourceId, resourcesSlice } from '@store/resources/slice';
import { CompanyId } from '@store/company/slice';
import {
  CreateResourceParams,
  UpdateResourceParams,
  DeleteResourcesParams,
} from '@sagas/api/resources';
import { createAction } from '@reduxjs/toolkit';

export type FetchResourcesRequestAction = {
  companyId: CompanyId;
};

export type FetchResourcesSuccessAction = {
  companyId: CompanyId;
  data: Resource[];
};

export type CreateResourceRequestAction = {
  companyId: CompanyId;
  temporaryId: string;
  data: CreateResourceParams;
};

export type CreateResourceSuccessAction = {
  companyId: CompanyId;
  temporaryId: string;
  data: Resource;
};

export type UpdateResourceRequestAction = {
  companyId: CompanyId;
  id: ResourceId;
  data: UpdateResourceParams;
};

export type UpdateResourceSuccessAction = {
  companyId: CompanyId;
  id: ResourceId;
  data: UpdateResourceParams;
};

export type DeleteResourcesRequestAction = {
  companyId: CompanyId;
  data: DeleteResourcesParams;
};

export type DeleteResourcesSuccessAction = {
  companyId: CompanyId;
  ids: ResourceId[];
};

export const updateResourceSuccess = createAction<UpdateResourceSuccessAction>(
  'resources/updateResourceSuccess'
);

export const deleteResourcesSuccess =
  createAction<DeleteResourcesSuccessAction>(
    'resources/deleteResourcesSuccess'
  );

export const {
  fetchResourcesRequest,
  fetchResourcesSuccess,
  createResourceRequest,
  createResourceSuccess,
  updateResourceRequest,
  deleteResourcesRequest,
} = resourcesSlice.actions;
