export const getUnixTime = () => Math.round(+new Date() / 1000);

export const toValidDataTime = (ms: number) => {
  switch (ms.toFixed().length) {
    case 10:
      return ms * 1000;
    case 11:
      return ms * 100;
    case 12:
      return ms * 10;
    default:
      return ms;
  }
};

type Locale = 'ru-RU';

type Month = {
  ['ru-RU']: Record<number, string>;
  ['en-EN']: Record<number, string>;
};

const mapMonth: Month = {
  'en-EN': {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'June',
    5: 'July',
    6: 'Jul',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December',
  },
  'ru-RU': {
    0: 'Январь',
    1: 'Февраль',
    2: 'Март',
    3: 'Апрель',
    4: 'Мау',
    5: 'Июнь',
    6: 'Июль',
    7: 'Август',
    8: 'Сентябрь',
    9: 'Октябрь',
    10: 'Ноябрь',
    11: 'Декабрь',
  },
};

export const dateToSrt = (ms: number, isMaxLength = false) => {
  const data = new Date(toValidDataTime(ms));

  const locale = navigator.language as Locale;

  let month = mapMonth[locale][data.getMonth()];

  if (!isMaxLength) month = month.toLocaleLowerCase().slice(0, 3);

  return `${data.getDate()} ${month} ${data.getFullYear()}`;
};
