import React from 'react';
import cn from 'classnames';
import { FormState, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconOpen } from '@static/img/icon-open.svg';
import { useFormErrors } from '@hooks/useFormErrors';
import styles from './styles.module.sass';
// TODO change any
type PasswordInputProps = {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formState: FormState<any>;
};

export const PasswordInput: React.FC<PasswordInputProps> = ({
  name,
  register,
  formState,
}) => {
  const { t } = useTranslation();
  const { errors: formErrors } = formState;
  const errors = useFormErrors(formErrors);

  const [isShowPassword, setIsShowPassword] = React.useState(false);

  const onClickShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const passwordErrors = () => {
    switch (errors[name]?.type) {
      case 'required':
        return <small>{t('auth.validation-empty')}</small>;
      case 'minLength':
        return <small>{t('auth.validation-password-short')}</small>;
      case 'server':
        return <small>{`${errors[name].message}`}</small>;
      default:
        return null;
    }
  };

  return (
    <div
      className={cn('input', 'password', {
        open: isShowPassword,
        error: errors[name],
      })}
    >
      <input
        type={isShowPassword ? 'text' : 'password'}
        placeholder="0"
        {...register(name, {
          required: true,
          minLength: 6,
        })}
      />
      <label>{t('auth.password')}</label>
      {passwordErrors()}
      <i onClick={onClickShowPassword} className={styles.image}>
        <IconOpen />
      </i>
    </div>
  );
};
