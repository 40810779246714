import { CommercialProposal } from '@store/costEstimates/slice';
import { getUnixTime } from 'date-fns';

export const workAtToUnix = (date: CommercialProposal) => {
  const startWorkAtDate = date.startWorkAt;
  const endWorkAtDate = date.endWorkAt;

  const startWorkAtUnixFormat = getUnixTime(new Date(startWorkAtDate));
  const endWorkAtUnixFormat = getUnixTime(new Date(endWorkAtDate));

  return { startWork: startWorkAtUnixFormat, endWork: endWorkAtUnixFormat };
};
