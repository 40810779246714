import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import iconAdd5 from '@static/img/icon-add5.svg';
import { useTranslation } from 'react-i18next';

type OperationCatalogAddFolderButtonProps = {
  onClick: () => void;
};

const OperationCatalogAddFolderButtonRaw: React.FC<
  OperationCatalogAddFolderButtonProps
> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Link to="#" className="manager__folder add" onClick={onClick}>
      <i>
        <img src={iconAdd5} />
      </i>
      {t('directory.create-folder')}
    </Link>
  );
};

export const OperationCatalogAddFolderButton = memo(
  OperationCatalogAddFolderButtonRaw
);
