import { useAppDispatch } from '@hooks/redux';
import { nanoid } from '@reduxjs/toolkit';
import { addMessage } from '@store/errors/actions';
import { useTranslation } from 'react-i18next';

export const TogglePlanActual = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  // TODO пока не реализован полностью функционал  заглушка для пользователя
  const handleClickToggle = () => {
    dispatch(
      addMessage({ id: nanoid(), message: 'Функционал временно не доступен' })
    );
  };
  return (
    <div className="cap__but-switch">
      <div className="switch">
        <input type="checkbox" name="object" onClick={handleClickToggle} />
        <div>
          <p>{t('navigation.plan')}</p>
          <label />
          <p>{t('navigation.fact')}</p>
        </div>
      </div>
    </div>
  );
};
