import { call } from 'redux-saga/effects';
import {
  RestoreRequestAction,
  SavePasswordRequestAction,
} from '@store/user/actions';
import { urlFromTemplate } from '@utils/url';
import { ENDPOINTS } from '@config';
import { postRequestNotAutorization, putRequestNotAutorization } from '.';

export function* reguestRestorePassword(params: RestoreRequestAction) {
  const { data } = yield call(
    postRequestNotAutorization,
    urlFromTemplate(ENDPOINTS.PROFILE.RESET, {}),
    params
  );

  return data as string;
}

export function* reguestResetPassword(params: SavePasswordRequestAction) {
  const { data } = yield call(
    putRequestNotAutorization,
    urlFromTemplate(ENDPOINTS.PROFILE.RESET, {}),
    params
  );

  return data as string;
}
