import { FC, useMemo, useState } from 'react';
import {
  CommercialProposal,
  ObjectCostEstimate,
} from '@store/costEstimates/slice';
import Documents from '@components/Documents';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { getCurrentCostEstimate } from '@store/costEstimates/selectors';
import { CostEstimatesBar } from '@pages/AboutObject/CostEstimatesBar';
import { getCurrentCompanyObject } from '@store/companyObjects/selectors';
import { getProfileCompanyId } from '@store/profile/selectors';
import {
  deleteDocumentCompanyObjectRequest,
  editDocumentCompanyObjectRequest,
  fetchCompanyObjectRequest,
} from '@store/companyObjects/actions';
import { CostEstimateObjectCollection } from '../utils/CostEstimateObjectCollection';
import { TimingProposals } from '../TimingProposals';
import { ObjectIndicators } from '../ObjectIndicators';
import { CommercialProposals } from '../CommercialProposals';

export type ObjectWithCostEstimatesProps = {
  objectCostEstimates: ObjectCostEstimate[];
};

type Category = 'general' | 'customer' | 'contractor';

export const ObjectWithCostEstimates: FC<ObjectWithCostEstimatesProps> = ({
  objectCostEstimates,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const companyId = useAppSelector(getProfileCompanyId);
  const currentCostEstimate = useAppSelector(getCurrentCostEstimate);
  const buildingObjectId = useAppSelector(getCurrentCompanyObject)
    ?.id as string;
  const currentDocumentsCompanyObject = useAppSelector(
    getCurrentCompanyObject
  )?.documents;

  const objectCollection = useMemo(() => {
    return new CostEstimateObjectCollection(objectCostEstimates);
  }, [objectCostEstimates]);

  const [
    currentApprovedCommercialProposal,
    setCurrentApprovedCommercialProposal,
  ] = useState<CommercialProposal | null>(null);

  const handleFindApprovedProposal = (obj: CommercialProposal | null) => {
    setCurrentApprovedCommercialProposal(obj);
  };

  const currentCostEstimateCostMoreThanNull =
    currentCostEstimate && currentCostEstimate.saleCost > 0;

  const onSuccessUpload = () => {
    if (companyId) {
      dispatch(fetchCompanyObjectRequest({ id: companyId }));
    }
  };

  const handleEditDocument = (
    id: string,
    originalDocumentName: string,
    category: Category
  ) => {
    dispatch(
      editDocumentCompanyObjectRequest({
        id,
        originalDocumentName,
        category,
      })
    );
  };

  const handleDeleteDocument = (id: string, category: Category) => {
    dispatch(
      deleteDocumentCompanyObjectRequest({
        id,
        category,
      })
    );
  };

  return (
    <div className="content__base">
      <div className="obj">
        <div className="obj__cap">
          <h4>{t('about-object.object-indicators')}</h4>
          <CostEstimatesBar />
          <TimingProposals
            previousCommercialProposal={
              currentCostEstimate?.commercialProposals[0]
            }
            currentApprovedCommercialProposal={
              currentApprovedCommercialProposal
            }
          />
        </div>
        <ObjectIndicators />
        {currentCostEstimateCostMoreThanNull && (
          <CommercialProposals
            objectCollection={objectCollection.getObjectsByKey(
              currentCostEstimate?.id
            )}
            onApproved={handleFindApprovedProposal}
            commercialProposal={
              objectCollection.getCommercialProposalsByKey(
                currentCostEstimate?.id
              )[0]
            }
            currentCostEstimateId={currentCostEstimate?.id}
          />
        )}
        <div className="obj__cap">
          <h4>{t('documents.documentation-on-the-object')}</h4>
        </div>
        <div className="obj__doc">
          <Documents
            params={{ buildingObjectId, category: 'general' }}
            docsForRender={currentDocumentsCompanyObject?.general || []}
            onSuccessUpload={onSuccessUpload}
            title="documents.general-documents"
            handleDeleteDocument={(id) => handleDeleteDocument(id, 'general')}
            handleEditDocument={(id, originalDocumentName) =>
              handleEditDocument(id, originalDocumentName, 'general')
            }
          />
          <Documents
            params={{ buildingObjectId, category: 'customer' }}
            docsForRender={currentDocumentsCompanyObject?.customer || []}
            onSuccessUpload={onSuccessUpload}
            title="documents.customer-documents"
            handleDeleteDocument={(id) => handleDeleteDocument(id, 'customer')}
            handleEditDocument={(id, originalDocumentName) =>
              handleEditDocument(id, originalDocumentName, 'customer')
            }
          />
          <Documents
            params={{ buildingObjectId, category: 'contractor' }}
            docsForRender={currentDocumentsCompanyObject?.contractor || []}
            onSuccessUpload={onSuccessUpload}
            title="documents.contractor-documents"
            handleDeleteDocument={(id) =>
              handleDeleteDocument(id, 'contractor')
            }
            handleEditDocument={(id, originalDocumentName) =>
              handleEditDocument(id, originalDocumentName, 'contractor')
            }
          />
        </div>
      </div>
    </div>
  );
};
