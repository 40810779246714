import { OrderDocuments, OrderStatus } from '@store/ordersCustomer/slice';
import OrderModalStatusAlert from '../OrderModalStatusAlert';
import OrderModalStatusList from '../OrderModalStatusList';

type Props = {
  status: OrderStatus;
  invoiceValidity: number;
  invoice: OrderDocuments;
  needInvoice: boolean;
  orderId: string;
};
const OrdersModalStatus = ({
  status,
  needInvoice,
  invoiceValidity,
  invoice,
  orderId,
}: Props) => {
  return (
    <div className="order__status">
      <OrderModalStatusList status={status} needInvoice={needInvoice} />
      <OrderModalStatusAlert
        status={status}
        needInvoice={needInvoice}
        invoiceValidity={invoiceValidity}
        invoice={invoice}
        orderId={orderId}
      />
    </div>
  );
};

export default OrdersModalStatus;
