import { useTranslation } from 'react-i18next';
import { ReactComponent as PasswordIcon } from '@static/img/icon-resetpassword.svg';
import { ResetPasswordForm } from '../ResetPasswordForm';

type EditPasswordProps = {
  onClose: () => void;
};

export const EditPassword = ({ onClose }: EditPasswordProps) => {
  const { t } = useTranslation();
  const styles = {
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
  };
  return (
    <>
      <div style={styles}>
        <PasswordIcon />
      </div>
      <div style={styles}>
        <h2>{t('auth.changing-password')}</h2>
      </div>
      <ResetPasswordForm onClose={onClose} />
    </>
  );
};
