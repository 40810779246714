import { ForwardedRef, forwardRef } from 'react';
import { ChangeHandler } from 'react-hook-form';
import cn from 'classnames';

export enum InputSize {
  Auto = '',
  Small = 'small',
  Medium = 'medium',
}

type InputProps = {
  title?: string;
  name: string;
  onChange: ChangeHandler;
  onBlur: ChangeHandler;
  error?: string;
  size?: InputSize;
  border?: boolean;
};

export const InputWithRef = (
  {
    title,
    name,
    onChange,
    onBlur,
    error,
    size = InputSize.Auto,
    border = false,
  }: InputProps,
  ref: ForwardedRef<HTMLInputElement>
): JSX.Element => {
  return (
    <div
      className={cn('input', size, {
        border,
        error,
      })}
    >
      <input
        type="text"
        name={name}
        ref={ref}
        onChange={onChange}
        onBlur={onBlur}
      />
      {title && <label>{title}</label>}
      {error && <small>{error}</small>}
    </div>
  );
};

export const Input = forwardRef<HTMLInputElement, InputProps>(InputWithRef);
