import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Saga } from '@sagas/types';
import { handleError } from '@sagas/errors';
import {
  CreateOrderCustomerRequestAction,
  CreateRequestCustomerRequestAction,
  DeleteRequestCustomerItemRequestAction,
  FetchRequestCustomerRequestAction,
  createOrderCustomerRequest,
  createOrderCustomerSuccess,
  createRequestCustomerRequest,
  createRequestCustomerSuccess,
  deleteRequestCustomerItemRequest,
  deleteRequestCustomerItemSuccess,
  fetchRequestCustomerObjectSuccess,
  fetchRequestCustomerRequest,
  setDeleteRequestCustomerItemStatus,
  setErrorCreateOrderCustomerStatus,
  setErrorCreateRequestCustomerStatus,
} from '@store/requestCustomer/actions';
import { NoticeTypes } from '@utils/notifications';
import { addMessage } from '@store/errors/actions';
import { FetchStatus } from '@store/constants';
import {
  createOrderCustomer,
  createRequestCustomer,
  deleteRequestCustomerItem,
  getRequestCustomer,
} from './api/requestCustomer';

const createRequestCustomerRequestHandler: Saga<CreateRequestCustomerRequestAction> =
  function* ({ payload }) {
    const { id, costEstimateItemsIds } = payload;
    try {
      yield call(createRequestCustomer, {
        id,
        costEstimateItemsIds,
      });
      yield put(createRequestCustomerSuccess());
      yield put(
        addMessage({
          id: 'createRequestCustomer',
          message: 'notice.you-have-successfully-requested-an-offer',
          type: NoticeTypes.success,
        })
      );
    } catch (e) {
      yield put(setErrorCreateRequestCustomerStatus());
      yield call(handleError, e);
    }
  };

const createOrderCustomerRequestHandler: Saga<CreateOrderCustomerRequestAction> =
  function* ({ payload }) {
    const { requestId, requestItemsIds } = payload;
    try {
      yield call(createOrderCustomer, {
        requestId,
        requestItemsIds,
      });
      yield put(createOrderCustomerSuccess());
      yield put(
        addMessage({
          id: 'createOrderCustomer',
          message: 'notice.you-have-successfully-placed-an-order',
          type: NoticeTypes.success,
        })
      );
      const data = yield call(getRequestCustomer, payload.requestId);
      yield put(fetchRequestCustomerObjectSuccess(data));
    } catch (e) {
      yield put(setErrorCreateOrderCustomerStatus());
      yield call(handleError, e);
    }
  };

const getRequestCustomerRequestHandler: Saga<FetchRequestCustomerRequestAction> =
  function* ({ payload }) {
    try {
      const data = yield call(getRequestCustomer, payload.id);
      yield put(fetchRequestCustomerObjectSuccess(data));
    } catch (e) {
      yield call(handleError, e);
    }
  };

const deleteRequestCustomerItemHandler: Saga<DeleteRequestCustomerItemRequestAction> =
  function* ({ payload }) {
    const { resourceId, requestId } = payload;
    try {
      yield put(setDeleteRequestCustomerItemStatus(FetchStatus.Fetching));
      yield call(deleteRequestCustomerItem, resourceId, requestId);
      yield put(deleteRequestCustomerItemSuccess());
      const data = yield call(getRequestCustomer, payload.requestId);
      yield put(fetchRequestCustomerObjectSuccess(data));
    } catch (e) {
      yield put(setDeleteRequestCustomerItemStatus(FetchStatus.Error));
      yield call(handleError, e);
    }
  };

export default function* root() {
  yield all([
    takeLatest(
      createRequestCustomerRequest,
      createRequestCustomerRequestHandler
    ),
    takeLatest(createOrderCustomerRequest, createOrderCustomerRequestHandler),
    takeLatest(fetchRequestCustomerRequest, getRequestCustomerRequestHandler),
    takeLatest(
      deleteRequestCustomerItemRequest,
      deleteRequestCustomerItemHandler
    ),
  ]);
}
