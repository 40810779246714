import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCurrentLocale } from '@hooks/useCurrentLocale';
import styles from './styles.module.sass';

// login__but - оригинальный класс
export const ChangeLanguage = () => {
  const { i18n } = useTranslation();
  const locale = useCurrentLocale();

  // TODO: fire redux action instead of straight change, store and persist language in application state
  const changeLanguage = (language: string) => i18n.changeLanguage(language);
  const switchToEn = () => changeLanguage('en');
  const switchToRu = () => changeLanguage('ru');

  const languages = [
    { language: 'Русская версия', onClick: switchToRu, lang: 'ru' },
    { language: 'English version', onClick: switchToEn, lang: 'en' },
  ];

  return (
    <div className={cn('login__but', styles.login__but)}>
      <ul>
        {languages.map((language) => {
          return (
            <li key={language.language}>
              <Link
                to="#"
                className={cn(language.lang === locale && styles.active)}
                onClick={language.onClick}
              >
                {language.language}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
