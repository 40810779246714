/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useReducer } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Response } from '@store/requestSupplier/slice';
import Calendar, { toValidDataTime } from '@components/commons/Calendar';
import { PayloadAction } from '@reduxjs/toolkit';

interface IItemProps {
  currencySymbolById: string | null | undefined;
  response: Response;
  handleBluerInput: (nameField: keyof Response, val: string) => void;
  handleSetDeliveryTime: (ms: number) => void;
  unitName: string;
  categoryName: string;
  requestId: string;
}

const reduser = (
  state: Response,
  action: PayloadAction<{
    field: keyof Response;
    value?: string;
    cb?: (field: keyof Response, value: string) => void;
  }>
) => {
  const { value, field, cb } = action.payload;
  switch (action.type) {
    case 'editPrice': {
      return { ...state, [field]: value };
    }
    case 'blurPrice': {
      const price =
        state.price !== '' ? parseFloat(state.price).toFixed(2) : '0';
      cb &&
        setTimeout(() => {
          cb('price', price);
        }, 0);
      return {
        ...state,
        [field]: price,
      };
    }
    case 'editName': {
      return { ...state, [field]: value };
    }
    default:
      return state;
  }
};

export default function ItemReady({
  response,
  currencySymbolById,
  unitName,
  categoryName,
  handleBluerInput,
  handleSetDeliveryTime,
}: IItemProps) {
  const { t } = useTranslation();

  const [state, disp] = useReducer(reduser, response);

  const updatedCost = state.amount * Number.parseFloat(state.price);

  return (
    <div className={cn('req__cot')} style={{ background: '#F5F5F9' }}>
      <sub />
      <s className="color-warning">{state.type}</s>

      <h4>
        <input
          type="text"
          value={state.name}
          name="name"
          style={{ width: '100%' }}
          onChange={(e) =>
            disp({
              type: 'editName',
              payload: { value: e.target.value, field: 'name' },
            })
          }
          onBlur={(e) => handleBluerInput('name', e.target.value)}
        />
      </h4>
      <small>{categoryName}</small>
      <p>{state.amount}</p>
      <p>{t(`units.${unitName}`)}</p>
      <input
        type="text"
        placeholder={currencySymbolById || ''}
        value={state.price}
        name="price"
        onChange={(e) =>
          disp({
            type: 'editPrice',
            payload: {
              field: 'price',
              value: e.target.value.replace(/[^\d.]/g, ''),
            },
          })
        }
        onBlur={() =>
          disp({
            type: 'blurPrice',
            payload: {
              field: 'price',
              cb: (field, val) => handleBluerInput(field, val),
            },
          })
        }
      />
      <p>
        {!Number.isNaN(updatedCost)
          ? updatedCost.toFixed(2)
          : state.cost.toFixed(2)}
      </p>
      <time>
        <Calendar
          setDate={handleSetDeliveryTime}
          short
          position="right"
          popup
          date={
            state.deliveryTime ? toValidDataTime(state.deliveryTime) : undefined
          }
        />
      </time>
    </div>
  );
}
