import styles from './styles.module.sass';

type Props = {
  progress: number;
  diff: number;
};

const setColorTimerBar = (percent: number) => {
  if (percent < 25) return '#FE4848'; // red
  if (percent < 50) return '#FEA548'; // yellow
  return '#7173DF'; // purple
};
const circleLength = 264;
const onePercent = 264 / 100;

const ProgressBar = ({ progress, diff }: Props) => {
  const dayCounter = diff >= 0 ? diff : 0;

  return (
    <>
      <svg
        width="100"
        height="100"
        viewBox="0 0 100 100"
        className={styles.progressBarSize}
      >
        <circle
          cx="50"
          cy="50"
          r="42"
          strokeDasharray={circleLength}
          style={{
            fill: 'transparent',
            stroke: '#EBEBEF',
            strokeWidth: 16,
            strokeDashoffset: 0,
          }}
        />
        <circle
          cx="50"
          cy="50"
          r="42"
          strokeDasharray={circleLength}
          style={{
            fill: 'transparent',
            stroke: setColorTimerBar(progress),
            strokeWidth: 16,
            strokeDashoffset: circleLength - onePercent * progress,
          }}
        />
      </svg>
      <b className={styles.progressBarHours}>{dayCounter}</b>
    </>
  );
};
export default ProgressBar;
