import { ReactComponent as IconReset } from '@static/img/icon-resetpassword.svg';

export const ResetPasswordLogo = () => {
  return (
    <div className="login__start">
      <i>
        <IconReset />
      </i>
    </div>
  );
};
