import { useState } from 'react';
import { ReactComponent as ArrowIconExport } from '@static/img/icon-circle-arrow.svg';
// import { ReactComponent as ArrowIconImport } from '@static/img/icon-circle-arrow2.svg';
import { ReactComponent as OptionsIcon } from '@static/img/icon-setting.svg';
import { useLocation } from 'react-router-dom';
import { Modal, ModalSize } from '@components/Modal';
import { ObjectModal } from '@pages/Dashboard/ObjectModal';
import { EditObjectForm } from '@pages/Dashboard/ObjectModal/EditObjectForm';
import { getCompanyCurrencyCode } from '@store/company/selectors';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { ReactComponent as IconOffer } from '@static/img/icon-company2.svg';
import {
  getCompanyObjectId,
  getCurrentCompanyObject,
} from '@store/companyObjects/selectors';

import { RadialPrimaryButton } from '@components/commons/RadialPrimaryButton';
import cn from 'classnames';
import { getProfileCompanyId } from '@store/profile/selectors';
import {
  getCurrentCostEstimate,
  getCurrentCostEstimateId,
  getObjectCostEstimates,
  getSelectedIds,
} from '@store/costEstimates/selectors';
import { axiosInstance } from '@utils/axios';
import { getAccessToken } from '@store/user/selectors';
import IconButton from '@components/commons/IconButton';
import { useTranslation } from 'react-i18next';
import {
  createRequestCustomerRequest,
  resetCreateRequestCustomerStatus,
} from '@store/requestCustomer/actions';
import {
  getCostEstimateResources,
  isCostEstimateResourcesFetched,
} from '@store/costEstimateResources/selectors';
import { clearSelectedIds } from '@store/costEstimates/actions';
import { ConfirmationModal } from '@components/commons/ConfirmationModal';
import { createRequestCustomerStatus } from '@store/requestCustomer/selectors';
import styles from './styles.module.sass';
// import ImportExcelModal from '../ImportExcelModal/index.tsx';

export const OptionObject = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const companyId = useAppSelector(getProfileCompanyId);
  const objectId = useAppSelector(getCompanyObjectId);
  const costEstimateId = useAppSelector(getCurrentCostEstimateId);
  const objectCostEstimates = useAppSelector(getObjectCostEstimates);
  const currentCostEstimate = useAppSelector(getCurrentCostEstimate);
  const accessToken = useAppSelector(getAccessToken);
  const selectedIds = useAppSelector(getSelectedIds);
  const { pathname } = useLocation();
  const isCostEstimateTab = pathname.indexOf('cost-estimates') >= 0;
  const isObjectTab = pathname.indexOf('/object/') >= 0;
  const isSupplyTab = pathname.indexOf('/supply/') >= 0;

  const companyCurrencyCode = useAppSelector(getCompanyCurrencyCode);
  const getCompanyObject = useAppSelector(getCurrentCompanyObject);

  const costEstimateResources = useAppSelector(getCostEstimateResources);
  const costEstimateResourceStatusFetched = useAppSelector(
    isCostEstimateResourcesFetched
  ); // Делаю disable кнопки пока не прогружу ресурсы
  const statusCreateRequestCustomer = useAppSelector(
    createRequestCustomerStatus
  );

  const countIndex =
    objectCostEstimates.findIndex(
      (item) => item?.id === currentCostEstimate?.id
    ) + 1;

  const [isViewModal, setIsViewModal] = useState(false);

  const handleModalClose = () => setIsViewModal(false);

  const handleOpenModal = () => {
    setIsViewModal(true);
  };

  // Ждем когда будет готово на бэке и уберем коммент

  // const [isViewImportModal, setIsViewImportModal] = useState(false);

  // const handleOpenImportModal = () => {
  //   setIsViewImportModal(true);
  // };
  // const handleCloseImportModal = () => {
  //   setIsViewImportModal(false);
  // };

  //

  const [isViewExport, setIsViewExport] = useState(false);
  const [isViewConfirmModal, setIsViewConfirmModal] = useState(false);

  const handleClickExport = () => {
    setIsViewExport(!isViewExport);
  };

  const handleOpenConfirmModal = () => {
    dispatch(resetCreateRequestCustomerStatus());
    setIsViewConfirmModal(!isViewConfirmModal);
  };

  const handleCloseConfirmModal = () => {
    setIsViewConfirmModal(false);
  };

  const handleClickAlbinaButton = () => {
    if (costEstimateId) {
      dispatch(
        createRequestCustomerRequest({
          costEstimateItemsIds: selectedIds,
          id: costEstimateId,
        })
      );
      dispatch(clearSelectedIds());
    }
  };

  const estimateName = {
    budgetEstimates: 'budget',
    summaryEstimate: 'summary',
    budgetEstimatePlanFact: 'customer',
  };

  const excels = [
    {
      name: estimateName.budgetEstimates,
      title: t('about-object.budget-estimates'),
    },
    {
      name: estimateName.summaryEstimate,
      title: t('about-object.summary-estimate'),
    },
    {
      name: estimateName.budgetEstimatePlanFact,
      title: t('about-object.budget-estimates-plan-fact'),
    },
  ];

  const handleClickDownloadExcel = (name: string, nameEstimate: string) => {
    const urlDownloadExcel = `/company/${companyId}/building-object/${objectId}/cost-estimate/${costEstimateId}/excel/${name}`;
    axiosInstance
      .get(urlDownloadExcel, {
        headers: { Authorization: `Bearer ${accessToken}` },
        responseType: 'blob',
      })
      .then((response) => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `${nameEstimate} №${countIndex}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };

  return (
    <div className="content__cap-but">
      {/* style написан временно. 
      Список с экспортом смет выходит за ширину страницы в Снабжение. 
      Как появится новый дизайн - исправлю. */}
      <div
        className={cn('drop', isViewExport && 'active')}
        style={{ marginRight: isSupplyTab ? '28px' : '15px' }}
      >
        <RadialPrimaryButton
          className="border gray"
          icon={<ArrowIconExport />}
          onClick={handleClickExport}
        >
          {t('about-object.export')}
        </RadialPrimaryButton>
        <ul className="drop-box">
          {excels.map(({ name, title }) => (
            <li
              onClick={() => handleClickDownloadExcel(name, title)}
              key={title}
            >
              {title}
            </li>
          ))}
          {
            // Пока нет функционала. В будущем появится.
            /* <li>Смета для заказчика</li>
              <li>Смета для подрядчика</li> */
          }
        </ul>
      </div>
      {/* Ждем когда будет готово на бэке и уберем коммент */}
      {/* {!isSupplyTab && (
        <>
          <div className="drop">
            <RadialPrimaryButton
              className="border gray"
              icon={<ArrowIconImport />}
              onClick={handleOpenImportModal}
            >
              {t('about-object.import')}
            </RadialPrimaryButton>
          </div>
          <ImportExcelModal
            isViewModal={isViewImportModal}
            onCancel={handleCloseImportModal}
          />
        </>
      )} */}
      {isObjectTab && (
        <div className="drop">
          <RadialPrimaryButton
            className="border gray"
            icon={<OptionsIcon />}
            onClick={handleOpenModal}
          >
            {t('about-object.settings')}
          </RadialPrimaryButton>
        </div>
      )}
      <Modal
        visible={isViewModal}
        onBackgroundClick={handleModalClose}
        size={ModalSize.Auto}
      >
        <ObjectModal onClose={handleModalClose}>
          <EditObjectForm
            companyCurrencyCode={companyCurrencyCode as string}
            dataObject={getCompanyObject}
          />
        </ObjectModal>
      </Modal>
      {isCostEstimateTab && (
        <>
          <IconButton
            className={styles.icon_button}
            onClick={handleOpenConfirmModal}
            disabled={
              !costEstimateResources.length ||
              !costEstimateResourceStatusFetched
            }
          >
            <span className="snap border open-modal">
              <IconOffer />
              Запросить предложения на Строй.рф
            </span>
          </IconButton>
          <ConfirmationModal
            confirmTitle={t(
              !selectedIds.length
                ? 'estimate.to-form-an-application-for-all-positions-in-the-estimate'
                : 'confirm.to-form-an-application-for-the-selected-positions'
            )}
            isVisibleModal={isViewConfirmModal}
            status={statusCreateRequestCustomer}
            onCancel={handleCloseConfirmModal}
            onConfirm={handleClickAlbinaButton}
          />
        </>
      )}
    </div>
  );
};
