import { ReactComponent as IconRestore } from '@static/img/icon-restore.svg';

export const RestoreLogo = () => {
  return (
    <div className="login__start">
      <i>
        <IconRestore />
      </i>
      {/* <s>
        <LoginLogo2 />
      </s> */}
    </div>
  );
};
