import { CompanyId } from '@store/company/slice';
import { CompanyObjectId } from '@store/companyObjects/slice';
import { CostEstimateId } from '@store/costEstimates/slice';
import { CostEstimateSectionId } from '@store/costEstimateSections/slice';
import {
  CostEstimateOperationId,
  costEstimateOperationsSlice,
} from '@store/costEstimateOperations/slice';
import {
  FetchedCostEstimateOperation,
  CreateCostEstimateOperationData,
  UpdateCostEstimateOperationData,
} from '@sagas/api/costEstimateOperations';
import { createAction } from '@reduxjs/toolkit';
import { CreateCostEstimateResourceData } from '@sagas/api/costEstimateResources';
import { OperationId } from '@store/operations/slice';

export type FetchCostEstimateOperationRequestAction = {
  operationId: OperationId;
};
export type FetchCostEstimateOperationSuccessAction = {
  data: FetchedCostEstimateOperation;
};

export type FetchCostEstimateOperationsRequestAction = {
  companyId: CompanyId;
  objectId: CompanyObjectId;
  costEstimateId: CostEstimateId;
};
export type FetchCostEstimateOperationsSuccessAction = {
  data: FetchedCostEstimateOperation[];
};

export type CreateCostEstimateOperationRequestAction = {
  temporaryId: string;
  companyId: CompanyId;
  objectId: CompanyObjectId;
  costEstimateId: CostEstimateId;
  sectionId: CostEstimateSectionId;
  data: CreateCostEstimateOperationData;
};
export type CreateCostEstimateOperationSuccessAction = {
  temporaryId: string;
  companyId: CompanyId;
  objectId: CompanyObjectId;
  costEstimateId: CostEstimateId;
  sectionId: CostEstimateSectionId;
  data: FetchedCostEstimateOperation;
};

export type UpdateCostEstimateOperationRequestAction = {
  companyId: CompanyId;
  objectId: CompanyObjectId;
  costEstimateId: CostEstimateId;
  id: CostEstimateOperationId;
  data: UpdateCostEstimateOperationData;
};
export type UpdateCostEstimateOperationSuccessAction = {
  companyId: CompanyId;
  objectId: CompanyObjectId;
  costEstimateId: CostEstimateId;
  id: CostEstimateOperationId;
  data: FetchedCostEstimateOperation;
};

export type DeleteCostEstimateOperationRequestAction = {
  companyId: CompanyId;
  objectId: CompanyObjectId;
  costEstimateId: CostEstimateId;
  id: CostEstimateOperationId;
};
export type DeleteCostEstimateOperationSuccessAction = {
  companyId: CompanyId;
  objectId: CompanyObjectId;
  costEstimateId: CostEstimateId;
  id: CostEstimateOperationId;
};

export type CreateCostEstimateOperationsWithResourcesRequestAction = {
  companyId: CompanyId;
  objectId: CompanyObjectId;
  costEstimateId: CostEstimateId;
  sectionId: CostEstimateSectionId;
  operationsData: CreateCostEstimateOperationData[];
  resourcesData: CreateCostEstimateResourceData[];
};

export type CostEstimateOperationMoveRequestActions = {
  companyId: CompanyId;
  objectId: CompanyObjectId;
  costEstimateId: CostEstimateId;
  objectElementId: string;
  subjectElementId: string;
  toMove: -1 | 1;
};

export const createCostEstimateOperationsWithResourcesRequest =
  createAction<CreateCostEstimateOperationsWithResourcesRequestAction>(
    'costEstimateOperations/createCostEstimateOperationsWithResourcesRequest'
  );

export const {
  fetchCostEstimateOperationSuccess,
  fetchCostEstimateOperationRequest,
  setErrorDeleteCostEstimateOperationStatus,
  resetDeleteCostEstimateOperationStatus,
  fetchCostEstimateOperationsRequest,
  fetchCostEstimateOperationsSuccess,
  createCostEstimateOperationRequest,
  createCostEstimateOperationSuccess,
  updateCostEstimateOperationRequest,
  updateCostEstimateOperationSuccess,
  deleteCostEstimateOperationRequest,
  deleteCostEstimateOperationSuccess,
  setCopyOperation,
  fetchostEstimateOperationMoveRequest,
} = costEstimateOperationsSlice.actions;
