import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import removeIcon from '@static/img/icon-remove4.svg';

type ResourcesBottomPanelProps = {
  selectedCount: number;
  onMoveClick?: () => void;
  onDeleteClick?: () => void;
};

export const ResourcesBottomPanel: FC<ResourcesBottomPanelProps> = ({
  selectedCount,

  onDeleteClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className="outlay__total active">
      <div className="outlay__total-check">
        <div>
          <i />
          <p>
            {t('directory.selected-positions-1')}{' '}
            <b>
              {t('directory.selected-positions-2', { count: selectedCount })}
            </b>
          </p>
        </div>
        <ul>
          <li onClick={onDeleteClick}>
            <img src={removeIcon} alt={t('directory.remove-items') ?? ''} />
            {t('directory.remove-items')}
          </li>
          {/* <li onClick={onMoveClick}> */}
          {/*  <img src={copyIcon} alt="Переместить элементы" /> */}
          {/*  Переместить элементы */}
          {/* </li> */}
        </ul>
      </div>
    </div>
  );
};
