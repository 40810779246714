import { AVAILABLE_PHASE_COLOR } from '@constants/phases';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { getObjectUrl } from '@pages';
import { CompanyObject, ObjectStatus } from '@store/companyObjects/slice';
import { getProfile, getProfileShortName } from '@store/profile/selectors';
import cn from 'classnames';
import { ReactComponent as NoUserImageIcon } from '@static/img/profile4.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import {
  fetchObjectMoveRequest,
  setCurrentCompanyObjectId,
} from '@store/companyObjects/actions';
import { TypeSize, setSizeImage } from '@utils/formatImage';
import Image from '@components/commons/Image';

interface ITableRow {
  row: CompanyObject;
  companyId: string;
}

type PhaseNamesData = {
  title: string;
  status: ObjectStatus;
};

export default function TableRow({ row, companyId }: ITableRow) {
  const { t } = useTranslation();

  const currentUser = useAppSelector(getProfile);
  const profile = useAppSelector(getProfileShortName);

  const phaseNames: PhaseNamesData[] = [
    {
      title: t('object.new-application'),
      status: ObjectStatus.NEW,
    },
    {
      title: t('object.cost-estimate-preparation'),
      status: ObjectStatus.PREPARE,
    },
    {
      title: t('object.commercial-proposal-approval'),
      status: ObjectStatus.AGREEMENT,
    },
    {
      title: t('object.in-work'),
      status: ObjectStatus.IN_WORK,
    },
  ];
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClickIDObject = (id: string) => {
    dispatch(setCurrentCompanyObjectId(id));
    navigate(getObjectUrl(id));
  };
  const status = phaseNames.find((i) => i.status === row.status);

  const handleMoveObject = (
    toMove: 1 | -1,
    objectElementId: string,
    subjectElementId: string
  ) => {
    dispatch(
      fetchObjectMoveRequest({
        companyId,
        toMove,
        objectElementId,
        subjectElementId,
      })
    );
  };

  const ref = useRef<HTMLDivElement>(null);

  const [isTop, setIsTop] = useState(true);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'row',
    item: row,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [{ isOver }, dropRef] = useDrop({
    accept: ['row'],
    drop: (item: CompanyObject, monitor) => {
      const id = ref.current?.getAttribute('id');
      if (ref.current) {
        const currentRow = ref.current.getBoundingClientRect();
        if (currentRow) {
          const h = currentRow.height;
          const y = monitor.getClientOffset()?.y;
          if (y) {
            const hoverActualY: number = y - currentRow.top;
            if (id)
              handleMoveObject(hoverActualY > h / 2 ? 1 : -1, item.id, id);
          }
        }
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
    hover: (item, monitor) => {
      const id = ref.current?.getAttribute('id');

      if (ref.current) {
        const currentRow = ref.current.getBoundingClientRect();
        if (currentRow) {
          const h = currentRow.height;
          const y = monitor.getClientOffset()?.y;
          if (y) {
            const hoverActualY: number = y - currentRow.top;
            if (id) setIsTop(hoverActualY < h / 2);
          }
        }
      }
    },
  });

  drag(dropRef(ref));
  return (
    <div
      className="object__table-block"
      onClick={() => handleClickIDObject(row.id)}
      style={{
        opacity: isDragging ? '0.2' : '1',
        borderTop: isOver && isTop ? '1px solid #FF9292' : '1px solid #fff',
        borderBottom: isOver && !isTop ? '1px solid #FF9292' : '1px solid #fff',
      }}
    >
      <div
        data-href="./modal/object/object-edit.php"
        className="object__line open-modal"
        ref={ref}
        id={row.id}
      >
        <div className="object__line-sort" />
        <div className="object__line-status">
          <p className={cn(AVAILABLE_PHASE_COLOR[status?.status || 'NEW'])}>
            {status?.title}
          </p>
        </div>
        <div className="object__line-name">
          <h4>{row.name}</h4>
        </div>
        <div className="object__line-client">
          {(row.organization || row.contact) && (
            <p>
              {row.organization?.name ||
                `${row.contact?.firstName} ${row.contact?.lastName
                  .charAt(0)
                  .toLocaleUpperCase()}`}
            </p>
          )}
          {(!row.organization || !row.contact) && (
            <p>{t('commons.not-indicated')}</p>
          )}
        </div>
        <div className="object__line-price">
          <p>{row.cost.toFixed(2)} ₽</p>
        </div>
        {/* TODO временно до полной работы функционала */}
        {/* <div className="object__line-update">
                  <p>{t('threeDaysAgo')}</p>
                </div> */}
        {/* <div className="object__line-progress">
                  <div>
                    <p>
                      <img src={iconFindAlbinaMarket} />
                      {t('directory.we-select-the-best-on-AlbinaMarket')}
                    </p>
                    <span>
                      <i />
                      <s style={{ width: ' 40%' }} />
                    </span>
                  </div>
                </div> */}
        <div className="object__line-profile">
          <p>{profile}</p>
          <i>
            {currentUser?.filePath ? (
              <Image
                url={setSizeImage(currentUser?.filePath, TypeSize.secondAvatar)}
              />
            ) : (
              <NoUserImageIcon />
            )}
          </i>
        </div>
      </div>
    </div>
  );
}
