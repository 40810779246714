import React, { KeyboardEvent, memo, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import { nanoid } from '@reduxjs/toolkit';
import { useAppDispatch } from '@hooks/redux';
import { useCompanyId } from '@hooks/useCompanyId';
import { useOutsideClickListener } from '@hooks/useOutsideClickListener';
import { createOperationFolderRequest } from '@store/operationFolders/actions';
import { ReactComponent as FolderIcon } from '@static/img/icon-folder.svg';

type OperationCatalogAddFolderProps = {
  onSubmit?: () => void;
  onCancel?: () => void;
};

const OperationCatalogAddFolderRaw: React.FC<
  OperationCatalogAddFolderProps
> = ({ onSubmit, onCancel }) => {
  const tileRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const companyId = useCompanyId();

  const submit = handleSubmit(({ value }) => {
    if (companyId === undefined) {
      return;
    }

    const name = value.trim();

    dispatch(
      createOperationFolderRequest({
        companyId,
        temporaryId: `create-${nanoid()}`,
        data: {
          name,
        },
      })
    );

    onSubmit?.();
  });
  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        submit();
      }
      if (e.key === 'Escape') {
        onCancel?.();
      }
    },
    [submit, onCancel]
  );
  const handleOutsideClick = useCallback(() => {
    onCancel?.();
  }, [onCancel]);

  useOutsideClickListener(tileRef, handleOutsideClick);

  const inputErrors = () => {
    switch (errors.value?.type) {
      case 'required':
        return <small>{t('auth.validation-empty')}</small>;
      default:
        return null;
    }
  };
  const error = !!inputErrors();

  return (
    <div ref={tileRef} className={cn('manager__folder input edit', { error })}>
      <input
        type="text"
        autoFocus
        maxLength={50}
        onKeyUp={handleKeyPress}
        {...register('value', { required: true })}
      />
      {inputErrors()}
      <h4>|</h4>
      <p>
        <FolderIcon />
        {t('directory.operations_count', { count: 0 })}
      </p>
    </div>
  );
};

export const OperationCatalogAddFolder = memo(OperationCatalogAddFolderRaw);
