import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@components/AboutObjectModal';
import { CompanyObjectId } from '@store/companyObjects/slice';
import { CompanyId } from '@store/company/slice';
import newAddIcon from '@static/img/new-add.gif';
import { CreateSectionModal } from './CreateSectionModal';

type NewCostEstimateProps = {
  companyId: CompanyId;
  objectId: CompanyObjectId;
};

export const NewCostEstimate: FC<NewCostEstimateProps> = ({
  companyId,
  objectId,
}) => {
  const { t } = useTranslation();
  const [isActiveModal, setActiveModal] = useState(false);

  const handleClickAddSectionButton = () => {
    setActiveModal(true);
  };
  const handleClickCloseModalButton = () => {
    setActiveModal(false);
  };

  return (
    <>
      <Modal
        isCenter
        visible={isActiveModal}
        onBackgroundClick={() => setActiveModal(false)}
      >
        <CreateSectionModal
          companyId={companyId}
          objectId={objectId}
          isActiveModal={isActiveModal}
          onClick={handleClickCloseModalButton}
        />
      </Modal>

      <div className="outlay">
        <div className="outlay__add">
          <i>
            <img
              src={newAddIcon}
              alt={t('about-object.get-started-create-section') ?? ''}
            />
          </i>
          <h6>{t('about-object.get-started-create-section')}</h6>
          <p>{t('about-object.after-started-create-section')}</p>
          <a className="link center" onClick={handleClickAddSectionButton}>
            {t('about-object.add-section')}
          </a>
        </div>
      </div>
    </>
  );
};
