import { all, call, put, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { Saga } from '@sagas/types';
import { handleError } from '@sagas/errors';
import { getFoundCategory, getVocabulary } from '@sagas/api/vocabulary';
import {
  fetchFoundCategoryRequest,
  FetchFoundCategoryRequestAction,
  fetchFoundCategorySuccess,
  fetchVocabularyRequest,
  fetchVocabularySuccess,
} from '@store/vocabulary/actions';

const fetchVocabularyRequestHandler: Saga = function* () {
  try {
    const data: SagaReturnType<typeof getVocabulary> = yield call(
      getVocabulary
    );

    yield put(fetchVocabularySuccess(data));
  } catch (e) {
    yield call(handleError, e);
  }
};

const fetchFoundCategoryRequestHandler: Saga<FetchFoundCategoryRequestAction> =
  function* ({ payload }) {
    try {
      const items = yield call(getFoundCategory, payload.request);
      yield put(fetchFoundCategorySuccess(items));
    } catch (e) {
      yield call(handleError, e);
    }
  };

export default function* root() {
  yield all([
    takeLatest(fetchVocabularyRequest, fetchVocabularyRequestHandler),
    takeLatest(fetchFoundCategoryRequest, fetchFoundCategoryRequestHandler),
  ]);
}
