import Button from '@components/commons/Button';
import Field from '@components/commons/Field';
import { EMAIL_PATTERN } from '@constants/patterns';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { restoreRequest } from '@store/account/actions';

import { getStatusResortPassword } from '@store/account/selectors';
import { inputErrors } from '@utils/inputErrors';

import { useForm, SubmitHandler } from 'react-hook-form';

export type FormDataRestore = {
  email: string;
  callbackUrl: string;
};

export const RestoreForm = () => {
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormDataRestore>();
  const onSubmit: SubmitHandler<FormDataRestore> = (data) => {
    dispatch(restoreRequest({ ...data, callbackUrl: window.location.origin }));
  };

  const status = useAppSelector(getStatusResortPassword);

  return (
    <form className="login__form" onSubmit={handleSubmit(onSubmit)}>
      <Field
        register={() =>
          register('email', {
            required: true,
            minLength: 3,
            pattern: EMAIL_PATTERN,
          })
        }
        errorInput={() => inputErrors('email', errors.email?.type)}
        placeholder="auth.email"
      />
      <Button name="auth.resort-password" status={status || 0} />
    </form>
  );
};
