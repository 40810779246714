import { createAction } from '@reduxjs/toolkit';
import { AuthData, userSlice } from '@store/user/slice';

export type SignInRequestAction = {
  email: string;
  password: string;
  languageCode: string;
};

export type SignInSuccessAction = {
  data: AuthData;
};

export type RegisterData = {
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  password: string;
  languageCode: string;
  callbackUrl: string;
  referral: string;
};

export type SignUpRequestAction = {
  data: RegisterData;
};

export type SignUpSuccessAction = {
  data: AuthData;
};

export interface RefreshTokenRequestAction {
  refreshToken: string;
}

export type RestoreRequestAction = {
  email: string;
  callbackUrl: string;
};
export type SavePasswordRequestAction = {
  password: string;
  repeatPassword: string;
  token: string;
};

export interface RefreshTokenSuccessAction {
  data: AuthData;
}

export type EmailConfirmRequestAction = {
  email: string;
  languageCode: string;
};

export const signInRequest =
  createAction<SignInRequestAction>('user/signInRequest');
export const signOutRequest = createAction('user/signOutRequest');
export const signOutSuccess = createAction('user/signOutSuccess');

export const signUpRequest =
  createAction<SignUpRequestAction>('user/signUpRequest');

export const refreshTokenRequest = createAction<RefreshTokenRequestAction>(
  'user/refreshTokenRequest'
);

export const refreshTokenError = createAction('user/refreshTokenError');

export const emailConfirmRequest = createAction<EmailConfirmRequestAction>(
  'user/emailConfirmRequest'
);

export const {
  signInSuccess,
  signUpSuccess,
  refreshTokenSuccess,
  emailConfirmSuccess,
  setEmailConfirmStatus,
} = userSlice.actions;
