import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useFormErrors } from '@hooks/useFormErrors';
import { useParams } from 'react-router-dom';
import { PHONE_PATTERN } from '@constants/patterns';
import {
  emailConfirmRequest,
  setEmailConfirmStatus,
  signUpRequest,
} from '@store/user/actions';
import RadioGroup from '@components/commons/RadioGroup';
import { phoneFormat } from '@utils/phoneFormat';
import { isEmailConfirmFetchedStatus } from '@store/user/selectors';
import { FetchStatus } from '@store/constants';
import { PasswordInput } from './PasswordInput';
import { SignUpButton } from './SignUpButton';
import { SignUpInput } from './SignUpInput';

import styles from './styles.module.sass';
import { LoginInput } from './LoginInput/LoginInput';

type FormData = {
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  verification: number;
  password: string;
  languageCode: string;
  callbackUrl: string;
  referral: string;
  role: string;
};

export const SignUpForm = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const { referral } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors: formErrors },
    setError,
    clearErrors,
  } = useForm<FormData>({
    mode: 'onBlur',
  });
  const errors = useFormErrors(formErrors);
  const languageCode = i18n.language;
  const emailConfirmFetchedStatus = useAppSelector(isEmailConfirmFetchedStatus);

  const [isViewConfirmEmailButton, setIsViewConfirmEmailButton] =
    useState(false);

  const handleBlur = (name: keyof FormData) => {
    const trimedValue =
      name === 'verification' ? getValues(name) : getValues(name).trim();

    setValue(name, trimedValue);
    if (name === 'email' && !errors.email) setIsViewConfirmEmailButton(true);
  };

  const registerHandler = handleSubmit((data) => {
    if (!data.role) {
      return setError('role', {
        message: t('errors.select-a-role-to-register') || undefined,
      });
    }

    dispatch(
      signUpRequest({
        data: {
          ...data,
          referral: referral || '',
          languageCode,
          callbackUrl: 'https://система.cтрой.рф/%s',
        },
      })
    );
  });

  const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = `+${event.target.value.replace(/[^\d- ()]/g, '')}`;

    setValue('phone', phoneFormat(value));
  };

  const handleSetRole = (role: string) => {
    setValue('role', role);
    if (errors.role) clearErrors('role');
  };

  const handleConfirmEmail = () => {
    dispatch(emailConfirmRequest({ email: getValues('email'), languageCode }));
  };

  useEffect(() => {
    if (emailConfirmFetchedStatus) {
      setTimeout(
        () => dispatch(setEmailConfirmStatus(FetchStatus.NotFetched)),
        10000
      );
    }
  }, [dispatch, emailConfirmFetchedStatus]);
  return (
    <form
      className={cn('login__form', styles.login__form)}
      onSubmit={registerHandler}
    >
      <SignUpInput
        type="text"
        title={t('auth.lastname')}
        maxLength={50}
        register={register('lastName', {
          required: true,
          onBlur: () => handleBlur('lastName'),
        })}
        errors={errors}
      />
      <SignUpInput
        type="text"
        title={t('auth.firstname')}
        maxLength={50}
        register={register('firstName', {
          required: true,
          onBlur: () => handleBlur('firstName'),
        })}
        errors={errors}
      />
      <LoginInput
        name="email"
        register={register}
        errors={errors}
        onBlur={() => handleBlur('email')}
        isViewConfirmEmailButton={isViewConfirmEmailButton}
        onConfirmClick={handleConfirmEmail}
        disabledConfirmButton={emailConfirmFetchedStatus}
      />
      <SignUpInput
        type="text"
        title={t('auth.verification-code')}
        maxLength={6}
        register={register('verification', {
          required: true,
          valueAsNumber: true,
        })}
        errors={errors}
      />
      <SignUpInput
        type="text"
        title={t('auth.phone')}
        maxLength={30}
        register={register('phone', {
          required: true,
          pattern: PHONE_PATTERN,
          onChange: handleChangePhone,
          onBlur: () => handleBlur('phone'),
        })}
        errors={errors}
      />
      <PasswordInput name="password" register={register} errors={errors} />
      <RadioGroup
        label={t('profile.register-as')}
        buttons={[
          { name: t('profile.role-customer'), value: 'ROLE_CUSTOMER' },
          { name: t('profile.role-supplier'), value: 'ROLE_SUPPLIER' },
        ]}
        setValue={handleSetRole}
        error={errors.role?.message}
      />

      <SignUpButton title={t('auth.sign-up')} />
    </form>
  );
};
