import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CommercialProposal } from '@store/costEstimates/slice';
import { useAppSelector } from '@hooks/redux';
import { useCurrentLocaleDate } from '@hooks/useCurrentLocaleDate';
import { getCurrentCostEstimate } from '@store/costEstimates/selectors';
import { workAtToUnix } from '@utils/workAtToUnix';

export type TimingProposalsProps = {
  currentApprovedCommercialProposal: CommercialProposal | null;
  previousCommercialProposal?: CommercialProposal;
};

export const TimingProposals: FC<TimingProposalsProps> = ({
  currentApprovedCommercialProposal,
  previousCommercialProposal,
}) => {
  const { t } = useTranslation();

  const [startWorkTimestamp, setStartWorkTimestamp] = useState(0);
  const [endWorkTimestamp, setEndWorkTimestamp] = useState(0);

  const currentCostEstimate = useAppSelector(getCurrentCostEstimate);

  const startWorkAt = useCurrentLocaleDate(startWorkTimestamp * 1000);
  const endWorkAt = useCurrentLocaleDate(endWorkTimestamp * 1000);

  useEffect(() => {
    if (currentApprovedCommercialProposal) {
      const { startWork, endWork } = workAtToUnix(
        currentApprovedCommercialProposal
      );

      setStartWorkTimestamp(startWork);
      setEndWorkTimestamp(endWork);
    } else if (previousCommercialProposal) {
      const { startWork, endWork } = workAtToUnix(previousCommercialProposal);

      setStartWorkTimestamp(startWork);
      setEndWorkTimestamp(endWork);
    }
    return () => {
      setStartWorkTimestamp(0);
      setEndWorkTimestamp(0);
    };
  }, [
    currentApprovedCommercialProposal,
    currentCostEstimate,
    previousCommercialProposal,
  ]);

  return (
    <div className="obj__cap-time">
      {startWorkTimestamp > 0 && (
        <p>
          <b>{t('about-object.timing')}</b> {startWorkAt} <b>—</b> {endWorkAt}
        </p>
      )}
    </div>
  );
};
