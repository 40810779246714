import { ReactComponent as IconCheck } from '@static/img/icon-check1.svg';
import { ReactComponent as IconChat } from '@static/img/icon-chat2.svg';
import { ReactComponent as IconRating } from '@static/img/icon-rating.svg';
import { ReactComponent as IconSort } from '@static/img/icon-sort2.svg';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { getCity, getUnit } from '@store/vocabulary/selectors';
import Calendar, { toValidDataTime } from '@components/commons/Calendar';
import { useTranslation } from 'react-i18next';
import IconButton from '@components/commons/IconButton';
import { setIsViewChatSuppliersOfferDrawer } from '@store/modalControl/actions';
import { fetchIsChekConversationRequestAction } from '@store/chat/actions';
import { getCurrencyCode } from '@store/requestCustomer/selectors';
import { useCurrentLocale } from '@hooks/useCurrentLocale';
import { currencyFormat } from '@utils/currency';
import ProgressBar from '@components/commons/ProgressBar';
import { useDateProgressBar } from '@hooks/useDateProgressBar';
import { getCurrentCostEstimate } from '@store/costEstimates/selectors';
import styles from './styles.module.sass';

type Props = {
  id: string;
  validity: number;
  responseName: string;
  resourceName: string;
  supplierName: string;
  amount: number;
  unitId: string;
  cityId: string;
  price: number;
  cost: number;
  deliveryTime: number;
  categoryName: string;
  createdAt: number;
  onClick: (id: string) => void;
  active?: boolean;
  supplierId: string;
  requestId?: string;
};
const Offer = ({
  id,
  validity,
  categoryName,
  responseName,
  deliveryTime,
  resourceName,
  supplierName,
  amount,
  price,
  cost,
  unitId,
  cityId,
  active,
  createdAt,
  onClick,
  supplierId,
  requestId,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const unit = useAppSelector((state) => getUnit(state, unitId));
  const city = useAppSelector((state) => getCity(state, cityId));

  const { progress, diff, isExpired } = useDateProgressBar(
    createdAt,
    validity,
    'days'
  );

  const handleOpenChat = () => {
    if (requestId) {
      dispatch(setIsViewChatSuppliersOfferDrawer());
      dispatch(fetchIsChekConversationRequestAction({ requestId, supplierId }));
    }
  };

  const costEstimateCurrencyCode = useAppSelector(
    getCurrentCostEstimate
  )?.currencyCode;
  const requestCurrencyCode = useAppSelector(getCurrencyCode);
  const currencyCode = costEstimateCurrencyCode || requestCurrencyCode;
  const locale = useCurrentLocale();

  const currencyFormatCostAndPrice = (value: number) => {
    return currencyCode && value !== undefined
      ? currencyFormat(value, currencyCode, locale)
      : value;
  };
  const isViewAnalogue = responseName !== resourceName;

  return (
    <>
      <div className="offer__item-cot">
        <IconButton
          onClick={() => onClick(id)}
          style={{ paddingLeft: '10px' }}
          className={styles.iconCheck}
          disabled={isExpired}
        >
          <i className={active ? 'active' : undefined} style={{ left: '-1px' }}>
            <IconCheck />
          </i>
        </IconButton>
        <div>
          <h4 className={styles.offerItemSupplierName}>{supplierName}</h4>
          <span className={styles.containerSvg}>
            <IconRating style={{ marginRight: '3px' }} />
            4.9
          </span>
          <span>{cityId && t(`cities.${city?.name}`)}</span>
          <IconButton className={styles.containerSvg} onClick={handleOpenChat}>
            {t('profile.chat')}
            <IconChat style={{ marginLeft: '4px' }} />
          </IconButton>
        </div>
        <ul>
          <li>{t('supplier.responses')}:</li>
          <li>{t('supplier.response-rate')}:</li>
          <li>{t('supplier.shipments')}:</li>
        </ul>
      </div>
      <div className="offer__item-info" style={{ position: 'relative' }}>
        <p className={styles.categoryName}>{categoryName}</p>
        <p className={styles.containerSvg}>
          <span className={styles.containerSvgWidthContent}>
            {isViewAnalogue && (
              <i title={t('supply.an-analogue-is-proposed') as string}>
                <IconSort style={{ marginRight: '8px' }} />
              </i>
            )}
            {responseName}
          </span>
          <span className={styles.timerBar}>
            <ProgressBar progress={progress} diff={diff} />
          </span>
        </p>
        <div>
          <ul>
            <li>
              {amount} {t(`units.${unit?.name}`)}
            </li>
            <li>x</li>
            <li className="color-main">{currencyFormatCostAndPrice(price)}</li>
            <li>=</li>
            <li>{currencyFormatCostAndPrice(cost)}</li>
          </ul>
          <span className="warning">
            {deliveryTime && !isExpired ? (
              <>
                {t('supply.supply')}:
                <Calendar
                  date={toValidDataTime(deliveryTime)}
                  size="small"
                  short
                  styles={{ margin: '0px' }}
                />
              </>
            ) : (
              t('supply.the-offer-has-expired')
            )}
          </span>
        </div>
      </div>
    </>
  );
};

export default Offer;
