import { ForwardedRef, forwardRef } from 'react';
import cn from 'classnames';
import { ChangeHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type TextAreaProps = {
  title: string;
  name: string;
  onChange: ChangeHandler;
  onBlur: ChangeHandler;
  count: number;
  error?: string;
};

export const TextAreaWithRef = (
  { title, count, error, onChange, name, onBlur }: TextAreaProps,
  ref: ForwardedRef<HTMLTextAreaElement>
): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={cn('textarea', 'border', { error })}>
      <textarea
        style={{ height: '195px', resize: 'none' }}
        placeholder="0"
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        ref={ref}
      />
      <label>{title}</label>
      <small>{t('company.left-characters', { count })}</small>
      {error && <small>{error}</small>}
    </div>
  );
};

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  TextAreaWithRef
);
