import React, { FC, KeyboardEvent, memo, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import { nanoid } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useCompanyId } from '@hooks/useCompanyId';
import { useOutsideClickListener } from '@hooks/useOutsideClickListener';
import { ResourceFolderId } from '@store/resourceFolders/slice';
import { createResourceFolderRequest } from '@store/resourceFolders/actions';
import { ReactComponent as FolderIcon } from '@static/img/icon-folder.svg';
import { getFolder } from '@store/resourceFolders/selectors';
import { useFormErrors } from '@hooks/useFormErrors';

type ResourcesCatalogAddFolderProps = {
  parentFolderId: ResourceFolderId;
  onSubmit?: () => void;
  onCancel?: () => void;
};

const ResourcesCatalogAddFolderRaw: FC<ResourcesCatalogAddFolderProps> = ({
  parentFolderId,
  onSubmit,
  onCancel,
}) => {
  const tileRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const folder = useAppSelector((state) => getFolder(state, parentFolderId));
  const companyId = useCompanyId();

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm();
  const errors = useFormErrors(formErrors);

  const inputErrors = () => {
    switch (errors.value?.type) {
      case 'required':
        return <small>{t('auth.validation-empty')}</small>;
      case 'server':
        return <small>{`${errors.value.message}`}</small>;
      default:
        return null;
    }
  };
  const error = !!inputErrors();

  const submit = handleSubmit(({ value }) => {
    if (companyId === undefined || folder === undefined) {
      return;
    }

    const name = value.trim();

    dispatch(
      createResourceFolderRequest({
        companyId,
        temporaryId: `create-${nanoid()}`,
        data: {
          name,
          level: folder.level + 1,
          path: `${folder.path}${folder.id}/`,
          parentFolderId,
        },
      })
    );

    onSubmit?.();
  });
  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        submit();
      }
      if (e.key === 'Escape') {
        onCancel?.();
      }
    },
    [submit, onCancel]
  );
  const handleOutsideClick = useCallback(() => {
    onCancel?.();
  }, [onCancel]);

  useOutsideClickListener(tileRef, handleOutsideClick);

  return (
    <div ref={tileRef} className={cn('manager__folder input edit', { error })}>
      <input
        type="text"
        autoFocus
        maxLength={50}
        onKeyUp={handleKeyPress}
        {...register('value', {
          required: true,
        })}
      />
      {inputErrors()}
      <h4>|</h4>
      <p>
        <FolderIcon />
        {t('directory.resources_count', { count: 0 })}
      </p>
    </div>
  );
};

export const ResourcesCatalogAddFolder = memo(ResourcesCatalogAddFolderRaw);
