import { useTranslation } from 'react-i18next';

type Props = {
  isViewSuppliers: boolean;
};
const TableRequestsHeader = ({ isViewSuppliers }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="obj__supply-cap">
      <p>{t('supply.resources')}</p>
      <p>{t('supply.category')}</p>
      <p>{t('estimate.list-count')}</p>
      <p>{t('estimate.list-unit')}</p>
      <p>{t('estimate.list-price')}</p>
      <p>{t('estimate.list-cost')}</p>
      <p>{t('supply.delivery-time')}</p>
      <p>
        {isViewSuppliers
          ? t('estimate.the-estimates-section')
          : t('supply.supplier')}
      </p>
    </div>
  );
};

export default TableRequestsHeader;
