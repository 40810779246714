import React, { FC } from 'react';
import { Link } from 'react-router-dom';

type EnterButtonProps = {
  title: string;
  path: string;
  onClick?: () => void;
};

export const EnterButton: FC<EnterButtonProps> = ({ title, path, onClick }) => {
  return (
    <Link to={path} className="link" onClick={onClick}>
      <span>{title}</span>
    </Link>
  );
};
