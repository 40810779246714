import React, { useState, useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { getOperationsUrl, getResourcesUrl } from '@pages';
import './styles.sass';

export const SettingsDirectoryTabs = () => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isPending, startTransition] = useTransition();
  const navigate = useNavigate();
  const location = useLocation();
  const [checked, setChecked] = useState(
    location.pathname.startsWith(getOperationsUrl())
  );

  const handleSwitchDirectory = () => {
    setChecked((prevState) => !prevState);

    startTransition(() => {
      setTimeout(() => {
        if (checked) {
          navigate(getResourcesUrl());
        } else {
          navigate(getOperationsUrl());
        }
      }, 200);
    });
  };

  return (
    <>
      <div className="top desctop">
        <div className="tab border">
          <ul>
            {/* TODO: fix `a > li` */}
            <NavLink to={getResourcesUrl()}>
              {({ isActive }) => (
                <li className={cn({ active: isActive })}>
                  {t('directory.resource')}
                </li>
              )}
            </NavLink>
            <NavLink to={getOperationsUrl()}>
              {({ isActive }) => (
                <li className={cn({ active: isActive })}>
                  {t('directory.operations')}
                </li>
              )}
            </NavLink>
          </ul>
        </div>
      </div>
      <div className="top mobile">
        <h4>Справочники</h4>
        <a className="switch">
          <input
            type="checkbox"
            checked={checked}
            onChange={handleSwitchDirectory}
          />
          <div>
            <p>Расценки</p>
            <label />
            <p>Операции</p>
          </div>
        </a>
      </div>
    </>
  );
};
