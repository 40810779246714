import { ReactComponent as IconEdit } from '@static/img/operation3.svg';
import { ReactComponent as IconDelete } from '@static/img/icon-delete.svg';
import { ReactComponent as IconDoc } from '@static/img/document.svg';
import { useState } from 'react';
import IconButton from '@components/commons/IconButton';
import { useDownloadDocument } from '@hooks/useDownloadDocument';
import css from './styles.module.sass';

type Props = {
  fileName: string;
  originalFileName: string;
  id: string;
  handleDeleteDocument: (id: string) => void;
  handleEditDocument: (id: string, originalDocumentName: string) => void;
};

const ItemDocument = ({
  fileName,
  originalFileName,
  id,
  handleDeleteDocument,
  handleEditDocument,
}: Props) => {
  const [isViewEdit, setIsViewEdit] = useState(false);
  const [documentName, setDocumentName] = useState(originalFileName);

  const handleClickDownloadDocument = useDownloadDocument();

  const handleViewEditInput = () => {
    setIsViewEdit(!isViewEdit);
  };

  const handleBlur = () => {
    handleEditDocument(id, documentName);
    setIsViewEdit(!isViewEdit);
  };

  const onEditDocument = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      handleEditDocument(id, documentName);
      setIsViewEdit(!isViewEdit);
    }
  };

  const renderForEdit = () => {
    return !isViewEdit ? (
      <IconButton
        onClick={() =>
          handleClickDownloadDocument(
            `private-doc/${fileName}`,
            originalFileName
          )
        }
        className={css.dct__item_button}
      >
        {originalFileName}
      </IconButton>
    ) : (
      <input
        value={documentName}
        onChange={(e) => setDocumentName(e.target.value)}
        onBlur={handleBlur}
        className={css.dct__item_input}
        onKeyUp={onEditDocument}
      />
    );
  };

  return (
    <div className="dct__item" title={documentName}>
      <i>
        <IconDoc />
      </i>
      <span>
        <h4>{renderForEdit()}</h4>
      </span>
      <ul>
        <li>
          <IconEdit onClick={handleViewEditInput} />
        </li>
        <li>
          <IconDelete onClick={() => handleDeleteDocument(id)} />
        </li>
      </ul>
      <s />
    </div>
  );
};

export default ItemDocument;
