import { useAppSelector } from '@hooks/redux.js';
import { getResourceList, isResourceFetched } from '@store/resources/selectors';
import { Operation } from '@store/operations/slice';
import { useMemo } from 'react';

export const useOperationCalculation = (operation: Operation) => {
  const resourceFetched = useAppSelector(isResourceFetched);
  const resources = useAppSelector((state) =>
    getResourceList(
      state,
      operation.resources.map((r) => r.id)
    )
  );

  return useMemo(() => {
    let operationPrice = 0;
    let operationMarkup = 0;
    let operationSalePrice = 0;

    if (resourceFetched && operation.amount !== 0) {
      const calculated = resources.reduce(
        (acc, resource) => {
          const { amount: resourceAmount = 0 } =
            operation.resources.find((r) => r.id === resource.id) || {};

          acc.operationPrice +=
            (resourceAmount / operation.amount) * resource.price;
          acc.operationSalePrice +=
            (resourceAmount / operation.amount) * resource.salePrice;

          return acc;
        },
        { operationPrice: 0, operationSalePrice: 0 }
      );
      operationPrice = calculated.operationPrice;
      operationSalePrice = calculated.operationSalePrice;
      operationMarkup =
        operationPrice !== 0
          ? (operationSalePrice / operationPrice - 1) * 100
          : 0;
    }

    operationPrice = Number.isNaN(operationPrice) ? 0 : operationPrice;
    operationSalePrice = Number.isNaN(operationSalePrice)
      ? 0
      : operationSalePrice;
    operationMarkup = Number.isNaN(operationMarkup) ? 0 : operationMarkup;

    return {
      operationPrice: parseFloat(operationPrice.toFixed(2)),
      operationMarkup: parseFloat(operationMarkup.toFixed(2)),
      operationSalePrice: parseFloat(operationSalePrice.toFixed(2)),
    };
  }, [operation.amount, operation.resources, resourceFetched, resources]);
};
