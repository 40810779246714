import { Dispatch, RefObject, SetStateAction } from 'react';
import { ReactComponent as IconProfile } from '@static/img/icon-profile1.svg';
import { FullOrganizations } from '@store/organizations/slice';
import { useTranslation } from 'react-i18next';
import { setChoiseFullOrganization } from '@store/organizations/actions';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { getProfile } from '@store/profile/selectors';
import TableWithVirtualScroll, {
  Columns,
} from '@components/commons/TableWithVirtualScroll';
import { isFetchOrganizationsFetched } from '@store/organizations/selectors';
import Empty from '@components/commons/Empty';
import InfiniteScroll from 'react-infinite-scroll-component';

type Props = {
  handleSetItemId: (id: string, index: number) => void;
  organizationsForRender: FullOrganizations[];
  totalOrganizations: number;
  onOrganizationsPageChange: Dispatch<SetStateAction<number>>;
  companyRef: RefObject<InfiniteScroll>;
};

const CompanyTable = ({
  handleSetItemId,
  organizationsForRender,
  totalOrganizations,
  onOrganizationsPageChange,
  companyRef,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const responsibleUser = useAppSelector(getProfile)?.firstName;
  const fetchOrganizationsStatus = useAppSelector(isFetchOrganizationsFetched);

  const columns: Columns<FullOrganizations>[] = [
    {
      className: 'client__cap-num',
      children: '№',
      render: (record, index: number) => index + 1,
      rowClassName: 'client__table-num',
    },
    {
      className: 'client__cap-co',
      children: t('company.name'),
      render: (record) => record.name,
      rowClassName: 'client__table-co',
    },
    {
      className: 'client__cap-inn',
      children: '',
      rowClassName: 'client__table-inn',
    },
    {
      className: 'client__cap-repres',
      children: t('company.representatives'),
      render: (record) => (
        <>
          {record.contacts.length > 0 && <IconProfile />}
          {record.contacts.map((e) => (
            <span key={e.id}>
              {e.firstName} {e.lastName.slice(0, 1)}
            </span>
          ))}
        </>
      ),
      rowClassName: 'client__table-repres',
    },
    {
      className: 'client__cap-respon',
      children: t('company.responsible'),
      render: () => responsibleUser,
      rowClassName: 'client__table-respon',
    },
  ];

  const onRowClick = (id: string, index: number) => {
    dispatch(setChoiseFullOrganization(id));
    handleSetItemId(id, index);
  };

  return (
    <>
      {organizationsForRender.length === 0 && fetchOrganizationsStatus ? (
        <Empty
          firstLine="organization.to-get-started-add-your-first-company"
          secondLine="organization.after-that-the-functionality-of-working-with-the-directory-of-companies"
        />
      ) : (
        <TableWithVirtualScroll
          columns={columns}
          data={organizationsForRender}
          onScroll={() => onOrganizationsPageChange((prev) => prev + 1)}
          bodyStyles={{ height: 'calc(100vh - 270px)', overflow: 'auto' }}
          onRowClick={onRowClick}
          rowKey="id"
          total={totalOrganizations}
          classNameTable="client__table"
          classNameTableItem="client__table-item open-modal"
          ref={companyRef}
        />
      )}
    </>
  );
};
export default CompanyTable;
