/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchStatus } from '@store/constants';
import {
  FetchCompanyObjectsRequestAction,
  FetchCompanyObjectsSuccessAction,
  CreateObjectRequestAction,
  CreateObjectSuccessAction,
  ArchiveObjectSuccessAction,
  UpdateStatusObject,
  FetchCompanyObjectSuccessAction,
  UpdateObjectRequestAction,
  UpdateCurrentObjectSuccessAction,
  ObjectMoveRequestActions,
  DeleteDocumentCompanyObjectRequestAction,
  DeleteDocumentCompanyObjectSuccessAction,
  EditDocumentCompanyObjectRequestAction,
  EditDocumentCompanyObjectSuccessAction,
} from '@store/companyObjects/actions';
import { Contact } from '@store/contacts/slice';
import { CompanyOrganizations } from '@store/organizations/slice';
import { DocumentsType } from '@store/documents/slice';

export type ContactId = string;
export type CompanyObjectId = string;
export type OrganizationId = string;

export enum ObjectStatus {
  NEW = 'NEW',
  PREPARE = 'PREPARE',
  AGREEMENT = 'APPROVAL',
  IN_WORK = 'PROCESS',
  DONE = 'DONE',
  ARCHIVED = 'ARCHIVED',
}

export type CompanyObject = {
  id: CompanyObjectId;
  name: string;
  cost: number;
  address: string;
  description: string;
  status: ObjectStatus;
  createdAt: number;
  ordering: number;
  organization?: {
    id: OrganizationId;
    name: string;
    organizationForm: string;
    phone: string;
    email: string;
    website: string;
    createdAt: number;
  };
  contact?: {
    id: ContactId;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    comment: string;
    whatsappAvailable: boolean;
    // Планируется использовать в будущем
    // responsible?: string;
  };
  documents: {
    general: DocumentsType[];
    customer: DocumentsType[];
    contractor: DocumentsType[];
  };
  filePath: string;
  imageId: string;
};

export type CompanyObjectsState = {
  status: FetchStatus;
  companyObjects: CompanyObject[];
  currentCompanyObject: CompanyObject | null;
  currentCompanyObjectId: CompanyObjectId;
  activeBlinkWhenCreatedNewObject: boolean;
  latestAddedCompanyObjectId: CompanyObjectId | null;
  createObjectStatus: FetchStatus;
  editDocumentStatus: FetchStatus;
  deleteDocumentStatus: FetchStatus;
  editObjectStatus: FetchStatus;
  archiveObjectStatus: FetchStatus;
  typeView: boolean;
};

const initialState: CompanyObjectsState = {
  status: FetchStatus.NotFetched,
  companyObjects: [],
  currentCompanyObject: null,
  currentCompanyObjectId: '',
  activeBlinkWhenCreatedNewObject: false,
  latestAddedCompanyObjectId: null,
  createObjectStatus: FetchStatus.NotFetched,
  editObjectStatus: FetchStatus.NotFetched,
  editDocumentStatus: FetchStatus.NotFetched,
  deleteDocumentStatus: FetchStatus.NotFetched,
  archiveObjectStatus: FetchStatus.NotFetched,
  typeView: true,
};

export const companyObjectsSlice = createSlice({
  name: 'companyObjects',
  initialState,
  reducers: {
    setArchiveObjectStatus: (state, action: PayloadAction<FetchStatus>) => {
      state.archiveObjectStatus = action.payload;
    },

    setImageObject: (
      state,
      action: PayloadAction<Pick<CompanyObject, 'filePath'>>
    ) => {
      if (state.currentCompanyObject) {
        state.currentCompanyObject = {
          ...state.currentCompanyObject,
          filePath: action.payload.filePath,
        };
      }
    },

    editDocumentCompanyObjectRequest: (
      state,
      payload: PayloadAction<EditDocumentCompanyObjectRequestAction>
    ) => {
      state.editDocumentStatus = FetchStatus.Fetching;
    },

    editDocumentCompanyObjectSuccess: (
      state,
      { payload }: PayloadAction<EditDocumentCompanyObjectSuccessAction>
    ) => {
      const { originalDocumentName, category } = payload;
      if (state.currentCompanyObject?.documents[category]) {
        state.currentCompanyObject.documents[category] =
          state.currentCompanyObject?.documents[category].filter(
            (document) => document.originalFileName !== originalDocumentName
          );
        state.editDocumentStatus = FetchStatus.NotFetched;
      }
    },

    deleteDocumentCompanyObjectRequest: (
      state,
      payload: PayloadAction<DeleteDocumentCompanyObjectRequestAction>
    ) => {
      state.deleteDocumentStatus = FetchStatus.Fetching;
    },

    deleteDocumentCompanyObjectSuccess: (
      state,
      { payload }: PayloadAction<DeleteDocumentCompanyObjectSuccessAction>
    ) => {
      const { id, category } = payload;
      if (state.currentCompanyObject?.documents[category]) {
        state.currentCompanyObject.documents[category] =
          state.currentCompanyObject?.documents[category].filter(
            (document) => document.id !== id
          );
        state.deleteDocumentStatus = FetchStatus.NotFetched;
      }
    },

    editDocumentRequest: (
      state,
      payload: PayloadAction<EditDocumentCompanyObjectRequestAction>
    ) => {
      // state.editDocumentStatus = FetchStatus.Fetching;
    },

    editDocumentSuccess: (
      state,
      { payload }: PayloadAction<EditDocumentCompanyObjectSuccessAction>
    ) => {
      // state.editDocumentStatus = FetchStatus.NotFetched;
    },

    deleteContactCurrentObject: (state) => {
      if (state.currentCompanyObject) {
        state.currentCompanyObject.contact = undefined;
      }
    },
    deleteOrganizationCurrentObject: (state) => {
      if (state.currentCompanyObject) {
        state.currentCompanyObject.organization = undefined;
      }
    },
    updateContactCurrentObject: (state, action: PayloadAction<Contact>) => {
      if (state.currentCompanyObject) {
        state.currentCompanyObject.contact = action.payload;
      }
    },
    updateOrganizationCurrentObject: (
      state,
      action: PayloadAction<CompanyOrganizations>
    ) => {
      if (state.currentCompanyObject) {
        state.currentCompanyObject.organization = action.payload;
      }
    },
    fetchUpdateStatusRequestSuccess: (
      state,
      action: PayloadAction<UpdateStatusObject>
    ) => {
      const index = state.companyObjects.findIndex(
        (i) => i.id === (action.payload.id ?? state.currentCompanyObjectId)
      );

      state.companyObjects[index].status = action.payload.status;
    },
    updateObjectRequest: (
      state,
      action: PayloadAction<UpdateObjectRequestAction>
    ) => {
      state.editObjectStatus = FetchStatus.Fetching;
    },
    updateObjectSuccess: (
      state,
      action: PayloadAction<UpdateCurrentObjectSuccessAction>
    ) => {
      const { data } = action.payload;
      state.companyObjects = state.companyObjects.map((e) => {
        if (e.id === data.id) {
          return data;
        }
        return e;
      });
      state.editObjectStatus = FetchStatus.Fetched;
    },
    resetEditObjectStatus: (state) => {
      state.editObjectStatus = FetchStatus.NotFetched;
    },
    setViewObject: (state, action: PayloadAction<boolean>) => {
      state.typeView = action.payload;
    },
    setActiveBlinkWhenCreatedNewObject: (state, { payload }) => {
      state.activeBlinkWhenCreatedNewObject = payload;
    },
    resetCreateObjectStatus: (state) => {
      state.createObjectStatus = FetchStatus.NotFetched;
    },
    resetLatestAddedCompanyObjectId: (state) => {
      state.latestAddedCompanyObjectId = null;
    },
    setCurrentCompanyObjectId: (
      state,
      { payload }: PayloadAction<CompanyObjectId>
    ) => {
      const id = payload;

      state.currentCompanyObjectId = id;
      state.currentCompanyObject = state.companyObjects.filter(
        (i) => i.id === id
      )[0];
    },
    fetchCompanyObjectsRequest: (
      state,
      action: PayloadAction<FetchCompanyObjectsRequestAction>
    ) => {
      state.status = FetchStatus.Fetching;
    },

    fetchCompanyObjectRequest: (
      state,
      action: PayloadAction<FetchCompanyObjectsRequestAction>
    ) => {
      state.status = FetchStatus.Fetching;
    },

    fetchCompanyObjectsSuccess: (
      state,
      action: PayloadAction<FetchCompanyObjectsSuccessAction>
    ) => {
      const { data } = action.payload;

      state.companyObjects = data.sort((a, b) => a.ordering - b.ordering);
    },

    fetchCompanyObjectSuccess: (
      state,
      action: PayloadAction<FetchCompanyObjectSuccessAction>
    ) => {
      const { data } = action.payload;

      state.currentCompanyObject = data;
    },

    createObjectRequest: (
      state,
      { payload }: PayloadAction<CreateObjectRequestAction>
    ) => {
      state.latestAddedCompanyObjectId = null;
      state.createObjectStatus = FetchStatus.Fetching;
    },
    createObjectSuccess: (
      state,
      { payload }: PayloadAction<CreateObjectSuccessAction>
    ) => {
      const { data } = payload;

      state.latestAddedCompanyObjectId = data.id;
      state.companyObjects = [data, ...state.companyObjects];
      state.createObjectStatus = FetchStatus.Fetched;
    },
    archiveObjectSuccess: (
      state,
      { payload }: PayloadAction<ArchiveObjectSuccessAction>
    ) => {
      const { id } = payload;

      const index = state.companyObjects.findIndex(
        (object) => object.id === id
      );

      state.companyObjects[index].status = ObjectStatus.ARCHIVED;
      state.archiveObjectStatus = FetchStatus.Fetched;
    },
    fetchObjectMoveRequest: (
      state,
      action: PayloadAction<ObjectMoveRequestActions>
    ) => {
      const indexSubjectElement = state.companyObjects.findIndex(
        (i) => i.id === action.payload.subjectElementId
      );
      const indexObjectElement = state.companyObjects.findIndex(
        (i) => i.id === action.payload.objectElementId
      );

      const indexStartInsert =
        indexObjectElement < indexSubjectElement
          ? indexSubjectElement - (action.payload.toMove === -1 ? 1 : 0)
          : indexSubjectElement + (action.payload.toMove === -1 ? 0 : 1);

      const data = [...state.companyObjects];

      data.splice(indexObjectElement, 1);

      data.splice(indexStartInsert, 0, {
        ...state.companyObjects[indexObjectElement],
      });

      state.companyObjects = data.map((e, i) => {
        return { ...e, ordering: i + 1 };
      });
    },
  },
});

export default companyObjectsSlice.reducer;
