import { ObjectStatus } from '@store/companyObjects/slice';
import { BidStatus } from '@store/costEstimates/slice';

type PhaseClass = string;

export const PHASE_CLASSES: PhaseClass[] = [
  'bg-primary',
  'bg-danger',
  'bg-warning',
  'bg-success',
  'bg-default',
];

export const PHASE_CLASSES_COLOR: PhaseClass[] = [
  'color-primary',
  'color-danger',
  'color-warning',
  'color-success',
  'color-default',
];

export const AVAILABLE_PHASES: Record<ObjectStatus, PhaseClass[]> = {
  [ObjectStatus.NEW]: PHASE_CLASSES.slice(0, 1),
  [ObjectStatus.PREPARE]: PHASE_CLASSES.slice(0, 2),
  [ObjectStatus.AGREEMENT]: PHASE_CLASSES.slice(0, 3),
  [ObjectStatus.IN_WORK]: PHASE_CLASSES.slice(0, 4),
  [ObjectStatus.DONE]: PHASE_CLASSES.slice(0, 5),
  [ObjectStatus.ARCHIVED]: PHASE_CLASSES.slice(0, 0),
};

export const AVAILABLE_PHASE: Record<ObjectStatus, PhaseClass> = {
  [ObjectStatus.NEW]: PHASE_CLASSES[0],
  [ObjectStatus.PREPARE]: PHASE_CLASSES[1],
  [ObjectStatus.AGREEMENT]: PHASE_CLASSES[2],
  [ObjectStatus.IN_WORK]: PHASE_CLASSES[3],
  [ObjectStatus.DONE]: PHASE_CLASSES[3],
  [ObjectStatus.ARCHIVED]: PHASE_CLASSES[4],
};

export const AVAILABLE_PHASE_COLOR: Record<ObjectStatus, PhaseClass> = {
  [ObjectStatus.NEW]: PHASE_CLASSES_COLOR[0],
  [ObjectStatus.PREPARE]: PHASE_CLASSES_COLOR[1],
  [ObjectStatus.AGREEMENT]: PHASE_CLASSES_COLOR[2],
  [ObjectStatus.IN_WORK]: PHASE_CLASSES_COLOR[3],
  [ObjectStatus.DONE]: PHASE_CLASSES_COLOR[3],
  [ObjectStatus.ARCHIVED]: PHASE_CLASSES_COLOR[4],
};

export const BID_PHASE: Record<BidStatus, PhaseClass> = {
  [BidStatus.NEW]: PHASE_CLASSES[0],
  [BidStatus.PROCESSED]: PHASE_CLASSES[1],
  [BidStatus.SELECTED]: PHASE_CLASSES[2],
  [BidStatus.ORDERED]: PHASE_CLASSES[3],
  [BidStatus.PRODUCTION]: PHASE_CLASSES[4],
};
