import cn from 'classnames';
import { OrderStatus } from '@utils/enums';
import { AVAILABLE_STATUSES } from '@constants/statuses';
import { useTranslation } from 'react-i18next';

type StatusProps = {
  status: keyof typeof OrderStatus;
  StatusInfo: () => JSX.Element;
};

export default function Status({ status, StatusInfo }: StatusProps) {
  const { t } = useTranslation();
  const statusPhases = [
    {
      status: OrderStatus.ACCEPTED,
      name: t('bid.status-accepted'),
    },
    {
      status: OrderStatus.PAYMENT,
      name: t('bid.status-payment'),
    },
    {
      status: OrderStatus.PAID,
      name: t('bid.status-paid'),
    },
    {
      status: OrderStatus.PRODUCTION,
      name: t('bid.status-production'),
    },
    {
      status: OrderStatus.READY,
      name: t('bid.status-ready'),
    },
    {
      status: OrderStatus.ISSUED,
      name: t('bid.status-issued'),
    },
  ];

  return (
    <div className={cn(`order-modal__status ${status.toLocaleLowerCase()}`)}>
      <div className="order-modal__name">
        {statusPhases.map((e) => {
          const className = e.status.toLocaleLowerCase();

          return (
            <div
              key={`indicator-${e.status}`}
              className={cn({ current: e.status === status })}
            >
              <div
                className={cn({
                  [className]: AVAILABLE_STATUSES[status].includes(className),
                })}
              />
              {e.name}
            </div>
          );
        })}
      </div>
      <StatusInfo />
    </div>
  );
}
