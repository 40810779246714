import {
  CreateOrganizationsData,
  UpdateOrganizationsData,
} from '@sagas/api/organizations';
import { CompanyId } from '@store/company/slice';
// eslint-disable-next-line import/no-cycle
import { ResponseWithPagination } from '@store/types';
import {
  CompanyObjectId,
  CompanyOrganizations,
  FullOrganizations,
  OrganizationId,
  organizationSlice,
} from './slice';

export type FetchOrganizationRequestAction = {
  id: CompanyId;
  page: number;
  max?: number;
  isUpdatePage?: boolean;
};

export type FetchFullOrganizationsSuccessAction = {
  response: ResponseWithPagination<FullOrganizations>;
  isUpdatePage?: boolean;
};

export type FetchOrganizationSuccessAction = {
  data: CompanyOrganizations[];
};

export type CreateOrganizationRequestAction = {
  data: CreateOrganizationsData;
  companyId: CompanyId;
};
export type CreateOrganizationSuccessAction = {
  data: CompanyOrganizations;
};

export type UpdateOrganizationRequestAction = {
  data: UpdateOrganizationsData;
  companyId: CompanyId;
  organizationId: OrganizationId;
  page?: number;
};

export type UpdateOrganizationSuccessAction = {
  data: UpdateOrganizationsData;
  id: CompanyObjectId;
};

export type DeleteOrganizationRequestAction = {
  companyId: CompanyId;
  id: OrganizationId;
};

export type DeleteOrganizationSuccessAction = {
  id: OrganizationId;
};

export type ArchiveOrganizationRequestAction = {
  companyId: CompanyId;
  id: CompanyObjectId;
};
export type ArchiveOrganizationSuccessAction = {
  id: CompanyObjectId;
};

export const {
  deleteOrganizationRequest,
  deleteOrganizationSuccess,
  resetUpdateOrganizationStatus,
  updateOrganizationSuccess,
  updateOrganizationRequest,
  setChoiseFullOrganization,
  fetchFullOrganizationsObjectRequest,
  fetchFullOrganizationsObjectSuccess,
  deleteChoiseOrganization,
  setChoiseOrganization,
  fetchOrganizationsObjectSuccess,
  fetchOrganizationSuccess,
  createOrganizationRequest,
  createOrganizationSuccess,
  archiveOrganizationSuccess,
  setcurrentCompanyObjectId,
  setActiveBlinkWhenCreatedNewOrganization,
  resetCreateOrganizationStatus,
  resetLatestAddedCompanyObjectId,
  setViewOrganization,
  fetchOrganizationsObjectRequest,
} = organizationSlice.actions;
