import React, {
  FC,
  memo,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import debounce from 'lodash/debounce';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { nanoid } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useTableRowForm } from '@hooks/useTableRowForm';
import { useEstimateCalculation } from '@hooks/useEstimateCalculation';
import { useCurrentLocale } from '@hooks/useCurrentLocale';
import { useLocalizedUnits } from '@hooks/useUnits';
import { ResourcesCatalogModal } from '@components/ResourcesCatalogModal';
import { EstimateContext } from '@components/Estimate';
import { TableSelect } from '@components/Table/Select';
import { EstimateTypeSelect } from '@components/Estimate/List/Resource/TypeSelector';
import { EntityStatus } from '@store/constants';
import { ResourceId, ResourceType } from '@store/resources/slice';
import { Unit, UnitId } from '@store/vocabulary/slice';
import {
  CostEstimateResource,
  CostEstimateResourceId,
} from '@store/costEstimateResources/slice';
import {
  createCostEstimateResourceRequest,
  fetchostEstimateResourcesMoveRequest,
  updateCostEstimateResourceRequest,
} from '@store/costEstimateResources/actions';
import { CostEstimateOperation } from '@store/costEstimateOperations/slice';
import { getResources } from '@store/resources/selectors';
import {
  getCurrentCostEstimate,
  getSelectedIds,
} from '@store/costEstimates/selectors';
import { percentFormat, percentParse } from '@utils/percent';
import { currencyFormat, currencyParse } from '@utils/currency';
import removeIcon from '@static/img/icon-delete.svg';
import copyIcon from '@static/img/icon-copy3.svg';
import { useDrag, useDrop } from 'react-dnd';
import { ReactComponent as IconAnalog } from '@static/img/icon-sort2.svg';
import { ReactComponent as IconFolder } from '@static/img/operation4.svg';
import { ReactComponent as IconSearch } from '@static/img/icon-search1.svg';
import { ReactComponent as IconBackPack } from '@static/img/backpack__ObjectButton.svg';
import { ReactComponent as IconEdit } from '@static/img/icon-edit3.svg';
import { ReactComponent as IconError } from '@static/img/icon-error.svg';
import { ReactComponent as IconWarning } from '@static/img/icon-warning2.svg';
import Checkbox from '@components/commons/Checkbox';
import { deleteSelectedIds, setSelectedId } from '@store/costEstimates/actions';
import { fetchFoundCategoryRequest } from '@store/vocabulary/actions';
import { getFoundCategories } from '@store/vocabulary/selectors';
import IconButton from '@components/commons/IconButton';
import { SelectWithSearch } from '@components/commons/SelectWithSearch/SelectWithSearch';
import Calendar from '@components/commons/Calendar';
import SupplierOffersModal from '@components/SupplierOffersModal';
import { useSearchParams } from 'react-router-dom';
import { useDidUpdateEffect } from '@hooks/useDidUpdateEffect';
import {
  setIsViewChatSuppliersOfferDrawer,
  setIsViewChatSuppliersOfferDrawerFalse,
} from '@store/modalControl/actions';
import Counter from '@components/commons/Counter/Counter';
import CategorySelectModal from '../CategorySelectModal';
import './styles.sass';

interface FormData {
  id: CostEstimateResourceId;
  type: ResourceType;
  name: string;
  categoryId: string;
  categoryName: string;
  unitId: UnitId;
  amount: number;
  price: string;
  cost: string;
  markup: string;
  salePrice: string;
  saleCost: string;
  deliveryTime: number;
}

type EstimateResourceProps = {
  create?: boolean;
  operation: CostEstimateOperation;
  resource: CostEstimateResource;
  onCancel?: () => void;
  onDeleteResource: (id: string) => void;
};

const EstimateResourceRaw: FC<EstimateResourceProps> = ({
  create = false,
  operation,
  resource,
  onCancel,
  onDeleteResource,
}) => {
  const { companyId, objectId } = useContext(EstimateContext);
  const defaultValues = useRef({});
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const locale = useCurrentLocale();
  const units = useLocalizedUnits();
  const costEstimate = useAppSelector(getCurrentCostEstimate);
  const selectedIds = useAppSelector(getSelectedIds);
  const foundCategories = useAppSelector(getFoundCategories);

  const isViewOfferFromTheSupplier =
    resource.defaultPrice &&
    resource.response &&
    resource.defaultPrice !== resource.response?.price;

  const fetching = resource.status === EntityStatus.Fetching;

  const disabledResource = !resource.ordered && resource?.response;
  const fullDisabledResource = resource.ordered && resource?.response;
  const isViewWarningRed = fullDisabledResource;
  const isViewWarningOrange = disabledResource;

  const [searchParams] = useSearchParams();
  const costEstimateIdFromQuery = searchParams.get('costEstimateId');

  const [openCatalog, setOpenCatalog] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<{
    id: string;
    name: string;
  } | null>(null);

  const [isViewChoise, setIsViewChoise] = useState(false);
  const [isViewChoiseSupplierOffers, setIsViewChoiseSupplierOffers] =
    useState(false);

  const isResponse = Boolean(resource.response);

  const currencyFormatInput = (value: number) => {
    return costEstimate?.currencyCode !== undefined
      ? currencyFormat(value, costEstimate?.currencyCode, locale)
      : `${value}`;
  };
  defaultValues.current = {
    ...resource,
    markup: percentFormat(resource.markup, locale),
    price: currencyFormatInput(resource.price),
    cost: currencyFormatInput(resource.cost),
    salePrice: currencyFormatInput(resource.salePrice),
    saleCost: currencyFormatInput(resource.saleCost),
    categoryName: resource.category?.name,
    categoryId: resource.category?.id,
    name: resource.name,
  };

  const {
    register,
    handleSubmit,
    setFocus,
    setValue,
    getValues,
    reset,
    control,
  } = useForm<FormData>({
    defaultValues: defaultValues.current,
  });

  const {
    rowRef,
    editable,
    handleInputFocus,
    handleInputBlur,
    handleInputKeyUp,
    handleFormSubmit,
  } = useTableRowForm({
    defaultEditable: create,
    onInputCancel: () => {
      reset(defaultValues.current);
      onCancel?.();
    },
    onEnter: () => {
      onCancel?.();
    },
    onOutsideClick: () => {
      // for some reason outside click doesn't trigger blur with form `reset()`
      (document?.activeElement as HTMLInputElement)?.blur();
      onCancel?.();
    },
    onInputFocus: (e) => {
      if (e?.target instanceof HTMLInputElement) {
        if (e.target.value === '') {
          return;
        }

        switch (e.target.name) {
          case 'markup':
            e.target.value = `${percentParse(e.target.value, locale)}`;
            break;
          case 'price':
          case 'cost':
          case 'salePrice':
          case 'saleCost':
            e.target.value = `${currencyParse(e.target.value, locale)}`;
            break;
          default:
            break;
        }
      }
    },
    onInputBlur: (e) => {
      if (e?.target instanceof HTMLInputElement) {
        switch (e.target.name) {
          case 'markup':
            e.target.value = `${percentFormat(e.target.value, locale)}`;
            break;
          case 'price':
          case 'cost':
          case 'salePrice':
          case 'saleCost':
            e.target.value =
              costEstimate?.currencyCode !== undefined
                ? `${currencyFormat(
                    parseFloat(e.target.value),
                    costEstimate?.currencyCode,
                    locale
                  )}`
                : e.target.value;
            break;
          default:
            break;
        }
      }

      // if (!create) {
      handleFormSubmit();
      // }
    },
    onFormSubmit: handleSubmit((formData) => {
      const { id, ...data } = formData;

      if (companyId === undefined) {
        return;
      }

      if (data.name.trim() === '') {
        return;
      }

      if (costEstimateIdFromQuery !== null) {
        if (create) {
          onCancel?.();
          dispatch(
            createCostEstimateResourceRequest({
              companyId,
              objectId,
              costEstimateId: costEstimateIdFromQuery,
              temporaryId: `create-${nanoid()}`,
              data: {
                ...data,
                operationId: operation.id,
                markup: percentParse(data.markup, locale),
                price: currencyParse(data.price, locale),
                cost: currencyParse(data.cost, locale),
                salePrice: currencyParse(data.salePrice, locale),
                saleCost: currencyParse(data.saleCost, locale),
              },
            })
          );
        } else {
          dispatch(
            updateCostEstimateResourceRequest({
              companyId,
              objectId,
              costEstimateId: costEstimateIdFromQuery,
              id,
              data: {
                operationId: operation.id,
                ...data,
                markup: percentParse(data.markup, locale),
                price: currencyParse(data.price, locale),
                cost: currencyParse(data.cost, locale),
                salePrice: currencyParse(data.salePrice, locale),
                saleCost: currencyParse(data.saleCost, locale),
              },
            })
          );
        }
      }
    }),
  });

  // useEffect(() => {
  //   reset(defaultValues.current);
  // }, [resource, reset]);

  const handleCatalogButtonClick = () => {
    setOpenCatalog(true);
  };

  const handleCatalogCancel = () => {
    setOpenCatalog(false);
    onCancel?.();
  };

  const resources = useAppSelector(getResources);
  const handleAddResources = (resourcesIds: ResourceId[]) => {
    resourcesIds.forEach((resourceId) => {
      const resourceData = resources.find((r) => r.id === resourceId);

      if (resourceData !== undefined && costEstimateIdFromQuery !== null) {
        dispatch(
          createCostEstimateResourceRequest({
            companyId,
            objectId,
            costEstimateId: costEstimateIdFromQuery,
            temporaryId: `create-${nanoid()}`,
            data: {
              ...resourceData,
              operationId: operation.id,
              amount: 1,
              cost: 0,
              saleCost: 0,
            },
          })
        );
      }
    });
  };

  const handlerTypeSelected = (value: ResourceType) => {
    setValue('type', value);
    setFocus('name');
  };

  const handlerUnitSelected = () => {
    setFocus('price');
  };

  const handleCalculation = useCallback(
    (name: keyof FormData, value: number) => {
      if ((document?.activeElement as HTMLInputElement).name === name) {
        return;
      }
      switch (name) {
        case 'markup':
          setValue(name, percentFormat(value, locale));
          break;
        case 'price':
        case 'cost':
        case 'salePrice':
        case 'saleCost':
          setValue(
            name,
            costEstimate?.currencyCode !== undefined
              ? currencyFormat(value, costEstimate?.currencyCode, locale)
              : `${value}`
          );
          break;
        default:
          setValue(name, value);
          break;
      }
    },
    [costEstimate?.currencyCode, locale, setValue]
  );

  useEstimateCalculation<FormData>(control, handleCalculation);

  const handleCopyResourceClick = () => {
    if (costEstimateIdFromQuery) {
      dispatch(
        createCostEstimateResourceRequest({
          companyId,
          objectId,
          costEstimateId: costEstimateIdFromQuery,
          temporaryId: `create-${nanoid()}`,
          data: {
            ...resource,
            name: resource.name,
            price: resource.price,
            cost: resource.cost,
            categoryId: resource.category?.id,
            categoryName: resource.category?.name,
            operationId: operation.id,
          },
        })
      );
    }
  };

  const handleMoveResource = (
    toMove: 1 | -1,
    objectElementId: string,
    subjectElementId: string
  ) => {
    if (costEstimateIdFromQuery) {
      dispatch(
        fetchostEstimateResourcesMoveRequest({
          companyId,
          costEstimateId: costEstimateIdFromQuery,
          toMove,
          objectElementId,
          subjectElementId,
          objectId,
        })
      );
    }
  };

  const ref = useRef<HTMLDivElement>(null);
  const [isTop, setIsTop] = useState(true);
  const [{ isDragging }, drag] = useDrag(() => ({
    type: operation.id,
    item: resource,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [{ isOver }, dropRef] = useDrop({
    accept: [operation.id],
    drop: (item: CostEstimateResource, monitor) => {
      const id = ref.current?.getAttribute('id');

      if (ref.current) {
        const currentRow = ref.current.getBoundingClientRect();

        if (currentRow) {
          const h = currentRow.height;
          const y = monitor.getClientOffset()?.y;
          if (y) {
            const hoverActualY: number = y - currentRow.top;
            if (id)
              handleMoveResource(hoverActualY > h / 2 ? 1 : -1, item.id, id);
          }
        }
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
    hover: (item, monitor) => {
      const id = ref.current?.getAttribute('id');
      if (ref.current) {
        const currentRow = ref.current.getBoundingClientRect();

        if (currentRow) {
          const h = currentRow.height;
          const y = monitor.getClientOffset()?.y;
          if (y) {
            const hoverActualY: number = y - currentRow.top;
            if (id) setIsTop(hoverActualY < h / 2);
          }
        }
      }
    },
  });

  drag(dropRef(ref));

  const getSearchData = useCallback((query: string) => {
    if (query.length > 0) {
      dispatch(fetchFoundCategoryRequest({ request: query }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedGetSearchData = debounce(getSearchData, 500);

  const handleSelectedCategory = (
    category: { id: string; name: string }[] | string[]
  ) => {
    const item = category[0] as { id: string; name: string };
    setSelectedCategory({ id: item.id, name: item.name });
  };

  const handleChoiseClose = () => {
    setIsViewChoise(false);
  };

  const handleOpenSupplierOffers = () => {
    setIsViewChoiseSupplierOffers(true);
  };

  const handleChoiseSupplierOffersClose = () => {
    setIsViewChoiseSupplierOffers(false);
    dispatch(setIsViewChatSuppliersOfferDrawerFalse());
  };

  const handleSupplierOffersCloseChat = () => {
    dispatch(setIsViewChatSuppliersOfferDrawer());
  };

  const handleClickAddCategory = () => {
    setValue('categoryId', selectedCategory?.id || '');
    setValue('categoryName', selectedCategory?.name || '');
    handleFormSubmit();
    setIsViewChoise(false);
  };

  const handleAddResourceIds = (id: string, checked: boolean) => {
    if (checked && resource.category) {
      dispatch(setSelectedId([id]));
    } else {
      dispatch(deleteSelectedIds([id]));
    }
  };

  const listItems = foundCategories.map((item) => ({
    id: item.id,
    name: item.name,
    path: item.path,
  }));

  const selectedItem = getValues('categoryName')
    ? {
        id: getValues('categoryId'),
        name: getValues('categoryName'),
      }
    : undefined;

  const onSelect = (id: string) => {
    const category = foundCategories.find((item) => item.id === id);
    setValue('categoryId', id);
    setValue('categoryName', category?.name || '');
    handleFormSubmit();
  };

  const handleSetDate = (ms: number) => {
    setValue('deliveryTime', ms);
    handleFormSubmit();
  };

  // После выбора поставщика ставит в поля значения из response
  useDidUpdateEffect(() => {
    setValue('name', resource.name);
    setValue('price', currencyFormatInput(resource.price));
    setValue('cost', currencyFormatInput(resource.cost));
    setValue('markup', percentFormat(resource.markup, locale));
    setValue('saleCost', currencyFormatInput(resource.saleCost));
    setValue('salePrice', currencyFormatInput(resource.salePrice));
  }, [
    resource.name,
    resource.cost,
    resource.price,
    resource.markup,
    resource.saleCost,
    resource.salePrice,
  ]);

  return (
    <>
      <div>
        <div
          ref={rowRef}
          style={{
            opacity: isDragging ? '0.2' : '1',
            borderTop: isOver && isTop ? '1px solid #FF9292' : '1px solid #fff',
            borderBottom:
              isOver && !isTop ? '1px solid #FF9292' : '1px solid #fff',
          }}
          className={cn('outlay__resource', {
            add: create,
            edit: editable,
            fetching,
          })}
        >
          <div className="outlay__resource-sort" ref={ref} id={resource.id} />
          {resource.resourceParent && (
            <i
              title={`${t('supply.an-analogue-is-proposed')}`}
              className="outlay_resource-icon-analog"
            >
              <IconAnalog style={{ marginRight: '8px' }} />
            </i>
          )}
          {fetching && <div className="outlay__resource-edit" />}
          <Checkbox
            rootClassName="outlay__resource-check check"
            onChange={(e) =>
              handleAddResourceIds(resource.id, e.currentTarget.checked)
            }
            checked={
              selectedIds.includes(resource.id) &&
              !disabledResource &&
              !fullDisabledResource
            }
          />
          <EstimateTypeSelect
            className={cn(
              'outlay__resource-group',
              isResponse && 'outlay__resource-opacity'
            )}
            editable={editable}
            value={getValues('type') as ResourceType}
            onSelected={handlerTypeSelected}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            register={register('type')}
            disabled={isResponse}
          />
          <div
            className={cn(
              'outlay__resource-name',
              isResponse && 'outlay__resource-opacity'
            )}
          >
            <input
              type="text"
              autoFocus={create}
              placeholder={t('estimate.resource-name') ?? ''}
              onFocus={handleInputFocus}
              readOnly={isResponse}
              onKeyUp={handleInputKeyUp}
              {...register('name', {
                setValueAs: (v) => v.trim(),
                onBlur: handleInputBlur,
              })}
              title={resource.name}
              disabled={isResponse}
            />
            {create && (
              <button
                aria-label={t('estimate.select-from-directory') ?? ''}
                data-balloon-pos="left"
                onClick={handleCatalogButtonClick}
              />
            )}
          </div>
          <div
            className={cn('outlay__resource-cat outlay__resource-cat-select')}
          >
            <SelectWithSearch
              listItems={listItems}
              onSelect={onSelect}
              onSearch={debouncedGetSearchData}
              selectedItem={selectedItem}
              disabled={isResponse}
              classNameInput={`${isResponse && 'outlay__resource-opacity'}`}
              maxLength={50}
            />
            <IconButton
              onClick={() => setIsViewChoise(true)}
              disabled={isResponse}
              title={t('estimate.select-a-category') || ''}
            >
              <IconSearch className={cn('outlay__resource-cat-search-icon')} />
            </IconButton>
          </div>
          <div
            className={cn(
              'outlay__resource-count',
              isResponse && 'outlay__resource-opacity'
            )}
            data-title={t('estimate.list-count')}
          >
            <input
              type="text"
              onFocus={handleInputFocus}
              onKeyUp={handleInputKeyUp}
              {...register('amount', {
                onChange: (e) => {
                  setValue('amount', e.target.value.replace(/[^\d.]/g, ''));
                },
                setValueAs: (v) => (v === '' ? 0 : parseFloat(v)),
                onBlur: handleInputBlur,
              })}
              title={`${resource.amount}`}
              disabled={isResponse}
            />
          </div>
          <div
            className={cn('outlay__resource-unit')}
            data-title={t('estimate.list-unit')}
          >
            <TableSelect<Unit, FormData>
              name="unitId"
              control={control}
              listItems={units}
              onSelectFocus={handleInputFocus}
              onSelectBlur={handleInputBlur}
              onSelectChange={handlerUnitSelected}
              disabled={isResponse}
            />
          </div>
          <div
            className="outlay__resource-price"
            data-title={t('estimate.list-price')}
          >
            {resource.resourceParent || isViewOfferFromTheSupplier ? (
              <i
                className="tooltip"
                title={`${t('estimate.the-price-is-from-the-supplier')}`}
              >
                <IconBackPack />
              </i>
            ) : (
              <i
                className="tooltip"
                title={`${t('estimate.the-price-is-indicated-manually')}`}
              >
                <IconEdit />
              </i>
            )}
            <input
              type="text"
              onFocus={handleInputFocus}
              onKeyUp={handleInputKeyUp}
              maxLength={18}
              {...register('price', {
                onChange: (e) => {
                  setValue('price', e.target.value.replace(/[^\d.]/g, ''));
                },
                onBlur: handleInputBlur,
              })}
              readOnly={isResponse}
              className={cn(
                'outlay__resource-price__input',
                isResponse && 'outlay__resource-opacity'
              )}
              title={currencyFormatInput(resource.price)}
              style={{ width: '50px' }}
              disabled={isResponse}
            />
            {!resource.numberOfResponses ? (
              <IconButton
                className="open-modal tooltip"
                title={t('estimate.selection-of-supplier-offers') || ''}
                data-tooltip="left"
                onClick={handleOpenSupplierOffers}
              >
                <IconFolder />
              </IconButton>
            ) : (
              <Counter
                count={resource.numberOfResponses}
                title={`${resource.numberOfResponses}`}
                disabled={!!isViewWarningRed}
                onClick={handleOpenSupplierOffers}
              />
            )}
          </div>
          <div
            className={cn(
              'outlay__resource-cost',
              isResponse && 'outlay__resource-opacity'
            )}
            data-title={t('estimate.list-cost')}
          >
            <input
              type="text"
              onFocus={handleInputFocus}
              onKeyUp={handleInputKeyUp}
              maxLength={18}
              {...register('cost', {
                onChange: (e) => {
                  setValue('cost', e.target.value.replace(/[^\d.]/g, ''));
                },
                onBlur: handleInputBlur,
              })}
              readOnly={isResponse}
              title={currencyFormatInput(resource.cost)}
              disabled={isResponse}
            />
          </div>
          <div
            className={cn(
              'outlay__resource-dev',
              isResponse && 'outlay__resource-opacity'
            )}
            title={t('estimate.delivery-time') as string}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Calendar
              short
              size="small"
              popup={!resource.response}
              date={resource.response?.deliveryTime || resource.deliveryTime}
              setDate={!resource.response ? handleSetDate : () => {}}
              disabled={isResponse}
            />
          </div>
          <div
            className={cn(
              'outlay__resource-markup',
              isResponse && 'outlay__resource-opacity'
            )}
            data-title={t('directory.markup')}
          >
            <input
              type="text"
              onFocus={handleInputFocus}
              onKeyUp={handleInputKeyUp}
              maxLength={18}
              {...register('markup', {
                onChange: (e) => {
                  setValue('markup', e.target.value.replace(/[^\d.-]/g, ''));
                },
                onBlur: handleInputBlur,
              })}
              title={percentFormat(resource.markup, locale)}
              disabled={isResponse}
            />
          </div>
          <div
            className={cn(
              'outlay__resource-price2',
              isResponse && 'outlay__resource-opacity'
            )}
            data-title={t('estimate.list-salePrice')}
          >
            <input
              type="text"
              onFocus={handleInputFocus}
              onKeyUp={handleInputKeyUp}
              maxLength={18}
              {...register('salePrice', {
                onChange: (e) => {
                  setValue('salePrice', e.target.value.replace(/[^\d.]/g, ''));
                },
                onBlur: handleInputBlur,
              })}
              title={currencyFormatInput(resource.salePrice)}
              disabled={isResponse}
            />
          </div>
          <div
            className={cn(
              'outlay__resource-cost2',
              isResponse && 'outlay__resource-opacity'
            )}
            data-title={t('estimate.list-saleCost')}
          >
            <input
              type="text"
              onFocus={handleInputFocus}
              onKeyUp={handleInputKeyUp}
              maxLength={18}
              {...register('saleCost', {
                onChange: (e) => {
                  setValue('saleCost', e.target.value.replace(/[^\d.]/g, ''));
                },
                onBlur: handleInputBlur,
              })}
              title={currencyFormatInput(resource.saleCost)}
              disabled={isResponse}
            />
          </div>
          {!editable && (
            <>
              <button
                className="outlay__resource-copy"
                title={t('estimate.copy-a-resource') ?? ''}
                onClick={handleCopyResourceClick}
              >
                <img src={copyIcon} />
              </button>
              <button
                className="outlay__resource-remove"
                title={t('estimate.delete-a-resource') ?? ''}
                onClick={() => onDeleteResource(resource.id)}
                disabled={!!isViewWarningRed}
              >
                <img
                  src={removeIcon}
                  alt={t('estimate.remove-resource') ?? ''}
                />
              </button>
            </>
          )}
          <div className="outlay__resource-position-center">
            {isViewWarningRed && (
              <i
                className="outlay__resource-position-center"
                title={`${t(
                  'estimate.the-resource-is-in-the-order-and-cannot-be-changed'
                )}`}
              >
                <IconError className="outlay__resource-icon-warning" />
              </i>
            )}
            {isViewWarningOrange && (
              <i
                className="outlay__resource-position-center"
                title={`${t(
                  'estimate.the-resource-has-a-supplier-selected-and-you-cannot-change'
                )}`}
              >
                <IconWarning className="outlay__resource-icon-warning" />
              </i>
            )}
          </div>
        </div>
        {create && (
          <ResourcesCatalogModal
            visible={openCatalog}
            onAddResources={handleAddResources}
            onCancel={handleCatalogCancel}
          />
        )}
        <CategorySelectModal
          isViewChoise={isViewChoise}
          handleChoiseClose={handleChoiseClose}
          handleClickAddCategory={handleClickAddCategory}
          handleSelectedCategory={handleSelectedCategory}
        />
        <SupplierOffersModal
          isViewChoise={isViewChoiseSupplierOffers}
          handleChoiseClose={handleChoiseSupplierOffersClose}
          resourceId={resource.id}
          withUpdate="updateCostEstimate"
          responseIdFromResource={resource.response?.id || ''}
          onCloseChat={handleSupplierOffersCloseChat}
        />
      </div>
    </>
  );
};

export const EstimateResource = memo(EstimateResourceRaw);
