import { useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { Link, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconCompany } from '@static/img/icon-company1.svg';
import { ReactComponent as IconAdd } from '@static/img/icon-add3.svg';
import { useOutsideClickListener } from '@hooks/useOutsideClickListener';
import { CostEstimateId, ObjectCostEstimate } from '@store/costEstimates/slice';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useCurrentLocale } from '@hooks/useCurrentLocale';
import {
  setActiveCreateNewCostEstimate,
  setCurrentEstimate,
} from '@store/costEstimates/actions';
import {
  getActiveCreateNewCostEstimate,
  getCurrentCostEstimate,
  getObjectCostEstimates,
  isCostEstimatesFetched,
} from '@store/costEstimates/selectors';
import { getCostEstimatesUrl } from '@pages';
import { getCompanyObjectId } from '@store/companyObjects/selectors';
import { createArrayMock } from '@utils/createArrayMock';
import { getCompanyCurrency } from '@store/company/selectors';
import { currencyFormat } from '@utils/currency';
import styles from './styles.module.sass';

export type CurrentObjectEstimate = {
  id: CostEstimateId;
  title?: string | null;
  saleCost: number;
};

export const CostEstimatesBar = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const locale = useCurrentLocale();

  const objectId = useAppSelector(getCompanyObjectId);
  const activeCreateNewCostEstimate = useAppSelector(
    getActiveCreateNewCostEstimate
  );
  const objectCostEstimates = useAppSelector(getObjectCostEstimates);

  const currencyCode = useAppSelector(getCompanyCurrency)?.code;

  const currentCostEstimate = useAppSelector(getCurrentCostEstimate);

  const divRef = useRef(null);
  const [isActive, setActive] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const costEstimateIdFromQuery = searchParams.get('costEstimateId');

  const isCostEstimatesFetchedStatus = useAppSelector(isCostEstimatesFetched);

  // This is the selected cost estimate. Rendering in modal title
  const [currentObjectCostEstimate, setCurrentObjectCostEstimate] = useState<
    CurrentObjectEstimate[]
  >([]);
  // This is items for render-list when modal is open
  const [listItems, setListItems] = useState<CurrentObjectEstimate[]>([]);

  const handleClickModal = () => {
    setActive(!isActive);
  };

  const handleCloseModal = () => {
    setActive(false);
  };

  // This mock added to render-list on create new cost estimate
  const mockObject = useMemo(
    () =>
      createArrayMock(1, () => {
        return {
          id: '777',
          title: t('about-object.estimate-of-object', {
            count: listItems.length + 1,
          }),
          saleCost: 0,
        };
      }),
    [t, listItems.length]
  );

  // On click select local current cost estimate(setCurrentObjectCostEstimate), set current cost estimate object in store
  const handleClickCurrentEstimate = (
    objectCostEstimate: CurrentObjectEstimate | ObjectCostEstimate,
    index: number
  ) => {
    const { id, saleCost } = objectCostEstimate;

    if (currentObjectCostEstimate[0].id === objectCostEstimate.id) {
      return null;
    }

    const currentObject: CurrentObjectEstimate[] = [
      {
        id,
        title: t('about-object.estimate-of-object', { count: index }),
        saleCost: saleCost || 0,
      },
    ];

    dispatch(setCurrentEstimate(objectCostEstimate));
    dispatch(setActiveCreateNewCostEstimate(false));
    setCurrentObjectCostEstimate(currentObject);
    setSearchParams({ costEstimateId: currentObject[0].id });
  };

  // If not match mock-object-id chooses which data to render. ObjectCostEstimates(original from fetch) if he length more then zero or currentObjectCostEstimate
  useEffect(() => {
    if (currentObjectCostEstimate[0]?.id !== mockObject[0].id) {
      const objectsToRender =
        objectCostEstimates.length > 0
          ? objectCostEstimates
          : currentObjectCostEstimate;

      setListItems(objectsToRender);
    }
  }, [objectCostEstimates, currentObjectCostEstimate, mockObject]);

  const handleClickCreateCostEstimateButton = () => {
    dispatch(setActiveCreateNewCostEstimate(true));
  };

  // This is push mockObject to current render-list for modal items(setListItems), and set modal title(setCurrentObjectCostEstimate) if activeCreateNewCostEstimate and idS don't match with mockObject-id
  useEffect(() => {
    const lastListItemMatchMockId =
      listItems[listItems.length - 1]?.id === mockObject[0].id;
    const firstCurrentObjEstimateMatchMockId =
      currentObjectCostEstimate[0]?.id === mockObject[0].id;

    if (
      !lastListItemMatchMockId &&
      !firstCurrentObjEstimateMatchMockId &&
      activeCreateNewCostEstimate
    ) {
      setListItems((prev) => [...prev, ...mockObject]);
      setCurrentObjectCostEstimate(mockObject);
    }
  }, [
    t,
    listItems,
    activeCreateNewCostEstimate,
    currentObjectCostEstimate,
    mockObject,
  ]);

  useEffect(() => {
    if (objectCostEstimates.length) {
      const currentCostEstimateId =
        currentCostEstimate?.id || costEstimateIdFromQuery || '';
      let currentCostEstimateIndex = objectCostEstimates.findIndex(
        (item) => item?.id === currentCostEstimateId
      );

      if (currentCostEstimateIndex < 0) {
        currentCostEstimateIndex = 0;
      }

      const currentEstimate = objectCostEstimates[currentCostEstimateIndex];

      dispatch(setCurrentEstimate(currentEstimate));
      setSearchParams({ costEstimateId: currentEstimate.id });

      const currentObject = [
        {
          id: currentEstimate.id,
          title: t('about-object.estimate-of-object', {
            count: currentCostEstimateIndex + 1,
          }),
          saleCost: currentEstimate.saleCost,
        },
      ];
      setCurrentObjectCostEstimate(currentObject);
    } else if (isCostEstimatesFetchedStatus) {
      setSearchParams(undefined);
    }
  }, [
    t,
    dispatch,
    objectCostEstimates,
    currentCostEstimate,
    costEstimateIdFromQuery,
    setSearchParams,
    isCostEstimatesFetchedStatus,
  ]);

  useOutsideClickListener(divRef, handleCloseModal);

  if (!currentObjectCostEstimate) {
    return null;
  }

  return (
    <div
      ref={divRef}
      className={cn(
        'sel',
        'big',
        'transparent',
        styles.wrapper,
        isActive && 'open'
      )}
      onClick={handleClickModal}
    >
      <a>
        <IconCompany />
        {currentObjectCostEstimate[0]?.title}
        <b>
          {' — '}
          {currencyCode !== undefined
            ? currencyFormat(
                currentObjectCostEstimate[0]?.saleCost || 0,
                currencyCode,
                locale
              )
            : currentObjectCostEstimate[0]?.saleCost || 0}
        </b>
      </a>
      <ul>
        {listItems.map((listItem, index) => {
          const isSameId = currentObjectCostEstimate[0]?.id === listItem.id;
          return (
            <li
              key={listItem.id}
              className={cn(
                { active: isSameId },
                { [styles.active]: isSameId },
                { [styles.item]: !isSameId }
              )}
              style={{ cursor: 'default' }}
              onClick={() => handleClickCurrentEstimate(listItem, index + 1)}
            >
              <IconCompany />
              {t('about-object.estimate-of-object', { count: index + 1 })}
              <b>
                {' — '}
                {currencyCode !== undefined
                  ? currencyFormat(listItem.saleCost, currencyCode, locale)
                  : listItem.saleCost}
              </b>
            </li>
          );
        })}
        {!activeCreateNewCostEstimate && objectCostEstimates.length > 0 && (
          <li>
            <Link
              to={getCostEstimatesUrl(objectId)}
              onClick={handleClickCreateCostEstimateButton}
            >
              <IconAdd />
              {t('about-object.create-estimate')}
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
};
