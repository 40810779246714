import React, { FC, memo, SyntheticEvent } from 'react';

type OfferGeneratorSlideProps = {
  index: number;
  imageNumber: number;
  imageSrc: string;
  onPositionChange: (prevIndex: number, nextIndex: number) => void;
};

const OfferGeneratorSlideRaw: FC<OfferGeneratorSlideProps> = ({
  index,
  imageNumber,
  imageSrc,
  onPositionChange,
}) => {
  const handleUpClick = (e: SyntheticEvent) => {
    e.preventDefault();
    onPositionChange(index, index - 1);
  };
  const handleDownClick = (e: SyntheticEvent) => {
    e.preventDefault();
    onPositionChange(index, index + 1);
  };

  return (
    <div className="gener__slide">
      <div>
        <a onClick={handleUpClick} />
        <i>
          <img src={imageSrc} />
        </i>
        <a onClick={handleDownClick} />
      </div>
      <span>
        <i>
          <s />
        </i>
        <p>{imageNumber}</p>
      </span>
    </div>
  );
};

export const OfferGeneratorSlide = memo(OfferGeneratorSlideRaw);
