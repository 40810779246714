import { useCurrentLocale } from '@hooks/useCurrentLocale';
import { currencyFormat } from '@utils/currency';
import { useTranslation } from 'react-i18next';

type Props = {
  total: number;
  currencyCode: string;
};
const OrderModalTableTotal = ({ total, currencyCode }: Props) => {
  const { t } = useTranslation();
  const locale = useCurrentLocale();
  return (
    <div className="order__table-total">
      <p>{t('bid.total-request')}</p>
      <b>
        {currencyCode !== undefined
          ? currencyFormat(total, currencyCode, locale)
          : total}
      </b>
    </div>
  );
};

export default OrderModalTableTotal;
