import { ReactComponent as IconFolder } from '@static/img/operation4.svg';
// import { ReactComponent as IconChat } from '@static/img/icon-chat3.svg';
import IconButton from '@components/commons/IconButton';
import { useAppSelector } from '@hooks/redux';
import { getUnit } from '@store/vocabulary/selectors';
import { RESOURCE_TYPES } from '@constants/resource';
import { ResourceType } from '@store/resources/slice';
import { getCurrencyCode } from '@store/requestCustomer/selectors';
import { currencyFormat } from '@utils/currency';
import { useCurrentLocale } from '@hooks/useCurrentLocale';
import Calendar from '@components/commons/Calendar';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconAnalog } from '@static/img/icon-sort2.svg';
import { ReactComponent as IconBackPack } from '@static/img/backpack__ObjectButton.svg';
import { ReactComponent as IconEdit } from '@static/img/icon-edit3.svg';
import { ReactComponent as IconRemove } from '@static/img/icon-delete.svg';
import { ReactComponent as IconWarning } from '@static/img/icon-error.svg';
import { ResourceParent } from '@store/costEstimateResources/slice';
import Counter from '@components/commons/Counter/Counter';
import TableRequestsSectionItemSupplier from '../TableRequestsSectionItemSupplier';
import styles from './styles.module.sass';

type Props = {
  resourceId: string;
  responseId: string;
  type: ResourceType;
  resourceName: string;
  category: string;
  amount: number;
  unitId: string;
  resourceParent: ResourceParent | null;
  price: number;
  cost: number;
  deliveryTime: number;
  supplierName: string;
  supplierLogo?: string;
  isViewSuppliers: boolean;
  sectionName: string;
  responseUnitId: string;
  isViewOfferFromTheSupplier: boolean;
  numberOfResponses: number | null;
  requestId: string;
  supplierId: string;
  responseExpired: boolean;
  handleOpenDeleteItemModal: (id: string) => void;
  handleOpenResponse: (id: string, responseId: string) => void;
};

const TableRequestsSectionItem = ({
  resourceId,
  responseId,
  type,
  resourceName,
  isViewOfferFromTheSupplier,
  category,
  amount,
  unitId,
  resourceParent,
  price,
  cost,
  deliveryTime,
  supplierName,
  supplierLogo,
  sectionName,
  isViewSuppliers,
  responseUnitId,
  numberOfResponses,
  responseExpired,
  requestId,
  supplierId,
  handleOpenResponse,
  handleOpenDeleteItemModal,
}: Props) => {
  const { t } = useTranslation();
  const unit = useAppSelector((state) => getUnit(state, unitId));
  const responseUnit = useAppSelector((state) =>
    getUnit(state, responseUnitId)
  );
  const currencyCode = useAppSelector(getCurrencyCode);
  const locale = useCurrentLocale();

  const currencyFormatInput = (value: number) => {
    return currencyCode && value !== undefined
      ? currencyFormat(value, currencyCode, locale)
      : value;
  };
  return (
    <>
      {resourceParent && (
        <i
          title={`${t('supply.an-analogue-is-proposed')}`}
          className={styles.iconAnalogContainer}
        >
          <IconAnalog style={{ marginRight: '8px' }} />
        </i>
      )}
      <div className="obj__supply-group">
        <s className={RESOURCE_TYPES[type].className}>
          {t(RESOURCE_TYPES[type].title)}
        </s>
      </div>
      <div className="obj__supply-name" title={resourceName}>
        {resourceName}
      </div>
      <div className="obj__supply-cat" title={category}>
        {category}
      </div>
      <div className="obj__supply-count" title={`${amount}`}>
        {amount}
      </div>
      <div
        className="obj__supply-unit"
        title={`${t(`units.${responseUnit?.name || unit?.name}`)}`}
      >
        {responseUnitId
          ? t(`units.${responseUnit?.name}`)
          : t(`units.${unit?.name}`)}
      </div>
      <div className="obj__supply-price">
        {resourceParent || isViewOfferFromTheSupplier ? (
          <i
            className="tooltip"
            title={`${t('estimate.the-price-is-from-the-supplier')}`}
          >
            <IconBackPack />
          </i>
        ) : (
          <i
            className="tooltip"
            title={`${t('estimate.the-price-is-indicated-manually')}`}
          >
            <IconEdit />
          </i>
        )}

        <p
          className={styles.outlayResourcePriceContainer}
          title={currencyCode && currencyFormat(price, currencyCode, locale)}
        >
          {currencyFormatInput(price)}
        </p>
        {!numberOfResponses ? (
          <IconButton
            className="open-modal tooltip"
            title={`${t('estimate.selection-of-supplier-offers')}`}
          >
            <IconFolder
              onClick={() => handleOpenResponse(resourceId, responseId)}
              className={styles.iconFolder}
            />
          </IconButton>
        ) : (
          <>
            <Counter
              count={numberOfResponses}
              title={t('estimate.selection-of-supplier-offers') || ''}
              onClick={() => handleOpenResponse(resourceId, responseId)}
            />
            {responseExpired && (
              <i
                title={`${t('supply.the-offer-period-has-expired')}`}
                className={styles.iconWarningExpiredContainer}
              >
                <IconWarning className={styles.iconWarningExpired} />
              </i>
            )}
          </>
        )}
      </div>
      <div className="obj__supply-cost" title={`${cost}`}>
        {currencyFormatInput(cost)}
      </div>
      <div className="obj__supply-dev">
        {deliveryTime && <Calendar date={deliveryTime} size="small" short />}
      </div>
      {!isViewSuppliers ? (
        <TableRequestsSectionItemSupplier
          supplierId={supplierId}
          requestId={requestId}
          supplierName={supplierName}
          supplierLogo={supplierLogo}
        />
      ) : (
        <div className="obj__supply-section">
          <p title={sectionName}>{sectionName}</p>
        </div>
      )}
      <div className={styles.containerButtonIconRemove}>
        <IconButton
          title={t('estimate.delete-a-resource') ?? ''}
          className={styles.buttonIconRemove}
          onClick={() => handleOpenDeleteItemModal(resourceId)}
        >
          <IconRemove />
        </IconButton>
      </div>
    </>
  );
};

export default TableRequestsSectionItem;
