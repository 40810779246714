import React, { SyntheticEvent } from 'react';
import cn from 'classnames';
import { ReactComponent as IconArrow } from '@static/img/icon-arrow7.svg';
import styles from './styles.module.sass';

export type HeaderProps = {
  title: string;
  hasBackForMobile?: boolean;
  buttons?: JSX.Element;
  toggle?: JSX.Element;
  onBackClick?: () => void;
  onHumbClick: () => void;
};

export const Header: React.FC<HeaderProps> = ({
  title,
  hasBackForMobile,
  buttons,
  toggle,
  onBackClick,
  onHumbClick,
}) => {
  const handleBackClick = (e: SyntheticEvent) => {
    e.preventDefault();
    onBackClick?.();
  };

  return (
    <section className="cap">
      {onBackClick !== undefined && (
        <a
          href="#"
          className={cn('cap__prev', { mobile: hasBackForMobile })}
          onClick={handleBackClick}
        >
          <IconArrow />
        </a>
      )}
      <div className="cap__humb" onClick={onHumbClick}>
        <i />
        <i />
      </div>
      <div className="cap__title">
        <h1 className={cn('cap__title-name', styles.objCapTitleNameWidth)}>
          <span className={styles.objCapTitleNameSpanText} title={title}>
            {title}
          </span>
          <b>Строй.рф</b>
        </h1>
      </div>
      <div className="cap__but">
        {toggle && <>{toggle}</>}
        {buttons && <>{buttons}</>}
      </div>
    </section>
  );
};
