/* eslint-disable no-nested-ternary */
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import {
  getCurrentConversation,
  getCurrentConversationId,
  getFetchStatusLoadingMessages,
  getMessages,
} from '@store/chat/selectors';
import { useRole } from '@hooks/useRole';
import React, { CSSProperties, useEffect } from 'react';
import {
  fetchMessagesConversationIdRequestAction,
  setActiveConversation,
} from '@store/chat/actions';
import { nanoid } from '@reduxjs/toolkit';
import cn from 'classnames';
import { FetchStatus } from '@store/constants';
import Loader from '@components/commons/Loader';
import Header from './Header';
import Control from './Control';
import MessageList from './Messages';
import { SelectedChat } from '../SelectedChat';

type ChatProps = {
  onClose?: () => void;
  rootClassName?: string;
  stylesRoot?: CSSProperties;
};

export default function Chat({
  onClose,
  rootClassName,
  stylesRoot,
}: ChatProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isCustomer } = useRole();

  const currentConversation = useAppSelector(getCurrentConversation);

  const currentConversationId = useAppSelector(getCurrentConversationId);

  const messagesActiveConversation = useAppSelector((state) =>
    getMessages(state, currentConversation?.id)
  );
  const fetchStatusLoadingMessages = useAppSelector(
    getFetchStatusLoadingMessages
  );

  useEffect(() => {
    if (currentConversation)
      dispatch(
        fetchMessagesConversationIdRequestAction({
          conversationId: currentConversation.id,
        })
      );
  }, [currentConversation, dispatch]);

  const id = nanoid();

  const ref = React.createRef<HTMLDivElement>();

  useEffect(() => {
    if (ref.current)
      ref.current.scrollTo({
        top: ref.current.scrollHeight,
        left: 0,
      });
  }, [id, ref]);

  useEffect(() => {
    return () => {
      dispatch(setActiveConversation(null));
    };
  }, [dispatch]);

  const reasonWithRequest = currentConversation?.request
    ? `${t('bid.request')} №${currentConversation.request.number}`
    : '';
  const reasonWithOrder = currentConversation?.order
    ? `${t('bid.order')} № ${currentConversation.order.number}`
    : reasonWithRequest;

  return (
    <div
      className={cn('chat__core', rootClassName, { modalchat: onClose })}
      style={stylesRoot}
    >
      <>
        {currentConversation && currentConversationId ? (
          <>
            <Header
              onClose={onClose}
              isModal={!!onClose}
              company={
                isCustomer
                  ? currentConversation.supplier.name
                  : currentConversation.company.name
              }
              reason={
                currentConversation.order ? reasonWithOrder : reasonWithRequest
              }
            />

            <div className="chat__block" ref={ref}>
              {fetchStatusLoadingMessages === FetchStatus.Fetching ? (
                <div style={{ marginTop: '50px' }}>
                  <Loader />
                </div>
              ) : (
                <>
                  {messagesActiveConversation.length === 0 && (
                    <p style={{ margin: '10px', paddingLeft: '20px' }}>
                      {t('chat.not-messages')}
                    </p>
                  )}
                  {messagesActiveConversation.map((e) => {
                    const date = e.day.split(':');
                    const chatDdate = `${date[0]} ${t(`month.${date[1]}`)}`;
                    return (
                      <>
                        <div className="chat__date" key={e.id}>
                          <p>{chatDdate}</p>
                        </div>
                        <MessageList messages={e.messages} key={`${e.id}msg`} />
                      </>
                    );
                  })}
                </>
              )}
            </div>

            <Control conversationId={currentConversationId} />
          </>
        ) : (
          <>
            {!onClose && (
              <small style={{ margin: '20px' }}>
                <SelectedChat />
              </small>
            )}
          </>
        )}
      </>
    </div>
  );
}
