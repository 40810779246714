import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { UseFormRegisterReturn } from 'react-hook-form';
// TODO change any
type SignUpInputProps = {
  type: string;
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegisterReturn<string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: any;
  maxLength?: number;
};

export const SignUpInput: FC<SignUpInputProps> = ({
  type,
  title,
  register,
  errors,
  maxLength,
}) => {
  const { t } = useTranslation();
  const name = register.name;

  const inputErrors = () => {
    switch (errors[name]?.type) {
      case 'required':
        return <small>{t('auth.validation-empty')}</small>;
      case 'pattern':
        switch (name) {
          case 'phone':
            return <small>{t('auth.validation-phone-pattern')}</small>;
          default:
            break;
        }
        break;
      case 'server':
        return <small>{errors[name].message}</small>;
      default:
        return null;
    }
    return null;
  };

  return (
    <div className={cn('input', { error: errors[name] })}>
      <input type={type} placeholder="0" maxLength={maxLength} {...register} />
      <label>{title}</label>
      {inputErrors()}
    </div>
  );
};
