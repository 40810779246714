import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppSelector } from '@hooks/redux';
import { getCompanyDataForGenerateCp } from '@store/company/selectors';
import { getSettingsUrl } from '@pages';
import logoIcon from '@static/img/logo2.svg';

type EstimateAlertProps = Record<string, unknown>;

const EstimateAlertRaw: FC<EstimateAlertProps> = () => {
  const { t } = useTranslation();
  const isValid = useAppSelector(getCompanyDataForGenerateCp);

  if (!isValid) {
    return (
      <div className="alert">
        <i>
          <img src={logoIcon} alt="Строй.рф" />
        </i>
        <b>{t('estimate.alert-title')}</b>
        <p>{t('estimate.alert-description')}</p>
        <Link to={getSettingsUrl()} className="snap border">
          {t('estimate.alert-button')}
        </Link>
      </div>
    );
  }

  return null;
};

export const EstimateAlert = memo(EstimateAlertRaw);
