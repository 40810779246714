import React, { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import cn from 'classnames';

type ListItemType = {
  id: string;
  name: string | undefined;
};
export type SelectProps = {
  title?: string | null;
  listItems: ListItemType[];
  isSmall?: boolean;
  onSelect: (id: string) => void;
  selectedId?: string;
  error?: string;
  onSearch?: (query: string) => void;
  onBlur?: () => void;
  selectedItem?: ListItemType;
};

const SelectWithRef = (
  {
    title,
    listItems,
    isSmall,
    onSelect,
    selectedId,
    error,
    onSearch,
    onBlur,
    selectedItem,
  }: SelectProps,
  ref: ForwardedRef<HTMLInputElement>
): JSX.Element => {
  const [activeModal, setActiveModal] = useState(false);
  const [value, setValue] = useState('');

  const selected = listItems.find((i) => i.id === selectedId);

  const modalHandler = () => {
    setActiveModal(!activeModal);
    if (activeModal && onSearch) {
      setValue(selectedItem?.name || '');
    }
  };

  const onClickActive = (listItem: ListItemType) => {
    onSelect(listItem.id);
    setActiveModal(false);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onSearch?.(e.target.value);
  };

  const getOptions = () => {
    let options = [...listItems];
    if (selectedItem) {
      options = [
        selectedItem,
        ...listItems.filter((item) => item.id !== selectedItem.id),
      ];
    }
    return options;
  };

  useEffect(() => {
    if (selected && selected.name) {
      setValue(selected.name);
    }
  }, [selected]);

  useEffect(() => {
    if (selectedItem && selectedItem.name) {
      setValue(selectedItem.name);
    }
  }, [selectedItem]);

  return (
    <>
      <div
        className={cn(
          'select',
          'border',
          isSmall && 'small',
          activeModal && 'open',
          activeModal && 'index',
          { error }
        )}
      >
        <input
          type="text"
          onClick={modalHandler}
          value={value}
          placeholder="0"
          readOnly={!onSearch}
          ref={ref}
          onChange={onInputChange}
          onBlur={onBlur}
        />
        <label>{title}</label>
        {error && <small>{error}</small>}
        <div>
          <ul>
            {getOptions().map((listItem) => (
              <li
                key={listItem.id}
                className={cn({ active: listItem.id === selectedId })}
                onClick={() => onClickActive(listItem)}
              >
                {listItem.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};
export const Select = forwardRef<HTMLInputElement, SelectProps>(SelectWithRef);
