import React, { SyntheticEvent } from 'react';

import { useTranslation } from 'react-i18next';
import { ObjectStatus } from '@store/companyObjects/slice';
import { AVAILABLE_PHASE } from '@constants/phases';
import { currencyFormat } from '@utils/currency';
import { useCurrentLocale } from '@hooks/useCurrentLocale';
import cn from 'classnames';
import styles from './styles.module.sass';

type TaskPhaseProps = {
  active: boolean;
  phaseName: string;
  totalObjects: number;
  totalPrice: number;
  status: ObjectStatus;
  priceCurrencyCode: string;
  onClick: () => void;
};

// TODO: Rework switch case
export const TaskPhase: React.FC<TaskPhaseProps> = ({
  active,
  phaseName,
  totalObjects,
  totalPrice,
  status,
  priceCurrencyCode,
  onClick,
}) => {
  const { t } = useTranslation();
  const locale = useCurrentLocale();

  const phaseClass = AVAILABLE_PHASE[status];

  const totalObjectsMarkup = () => {
    return (
      <p
        className={styles.objItemText}
        title={currencyFormat(totalPrice, priceCurrencyCode, locale) || ''}
      >
        {t('object.object_on', { count: totalObjects })}{' '}
        {currencyFormat(totalPrice, priceCurrencyCode, locale)}
      </p>
    );
  };

  const handleClick = (e: SyntheticEvent) => {
    e.preventDefault();
    onClick();
  };

  return (
    <a
      href="#"
      className={cn('object__title', { active })}
      onClick={handleClick}
    >
      <i className={phaseClass} />
      <h4>{phaseName}</h4>
      {totalObjects > 0 && totalObjectsMarkup()}
      <b className={phaseClass}>{totalObjects}</b>
    </a>
  );
};
