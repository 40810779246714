import { createContext } from 'react';

type Message = {
  id: string;
};

const WebSocketContext = createContext<{
  sendMessageToJson: (msg: Message) => void;
  receiveMessage: Message | undefined;
  closeListiner: () => void;
}>({
  sendMessageToJson: () => {},
  receiveMessage: undefined,
  closeListiner: () => {},
});

export default WebSocketContext;
