import { RESOURCE_TYPES } from '@constants/resource';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from '@store/constants';
import { OrderStatus, StatusOrder } from '@utils/enums';

export type FetchDeleteOrderItem = {
  orderItemId: string;
  orderId: string;
};

export type FetchStatusOrder = {
  ORDERED: FetchStatus;
  PRODUCTION: FetchStatus;
};

export type DocumentOrder = {
  id: string;
  fileName: string;
  originalFileName: string;
};

type BuildingObjectShortData = {
  id: string;
  name: string;
};

export type OrderList = {
  total: number;
  items: OrderItem[];
};

export type Order = {
  id: string;
  status: keyof typeof OrderStatus;
  number: number;
  deliveryTime: number;
  createdAt: number;
  allItemsCost: number;
  company: {
    id: string;
    name: string;
    image: {
      id: string;
      name: string;
      fileName: string;
    };
  };
  buildingObject: BuildingObjectShortData;
  request: {
    id: string;
    number: number;
  };
  needInvoice: boolean;
  invoiceValidity: number;
  invoice: DocumentOrder | null;
  payment: DocumentOrder | null;
  proxy: DocumentOrder | null;
  loadStatus: FetchStatus;
};

export type OrderItem = {
  id: string;
  name: {
    itemName: string;
    sectionName: string;
  };
  amount: number;
  type: keyof typeof RESOURCE_TYPES;
  price: number;
  cost: number;
  currencyCode: string;
  unitId: string;
  deliveryTime: number;
  status: FetchStatus;
};

type Orderstate = {
  status: Record<keyof FetchStatusOrder, FetchStatus>;
  orders: {
    [StatusOrder.ORDERED]: Order[];
    [StatusOrder.PRODUCTION]: Order[];
  };
  currentOrder: Order | null;
  orderList: OrderList | null;
  orderDetailsStatus: FetchStatus;
  deleteOrderStatus: FetchStatus;
};

const initialState: Orderstate = {
  status: {
    ORDERED: FetchStatus.NotFetched,
    PRODUCTION: FetchStatus.NotFetched,
  },
  orders: {
    ORDERED: [],
    PRODUCTION: [],
  },
  currentOrder: null,
  orderList: null,
  orderDetailsStatus: FetchStatus.NotFetched,
  deleteOrderStatus: FetchStatus.NotFetched,
};

export const orderSuplierSlice = createSlice({
  name: 'orderSupplier',
  initialState,
  reducers: {
    setDeleteOrderSupplierStatus: (
      state,
      action: PayloadAction<FetchStatus>
    ) => {
      state.deleteOrderStatus = action.payload;
    },

    setCurrentOrderId: (
      state,
      action: PayloadAction<{
        status: keyof typeof StatusOrder;
        orderId: string;
      }>
    ) => {
      const order =
        state.orders[action.payload.status].find(
          (i) => i.id === action.payload.orderId
        ) || null;
      if (order)
        state.currentOrder = { ...order, loadStatus: FetchStatus.NotFetched };
    },
    fetchOrerBasicDataRequestSeccess: (
      state,
      action: PayloadAction<{
        status: keyof FetchStatusOrder;
        orders: Order[];
      }>
    ) => {
      state.orders[action.payload.status] = action.payload.orders;
      state.status[action.payload.status] = FetchStatus.Fetched;
    },
    setOrderDetailsStatus: (state, action: PayloadAction<FetchStatus>) => {
      state.orderDetailsStatus = action.payload;
    },
    fetchUpdateOrder: (state) => {
      if (state.currentOrder)
        state.currentOrder = {
          ...state.currentOrder,
          loadStatus: FetchStatus.Fetching,
        };
    },
    fetchUpdateOrderRequestSuccess: (state, action: PayloadAction<Order>) => {
      state.currentOrder = {
        ...state.currentOrder,
        ...action.payload,
        loadStatus: FetchStatus.NotFetched,
      };
    },
    fetchOrerDetailsRequestActionSuccess: (
      state,
      action: PayloadAction<{ total: number; items: OrderItem[] }>
    ) => {
      state.orderList = action.payload;
      state.orderDetailsStatus = FetchStatus.Fetched;
    },
    fetchDeleteOrderItemsRequest: (
      state,
      action: PayloadAction<FetchDeleteOrderItem>
    ) => {
      if (state.orderList) {
        const index = state.orderList?.items.findIndex(
          (i) => i.id === action.payload.orderItemId
        );

        state.orderList.items[index] = {
          ...state.orderList.items[index],
          status: FetchStatus.Fetching,
        };
      }
    },
    FetchUpdateCurrentOrderValidityInvoiceRequestActionSuccess: (
      state,
      action: PayloadAction<Pick<Order, 'invoiceValidity'>>
    ) => {
      if (state.currentOrder)
        state.currentOrder = {
          ...state.currentOrder,
          invoiceValidity: action.payload.invoiceValidity,
          loadStatus: FetchStatus.Fetched,
        };
    },
    setStatusFetchingData: (
      state,
      action: PayloadAction<{ status: keyof typeof StatusOrder }>
    ) => {
      state.status[action.payload.status] = FetchStatus.Fetching;
    },
  },
});

export default orderSuplierSlice.reducer;
