import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { Saga } from '@sagas/types';
import { handleError } from '@sagas/errors';
import {
  FetchCostEstimateResourcesRequestAction,
  CreateCostEstimateResourceRequestAction,
  UpdateCostEstimateResourceRequestAction,
  DeleteCostEstimateResourceRequestAction,
  fetchCostEstimateResourcesRequest,
  fetchCostEstimateResourcesSuccess,
  createCostEstimateResourceRequest,
  createCostEstimateResourceSuccess,
  updateCostEstimateResourceRequest,
  updateCostEstimateResourceSuccess,
  deleteCostEstimateResourceRequest,
  deleteCostEstimateResourceSuccess,
  CostEstimateResourcesMoveRequestActions,
  fetchostEstimateResourcesMoveRequest,
  fetchCommercialProposalSuccess,
  fetchCommercialProposalRequest,
  setErrorDeleteResourceStatus,
  FetchCostEstimateResourceRequestAction,
  fetchCostEstimateResourceSuccess,
  fetchCostEstimateResourceRequest,
} from '@store/costEstimateResources/actions';
import {
  createCostEstimateResource,
  deleteCostEstimateResource,
  getCommercialProposal,
  getCostEstimateResource,
  getCostEstimateResources,
  moveCostEstimateResource,
  updateCostEstimateResource,
} from '@sagas/api/costEstimateResources';

const fetchCommercialProposalRequestHandler: Saga<FetchCostEstimateResourcesRequestAction> =
  function* ({ payload }) {
    const { companyId, objectId, costEstimateId } = payload;

    try {
      yield call(getCommercialProposal, companyId, objectId, costEstimateId);
      yield put(fetchCommercialProposalSuccess());
    } catch (e) {
      yield call(handleError, e);
    }
  };

const fetchCostEstimateResourceRequestHandler: Saga<FetchCostEstimateResourceRequestAction> =
  function* ({ payload }) {
    const { resourceId } = payload;

    try {
      const data = yield call(getCostEstimateResource, resourceId);
      yield put(fetchCostEstimateResourceSuccess({ data }));
    } catch (e) {
      yield call(handleError, e);
    }
  };

const fetchCostEstimateResourcesRequestHandler: Saga<FetchCostEstimateResourcesRequestAction> =
  function* ({ payload }) {
    const { companyId, objectId, costEstimateId } = payload;

    try {
      const costEstimateResources = yield call(
        getCostEstimateResources,
        companyId,
        objectId,
        costEstimateId
      );
      yield put(
        fetchCostEstimateResourcesSuccess({ data: costEstimateResources })
      );
    } catch (e) {
      yield call(handleError, e);
    }
  };

const createCostEstimateResourceRequestHandler: Saga<CreateCostEstimateResourceRequestAction> =
  function* ({ payload }) {
    const { companyId, objectId, costEstimateId, temporaryId, data } = payload;

    try {
      const costEstimateResource = yield call(
        createCostEstimateResource,
        companyId,
        objectId,
        costEstimateId,
        data
      );
      yield put(
        createCostEstimateResourceSuccess({
          companyId,
          objectId,
          costEstimateId,
          temporaryId,
          data: costEstimateResource,
        })
      );
    } catch (e) {
      yield put(
        fetchCostEstimateResourcesRequest({
          companyId,
          objectId,
          costEstimateId,
        })
      );
      yield call(handleError, e);
    }
  };

const updateCostEstimateResourceRequestHandler: Saga<UpdateCostEstimateResourceRequestAction> =
  function* ({ payload }) {
    const { companyId, objectId, costEstimateId, id, data } = payload;

    try {
      const costEstimate = yield call(
        updateCostEstimateResource,
        companyId,
        objectId,
        costEstimateId,
        id,
        data
      );
      yield put(
        updateCostEstimateResourceSuccess({
          companyId,
          objectId,
          costEstimateId,
          id,
          data: costEstimate,
        })
      );
    } catch (e) {
      yield put(
        fetchCostEstimateResourcesRequest({
          companyId,
          objectId,
          costEstimateId,
        })
      );
      yield call(handleError, e);
    }
  };

const deleteCostEstimateResourceRequestHandler: Saga<DeleteCostEstimateResourceRequestAction> =
  function* ({ payload }) {
    const { companyId, objectId, costEstimateId, id } = payload;

    try {
      yield call(
        deleteCostEstimateResource,
        companyId,
        objectId,
        costEstimateId,
        id
      );
      yield put(
        deleteCostEstimateResourceSuccess({
          companyId,
          objectId,
          costEstimateId,
          id,
        })
      );
    } catch (e) {
      yield put(
        fetchCostEstimateResourcesRequest({
          companyId,
          objectId,
          costEstimateId,
        })
      );
      yield put(setErrorDeleteResourceStatus());
      yield call(handleError, e);
    }
  };
const fetchostEstimateResourcesMoveRequestHandler: Saga<CostEstimateResourcesMoveRequestActions> =
  function* ({ payload }) {
    const {
      companyId,
      costEstimateId,
      objectId,
      objectElementId,
      subjectElementId,
      toMove,
    } = payload;

    const countPoint = toMove === -1 ? 'before' : 'after';
    const elementType = 'resource';
    try {
      yield call(
        moveCostEstimateResource,
        companyId,
        objectId,
        costEstimateId,
        { countPoint, elementType, objectElementId, subjectElementId }
      );
    } catch (e) {
      yield call(handleError, e);
    }
  };

export default function* root() {
  yield all([
    takeLatest(
      fetchCommercialProposalRequest,
      fetchCommercialProposalRequestHandler
    ),
    takeLatest(
      fetchCostEstimateResourceRequest,
      fetchCostEstimateResourceRequestHandler
    ),
    takeLatest(
      fetchCostEstimateResourcesRequest,
      fetchCostEstimateResourcesRequestHandler
    ),
    takeEvery(
      createCostEstimateResourceRequest,
      createCostEstimateResourceRequestHandler
    ),
    takeEvery(
      updateCostEstimateResourceRequest,
      updateCostEstimateResourceRequestHandler
    ),
    takeEvery(
      deleteCostEstimateResourceRequest,
      deleteCostEstimateResourceRequestHandler
    ),
    takeEvery(
      fetchostEstimateResourcesMoveRequest,
      fetchostEstimateResourcesMoveRequestHandler
    ),
  ]);
}
