import React, { FC, useMemo, useState } from 'react';
import cn from 'classnames';
import { CompanyObjectId, ObjectStatus } from '@store/companyObjects/slice';
import { useTranslation } from 'react-i18next';
import { TaskColumn } from '@components/Dashboard/TaskColumn/TaskColumn';
import { TaskPhase } from '@components/Dashboard/TaskPhase/TaskPhase';
import { useAppSelector } from '@hooks/redux';
import { getCompanyObjects } from '@store/companyObjects/selectors';
import { nanoid } from '@reduxjs/toolkit';
import { ObjectCollection } from './ObjectCollection';

export type PhaseNamesData = {
  title: string;
  status: ObjectStatus;
};

type DashboardProps = {
  onClick: (id: CompanyObjectId) => void;
  companyCurrencyCode: string;
  handleCreateButtonClick: () => void;
};

// TODO: Change key=index for TaskPhase, TaskColumn
export const Dashboard: FC<DashboardProps> = ({
  onClick,
  companyCurrencyCode,
  handleCreateButtonClick,
}) => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);

  const companyObjects = useAppSelector(getCompanyObjects);
  const objectCollection = useMemo(() => {
    return new ObjectCollection(companyObjects);
  }, [companyObjects]);

  const phaseNames: PhaseNamesData[] = [
    {
      title: t('object.new-application'),
      status: ObjectStatus.NEW,
    },
    {
      title: t('object.cost-estimate-preparation'),
      status: ObjectStatus.PREPARE,
    },
    {
      title: t('object.commercial-proposal-approval'),
      status: ObjectStatus.AGREEMENT,
    },
    {
      title: t('object.in-work'),
      status: ObjectStatus.IN_WORK,
    },
  ];

  const handlePhaseClick = (index: number) => () => {
    setActiveIndex(index);
  };

  return (
    <div className={cn('object')}>
      <div className="object__cap">
        {phaseNames.map((phaseName, index) => {
          const totalPrice = objectCollection.getCostByKey(phaseName.status);
          const totalObjects = objectCollection.getObjectsByKey(
            phaseName.status
          ).length;
          return (
            <TaskPhase
              key={nanoid()}
              active={index === activeIndex}
              phaseName={phaseName.title}
              totalObjects={totalObjects}
              totalPrice={totalPrice}
              status={phaseName.status}
              priceCurrencyCode={companyCurrencyCode}
              onClick={handlePhaseClick(index)}
            />
          );
        })}
      </div>
      <div className={cn('object__cot')}>
        {phaseNames.map((phaseName, index) => {
          const companyObjectsFilteredByStatus =
            objectCollection.getObjectsByKey(phaseName.status);
          return (
            <TaskColumn
              key={nanoid()}
              active={index === activeIndex}
              onClick={onClick}
              companyObjects={companyObjectsFilteredByStatus}
              priceCurrencyCode={companyCurrencyCode}
              handleCreateButtonClick={handleCreateButtonClick}
              phaseName={phaseName.status}
            />
          );
        })}
      </div>
    </div>
  );
};
