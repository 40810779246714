import { RootState } from '@store';
import { FetchStatus } from '@store/constants';

export const getRootState = (state: RootState) => state.costEstimateOperations;
export const getLastCreatedId = (state: RootState) =>
  getRootState(state).lastCreatedId;
export const getStatus = (state: RootState) => getRootState(state).status;
export const getDeleteOperationStatus = (state: RootState) =>
  getRootState(state).deleteOperationStatus;
export const getCostEstimateOperations = (state: RootState) =>
  getRootState(state).items;

// export const getCostEstimateOperationsByCostEstimateId = (
//   state: RootState,
//   costEstimateId: CostEstimateId
// ) =>
//   getCostEstimateOperations(state).filter(
//     (item) => item.costEstimateId === costEstimateId
//   );

export const isCostEstimateOperationsNotFetched = (state: RootState) =>
  getStatus(state) === FetchStatus.NotFetched;
export const isCostEstimateOperationsFetching = (state: RootState) =>
  getStatus(state) === FetchStatus.Fetching;
export const isCostEstimateOperationsFetched = (state: RootState) =>
  getStatus(state) === FetchStatus.Fetched;
