import { call } from 'redux-saga/effects';
import { ENDPOINTS } from '@config';
import { getRequest, patchRequest, postRequest, putRequest } from '@sagas/api';
import { CompanyObjectId } from '@store/companyObjects/slice';
import { CostEstimateId } from '@store/costEstimates/slice';
import { CostEstimateOperationId } from '@store/costEstimateOperations/slice';
import { CompanyId } from '@store/company/slice';
import { urlFromTemplate } from '@utils/url';
import { CostEstimateSectionId } from '@store/costEstimateSections/slice';
import { UnitId } from '@store/vocabulary/slice';
import { OperationId } from '@store/operations/slice';
import { SectionId } from '@components/Estimate';

export type FetchedCostEstimateOperation = {
  id: CostEstimateOperationId;
  sectionId: CostEstimateSectionId;
  name: string;
  amount: number;
  price: number;
  cost: number;
  salePrice: number;
  saleCost: number;
  markup: number;
  unitId: UnitId;
  ordering: number;
};
export type CreateCostEstimateOperationData = {
  id: CostEstimateOperationId;
  sectionId: CostEstimateSectionId;
  name: string;
  unitId: UnitId;
  amount: number;
  price: number;
  salePrice: number;
  cost: number;
  saleCost: number;
  markup: number;
  ordering: number;
};

export type CreateCostEstimateOperationWithResourcesData = {
  sectionId: SectionId;
  operations: {
    id: OperationId;
    amount: number;
  }[];
};

export type UpdateCostEstimateOperationData = {
  name: string;
  unitId: UnitId;
  amount: number;
  price: number;
  salePrice: number;
  cost: number;
  saleCost: number;
  markup: number;
};

export type ParamsMoveOperation = {
  subjectElementId: string;
  countPoint: string;
  elementType: string;
  objectElementId: string;
};

export function* getCostEstimateOperation(operationId: OperationId) {
  const { data } = yield call(
    getRequest,
    urlFromTemplate(
      ENDPOINTS.COMPANY_OBJECTS.COST_ESTIMATE_OPERATIONS.GET_OPERATION,
      {
        operationId,
      }
    )
  );

  return data as FetchedCostEstimateOperation;
}

export function* getCostEstimateOperations(
  companyId: CompanyId,
  objectId: CompanyObjectId,
  costEstimateId: CostEstimateId
) {
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.COMPANY_OBJECTS.COST_ESTIMATE_OPERATIONS.GET, {
      companyId,
      objectId,
      costEstimateId,
    })
  );

  return data as FetchedCostEstimateOperation[];
}

export function* createCostEstimateOperation(
  companyId: CompanyId,
  objectId: CompanyObjectId,
  costEstimateId: CostEstimateId,
  params: CreateCostEstimateOperationData
) {
  const { data } = yield call(
    postRequest,
    urlFromTemplate(ENDPOINTS.COMPANY_OBJECTS.COST_ESTIMATE_OPERATIONS.CREATE, {
      companyId,
      objectId,
      costEstimateId,
    }),
    params
  );

  return data as FetchedCostEstimateOperation;
}

export function* createCostEstimateOperationWithResources(
  companyId: CompanyId,
  objectId: CompanyObjectId,
  costEstimateId: CostEstimateId,
  params: CreateCostEstimateOperationWithResourcesData
) {
  yield call(
    postRequest,
    urlFromTemplate(ENDPOINTS.COMPANY_OBJECTS.COST_ESTIMATE_OPERATIONS.ADD, {
      companyId,
      objectId,
      costEstimateId,
    }),
    params
  );
}

export function* updateCostEstimateOperation(
  companyId: CompanyId,
  objectId: CompanyObjectId,
  costEstimateId: CostEstimateId,
  operationId: CostEstimateOperationId,
  params: UpdateCostEstimateOperationData
) {
  const { data } = yield call(
    putRequest,
    urlFromTemplate(ENDPOINTS.COMPANY_OBJECTS.COST_ESTIMATE_OPERATIONS.UPDATE, {
      companyId,
      objectId,
      costEstimateId,
      operationId,
    }),
    params
  );

  return data as FetchedCostEstimateOperation;
}

export function* deleteCostEstimateOperation(
  companyId: CompanyId,
  objectId: CompanyObjectId,
  costEstimateId: CostEstimateId,
  id: CostEstimateOperationId
) {
  yield call(
    patchRequest,
    urlFromTemplate(ENDPOINTS.COMPANY_OBJECTS.COST_ESTIMATE_OPERATIONS.DELETE, {
      companyId,
      objectId,
      costEstimateId,
    }),
    {
      operations: [id],
    }
  );
}

export function* moveCostEstimateOperation(
  companyId: string,
  objectId: string,
  costEstimateId: string,
  params: ParamsMoveOperation
) {
  const { data } = yield call(
    putRequest,
    urlFromTemplate(ENDPOINTS.COMPANY_OBJECTS.COST_ESTIMATES.MOVEITEMS, {
      companyId,
      objectId,
      costEstimateId,
    }),
    params
  );

  return data as FetchedCostEstimateOperation[];
}
