import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { ROOT_FOLDER_ID } from '@constants/folders';
import { useSelectedResources } from '@hooks/useSelectedResources';
import { Modal } from '@components/Modal';
import {
  ResourcesCatalogSize,
  ResourcesCatalog,
} from '@components/ResourcesCatalog';
import { ResourceFolderId } from '@store/resourceFolders/slice';
import { ResourceId } from '@store/resources/slice';
import styles from './styles.module.sass';

type ResourcesCatalogModalProps = {
  visible: boolean;
  onAddResources: (resourceIds: ResourceId[]) => void;
  onCancel: () => void;
};

export const ResourcesCatalogModal: FC<ResourcesCatalogModalProps> = ({
  visible,
  onAddResources,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [folderId, setFolderId] = useState(ROOT_FOLDER_ID);
  const [selectedResources, handleSelectResources, resetSelected] =
    useSelectedResources();
  const activeButtons = selectedResources.length > 0;

  const handleChangeFolder = (id: ResourceFolderId) => {
    setFolderId(id);
  };

  const handleAddResourcesClick = () => {
    onAddResources(selectedResources);
    onCancel();
  };
  const handleCancelClick = () => {
    onCancel();
  };

  useEffect(() => {
    if (!visible) {
      resetSelected();
    }
  }, [resetSelected, visible]);

  return (
    <Modal visible={visible} onBackgroundClick={onCancel}>
      <ResourcesCatalog
        className={cn('modal-scroll', {
          [styles.modalScroll]: !activeButtons,
          [styles.activeButtons]: activeButtons,
        })}
        size={ResourcesCatalogSize.Minimal}
        folderId={folderId}
        onChangeFolder={handleChangeFolder}
        selectedResources={selectedResources}
        onSelectResources={handleSelectResources}
      />
      {activeButtons && (
        <div className="modal__but">
          <a
            className="link white border modal-close"
            onClick={handleCancelClick}
          >
            {t('confirm.cancel')}
          </a>
          <a className="link" onClick={handleAddResourcesClick}>
            {t('estimate.add-resources')}
          </a>
        </div>
      )}
    </Modal>
  );
};
