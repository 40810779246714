export enum Errors {
  InvalidCred = 'Invalid credentials.',
  InvalidPhone = 'valid phone number',
  SameEmail = 'User with such email already registered',
  OnlyAdminsRemoveCompany = 'Administrators can remove only their own company',
  OnlyAdminsCreateCompany = 'Only Administrators can create a company',
  CompanyNotAccess = 'You have not access to this company',
  ObjectNotAccess = 'You have not access to this building object',
  OperationSameName = 'Operation with the same name already exists',
  FolderSameName = 'Folder with the same name already exists',
  EstimateNotHistorical = 'Cost estimate is not historical',
  EstimateWrong = 'Wrong cost estimate',
  EstimateNotFoundHistorical = 'Can not find historical cost estimate',
  CompanyFillInfo = 'Fill company information',
  ProposalNotFound = 'Commercial Proposal was not found',
  SectionNotFound = 'Section was not found',
  OperationNotFound = 'Operation was not found',
  InvalidOfferName = 'Name can contain letters, digits, spaces, single quotes and hyphens',
  EmailNotFound = 'User email address not found',
  PasswordRecoveryLinkExpired = 'The password recovery link has expired. Please request a new password recovery link',
  CurrentPasswordIncorrectly = 'The current password is entered incorrectly',
  UnspecifiedServerError = 'Unspecified server error',
  SetDateDelivery = 'Please set delivery time for all resources',
  NotDataSettingCompany = 'To place an order, please fill in the information about your company in the settings',
  NotResourceWithresponseSupplier = 'There must be at least one resource with the selected up-to-date response from the supplier',
  TheRequestSubmittedOnceDay = 'The request for the entire estimate can be submitted once a day. You can create a request for the selected resources or wait until a day has passed.',
  WrongFileFormat = 'Wrong file format',
  TheSelectedDateShouldNotBeEarlierThanToday = 'The selected date should not be earlier than today',
  TheVerificationCodeWasNotRequested = 'The verification code was not requested by email. To continue registration, please request a verification code.',
  WrongVerificationCode = 'Wrong verification code. Please try again.',
  TheVerificationCodeHasExpired = 'The verification code has expired. Please request a new one.',
  YourTokenIsInvalid = 'Your token is invalid, please login again to get a new one',
  ToPlaceAResponse = 'To place a response, please fill in the information about your company in the settings',
}

export const AVAILABLE_ERRORS: Record<Errors | string, string> = {
  [Errors.OnlyAdminsRemoveCompany]: 'errors.only-admins-remove-company',
  [Errors.OnlyAdminsCreateCompany]: 'errors.only-admins-create-company',
  [Errors.CompanyNotAccess]: 'errors.company-not-access',
  [Errors.ObjectNotAccess]: 'errors.object-not-access',
  [Errors.OperationSameName]: 'errors.operation-same-name',
  [Errors.FolderSameName]: 'errors.folder-same-name',
  [Errors.EstimateNotHistorical]: 'errors.estimate-not-historical',
  [Errors.EstimateWrong]: 'errors.estimate-wrong',
  [Errors.EstimateNotFoundHistorical]: 'errors.estimate-not-found-historical',
  [Errors.CompanyFillInfo]: 'errors.company-fill-info',
  [Errors.ProposalNotFound]: 'errors.proposal-not-found',
  [Errors.SectionNotFound]: 'errors.section-not-found',
  [Errors.OperationNotFound]: 'errors.operation-not-found',
  [Errors.InvalidCred]: 'errors.invalid-credentials',
  [Errors.InvalidPhone]: 'errors.invalid-phone',
  [Errors.SameEmail]: 'errors.email-same-name',
  [Errors.InvalidOfferName]: 'errors.invalid-offer-name',
  [Errors.EmailNotFound]: 'errors.user-not-found',
  [Errors.PasswordRecoveryLinkExpired]: 'errors.password-recovery-link-expired',
  [Errors.CurrentPasswordIncorrectly]: 'errors.current-password-incorrectly',
  [Errors.UnspecifiedServerError]: 'errors.unspecified-server-error',
  [Errors.SetDateDelivery]: 'errors.set-date-delivery',
  [Errors.NotDataSettingCompany]: 'errors.not-data-setting-company',
  [Errors.NotResourceWithresponseSupplier]:
    'errors.resource-with-selected-response-from-supplier',
  [Errors.TheRequestSubmittedOnceDay]:
    'errors.the-request-for-the-entire-estimate-can-be-submitted-once-a-day',
  [Errors.WrongFileFormat]: 'errors.wrong-file-format',
  [Errors.TheSelectedDateShouldNotBeEarlierThanToday]:
    'errors.the-selected-date-should-not-be-earlier-than-today',
  [Errors.TheVerificationCodeWasNotRequested]:
    'errors.the-verification-code-was-not-requested',
  [Errors.WrongVerificationCode]: 'errors.wrong-verification-code',
  [Errors.TheVerificationCodeHasExpired]:
    'errors.the-verification-code-has-expired',
  [Errors.YourTokenIsInvalid]: 'errors.your-token-is-invalid',
  [Errors.ToPlaceAResponse]: 'errors.to-place-a-response',
};

export const errorHandler = (message: Errors | string) => {
  if (message === undefined) return null;

  if (message.includes(Errors.InvalidPhone)) {
    return AVAILABLE_ERRORS[Errors.InvalidPhone];
  }
  return (
    AVAILABLE_ERRORS[message] || AVAILABLE_ERRORS[Errors.UnspecifiedServerError]
  );
};
