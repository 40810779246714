import {
  all,
  call,
  put,
  SagaReturnType,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { Saga } from '@sagas/types';
import { handleError } from '@sagas/errors';
import {
  FetchResourcesRequestAction,
  CreateResourceRequestAction,
  UpdateResourceRequestAction,
  DeleteResourcesRequestAction,
  fetchResourcesRequest,
  fetchResourcesSuccess,
  createResourceRequest,
  createResourceSuccess,
  updateResourceRequest,
  updateResourceSuccess,
  deleteResourcesRequest,
  deleteResourcesSuccess,
} from '@store/resources/actions';
import {
  getResources,
  createResource,
  updateResource,
  deleteResources,
} from '@sagas/api/resources';

const fetchResourcesRequestHandler: Saga<FetchResourcesRequestAction> =
  function* ({ payload }) {
    const { companyId } = payload;

    try {
      const data: SagaReturnType<typeof getResources> = yield call(
        getResources,
        companyId
      );

      yield put(fetchResourcesSuccess({ companyId, data }));
    } catch (e) {
      yield call(handleError, e);
    }
  };

const createResourceRequestHandler: Saga<CreateResourceRequestAction> =
  function* ({ payload }) {
    const { companyId, temporaryId, data } = payload;

    try {
      const resource: SagaReturnType<typeof createResource> = yield call(
        createResource,
        companyId,
        data
      );

      yield put(
        createResourceSuccess({ companyId, temporaryId, data: resource })
      );
    } catch (e) {
      yield call(handleError, e);
    }
  };

const updateResourceRequestHandler: Saga<UpdateResourceRequestAction> =
  function* ({ payload }) {
    const { companyId, id, data } = payload;

    try {
      yield call(updateResource, companyId, id, data);
      yield put(updateResourceSuccess({ companyId, id, data }));
    } catch (e) {
      yield call(handleError, e);
    }
  };

const deleteResourcesRequestHandler: Saga<DeleteResourcesRequestAction> =
  function* ({ payload }) {
    const { companyId, data } = payload;

    try {
      yield call(deleteResources, companyId, data);
      yield put(deleteResourcesSuccess({ companyId, ids: data.ids }));
    } catch (e) {
      yield call(handleError, e);
    }
  };

export default function* root() {
  yield all([
    takeLatest(fetchResourcesRequest, fetchResourcesRequestHandler),
    takeEvery(createResourceRequest, createResourceRequestHandler),
    takeEvery(updateResourceRequest, updateResourceRequestHandler),
    takeEvery(deleteResourcesRequest, deleteResourcesRequestHandler),
  ]);
}
