import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Saga } from '@sagas/types';
import { handleError } from '@sagas/errors';
import { FetchOrderCustomerRequestAction } from '@store/requestCustomer/actions';
import {
  DeleteOrderCustomerRequestAction,
  deleteOrderCustomerItemRequest,
  deleteOrderCustomerItemSuccess,
  fetchOrderCustomerSuccess,
  fetchOrderCustomerRequest,
  FetchOrderCustomerItemsRequestAction,
  fetchOrderCustomerItemsSuccess,
  fetchOrderCustomerItemsRequest,
  RequestAnInvoiceRequestAction,
  requestAnInvoiceSuccess,
  requestAnInvoiceRequest,
  UpdateOrderCustomerFinishedStatusRequestAction,
  updateOrderCustomerFinishedStatusSuccess,
  updateOrderCustomerFinishedStatusRequest,
  setErrorDeleteOrderCustomerItemStatus,
  DeleteOrderCustomerProxyDocumentRequestAction,
  deleteOrderCustomerProxyDocumentRequest,
  setDeleteOrderCustomerProxyDocumentStatus,
} from '@store/ordersCustomer/actions';
import { FetchStatus } from '@store/constants';
import {
  deleteOrderCustomerItem,
  deleteOrderCustomerProxyDocument,
  getOrderCustomer,
  getOrderCustomerItems,
  requestAnInvoice,
  updateOrderCustomerFinishedStatus,
} from './api/orderCustomer';

const getOrdersCustomerRequestHandler: Saga<FetchOrderCustomerRequestAction> =
  function* ({ payload }) {
    try {
      const data = yield call(getOrderCustomer, payload.costEstimateId);
      yield put(fetchOrderCustomerSuccess(data));
    } catch (e) {
      yield call(handleError, e);
    }
  };

const getOrdersCustomerItemsRequestHandler: Saga<FetchOrderCustomerItemsRequestAction> =
  function* ({ payload }) {
    const { orderId } = payload;
    try {
      const data = yield call(getOrderCustomerItems, orderId);
      yield put(fetchOrderCustomerItemsSuccess({ data }));
    } catch (e) {
      yield call(handleError, e);
    }
  };

const requestAnInvoiceRequestHandler: Saga<RequestAnInvoiceRequestAction> =
  function* ({ payload }) {
    const { orderId } = payload;
    try {
      yield call(requestAnInvoice, orderId);
      yield put(requestAnInvoiceSuccess({ orderId }));
      const data = yield call(getOrderCustomer, payload.costEstimateId);
      yield put(fetchOrderCustomerSuccess(data));
    } catch (e) {
      yield call(handleError, e);
    }
  };

const updateOrderCustomerFinishedStatusHandler: Saga<UpdateOrderCustomerFinishedStatusRequestAction> =
  function* ({ payload }) {
    const { orderId, status } = payload;
    try {
      yield call(updateOrderCustomerFinishedStatus, orderId, status);
      yield put(updateOrderCustomerFinishedStatusSuccess({ orderId }));
      const data = yield call(getOrderCustomer, payload.costEstimateId);
      yield put(fetchOrderCustomerSuccess(data));
    } catch (e) {
      yield call(handleError, e);
    }
  };

const deleteOrderCustomerItemRequestHandler: Saga<DeleteOrderCustomerRequestAction> =
  function* ({ payload }) {
    const { orderitemId, orderId } = payload;
    try {
      yield call(deleteOrderCustomerItem, orderitemId);
      yield put(deleteOrderCustomerItemSuccess({ orderitemId }));
      const data = yield call(getOrderCustomerItems, orderId);
      yield put(fetchOrderCustomerItemsSuccess({ data }));
    } catch (e) {
      yield put(setErrorDeleteOrderCustomerItemStatus());
      yield call(handleError, e);
    }
  };

const deleteOrderCustomerProxyDocumentRequestHandler: Saga<DeleteOrderCustomerProxyDocumentRequestAction> =
  function* ({ payload }) {
    const { orderDocumentId, costEstimateId } = payload;
    try {
      yield put(
        setDeleteOrderCustomerProxyDocumentStatus(FetchStatus.Fetching)
      );
      yield call(deleteOrderCustomerProxyDocument, orderDocumentId);
      yield put(setDeleteOrderCustomerProxyDocumentStatus(FetchStatus.Fetched));
      const data = yield call(getOrderCustomer, costEstimateId);
      yield put(fetchOrderCustomerSuccess(data));
    } catch (e) {
      yield put(setDeleteOrderCustomerProxyDocumentStatus(FetchStatus.Error));
      yield call(handleError, e);
    }
  };

export default function* root() {
  yield all([
    takeLatest(fetchOrderCustomerRequest, getOrdersCustomerRequestHandler),
    takeLatest(
      fetchOrderCustomerItemsRequest,
      getOrdersCustomerItemsRequestHandler
    ),
    takeLatest(requestAnInvoiceRequest, requestAnInvoiceRequestHandler),
    takeLatest(
      deleteOrderCustomerItemRequest,
      deleteOrderCustomerItemRequestHandler
    ),
    takeLatest(
      updateOrderCustomerFinishedStatusRequest,
      updateOrderCustomerFinishedStatusHandler
    ),
    takeLatest(
      deleteOrderCustomerProxyDocumentRequest,
      deleteOrderCustomerProxyDocumentRequestHandler
    ),
  ]);
}
