import React, {
  FC,
  memo,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormRegisterReturn } from 'react-hook-form';
import cn from 'classnames';
import { RESOURCE_TYPES } from '@constants/resource';
import { useOutsideClickListener } from '@hooks/useOutsideClickListener';
import { ResourceType } from '@store/resources/slice';
import './styles.sass';

type ResourcesCatalogResourceSelectProps = {
  editable: boolean;
  value: ResourceType;
  onSelected: (value: ResourceType) => void;
  onFocus: () => void;
  onBlur: () => void;
  register: UseFormRegisterReturn;
};

const ResourcesCatalogResourceSelectRaw: FC<
  ResourcesCatalogResourceSelectProps
> = ({ editable, value, onSelected, onFocus, onBlur, register }) => {
  const { t } = useTranslation();

  const divRef = useRef<HTMLDivElement>(null);
  const [selectedType, setSelectedType] = useState(value);
  const [focusedInput, setFocusedInput] = useState(false);

  const handleClick = () => {
    if (focusedInput) {
      setFocusedInput(false);
      onBlur();
    } else {
      setFocusedInput(true);
      onFocus();
    }
  };
  const handleBlur = () => {
    setFocusedInput(false);
    onBlur();
  };
  const handleSelect = (type: ResourceType) => (e: SyntheticEvent) => {
    e.stopPropagation();
    onSelected(type);
    handleBlur();
    setSelectedType(type);
  };
  const handleOutsideClick = () => {
    if (focusedInput) {
      handleBlur();
    }
  };

  useOutsideClickListener(divRef, handleOutsideClick);

  useEffect(() => {
    setSelectedType(value);
  }, [value, editable]);

  return (
    <div ref={divRef} className="manager__resource-group" onClick={handleClick}>
      <input type="hidden" {...register} />
      <div className={cn('sel', { index: focusedInput, open: focusedInput })}>
        {/* <a> */}
        <s className={RESOURCE_TYPES[selectedType].className}>
          {t(RESOURCE_TYPES[selectedType].title)}
        </s>
        {/* </a> */}
        <ul>
          {Object.values(ResourceType).map((type) => (
            <li key={type} onClick={handleSelect(type)}>
              <s className={RESOURCE_TYPES[type].className}>
                {t(RESOURCE_TYPES[type].title)}
              </s>{' '}
              {t(RESOURCE_TYPES[type].description)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export const ResourcesCatalogResourceSelect = memo(
  ResourcesCatalogResourceSelectRaw
);
