import { useCurrentLocale } from '@hooks/useCurrentLocale';
import { currencyFormat } from '@utils/currency';
import { useTranslation } from 'react-i18next';

type Props = {
  budget: number;
  // Ждем когда будет готово на бэке и уберем коммент
  // overBudget: number;
  // remainsToPurchase: number;
  // savings: number;
  currencyCode: string;
};

const SupplyBudget = ({
  budget,
  // overBudget,
  // remainsToPurchase,
  // savings,
  currencyCode,
}: Props) => {
  const { t } = useTranslation();
  const locale = useCurrentLocale();

  return (
    <ul>
      <li>
        <p>{t('supply.budget')}</p>
        <b>
          {currencyCode && budget !== undefined
            ? currencyFormat(budget, currencyCode, locale)
            : budget}
        </b>
      </li>
      {/* // Ждем когда будет готово на бэке и уберем коммент */}
      {/* <li>
        <p>{t('supply.over-budget')}</p>
        <b>
          {currencyCode && overBudget !== undefined
            ? currencyFormat(overBudget, currencyCode, locale)
            : overBudget}
        </b>
      </li>
      <li>
        <p>{t('supply.it-remains-to-purchase')}</p>
        <b>
          {currencyCode && remainsToPurchase !== undefined
            ? currencyFormat(remainsToPurchase, currencyCode, locale)
            : remainsToPurchase}
        </b>
      </li>
      <li>
        <p>{t('supply.savings')}</p>
        <b>
          {currencyCode && savings !== undefined
            ? currencyFormat(savings, currencyCode, locale)
            : savings}
        </b>
      </li> */}
    </ul>
  );
};

export default SupplyBudget;
