import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchStatus } from '@store/constants';
import {
  refreshTokenError,
  RefreshTokenSuccessAction,
  SignInSuccessAction,
  signOutSuccess,
  SignUpSuccessAction,
} from '@store/user/actions';
import { axiosInstanceFileFn } from '@utils/axios';

export type UserId = string;

export type AuthData = {
  accessToken: string;
  refreshToken: string;
};

export type UserState = {
  authData: AuthData | null;
  emailConfirmStatus: FetchStatus;
};

const initialState: UserState = {
  authData: null,
  emailConfirmStatus: FetchStatus.NotFetched,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    signInSuccess: (state, action: PayloadAction<SignInSuccessAction>) => {
      const { data } = action.payload;

      state.authData = data;
    },
    signUpSuccess: (state, action: PayloadAction<SignUpSuccessAction>) => {
      const { data } = action.payload;

      state.authData = data;
    },
    refreshTokenSuccess: (
      state,
      action: PayloadAction<RefreshTokenSuccessAction>
    ) => {
      const { data } = action.payload;

      state.authData = data;

      axiosInstanceFileFn(data.accessToken);
    },

    emailConfirmSuccess: (state) => {
      state.emailConfirmStatus = FetchStatus.Fetched;
    },
    setEmailConfirmStatus: (state, action: PayloadAction<FetchStatus>) => {
      state.emailConfirmStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signOutSuccess, () => initialState);
    builder.addCase(refreshTokenError, () => initialState);
  },
});

export default userSlice.reducer;
