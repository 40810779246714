import React from 'react';
import { useTranslation } from 'react-i18next';

// TODO: change loading
const Loading: React.FC = () => {
  const { t } = useTranslation();

  return <div>{t('status.loading')}...</div>;
};

export default Loading;
