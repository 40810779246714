import { createAction } from '@reduxjs/toolkit';
import { OrderStatus, StatusOrder } from '@utils/enums';
import { orderSuplierSlice } from './slice';

export type FetchOrders = {
  status: keyof typeof StatusOrder;
};
export type FetchOrderList = {
  orderId: string;
};

export type FetchOrder = {
  orderId: string;
};

export type FetchOrderStatus = {
  orderId: string;
  status: keyof typeof OrderStatus;
};

export type FetchUpdateOrderInvoiceValidity = {
  orderId: string;
  invoiceValidity: number;
};

export const FetchOrerBasicDataRequestAction = createAction<FetchOrders>(
  'orderSupplier/FetchOredrBasicDataRequestAction'
);
export const FetchOrerDetailsRequestAction = createAction<FetchOrderList>(
  'orderSupplier/FetchOrerDetailsRequestAction'
);
export const FetchDeleteOrerRequestAction = createAction<FetchOrderList>(
  'orderSupplier/FetchDeleteOrerRequestAction'
);

export const FetchUpdateStatusORderRequestAction =
  createAction<FetchOrderStatus>(
    'orderSupplier/FetchUpdateStatusORderRequestAction'
  );

export const FetchUpdateCurrentOrderRequestAction = createAction<{
  orderId: string;
}>('orderSupplier/FetchUpdateCurrentOrderRequestAction');

export const FetchUpdateCurrentOrderValidityInvoiceRequestAction =
  createAction<FetchUpdateOrderInvoiceValidity>(
    'orderSupplier/FetchUpdateCurrentOrderValidityInvoiceRequestAction'
  );

export const {
  setDeleteOrderSupplierStatus,
  fetchDeleteOrderItemsRequest,
  fetchOrerBasicDataRequestSeccess,
  fetchOrerDetailsRequestActionSuccess,
  fetchUpdateOrder,
  fetchUpdateOrderRequestSuccess,
  setCurrentOrderId,
  setOrderDetailsStatus,
  FetchUpdateCurrentOrderValidityInvoiceRequestActionSuccess,
  setStatusFetchingData,
} = orderSuplierSlice.actions;
