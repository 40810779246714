import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EntityStatus, FetchStatus } from '@store/constants';
import { refreshTokenError, signOutSuccess } from '@store/user/actions';
import { ResourceFolderId } from '@store/resourceFolders/slice';
import {
  CreateResourceRequestAction,
  CreateResourceSuccessAction,
  FetchResourcesRequestAction,
  FetchResourcesSuccessAction,
  UpdateResourceRequestAction,
  DeleteResourcesRequestAction,
} from '@store/resources/actions';
import { Currency, UnitId } from '@store/vocabulary/slice';

export type ResourceId = string;

export enum ResourceType {
  MAT = 'MAT',
  MEC = 'MEC',
  HUM = 'HUM',
  EXT = 'EXT',
}

export type Resource = {
  status: EntityStatus;
  id: ResourceId;
  name: string;
  type: ResourceType;
  price: number;
  salePrice: number;
  markup: number;
  resourceFolderId: ResourceFolderId;
  unitId: UnitId;
  currency: Currency;
};

export type ResourcesState = {
  status: FetchStatus;
  items: Resource[];
};

const initialState: ResourcesState = {
  status: FetchStatus.NotFetched,
  items: [],
};

export const resourcesSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    fetchResourcesRequest: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<FetchResourcesRequestAction>
    ) => {
      state.status = FetchStatus.Fetching;
    },
    fetchResourcesSuccess: (
      state,
      action: PayloadAction<FetchResourcesSuccessAction>
    ) => {
      const { data } = action.payload;

      state.status = FetchStatus.Fetched;
      state.items = data.map((d) => ({ ...d, status: EntityStatus.Fetched }));
    },
    createResourceRequest: (
      state,
      action: PayloadAction<CreateResourceRequestAction>
    ) => {
      const { data, temporaryId } = action.payload;

      state.items.push({
        ...data,
        id: temporaryId,
        currency: { id: '', name: 'dollar', code: 'USD', symbol: '$' },
        status: EntityStatus.Fetching,
      });
    },
    createResourceSuccess: (
      state,
      action: PayloadAction<CreateResourceSuccessAction>
    ) => {
      const { data, temporaryId } = action.payload;
      const index = state.items.findIndex((item) => item.id === temporaryId);

      state.items[index] = {
        ...data,
        status: EntityStatus.Fetched,
      };
    },
    updateResourceRequest: (
      state,
      action: PayloadAction<UpdateResourceRequestAction>
    ) => {
      const { id, data } = action.payload;
      const index = state.items.findIndex((i) => i.id === id);

      state.items[index] = {
        ...state.items[index],
        ...data,
      };
    },
    deleteResourcesRequest: (
      state,
      { payload }: PayloadAction<DeleteResourcesRequestAction>
    ) => {
      const { ids } = payload.data;

      state.items = state.items.filter((item) => !ids.includes(item.id));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signOutSuccess, () => initialState);
    builder.addCase(refreshTokenError, () => initialState);
  },
});

export default resourcesSlice.reducer;
