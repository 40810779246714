import { CompanyId } from '@store/company/slice';
import { CreateContactData, UpdateContactData } from '@sagas/api/contacts';
import { createAction } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { ResponseWithPagination } from '@store/types';
import {
  CompanyObjectId,
  Contact,
  ContactId,
  FullContacts,
  contactSlice,
} from './slice';

export type FetchContactsRequestAction = {
  id: CompanyId;
  page: number;
  max?: number;
  isUpdatePage?: boolean;
};

export type FetchFullContactsSuccessAction = {
  response: ResponseWithPagination<FullContacts>;
  isUpdatePage?: boolean;
};

export type FetchContactsSuccessAction = {
  data: Contact[];
};

export type CreateContactRequestAction = {
  data: CreateContactData;
  companyId: CompanyId;
};

export type CreateContactSuccessAction = {
  data: Contact;
};

export type UpdateContactRequestAction = {
  data: UpdateContactData;
  companyId: CompanyId;
  contactId: ContactId;
  page?: number;
};

export type DeleteContactRequestAction = {
  companyId: CompanyId;
  id: ContactId;
};

export type DeleteContactSuccessAction = {
  id: ContactId;
};

export type UpdateContactSuccessAction = {
  data: UpdateContactData;
  id: CompanyObjectId;
};

export type ArchiveContactRequestAction = {
  companyId: CompanyId;
  id: CompanyObjectId;
};
export type ArchiveContactSuccessAction = {
  id: CompanyObjectId;
};

export const archiveContactRequest = createAction<ArchiveContactRequestAction>(
  'company/archiveObjectRequest'
);

export const {
  deleteContactSuccess,
  deleteContactRequest,
  resetUpdateContactStatus,
  updateContactSuccess,
  updateContactRequest,
  setChoiseFullContact,
  fetchFullContactsObjectRequest,
  fetchFullContactsObjectSuccess,
  setChoiseContact,
  deleteChoiseContact,
  fetchContactsObjectRequest,
  fetchContactSuccess,
  fetchContactsObjectSuccess,
  fetchAddContactRequest,
  createContactRequest,
  createContactSuccess,
  archiveContactSuccess,
  setCurrentCompanyObjectId,
  setActiveBlinkWhenCreatedNewContact,
  resetCreateContactStatus,
  resetLatestAddedCompanyObjectId,
  setViewContact,
} = contactSlice.actions;
