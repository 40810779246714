import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormState, UseFormRegister } from 'react-hook-form';
import { EMAIL_PATTERN } from '@constants/patterns';
import cn from 'classnames';
import { useFormErrors } from '@hooks/useFormErrors';
// TODO change any
type LoginInputProps = {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formState: FormState<any>;
};

export const LoginInput: React.FC<LoginInputProps> = ({
  name,
  register,
  formState,
}) => {
  const { t } = useTranslation();
  const { errors: formErrors } = formState;
  const errors = useFormErrors(formErrors);

  const emailErrors = () => {
    switch (errors[name]?.type) {
      case 'required':
        return <small>{t('auth.validation-empty')}</small>;
      case 'minLength':
        return <small>{t('auth.validation-email-short')}</small>;
      case 'pattern':
        return <small>{t('auth.validation-email')}</small>;
      case 'server':
        return <small>{`${errors[name].message}`}</small>;
      default:
        return null;
    }
  };

  return (
    <div className={cn('input', { error: errors[name] })}>
      <input
        type="text"
        placeholder="0"
        {...register(name, {
          required: true,
          minLength: 3,
          pattern: EMAIL_PATTERN,
        })}
      />
      <label>{t('auth.email')}</label>
      {emailErrors()}
    </div>
  );
};
