import React, { memo, useContext, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@hooks/redux';
import { OperationId } from '@store/operations/slice';
import { getOperationsByFolder } from '@store/operations/selectors';
import { getCurrency, getDefaultUnit } from '@store/vocabulary/selectors';
import { getProfileSuggestedCurrencyId } from '@store/profile/selectors';
import { OperationCatalogContext } from '@components/OperationsCatalog';
import { ReactComponent as ArrowIcon } from '@static/img/icon-arrow6.svg';
import addOperationIcon from '@static/img/icon-add6.svg';
import { EntityStatus } from '@store/constants';
import { OperationsCatalogOperation } from '../Operation';
import './styles.sass';

type OperationsCatalogOperationListType = {
  onCreateOperationClick: () => void;
};

const OperationsCatalogOperationListRaw: React.FC<
  OperationsCatalogOperationListType
> = ({ onCreateOperationClick }) => {
  const { t } = useTranslation();
  const { size, folderId, isSelectedOperation, onSelectResources } = useContext(
    OperationCatalogContext
  );
  const [openedList, setOpenedList] = useState<Record<OperationId, boolean>>(
    {}
  );
  const [createOperation, setCreateOperation] = useState(false);
  const operations = useAppSelector((state) =>
    getOperationsByFolder(state, folderId)
  );
  const defaultUnit = useAppSelector(getDefaultUnit);

  const suggestedCurrencyId = useAppSelector(getProfileSuggestedCurrencyId);
  const currency = useAppSelector((state) =>
    getCurrency(state, suggestedCurrencyId ?? '')
  );

  const operationsOpened = operations.every(
    (operation) => openedList[operation.id]
  );
  const operationsSelected =
    operations.length > 0 && operations.every(isSelectedOperation);

  const handleCreateOperation = () => {
    setCreateOperation(true);
    onCreateOperationClick();
  };

  const handleCancelCreateOperation = () => {
    setCreateOperation(false);
  };

  const handleToggleOperationsOpen = () => {
    setOpenedList(() => {
      return operations.reduce<typeof openedList>((acc, operation) => {
        acc[operation.id] = !operationsOpened;
        return acc;
      }, {});
    });
  };

  const handleToggleOperationsSelect = () => {
    operations.forEach((operation) => {
      onSelectResources(
        operation.id,
        operation.resources.map((resource) => resource.id),
        !operationsSelected
      );
    });
  };

  const handleOperationOpenChange = (id: OperationId, opened: boolean) => {
    setOpenedList((prevOpenedList) => ({
      ...prevOpenedList,
      [id]: opened,
    }));
  };

  if (currency === undefined) {
    return null;
  }

  return (
    <div className="manager__base">
      <div className={cn('manager__operations', size)}>
        <div
          className={cn('manager__operations-cap', { open: operationsOpened })}
        >
          <span className="check" onClick={handleToggleOperationsSelect}>
            <input
              type="checkbox"
              name="operation_all"
              value="1"
              checked={operationsSelected}
              readOnly
            />
            <label />
          </span>
          <p>
            <i>
              <ArrowIcon onClick={handleToggleOperationsOpen} />
            </i>
            {t('directory.operation-name')}
          </p>
          <p>{t('directory.quantity')}</p>
          <p>{t('directory.unit')}</p>
          <p>{t('directory.price')}</p>
          <p>{t('directory.markup')}</p>
          <p>{t('directory.for-customer')}</p>
        </div>
        <div className="manager__add-operation" onClick={handleCreateOperation}>
          <img src={addOperationIcon} alt="Создать операцию" />
          {t('directory.create-operation')}
        </div>
        {operations.map((operation) => (
          <OperationsCatalogOperation
            key={operation.id}
            operation={operation}
            opened={openedList[operation.id]}
            onOperationOpenChange={handleOperationOpenChange}
          />
        ))}
        {createOperation && (
          <OperationsCatalogOperation
            operation={{
              status: EntityStatus.New,
              id: '',
              name: '',
              amount: 1,
              unitId: defaultUnit.id,
              operationFolderId: folderId,
              resources: [],
              price: 0,
              salePrice: 0,
              markup: 0,
              currency,
            }}
            create
            opened={false}
            onOperationOpenChange={handleOperationOpenChange}
            onCancel={handleCancelCreateOperation}
          />
        )}
      </div>
    </div>
  );
};

export const OperationsCatalogOperationList = memo(
  OperationsCatalogOperationListRaw
);
