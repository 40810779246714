import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getCostEstimatesUrl } from '@pages';
import { CompanyObjectId } from '@store/companyObjects/slice';
import newAddIcon from '@static/img/new-add.gif';

type NewObjectProps = {
  objectId: CompanyObjectId;
};
export const NewObject: FC<NewObjectProps> = ({ objectId }) => {
  const { t } = useTranslation();
  return (
    <div className="content__base">
      <div className="obj">
        <div className="obj__cap">
          <h4>{t('about-object.object-indicators')}</h4>
        </div>
        <div className="outlay__add">
          <i>
            <img
              src={newAddIcon}
              alt={t('about-object.get-started-create-estimate') ?? ''}
            />
          </i>
          <h6>{t('about-object.get-started-create-estimate')}</h6>
          <p>{t('about-object.after-started-create-estimate')}</p>
          <Link to={getCostEstimatesUrl(objectId)} className="link center">
            {t('about-object.to-estimate')}
          </Link>
        </div>
      </div>
    </div>
  );
};
