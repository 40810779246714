/* eslint-disable react-hooks/exhaustive-deps */
import { useUpload } from '@hooks/useUpload';
import {
  BaseSyntheticEvent,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { urlFromTemplate } from '@utils/url';
import { nanoid } from '@reduxjs/toolkit';
import lodingLogoLight from '@static/img/logo4.svg';
import lodingLogoDark from '@static/img/logo3.svg';
import { handleError } from '@sagas/errors';
import { useAppDispatch } from '@hooks/redux';
import css from './FieldFile.module.sass';

export type Params = { [key: string]: string | number };

export type Endpoints = {
  updateObjectImage: string;
  createObjectImage: string;
  settingCompanyLogo: string;
  settingSupplierLogo: string;
  profileImage: string;
  document: string;
  orderDocument: string;
  importExcel: string;
};

const edpoints: Endpoints = {
  updateObjectImage: '/company/{companyId}/building-object/{objectId}/image',
  createObjectImage: '/image',
  settingCompanyLogo: '/company/{companyId}/logo',
  settingSupplierLogo: '/supplier/{supplierId}/image',
  profileImage: '/user/image',
  document: '/building-object/{buildingObjectId}/document/{category}',
  orderDocument: '/order/{orderId}/document/{category}',
  importExcel:
    '/building-object/{buildingObjectId}/cost-estimate/import/{type}',
};

interface IInputImg extends PropsWithChildren {
  stylesLabel?: boolean;
  disabled?: boolean;
  className?: string;
  setUploadFileId?: (id: string) => void;
  setUploadFileUrl: (url: string) => void;
  setUploadFileName?: (name: string) => void;
  partLoadFile: keyof Endpoints;
  params?: Params | (() => Params);
  htmlForId?: string;
  isQuicklyUpload?: boolean;
  isViewLoader?: boolean;
  onErrorUpload?: () => void;
  setIsLoading?: Dispatch<SetStateAction<boolean>>;
  setSelectedFileName?: (fileName: string) => void;
  sideEffect?: () => void;
  themeDownloader?: 'ligth' | 'dark';
}

export default function InputFileUpload({
  stylesLabel,
  children,
  disabled,
  partLoadFile,
  setUploadFileUrl,
  setUploadFileName,
  setUploadFileId,
  params = {},
  htmlForId,
  isQuicklyUpload = true,
  isViewLoader = true,
  onErrorUpload,
  setIsLoading,
  setSelectedFileName,
  sideEffect,
  themeDownloader = 'dark',
}: IInputImg) {
  const dispatch = useAppDispatch();

  const ref = useRef<HTMLInputElement>(null);

  const upLoadId = htmlForId || nanoid().replaceAll('-', '_');

  const [selectedImage, setSelectedImage] = useState();

  const { loadFile, loading, urlFile, originalFileName, idFile } = useUpload();

  const onSelectFile = (e: BaseSyntheticEvent) => {
    if (!e.target.files || e.target.files.length === 0) return;
    setSelectedImage(e.target.files[0]);
    if (setSelectedFileName) {
      setSelectedFileName(e.target.files[0].name);
    }
  };

  const url = urlFromTemplate(
    edpoints[partLoadFile],
    typeof params === 'function' ? params() : params
  );

  const uploadFile = async () => {
    try {
      if (!selectedImage) return;

      await loadFile(selectedImage, url);
      if (ref.current) {
        ref.current.value = '';
      }
    } catch (e) {
      if (onErrorUpload) onErrorUpload();
      if (setSelectedFileName) setSelectedFileName('');
      setSelectedImage(undefined);
      [...handleError(e)].forEach((i) => dispatch({ ...i.payload.action }));
    }
  };

  useEffect(() => {
    if (isQuicklyUpload) {
      uploadFile();
    }
  }, [selectedImage, isQuicklyUpload]);

  useEffect(() => {
    if (urlFile) setUploadFileUrl(urlFile);
    if (sideEffect) {
      sideEffect();
    }
  }, [urlFile]);

  useEffect(() => {
    if (originalFileName && setUploadFileName) {
      setUploadFileName(originalFileName);
    }
  }, [originalFileName]);

  useEffect(() => {
    if (idFile && setUploadFileId) setUploadFileId(idFile);
  }, [idFile]);

  useEffect(() => {
    if (setIsLoading) setIsLoading(loading);
  }, [loading]);

  const setLoader = () => {
    switch (themeDownloader) {
      case 'dark':
        return lodingLogoDark;

      default:
        return lodingLogoLight;
    }
  };

  return (
    <>
      {isViewLoader && loading && (
        <div className={css.preloaderFile}>
          <img src={setLoader()} alt="" />
        </div>
      )}

      <label
        htmlFor={upLoadId}
        style={{ position: stylesLabel ? 'absolute' : 'initial' }}
      >
        {children}
      </label>
      <input
        type="file"
        onChange={(e) => onSelectFile(e)}
        ref={ref}
        id={upLoadId}
        style={{ display: 'none' }}
        disabled={disabled}
      />
    </>
  );
}
