import Checkbox from '@components/commons/Checkbox';
import Image from '@components/commons/Image';
import { ReactComponent as IconChat } from '@static/img/icon-chat3.svg';
import { TypeSize, setSizeImage } from '@utils/formatImage';
import { useEffect, useState } from 'react';
import IconButton from '@components/commons/IconButton';
import { setIsViewChat } from '@store/modalControl/actions';
import { useAppDispatch } from '@hooks/redux';
import { RequestCustomerDataItem } from '@store/requestCustomer/slice';
import { fetchIsChekConversationRequestAction } from '@store/chat/actions';
import styles from './styles.module.sass';

type Props = {
  sectionItemIds: string[];
  sectionName: string;
  isViewSuppliers: boolean;
  supplier: RequestCustomerDataItem['supplier'];
  supplierLogo?: string;
  selectedIds: string[];
  requestId: string;
  handleAddSectionIds: (ids: string[], checked: boolean) => void;
};

const SectionName = ({
  sectionItemIds,
  sectionName,
  isViewSuppliers,
  supplier,
  supplierLogo,
  selectedIds,
  requestId,
  handleAddSectionIds,
}: Props) => {
  const dispatch = useAppDispatch();

  const isViewCheckbox = (isViewSuppliers && supplier) || sectionName;

  const [isChecked, setIsChecked] = useState(false);

  const handleOpenChatFromRequest = () => {
    dispatch(
      fetchIsChekConversationRequestAction({
        requestId,
        supplierId: supplier.id,
      })
    );
    dispatch(setIsViewChat());
  };

  useEffect(() => {
    const isHaveNoCheckElement = sectionItemIds.find(
      (elem) => !selectedIds.includes(elem)
    );

    if (isHaveNoCheckElement) {
      setIsChecked(false);
    } else if (sectionItemIds.length > 0) {
      setIsChecked(true);
    }
  }, [sectionItemIds, selectedIds]);

  return (
    <div className="obj__supply-title">
      {isViewCheckbox && (
        <span className="obj__supply-check">
          <Checkbox
            styles={{ paddingLeft: '0px' }}
            onChange={(e) =>
              handleAddSectionIds([...sectionItemIds], e.currentTarget.checked)
            }
            checked={isChecked}
            disabled={!sectionItemIds.length}
          />
        </span>
      )}
      <div style={{ paddingLeft: '0px' }}>
        {isViewSuppliers && supplierLogo && (
          <i className={styles.objSupplySectionNameImageContainer}>
            <Image
              url={setSizeImage(supplierLogo, TypeSize.secondSupplierLogo)}
              styles={{ borderRadius: '2px' }}
            />
          </i>
        )}
        <h4 className={styles.objSupplySectionNameContainer}>{sectionName}</h4>
        {isViewSuppliers && supplier && (
          <IconButton
            className={styles.objSupplySectionNameChatButton}
            onClick={handleOpenChatFromRequest}
          >
            <IconChat />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default SectionName;
