import { Modal, ModalSize } from '@components/Modal';
import SuppliersOffers from '@components/SupplierOffers';
import { useAppSelector } from '@hooks/redux';
import { ObjectModal } from '@pages/Dashboard/ObjectModal';
import Chat from '@pages/Messenger/Chat';
import { getisViewChatSupplierOffersDrawer } from '@store/modalControl/selectors';
import styles from './styles.module.sass';

type Props = {
  isViewChoise: boolean;
  handleChoiseClose: () => void;
  onCloseChat?: () => void;
  resourceId: string;
  withUpdate: 'updateCostEstimate' | 'updateSupply';
  responseIdFromResource: string;
};

const SupplierOffersModal = ({
  isViewChoise,
  resourceId,
  withUpdate,
  responseIdFromResource,
  handleChoiseClose,
  onCloseChat,
}: Props) => {
  const isViewChatSupplierOffersDrawer = useAppSelector(
    getisViewChatSupplierOffersDrawer
  );

  return (
    <Modal
      visible={isViewChoise}
      size={isViewChatSupplierOffersDrawer ? ModalSize.Max : ModalSize.Medium}
      onBackgroundClick={handleChoiseClose}
    >
      <ObjectModal onClose={handleChoiseClose} styles={{ padding: '0px' }}>
        <div style={{ display: 'flex' }}>
          <SuppliersOffers
            onClose={handleChoiseClose}
            resourceId={resourceId}
            withUpdate={withUpdate}
            responseIdFromResource={responseIdFromResource}
          />
          {isViewChatSupplierOffersDrawer && (
            <Chat rootClassName={styles.chatSize} onClose={onCloseChat} />
          )}
        </div>
      </ObjectModal>
    </Modal>
  );
};

export default SupplierOffersModal;
