import { RootState } from '@store';
import { CompanyObjectId } from '@store/companyObjects/slice';
import { FetchStatus } from '@store/constants';

export const getRootState = (state: RootState) => state.costEstimates;
export const getObjectCostEstimatesStatus = (state: RootState) =>
  getRootState(state).objectCostEstimatesStatus;
export const getDeleteCostEstimatesStatus = (state: RootState) =>
  getRootState(state).deleteCostEstimateStatus;
export const getCommercialProposalStatus = (state: RootState) =>
  getRootState(state).commercialProposalStatus;
export const getObjectCostEstimates = (state: RootState) =>
  getRootState(state).objectCostEstimates;
export const getCostEstimatesStatus = (state: RootState) =>
  getRootState(state).costEstimatesStatus;
export const getCostEstimates = (state: RootState) =>
  getRootState(state).costEstimates;
export const getCurrentCostEstimate = (state: RootState) =>
  getRootState(state).currentEstimate;
export const getCurrentCostEstimateId = (state: RootState) =>
  getRootState(state).currentEstimate?.id;
export const getActiveCreateNewCostEstimate = (state: RootState) =>
  getRootState(state).activeCreateNewCostEstimate;
export const getCompletelyCommercialProposal = (state: RootState) =>
  getRootState(state).сompletelyFilledCommercialProposal;
export const getRequestId = (state: RootState) =>
  getRootState(state).currentEstimate?.requestId || '';
export const getSelectedIds = (state: RootState) =>
  getRootState(state).selectedIds;

export const getCurrentEstimateByObject = (state: RootState) =>
  getRootState(state).currentObjectCostEstimate;

export const getObjectCostEstimateById = (
  state: RootState,
  id: CompanyObjectId
) => {
  if (id === undefined) {
    return undefined;
  }

  return getObjectCostEstimates(state).find(
    (costEstimate) => costEstimate.buildingObjectId === id
  );
};

export const getCostEstimateByObjectId = (
  state: RootState,
  id: CompanyObjectId
) => {
  if (id === undefined) {
    return undefined;
  }

  return getCostEstimates(state).find(
    (costEstimate) => costEstimate.buildingObjectId === id
  );
};

export const isCommercialProposalNotFetched = (state: RootState) =>
  getCommercialProposalStatus(state) === FetchStatus.NotFetched;
export const isCommercialProposalFetching = (state: RootState) =>
  getCommercialProposalStatus(state) === FetchStatus.Fetching;
export const isCommercialProposalFetched = (state: RootState) =>
  getCommercialProposalStatus(state) === FetchStatus.Fetched;

export const isObjectCostEstimatesNotFetched = (state: RootState) =>
  getObjectCostEstimatesStatus(state) === FetchStatus.NotFetched;
export const isObjectCostEstimatesFetching = (state: RootState) =>
  getObjectCostEstimatesStatus(state) === FetchStatus.Fetching;
export const isObjectCostEstimatesFetched = (state: RootState) =>
  getObjectCostEstimatesStatus(state) === FetchStatus.Fetched;

export const isCostEstimatesNotFetched = (state: RootState) =>
  getCostEstimatesStatus(state) === FetchStatus.NotFetched;
export const isCostEstimatesFetching = (state: RootState) =>
  getCostEstimatesStatus(state) === FetchStatus.Fetching;
export const isCostEstimatesFetched = (state: RootState) =>
  getCostEstimatesStatus(state) === FetchStatus.Fetched;
