import React from 'react';
import { Outlet } from 'react-router-dom';
import { SettingsDirectoryTabs } from './Tabs';

export const SettingsDirectory = () => {
  return (
    <div className="content__core">
      <div className="content__block">
        <SettingsDirectoryTabs />
        <Outlet />
      </div>
    </div>
  );
};
