import React, {
  FC,
  KeyboardEvent,
  memo,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import { useAppSelector, useAppDispatch } from '@hooks/redux';
import { useCompanyId } from '@hooks/useCompanyId';
import { useOutsideClickListener } from '@hooks/useOutsideClickListener';
import { ResourceFolder, ResourceFolderId } from '@store/resourceFolders/slice';
import {
  deleteResourceFolderRequest,
  renameResourceFolderRequest,
} from '@store/resourceFolders/actions';
import { EntityStatus } from '@store/constants';
import {
  getFolder,
  getFolderResourcesCount,
} from '@store/resourceFolders/selectors';
import { confirm } from '@utils/notifications';
import { ReactComponent as FolderIcon } from '@static/img/icon-folder.svg';
import { ResourcesCatalogFolderModal } from './FolderModal';
import './styles.sass';

type ResourcesCatalogFolderProps = {
  folder: ResourceFolder;
  onClick?: (id: ResourceFolderId) => void;
};

const ResourcesCatalogFolderRaw: FC<ResourcesCatalogFolderProps> = ({
  folder,
  onClick,
}) => {
  const tileRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const fetching = folder.status === EntityStatus.Fetching;

  const [edit, setEdit] = useState(false);
  const currentFolder = useAppSelector((state) => getFolder(state, folder.id));
  const resourceAmount = useAppSelector((state) =>
    getFolderResourcesCount(state, folder.id)
  );
  const companyId = useCompanyId();

  const handleClick = useCallback(() => {
    if (edit || fetching) {
      return;
    }

    onClick?.(folder.id);
  }, [edit, fetching, folder.id, onClick]);

  const handleEdit = useCallback(() => {
    reset();
    setEdit(true);
  }, [reset]);

  const handleDelete = useCallback(async () => {
    if (companyId === undefined) {
      return;
    }

    const confirmed = await confirm(
      t('confirm.attention'),
      t('confirm.delete-folder')
    );

    if (!confirmed) {
      return;
    }

    dispatch(deleteResourceFolderRequest({ companyId, id: folder.id }));
  }, [companyId, folder.id, dispatch, t]);

  const submit = handleSubmit(({ value }) => {
    if (companyId === undefined) {
      return;
    }

    const name = value.trim();

    dispatch(
      renameResourceFolderRequest({
        companyId,
        id: folder.id,
        data: {
          ...currentFolder,
          name,
        },
      })
    );

    setEdit(false);
  });
  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        submit();
      }
      if (e.key === 'Escape') {
        setEdit(false);
        reset();
      }
    },
    [submit, reset]
  );
  const handleOutsideClick = useCallback(() => {
    setEdit(false);
  }, []);

  useOutsideClickListener(tileRef, handleOutsideClick);

  const inputErrors = () => {
    switch (errors.value?.type) {
      case 'required':
        return <small>{t('auth.validation-empty')}</small>;
      default:
        return null;
    }
  };
  const error = !!inputErrors();

  return (
    <div
      ref={tileRef}
      className={cn('manager__folder input', { edit, fetching, error })}
      onClick={handleClick}
    >
      {edit && (
        <>
          <input
            type="text"
            autoFocus
            maxLength={50}
            defaultValue={currentFolder?.name}
            onKeyUp={handleKeyPress}
            {...register('value', { required: true })}
          />
          {inputErrors()}
        </>
      )}
      <h4>{folder.name}</h4>
      <p>
        <FolderIcon />
        {t('directory.resources_count', { count: resourceAmount })}
      </p>
      {!fetching && (
        <ResourcesCatalogFolderModal
          onRename={handleEdit}
          onDelete={handleDelete}
        />
      )}
    </div>
  );
};

export const ResourcesCatalogFolder = memo(ResourcesCatalogFolderRaw);
