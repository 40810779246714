import { RootState } from '@store';
import { FetchStatus } from '@store/constants';

export const getRootState = (state: RootState) => state.company;
export const getStatus = (state: RootState) => getRootState(state).status;
export const getCompany = (state: RootState) => getRootState(state).companyData;
export const getCompanySaveStatus = (state: RootState) =>
  getRootState(state).saveCompanyStatus;

export const getCompanyId = (state: RootState) =>
  getRootState(state).companyData?.id;
// TODO: change any
export const getCompanyDataForGenerateCp = (state: RootState) => {
  const company = getRootState(state).companyData;

  if (company !== null) {
    const {
      name,
      phone,
      address,
      email,
      projectsAmount,
      marketExperience,
      employeesAmount,
      description,
    }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any = getRootState(state).companyData;

    const isValid =
      name &&
      phone &&
      address &&
      email &&
      projectsAmount &&
      marketExperience &&
      employeesAmount &&
      description;

    return !!isValid;
  }
};

export const getCompanyCurrency = (state: RootState) =>
  getRootState(state).companyData?.currency;

export const getCompanyCurrencyCode = (state: RootState) =>
  getCompanyCurrency(state)?.code;

export const getCompanyName = (state: RootState) =>
  getRootState(state).companyData?.name;

export const getDeleteCompanyStatus = (state: RootState) =>
  getRootState(state).deleteCompanyStatus;

export const isCompanyNotFetched = (state: RootState) =>
  getStatus(state) === FetchStatus.NotFetched;
export const isCompanyFetching = (state: RootState) =>
  getStatus(state) === FetchStatus.Fetching;
export const isCompanyFetched = (state: RootState) =>
  getStatus(state) === FetchStatus.Fetched;
