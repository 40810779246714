import { call } from 'redux-saga/effects';
import { ENDPOINTS } from '@config';
import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
} from '@sagas/api';
import { CompanyId } from '@store/company/slice';
import {
  OperationFolder,
  OperationFolderId,
} from '@store/operationFolders/slice';
import { OperationId } from '@store/operations/slice';
import { urlFromTemplate } from '@utils/url';

export interface CreateOperationFolderParams {
  name: string;
}

export interface RenameOperationFolderParams {
  name: string;
}

export function* getOperationFolders(companyId: CompanyId) {
  const { data } = yield call(
    getRequest,
    urlFromTemplate(ENDPOINTS.OPERATIONS.FOLDERS.GET, { companyId })
  );

  return data as OperationFolder[];
}

export function* createOperationFolder(
  companyId: CompanyId,
  params: CreateOperationFolderParams
) {
  const { data } = yield call(
    postRequest,
    urlFromTemplate(ENDPOINTS.OPERATIONS.FOLDERS.CREATE, { companyId }),
    params
  );

  return data as OperationFolder;
}

export function* renameOperationFolder(
  companyId: CompanyId,
  id: OperationFolderId,
  params: RenameOperationFolderParams
) {
  yield call(
    patchRequest,
    urlFromTemplate(ENDPOINTS.OPERATIONS.FOLDERS.RENAME, { companyId, id }),
    params
  );
}

export function* deleteOperationFolder(companyId: CompanyId, id: OperationId) {
  yield call(
    deleteRequest,
    urlFromTemplate(ENDPOINTS.OPERATIONS.FOLDERS.DELETE, { companyId, id })
  );
}
