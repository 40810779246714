import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchStatus } from '@store/constants';

export type AccountState = {
  statusResortProfile: FetchStatus;
  statusSavePassword: FetchStatus;
};

const initialState: AccountState = {
  statusResortProfile: FetchStatus.NotFetched,
  statusSavePassword: FetchStatus.NotFetched,
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setStatusResotrPassword: (state, action: PayloadAction<FetchStatus>) => {
      state.statusResortProfile = action.payload;
    },
    setStatusSaveNewPassword: (state, action: PayloadAction<FetchStatus>) => {
      state.statusSavePassword = action.payload;
    },
  },
});

export default accountSlice.reducer;
