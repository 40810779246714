import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchStatus } from '@store/constants';
import { refreshTokenError, signOutSuccess } from '@store/user/actions';
import { CityId, CountryId, Currency } from '@store/vocabulary/slice';
import {
  FetchCompanyRequestAction,
  FetchCompanySuccessAction,
  SaveCompanySuccessAction,
} from '@store/company/actions';

// TODO: move to separate store
export type UserId = string;

export type CompanyId = string;

export type CompanyData = {
  id: CompanyId;
  ownerId: UserId;
  name: string;
  email: string;
  phone: string;
  website: string;
  countryId: CountryId | null;
  cityId: CityId | null;
  address: string;
  description: string;
  employeesAmount: string;
  marketExperience: string;
  projectsAmount: string;
  currency: Currency;
  bankAccount: string;
  correspondentAccount: string;
  bic: string;
  bankName: string;
  filePath: string;
};

export type CompanyState = {
  status: FetchStatus;
  saveCompanyStatus: boolean;
  companyData: CompanyData | null;
  deleteCompanyStatus: FetchStatus;
};

const initialState: CompanyState = {
  status: FetchStatus.NotFetched,
  saveCompanyStatus: false,
  companyData: null,
  deleteCompanyStatus: FetchStatus.NotFetched,
};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setDeleteCompanyStatus: (state, action: PayloadAction<FetchStatus>) => {
      state.deleteCompanyStatus = action.payload;
    },

    setImageProfileCompany: (
      state,
      action: PayloadAction<Pick<CompanyData, 'filePath'>>
    ) => {
      if (state.companyData)
        state.companyData.filePath = action.payload.filePath;
    },
    setSaveCompanyStatus: (state, action: PayloadAction<boolean>) => {
      state.saveCompanyStatus = action.payload;
    },
    fetchCompanyRequest: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<FetchCompanyRequestAction>
    ) => {
      state.status = FetchStatus.Fetching;
    },
    fetchCompanySuccess: (
      state,
      action: PayloadAction<FetchCompanySuccessAction>
    ) => {
      const { data } = action.payload;

      state.status = FetchStatus.Fetched;
      state.companyData = {
        ...data,
      };
    },
    saveCompanySuccess: (
      state,
      { payload }: PayloadAction<SaveCompanySuccessAction>
    ) => {
      const { data } = payload;

      state.companyData = data;
      state.saveCompanyStatus = true;
    },
    deleteCompanySuccess: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(signOutSuccess, () => initialState);
    builder.addCase(refreshTokenError, () => initialState);
  },
});

export default companySlice.reducer;
