import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MainLayout } from '@layouts/MainLayout';
import { CompanyObjectId } from '@store/companyObjects/slice';
import { getProfileCompanyId } from '@store/profile/selectors';
import { fetchCompanyRequest } from '@store/company/actions';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { getCompanyCurrencyCode } from '@store/company/selectors';
import {
  archiveObjectRequest,
  fetchCompanyObjectsRequest,
} from '@store/companyObjects/actions';
import { Toggle } from '@components/Toggle';
import { Modal, ModalSize } from '@components/Modal';
import { CreateButton } from '@components/Header/CreateButton';
import { ConfirmationModal } from '@components/ConfirmationModal';
import { isViewObjectFunnel } from '@store/companyObjects/selectors';
import { Dashboard } from './Dashboard';
import { ObjectModal } from './Dashboard/ObjectModal';
import { CreateObjectForm } from './Dashboard/ObjectModal/CreateObjectForm';
import { Table } from './Dashboard/table';

export const DashboardPage = () => {
  const dispatch = useAppDispatch();
  const companyId = useAppSelector(getProfileCompanyId);
  const companyCurrencyCode = useAppSelector(getCompanyCurrencyCode);
  const typeView = useAppSelector(isViewObjectFunnel);
  const [isVisibleCreateModal, setVisibleCreateModal] = useState(false);

  const [isVisibleArchiveModal, setVisibleArchiveModal] = useState(false);
  const [currentObjectId, setCurrentObjectId] = useState('');

  const { t } = useTranslation();

  const handleCreateButtonClick = () => {
    setVisibleCreateModal(true);
  };
  const handleTaskInfoClick = (id: CompanyObjectId) => {
    setCurrentObjectId(id);
  };

  const handleClickConfirmArchiveButton = () => {
    if (companyId) {
      dispatch(archiveObjectRequest({ companyId, id: currentObjectId }));
    }
  };

  const handleClickCancelArchiveButton = () => {
    setVisibleArchiveModal(false);
  };

  useEffect(() => {
    if (companyId !== undefined) {
      dispatch(fetchCompanyRequest({ id: companyId }));
      dispatch(fetchCompanyObjectsRequest({ id: companyId }));
    }
  }, [dispatch, companyId]);

  if (!companyId || !companyCurrencyCode) {
    return null;
  }

  return (
    <>
      <Modal
        visible={isVisibleCreateModal}
        onBackgroundClick={() => setVisibleCreateModal(false)}
        size={ModalSize.Auto}
      >
        <ObjectModal onClose={() => setVisibleCreateModal(false)}>
          <>
            <CreateObjectForm
              companyId={companyId}
              companyCurrencyCode={companyCurrencyCode}
            />
          </>
        </ObjectModal>
      </Modal>
      <ConfirmationModal
        successTitle={t('confirm.archive-success')}
        confirmTitle={t('confirm.archive-confirm')}
        isVisibleModal={isVisibleArchiveModal}
        onCancel={handleClickCancelArchiveButton}
        onConfirm={handleClickConfirmArchiveButton}
      />
      <MainLayout
        title={t('object.construction')}
        buttons={
          <CreateButton
            onClick={handleCreateButtonClick}
            title={t('object.createObject')}
          />
        }
        toggle={<Toggle />}
      >
        {typeView ? (
          <Dashboard
            onClick={handleTaskInfoClick}
            companyCurrencyCode={companyCurrencyCode}
            handleCreateButtonClick={handleCreateButtonClick}
          />
        ) : (
          <Table />
        )}
      </MainLayout>
    </>
  );
};
