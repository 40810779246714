import React, {
  FC,
  memo,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import cn from 'classnames';
import { RESOURCE_TYPES } from '@constants/resource';
import { useOutsideClickListener } from '@hooks/useOutsideClickListener';
import { ResourceType } from '@store/resources/slice';
import './styles.sass';
import { useTranslation } from 'react-i18next';

type EstimateTypeSelectProps = {
  editable: boolean;
  value: ResourceType;
  onSelected: (value: ResourceType) => void;
  onFocus: () => void;
  onBlur: () => void;
  register: UseFormRegisterReturn;
  className?: string;
  disabled?: boolean;
};

const EstimateTypeSelectRaw: FC<EstimateTypeSelectProps> = ({
  editable,
  value,
  onSelected,
  onFocus,
  onBlur,
  register,
  className,
  disabled,
}) => {
  const { t } = useTranslation();
  const divRef = useRef<HTMLButtonElement>(null);
  const [selectedType, setSelectedType] = useState(value);
  const [focusedInput, setFocusedInput] = useState(false);

  const handleClick = () => {
    if (focusedInput) {
      setFocusedInput(false);
      onBlur();
    } else {
      setFocusedInput(true);
      onFocus();
    }
  };
  const handleBlur = () => {
    setFocusedInput(false);
    onBlur();
  };
  const handleSelect = (type: ResourceType) => (e: SyntheticEvent) => {
    e.stopPropagation();
    onSelected(type);
    handleBlur();
    setSelectedType(type);
  };
  const handleOutsideClick = () => {
    if (focusedInput) {
      handleBlur();
    }
  };

  useOutsideClickListener(divRef, handleOutsideClick);

  useEffect(() => {
    setSelectedType(value);
  }, [value, editable]);

  return (
    <button
      ref={divRef}
      className={className || 'manager__resource-group'}
      disabled={disabled}
      onClick={handleClick}
    >
      <input type="hidden" {...register} />
      <div className={cn('sel', { index: focusedInput, open: focusedInput })}>
        {/* <a> */}
        <s className={RESOURCE_TYPES[selectedType].className}>
          {t(RESOURCE_TYPES[selectedType].title)}
        </s>
        {/* </a> */}
        <ul>
          {Object.values(ResourceType).map((type) => (
            <li key={type} onClick={handleSelect(type)}>
              <s className={RESOURCE_TYPES[type].className}>
                {t(RESOURCE_TYPES[type].title)}
              </s>{' '}
              {t(RESOURCE_TYPES[type].description)}
            </li>
          ))}
        </ul>
      </div>
    </button>
  );
};

export const EstimateTypeSelect = memo(EstimateTypeSelectRaw);
