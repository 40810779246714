import { OrderStatus, StatusClasses } from '@utils/enums';

type PhaseClass = string;

export const AVAILABLE_STATUSES: Record<OrderStatus, PhaseClass[]> = {
  [OrderStatus.ACCEPTED]: [StatusClasses.accepted],
  [OrderStatus.PAYMENT]: [StatusClasses.accepted, StatusClasses.payment],
  [OrderStatus.PAID]: [
    StatusClasses.accepted,
    StatusClasses.payment,
    StatusClasses.paid,
  ],
  [OrderStatus.PRODUCTION]: [
    StatusClasses.accepted,
    StatusClasses.payment,
    StatusClasses.paid,
    StatusClasses.production,
  ],
  [OrderStatus.READY]: [
    StatusClasses.accepted,
    StatusClasses.payment,
    StatusClasses.paid,
    StatusClasses.production,
    StatusClasses.ready,
  ],
  [OrderStatus.ISSUED]: [
    StatusClasses.accepted,
    StatusClasses.payment,
    StatusClasses.paid,
    StatusClasses.production,
    StatusClasses.ready,
    StatusClasses.issued,
  ],
};
