import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { setViewObject } from '@store/companyObjects/actions';
import { isViewObjectFunnel } from '@store/companyObjects/selectors';
import { useTranslation } from 'react-i18next';

export const Toggle = () => {
  const typeView = useAppSelector(isViewObjectFunnel);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleClickToggle = () => {
    dispatch(setViewObject(!typeView));
  };

  return (
    <div className="cap__but-switch">
      <div className="switch">
        <input
          type="checkbox"
          name="object"
          checked={typeView}
          onClick={handleClickToggle}
          readOnly
        />
        <div>
          <p>{t('navigation.table')}</p>
          <label />
          <p>{t('navigation.funnel')}</p>
        </div>
      </div>
    </div>
  );
};
