import { RootState } from '@store';
import { FetchStatus } from '@store/constants';
import { OperationFolderId } from '@store/operationFolders/slice';

export const getRootState = (state: RootState) => state.operationFolders;
export const getStatus = (state: RootState) => getRootState(state).status;
export const getOperationFolders = (state: RootState) =>
  getRootState(state).items;

export const isNotFetched = (state: RootState) =>
  getStatus(state) === FetchStatus.NotFetched;
export const isFetching = (state: RootState) =>
  getStatus(state) === FetchStatus.Fetching;
export const isFetched = (state: RootState) =>
  getStatus(state) === FetchStatus.Fetched;

export const getFolder = (
  state: RootState,
  id: OperationFolderId | undefined
) => {
  if (id === undefined) {
    return undefined;
  }

  return getOperationFolders(state).find((folder) => folder.id === id);
};
