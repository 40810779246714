import { useTranslation } from 'react-i18next';
import newAddIcon from '@static/img/new-add.gif';

export const SelectedChat = () => {
  const { t } = useTranslation();
  return (
    <div className="content__base">
      <div className="obj">
        <div className="outlay__add">
          <i>
            <img src={newAddIcon} alt="" />
          </i>
          <h6>{t('chat.selected-chat')}</h6>
          <p>{t('chat.selected-chat-attacment')}</p>
        </div>
      </div>
    </div>
  );
};
