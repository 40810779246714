export const ENDPOINTS = {
  AUTH: {
    LOGIN: '/login',
    REFRESH: '/refresh',
    LOGOUT: '/logout',
    REGISTER: '/registration',
    CONFIRM: '/registration/confirm',
  },
  CHAT: {
    CREATECHATBYREQUEST:
      '/conversation/request/{requestId}/supplier/{supplierId}',
    GETCHATBYREQUEST: '/conversation/request/{requestId}/supplier/{supplierId}',
    CREATECHATBYORDER: '/conversation/order/{orderId}',
    GETCHATBYORDER: '/conversation/order/{orderId}',
    CHATS: '/conversations',
    SENDMESSAGE: '/conversation/{conversationId}/message',
    GETMESSAGES: '/conversation/{conversationId}/messages',
    ISCONVERSATIONREQUEST:
      '/conversation/request/{requestId}/supplier/{supplierId}/check',
    ISCONVERSOTIONBYORDER: '/conversation/order/{orderId}/check',
    UNREAD: '/conversations/unread',
    NEW: '/messages/after/{messageId}',
    ACCESSTOCONVERSATION: '/conversation/{conversationId}/check',
    GETCONVERSATION: '/conversation/{conversationId}',
  },
  COMPANY: {
    GET: '/company/{id}',
    SAVE: '/company/{id}',
    DELETE: '/company/{id}',
  },
  SUPPLIER: {
    GET: '/supplier/{id}',
    SAVE: '/supplier/{id}',
    DELETE: '/supplier/{id}',
    CATEGORY: {
      UPDATE: '/supplier/{id}/categories',
    },
    REQUEST: {
      GET: '/requests/{status}',
      GETBYID: 'request/{requestId}/{status}',
    },
    RESPONSES: {
      CREATE: '/request/{requestId}/responses',
    },
    ORDER: {
      GETBYSTATUS: '/orders/{status}',
      UPDATESTATUS: 'order/{orderId}/{status}',
      ORDER: '/order/{orderId}',
      GETITEMS: '/order/{orderId}/items',
      DELETEITEM: '/order/item/{orderItemId}',
      UPDATEVALIDITYINVOICE: '/order/{orderId}/invoice/validity',
    },
  },
  COMPANY_OBJECTS: {
    OBJECTS: {
      GETLIST: `/company/{id}/building-objects`,
      GET: '/company/{companyId}/building-object/{id}',
      CREATE: '/company/{id}/building-object',
      UPDATE: '/company/{companyId}/building-object/{id}',
      ARCHIVE: '/company/{companyId}/building-object/{id}/archive',
      UPDATESTAUS: '/company/{companyId}/building-object/{id}/status/',
      MOVE: '/company/{companyId}/building-object/move/',
    },
    COST_ESTIMATES: {
      GET_ALL: '/company/{companyId}/building-object/{id}/cost-estimates',
      GET: '/company/{companyId}/building-object/{objectId}/cost-estimate/{id}',
      CREATE: '/company/{companyId}/building-object/{id}/cost-estimate',
      COPY: '/company/{companyId}/building-object/{objectId}/cost-estimate/{id}',

      DELETE:
        '/company/{companyId}/building-object/{objectId}/cost-estimate/{id}',
      MOVEITEMS:
        '/company/{companyId}/building-object/{objectId}/cost-estimate/{costEstimateId}/move',

      COMMERCIAL_PROPOSAL: {
        GET: `/company/{companyId}/building-object/{objectId}/cost-estimate/{id}/commercial-proposal`,
        CREATE:
          '/company/{companyId}/building-object/{objectId}/cost-estimate/{id}/commercial-proposal',
        DELETE:
          '/company/{companyId}/building-object/{objectId}/cost-estimate/{id}/commercial-proposal',
        UPDATE:
          '/company/{companyId}/building-object/{objectId}/cost-estimate/{id}/commercial-proposal',
        GET_CP:
          '/company/{companyId}/building-object/{objectId}/cost-estimate/{costEstimateId}/presentation',
      },
    },
    COST_ESTIMATE_SECTION: {
      GET_SECTION: '/section/{sectionId}',
      GET: '/company/{companyId}/building-object/{objectId}/cost-estimate/{costEstimateId}/sections',
      CREATE:
        '/company/{companyId}/building-object/{objectId}/cost-estimate/{costEstimateId}/section',
      UPDATE:
        '/company/{companyId}/building-object/{objectId}/cost-estimate/{costEstimateId}/section/{id}',
      DELETE:
        '/company/{companyId}/building-object/{objectId}/cost-estimate/{costEstimateId}/section/{id}',
    },
    COST_ESTIMATE_OPERATIONS: {
      GET_OPERATION: '/operation/{operationId}',
      GET: '/company/{companyId}/building-object/{objectId}/cost-estimate/{costEstimateId}/operations',
      CREATE:
        '/company/{companyId}/building-object/{objectId}/cost-estimate/{costEstimateId}/operation',
      ADD: '/company/{companyId}/building-object/{objectId}/cost-estimate/{costEstimateId}/operations',
      UPDATE:
        '/company/{companyId}/building-object/{objectId}/cost-estimate/{costEstimateId}/operation/{operationId}',
      DELETE:
        '/company/{companyId}/building-object/{objectId}/cost-estimate/{costEstimateId}/operations',
    },
    COST_ESTIMATE_RESOURCES: {
      GET_RESOURCE: '/resource/{resourceId}',
      GET: '/company/{companyId}/building-object/{objectId}/cost-estimate/{costEstimateId}/resources',
      CREATE:
        '/company/{companyId}/building-object/{objectId}/cost-estimate/{costEstimateId}/resource',
      UPDATE:
        '/company/{companyId}/building-object/{objectId}/cost-estimate/{costEstimateId}/resource/{id}',
      DELETE:
        '/company/{companyId}/building-object/{objectId}/cost-estimate/{costEstimateId}/resource',
    },
  },
  ORGANIZATION: {
    POST: '/company/{id}/organization',
    PUT: '/company/{companyId}/organization/{id}',
    DELETE: '/company/{companyId}/organization/{id}',
    GET: '/company/{id}/organizations',
    GET_FULL: '/company/{id}/organizations-with-contacts',
  },

  CONTACT: {
    CREATE: '/company/{id}/contact',
    UPDATE: '/company/{companyId}/contact/{id}',
    DELETE: '/company/{companyId}/contact/{id}',
    GET: '/company/{id}/contacts',
    GET_FULL: '/company/{id}/contacts-with-organizations',
  },
  DOCUMENTS: {
    UPDATE: '/document/{id}',
    DELETE: '/document/{id}',
  },
  REQUEST: {
    CREATE: '/cost-estimate/{id}/request',
    CREATE_ORDER: '/request/{requestId}/order',
    GET: '/request/{id}',
    SELF_DELIVERY: '/resource/{resourceId}/request/{requestId}/self',
    DELETE_ITEM: '/resource/{resourceId}/request/{requestId}',
  },
  ORDER_CUSTOMER: {
    GET: '/cost-estimate/{costEstimateId}/orders',
    GET_ITEMS: '/order/{orderId}/items',
    UPDATE_STATUS: 'order/{orderId}/{status}',
    DELETE_ITEM: '/order/item/{orderitemId}',
    DELETE_PROXY: '/order/document/{orderDocumentId}',
    REQUEST_AN_INVOICE: 'order/{orderId}/NEED_INVOICE',
  },
  RESPONSE_SUPPLIER: {
    GET_LIST_BY_RESOURCE: '/resource/{resourceId}/responses',
    SELECT_SUPPLIER: '/resource/{resourceId}/response/{responseId}',
  },
  PROFILE: {
    GET: '/user',
    UPDATE: '/user',
    RESTORE: '/user',
    RESET: '/password-reset',
    UPDATEPASSWORD: '/user/password',
  },
  RESOURCES: {
    FOLDERS: {
      GET: '/company/{companyId}/resource-folders',
      CREATE: '/company/{companyId}/resource-folder',
      RENAME: '/company/{companyId}/resource-folder/{id}',
      DELETE: '/company/{companyId}/resource-folder/{id}',
    },
    ITEMS: {
      GET: '/company/{companyId}/resources',
      CREATE: '/company/{companyId}/resource',
      UPDATE: '/company/{companyId}/resource/{id}',
      DELETE: '/company/{companyId}/resources',
    },
  },
  OPERATIONS: {
    FOLDERS: {
      GET: '/company/{companyId}/operation-folders',
      CREATE: '/company/{companyId}/operation-folder',
      RENAME: '/company/{companyId}/operation-folder/{id}',
      DELETE: '/company/{companyId}/operation-folder/{id}',
    },
    ITEMS: {
      GET: '/company/{companyId}/operations',
      CREATE: '/company/{companyId}/operation',
      UPDATE: '/company/{companyId}/operation/{id}',
      DELETE: '/company/{companyId}/operations',
    },
    RESOURCES: {
      ADD: '/company/{companyId}/operation/{id}',
      UPDATE: '/company/{companyId}/operation/{id}',
    },
  },
  VOCABULARY: {
    GET: '/vocabulary',
    GET_SEARCH_CATEGORY: '/category/search/{request}',
  },
};
