import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@hooks/redux';

import { currencyFormat } from '@utils/currency';
import { useCurrentLocale } from '@hooks/useCurrentLocale';
import { getCompanyCurrency } from '@store/company/selectors';
import { nanoid } from '@reduxjs/toolkit';

type OfferGeneratorItemProps = {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: any; // TODO: change type
  count: number;
};

// TODO: Change index key
const OfferGeneratorItemRaw: FC<OfferGeneratorItemProps> = ({
  name,
  items,
  count,
}) => {
  const { t } = useTranslation();

  const locale = useCurrentLocale();
  // const currentCostEstimate = useAppSelector(getCurrentCostEstimate);
  const currencyCode = useAppSelector(getCompanyCurrency)?.code;

  const itemsArray = [
    { title: t('about-object.works'), saleCost: items.HUM.saleCost },
    { title: t('about-object.materials'), saleCost: items.MAT.saleCost },
    {
      title: `${t('about-object.meh-short')}., ${t(
        'about-object.overhead-short-in-proposal'
      )}`,
      saleCost: items.MEC.saleCost + items.EXT.saleCost,
    },
    {
      title: t('about-object.section-total'),
      saleCost:
        items.HUM.saleCost +
        items.MAT.saleCost +
        items.MEC.saleCost +
        items.EXT.saleCost,
    },
  ];

  return (
    <div className="gener__outlay-item">
      <small>{t('about-object.section-right', { count })}</small>
      <h4>{name}</h4>
      <ul>
        {itemsArray.map((item) => {
          return (
            <li key={nanoid()}>
              <p>{item.title}</p>
              <span>
                {currencyCode !== undefined
                  ? currencyFormat(item.saleCost, currencyCode, locale)
                  : item.saleCost}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export const OfferGeneratorItem = memo(OfferGeneratorItemRaw);
