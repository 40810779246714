import React, { useState } from 'react';
import { Modal, ModalSize } from '@components/Modal';
import { Modal as CenterModal } from '@components/AboutObjectModal';
import { EditProfileModal } from '@components/Profile/EditProfileModal';
// import profile1 from '@static/img/profile1.jpg';
import profileIcon from '@static/img/profile3.svg';
import { ReactComponent as IconTehnicalSupport } from '@static/img/phone.svg';
import Image from '@components/commons/Image';
import { useTranslation } from 'react-i18next';
import { TypeSize, setSizeImage } from '@utils/formatImage';
import { useAppSelector } from '@hooks/redux';
import { getProfile } from '@store/profile/selectors';
import IconButton from '@components/commons/IconButton';
import cn from 'classnames';
import styles from './styles.module.sass';

type ProfileProps = {
  name: string;
  // positionInCompany: string;
};

// TODO: Delete styles
export const Profile: React.FC<ProfileProps> = ({
  name,
  // positionInCompany,
}) => {
  const { t } = useTranslation();
  const userLogo = useAppSelector(getProfile);

  const [visibleModal, setVisibleModal] = useState(false);
  const openModal = () => setVisibleModal(true);
  const closeModal = () => setVisibleModal(false);

  const [isVisibleTechnicalSupportModal, setIsVisibleTechnicalSupportModal] =
    useState(false);
  const openTechnicalSupportModal = () =>
    setIsVisibleTechnicalSupportModal(true);
  const closeTechnicalSupportModal = () =>
    setIsVisibleTechnicalSupportModal(false);

  return (
    <>
      <div className={cn('menu__profile', styles.menuProfile)}>
        <IconButton
          className={styles.menuProfileTechnicalPhoneButton}
          aria-label={t('profile.contact-technical-support') || ''}
          data-balloon-pos="right"
          onClick={openTechnicalSupportModal}
        >
          <a className={styles.text}>
            <IconTehnicalSupport />
          </a>
        </IconButton>
        <IconButton
          onClick={openModal}
          className={styles.menuProfileButtonImage}
        >
          <a className={styles.text}>
            {userLogo?.filePath ? (
              <Image
                url={setSizeImage(userLogo?.filePath, TypeSize.secondAvatar)}
                className={styles.menuProfileButtonImage}
              />
            ) : (
              <>
                <img src={profileIcon} className={styles.profile} alt={name} />
                <h4>{name}</h4>
                {/* <b>{positionInCompany}</b> */}
              </>
            )}
          </a>
        </IconButton>
      </div>
      <Modal
        visible={visibleModal}
        onBackgroundClick={closeModal}
        size={ModalSize.Auto}
      >
        <EditProfileModal />
      </Modal>
      <CenterModal
        visible={isVisibleTechnicalSupportModal}
        isCenter
        onBackgroundClick={closeTechnicalSupportModal}
      >
        <div className="modal__min">
          <div className="modal__close" onClick={closeTechnicalSupportModal} />
          <h3>{t('profile.technical-support-number')}:</h3>
          <a href="tel:88001002901">8 (800) 100-29-01</a>
        </div>
      </CenterModal>
    </>
  );
};
