import { ErrorMessage } from '@components/ErrorMessage';
import { useAppSelector } from '@hooks/redux';
import { getErrorMessages } from '@store/errors/selectors';

export default function MessageQueue() {
  const errorMessages = useAppSelector(getErrorMessages);
  return (
    <>
      {errorMessages.length > 0 &&
        errorMessages.map((e, i) => {
          return (
            <ErrorMessage
              key={e.id}
              id={e.id}
              message={e.message}
              idx={i}
              type={e.type}
            />
          );
        })}
    </>
  );
}
