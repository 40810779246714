import classNames from 'classnames';
import { RegisterOptions, UseFormRegisterReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconOpen } from '@static/img/icon-open.svg';
import { useState } from 'react';

interface Input {
  register: (name?: string, options?: RegisterOptions) => UseFormRegisterReturn;
  errorInput?: () => string | null;
  placeholder?: string;
  type?: string;
}
function Field({ register, errorInput, placeholder, type = 'text' }: Input) {
  const { t } = useTranslation();

  const [isHideInput, setIsHideInput] = useState(false);

  let errorField = errorInput ? errorInput() : null;

  if (typeof errorField === 'string') errorField = t(errorField);

  let label = '';

  if (typeof placeholder === 'string') label = t(placeholder);

  const handleClickResetType = () => {
    setIsHideInput(!isHideInput);
  };

  return (
    <div
      className={classNames('input open', {
        error: errorField,
        password: isHideInput,
      })}
    >
      <input
        type={!isHideInput ? type : 'text'}
        placeholder="0"
        {...register()}
      />
      <label>{label}</label>
      {type === 'password' && (
        <i>
          <IconOpen onClick={handleClickResetType} />
        </i>
      )}

      {errorField && <small>{errorField}</small>}
    </div>
  );
}
export default Field;
