import { useDispatch } from 'react-redux';
import { useFormErrors } from '@hooks/useFormErrors';
import { Input } from '@pages/Dashboard/ObjectModal/CreateObjectForm/Input';
import { EMAIL_PATTERN } from '@constants/patterns';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ObjectButtonBlock from '@components/ObjectButtonBlock';
import {
  createOrganizationRequest,
  resetCreateOrganizationStatus,
} from '@store/organizations/actions';
import { ReactComponent as IconHuman } from '@static/img/human__ObjectButton.svg';
import { ReactComponent as IconAdd } from '@static/img/add__ObjectButton.svg';
import ResponsibleSelect from '@components/ResponsibleSelect';
import { getCompany } from '@store/company/selectors';
import { useAppSelector } from '@hooks/redux';
import { getCreateOrganizationStatus } from '@store/organizations/selectors';
import { phoneFormat } from '@utils/phoneFormat';
import {
  ChangeEvent,
  FormEventHandler,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react';
import { FetchStatus } from '@store/constants';
import Button from '@components/commons/Button';
import { SecondaryButton } from '@components/commons/SecondaryButton';
import { Contact, ContactId } from '@store/contacts/slice';
import ChoisedContact from '../ChoisedContact';
import { emailErrors, inputErrors } from '../utils';

type CreateOrganizationFormData = {
  name: string;
  organizationForm?: string;
  phone: string;
  email: string;
  website?: string;
  responsible: string;
  contactId?: ContactId;
};

type CreateOrganizationFormProps = {
  onClose: () => void;
};
const CreateOrganizationForm = ({ onClose }: CreateOrganizationFormProps) => {
  const companyId = useAppSelector(getCompany)?.id;
  const statusOrganization = useAppSelector(getCreateOrganizationStatus);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedContact, setSelectedContact] = useState<Contact | undefined>(
    undefined
  );

  const {
    register,
    trigger,
    getValues,
    setValue,
    watch,
    formState: { errors: formErrors },
  } = useForm<CreateOrganizationFormData>({ mode: 'onBlur' });
  const errors = useFormErrors(formErrors);

  const handleContactChange = (value: unknown) => {
    const typedValue = value as Contact | undefined;
    setValue('contactId', typedValue?.id || '');
    setSelectedContact(typedValue);
  };

  const handleCancelClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClose();
  };

  const handleChangePhone = (event: ChangeEvent<HTMLInputElement>) => {
    setValue('phone', phoneFormat(event.target.value));
  };

  const submit: SubmitHandler<CreateOrganizationFormData> = (data, e) => {
    e?.stopPropagation();
    if (companyId && statusOrganization === FetchStatus.NotFetched) {
      dispatch(
        createOrganizationRequest({
          companyId,
          data,
        })
      );
      onClose();
    }
  };

  const handleSubmitForm: FormEventHandler<HTMLFormElement> = async (e) => {
    e?.stopPropagation();
    e.preventDefault();
    const result = await trigger();
    if (result) {
      submit(getValues());
    }
  };

  useEffect(() => {
    if (statusOrganization === FetchStatus.Fetched) {
      setTimeout(() => {
        dispatch(resetCreateOrganizationStatus());
      }, 1000);
    }
  }, [dispatch, statusOrganization]);

  return (
    <form action="#" className="form" onSubmit={handleSubmitForm}>
      <div className="form__cap">
        <h4>{t('object.new-organization')}</h4>
      </div>
      <div className="form__group">
        <Input
          title={t('organization.company-name')}
          maxLength={35}
          type="text"
          {...register('name', {
            required: true,
          })}
          error={inputErrors('name', errors, t)}
        />
        <Input
          title={t('organization.form-organizations')}
          maxLength={35}
          type="text"
          {...register('organizationForm', {
            required: false,
          })}
        />
        <Input
          title={t('company.company-website')}
          maxLength={200}
          type="text"
          {...register('website', {
            required: false,
          })}
        />
        <Input
          title={t('auth.phone')}
          type="text"
          maxLength={100}
          {...register('phone', {
            required: true,
            onChange: handleChangePhone,
            minLength: 7,
          })}
          error={inputErrors('phone', errors, t)}
        />
        <Input
          {...register('email', {
            required: true,
            minLength: 3,
            pattern: EMAIL_PATTERN,
          })}
          maxLength={180}
          title={t('auth.email')}
          error={emailErrors(errors, t)}
        />
        <ResponsibleSelect
          onSelect={(id) => {
            setValue('responsible', id);
          }}
          selectedId={watch('responsible')}
        />
      </div>
      <div className="form__cap">
        <h5>{t('object.company-representatives')}</h5>
      </div>
      {selectedContact ? (
        <ChoisedContact
          firstName={selectedContact.firstName}
          lastName={selectedContact.lastName}
          email={selectedContact.email}
          phone={selectedContact.phone}
          onDelete={() => handleContactChange(undefined)}
        />
      ) : (
        <ObjectButtonBlock
          oneName={t('object.select-from-database')}
          secondName={t('object.create-new-one')}
          oneLogo={<IconHuman />}
          secondLogo={<IconAdd />}
          type="contact"
          onSelect={handleContactChange}
        />
      )}
      <div className="form__group">
        <Button
          name={t('object.create-an-organization')}
          status={statusOrganization}
        />
        <SecondaryButton onClick={handleCancelClick}>
          {t('object.cancel-the-selection')}
        </SecondaryButton>
      </div>
    </form>
  );
};

export default CreateOrganizationForm;
