import { createAction } from '@reduxjs/toolkit';
import {
  OperationFolderId,
  OperationFolder,
  operationFoldersSlice,
} from '@store/operationFolders/slice';
import { CompanyId } from '@store/company/slice';
import {
  CreateOperationFolderParams,
  RenameOperationFolderParams,
} from '@sagas/api/operationFolders';

export type FetchOperationFoldersRequestAction = {
  companyId: CompanyId;
};

export type FetchOperationFoldersSuccessAction = {
  companyId: CompanyId;
  data: OperationFolder[];
};

export type CreateOperationFolderRequestAction = {
  companyId: CompanyId;
  temporaryId: string;
  data: CreateOperationFolderParams;
};
export type CreateOperationFolderSuccessAction = {
  companyId: CompanyId;
  temporaryId: string;
  data: OperationFolder;
};
export type CreateOperationFolderErrorAction = {
  companyId: CompanyId;
  temporaryId: string;
};

export type RenameOperationFolderRequestAction = {
  companyId: CompanyId;
  id: OperationFolderId;
  data: RenameOperationFolderParams;
};

export type RenameOperationFolderSuccessAction = {
  companyId: CompanyId;
  id: OperationFolderId;
};

export type DeleteOperationFolderRequestAction = {
  companyId: CompanyId;
  id: OperationFolderId;
};

export type DeleteOperationFolderSuccessAction = {
  companyId: CompanyId;
  id: OperationFolderId;
};

export const renameOperationFolderSuccess =
  createAction<RenameOperationFolderSuccessAction>(
    'operationFolders/renameOperationFolderSuccess'
  );

export const deleteOperationFolderSuccess =
  createAction<DeleteOperationFolderSuccessAction>(
    'operationFolders/deleteOperationFolderSuccess'
  );

export const {
  fetchOperationFoldersRequest,
  fetchOperationFoldersSuccess,
  createOperationFolderRequest,
  createOperationFolderSuccess,
  createOperationFolderError,
  renameOperationFolderRequest,
  deleteOperationFolderRequest,
} = operationFoldersSlice.actions;
