import React, { FC, memo, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { EstimateContext } from '@components/Estimate';
import { EstimateMarkupSettings } from '@components/Estimate/List/MarkupSettings';
import { EstimateSection } from '@components/Estimate/List/Section';
import { EntityStatus } from '@store/constants';
import {
  getCostEstimateSectionsByCostEstimateId,
  getDeleteStatus,
} from '@store/costEstimateSections/selectors';
import {
  getCostEstimateOperations,
  getDeleteOperationStatus,
} from '@store/costEstimateOperations/selectors';
import addIcon from '@static/img/icon-add3.svg';
import {
  deleteCostEstimateSectionRequest,
  resetDeleteCostEstimateSectionStatus,
} from '@store/costEstimateSections/actions';
import { CostEstimateSectionId } from '@store/costEstimateSections/slice';
import { clearSelectedIds } from '@store/costEstimates/actions';
import { useSearchParams } from 'react-router-dom';
import { ConfirmationModal } from '@components/commons/ConfirmationModal';
import {
  deleteCostEstimateOperationRequest,
  resetDeleteCostEstimateOperationStatus,
} from '@store/costEstimateOperations/actions';
import { CostEstimateOperationId } from '@store/costEstimateOperations/slice';
import { getDeleteResourceStatus } from '@store/costEstimateResources/selectors';
import {
  deleteCostEstimateResourceRequest,
  resetDeleteResourceStatus,
} from '@store/costEstimateResources/actions';
import { CostEstimateResourceId } from '@store/costEstimateResources/slice';

const EstimateListRaw: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    companyId,
    objectId,
    costEstimateId,
    openOperation,
    isOpenedOperation,
  } = useContext(EstimateContext);

  const deleteSectionStatus = useAppSelector(getDeleteStatus);
  const deleteOperationStatus = useAppSelector(getDeleteOperationStatus);
  const deleteResourceStatus = useAppSelector(getDeleteResourceStatus);

  const [isVisibleDeleteSectionModal, setVisibleDeleteSectionModal] =
    useState(false);
  const [sectionId, setSectionId] = useState<CostEstimateSectionId>();

  const [isViewDeleteOperationModal, setIsViewDeleteOperationModal] =
    useState(false);
  const [operationId, setOperationId] = useState('');

  const [isViewDeleteResourceModal, setIsViewDeleteResourceModal] =
    useState(false);
  const [resourceId, setResourceId] = useState('');

  const [createSection, setCreateSection] = useState(false);
  const [activeMarkupSettings, setActiveMarkupSettings] = useState(false);

  const [searchParams] = useSearchParams();
  const costEstimateIdFromQuery = searchParams.get('costEstimateId');

  const sections = useAppSelector((state) =>
    getCostEstimateSectionsByCostEstimateId(
      state,
      costEstimateIdFromQuery || ''
    )
  );
  const operations = useAppSelector(getCostEstimateOperations);

  const allOperationsOpened = operations.every((operation) =>
    isOpenedOperation(operation.sectionId, operation.id)
  );

  const handleOpenAllOperations = () => {
    operations.forEach((operation) =>
      openOperation(operation.sectionId, operation.id, !allOperationsOpened)
    );
  };

  const handleAddSectionClick = () => {
    setCreateSection(true);
  };

  const handleAddSectionCancel = () => {
    setCreateSection(false);
  };

  const handleActiveMarkupSettingsClick = () => {
    setActiveMarkupSettings((prevState) => !prevState);
  };

  const handleActiveMarkupSettingsCancel = () => {
    setActiveMarkupSettings(false);
  };

  // ConfirmModal Section
  const handleClickCancelDeleteSectionButton = () => {
    setVisibleDeleteSectionModal(false);
  };

  const handleClickOpenSectionDeleteButton = (id: CostEstimateSectionId) => {
    dispatch(resetDeleteCostEstimateSectionStatus());
    setVisibleDeleteSectionModal(true);
    setSectionId(id);
  };

  const handleClickConfirmDeleteSectionButton = () => {
    if (costEstimateIdFromQuery && sectionId) {
      dispatch(
        deleteCostEstimateSectionRequest({
          companyId,
          objectId,
          costEstimateId: costEstimateIdFromQuery,
          id: sectionId,
        })
      );
    }
  };

  // ConfirmModal Operation
  const handleCloseDeleteOperationModal = () => {
    setIsViewDeleteOperationModal(false);
  };

  const handleOpenDeleteOperationModal = (id: CostEstimateOperationId) => {
    dispatch(resetDeleteCostEstimateOperationStatus());
    setIsViewDeleteOperationModal(true);
    setOperationId(id);
  };

  const handleRemoveOperationClick = () => {
    if (costEstimateIdFromQuery !== null) {
      dispatch(
        deleteCostEstimateOperationRequest({
          companyId,
          objectId,
          costEstimateId: costEstimateIdFromQuery,
          id: operationId,
        })
      );
    }
  };

  // ConfirmModal Resource
  const handleOpenDeleteResourceModal = (id: CostEstimateResourceId) => {
    dispatch(resetDeleteResourceStatus());
    setIsViewDeleteResourceModal(true);
    setResourceId(id);
  };

  const handleCloseDeleteResourceModal = () => {
    setIsViewDeleteResourceModal(false);
  };

  const handleRemoveResourceClick = () => {
    if (costEstimateIdFromQuery !== null) {
      dispatch(
        deleteCostEstimateResourceRequest({
          companyId,
          objectId,
          costEstimateId: costEstimateIdFromQuery,
          id: resourceId,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(clearSelectedIds());
  }, [dispatch]);

  return (
    <>
      <ConfirmationModal
        confirmTitle={t('confirm.delete-section-confirm')}
        isVisibleModal={isVisibleDeleteSectionModal}
        status={deleteSectionStatus}
        confirmButtonClassName="bg-danger"
        onCancel={handleClickCancelDeleteSectionButton}
        onConfirm={handleClickConfirmDeleteSectionButton}
      />
      <ConfirmationModal
        confirmTitle={t('confirm.delete-operation-confirm')}
        isVisibleModal={isViewDeleteOperationModal}
        status={deleteOperationStatus}
        confirmButtonClassName="bg-danger"
        onCancel={handleCloseDeleteOperationModal}
        onConfirm={handleRemoveOperationClick}
      />
      <ConfirmationModal
        confirmTitle={t('confirm.delete-resource-confirm')}
        isVisibleModal={isViewDeleteResourceModal}
        status={deleteResourceStatus}
        confirmButtonClassName="bg-danger"
        onCancel={handleCloseDeleteResourceModal}
        onConfirm={handleRemoveResourceClick}
      />
      <div className="outlay">
        <div className="outlay__cap">
          <div
            className="outlay__cap-slider"
            onClick={handleOpenAllOperations}
          />
          <div className="outlay__cap-name">{t('estimate.list-name')}</div>
          <div className="outlay__cap-cat">{t('supply.category')}</div>
          <div className="outlay__cap-count">{t('estimate.list-count')}</div>
          <div className="outlay__cap-unit">{t('estimate.list-unit')}</div>
          <div className="outlay__cap-price">{t('estimate.list-price')}</div>
          <div className="outlay__cap-cost">{t('estimate.list-cost')}</div>
          <div className="outlay__cap-dev">{t('estimate.delivery-time')}</div>
          <div
            className={cn('outlay__cap-markup', {
              active: activeMarkupSettings,
            })}
          >
            <EstimateMarkupSettings
              onClick={handleActiveMarkupSettingsClick}
              onCancel={handleActiveMarkupSettingsCancel}
            />
          </div>
          <div className="outlay__cap-price2">
            {t('estimate.list-salePrice')}
          </div>
          <div className="outlay__cap-cost2">{t('estimate.list-saleCost')}</div>
        </div>
        {sections
          ?.sort((a, b) => a.ordering - b.ordering)
          .map((section, index) => (
            <EstimateSection
              key={section.id}
              section={section}
              sectionOrdering={index + 1}
              onDeleteSection={handleClickOpenSectionDeleteButton}
              onDeleteOperation={handleOpenDeleteOperationModal}
              onDeleteResource={handleOpenDeleteResourceModal}
            />
          ))}
        {createSection && sections && (
          <EstimateSection
            create
            section={{
              id: '',
              status: EntityStatus.New,
              costEstimateId,
              name: '',
              items: [],
              saleCost: 0,
              cost: 0,
              ordering: 0,
            }}
            sectionOrdering={sections.length + 1}
            onDeleteSection={handleClickOpenSectionDeleteButton}
            onCancel={handleAddSectionCancel}
            onDeleteOperation={handleOpenDeleteOperationModal}
            onDeleteResource={handleOpenDeleteResourceModal}
          />
        )}
        {!createSection && (
          <div className="outlay__add-object" onClick={handleAddSectionClick}>
            <img src={addIcon} alt={t('estimate.add-section') ?? ''} />
            {t('estimate.add-section')}
          </div>
        )}
      </div>
    </>
  );
};

export const EstimateList = memo(EstimateListRaw);
