import { combineReducers, configureStore, Middleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootSage from '@sagas';
import userReducer from './user/slice';
import companyReducer from './company/slice';
import companyObjectsReducer from './companyObjects/slice';
import costEstimatesReducer from './costEstimates/slice';
import costEstimateSectionsReducer from './costEstimateSections/slice';
import costEstimateOperationsReducer from './costEstimateOperations/slice';
import costEstimateResourcesReducer from './costEstimateResources/slice';
import resourceFoldersReducer from './resourceFolders/slice';
import resourcesReducer from './resources/slice';
import operationFoldersReducer from './operationFolders/slice';
import operationsReducer from './operations/slice';
import profileReducer from './profile/slice';
import vocabularyReducer from './vocabulary/slice';
import errorsReducer from './errors/slice';
import contactReducer from './contacts/slice';
import organizationReducer from './organizations/slice';
import accountReducer from './account/slice';
import documentsReducer from './documents/slice';
import supplierReducer from './supplier/slice';
import requestCustomerReducer from './requestCustomer/slice';
import responseSupplierReducer from './responseSupplier/slice';
import requestSupplierReducer from './requestSupplier/slice';
import orderCustomerReducer from './ordersCustomer/slice';
import orderSupplierReducer from './orderSupplier/slice';
import chatReducer from './chat/slice';
import modalControlReducer from './modalControl/slice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['user', 'companyObjects'],
  blacklist: ['costEstimates', 'companyObjects'],
};

const companyObjectsPersistConfig = {
  key: 'companyObjects',
  storage,
  blacklist: [
    'status',
    'companyObjects',
    'activeBlinkWhenCreatedNewObject',
    'createObjectStatus',
    'latestAddedCompanyObjectId',
  ],
};

const costEstimatesPersistConfig = {
  key: 'costEstimates',
  storage,
  blacklist: [
    'objectCostEstimatesStatus',
    'costEstimatesStatus',
    'commercialProposalStatus',
    'objectCostEstimates',
    'costEstimates',
    'activeCreateNewCostEstimate',
  ],
};

const rootReducer = combineReducers({
  documents: documentsReducer,
  contact: contactReducer,
  organization: organizationReducer,
  user: userReducer,
  company: companyReducer,
  companyObjects: persistReducer(
    companyObjectsPersistConfig,
    companyObjectsReducer
  ),
  costEstimates: persistReducer(
    costEstimatesPersistConfig,
    costEstimatesReducer
  ),
  costEstimateSections: costEstimateSectionsReducer,
  costEstimateOperations: costEstimateOperationsReducer,
  costEstimateResources: costEstimateResourcesReducer,
  profile: profileReducer,
  resourceFolders: resourceFoldersReducer,
  resources: resourcesReducer,
  operationFolders: operationFoldersReducer,
  operations: operationsReducer,
  vocabulary: vocabularyReducer,
  errors: errorsReducer,
  account: accountReducer,
  supplier: supplierReducer,
  requestCustomer: requestCustomerReducer,
  responseSupplier: responseSupplierReducer,
  requestSupplier: requestSupplierReducer,
  orderCustomer: orderCustomerReducer,
  orderSupplier: orderSupplierReducer,
  chat: chatReducer,
  modalControl: modalControlReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares: Middleware[] = [];

const sagaMiddleware = createSagaMiddleware();
middlewares.push(sagaMiddleware);

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line import/no-extraneous-dependencies
  const { createLogger } = await import('redux-logger');
  const logger = createLogger({
    collapsed: true,
  }) as Middleware;
  middlewares.push(logger);
}

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(middlewares),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

sagaMiddleware.run(rootSage);
