import React, {
  FC,
  memo,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { nanoid } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useTableRowForm } from '@hooks/useTableRowForm';
import { EstimateResource } from '@components/Estimate/List/Resource';
import { EstimateContext } from '@components/Estimate';
import { TableSelect } from '@components/Table/Select';
import { EstimateOperationsCatalogModal } from '@components/EstimateOperationsCatalogModal';
import { EntityStatus } from '@store/constants';
import { CostEstimateSection } from '@store/costEstimateSections/slice';
import { CostEstimateOperation } from '@store/costEstimateOperations/slice';
import {
  createCostEstimateOperationRequest,
  createCostEstimateOperationsWithResourcesRequest,
  fetchostEstimateOperationMoveRequest,
  setCopyOperation,
  updateCostEstimateOperationRequest,
} from '@store/costEstimateOperations/actions';
import { getCostEstimateResources } from '@store/costEstimateResources/selectors';
import { getOperations } from '@store/operations/selectors';
import { getResources } from '@store/resources/selectors';
import { ResourceType } from '@store/resources/slice';
import { Unit, UnitId } from '@store/vocabulary/slice';
import { getDefaultUnit } from '@store/vocabulary/selectors';
import { CreateCostEstimateResourceData } from '@sagas/api/costEstimateResources';
import { CreateCostEstimateOperationData } from '@sagas/api/costEstimateOperations';
import { useCurrentLocale } from '@hooks/useCurrentLocale';
import { useLocalizedUnits } from '@hooks/useUnits';
import { useSearchParams } from 'react-router-dom';
import {
  getCurrentCostEstimate,
  getSelectedIds,
} from '@store/costEstimates/selectors';
import { getLastCreatedId } from '@store/costEstimateOperations/selectors';
import { OperationId } from '@store/operations/slice';
import { percentFormat, percentParse } from '@utils/percent';
import { currencyFormat, currencyParse } from '@utils/currency';
import { ReactComponent as ArrowIcon } from '@static/img/icon-arrow6.svg';
import { useDrag, useDrop } from 'react-dnd';
import Checkbox from '@components/commons/Checkbox';
import addIcon from '@static/img/icon-add5.svg';
import removeIcon from '@static/img/icon-delete.svg';
import copyIcon from '@static/img/icon-copy3.svg';
import { deleteSelectedIds, setSelectedId } from '@store/costEstimates/actions';
import { createCostEstimateResourceRequest } from '@store/costEstimateResources/actions';
import styles from './styles.module.sass';
import './styles.sass';

type EstimateOperationProps = {
  create?: boolean;
  section: CostEstimateSection;
  sectionOrdering: number;
  operation: CostEstimateOperation;
  operationOrdering: number;
  onCancel?: () => void;
  onDeleteOperation: (id: string) => void;
  onDeleteResource: (id: string) => void;
};

type FormData = {
  id: string;
  name: string;
  amount: number;
  unitId: UnitId;
  price: string;
  salePrice: string;
  cost: string;
  saleCost: string;
  deliveryTime: number;
  markup: string;
};

const EstimateOperationRaw: FC<EstimateOperationProps> = ({
  create = false,
  section,
  sectionOrdering,
  operation,
  operationOrdering,
  onCancel,
  onDeleteOperation,
  onDeleteResource,
}) => {
  const { companyId, objectId, openOperation, isOpenedOperation } =
    useContext(EstimateContext);
  const defaultValues = useRef({});
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const locale = useCurrentLocale();
  const units = useLocalizedUnits();
  const defaultUnit = useAppSelector(getDefaultUnit);
  const allOperations = useAppSelector(getOperations);
  const allResources = useAppSelector(getResources);
  const resources = useAppSelector(getCostEstimateResources);
  const costEstimate = useAppSelector(getCurrentCostEstimate);
  const selectedIds = useAppSelector(getSelectedIds);
  const fetching = operation.status === EntityStatus.Fetching;

  const [openCatalog, setOpenCatalog] = useState(false);
  const [createResource, setCreateResource] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const [searchParams] = useSearchParams();
  const costEstimateIdFromQuery = searchParams.get('costEstimateId');

  const currencyFormatInput = (value: number) => {
    return costEstimate?.currencyCode !== undefined
      ? currencyFormat(value, costEstimate?.currencyCode, locale)
      : `${value}`;
  };

  defaultValues.current = {
    ...operation,
    markup: percentFormat(operation.markup, locale),
    price:
      costEstimate?.currencyCode !== undefined
        ? currencyFormat(operation.price, costEstimate?.currencyCode, locale)
        : `${operation.price}`,
    cost:
      costEstimate?.currencyCode !== undefined
        ? currencyFormat(operation.cost, costEstimate?.currencyCode, locale)
        : `${operation.cost}`,
    salePrice:
      costEstimate?.currencyCode !== undefined
        ? currencyFormat(
            operation.salePrice,
            costEstimate?.currencyCode,
            locale
          )
        : `${operation.salePrice}`,
    saleCost:
      costEstimate?.currencyCode !== undefined
        ? currencyFormat(operation.saleCost, costEstimate?.currencyCode, locale)
        : `${operation.saleCost}`,
  };

  const { register, control, handleSubmit, setValue, setFocus, reset } =
    useForm<FormData>({
      defaultValues: defaultValues.current,
    });

  const opened = isOpenedOperation(section.id, operation.id);

  const {
    rowRef,
    editable,
    handleInputFocus,
    handleInputBlur,
    handleInputKeyUp,
    handleFormSubmit,
  } = useTableRowForm({
    defaultEditable: create,
    onInputCancel: () => {
      reset(defaultValues.current);
      onCancel?.();
    },
    onEnter: () => {
      onCancel?.();
    },
    onOutsideClick: () => {
      // for some reason outside click doesn't trigger blur with form `reset()`
      (document?.activeElement as HTMLInputElement)?.blur();
      onCancel?.();
    },
    onInputBlur: () => {
      handleFormSubmit();
    },
    onFormSubmit: handleSubmit((formData) => {
      const { id, ...data } = formData;

      if (companyId === undefined) {
        return;
      }

      if (data.name.trim() === '') {
        return;
      }

      if (costEstimateIdFromQuery) {
        if (create) {
          onCancel?.();
          dispatch(
            createCostEstimateOperationRequest({
              companyId,
              objectId,
              costEstimateId: costEstimateIdFromQuery,
              sectionId: section.id,
              temporaryId: `create-${nanoid()}`,
              data: {
                id: '',
                sectionId: section.id,
                ...data,
                markup: percentParse(data.markup, locale),
                price: currencyParse(data.price, locale),
                cost: currencyParse(data.cost, locale),
                salePrice: currencyParse(data.salePrice, locale),
                saleCost: currencyParse(data.saleCost, locale),
                ordering: 0,
              },
            })
          );
        } else {
          dispatch(
            updateCostEstimateOperationRequest({
              companyId,
              objectId,
              costEstimateId: costEstimateIdFromQuery,
              id,
              data: {
                ...data,
                markup: percentParse(data.markup, locale),
                price: currencyParse(data.price, locale),
                cost: currencyParse(data.cost, locale),
                salePrice: currencyParse(data.salePrice, locale),
                saleCost: currencyParse(data.saleCost, locale),
              },
            })
          );
        }
      }
    }),
  });

  useEffect(() => {
    reset(defaultValues.current);
  }, [operation, reset]);

  const handleOpenOperation = () => {
    openOperation(section.id, operation.id);
  };

  const handlerUnitSelected = () => {
    setFocus('price');
  };

  const handleAddResourceClick = () => {
    setCreateResource(true);
  };

  const handleAddResourceCancel = () => {
    setCreateResource(false);
  };

  const handleCatalogButtonClick = () => {
    setOpenCatalog(true);
  };

  const handleCatalogCancel = () => {
    setOpenCatalog(false);
    onCancel?.();
  };

  const handleAddSelected = (
    selectedItems: string[],
    operations: Record<
      OperationId,
      {
        amount: number;
        price: number;
        markup: number;
        salePrice: number;
      }
    >
  ) => {
    const list = selectedItems.reduce((acc, selectedItem) => {
      const [operationId, resourceId] = selectedItem.split(' ');
      acc[operationId] ??= {};
      acc[operationId][resourceId] = true;
      return acc;
    }, {} as Record<string, Record<string, boolean>>);

    const operationsData: CreateCostEstimateOperationData[] = [];
    const resourcesData: CreateCostEstimateResourceData[] = [];

    Object.keys(list).forEach((operationId) => {
      const amount = operations[operationId]?.amount ?? 1;
      const operationData = allOperations.find((o) => o.id === operationId);

      if (operationData !== undefined) {
        operationsData.push({
          sectionId: section.id,
          cost: 0,
          saleCost: 0,
          ...operationData,
          amount,
          price: operations[operationId]?.price ?? 1,
          markup: operations[operationId]?.markup ?? 1,
          salePrice: operations[operationId]?.salePrice ?? 1,
          ordering: 0,
        });
      }

      Object.keys(list[operationId]).forEach((resourceId) => {
        const resourceData = allResources.find((r) => r.id === resourceId);
        const operationResource = operationData?.resources.find(
          (r) => r.id === resourceId
        );
        const operationAmount = operationData?.amount ?? 1;
        const operationResourceAmount = operationResource?.amount ?? 1;

        if (resourceData !== undefined) {
          resourcesData.push({
            ...resourceData,
            operationId: '', // TODO: fix
            amount: (operationResourceAmount / operationAmount) * amount,
            cost: 0,
            saleCost: 0,
          });
        }
      });
    });

    if (costEstimateIdFromQuery !== null) {
      dispatch(
        createCostEstimateOperationsWithResourcesRequest({
          companyId,
          objectId,
          costEstimateId: costEstimateIdFromQuery,
          sectionId: section.id,
          operationsData,
          resourcesData,
        })
      );
    }
  };

  const handleCopyResource = () => {
    if (costEstimateIdFromQuery && operation.fromCopyId) {
      resources.forEach((e) => {
        if (e.operationId === operation.fromCopyId) {
          dispatch(
            createCostEstimateResourceRequest({
              companyId,
              objectId,
              costEstimateId: costEstimateIdFromQuery,
              temporaryId: `create-${nanoid()}`,
              data: {
                ...e,
                categoryId: e.category?.id,
                categoryName: e.category?.name,
                operationId: operation.id,
              },
            })
          );
        }
      });
      dispatch(setCopyOperation(''));
    }
  };

  const handleCopyOperationClick = () => {
    if (costEstimateIdFromQuery) {
      dispatch(
        createCostEstimateOperationRequest({
          companyId,
          objectId,
          costEstimateId: costEstimateIdFromQuery,
          sectionId: section.id,
          temporaryId: `create-${nanoid()}`,
          data: {
            ...operation,
            id: '',
            sectionId: section.id,
          },
        })
      );
      dispatch(setCopyOperation(operation.id));
    }
  };

  const handleMoveOperation = (
    toMove: 1 | -1,
    objectElementId: string,
    subjectElementId: string
  ) => {
    if (costEstimateIdFromQuery) {
      dispatch(
        fetchostEstimateOperationMoveRequest({
          companyId,
          costEstimateId: costEstimateIdFromQuery,
          toMove,
          objectElementId,
          subjectElementId,
          objectId,
        })
      );
    }
  };

  useEffect(() => {
    handleCopyResource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ref = useRef<HTMLDivElement>(null);
  const [isTop, setIsTop] = useState(true);
  const [{ isDragging }, drag] = useDrag(() => ({
    type: section.id,
    item: operation,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [{ isOver }, dropRef] = useDrop({
    accept: [section.id],
    drop: (item: CostEstimateOperation, monitor) => {
      const id = ref.current?.getAttribute('id');

      if (ref.current) {
        const currentRow = ref.current.getBoundingClientRect();

        if (currentRow) {
          const h = currentRow.height;
          const y = monitor.getClientOffset()?.y;
          if (y) {
            const hoverActualY: number = y - currentRow.top;
            if (id)
              handleMoveOperation(hoverActualY > h / 2 ? 1 : -1, item.id, id);
          }
        }
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
    hover: (item, monitor) => {
      const id = ref.current?.getAttribute('id');
      if (ref.current) {
        const currentRow = ref.current.getBoundingClientRect();

        if (currentRow) {
          const h = currentRow.height;
          const y = monitor.getClientOffset()?.y;
          if (y) {
            const hoverActualY: number = y - currentRow.top;
            if (id) setIsTop(hoverActualY < h / 2);
          }
        }
      }
    },
  });

  drag(dropRef(ref));

  const lastCreatedId = useAppSelector(getLastCreatedId);

  const localResources = resources.filter(
    (r) => r.operationId === operation.id && !r.response && r.category
  );

  useEffect(() => {
    if (lastCreatedId === operation.id) openOperation(section.id, operation.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const noneCheckedResources = localResources.filter(
      (r) => !selectedIds.includes(r.id)
    );
    if (noneCheckedResources.length === 0 && localResources.length > 0) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [localResources, operation.id, resources, selectedIds]);

  const handleAddOperationIds = (id: string, checked: boolean) => {
    if (checked) {
      dispatch(setSelectedId(localResources.map((r) => r.id)));
    } else {
      dispatch(deleteSelectedIds(localResources.map((r) => r.id)));
    }
  };

  return (
    <>
      <div
        ref={rowRef}
        className="outlay__operation"
        style={{
          opacity: isDragging ? '0.2' : '1',
          borderTop: isOver && isTop ? '1px solid #FF9292' : '1px solid #fff',
          borderBottom:
            isOver && !isTop ? '1px solid #FF9292' : '1px solid #fff',
        }}
      >
        <div
          className={cn('outlay__operation-block', {
            open: opened,
            add: create,
            edit: editable,
            fetching,
          })}
        >
          <div className="outlay__operation-sort" ref={ref} id={operation.id} />
          {fetching && <div className="outlay__operation-edit" />}
          <Checkbox
            rootClassName="outlay__operation-check check"
            onChange={(e) =>
              handleAddOperationIds(operation.id, e.currentTarget.checked)
            }
            checked={isChecked}
          />
          <div
            className="outlay__operation-slider"
            onClick={handleOpenOperation}
          >
            <i>
              <ArrowIcon />
            </i>
          </div>
          <div className="outlay__operation-name">
            {!create && (
              <b>
                {sectionOrdering}.{operationOrdering}
              </b>
            )}
            <input
              type="text"
              autoFocus={create}
              placeholder={t('estimate.operation-name') ?? ''}
              onFocus={handleInputFocus}
              onKeyUp={handleInputKeyUp}
              {...register('name', {
                onBlur: handleInputBlur,
              })}
              title={operation.name}
            />
            {create && (
              <button
                aria-label={t('estimate.select-from-directory') ?? ''}
                data-balloon-pos="left"
                onClick={handleCatalogButtonClick}
              />
            )}
          </div>
          {/* Убрать див с категориями как будет выдана актуальная верстка */}
          <div className="outlay__operation-cat" data-title="Категория" />
          <div
            className="outlay__operation-count"
            data-title={t('estimate.list-count')}
          >
            <input
              type="text"
              // className="error"
              onFocus={handleInputFocus}
              onKeyUp={handleInputKeyUp}
              {...register('amount', {
                onChange: (e) => {
                  setValue('amount', e.target.value.replace(/[^\d.]/g, ''));
                },
                setValueAs: (v) => (v === '' ? 0 : parseFloat(v)),
                onBlur: handleInputBlur,
              })}
              title={`${operation.amount}` || ''}
            />
          </div>
          <div
            className="outlay__operation-unit"
            data-title={t('estimate.list-unit')}
          >
            <TableSelect<Unit, FormData>
              name="unitId"
              control={control}
              listItems={units}
              onSelectFocus={handleInputFocus}
              onSelectBlur={handleInputBlur}
              onSelectChange={handlerUnitSelected}
            />
          </div>
          <div
            className="outlay__operation-price"
            data-title={t('estimate.list-price')}
          >
            <input
              readOnly
              type="text"
              {...register('price')}
              className="outlay__operation-price__input"
              title={currencyFormatInput(operation.price)}
            />
          </div>
          <div
            className="outlay__operation-cost"
            data-title={t('estimate.list-cost')}
          >
            <input
              readOnly
              type="text"
              {...register('cost')}
              title={currencyFormatInput(operation.cost)}
            />
          </div>
          <div className="outlay__operation-dev" />
          <div
            className="outlay__operation-markup"
            data-title={t('directory.markup')}
          >
            <input
              readOnly
              type="text"
              {...register('markup')}
              title={percentFormat(operation.markup, locale)}
            />
          </div>
          <div
            className="outlay__operation-price2"
            data-title={t('estimate.list-salePrice')}
          >
            <input
              readOnly
              type="text"
              {...register('salePrice')}
              title={currencyFormatInput(operation.salePrice)}
            />
          </div>
          <div
            className="outlay__operation-cost2"
            data-title={t('estimate.list-saleCost')}
          >
            <input
              readOnly
              type="text"
              {...register('saleCost')}
              title={currencyFormatInput(operation.saleCost)}
            />
          </div>
          {!editable && (
            <>
              <button
                className="outlay__operation-copy"
                title={t('estimate.copy-the-operation') ?? ''}
                onClick={handleCopyOperationClick}
              >
                <img src={copyIcon} alt="" />
              </button>
              <button
                className="outlay__operation-remove"
                title={t('estimate.delete-an-operation') ?? ''}
                onClick={() => onDeleteOperation(operation.id)}
              >
                <img src={removeIcon} alt="" />
              </button>
            </>
          )}
        </div>
        <div
          className={cn('outlay__operation-resource', {
            [styles.open]: opened,
          })}
        >
          {resources
            .filter((r) => r.operationId === operation.id)
            .sort((a, b) => a.ordering - b.ordering)
            .map((resource) => (
              <EstimateResource
                key={resource.id}
                operation={operation}
                resource={resource}
                onDeleteResource={onDeleteResource}
              />
            ))}
          {createResource && costEstimateIdFromQuery && (
            <EstimateResource
              create
              operation={operation}
              resource={{
                numberOfResponses: null,
                status: EntityStatus.New,
                id: '',
                costEstimateId: costEstimateIdFromQuery,
                operationId: operation.id,
                items: [],
                name: '',
                type: ResourceType.MAT,
                unitId: defaultUnit.id,
                price: 0,
                salePrice: 0,
                markup: 0,
                amount: 1,
                cost: 0,
                saleCost: 0,
                ordering: 0,
                response: null,
                deliveryTime: null,
                defaultPrice: null,
                resourceParent: null,
                ordered: false,
              }}
              onCancel={handleAddResourceCancel}
              onDeleteResource={onDeleteResource}
            />
          )}
          {!createResource && (
            <div
              className="outlay__add-resource"
              onClick={handleAddResourceClick}
            >
              <i>
                <img src={addIcon} alt={t('estimate.add-resource') ?? ''} />
              </i>
              {t('estimate.add-resource')}
            </div>
          )}
        </div>
      </div>
      {create && (
        <EstimateOperationsCatalogModal
          visible={openCatalog}
          onAddOperations={handleAddSelected}
          onCancel={handleCatalogCancel}
        />
      )}
    </>
  );
};

export const EstimateOperation = memo(EstimateOperationRaw);
