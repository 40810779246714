import React from 'react';
import { addPopUp } from '@utils/notifications/popup';
import Confirm from '@components/Notification/Confirm';

const confirm = (title: string, description: string) => {
  return new Promise((resolve) => {
    addPopUp((close) => (
      <Confirm
        title={title}
        description={description}
        onOk={() => {
          close();
          resolve(true);
        }}
        onCancel={() => {
          close();
          resolve(false);
        }}
      />
    ));
  });
};

export default confirm;
