import { ReactComponent as IconCheck } from '@static/img/icon-check1.svg';
import { TFunction } from 'i18next';

type Props = {
  onClick: (id: string) => void;
  active?: boolean;
  t: TFunction<'translation', undefined, 'translation'>;
};

const SelfDelivery = ({ t, onClick, active }: Props) => {
  return (
    <div className="offer__item-cot">
      <i
        onClick={() => onClick('self')}
        className={active ? 'active' : undefined}
      >
        <IconCheck />
      </i>
      <div>
        <h4>{t('supply.self-delivery')}</h4>
      </div>
      <ul>
        <li>{t('supply.cancels-the-selection-among-other-suppliers')}</li>
      </ul>
    </div>
  );
};

export default SelfDelivery;
