import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ANIMATION_TIME } from '@constants/modal';
import { Modal, ModalPosition, ModalType } from '@components/Modal';

type ConfirmProps = {
  title: string;
  description: string;
  onOk: () => void;
  onCancel: () => void;
};

const Confirm: FC<ConfirmProps> = ({ title, description, onOk, onCancel }) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setVisible(true);
  }, []);

  const handleConfirm = (e: SyntheticEvent) => {
    e.preventDefault();
    setVisible(false);
    setTimeout(onOk, ANIMATION_TIME);
  };
  const handleReject = (e: SyntheticEvent) => {
    e.preventDefault();
    setVisible(false);
    setTimeout(onCancel, ANIMATION_TIME);
  };

  return (
    <Modal
      visible={visible}
      type={ModalType.Min}
      position={ModalPosition.Center}
      onBackgroundClick={handleReject}
    >
      <div className="modal__close" onClick={handleReject} />
      <div className="modal__min-title center">
        <h4>{title}</h4>
        <p>{description}</p>
      </div>
      <div className="modal__min-submit">
        <a
          className="link modal-close transparent border"
          onClick={handleReject}
        >
          {t('confirm.cancel')}
        </a>
        <a className="link" onClick={handleConfirm}>
          {t('confirm.ok')}
        </a>
      </div>
    </Modal>
  );
};

export default Confirm;
