import { useAppDispatch } from '@hooks/redux';
import { ReactComponent as IconSearch } from '@static/img/icon-search1.svg';
import { searchConversationByCompany } from '@store/chat/actions';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

export default function Search() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(searchConversationByCompany(e.target.value));
  };

  return (
    <form className="chat__search">
      <div>
        <input
          placeholder={t('chat.serach-placeholder') || ''}
          autoComplete="off"
          onChange={(e) => handleSearch(e)}
        />
        <button>
          <IconSearch />
        </button>
      </div>
    </form>
  );
}
