import {
  all,
  call,
  put,
  SagaReturnType,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { Saga } from '@sagas/types';
import { handleError } from '@sagas/errors';
import {
  FetchOperationsRequestAction,
  CreateOperationRequestAction,
  UpdateOperationRequestAction,
  DeleteOperationsRequestAction,
  AddOperationResourcesRequestAction,
  UpdateOperationResourcesRequestAction,
  DeleteOperationResourcesRequestAction,
  fetchOperationsRequest,
  fetchOperationsSuccess,
  createOperationRequest,
  createOperationSuccess,
  createOperationError,
  updateOperationRequest,
  updateOperationSuccess,
  deleteOperationsRequest,
  deleteOperationsSuccess,
  addOperationResourcesRequest,
  addOperationResourcesSuccess,
  updateOperationResourcesRequest,
  updateOperationResourcesSuccess,
  deleteOperationResourcesRequest,
  deleteOperationResourcesSuccess,
} from '@store/operations/actions';
import {
  getOperations,
  createOperation,
  updateOperation,
  deleteOperations,
  addOperationResources,
  updateOperationResources,
} from '@sagas/api/operations';

const fetchOperationsRequestHandler: Saga<FetchOperationsRequestAction> =
  function* ({ payload }) {
    const { companyId } = payload;

    try {
      const data: SagaReturnType<typeof getOperations> = yield call(
        getOperations,
        companyId
      );

      yield put(fetchOperationsSuccess({ companyId, data }));
    } catch (e) {
      yield call(handleError, e);
    }
  };

const createOperationRequestHandler: Saga<CreateOperationRequestAction> =
  function* ({ payload }) {
    const { companyId, temporaryId, data } = payload;

    try {
      const operation: SagaReturnType<typeof createOperation> = yield call(
        createOperation,
        companyId,
        data
      );

      yield put(
        createOperationSuccess({ companyId, temporaryId, data: operation })
      );
    } catch (e) {
      yield put(createOperationError({ companyId, temporaryId }));
      yield call(handleError, e);
    }
  };

const updateOperationRequestHandler: Saga<UpdateOperationRequestAction> =
  function* ({ payload }) {
    const { companyId, id, data } = payload;

    try {
      yield call(updateOperation, companyId, id, data);
      yield put(updateOperationSuccess({ companyId, id, data }));
    } catch (e) {
      yield call(handleError, e);
    }
  };

const deleteOperationsRequestHandler: Saga<DeleteOperationsRequestAction> =
  function* ({ payload }) {
    const { companyId, data } = payload;

    try {
      yield call(deleteOperations, companyId, data);
      yield put(deleteOperationsSuccess({ companyId, ids: data.ids }));
    } catch (e) {
      yield call(handleError, e);
    }
  };

const addOperationResourcesRequestHandler: Saga<AddOperationResourcesRequestAction> =
  function* ({ payload }) {
    const { companyId, id, data } = payload;

    try {
      yield call(addOperationResources, companyId, id, data);
      yield put(addOperationResourcesSuccess({ companyId, id, data }));
    } catch (e) {
      yield call(handleError, e);
    }
  };

const updateOperationResourcesRequestHandler: Saga<UpdateOperationResourcesRequestAction> =
  function* ({ payload }) {
    const { companyId, id, data } = payload;

    try {
      yield call(updateOperationResources, companyId, id, data);
      yield put(updateOperationResourcesSuccess({ companyId, id, data }));
    } catch (e) {
      yield call(handleError, e);
    }
  };

const deleteOperationResourcesRequestHandler: Saga<DeleteOperationResourcesRequestAction> =
  function* ({ payload }) {
    const { companyId, id, data } = payload;

    try {
      yield put(deleteOperationResourcesSuccess({ companyId, id, data }));
    } catch (e) {
      yield call(handleError, e);
    }
  };

export default function* root() {
  yield all([
    takeLatest(fetchOperationsRequest, fetchOperationsRequestHandler),
    takeEvery(createOperationRequest, createOperationRequestHandler),
    takeEvery(updateOperationRequest, updateOperationRequestHandler),
    takeEvery(deleteOperationsRequest, deleteOperationsRequestHandler),
    takeEvery(
      addOperationResourcesRequest,
      addOperationResourcesRequestHandler
    ),
    takeEvery(
      updateOperationResourcesRequest,
      updateOperationResourcesRequestHandler
    ),
    takeEvery(
      deleteOperationResourcesRequest,
      deleteOperationResourcesRequestHandler
    ),
  ]);
}
