import { TFunction } from 'i18next';
import { FieldValues } from 'react-hook-form';

export const inputErrors = (
  name: string,
  errors: FieldValues,
  t: TFunction<'translation', undefined, 'translation'>
) => {
  switch (errors[name]?.type) {
    case 'required':
      return t('auth.validation-empty');
    case 'validate':
      return t('auth.validation-cost-zero');
    case 'phone':
      return t('auth.validation-phone-pattern');
    case 'minLength':
      return t('auth.validation-phone-short');
    case 'server':
      return errors[name]?.message;
    default:
      return '';
  }
};

export const emailErrors = (
  errors: FieldValues,
  t: TFunction<'translation', undefined, 'translation'>
) => {
  switch (errors.email?.type) {
    case 'required':
      return t('auth.validation-empty');
    case 'minLength':
      return t('auth.validation-email-short');
    case 'pattern':
      return t('auth.validation-email');
    default:
      return '';
  }
};
