import { createAction } from '@reduxjs/toolkit';
import { companySlice, CompanyData, CompanyId } from '@store/company/slice';
import { CityId, CountryId } from '@store/vocabulary/slice';

export type SaveCompanyRequestData = {
  id: CompanyId;
  name: string;
  email: string;
  phone: string;
  website: string;
  countryId: CountryId | null;
  cityId: CityId | null;
  address: string;
  description: string;
  employeesAmount: string;
  marketExperience: string;
  projectsAmount: string;
};
export type SaveCompanySuccessData = SaveCompanyRequestData;

export type FetchCompanyRequestAction = {
  id: CompanyId;
};

export type FetchCompanySuccessAction = {
  data: CompanyData;
};

export type SaveCompanyRequestAction = {
  data: SaveCompanyRequestData;
};

export type SaveCompanySuccessAction = {
  data: CompanyData;
};

export type DeleteCompanyRequestAction = {
  id: CompanyId;
};

export type DeleteCompanySuccessAction = void;

export const saveCompanyRequest = createAction<SaveCompanyRequestAction>(
  'company/saveCompanyRequest'
);

export const deleteCompanyRequest = createAction<DeleteCompanyRequestAction>(
  'company/deleteCompanyRequest'
);

export const {
  setDeleteCompanyStatus,
  setSaveCompanyStatus,
  saveCompanySuccess,
  deleteCompanySuccess,
  fetchCompanyRequest,
  fetchCompanySuccess,
  setImageProfileCompany,
} = companySlice.actions;
