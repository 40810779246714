import { RootState } from '@store';
import { FetchStatus } from '@store/constants';
import { CostEstimateId } from '@store/costEstimates/slice';

export const getRootState = (state: RootState) => state.costEstimateResources;
export const getStatus = (state: RootState) => getRootState(state).status;
export const getDeleteResourceStatus = (state: RootState) =>
  getRootState(state).deleteResourceStatus;
export const getCostEstimateResources = (state: RootState) =>
  getRootState(state).items;

export const getCostEstimateResourcesByCostEstimateId = (
  state: RootState,
  costEstimateId: CostEstimateId
) =>
  getCostEstimateResources(state).filter(
    (item) => item.costEstimateId === costEstimateId
  );

export const isCostEstimateResourcesNotFetched = (state: RootState) =>
  getStatus(state) === FetchStatus.NotFetched;
export const isCostEstimateResourcesFetching = (state: RootState) =>
  getStatus(state) === FetchStatus.Fetching;
export const isCostEstimateResourcesFetched = (state: RootState) =>
  getStatus(state) === FetchStatus.Fetched;
