/* eslint-disable react/display-name */
import { OrderStatus } from '@utils/enums';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconChek } from '@static/img/check-order.svg';

type InProps = {
  action: () => void;
  isProxy: boolean;
  status: keyof typeof OrderStatus;
};

type InjectProps = {
  header: string;
  title: string;
  textButton: string;
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string | undefined }
  >;
};

function Bar({
  action,
  header,
  textButton,
  title,
  Icon,
  status,
  isProxy,
}: InProps & InjectProps) {
  return (
    <div className="order-modal__option">
      <div className="order-modal__item-icon">
        <Icon />
      </div>
      <div className="order-modal__item-title">
        <h4>{header}</h4>
        <span>{title}</span>
      </div>

      <div className="order-modal__item">
        <button
          onClick={action}
          className={status === 'READY' && !isProxy ? 'hide' : undefined}
        >
          {textButton}
        </button>
      </div>
    </div>
  );
}

export const insertAction = (
  Component: React.ComponentType<InProps & InjectProps>
) => {
  return ({ status, ...props }: InProps) => {
    const { t } = useTranslation();
    const Prases: Record<keyof typeof OrderStatus, InjectProps> = {
      ACCEPTED: {
        header: t('order.accepted-header'),
        title: t('order.accepted-title'),
        textButton: t('order.accepted-button'),
        Icon: IconChek,
      },
      PAYMENT: {
        header: t('order.payment-header'),
        title: t('order.payment-title'),
        textButton: t('order.payment-button'),
        Icon: IconChek,
      },
      PAID: {
        header: t('order.paid-header'),
        title: t('order.paid-title'),
        textButton: t('order.paid-button'),
        Icon: IconChek,
      },
      PRODUCTION: {
        header: t('order.ready-header'),
        title: t('order.ready-title'),
        textButton: t('order.payment-button'),
        Icon: IconChek,
      },
      READY: {
        header: t('order.issued-header'),
        title: t('order.issued-title'),
        textButton: t('order.issued-button'),
        Icon: IconChek,
      },
      ISSUED: {
        header: t('order.finish-header'),
        title: t('order.finish-title'),
        textButton: t('order.finish-button'),
        Icon: IconChek,
      },
    };
    return (
      <Component
        {...(props as InProps)}
        header={Prases[status].header}
        textButton={Prases[status].textButton}
        title={Prases[status].title}
        Icon={Prases[status].Icon}
        isProxy={props.isProxy}
        status={status}
      />
    );
  };
};

const StatusBar = insertAction(Bar);

export default StatusBar;
