import { createAction } from '@reduxjs/toolkit';
import { SupplierData, SupplierId, supplierSlice } from '@store/supplier/slice';
import { CityId, CountryId } from '@store/vocabulary/slice';

export type SaveSupplierRequestData = {
  id: string;
  name: string;
  email: string;
  phone: string;
  website: string;
  countryId: CountryId | null;
  cityId: CityId | null;
  address: string;
  description: string;
  employeesAmount: string;
  marketExperience: string;
  projectsAmount: string;
  bankAccount: string;
  correspondentAccount: string;
  bic: string;
  bankName: string;
  categories: string[];
};

export type FetchSupplinerSuccessAction = {
  data: SupplierData;
};

export type SaveSupplinerSuccessAction = {
  data: SupplierData;
};

export type FetchSupplierRequestAction = {
  id: SupplierId;
};

export type SaveSupplierRequestAction = {
  data: SaveSupplierRequestData;
};
export type DeleteSupplierRequestAction = {
  id: SupplierId;
};

export type DeleteCompanySuccessAction = void;

export const saveSupplierRequest = createAction<SaveSupplierRequestAction>(
  'supplier/saveSupplierRequest'
);

export const deleteSupplierRequest = createAction<DeleteSupplierRequestAction>(
  'supplier/deleteSupplierRequest'
);

export const {
  setDeleteSupplierStatus,
  deleteSupplierSuccess,
  fetchSupplierRequest,
  fetchSupplierSuccess,
  saveSupplierSuccess,
  setImageProfileSupplier,
  setSaveSupplierStatus,
} = supplierSlice.actions;
