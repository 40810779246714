import {
  all,
  call,
  put,
  SagaReturnType,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { Saga } from '@sagas/types';
import { handleError } from '@sagas/errors';
import {
  FetchOperationFoldersRequestAction,
  CreateOperationFolderRequestAction,
  RenameOperationFolderRequestAction,
  DeleteOperationFolderRequestAction,
  fetchOperationFoldersRequest,
  fetchOperationFoldersSuccess,
  createOperationFolderRequest,
  createOperationFolderSuccess,
  createOperationFolderError,
  renameOperationFolderRequest,
  renameOperationFolderSuccess,
  deleteOperationFolderRequest,
  deleteOperationFolderSuccess,
} from '@store/operationFolders/actions';
import {
  getOperationFolders,
  createOperationFolder,
  renameOperationFolder,
  deleteOperationFolder,
} from '@sagas/api/operationFolders';

const fetchOperationFoldersRequestHandler: Saga<FetchOperationFoldersRequestAction> =
  function* ({ payload }) {
    const { companyId } = payload;
    try {
      const data: SagaReturnType<typeof getOperationFolders> = yield call(
        getOperationFolders,
        companyId
      );

      yield put(fetchOperationFoldersSuccess({ companyId, data }));
    } catch (e) {
      yield call(handleError, e);
    }
  };

const createOperationFolderRequestHandler: Saga<CreateOperationFolderRequestAction> =
  function* ({ payload }) {
    const { companyId, temporaryId, data } = payload;

    try {
      const operationFolder: SagaReturnType<typeof createOperationFolder> =
        yield call(createOperationFolder, companyId, data);

      yield put(
        createOperationFolderSuccess({
          companyId,
          temporaryId,
          data: operationFolder,
        })
      );
    } catch (e) {
      yield put(
        createOperationFolderError({
          companyId,
          temporaryId,
        })
      );
      yield put(fetchOperationFoldersRequest({ companyId }));
      yield call(handleError, e);
    }
  };

const renameOperationFolderRequestHandler: Saga<RenameOperationFolderRequestAction> =
  function* ({ payload }) {
    const { companyId, id, data } = payload;

    try {
      yield call(renameOperationFolder, companyId, id, data);
      yield put(renameOperationFolderSuccess({ companyId, id }));
    } catch (e) {
      yield put(fetchOperationFoldersRequest({ companyId }));
      yield call(handleError, e);
    }
  };

const deleteOperationFolderRequestHandler: Saga<DeleteOperationFolderRequestAction> =
  function* ({ payload }) {
    const { companyId, id } = payload;

    try {
      yield call(deleteOperationFolder, companyId, id);
      yield put(deleteOperationFolderSuccess({ companyId, id }));
    } catch (e) {
      yield put(fetchOperationFoldersRequest({ companyId }));
      yield call(handleError, e);
    }
  };

export default function* root() {
  yield all([
    takeLatest(
      fetchOperationFoldersRequest,
      fetchOperationFoldersRequestHandler
    ),
    takeEvery(
      createOperationFolderRequest,
      createOperationFolderRequestHandler
    ),
    takeEvery(
      renameOperationFolderRequest,
      renameOperationFolderRequestHandler
    ),
    takeEvery(
      deleteOperationFolderRequest,
      deleteOperationFolderRequestHandler
    ),
  ]);
}
