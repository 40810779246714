import { call } from 'redux-saga/effects';
import { ENDPOINTS } from '@config';
import {
  getRequest,
  postRequestNotAutorization,
  postRequestWithoutAuthorization,
  postRequestWithoutRefresh,
} from '@sagas/api';
import { axiosInstanceFileFn } from '@utils/axios';

export interface LoginRequest {
  email: string;
  password: string;
  languageCode: string;
}
export interface LoginResponse {
  token: string;
  refreshToken: string;
}
export interface RegisterRequest {
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  password: string;
  languageCode: string;
  callbackUrl: string;
}

export interface RefreshTokenRequest {
  refreshToken: string;
}

export interface RefreshTokenResponse {
  token: string;
  refreshToken: string;
}

export type EmailConfirmData = {
  email: string;
  languageCode: string;
};

export function* login(params: LoginRequest) {
  const { data } = yield call(
    postRequestWithoutAuthorization,
    ENDPOINTS.AUTH.LOGIN,
    params
  );

  axiosInstanceFileFn(data.token);
  return data as LoginResponse;
}

export function* register(params: RegisterRequest) {
  const { data } = yield call(
    postRequestWithoutAuthorization,
    ENDPOINTS.AUTH.REGISTER,
    params
  );

  axiosInstanceFileFn(data.token);

  return data as LoginResponse;
}

export function* logout() {
  yield call(getRequest, ENDPOINTS.AUTH.LOGOUT);
}

export function* emailConfirm(params: EmailConfirmData) {
  yield call(postRequestNotAutorization, ENDPOINTS.AUTH.CONFIRM, params);
}

export function* refreshToken(params: RefreshTokenRequest) {
  const { data } = yield call(
    postRequestWithoutRefresh,
    ENDPOINTS.AUTH.REFRESH,
    params
  );

  axiosInstanceFileFn(data.token);

  return data as RefreshTokenResponse;
}
