/// <reference types="vite-plugin-svgr/client" />
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import Loading from '@components/Loading';
import { store, persistor } from '@store';
import App from './app';
import './i18n';
import '@static/sass/style.sass';
import 'balloon-css';

// if (process.env.NODE_ENV === 'development') {
//   const { worker } = await import('./mocks/browser');
//   worker.start({
//     onUnhandledRequest: 'bypass',
//   });
// }

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <Suspense fallback={<Loading />}>
          <App />
        </Suspense>
      </PersistGate>
    </Provider>
  </BrowserRouter>
);
