import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import checkIcon from '@static/img/icon-check9.svg';
import logo from '@static/img/logo4.svg';

enum Status {
  create = '',
  loading = 'loading',
  created = 'dynamic',
  transition = 'active',
}

type CreateObjectButtonProps = {
  createObjectFetched: boolean;
  onCreateClick: () => boolean;
  onTransitionClick: () => void;
};

export const CreateObjectButton: FC<CreateObjectButtonProps> = ({
  createObjectFetched,
  onCreateClick,
  onTransitionClick,
}) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState(Status.create);

  const handleClick = () => {
    if (status === Status.create) {
      const isFormValid = onCreateClick();

      if (isFormValid) {
        setStatus(Status.loading);
      }
    }

    if (status === Status.transition) {
      onTransitionClick();
    }
  };

  useEffect(() => {
    if (createObjectFetched) {
      if (status === Status.loading) {
        const success = createObjectFetched;

        if (success) {
          setStatus(Status.created);

          setTimeout(() => {
            setStatus(Status.transition);
          }, 1000);
        }
      }
    }
  }, [createObjectFetched, status]);

  return (
    <div className="form__group">
      <button className={cn('link add', status)} onClick={handleClick}>
        <span>{t('object.createObject')}</span>
        <span>
          <img src={checkIcon} alt={t('about-object.done') ?? ''} />
        </span>
        <span>{t('object.goToObject')}</span>
        <i>
          <img src={logo} alt={t('about-object.loading') ?? ''} />
        </i>
      </button>
    </div>
  );
};
