/* eslint-disable @typescript-eslint/no-unused-expressions */
import cn from 'classnames';
import './calendar.sass';
import { CSSProperties, useEffect, useState } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { ReactComponent as IconCalendar } from './svg/icon-calendar.svg';
import { ReactComponent as IconClose } from './svg/close.svg';
import { ReactComponent as IconNext } from './svg/arrow-right.svg';
import { ReactComponent as IconPriev } from './svg/arrow-left.svg';

export const toValidDataTime = (ms: number) => {
  const msLength = ms.toFixed().length;

  if (msLength === 13) {
    return ms;
  }

  let newMs = ms;
  for (let index = msLength; index < 13; index += 1) {
    newMs *= 10;
  }

  return newMs;
};

type Month = {
  ['ru-RU']: string[];
  ['ru']: string[];
  ['en-EN']: string[];
};

type Locale = keyof Month;

type Days = Record<number, number>;

const enum SizeDate {
  small = 'small',
  large = 'large',
  medium = 'medium',
}

const ruMonth = [
  'Января',
  'Февраля',
  'Марта',
  'Апреля',
  'Мая',
  'Июня',
  'Июля',
  'Августа',
  'Сентября',
  'Октября',
  'Ноября',
  'Декабря',
];

const mapMonth: Month = {
  'en-EN': [
    'January',
    'February',
    'March',
    'April',
    'June',
    'July',
    'Jul',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  ru: ruMonth,
  'ru-RU': ruMonth,
};

const mapDays: Days = {
  0: 31,
  1: 28,
  2: 31,
  3: 30,
  4: 31,
  5: 30,
  6: 31,
  7: 31,
  8: 30,
  9: 31,
  10: 30,
  11: 31,
};

export const dateToSrt = (ms: number, month: string, short?: boolean) => {
  const date = new Date(ms);
  let m = month.toLocaleLowerCase();
  if (short) m = m.slice(0, 3);

  return `${date.getDate()} ${m} ${date.getFullYear()}`;
};

type CalendarProps = {
  date?: number | null;
  setDate?: (ms: number) => void;
  short?: boolean;
  position?: 'left' | 'center' | 'right';
  popup?: boolean;
  size?: keyof typeof SizeDate;
  styles?: CSSProperties;
  disabled?: boolean;
};

export default function Calendar({
  setDate,
  date,
  short = false,
  position = 'center',
  popup = false,
  size = 'large',
  styles,
  disabled,
}: CalendarProps) {
  const currentDate = date ? new Date(date) : new Date();
  const locale = navigator.language as Locale;

  const [isVisibleCalendar, setIsVisebleCalendar] = useState(false);

  const [day, setDay] = useState(currentDate.getDate());
  const [month, setMonth] = useState(currentDate.getMonth());
  const [year, setYear] = useState(currentDate.getFullYear());

  const [view, setView] = useState<'day' | 'month' | 'yaer'>('day');

  const [arrayDays, setArrayDays] = useState(
    [...Array(mapDays[month])].map((i) => i + 1)
  );

  const [arrayYear, setArrayYear] = useState(
    [...Array(12)].map((e, i) => year - 5 + i)
  );

  const handleSelectedDay = (d: number) => {
    setDate && setDate(new Date(year, month, d).getTime());
    setIsVisebleCalendar(false);
    setDay(d);
  };

  const handleSelectedMonth = (m: number) => {
    setMonth(m);
    setView('day');
  };

  const handleSelectedYear = (y: number) => {
    setYear(y);
    setView('month');
  };

  useEffect(() => {
    setArrayYear([...Array(12)].map((e, i) => year - 5 + i));
  }, [year]);

  useEffect(() => {
    const isLeap = !(year % 4) && month === 1;
    setArrayDays(
      [...Array(isLeap ? mapDays[month] + 1 : mapDays[month]).keys()].map(
        (i) => i + 1
      )
    );
  }, [month, year]);

  return (
    <>
      <div
        className={cn(`calendar ${size}`)}
        onClick={
          popup && !disabled
            ? () => setIsVisebleCalendar(!isVisibleCalendar)
            : undefined
        }
        style={styles}
        aria-disabled
        title={`${
          date
            ? dateToSrt(toValidDataTime(date), mapMonth[locale][month], short)
            : ''
        }`}
      >
        {date &&
          dateToSrt(toValidDataTime(date), mapMonth[locale][month], short)}
        {!date && <IconCalendar />}
      </div>

      {isVisibleCalendar && (
        <div
          className={cn('calendar__popup', {
            left: position === 'left',
            right: position === 'right',
            center: position === 'center',
          })}
          style={{ width: '250px' }}
        >
          <div className="calendar__popup__header">
            <div className="calendar__popup__title">
              <div
                onClick={() => setView('month')}
                className={cn('calendar__navigate', {
                  show: view === 'day' || view === 'month',
                })}
              >
                {mapMonth[locale][month]}
              </div>
              <div
                onClick={() => setView('yaer')}
                className={cn('calendar__popup__navigate', {
                  show: view === 'day' || view === 'month',
                })}
              >
                {year}
              </div>
              <div
                className={cn('calendar__popup__navigate arrow', {
                  show: view === 'yaer',
                })}
              >
                <IconPriev onClick={() => setYear(year - 12)} />
                <IconNext onClick={() => setYear(year + 12)} />
              </div>
            </div>
            <div onClick={() => setIsVisebleCalendar(false)}>
              <IconClose />
            </div>
          </div>
          <div className="calendar__popup__content">
            {view === 'day' &&
              arrayDays.map((d) => {
                return (
                  <div
                    onClick={() => handleSelectedDay(d)}
                    className={cn('day', { current: day === d })}
                    key={nanoid()}
                  >
                    {d}
                  </div>
                );
              })}
            {view === 'month' &&
              mapMonth[locale].map((e, i) => {
                return (
                  <div
                    onClick={() => handleSelectedMonth(i)}
                    className={cn('month', { current: month === i })}
                    key={nanoid()}
                  >
                    {e}
                  </div>
                );
              })}
            {view === 'yaer' &&
              arrayYear.map((e) => {
                return (
                  <div
                    onClick={() => handleSelectedYear(e)}
                    className="year"
                    key={nanoid()}
                  >
                    {e}
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
}
