import { useTranslation } from 'react-i18next';

const OrdersTableHeader = () => {
  const { t } = useTranslation();

  return (
    <div className="obj__order-cap">
      <p>{t('supply.order')}</p>
      <p>{t('supply.delivery-time')}</p>
      <p>{t('supply.supplier')}</p>
      <p>{t('navigation.plan')}</p>
      <p>{t('navigation.fact')}</p>
      <p>{t('supply.documentation')}</p>
      <p>{t('estimate.list-cost')}</p>
      <p>{t('supply.delivery-time')}</p>
    </div>
  );
};
export default OrdersTableHeader;
