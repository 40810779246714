import React, { FC, memo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import iconAdd5 from '@static/img/icon-add5.svg';

type ResourcesCatalogAddFolderButtonProps = {
  onClick: () => void;
};

export const ResourcesCatalogAddFolderButtonRaw: FC<
  ResourcesCatalogAddFolderButtonProps
> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <Link to="#" className="manager__folder add" onClick={onClick}>
      <i>
        <img src={iconAdd5} />
      </i>
      {t('directory.create-folder')}
    </Link>
  );
};

export const ResourcesCatalogAddFolderButton = memo(
  ResourcesCatalogAddFolderButtonRaw
);
