import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@hooks/redux';
import { signInRequest } from '@store/user/actions';
import { useCurrentLocale } from '@hooks/useCurrentLocale';
import { LoginInput } from './LoginInput';
import { PasswordInput } from './PasswordInput';
import { AuthButton } from './AuthButton';
import { ToSignUpButton } from './ToSignUpButton';
import { SupportInfo } from '../SupportInfo';

type FormData = {
  email: string;
  password: string;
};

export const AuthForm = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const locale = useCurrentLocale();
  const { register, handleSubmit, formState } = useForm<FormData>({
    mode: 'onBlur',
  });

  const loginHandler = handleSubmit(({ email, password }) => {
    dispatch(signInRequest({ email, password, languageCode: locale }));
  });

  return (
    <form className="login__form" onSubmit={loginHandler}>
      <LoginInput name="email" register={register} formState={formState} />
      <PasswordInput
        name="password"
        register={register}
        formState={formState}
      />
      <AuthButton title={t('auth.sign-in')} />

      <ToSignUpButton title={t('auth.to-sign-up')} />
      <SupportInfo />
    </form>
  );
};
