export enum TypeSize {
  min = '200x200',
  object = '354x180',
  avatar = '16x16',
  secondAvatar = '40x40',
  firstSupplierLogo = '22x23',
  secondSupplierLogo = '32x32',
}

export const setSizeImage = (img: string, size = TypeSize.min) => {
  const image = img.split('.');

  return `${image[0]}_${size}.${image[1]}`;
};
