import { HTMLAttributes } from 'react';
import cn from 'classnames';

type Props = {
  icon?: JSX.Element;
} & HTMLAttributes<HTMLButtonElement>;

export const RadialPrimaryButton = ({
  className,
  children,
  icon,
  ...restProps
}: Props) => {
  return (
    <button className={cn('snap', className)} {...restProps}>
      {icon}
      {children}
    </button>
  );
};
