import React, {
  createContext,
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useCompanyId } from '@hooks/useCompanyId';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { EstimateAlert } from '@components/Estimate/Alert';
import { EstimateList } from '@components/Estimate/List';
import { Operation, OperationId } from '@store/operations/slice';
import { CompanyId } from '@store/company/slice';
import { CompanyObjectId } from '@store/companyObjects/slice';
import { CostEstimateId } from '@store/costEstimates/slice';
import { getCompanyObjectId } from '@store/companyObjects/selectors';
import { getCostEstimateByObjectId } from '@store/costEstimates/selectors';
import { fetchCostEstimateSectionsRequest } from '@store/costEstimateSections/actions';
import { fetchCostEstimateOperationsRequest } from '@store/costEstimateOperations/actions';
import { fetchCostEstimateResourcesRequest } from '@store/costEstimateResources/actions';
import { useSearchParams } from 'react-router-dom';

export type SectionId = string;

export type Section = {
  id: SectionId;
  name: string;
  operations: Operation[];
};

type EstimateContextValue = {
  companyId: CompanyId;
  objectId: CompanyObjectId;
  costEstimateId: CostEstimateId;
  isOpenedOperation: (
    sectionId: SectionId,
    operationId: OperationId
  ) => boolean;
  openOperation: (
    sectionId: SectionId,
    operationId: OperationId,
    forceOpen?: boolean
  ) => void;
};

export const EstimateContext = createContext<EstimateContextValue>({
  companyId: '',
  objectId: '',
  costEstimateId: '',
  isOpenedOperation: () => false,
  openOperation: () => {},
});

// TUDO попровить типы
type EstimateProps = Record<string, unknown>;

const EstimateRaw: FC<EstimateProps> = () => {
  const dispatch = useAppDispatch();
  const [openedOperations, setOpenedOperations] = useState<string[]>([]);

  const companyId = useCompanyId();
  const objectId = useAppSelector(getCompanyObjectId);
  const costEstimate = useAppSelector((state) =>
    getCostEstimateByObjectId(state, objectId)
  );

  const [searchParams] = useSearchParams();
  const costEstimateIdFromQuery = searchParams.get('costEstimateId');
  // const costEstimateId = useAppSelector(getCurrentCostEstimateId);

  useEffect(() => {
    if (companyId !== undefined && costEstimateIdFromQuery !== null) {
      dispatch(
        fetchCostEstimateSectionsRequest({
          companyId,
          objectId,
          costEstimateId: costEstimateIdFromQuery,
        })
      );
      dispatch(
        fetchCostEstimateOperationsRequest({
          companyId,
          objectId,
          costEstimateId: costEstimateIdFromQuery,
        })
      );
      dispatch(
        fetchCostEstimateResourcesRequest({
          companyId,
          objectId,
          costEstimateId: costEstimateIdFromQuery,
        })
      );
    }
  }, [companyId, objectId, dispatch, costEstimateIdFromQuery]);

  const isOpenedOperation = useCallback(
    (sectionId: SectionId, operationId: OperationId) =>
      openedOperations.includes(`${sectionId} ${operationId}`),
    [openedOperations]
  );
  const openOperation = useCallback(
    (sectionId: SectionId, operationId: OperationId, forceOpen?: boolean) => {
      setOpenedOperations((prevState) => {
        const open = forceOpen ?? !isOpenedOperation(sectionId, operationId);
        if (open) {
          return [...prevState, `${sectionId} ${operationId}`];
        }

        return prevState.filter((o) => o !== `${sectionId} ${operationId}`);
      });
    },
    [isOpenedOperation]
  );

  // TODO: handle undefined values
  const estimateContextProps = useMemo(
    () => ({
      companyId: companyId ?? '',
      objectId,
      costEstimateId: costEstimate?.id ?? '',
      isOpenedOperation,
      openOperation,
    }),
    [companyId, costEstimate?.id, isOpenedOperation, objectId, openOperation]
  );

  return (
    <EstimateContext.Provider value={estimateContextProps}>
      {/* <div className="obj"> */}
      {/*  <EstimateHeader /> */}
      <EstimateAlert />
      <EstimateList />
      {/* <EstimateBottomPanel /> */}
      {/* </div> */}
    </EstimateContext.Provider>
  );
};

export const Estimate = memo(EstimateRaw);
