import { createAction } from '@reduxjs/toolkit';
import { StatusRequest } from '@utils/enums';
import { requestSuplierSlice } from './slice';

export type FetchRequest = {
  supplierId: string;
  status: keyof typeof StatusRequest;
};

export type FetchRequestById = {
  status: keyof typeof StatusRequest;
  requestId: string;
};

export type FetchSendResponse = {
  requestId: string;
  validity: number;
};

export const FetchRequestBasicDataRequestAction = createAction<FetchRequest>(
  'requestSupplier/FetchRequestBasicDataRequestAction'
);

export const FetchRequestRequestAction = createAction<FetchRequestById>(
  'requestSupplier/fetchRequestByIdRequest'
);

export const FetchResponseRequestAction = createAction<FetchSendResponse>(
  'requestSupplier/fetchResponseRequestAction'
);

export const {
  fetchRequestRequestSuccess,
  setStatusFetchingData,
  setActiveRequestId,
  fetchRequestListRequestSuccess,
  updateResponseItem,
  fetchResponseRequestSuccess,
  createResponseItem,
  setFetchingStatusRequest,
} = requestSuplierSlice.actions;
