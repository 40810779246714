import React, { memo, useCallback, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useAppSelector } from '@hooks/redux';
import { getOperationsCountByFolder } from '@store/operations/selectors';
import { OperationFolder } from '@store/operationFolders/slice';
import { EstimateOperationsCatalogContext } from '@components/EstimateOperationsCatalog';
import { ReactComponent as FolderIcon } from '@static/img/icon-folder.svg';
import './styles.sass';

type OperationCatalogFolderProps = {
  folder: OperationFolder;
};

const OperationCatalogFolderRaw: React.FC<OperationCatalogFolderProps> = ({
  folder,
}) => {
  const tileRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { onChangeFolder } = useContext(EstimateOperationsCatalogContext);

  const operationsCount = useAppSelector((state) =>
    getOperationsCountByFolder(state, folder.id)
  );

  const handleClick = useCallback(() => {
    onChangeFolder(folder.id);
  }, [folder.id, onChangeFolder]);

  return (
    <div ref={tileRef} className={cn('manager__folder')} onClick={handleClick}>
      <h4>{folder.name}</h4>
      <p>
        <FolderIcon />
        {t('directory.operations_count', { count: operationsCount })}
      </p>
    </div>
  );
};

export const OperationCatalogFolder = memo(OperationCatalogFolderRaw);
