import { useAppDispatch } from '@hooks/redux';
import { useRole } from '@hooks/useRole';
import { ReactComponent as IconNotImage } from '@static/img/image-upload1.svg';
import { Conversation as ConversationType } from '@store/chat/slice';
import { TypeSize, setSizeImage } from '@utils/formatImage';
import Image from '@components/commons/Image';
import cn from 'classnames';
import { useContext, useEffect } from 'react';
import WebSocketContext from '@context/WebSocketContext';
import { fetchConversationRequestAction } from '@store/chat/actions';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.sass';

type ConversationProps = {
  currentConversationId?: string;
  conversation: ConversationType;
  setActiveConversation: (c: ConversationType) => void;
};

export default function Conversation({
  currentConversationId,
  conversation,
  setActiveConversation,
}: ConversationProps) {
  const { t } = useTranslation();
  const { isCustomer } = useRole();
  const dispatch = useAppDispatch();
  const { receiveMessage } = useContext(WebSocketContext);

  useEffect(() => {
    if (receiveMessage) {
      if (
        receiveMessage.id === conversation.id &&
        conversation.id !== currentConversationId
      ) {
        dispatch(
          fetchConversationRequestAction({ conversationId: conversation.id })
        );
      }
    }
  }, [conversation.id, currentConversationId, dispatch, receiveMessage]);
  return (
    <div
      className={cn('chat__item', {
        active: conversation.id === currentConversationId,
      })}
      key={conversation.id}
      onClick={() => setActiveConversation(conversation)}
    >
      <>
        {isCustomer ? (
          <>
            <i>
              {conversation.supplier.image &&
              conversation.supplier.image.fileName ? (
                <Image
                  url={setSizeImage(
                    conversation.supplier.image?.fileName,
                    TypeSize.secondAvatar
                  )}
                />
              ) : (
                <IconNotImage />
              )}
            </i>
            <h4
              className={styles.chatItemCompanyName}
              title={conversation.supplier.name}
            >
              {conversation.supplier.name}
            </h4>
          </>
        ) : (
          <>
            <i>
              {conversation.company.image &&
              conversation.company.image?.fileName ? (
                <Image
                  url={setSizeImage(
                    conversation.company.image?.fileName,
                    TypeSize.secondAvatar
                  )}
                />
              ) : (
                <IconNotImage />
              )}
            </i>
            <h4
              className={styles.chatItemCompanyName}
              title={conversation.company.name}
            >
              {conversation.company.name}
            </h4>
          </>
        )}
        {conversation.order && (
          <p>{`${t('bid.order')} №${conversation.order.number}`}</p>
        )}
        {conversation.request && (
          <p>{`${t('bid.request')} №${conversation.request.number}`}</p>
        )}
        {conversation.numberOfUnreadMessages > 0 && (
          <s>{conversation.numberOfUnreadMessages}</s>
        )}
      </>
    </div>
  );
}
