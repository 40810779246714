import React, {
  createContext,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import cn from 'classnames';
import { ROOT_FOLDER_ID } from '@constants/folders';
import { useAppDispatch } from '@hooks/redux';
import { useCompanyId } from '@hooks/useCompanyId';
import { ResourceId } from '@store/resources/slice';
import { ResourceFolderId } from '@store/resourceFolders/slice';
import { fetchResourcesRequest } from '@store/resources/actions';
import { fetchResourceFoldersRequest } from '@store/resourceFolders/actions';
import { ResourcesCatalogHeader } from './Header';
import { ResourcesCatalogFolderList } from './FolderList';
import { ResourcesCatalogResourceList } from './ResourceList';

export enum ResourcesCatalogSize {
  Minimal = 'min',
  Normal = '',
}

type ResourcesCatalogContextValue = {
  size: ResourcesCatalogSize;
  folderId: ResourceFolderId;
  onChangeFolder: (id: ResourceFolderId) => void;
  selectedResources: ResourceId[];
  onSelectResources: (resourceId: ResourceId[]) => void;
};

export const ResourcesCatalogContext =
  createContext<ResourcesCatalogContextValue>({
    size: ResourcesCatalogSize.Normal,
    folderId: ROOT_FOLDER_ID,
    onChangeFolder: () => {},
    selectedResources: [],
    onSelectResources: () => {},
  });

type ResourcesCatalogProps = {
  className?: string;
  size: ResourcesCatalogSize;
  folderId: ResourceFolderId;
  onChangeFolder: (id: ResourceFolderId) => void;
  selectedResources: ResourceId[];
  onSelectResources: (resourceId: ResourceId[]) => void;
};

export const ResourcesCatalog: FC<ResourcesCatalogProps> = ({
  className,
  size,
  folderId,
  onChangeFolder,
  selectedResources,
  onSelectResources,
}) => {
  const dispatch = useAppDispatch();
  const [createFolder, setCreateFolder] = useState(false);
  const [createResource, setCreateResource] = useState(false);
  const companyId = useCompanyId();

  useEffect(() => {
    if (companyId !== undefined) {
      dispatch(fetchResourceFoldersRequest({ companyId }));
      dispatch(fetchResourcesRequest({ companyId }));
    }
  }, [companyId, dispatch]);

  const createResourceHandler = useCallback(() => setCreateResource(true), []);
  const cancelResourceEditHandler = useCallback(
    () => setCreateResource(false),
    []
  );
  const createFolderHandler = useCallback(() => setCreateFolder(true), []);
  const submitFolderHandler = useCallback(() => setCreateFolder(false), []);
  const cancelFolderEditHandler = useCallback(() => setCreateFolder(false), []);
  const importHandler = useCallback(() => {}, []);

  const catalogContextProps = useMemo(
    () => ({
      size,
      folderId,
      onChangeFolder,
      selectedResources,
      onSelectResources,
    }),
    [folderId, onChangeFolder, selectedResources, onSelectResources, size]
  );

  return (
    <ResourcesCatalogContext.Provider value={catalogContextProps}>
      <div className={cn('manager', className)}>
        <ResourcesCatalogHeader
          onCreateResourceClick={createResourceHandler}
          onCreateFolderClick={createFolderHandler}
          onImportClick={importHandler}
        />
        <div className="manager__base">
          <ResourcesCatalogFolderList
            createFolder={createFolder}
            onCreateFolderClick={createFolderHandler}
            onSubmitFolder={submitFolderHandler}
            onCancelFolderEdit={cancelFolderEditHandler}
          />
          <ResourcesCatalogResourceList
            createResource={createResource}
            onCreateResourceClick={createResourceHandler}
            onCancelResourceEdit={cancelResourceEditHandler}
          />
        </div>
      </div>
    </ResourcesCatalogContext.Provider>
  );
};
