/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from '@store/constants';

export type DocumentId = string;

export type DocumentsType = {
  id: string;
  fileName: string;
  originalFileName: string;
};

export type DocumentsState = {
  documents: DocumentsType[];
  downloadGeneralDocumentsStatus: FetchStatus;
  downloadCustomersDocumentsStatus: FetchStatus;
  downloadContractorsDocumentsStatus: FetchStatus;
};
const initialState: DocumentsState = {
  documents: [],
  downloadGeneralDocumentsStatus: FetchStatus.NotFetched,
  downloadCustomersDocumentsStatus: FetchStatus.NotFetched,
  downloadContractorsDocumentsStatus: FetchStatus.NotFetched,
};

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    fetchGeneralDocumentsRequest: (state, action) => {
      state.downloadGeneralDocumentsStatus = FetchStatus.Fetching;
    },
    fetchGeneralDocumentsSuccess: (state, action) => {
      state.documents = action.payload;
    },

    fetchCustomersDocumentsRequest: (state, action) => {
      state.downloadCustomersDocumentsStatus = FetchStatus.Fetching;
    },
    fetchCustomersDocumentsSuccess: (state, action) => {
      state.documents = action.payload;
    },

    fetchContractorsDocumentsRequest: (state, action) => {
      state.downloadContractorsDocumentsStatus = FetchStatus.Fetching;
    },
    fetchContractorsDocumentsSuccess: (state, action) => {
      state.documents = action.payload;
    },
  },
});

export default documentsSlice.reducer;
