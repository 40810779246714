import React from 'react';
import { NavLink } from 'react-router-dom';

type IconProps = {
  linkHref: string;
  title: string;
  iconElement: JSX.Element;
};

export const Icon: React.FC<IconProps> = ({ linkHref, title, iconElement }) => {
  return (
    <NavLink
      to={linkHref}
      className={({ isActive }) => (isActive ? 'active' : undefined)}
      aria-label={title}
      data-balloon-pos="right"
    >
      {iconElement}
      {title}
    </NavLink>
  );
};
